<div class="xfw3-grid-row-container">
  <div class="action-item-container-body chat-container-body" #chat>
    <div class="chat-body" (dragover)="onDragOver($event)">
      @if (displayDragoverMenu) {
        <div class="container-fluid">
          <i class="fa-solid fa-xmark text-right" (click)="displayDragoverMenu = false"></i>
        </div>
        <h4 class="text-center">Upload een bestand</h4>
        <xfw-uploader
          [fileJSON]="fieldGroup"
          [size]="'form-large'"
          [extensions]="uploaderExtensions"
          (fileUploads)="onUploadedFiles($event); displayDragoverMenu = false"/>
      }

      @if (loaded && dataGroupItem.dataTable[0].data.length) {
        @if (!displayDragoverMenu) {
          @for (item of dataGroupItem.dataTable[0].data; track item) {
            @if (item.additionalClasses && item.additionalClasses.length) {
            <div class="row" >
              <div class="col-12">
                <div class="message" [ngClass]="item.additionalClasses">
                  <div class="send-by">
                    @if (item.logJSON.contactId === communicationService.user.contactId) {
                      <i [ngClass]="{'message-checkmark': true, 'dripicons dripicons-checkmark': true, 'unread': !item.logJSON.isRead}"></i>
                    }
                    {{ item.logJSON.firstName }}
                  </div>
                  <div class="send-by-date">{{ item.logJSON.datetime | date: 'dd-MM-yyyy HH:mm' }}</div>
                </div>
              </div>
              <div class="col-12">
                <div class="message" [ngClass]="item.additionalClasses">
                  @switch (item.logJSON.type) {
                    @case ('image') {
                      <div class="message-text">
                        <img [src]="item.logJSON.text" alt="image upload" width="100%">
                      </div>
                    }
                    @case ('file') {
                      <div class="message-text">
                        <a [href]="item.logJSON.text" [download]="item.logJSON.text" target="_blank" class="text-light">
                          <i class="dripicons dripicons-download"></i> Download {{ item.logJSON.text.split('/').pop() }}
                        </a>
                      </div>
                    }
                    @default () {
                      <div class="message-text" innerHTML="{{item.logJSON.text | textLinkParse }}"></div>
                    }
                  }
                </div>
              </div>
            </div>
          }
        }
        }
      }

      <!-- <ng-container *ngIf="displayDragoverMenu">
        <div class="d-flex w-100 h-100 align-items-center">
          <h2 class="text-center d-block w-100">
            Upload! <br>
            <h6 class="text-center">Bestand mag maximaal 1mb zijn!</h6>
          </h2>
        </div>
      </ng-container> -->

    </div>
  </div>
  <div class="action-item-container-footer">
    <div class="chat-input" [ngClass]="{'d-none': displayDragoverMenu }">
      <form>
        <div class="row">
          <div class="col-12 d-flex">
            <input
              type="text"
              class="form-control rounded-pill border-0 me-2 flex-grow-1"
              placeholder="Typ een bericht"
              [(ngModel)]="typeMessage" [ngModelOptions]="{standalone: true}"
              #chatInput>
            <app-cmd
                [component]="component"
                [nav]="chatNav"
                [navList]="['primary']"
                [navVisible]="1"
                [className]=""
                [collapsed]="false">
            </app-cmd>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-cmd
              [component]="component"
              [nav]="contextButtonNav"
              [navList]="[]"
              [navVisible]="1"
              [className]=""
              [collapsed]="false">
            </app-cmd>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
