import { Pipe, PipeTransform } from '@angular/core';
import { Key } from 'src/app/data';

@Pipe({
  name: 'primaryKeyObject'
})
export class PrimaryKeyObjectPipe implements PipeTransform {
  transform(value: unknown, args: Key[]): unknown {
    return args.map(param => param.key).reduce((acc, key) => {
      return { ...acc, [key]: value[key] }
    }, {});
  }
}
