import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormComponent } from 'src/app/widget/form/form.component';
import { Nav, Param, WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss'
})
export class BreadcrumbsComponent extends FormComponent implements OnInit, OnChanges{
  @Input() clearsExcluded: string[] = [];

  public nav: Nav;

  public component = this;

  ngOnInit(): void {
    super.ngOnInit();
    this.initClearNav();
  }

  initClearNav() {
    this.nav = {
      navId: 0,
      primary: {
        className: 'cmd icon xxs xfw3-bg-transparent',
        icon: 'fa-regular fa-x',
        text: '',
        tabIndex: -1,
        func: 'clear'
      }
    };
  }

  clear(params: Param[], dataItem: any, data: any[]) {
    dataItem.forEach(field => {
      this.form.controls[field].setValue(null);
    });

    this.saveData(this.rowIndex, 'update')
      .then((data: any[]) => {
        this.refreshFormState(data);

        this.communicationService.performAction({
          info: 'form ' + this.dataGroupItemInstance.edit.type,
          widgetGroup: [this.widgetId.split('-')[0]],
          event: WidgetEvent.SAVE,
          dataItem: data
        });

        return data;
      });
  }
}
