import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';



const attemptHourPartialMatch = (str: string) => {
  // Full match
  let match = str.match(/^(?:(\d+):)?(\d{1,2})$/);
  let hours = '00', minutes = '00';

  if (match) {
    hours = match[1];
    minutes = match[2];
  }

  if (!match) {
    match = str.match(/^(\d+):?$/);

    if (match) {
      hours = match[1];
      minutes = '00';
    }
  }

  if (!match) {
    match = str.match(/^:(\d{1,2})$/);

    if (match) {
      minutes = match[1];
    }
  }

  if (match || str.length === 0)
    return [hours, minutes];
};


/** NG form input component for collecting durations in milliseconds */
@Component({
  selector: 'app-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DurationInputComponent),
    multi: true
  }]
})
export class DurationInputComponent implements ControlValueAccessor {
  @Input() public formControlName: string;

  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  public val: number = 0;;

  /** Value as 'hh:mm' */
  public set value(timeStr: string) {
    if (timeStr === this._value) return;

    const match = attemptHourPartialMatch(timeStr);

    if (!match) return;

    const [hours, minutes] = match;
    const pHour = parseInt(hours) || 0,
      pMinute = parseInt(minutes);

    if (isNaN(pMinute)) return;

    const val = (pHour * 60 + pMinute) * 60;

    this.onChange(val);
    this.onBlur.emit();
    this._value = timeStr;
    this.val = val;
  }
  public get value() { return this._value; }

  public disabled: boolean;

  public onInput(event: InputEvent): void {
    const element = (event.target as HTMLInputElement);
    this.value = element.value;
    element.value = this.value;
  }

  private _value = '00:00';

  private onChange: (data: number) => void = () => { };
  private onTouched: () => void = () => { };

  /** Accepts only number of milliseconds (or string that can be parsed as) or time-string ('hh:mm' format) */
  writeValue(obj: string | number): void {

    // CASE: Empty -> Default
    if (!obj) {
      this._value = '00:00';
      return;
    }

    // CASE: Flat number
    if (typeof obj === 'number' && !isNaN(obj)) {
      this._value = toHourMinuteStr(obj);
      return;
    }

    // CASE: No correct type, kick the bucket
    if (!(typeof obj === 'string'))
      throw `Expected number of seconds, string that can be parsed as or a time string ('hh:mm'). Got '${obj}'`;

    // CASE: String containing number
    const seconds = parseInt(obj);

    if (!isNaN(seconds)) {
      this._value = toHourMinuteStr(seconds);
      return;
    }

    // CASE: String containing 'hh:mm'
    const hourAndMinutes = obj.split(':');
    const hours = parseInt(hourAndMinutes[0]),
      minutes = parseInt(hourAndMinutes[1]);

    if (!(isNaN(hours) || isNaN(minutes))) {

      if (minutes > 60)
        throw `Time string (hh:mm) minutes value too big. Can not exceed an hour (60 minutes)). Got ${minutes}`;

      this._value = obj;

      return;
    }

    // CASE: Invalid data
    throw `Expected number of seconds, string that can be parsed as or a time string ('hh:mm'). Got '${obj}'`;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}

function toHourMinuteStr(seconds: number) {
  // Milliseconds to minutes
  seconds = Math.floor(seconds / 60);

  const minutes = seconds % 60;
  return `${Math.floor(seconds / 60).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}
