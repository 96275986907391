<div class="container-fluid"
     [ngClass]="contentJSON.blockLayoutType">
  <div class="row py-5" [class]="contentJSON.blockCombine">
    <div class="col-12 p-0">
      <div *ngIf="contentJSON.title.length" class="row" [ngClass]="contentJSON.rowClassName">
        <div [ngClass]="contentJSON.blockLayoutGridTitle ? contentJSON.blockLayoutGridTitle : 'offset-1 col-10 offset-sm-2 col-sm-8'">
          <h2>
            <ng-container *ngFor="let item of contentJSON.title; trackBy: trackByFn">
              <div class="header" *ngIf="item.className" [class]="item.className">{{ item.text }}</div>
              <ng-container *ngIf="!item.className">{{ item.text }}</ng-container>
            </ng-container>
          </h2>
        </div>
      </div>
      <div class="row" [ngClass]="contentJSON.rowClassName">
        <ng-container *ngFor="let col of contentJSON.cols[0].col; let index = index; trackBy: trackByFn">
          <div *ngIf="col.body" [ngClass]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[index]]">
            <ng-container *ngFor="let item of col.body">
              <app-fusion-viewer [sourceUrl]="item.sourceUrl" [height]="item.height"></app-fusion-viewer>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
