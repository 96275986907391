import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  checkTiling(silfSizes: any[], formData: any, controls: string[]): boolean {
    const control = controls[0];

    if (!silfSizes || !silfSizes.length) {
      return false;
    }

    const workSizes = ((sizes: any[]) => {
      return sizes.map(size => { return {
        itemCode: size.itemCode,
        supplierId: size.supplierId,
        min: Math.min(size.width, size.height),
        max: Math.max(size.width, size.height)
        }
      });
    });

    formData[control] = !(
      [...new Set(silfSizes.map(size => size.itemCode))].length ===
      [...new Set(workSizes(silfSizes)
        .filter(size => size.min >= Math.min(formData.width, formData.height) && size.max >= Math.max(formData.width, formData.height))
        .map(item => item.itemCode))].length
    );

    return formData[control];
  }

  checkPassword(data: any[], formData: any, controls: string): boolean {
    return formData[controls[0]] === formData[controls[1]];
  }
}
