import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { CommunicationService } from 'src/app/services/communication.service';
import { NetworkService } from 'src/app/services/network.service';
import { NotificationService } from 'src/app/services/notification.service';

interface ImageContent {
  x: number;
  y: number;

  height: number;

  zoom: number;
  angle: number;

  url: string;
}

const defaults: Partial<ImageContent> = {
  x: 0,
  y: 0,
  height: 200,
  zoom: 1,
  angle: 0
};

const clamp = (min: number, max: number, value: number) =>
  Math.min(max, Math.max(min, value));

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: [ './image-uploader.component.scss' ],
  host: {
    '(blur)': 'onTouchedCallback',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true,
    },
  ],
})
export class ImageUploaderComponent
  implements ControlValueAccessor, AfterViewInit, OnInit, OnDestroy {
  @Input() fileJSON: string | any;
  @Input() size: 'form-small' | 'form-large' = 'form-small';
  @Input() info: boolean = true;
  @Input() extensions: string[];
  @Input() fieldGroup: any;

  @Input() widgetId: string;

  @Output() fileUploads = new EventEmitter<File[]>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @ViewChild('fileInput') inputElementRef!: ElementRef<HTMLInputElement>;
  @ViewChild('imageElement') imageElementRef!: ElementRef<HTMLImageElement>;
  @ViewChild('imageContainer') imageContainerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('cropBox') cropBoxRef!: ElementRef<HTMLDivElement>;

  public isRightAngleMode = false;
  public isDisabled = false;
  public img: ImageContent = {
    x: 0,
    y: 0,
    height: 200,
    zoom: 1,
    angle: 0,
    url: '',
  };

  public minZoom = 0.25;
  public maxZoom = 4;

  public hostLeft: number;
  public hostTop: number;
  public onChange!: (val: any) => void;

  public round = Math.round;
  public max = Math.max;
  public min = Math.min;

  // Drag state

  public dragging = false;
  public turning = false;
  public file: File | undefined;

  private mouseX = 0;
  private mouseY = 0;
  private initialPositionX = 0;
  private initialPositionY = 0;

  private $change = new Subject<void>();
  private subscriptions: Subscription[] = [];
  private observer: MutationObserver;

  private isUploadingFile = false;
  private hasFileJSONUpdatePending = false;
  private fileReader = (() => {
    const fr = new FileReader();

    fr.onload = (event) => {
      this.img.url = event.target.result as string;
    };

    return fr;
  })();

  public constructor (
    public hostElement: ElementRef<HTMLElement>,
    private http: NetworkService,
    private notification: NotificationService,
    private comms: CommunicationService
  ) {
    this.subscriptions.push(
      this.$change
        .pipe(debounceTime(5000))
        .subscribe((v) => this.uploadFileJSON())
    );
  }

  public resetStyling() {
    Object.assign(this.img, defaults);
    this.$change.next();
  }

  ngOnInit(): void {
    this.fileJSON = { ...this.fileJSON, ...this.fileJSON.dataFileJSON };

    this.writeValue(this.fileJSON);

    delete this.fileJSON.dataFileJSON;
    this.updateScreenOffsets();
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.updateScreenOffsets(); });

    const observer = this.observer = new MutationObserver(() => {
      this.updateScreenOffsets();
    });

    observer.observe(this.hostElement.nativeElement, { attributes: true, childList: false, subtree: false });
    observer.observe(this.hostElement.nativeElement.parentNode, { attributes: false, childList: true, subtree: false });
  }

  @HostListener('window:scroll')
  @HostListener('window:resize')
  private updateScreenOffsets(): void {
    this.hostLeft = this.hostElement.nativeElement.getBoundingClientRect().left;
    this.hostTop = this.hostElement.nativeElement.getBoundingClientRect().top;
  }

  private uploadFileJSON() {
    if (this.fileJSON.imgAndThumbs.length === 0) return;
    if (this.isUploadingFile) return this.hasFileJSONUpdatePending = true;

    let fileJSON = this.fileJSON;
    const img = this.img;

    if (typeof fileJSON === 'string') fileJSON = JSON.parse(fileJSON);

    fileJSON.imgAndThumbs[ 0 ].styling = {
      x: img.x,
      y: img.y,
      width: this.imageElementRef.nativeElement.clientWidth,
      height: this.imageElementRef.nativeElement.clientHeight,
      angle: img.angle,
      filter: ``,
    };

    const formData = new FormData();

    formData.append('fileJSON', JSON.stringify(fileJSON));
    formData.append('widgetId', this.widgetId);

    const reqSub = this.http
      .post('/api/FileUpload/json', formData, 'json')
      .subscribe({
        next: (data) => {
          console.log('data fileUpload', data, fileJSON);

          this.fileUploads.emit([ this.file ]);

          reqSub.unsubscribe();
          typeof this.onChange === 'function' && this.onChange(fileJSON);
        },
        error: (res) => {
          this.notification.addNotification({
            msgType: 'error',
            msg: {
              title: 'Error',
              body: { text: 'Error uploading' },
            },
          });
          reqSub.unsubscribe();
          typeof this.onChange === 'function' && this.onChange(fileJSON);
          this.onBlur.emit();
        }
      });
  }

  private uploadFile() {
    let fileJSON = this.fileJSON;
    const img = this.img;

    if (typeof fileJSON === 'string') fileJSON = JSON.parse(fileJSON);

    fileJSON.imgAndThumbs[ 0 ].styling = {
      x: img.x,
      y: img.y,
      width: this.imageElementRef.nativeElement.clientWidth,
      height: this.imageElementRef.nativeElement.clientHeight,
      angle: img.angle,
      filter: ''
    };
    this.isUploadingFile = true;
    const formData = new FormData();

    formData.append('files', this.file);
    formData.append('fileJSON', JSON.stringify(fileJSON));
    formData.append('widgetId', this.widgetId);

    const reqSub = this.http
      .post('/api/FileUpload', formData, 'json')
      .subscribe({
        next: (data) => {
          console.log('data uploadFile', data, fileJSON);

          this.fileUploads.emit([ this.file ]);

          this.file = undefined;
          this.isUploadingFile = false;

          reqSub.unsubscribe();

          if (this.hasFileJSONUpdatePending) {
            this.hasFileJSONUpdatePending = false;
            this.uploadFileJSON();
          } else {
            typeof this.onChange === 'function' && this.onChange(fileJSON);
            this.onBlur.emit();
          }

        },
        error: (res) => {
          this.notification.addNotification({
            msgType: 'error',
            msg: {
              title: 'Error',
              body: { text: 'Error uploading' },
            },
          });
          reqSub.unsubscribe();
          this.isUploadingFile = false;

          if (this.hasFileJSONUpdatePending) {
            this.hasFileJSONUpdatePending = false;
          } else {
            typeof this.onChange === 'function' && this.onChange(fileJSON);

          }
        }
      });
  }

  public fileChange(file: File) {
    this.file = file;
    this.fileReader.readAsDataURL(file);

    this.uploadFile();
  }

  @HostListener('wheel', [ '$event' ])
  private _onWheel(event: WheelEvent) {
    if (this.isDisabled) return;

    event.preventDefault();

    this.img.zoom = clamp(
      this.minZoom,
      this.maxZoom,
      this.img.zoom * (1 + event.deltaY * -0.001)
    );

    this.$change.next();
  }

  @HostListener('pointerdown', [ '$event' ])
  private _onDragStart(event: PointerEvent) {
    if (this.isDisabled) return;
    if (event.button !== 0) return;

    if (
      !(
        this.imageContainerRef.nativeElement.contains(event.target as Node) ||
        this.imageContainerRef.nativeElement === event.target ||
        this.cropBoxRef.nativeElement === event.target
      )
    )
      return;

    event.preventDefault();

    this.dragging = true;
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
    this.initialPositionX = this.img.x;
    this.initialPositionY = this.img.y;

    document.addEventListener('pointermove', this._onDrag);
    document.addEventListener('pointerup', this._onPointerUp);
  }

  public onTurnStart(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.turning = true;
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    // Get the center of the rotating element
    const elementRect =
      this.imageElementRef.nativeElement.getBoundingClientRect();
    const centerX = elementRect.left + elementRect.width / 2;
    const centerY = elementRect.top + elementRect.height / 2;
    this.initialPositionX = Math.atan2(mouseY - centerY, mouseX - centerX);
    this.initialPositionY = this.img.angle;

    document.addEventListener('pointermove', this._onTurn);
    document.addEventListener('pointerup', this._onPointerUp);
  }

  private _onDrag = (event: PointerEvent) => {
    // Substract initial
    const dx = event.clientX - this.mouseX;
    const dy = event.clientY - this.mouseY;

    this.img.x = this.initialPositionX + dx;
    this.img.y = this.initialPositionY + dy;

    this.$change.next();
  };

  private _onTurn = (event: PointerEvent) => {
    // Calculate the rotation angle based on mouse position
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    // Get the center of the rotating element
    const elementRect =
      this.imageElementRef.nativeElement.getBoundingClientRect();
    const centerX = elementRect.left + elementRect.width / 2;
    const centerY = elementRect.top + elementRect.height / 2;

    // Calculate the angle between the center of the element and the mouse position
    this.img.angle =
      Math.atan2(mouseY - centerY, mouseX - centerX) -
      this.initialPositionX +
      this.initialPositionY;

    if (this.isRightAngleMode) {
      this.img.angle *= 180 / Math.PI / 90;

      this.img.angle = this.round(this.img.angle) / (180 / Math.PI / 90);
    }

    this.$change.next();
  };

  private _onPointerUp = (event: PointerEvent) => {
    this.dragging = false;
    this.turning = false;

    if (this.mouseX === event.clientX && this.mouseY === event.clientY) {
      this.inputElementRef.nativeElement.click();
    }

    document.removeEventListener('pointermove', this._onDrag);
    document.removeEventListener('pointermove', this._onTurn);
    document.removeEventListener('pointerup', this._onPointerUp);
  };

  // Misc events

  @HostListener('window:keydown.shift', [ '$event' ])
  private _setRightAnglesActive(event: KeyboardEvent) {
    if (event.key === 'Shift') {
      this.isRightAngleMode = true;
      window.addEventListener('keyup', this._setRightAnglesInactive);
    }

  }

  private _setRightAnglesInactive = (event: KeyboardEvent) => {
    if (event.key === 'Shift') this.isRightAngleMode = false;
  };

  // Control Value Accessor

  public writeValue(obj: any): void {
    if (typeof obj === 'string')
      this.img.url = obj;
    else if (obj instanceof Object && obj?.imgAndThumbs?.length) {
      const imgInObject = obj?.imgAndThumbs[ 0 ];
      const styling = imgInObject.styling;
      const img = this.img;

      // Fill out internal img object
      Object.assign(img, styling);
      img.url = imgInObject.imgUrl;

      // Add default so image isn't size 0
      if (!img.height) img.height = 200;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void { }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }


  public ngOnDestroy(): void {
    // TODO remove from comms service
    this.uploadFileJSON();
    this.subscriptions.forEach((v) => v.unsubscribe());
    this.observer.disconnect();
  }
}
