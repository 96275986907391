import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css']
})
export class SwitchComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  public showReseller = false;
  public showClient = false;

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
  }

  trackByFn(index, item) {
    return index;
  }

}
