import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ConfiguratorOption, ConfiguratorProduct, ConfiguratorRow } from 'src/app/configurator';
import { DataGroup } from 'src/app/data';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { UtilsService } from 'src/app/services/utils.service';
import { EventParams } from 'src/app/widgets';

@Component({
  selector: 'app-configurator-option',
  templateUrl: './configurator-option.component.html',
  styleUrls: ['./configurator-option.component.scss']
})
export class ConfiguratorOptionComponent implements OnInit {
  @Input() dataGroupItemInstance: DataGroup;
  @Input() index: number;
  @Input() product: ConfiguratorProduct;
  @Input() row: ConfiguratorRow;
  @Input() rowIndex: number;
  @Input() option: ConfiguratorOption;
  @Input() code: string;
  @Input() state: string;
  @Input() uploadType: 'no-img' | 'none' | 'single' | 'single-edit' | 'multiple' = 'none';


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private utilsService: UtilsService,
    private configuratorService: ConfiguratorService
  ) { }

  ngOnInit(): void {
    this.option['name'] = this.option.block.name;
    this.option['description'] = this.option.block.description;
  }

  /**
   * Zodra een keuze wordt gemaakt (type radio) dan checken we of er al een keuze was gemaakt.
   * Is dat het geval dan controleren we of de nieuwe keuze invloed heeft op de mogelijke gekozen vervolgopties.
   * Is dat het geval dan worden de gekozen vervolgopties gereset, anders blijven de gekozen vervolgopties geselecteerd.
   *
   * Dit maakt het aanpassen van voorgaande keuzes een stuk gebruikersvriendelijker en verkleint de kans op fouten.
   *
   * @param row gekozen row
   * @param option gekozen optie
   */

  select(configuratorRow: ConfiguratorRow, eventParams: EventParams) {
    const option: ConfiguratorOption = eventParams.dataItem;

    this.configuratorService.configurator.state[this.state] = false;

    const oldOption = configuratorRow.options.some(option => option.isSelected) ?
      configuratorRow.options.find(option => option.isSelected)
      :
      null;

    const newCode = option.code;

    configuratorRow.options.filter(rowOption => option.type === 'checkbox' ? rowOption.type !== 'checkbox' : true).forEach(rowOption => {
      rowOption.isSelected = false;
      rowOption.checked = false;
    });

    oldOption?.code && option.type !== 'checkbox' &&
      this.product.configuratorRows.forEach(productConfiguratorRow => {
        productConfiguratorRow.conditionalCodesExt.forEach(conditionalCode => {
          this.utilsService.isSubsetMap(conditionalCode.codes, [[oldOption.code]]) &&
            productConfiguratorRow.options.filter(option => option.isSelected).forEach(rowOption => {
              let resetOption = true;

              rowOption.conditionalCodesExt.forEach(optionConditionalCode => {
                this.utilsService.isSubsetMap(optionConditionalCode.codes, [[newCode]]) && (resetOption = false);
              });

              if (resetOption) {
                rowOption.state.hidden = true;
                productConfiguratorRow.optionSelected = null;
                productConfiguratorRow.state.checked = false;
                productConfiguratorRow.state.inValid = true;
                productConfiguratorRow.state.expanded = true;
              } else {
                productConfiguratorRow.state.checked = true;
                productConfiguratorRow.state.expanded = false;
              }
            });

          this.utilsService.isSubsetMap(conditionalCode.codes, [[newCode]]) &&
          productConfiguratorRow.options.filter(option => option.isSelected).forEach(rowOption => {
              rowOption.state.hidden = false;
              this.configuratorService.setRowSelected(productConfiguratorRow, productConfiguratorRow.options);
            });
        })
      });

    option.isSelected = option.checked = (option.type !== 'checkbox' ? !option.isSelected : !option.checked);

    this.configuratorService.setRowSelected(configuratorRow, configuratorRow.options);

    if (!configuratorRow.options.some(option => option.type === 'checkbox')) {
      configuratorRow.state.checked = true;
      configuratorRow.state.expanded = false;
    } else {
      configuratorRow.state.checked = false;
    }

    this.configuratorService.refreshConfiguration(
      this.product,
      this.dataGroupItemInstance,
      this.state);

    !configuratorRow.options.some(rowOption => rowOption.type === 'checkbox') &&
      this.configuratorService.selectNextUndecided(this.product, this.document);
  }
}
