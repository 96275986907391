import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { FormGroupValidation } from 'src/app/data';
import { ValidatorService } from 'src/app/services/validator.service';

@Injectable({ providedIn: 'root' })
export class XfwValidator {

  public dynamicValidator(
    validatorService: ValidatorService,
    warning: any,
    formGroupValidation: FormGroupValidation
    ): ValidatorFn {
    return (formGroup: FormGroup) => {
      let result = true;

      if (formGroupValidation) {
        const formData = formGroup.getRawValue();
        const formControl = [];

        formGroupValidation.control.forEach(control => {
          formControl.push(formGroup.get(control));
        });

        result = validatorService[formGroupValidation.func](formGroupValidation.data, formData, formGroupValidation.control);

        if(formGroupValidation?.patchResult) {
          formControl.forEach(control => {
            control.patchValue(result ? formGroupValidation.valueValid : formGroupValidation.valueInvalid,
              { onlySelf: true, emitEvent: false, emitViewToModelChange: true, emitModelToViewChange: true });
          });
        } else {
          formControl.forEach(control => {
            if (!result) {
              control.setErrors({ 'invalid': !result });
            } else {
              control.setErrors(null);
            }
          });
        }

        warning.text = result ? formGroupValidation.textValid : formGroupValidation.textInvalid;
      }

      return formGroupValidation?.patchResult || result ? null : { error: !result };
    }
  }
}
