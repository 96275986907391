import { Injectable } from '@angular/core';
import { DataTable } from '../data';
import { NetworkService } from './network.service';


type MapItem = LocationInfo & {
  companyName: string;
  color: string;
};

type LocationInfo = {
  street: string;
  no: string;
  zip: string;
  place: string;
  latitude: number;
  longitude: number;
};

@Injectable({
  providedIn: 'root'
})
export class GmapService {

  /**
   * De TeamId, TeamColor en Address met hoofdletters, komen
   * namelijk uit de database.
   *
   * Address is Street + No + ', ' + Place
   *
   * teams: {
   *  TeamId,
   *  TeamColor
   * }
   *
   * teams[].waypoints: [{
   *  Address
   * }]
   */

  /**
   *
   * Hier moet dus ook een routine komen om gewoon plaatsen aan te geven.
   * Zodra je op een plaats klikt moet er een popup komen. In die popup
   * zou wellicht een data-item moeten staan of doen we dat anders?
   *
   */

  /**
   * Saturation
   * Zoom
   * Color markers
   * Height
   */

  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  bounds: google.maps.LatLngBounds;

  fit: 'user' | 'markers' = 'user';

  public mapOptions: google.maps.MapOptions = {
    zoom: 10,
    disableDefaultUI: true,
  };

  svgMarker: google.maps.Symbol = {
    path: "M0-20c1.9 0 3.6.7 4.9 2s2 3 2 4.9c0 1-.2 2.1-.7 3.3s-1.1 2.4-1.8 3.5c-.7 1.1-1.4 2.1-2 3.1-.7 1-1.2 1.7-1.7 2.3L0 0c-.2-.2-.4-.5-.8-.9-.3-.4-.9-1.1-1.7-2.2S-4-5.2-4.6-6.2c-.6-1-1.2-2.2-1.7-3.4S-7-12-7-13c0-1.9.7-3.6 2-4.9S-1.9-20 0-20L0-20z",
    fillColor: "#fbdc04",
    fillOpacity: 1,
    rotation: 0,
    scale: 1.4,
    strokeColor: '#000000',
    strokeWeight: 1,
    strokeOpacity: 0.1
  };


  constructor(
    private network: NetworkService
  ) { }

  mapLocationsInitializer(dataTable: DataTable[], gmap, options) {


    setTimeout(() => {
      // if (options.saturation) {
      //   this.mapOptions.styles[0].stylers[0].saturation = options.saturation;
      // }

      if (options.markerColor) {
        this.svgMarker.fillColor = options.markerColor;
      }

      this.map = new google.maps.Map(gmap.nativeElement, this.mapOptions);
      this.geocoder = new google.maps.Geocoder();
      this.bounds = new google.maps.LatLngBounds();

      google.maps.event.addListenerOnce(this.map, 'bounds_changed', function (event) {
        // Jezelf laten zien is goed mogelijk op de map. Bedenken hoe we dat willen doen.

        //this.setCenter();
        //component.addMarker({ latitude: 51.6612516, longitude: 4.0961845 }, component.map, component.bounds, { markerColor: '#fbdc04' });
        //this.setZoom(9);

        if (options.zoom) {
          this.setZoom(parseInt(options.zoom));
        }
      });

      if (this.fit === 'user') {

        if ('geolocation' in navigator) {
          // Request the user's position
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // Get the latitude and longitude
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;

              // Create a LatLng object with the user's position
              const userLatLng = new google.maps.LatLng(latitude, longitude);

              this.map.setCenter(userLatLng);
              dataTable[0].data.forEach((item, index) => {
                // console.log(item);
                this.addMarker(item, this.map, this.bounds, dataTable[0].src, options);
              });
            },
            (error) => {
              // Handle geolocation error
              console.error("Error getting user's location:", error);

              this.fit = 'markers';
              dataTable[0].data.forEach((item, index) => {
                // console.log(item);
                this.addMarker(item, this.map, this.bounds, dataTable[0].src, options);
              });
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");

          this.fit = 'markers';
          dataTable[0].data.forEach((item, index) => {
            // console.log(item);
            this.addMarker(item, this.map, this.bounds, dataTable[0].src, options);
          });
        }
      } else {
        dataTable[0].data.forEach((item, index) => {
          // console.log(item);
          this.addMarker(item, this.map, this.bounds, dataTable[0].src, options);
        });
      }
    });
  }

  showMarker(marker: any, item: any, map, bounds, options) {
    const infoWindow = new google.maps.InfoWindow({
      content: `
        <div style="padding: 0.5rem; line-height: 1.2rem;">
          <b>${item.companyName}</b><br />
          <span>${item.street} ${item.no}</span><br />
          <span>${item.zip}  ${item.place}</span><br /><br />
          <span>Tel: ${item.companyPhone}</span><br />
          <span>Email: <a style="color: var(--black); text-decoration: underline;" href="mailto:${item.companyEmail}">${item.companyEmail}</a></span><br />
          <span>Web: <a style="color: var(--black); text-decoration: underline;" target="_blank" href="https:\\\\${item.website}">${item.website}</a></span>
        </div>
      `
    });

    if (marker) {
      marker.addListener('click', () => {
        infoWindow.open(map, marker);
      });

      marker.setMap(map);

      if (this.fit === 'markers')
        map.fitBounds(bounds);
    }
  }

  addMarker(item: MapItem, map: google.maps.Map, bounds: google.maps.LatLngBounds, src: string, options: any, retry = true) {

    // If no long/lat is present, exit (with retry)
    if (!(item.latitude && item.longitude))
      return retry && this.geocoder.geocode({
        address: `${item.street} ${item.no} ${item.zip} ${item.place}`,
      }, (results, status) => {
        if (results?.length) {
          const { geometry: { location }, ...result } = results[0];

          console.log(item, location.lat(), location.lng());

          this.network.crud([{
            src, mutations: [{
              ...item,
              crud: 'update',
              latitude: location.lat(),
              longitude: location.lng()
            }]
          }], 'json').subscribe();


          this.addMarker({ ...item, latitude: location.lat(), longitude: location.lng() }, map, bounds, src, options, false);
        }
      });

    const marker = new google.maps.Marker({
      position: {
        lat: item.latitude,
        lng: item.longitude
      },
      title: item.companyName,
      icon: {
        ...this.svgMarker,
        fillColor: item.color || this.svgMarker.fillColor
      },
    });

    bounds.extend({
      lat: item.latitude,
      lng: item.longitude
    });

    this.showMarker(marker, item, map, bounds, options);
  }

  mapRouteInitializer(teams, gmap, paramObject) {
    this.map = new google.maps.Map(gmap.nativeElement, this.mapOptions);

    teams.forEach(team => {
      this.calcRoute(team);
    });
  }

  calcRoute(team: any) {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();

    directionsRenderer.setMap(this.map);

    directionsService.route(this.setRoute(team.teamId, team.waypoints), (results, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(results);
        directionsRenderer.setOptions({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: '#' + team.TeamColor
          }
        });

        team.distances = results.routes[0].legs;

        team.distance = team.distances.reduce((accum, current) => accum + current.distance.value, 0);
        team.duration = team.distances.reduce((accum, current) => accum + current.duration.value, 0);

        team.distances.forEach((point, index) => {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(point.start_location.lat(), point.start_location.lng()),
            map: this.map,
            icon: index === 0 ? 'https://chart.googleapis.com/chart?chst=d_map_xpin_icon&chld=pin_star|car-dealer|' + team.teamColor + '|fff' :
              'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=' + index + '|' + team.teamColor + '|fff'
          });
        });
      }
    });
  }

  setRoute(teamId: number, route: any[]) {
    const waypoints = [];

    route.forEach(waypoint => {
      waypoints.push({
        location: waypoint.address,
        stopover: true
      });
    });

    waypoints.pop();
    waypoints.shift();

    return {
      origin: route[0].address,
      destination: route[route.length - 1].address,
      waypoints: waypoints,
      provideRouteAlternatives: false,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC
    };
  }
}
