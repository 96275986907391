@if (!dataGroupItemInstance.noFooter) {
  <div class="row-footer" [ngClass]="[dataGroupItemInstance.footer?.className ?? '']">
    <div class="nav nav-top" >
      <div class="primary" >
        @if (['inline', 'form'].includes(dataGroupItemInstance.edit.type) && !['form', 'treeview'].includes(dataGroupItemInstance.layout) && dataGroupItemInstance.edit.create) {
            <app-cmd [component]="component" [nav]="nav" [navList]="['primary']" [navVisible]="1"/>
          }
      </div>
      @if (dataGroupItemInstance.footer?.nav && dataGroupItemInstance.footer?.nav?.menu) {
        <div class="menu">
          <app-cmd
            [nav]="dataGroupItemInstance.footer.nav"
            [navList]="['menu']"
            [navVisible]="3"
            [className]="dataGroupItemInstance.footer.nav.className ?? ''"
            [component]="component"
            [dataItem]="dataItem"
            [data]="dataGroupItemInstance.dataTable"
            [collapsed]="true"/>
        </div>
      }
    </div>
    <div class="nav nav-middle">
      <div [ngClass]="['primary', dataGroupItemInstance.footer?.className ?? '']" >
        @if (dataGroupItemInstance.footer?.nav) {
          <app-cmd
            [nav]="dataGroupItemInstance.footer.nav"
            [navList]="['primary']"
            [navVisible]="0"
            [className]="dataGroupItemInstance.footer.nav.className ?? ''"
            [component]="component"
            [dataItem]="dataItem"
            [data]="dataGroupItemInstance.dataTable"
            [collapsed]="false"/>
        }
      </div>
    </div>
    @if (!headerPagination && dataGroupItemInstance.dataTable[0]?.data?.length > dataGroupItemInstance.page && dataGroupItemInstance.paging === 'pagination') {
      <div class="nav-bottom">
        <div class="pagination">
          <ngb-pagination
            (click)="$event.stopPropagation()"
            [collectionSize]="dataGroupItemInstance.dataTable[0].data.length"
            [pageSize]="dataGroupItemInstance.page"
            [(page)]="curPage"
            [size]="'sm'"
            [maxSize]="3"
            [rotate]="true"
            [boundaryLinks]="true"
            [ellipses]="false"/>
        </div>
      </div>
    }
  </div>
}
