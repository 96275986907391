@if (loaded) {
  <div class="xfw3-grid-row-container">
    <div class="xfw3-grid-header">
      @if (nav.type === 'menu') {
        <app-menu
          [widgetId]="widgetId"
          [src]="src"
          [nav]="nav"/>
      }@else if (['tabstrip', 'tabs', 'tabs-dropdown-fullwidth'].includes(nav.type)) {
        <app-tabstrip2
          [widgetId]="widgetId"
          [src]="src"
          [component]="component"
          [nav]="nav"
          [dataItem]="dataItem"
          [className]="className ?? nav.className"/>
      }@else if (nav.type === 'tabcols') {
        <app-tabcols
          [widgetId]="widgetId"
          [src]="src"
          [nav]="nav"/>
      }

      @if (routerOutlet) {
        <router-outlet></router-outlet>
      }
    </div>

    @if (nav.dataGroup) {
      <div [ngClass]="['xfw3-grid-body', 'data-group-container', nav.className ?? '']">
        <app-data-group
          [widgetId]="widgetId"
          [src]="nav.dataGroup"
          [params]="params"
          [dataItem]="dataItem"
          [selected]="dataGroupItemSelected"/>
      </div>
    }
  </div>
}
