import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fusion-viewer',
  templateUrl: './fusion-viewer.component.html',
  styleUrls: ['./fusion-viewer.component.css']
})
export class FusionViewerComponent {

  private readonly fusionViewerSourceUrl: string = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/6.*/viewer3D.js';
  private readonly fusionViewerStyleUrl: string = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/6.*/style.min.css';

  @Input() sourceUrl: string;
  @Input() height: number = 500;

  private viewer: any;

  constructor(private http: HttpClient) {
    // Load the fusion viewer
    const getViewer = new Promise(resolve => {
      // Load CSS
      const styleElement = document.createElement('link');
      styleElement.type = "text/css";
      styleElement.rel = "stylesheet"
      styleElement.href = this.fusionViewerStyleUrl;
      document.head.appendChild(styleElement);

      // Load Script
      const scriptElement = document.createElement('script');
      scriptElement.src = this.fusionViewerSourceUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    }).then(async () => {
      // load the data of the model

      // @ts-ignore Sorry, I don't know how to resolve this in any other way.
      Window.tempFusionViewTokenUrl = this.sourceUrl.replace('public', 'sign').replace('mode=embed', 'oauth2=true');

      const options = {
        env: 'AutodeskProduction',
        getAccessToken: this.getToken
      };

      const urn = await this.getURN();

      const documentId = 'urn:' + urn;

      // @ts-ignore
      Autodesk.Viewing.Initializer(options, () => {
        // @ts-ignore
        Autodesk.Viewing.Document.load(documentId, (doc) => {

          // A document contains references to 3D and 2D viewables.
          // @ts-ignore
          const viewables = Autodesk.Viewing.Document.getSubItemsWithProperties(doc.getRootItem(), {
            'type': 'geometry'
          }, true);
          if (viewables.length === 0) {
              console.error('Document contains no viewables.');
              return;
          }

          // Choose any of the avialble viewables
          const initialViewable = viewables[0];
          const svfUrl = doc.getViewablePath(initialViewable);
          const modelOptions = {
              sharedPropertyDbPath: doc.getPropertyDbPath()
          };

          const viewerDiv = document.getElementById('fusionViewer');
          // @ts-ignore
          this.viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv);

          // Our customization of the Viewer: we are removing some of the toolbar buttons
          // @ts-ignore
          this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreated);

          this.viewer.start(svfUrl, modelOptions, () => {}, () => {});

        }, () => {});
      });
    });
  }

  // This method will download the data needed to view the right model
  private async getURN(): Promise<string> {
    const metadata = await this.http.get(
      this.sourceUrl.replace('public', 'metadata').replace('?mode=embed', ''),
      {
        responseType: 'json',
      }
    ).toPromise().catch((e) => console.error(e));

    // @ts-ignore
    return metadata.success.body.viewing.views.viewableUrn;

  }

  /**
   * this method is the getter for an public auth token.
   *
   * @param onTokenCallback callback function
   */
  private async getToken(onTokenCallback): Promise<void> {
    console.log(onTokenCallback)

    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    // @ts-ignore
    const req: Promise<Response> = fetch(Window.tempFusionViewTokenUrl , { method: 'POST', body: '{}', headers: headers })

    req.then(response => response.json()).then(oauth => {
      if (onTokenCallback)
        onTokenCallback(oauth.accessToken, oauth.validitySeconds);
    }).catch((e) => console.error('loading failed.', e));


  }

  /**
   * This method will initialise the view of the fusion model viewer
   *
   * @param doc retreved model object
   */
  private onDocumentLoadSuccess(doc) {

    // A document contains references to 3D and 2D viewables.
    // @ts-ignore
    const viewables = Autodesk.Viewing.Document.getSubItemsWithProperties(doc.getRootItem(), {
        'type': 'geometry'
    }, true);
    if (viewables.length === 0) {
        console.error('Document contains no viewables.');
        return;
    }

    // Choose any of the avialble viewables
    const initialViewable = viewables[0];
    const svfUrl = doc.getViewablePath(initialViewable);
    const modelOptions = {
        sharedPropertyDbPath: doc.getPropertyDbPath()
    };

    const viewerDiv = document.getElementById('MyViewerDiv');
    // @ts-ignore
    this.viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv);

    // Our customization of the Viewer: we are removing some of the toolbar buttons
    // @ts-ignore
    this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreated);

    this.viewer.start(svfUrl, modelOptions, () => {}, () => {});
  }

  /**
   * this method will remove controlls that are not needed
   *
   * @param e event
   */
  private onToolbarCreated = (e) => {
    const settingsTools = this.viewer.toolbar.getControl('settingsTools');
    settingsTools.removeControl('toolbar-modelStructureTool');
    settingsTools.removeControl('toolbar-propertiesTool');
    settingsTools.removeControl('toolbar-settingsTool');

    // @ts-ignore
    this.viewer.removeEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, () => {});
  }

}
