import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapAnchorPoint, MapDirectionsService, MapInfoWindow } from '@angular/google-maps';
import { Observable, ReplaySubject, Subscription, map, mergeMap } from 'rxjs';
import { DataGroup } from 'src/app/data';

type Location = {
  longitude: number;
  latitude: number;
};

type Route = {
  points: Location[];
};

type Marker = {
  latitude: number;
  longitude: number;
  color?: string;
  label?: {
    text: string,
    color: string, // Label color
    fontSize: string, // Label font size
    fontWeight: string, // Label font weight
  };
} & google.maps.MarkerOptions;

const svgMarker: google.maps.Symbol = {
  path: 'M0-20c1.9 0 3.6.7 4.9 2s2 3 2 4.9c0 1-.2 2.1-.7 3.3s-1.1 2.4-1.8 3.5c-.7 1.1-1.4 2.1-2 3.1-.7 1-1.2 1.7-1.7 2.3L0 0c-.2-.2-.4-.5-.8-.9-.3-.4-.9-1.1-1.7-2.2S-4-5.2-4.6-6.2c-.6-1-1.2-2.2-1.7-3.4S-7-12-7-13c0-1.9.7-3.6 2-4.9S-1.9-20 0-20L0-20z',
  fillColor: '#fbdc04',
  fillOpacity: 1,
  rotation: 0,
  scale: 1.4,
  strokeColor: '#000000',
  strokeWeight: 1,
  strokeOpacity: 0.1
};

const routeToDirectionsRequest = (locations: Location[]): google.maps.DirectionsRequest => {
  if (locations.length < 2) return;

  const points = locations.map(v => ({
    lng: v.longitude,
    lat: v.latitude
  }));

  return {
    origin: points[0],
    destination: points[points.length - 1],
    waypoints: points.length > 2 ? points.slice(1, -1).map(point => ({ stopover: true, location: point })) : undefined,
    travelMode: google.maps.TravelMode.DRIVING,
  };
};

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss']
})
export class GMapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() widgetId: string;
  @Input() options: any;
  @Input() type: string;
  @Input({ alias: 'dataGroupItem' }) set inputDataGroupItem(v: DataGroup) {
    this.dataGroupItem = v;

    if (v.fit)
      this.fit = v.fit as any;
  };
  @Input() set route(v: Location[]) { this.route$.next(v); };

  @Input() fit: 'user' | 'markers' | 'route' = 'user';
  @Input() mapId: string = 'a7edf8d065bfa0db';

  @Input() markers: Marker[] = [];

  public markersRoute: Marker[] = [];
  public selected: any = {};

  public mapCenter: google.maps.LatLngLiteral = { lat: 51.6969389, lng: 4.8572958 };
  public mapZoom: number = 10;

  public readonly route$ = new ReplaySubject<Location[]>(1);
  public readonly directionsResults$: Observable<google.maps.DirectionsResult | undefined>;

  private geocoder = new google.maps.Geocoder();
  @ViewChild(GoogleMap) private map: GoogleMap;
  @ViewChild(MapInfoWindow) private info: MapInfoWindow;

  private dataGroupItem: DataGroup;
  private subscriptions: Subscription[] = [];

  public constructor(
    private mapDirectionsService: MapDirectionsService
  ) {
    this.directionsResults$ = this.route$
      .pipe(
        mergeMap(value => value ? this.mapDirectionsService.route(routeToDirectionsRequest(value)) : undefined),
        map(response => response?.result),
      );

    // Set up markers for routing
    this.subscriptions.push(this.directionsResults$.subscribe(({ routes }) => {
      if (!routes || !routes[0]) return this.markersRoute = [];
      const route = routes[0];
      const legs = route.legs;
      const markers = legs.map<Marker>((leg, idx) => ({
        latitude: leg.start_location.lat(),
        longitude: leg.start_location.lng(),
        label: {
          text: (idx + 1).toString(),
          color: 'white',
          fontSize: '12px',
          fontWeight: 'bold',
        },
        icon: {
          ...svgMarker,
          fillColor: '#0000FF',
          labelOrigin: new google.maps.Point(0, -12),
          scale: 1.1 * svgMarker.scale,
        },
        zIndex: 1000

      }));

      const leg = legs[legs.length - 1];
      markers.push({
        latitude: leg.end_location.lat(),
        longitude: leg.end_location.lng(),
        label: {
          text: (legs.length + 1).toString(),
          color: 'white',
          fontSize: '12px',
          fontWeight: 'bold',
        },
        icon: {
          ...svgMarker,
          fillColor: '#0000FF',
          labelOrigin: new google.maps.Point(0, -12),
          scale: 1.1 * svgMarker.scale,
        },
        zIndex: 1000
      });

      this.markersRoute = markers;

      if (this.fit === 'route' && this.markersRoute.length > 0) {
        this.map.fitBounds(
          this.markersRoute.reduce(
            (bound, marker) => bound.extend({ lat: marker.latitude, lng: marker.longitude }),
            new google.maps.LatLngBounds()
          )
        );
      }

    }));

  }

  /** Select a map object with any provided/attached data. */
  public select(mapObject: MapAnchorPoint, data: any): void {
    this.selected = data;
    if (mapObject)
      this.info.open(mapObject, true);
    else
      this.info.close();
  }

  /** Fits the map's view based on {@linkcode GMapComponent.fit} */
  private fitView(): void {
    if (this.fit === 'user') {
      if ('geolocation' in navigator) {
        // Request the user's position
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Get the latitude and longitude
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Create a LatLng object with the user's position
            this.mapCenter = { lat: latitude, lng: longitude };
          },
          (error) => {
            // Handle geolocation error
            console.error(`Error getting user's location: `, error);

            this.fit = 'markers';
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');

        this.fit = 'markers';
      }
    }

    if (this.fit === 'markers') {
      if (this.markers.length > 1)
        this.map.fitBounds(
          this.markers.reduce(
            (bound, marker) => bound.extend({ lat: marker.latitude, lng: marker.longitude }),
            new google.maps.LatLngBounds()
          )
        );
      else if (this.markers.length === 1)
        this.mapCenter = { lat: this.markers[0].latitude, lng: this.markers[0].longitude };
    }

    if (this.fit === 'route' && this.markersRoute.length > 0) {
      this.map.fitBounds(
        this.markersRoute.reduce(
          (bound, marker) => bound.extend({ lat: marker.latitude, lng: marker.longitude }),
          new google.maps.LatLngBounds()
        )
      );
    }
  }

  public ngOnInit(): void {
    if (this.dataGroupItem) {
      const setMarkers = () => {
        const res = this.dataGroupItem.dataTable[0].data
          .filter(item => item.longitude && item.latitude)
          .map(item => ({ ...item, icon: { ...svgMarker, fillColor: item.color } }));

        // set fit
        this.fit = (this.dataGroupItem.fit as typeof this.fit) ?? this.fit;

        if (this.dataGroupItem.fit === 'route' && res.length < 2)
          this.fit = 'markers';


        // set data
        if (this.dataGroupItem.fit === 'route' && res.length > 1) {
          this.route = res;
        } else
          this.markers = res;
      };

      const itemsMissingLonLat = this.dataGroupItem.dataTable[0].data
        .filter(item => !(item.longitude && item.latitude));

      const promises = itemsMissingLonLat
        .map(item => this.geocoder.geocode({
          address: `${item.street} ${item.no} ${item.zip} ${item.place} ${item.country}`,
        }, (results, status) => {
          if (results?.length) {
            const { geometry: { location } } = results[0];

            item.longitude = location.lng();
            item.latitude = location.lat();
          }
        }));

      if (promises.length > 0) {
        Promise.allSettled(promises).then(() => {
          setMarkers();
          this.fitView();
        });
      } else {
        setMarkers();
        this.fitView();
      }
    }
  }

  public ngAfterViewInit() {
  }


  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  trackByFn(index, item) {
    return index;
  }
}
