import { ISvgElement } from "../svg-draw/svg-draw.component";
import { SvgInhakerDesignToolComponent } from "../svg-inhaker-design-tool/svg-inhaker-design-tool.component";

export abstract class DiagnosticsProvider {
  public drawable: DiagnosticElement[] = [];
  public info: string[] = [];
  public warn: string[] = [];

  public abstract diagnose(service: SvgInhakerDesignToolComponent): void;

  /** Shorthand for clearing out all diagnostic info real quick. */
  public clear() {
    this.drawable = [];
    this.info = [];
    this.warn = [];
  }

  protected findDrawn<T extends DiagnosticElement = DiagnosticElement>(ident: string): undefined | T {
    return this.drawable.find(drawable => drawable.ident === ident) as undefined | T;
  }

  protected findOrDefault<T extends DiagnosticElement>(ident: string, defaultVal: T): T {
    let val = this.findDrawn<T>(ident);

    if (!val) {
      val = defaultVal;

      this.drawable.push(val);
    }

    return val;
  }
}

/** Base of all displayed diagnostics. Ignored interactions, cause they're not intended for interaction, just display. */
export abstract class DiagnosticElement implements ISvgElement {
  public ident: string;

  public x = 0;
  public y = 0;

  public drag = 0;

  constructor(ident: string) {
    this.ident = ident;
  }
}

export class DiagnosticVector extends DiagnosticElement {
  public x2: number = 0;
  public y2: number = 0;

  public fill: string = '#fff';

  public constructor(ident: string, fill: string) {
    super(ident);

    this.fill = fill;
  }

  public update(x1: number, y1: number, x2: number, y2: number): void {
    this.x = x1;
    this.y = y1;
    this.x2 = x2;
    this.y2 = y2;
  }

  public reset(): void {
    this.x = 0;
    this.y = 0;
    this.x2 = 0;
    this.y2 = 0;
  }
}

export class DiagnosticForceVector extends DiagnosticElement {
  public xLen: number = 0;
  public yLen: number = 0;

  public fill: string = '#fff';


  public constructor(ident: string, fill: string) {
    super(ident);

    this.fill = fill;
  }

  public update(x: number, y: number, xLen: number, yLen: number): void {
    this.x = x;
    this.y = y;
    this.xLen = xLen;
    this.yLen = yLen;
  }

  public reset(): void {
    this.x = 0;
    this.y = 0;
    this.xLen = 0;
    this.yLen = 0;
  }

}
