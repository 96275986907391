import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, ViewChildren, ElementRef, QueryList, ViewChild, Input, HostListener } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';
import { EventParams, Param, WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-data-cards',
  templateUrl: './data-cards.component.html',
  styleUrls: ['./data-cards.component.css']
})
export class DataCardsComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public cardSelected: boolean;
  public showViewer: boolean = false;
  public cols: number;
  public index: number;

  public layout = {
    dataListHeight: 0,
    dataRowHeight: 0,
  };

  public JSON = JSON;

  @Input() mode: string = 'grid';
  @Input() cardHeight: number = 300;
  @Input() cardWidth: number = 300;

  @ViewChild('dataList', { static: false }) dataList: ElementRef | undefined;
  @ViewChild('gridFooter', { static: false }) gridFooter: ElementRef | undefined;

  @HostListener('window:resize')
  onResize() {
    if (this.dataItems && this.gridFooter?.nativeElement && this.dataList?.nativeElement) {
      const dataItems = this.dataItems.toArray();
      const cols = [... new Set(dataItems.map(dataItem => dataItem.nativeElement.getBoundingClientRect().left))].length;
      const rows = dataItems.filter(dataItem => dataItem.nativeElement.getBoundingClientRect().left === dataItems[0].nativeElement.getBoundingClientRect().left).length;
      const height = (cols && rows > 1) ? dataItems[cols].nativeElement.getBoundingClientRect().top - dataItems[0].nativeElement.getBoundingClientRect().top : 0;

      const dataListHeight = this.gridFooter?.nativeElement.getBoundingClientRect().top - this.dataList?.nativeElement.getBoundingClientRect().top;

      if (dataListHeight !== this.layout.dataListHeight && this.dataGroupItem.page !== -1) {
       this.layout.dataListHeight = dataListHeight;

        if (this.layout.dataRowHeight === 0 && height) {
          this.layout.dataRowHeight = height;
        }

        if (this.layout.dataListHeight && this.layout.dataRowHeight) {
          // TODO - reimplement this but correct. Current approach unloads the entire list.
          // this.dataGroupItem.page = Math.floor(this.layout.dataListHeight / this.layout.dataRowHeight ?? 40) * cols;
          this.setCurrentPage();
        }
      }
    }
  }

  initResize() {
    this.layout.dataListHeight = 0;
    this.layout.dataRowHeight = 0;

    setTimeout(() => {
      this.onResize();
    });
  }

  ngOnInit() {
    if (this.dataGroupItem.card) {
      this.cardHeight = this.dataGroupItem.card.height;
      this.cardWidth = this.dataGroupItem.card.width;

      if (this.dataGroupItem.card.upload) {
        this.dataGroupItem.card.upload.fileUid.params.forEach(fileUidParam => {
          if (this.dataGroupItem.params.some(param => param.key === fileUidParam.key)) {
            fileUidParam.val = this.dataGroupItem.params.find(param => param.key === fileUidParam.key).val;
          }
        });
      }
    }

    super.ngOnInit();

    !this.communicationService.widget.find(widget => widget.widgetId === this.widgetId)
      ?.subscribeTo.some(subscribeTo => subscribeTo.func === 'initResize') &&
      this.communicationService.initWidget({
        widgetId: this.widgetId,
        component: this.component,
        state: WidgetState.OK,
        subscribeTo: [
          {
            event: WidgetEvent.REFRESHED,
            func: 'initResize',
          }
        ]
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  showImgViewer(params: Param[], dataItem?, data?) {
    this.index = params[0].val;
    this.showViewer = true;
  }

  closeImgViewer(event) {
    this.showViewer = false;
  }

  close(event) {
    if (this.dataGroupItem.dataTable) {
      this.dataGroupItem.dataTable[0].data.forEach(row => row.edit = false);
      this.edit.mode = false;
    }
  }
}
