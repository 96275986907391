<app-popup-window *ngIf="loaded"
  [window]="{ title: 'Translate & edit', dataLength: editViewData.length, index: rowIndex, nav: true, add: false, modal: true, closeOnOutsideClick: false }"
  (onCancelWindow)="close($event)"
  (onPrevious)="previous($event)"
  (onNext)="next($event)">

  <div class="row">
    <div class="offset-6 col-6 mb-3">
      <app-data-group [src]="'filterLanguage'" [widgetId]="'filterLanguage'" [dataGroup]="filterGroup"></app-data-group>
    </div>
  </div>

  <div class="form-content">
    <ng-container *ngIf="languages.length">
      <ng-container *ngFor="let row of editViewData; let index = index; trackBy: trackByFn">
        <ng-container *ngIf="index === (rowIndex > editViewData.length - 1 ? editViewData.length - 1 : rowIndex)">
          <ng-container *ngTemplateOutlet="childContentTemplate; context: { row: row }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <app-cmd
    [className]="'cmd-form'"
    [component]="component"
    [widgetId]="widgetId"
    [nav]="nav"
    [navList]="['menu']"
    [navVisible]="3">
  </app-cmd>
</app-popup-window>

<ng-template #childContentTemplate let-row="row">
  <ng-container *ngIf="row.children?.length">
    <ng-container *ngFor="let child of row.children; let childIndex = index; trackBy: trackByFn">
      <ng-container *ngIf="child.children?.length">
        <ng-container *ngTemplateOutlet="childContentTemplate; context: { row: child }"></ng-container>
        <ng-container *ngTemplateOutlet="contentTemplate; context: { row: child }"></ng-container>
      </ng-container>
      <ng-container *ngIf="!child.children?.length">
        <ng-container *ngTemplateOutlet="contentTemplate; context: { row: child }"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!row.children?.length">
    <ng-container *ngTemplateOutlet="contentTemplate; context: { row: row }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #contentTemplate let-row="row">
  <ng-container *ngFor="let item of row.content; trackBy: trackByFn">
    <ng-container *ngFor="let field of ['title', 'text', 'path']; trackBy: trackByFn">
      <div class="row" *ngIf="item[field]">
        <div class="col-6" [ngClass]="field">
          <div class="form-group">
            <label></label><br />
            <textarea class="form-control" style="width: 100%;" rows="6" readonly [(ngModel)]="item[field]"></textarea><br />
          </div>
        </div>
        <div class="col-6">
          <ng-container *ngFor="let language of languages; trackBy: trackByFn">
            <div class="form-group">
              <label class="data-cell-label">{{ language.content.text }}</label>
              <textarea (blur)="checkInput(item)" class="form-control" style="width: 100%;" rows="6" [(ngModel)]="item.ml[language.id][field]"></textarea><br />
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
