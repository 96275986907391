@if (loaded && configuratorReady && productJSON?.rows) {
  @if(configuratorService.configurator.state.configuratorEditor) {
    <app-popup-window
      [widgetId]="widgetId + '_editor'"
      [window]="{
        title: 'Opties',
        info: '(shift)tab = inspringen, enter = nieuwe regel, ctrl + enter = opslaan',
        className: '',
        modal: false,
        closeOnOutsideClick: false,
        top: 0.8,
        right: 0.8,
        width: 42,
        height: 60,
        zIndex: 1011,
        sidebar: true
      }"
      (onCancelWindow)="cancelWindow($event)">
      <div class="xfw3-grid-row-container">
        <div class="xfw3-grid-row">
          <app-cmd [component]="component" [nav]="editNav" [navList]="['menu']" [navVisible]="3"/>
        </div>
        <div class="xfw3-grid-row">
          @switch (editTab) {
            @case ('configuratorInput') {
              <app-configurator-input [dataGroupItemInstance]="configuratorRowsDataGroupItemInstance" [product]="productJSON" />
            }
            @case ('conditionalCodes') {
              <app-conditional-codes [dataGroupItemInstance]="configuratorRowsDataGroupItemInstance" [product]="productJSON" />
            }
            @case ('link') {
              <app-edit-link />
            }
          }
        </div>
      </div>

    </app-popup-window>
  }


  <div  cdkDropList
        [id]="configuratorRowsDataGroupItemInstance.widgetId + '-cards-group'"
        [cdkDropListDisabled]="dragDropService.noDrag"
        (cdkDropListDropped)="dropRow($event)"
        [cdkDropListData]="productJSON.rows">

      @for (row of productJSON.rows; let rowIndex = $index; track rowIndex) {
        <div cdkDrag
             cdkDragPreviewContainer="parent"
             [cdkDragDisabled]="dragDropService.noDrag"
             [cdkDragData]="row"
             cdkDragLockAxis="y"
             class="drag-row">
          <app-edit-row
            [dataGroupItemInstance]="editDataGroupItemInstance"
            [product]="productJSON"
            [row]="row"
            [rowIndex]="rowIndex"
            [state]="state"
            (onSetEditMode)="editMode($event)"/>
        </div>
      }
    </div>
    <div class="row data-row px-2 pt-2">
      <app-cmd [component]="component" [nav]="addRowNav" [navList]="['primary']" [navVisible]="1"/>
    </div>
  }

  <app-data-generic-footer
    [widgetId]="widgetId"
    [component]="component"
    [dataGroupItemInstance]="editDataGroupItemInstance"/>

  @if (configuratorRowsDataGroupItemInstance?.edit.type === 'form' && edit.mode) {
    <app-popup-form
      [widgetId]="configuratorRowsDataGroupItemInstance.widgetId"
      [dataGroupItemInstance]="configuratorRowsDataGroupItemInstance"
      [edit]="edit"
      [rowIndex]="index"
      (onCloseWindow)="close($event)"
      [window]="{
        nav: true,
        add: true,
        modal: true,
        closeOnOutsideClick: false}"
      (onAddRow)="addNewRow({
        params: [],
        dataItem: null,
        data: null
      })"
      [navList]="['primary']"/>
  }
