<div class="row-wrapper editor" [ngClass]="[row.state.isSelected ? 'selected' : '']">
    <div [ngClass]="['xfw3-grid-col-container', row.state.expanded ? 'expanded' : 'colapsed']"
         divBreakpointClass
         (click)="toggle(row, rowIndex, $event);"
         [id]="[row.code, rowIndex].join('_')">

      <div class="option-area">
        <div class="icon" cdkDragHandle>
          <i class="fa-solid fa-grip-lines"></i>
        </div>
        <h5>
          {{ row.block.name }}
        </h5>
      </div>

      <div class="cmd-area">
        <app-cmd
          [component]="component"
          [dataItem]="row"
          [nav]="nav"
          [navList]="['menu']"
          dropdownClassName="icon xxs more"
          [navVisible]="6"/>
      </div>
    </div>

    <div class="row" [hidden]="!row.state.expanded">
      <div class="col-12">
        @if (row.type !== 'dataGroup') {
          <div [ngClass]="['xfw3-cards', 'editor', edit.reorder ? 'reorder' : '', row.imgAspectRatio ?? '']"
               cdkDropList
               [id]="configuratorRowOptionDataGroupItemInstance.widgetId + '-cards-group'"
               [cdkDropListDisabled]="dragDropService.noDrag"
               (cdkDropListDropped)="dropOption($event)"
               [cdkDropListData]="row.options">
            @for (option of row.options; let index = $index; track option) {
              @if (!edit.reorder) {
                <app-edit-option
                  [dataGroupItemInstance]="dataGroupItemInstance"
                  [product]="product"
                  [row]="row"
                  [rowIndex]="rowIndex"
                  [index]="index"
                  [option]="option"
                  [code]="option.code"
                  [state]="state"
                  [uploadType]="row.imgAspectRatio === 'no-img' ? 'no-img' : edit.uploadType"
                  (onSetEditMode)="editMode($event)"/>
              }@else {
                <div cdkDrag
                  [cdkDragDisabled]="dragDropService.noDrag"
                  cdkDragPreviewContainer="parent"
                  [cdkDragData]="option"
                  cdkDragLockAxis="y"
                  class="drag-option">

                  <div class="icon" cdkDragHandle>
                    <i class="fa-solid fa-grip-lines"></i>
                  </div>
                  <div class="title">
                    {{ option.block.name}}
                  </div>
                  <div class="order">
                    {{ index + 1 }}
                  </div>
                </div>
              }
            }
            <div class="d-flex flex-column justify-content-end">
              <app-cmd [component]="component" [nav]="addOptionNav" [navList]="['primary']" [navVisible]="1"/>
            </div>
          </div>
        }
      </div>
      @if (next && row.type !== 'dataGroup') {
        <div class="configurator-row-footer">
          <app-cmd
            [nav]="dataGroupItemInstance.rowOptions.nav"
            [navList]="['primary']"
            [navVisible]="dataGroupItemInstance.rowOptions.navItems"
            [className]=""
            [component]="component"
            [dataItem]="row"/>
        </div>
      }
    </div>
  </div>

  @if (configuratorRowOptionDataGroupItemInstance?.edit.type === 'form' && edit.mode) {
    <app-popup-form
      [widgetId]="configuratorRowOptionDataGroupItemInstance.widgetId"
      [dataGroupItemInstance]="configuratorRowOptionDataGroupItemInstance"
      [edit]="edit"
      [rowIndex]="edit.index"
      (onCloseWindow)="close($event)"
      [window]="{
        nav: true,
        add: true,
        modal: true,
        closeOnOutsideClick: false}"
      (onAddRow)="addNewOption({
        params: [],
        dataItem: null,
        data: null
      })"
      [navList]="['primary']"/>
  }
