import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exclude'
})
export class ExcludePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value.filter((item: any) => !args[1].includes(item[args[0]]));
  }
}
