import { Component } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { DomainConfigService } from '../services/domain-config.service';

@Component({
  selector: 'app-debug-info',
  templateUrl: './debug-info.component.html',
  styleUrls: ['./debug-info.component.scss']
})
export class DebugInfoComponent {
  showLog = false;
  level = 172;

  public roleList: string;

  public hideSideMenu: boolean = false;
  public hideTopMenu: boolean = false;

  public domainConfig: any = {};

  constructor(public communicationService: CommunicationService,
              public domainConfigService: DomainConfigService) {
  }


  ngOnInit(): void {
    const authBitMask = this.communicationService.user.authBitMask;
    console.log(this.domainConfigService.domainConfig.roles);
    const roles: string[] = [];

    Object.keys(this.domainConfigService.domainConfig.roles).forEach(key => {
      if ((this.domainConfigService.domainConfig.roles[key] & authBitMask) > 0) {
        roles.push(key);
      }
    });

    this.roleList = roles.join(', ');
  }

  ngOnDestroy(): void {

  }
}
