import { Directive, HostBinding, HostListener, Input, ElementRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

/**
 * ## Mask directive
 * The directive visualizes and enforces a format on user input. The format is based on the {@linkcode appMask} provided.
 *
 * Disallowed characters are automatically removed from the input.
 *
 * A mask can consist of the following characters: `A`, `a`, `0`, `/`, `(`, `)`, `.`, `:`, `space`, `+`, `-`, `,`, `$`, `€`, `£` `%`, `@`, `#`
 *
 * ### Examples
 * Postal code: `0000 AA`
 * matches alike `9876 CD` or `5431 BT`
 *
 * Phone number mask: `+00 000 00 000`
 * matches alike `+11 111 11 111`
 *
 * @example Input with literal string as mask
 * ```html
 * <input [appMask]="'0000 AA'"/>
 * ```
 *
 * @example Input with character array as mask
 * ```html
 * <input [appMask]="[{char: '0'},{char: '0'},{char: '0'},{char: '0'},{char: ' '},{char: 'A'},{char: 'A'}]"/>
 * ```
 */
@Directive({
  selector: '[appMask]',
})
export class MaskDirective implements ControlValueAccessor {

  private cursorPos = 0;
  private mask: MaskCharPreprocessed[];
  private maxCharPos = 0;

  constructor(private elementRef: ElementRef) { }

  @HostBinding('disabled') isDisabled: boolean = false;

  @Input() set appMask(mask: MaskChar[] | string) {
    if (!mask) return;

    if (typeof mask === 'string')
      mask = mask.split('').map(c => ({ char: c }));

    try {
      if (mask.length === 0) return;

      this.mask = mask.map((maskChar, index) => {
        if (maskChar.char.length !== 1)
          throw new Error('MaskChar must be a single character');

        const maskCharPreprocessed: MaskCharPreprocessed = {
          char: maskChar.char,
          isStatic: maskChar.isStatic,
          charVisualPos: this.setVisualCharPos(maskChar),
        };

        return maskCharPreprocessed;
      });
    } catch (e) {
      console.log('mask is invalid', mask, e);
    }
  }

  setVisualCharPos(maskChar: MaskChar): number {
    this.maxCharPos++;

    if (maskChar.isStatic) return -1;

    // If char is special character that should be typed for them
    if (
      /^[\s\d\/\(\).:\+\-\_,$€£%@#]*$/g.test(maskChar.char) &&
      isNaN(parseInt(maskChar.char))
    ) {
      return -1;
    }

    return this.maxCharPos - 1;
  }

  forceCursorPos(event): void {
    const element = event.target as HTMLInputElement;

    setTimeout(() => {
      // if cursor is at the beginning
      if (element.value.length === 0) {
        this.mask.find((maskChar, index) => {
          if (maskChar.charVisualPos === -1) {
            element.value += maskChar.char;

            this.cursorPos = index + 1;
            element.setSelectionRange(this.cursorPos, this.cursorPos);

            return false;
          } else return true;
        });
      }

      // remove all text after cursor
      element.value = element.value.substring(0, this.cursorPos);

      // if next char is static and place all static characters
      if (this.mask[this.cursorPos])
        while (this.mask[this.cursorPos].charVisualPos === -1) {
          event.target.value += this.mask[this.cursorPos].char;
          this.cursorPos++;
        }

      // add rest of mask after cursor
      this.mask.forEach((maskChar, index) => {
        if (index < this.cursorPos) {
          return;
        } else {
          if (maskChar.charVisualPos !== -1) element.value += '_';
          else element.value += maskChar.char;
        }
      });

      // set cursor pos
      element.setSelectionRange(this.cursorPos, this.cursorPos);

    });
  }

  @HostListener('keydown', ['$event']) keydownHandler(event) {
    if (this.mask)
      this.handleKeydown(event);
  }

  @HostListener('click', ['$event']) clickHandler(event) {
    if (this.mask)
      this.forceCursorPos(event);
  }

  @HostListener('focus', ['$event']) focusHandler(event) {
    if (this.mask)
      this.forceCursorPos(event);
  }

  private tempErrorBorder(element: HTMLElement): void {
    if (element.style.borderColor !== 'var(--bs-danger)') {
      const oldStyleBorder = element.style.borderColor;
      const oldStyleShadow = element.style.borderColor;

      element.style.borderColor = 'var(--bs-danger)';
      element.style.boxShadow = '0 0 0 0.25rem var(--bs-danger)';

      setTimeout(() => {
        element.style.borderColor = oldStyleBorder;
        element.style.boxShadow = oldStyleShadow;
      }, 1000);
    }
  }

  private handleKeydown(event: KeyboardEvent): void {
    if (event.code === 'Tab' || event.code === 'F5' || (event.code === 'KeyR' && event.ctrlKey) || (event.code === 'KeyR' && event.metaKey))
      return; // Keep default behavior

    event.preventDefault();

    // Handle keys
    if (event.code === 'Backspace') {

      // move cursor back until there are no special characters after initial cursor position decrement
      do {
        this.cursorPos--;
      } while (this.mask[this.cursorPos] && this.mask[this.cursorPos].charVisualPos === -1);

      // prevent cursor going negative.
      if (this.cursorPos < 1)
        this.cursorPos = 0;

      setTimeout(() => this.forceCursorPos(event));
    } else if (event.code === 'Delete' || event.code.indexOf('Arrow') === 0 || this.cursorPos === this.mask.length || event.key === ' ') {

      // Just cancel these keys

    } else {

      const char = event.key;
      const index = this.cursorPos;
      const element = event.target as HTMLInputElement;

      // if char is somehow longer than 1 char then do nothing
      if (char.length !== 1) return;

      if (this.mask[index].char === 'a') {
        // check if character is a letter
        const regex: RegExp = /^[a-zA-Z]$/g;

        if (!regex.test(char)) {
          this.tempErrorBorder(element);
          return;
        } else {
          element.value = element.value.substring(0, this.cursorPos) + char;

          this.cursorPos++;
          this.forceCursorPos(event);
        }
      } else if (this.mask[index].char === 'A') {
        // check if character is a letter
        const regex: RegExp = /^[a-zA-Z]$/g;

        if (!regex.test(char)) {
          this.tempErrorBorder(element);
          return;
        } else {
          // toUppercase this character
          element.value = element.value.substring(0, this.cursorPos) + char.toUpperCase();

          this.cursorPos++;
          this.forceCursorPos(event);
        }
      } else if (this.mask[index].char === '0') {
        event.preventDefault();
        // check if character is not a number
        if (isNaN(parseInt(char))) {
          this.tempErrorBorder(element);
          return;
        } else {
          element.value = element.value.substring(0, this.cursorPos) + char;

          this.cursorPos++;
          this.forceCursorPos(event);
        }
      }
    }
  }

  /**
   *  ControlValueAccessor interface implementation
   */

  onChange: (data: Array<Date>) => void;
  onTouched: () => void;

  writeValue(obj: any): void {
    let str: string = typeof obj === 'string' ? obj : obj.toString();

    // TODO think of compact code to check the validity of the string

    this.elementRef.nativeElement.value = str;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

interface MaskChar {
  char: string;
  isStatic?: boolean;
}

interface MaskCharPreprocessed extends MaskChar {
  charVisualPos: number;
}
