import { SvgInhakerDesignToolComponent } from "../svg-inhaker-design-tool/svg-inhaker-design-tool.component";
import { DiagnosticForceVector, DiagnosticsProvider, DiagnosticVector } from "./diagnostics";
import { Inhaker, WeightObject } from "./world";

const gravityAccel = -9.81;
const rotationColor = 'gray';

export class FootScrewCalculation extends DiagnosticsProvider {


  public diagnose(world: SvgInhakerDesignToolComponent): void {
    const inhakers = world.find(Inhaker);
    const weights = world
      .find(WeightObject);

    this.clear();

    for (const inhaker of inhakers)
      this.executeForSingleInhaker(inhaker, weights, world);

  }

  private executeForSingleInhaker(inhaker: Inhaker, weights: WeightObject[], world: SvgInhakerDesignToolComponent) {
    const ihIdx = world.objects.findIndex(v => v === inhaker);

    const attachments = weights
      .filter(obj => obj.inhakers.includes(ihIdx) && obj.inhakers.filter(v => v !== undefined).length === 1);

    if (attachments.length == 0) return; // Nothing to calculate on.

    const poleCenterX = inhaker.x,
      poleCenterY = inhaker.y + inhaker.footHeight + inhaker.poleHeight * 0.5,
      poleWeight = inhaker.poleWeight;

    // Accumulate centers of mass to determine point at which weight is "applied"
    let xAcc = poleCenterX * poleWeight,
      yAcc = poleCenterY * poleWeight,
      mass = poleWeight;

    attachments.forEach((curr) => {
      const currMass = curr.mass;
      xAcc += (curr.x + curr.comX) * currMass;
      yAcc += (curr.y + curr.comY) * currMass;
      mass += currMass;

      this.updateWeightObjectDisplay(world, curr, inhaker);
    });

    // Calculate centre of mass of everything attached to the screw (inhaker pole & all attachments)
    const comX = xAcc / mass,
      comY = yAcc / mass;

    const totalComX = (comX * mass + inhaker.x * inhaker.footWeight) / (mass + inhaker.footWeight);
    const inhakerFootBase = inhaker.x + inhaker.footOffsetX;
    if (totalComX > inhakerFootBase + inhaker.footWidth * 0.5 || inhakerFootBase - inhaker.footWidth * 0.5 > totalComX)
      this.warn.push(`inhaker.topple:${inhaker.ident}`);

    const calc = this.calculateWeightOnRotationalAxis(world, inhaker, comX, comY, mass);

    if (!calc)
      return; // No turning point found, so nothing to calculate.

    const { projectMassOnRotationMultiplier, weightOnRotationalAxisX, weightOnRotationalAxisY, armX, armY, massY, turningPointOffsetX, turningPointOffsetY } = calc;


    const rotationalForce = (weightOnRotationalAxisX ** 2 + weightOnRotationalAxisY ** 2) ** 0.5;
    const rotArmLength = (armX ** 2 + armY ** 2) ** 0.5;

    const torque = rotationalForce * rotArmLength;

    // Calculate rotational pull on screw
    let rotAxX = (turningPointOffsetY - inhaker.footHeight),
      rotAxY = -turningPointOffsetX;

    const unitVectorMult = (rotAxX ** 2 + rotAxY ** 2) ** 0.5;
    const screwPullForce = torque / unitVectorMult * Math.sign(projectMassOnRotationMultiplier);

    const screwPullX = rotAxX / unitVectorMult * screwPullForce,
      screwPullY = rotAxY / unitVectorMult * screwPullForce;

    const projectMassOnArmMultiplier = armY * massY / (armX ** 2 + armY ** 2);

    const weightOnArmAxisX = armX * projectMassOnArmMultiplier,
      weightOnArmAxisY = armY * projectMassOnArmMultiplier;

    // Calculate net force on foot connector point
    const netX = weightOnArmAxisX + screwPullX,
      netY = Math.max(weightOnArmAxisY + screwPullY, 0); // Downwards force just pushes onto the foot plate.

    const netForce = (netX ** 2 + netY ** 2) ** 0.5;

    if (netForce > 5000) //TODO determine breaking point
    {
      this.warn.push(`inhaker.foot-connector-break:${inhaker.ident}`);
    }
  }

  /** Calculate the projection of an object's mass onto the rotational axis around the inhaker's turning point.
   * @returns undefined if there is no turning point.
  */
  private calculateWeightOnRotationalAxis(world: SvgInhakerDesignToolComponent, inhaker: Inhaker, comX: number, comY: number, mass: number) {
    let turningPointOffsetX = 0;
    let turningPointOffsetY = inhaker.footHeight;

    if (comX > inhaker.x + inhaker.poleWidth * 0.5) {// Center of mass is causing rotation in the direction of right bench
      turningPointOffsetX = inhaker.poleWidth * 0.5;
      turningPointOffsetY += inhaker.footHeight + (inhaker.getRightBench(world)?.height || 0);
    } else if (comX < inhaker.x - inhaker.poleWidth * 0.5) { // Center of mass is causing rotation in the direction of left bench
      turningPointOffsetX = -inhaker.poleWidth * 0.5;
      turningPointOffsetY += inhaker.footHeight + (inhaker.getLeftBench(world)?.height || 0);
    } else return; // No rotation when weight falls within the inhaker pole.

    if (comY < inhaker.y + turningPointOffsetY) return; // if COM is underneath bench, the lower half of a pole is pushing against the pole; no turning is happening

    // Calculate pole corner coordinates
    const turningPointX = inhaker.x + turningPointOffsetX,
      turningPointY = inhaker.y + inhaker.footHeight + turningPointOffsetY;

    // Calculate the arm of rotation
    const armX = comX - turningPointX,
      armY = comY - turningPointY;

    // Perpendicular to the arm of rotation
    const rotationalAxisX = -armY,
      rotationalAxisY = armX;

    // Mass as force vector, straight down.
    const massY = mass * gravityAccel;

    // Projection onto rotational axis.
    const projectMassOnRotationMultiplier = rotationalAxisY * massY / ((rotationalAxisX ** 2 + rotationalAxisY ** 2) * inhaker.reinforcement);

    const weightOnRotationalAxisX = rotationalAxisX * projectMassOnRotationMultiplier,
      weightOnRotationalAxisY = rotationalAxisY * projectMassOnRotationMultiplier;

    return {
      turningPointX,
      turningPointY,

      turningPointOffsetX,
      turningPointOffsetY,

      armX,
      armY,

      massY,

      projectMassOnRotationMultiplier,

      weightOnRotationalAxisX,
      weightOnRotationalAxisY,
    };
  }

  private updateWeightObjectDisplay(world: SvgInhakerDesignToolComponent, curr: WeightObject, inhaker: Inhaker) {
    const calc = this.calculateWeightOnRotationalAxis(world, inhaker, curr.comX + curr.x, curr.comY + curr.y, curr.mass);

    const idx = curr.ident;
    if (calc) { // Set coordinates of displayed vectors.
      const { turningPointX, turningPointY, weightOnRotationalAxisX, weightOnRotationalAxisY } = calc;

      this.findOrDefault(`rotArm${idx}`, new DiagnosticForceVector(
        `rotArm${idx}`,
        rotationColor
      )).update(
        curr.comX + curr.x,
        curr.comY + curr.y,
        weightOnRotationalAxisX,
        weightOnRotationalAxisY
      );

      this.findOrDefault(`rotAxis${idx}`, new DiagnosticVector(
        `rotAxis${idx}`,
        rotationColor
      )).update(
        curr.comX + curr.x,
        curr.comY + curr.y,
        turningPointX,
        turningPointY
      );

    } else { // Reset displayed vectors.
      this.findDrawn<DiagnosticVector>(`rotArm${idx}`)?.reset();
      this.findDrawn<DiagnosticVector>(`rotAxis${idx}`)?.reset();
    }
  }

}
