@if (row.state.expanded) {
    <app-data-card
      #dataItem
      [index]="index"
      [item]="option"
      [component]="component"
      [dataGroupItemInstance]="dataGroupItemInstance"
      (onSelect)="select(row, $event)"
      [className]="dataGroupItemInstance.rowOptions.className"
      [uploadType]="uploadType"/>
  }
  