@if (dataGroupItemInstance.dataTable[0]?.data) {
  <div class="component-container">
    @for (item of dataGroupItemInstance.dataTable[0].data; let rowIndex = $index; track item) {
      <div class="component-list">
        <div class="component-header">
          <div class="data-row">
            <div class="content">
              <div class="content-header">
                <h4>{{ item.component }} {{item.title}}</h4>
              </div>
              <div class="content-body">
                @for (optionInfo of item.configurationJSON.products[0].options; let optionIndex = $index; let first = $first; let last = $last; track optionIndex) {
                  <div class="question-answer-wrapper">
                    @if ((!first && item.configurationJSON.products[0].options[optionIndex - 1].info.question !== optionInfo.info.question) || first) {
                      <span class="question">{{ optionInfo.info.question }}</span>:&nbsp;
                    }@else {
                      <span>-&nbsp;</span>
                    }
                    <span class="answer">{{ optionInfo.info.answer }}</span>
                  </div>
                }
              </div>
            </div>
            <div class="content-price">
              <div class="price">
                <h3>{{item.priceJSON.price - item.priceJSON.discount || 0 | number: '1.2-2'}}</h3>
              </div>
              @if (item.priceJSON.discount !== 0) {
                <div class="advice">
                  {{item.priceJSON.originalPrice | number: '1.2-2' }}
                </div>
              }
            </div>
          </div>
          @if (!item.collapsed) {
            <div class="content-footer">
              <div class="row-nav-5 justify-self-end">
                  <app-cmd [component]="component" [dataItem]="item" [nav]="nav" [navList]="['primary','secondary','cancel', 'menu']" [navVisible]="0"/>
              </div>
            </div>
          }
        </div>
        @if (!item.collapsed) {
          <div class="component-body">
            @if (item.type?.type !== 'accessory' && dataGroupItemInstance.layout === 'list') {
              <div [ngClass]="['data-list-wrapper', dataGroupItemInstance.dataClassName || '' ]">
                <app-data-list
                  [widgetId]="dataGroupItemInstance.children[0].widgetId + '_' + (item | primaryKey : dataGroupItemInstance.dataTable[0].primaryKeys)"
                  [dataGroupItem]="dataGroupItemInstance.children[0]"
                  [dataItem]="item"
                  (hasData)="onHasData($event, dataGroupItemInstance.children[0])"/>
              </div>
            }
            <div class="job-component-tabstrip" #componentTabstrip>
              <app-nav
                [widgetId]="navJSON.widgetId + '_' + (item | primaryKey : dataGroupItemInstance.dataTable[0].primaryKeys)"
                [dataItem]="item"
                [src]="navJSON.src"/>
            </div>
          </div>
        }

        <div [ngClass]="['component-footer', item.collapsed ? 'collapsed' : 'expanded']"
             (click)="$event.stopPropagation(); collExp({ dataItem: item })">
          <span [ngClass]="['fa-regular', item.collapsed ? 'fa-chevron-down' : 'fa-chevron-up']"></span>
        </div>
      </div>
    }
  </div>
}
