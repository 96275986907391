import { Component, Input } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { DataService } from 'src/app/services/data.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'xfwPopOver',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {
  @Input() config: any;

  public blocks: any[] = [];
  public queryParams: Param[];
  public user: any;

  constructor(
    private dataService: DataService,
    private communicationService: CommunicationService
    ) {
      this.user = this.communicationService.user;
    }

  ngOnInit() {
    if (this.config?.path) {
      this.dataService
      .getBlocks(this.config?.path, this.user.language, this.config?.paramJSON)
      .then(
        (data: { blocks: any[]; environmentJSON: any; mlNavJSON: any }) => {
          this.blocks = data.blocks;
      });
    }
  }
}
