import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';
import { WidgetState, WidgetEvent, EventParams } from 'src/app/widgets';

@Component({
  selector: 'app-data-tree-view',
  templateUrl: './data-tree-view.component.html',
  styleUrls: ['../data-list/data-list.component.scss', './data-tree-view.component.scss']
})

export class DataTreeViewComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public dataView: any[];
  public collapsed: string[] = [];

  public allCollapsed = 1;

  public layout = {
    dataListHeight: 0,
    dataRowHeight: 0,
  };

  @ViewChild('dataList', { static: false }) dataList: ElementRef | undefined;
  @ViewChild('gridFooter', { static: false }) gridFooter: ElementRef | undefined;

  @HostListener('window:resize')
  onResize() {
    if (this.gridFooter?.nativeElement && this.dataList?.nativeElement) {
      const dataListHeight = this.gridFooter.nativeElement.getBoundingClientRect().top - this.dataList.nativeElement.getBoundingClientRect().top;

      if (dataListHeight !== this.layout.dataListHeight && this.dataGroupItemInstance.paging === 'virtual-scroll') {
        this.layout.dataListHeight = dataListHeight;

        if (this.layout.dataRowHeight === 0) {
          this.layout.dataRowHeight = this.dataItems?.toArray()[0]?.nativeElement.getBoundingClientRect().height;
        }

        this.dataGroupItemInstance.page = Math.ceil(this.layout.dataListHeight / (this.layout.dataRowHeight || 40));
        !this.dataGroupItemInstance.scroll && (this.dataGroupItemInstance.scroll = this.dataGroupItemInstance.page);

        console.log('this.dataGroupItemInstance', this.dataGroupItemInstance)

        this.setCurrentPage();
      }
    }
  }

  ngOnInit(): void {
    this.setFromParams(this.dataGroupItem, this.dataItem, this.widgetId);
    this.initWidget();

    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this.component,
      state: WidgetState.OK,
      subscribeTo: [
        {
          event: WidgetEvent.REFRESHED,
          func: 'initResize',
        },
        {
          widgetGroup: [this.widgetId + '_form'],
          event: WidgetEvent.CLOSE,
          func: 'close'
        }
      ]
    });

    this.loadDataTable().then(data => {
      if (this.dataGroupItemInstance.dataTable[0]?.data?.length) {
        this.dataView = this.dataGroupItemInstance.dataTable[0].data;

        // We slaan de normale init van de dataTable over, maar dan moeten wel de queryParams gezet worden.
        this.queryParams = this.communicationService.queryParams;

        // this.setSelected(this.queryParams);

        this.setQueryParams(this.communicationService.queryParams, true);

        setTimeout(() => {
          this.onResize();
        });
      }
    });
  }

  addNewRow(eventParams: EventParams) {
    const fullPathNode: string = '00000';
    let newDataItem = structuredClone(eventParams.dataItem);

    // in geval van meerdere foreign keys en/of primary keys even kijken hoe we dit doen?
    // dit stukje herschrijven, maar dit is al de derde plaats waar dit gebeurd, in data-generic, in data-group-cards en hier.

    newDataItem[this.dataGroupItemInstance.dataTable[0].foreignKeys[0].key] = eventParams.dataItem[this.dataGroupItemInstance.dataTable[0].primaryKeys[0].key];
    newDataItem[this.dataGroupItemInstance.dataTable[0].primaryKeys[0].key] = null;

    Object.keys(newDataItem).forEach(key => {
      if (!this.dataGroupItemInstance.dataTable[0].foreignKeys.some(fk => fk.key === key)) {
        newDataItem[key] = null;
      }
    });

    newDataItem = {...newDataItem, ...{
        crud: 'create',
        edit: ['edit','editRow'].includes(this.dataGroupItemInstance.edit.mode),
        fullPath: eventParams.dataItem.fullPath + '.' + fullPathNode,
        checked: false,
        collapsed: 0,
        isSelected: true,
        trackBy: this.dataGroupItemInstance.dataTable[0].data.length,
        level: eventParams.dataItem.level + 1,
      }
    };

    this.addRow({
      params: eventParams.params,
      dataItem: newDataItem,
      data: []
    });

    this.refreshCollapsed(null);

    if (['save', 'direct'].includes(this.dataGroupItemInstance.edit.updateMutations)) {
      this.setEditMode({
        params: [],
        dataItem: this.dataGroupItemInstance.dataTable[0].data.find(row => row.isSelected),
        data: []
      });
    }
  }

  collExp(item) {
    item.collapsed = (item.collapsed === 1 ? 2 : 1);

    item.collapsed === 2 ? this.collapsed.push(item.fullPath + '.') :
      this.collapsed = this.collapsed.filter(fullPathPart => fullPathPart !== item.fullPath + '.');

    this.refreshCollapsed(item);
  }

  refreshCollapsed(item) {
    this.dataView = this.dataGroupItemInstance.dataTable[0].data;

    this.collapsed.forEach(fullPathPart => {
      this.dataView = this.dataView.filter(row => row.fullPath.substring(0, fullPathPart.length) !== fullPathPart);
    });

    // deze is nodig omdat we een nieuwe array hebben gemaakt met het filter op de dataView.
    item && (this.dataView.find(row => row.fullPath === item.fullPath).collapsed = item.collapsed);
  }

  setAllCollapsed() {
    this.allCollapsed = (this.allCollapsed === 1 ? 2 : 1);

    if (this.allCollapsed === 2) {
      this.collapsed = this.dataGroupItemInstance.dataTable[0].data.filter(row => row.collapsed !== 0).map(row => row.fullPath + '.');
      this.dataGroupItemInstance.dataTable[0].data.filter(row => row.collapsed !== 0).forEach(row => row.collapsed = 2);
    } else {
      this.collapsed = [];
      this.dataGroupItemInstance.dataTable[0].data.filter(row => row.collapsed !== 0).forEach(row => row.collapsed = 1);
    }

    this.dataView = this.dataGroupItemInstance.dataTable[0].data;

    this.collapsed.forEach(fullPathPart => {
      this.dataView = this.dataView.filter(row => row.fullPath.substring(0, fullPathPart.length) !== fullPathPart);
    });
  }

  isActive(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  close(event) {
    if (this.dataGroupItemInstance.dataTable) {
      this.dataGroupItemInstance.dataTable[0].data.forEach(row => row.edit = false);
      // this.dataGroupItemInstance.dataTable[0].data = this.dataGroupItemInstance.dataTable[0].data.filter(row => !row.crud);
      this.refreshCollapsed(null);

      this.edit.mode = false;
    }
  }
}
