import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataGroupMatrixGenericComponent } from '../data-group-matrix-generic/data-group-matrix-generic.component';

@Component({
  selector: 'app-data-group-matrix-col-item',
  templateUrl: './data-group-matrix-col-item.component.html',
  styleUrls: ['./data-group-matrix-col-item.component.css']
})
export class DataGroupMatrixColItemComponent extends DataGroupMatrixGenericComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}
