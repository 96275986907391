import { Component, HostListener, NgZone, OnInit } from "@angular/core";
import { StyleManagerService } from "./services/style-manager.service";
import { AuthService } from "./services/auth.service";
import { DataService } from "./services/data.service";
import { CommunicationService } from "./services/communication.service";
import { DomainConfigService } from "./services/domain-config.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { Subscription, debounceTime, lastValueFrom } from "rxjs";
import { WidgetEvent } from "./widgets";
import { BatchUpdateService } from "./services/batch-update.service";
import { UserService } from "./services/user.service";
import { Socket } from "ngx-socket-io";
import { UtilsService } from "./services/utils.service";
import { NetworkService } from "./services/network.service";
import { ContentService } from "./services/content.service";
import { CustomFuncService } from "./services/custom-func.service";
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from "@angular/router";
import { Config } from "protractor";
import { ConfiguratorProboService } from "./services/configurator-probo.service";
import { SessionStorageService } from "./services/session-storage.service";


const scrollMemoryDuration = 5 * 60 * 1000;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  authenticated: boolean = false;
  domainConfig: any;

  public component = this;

  public gone = false;

  userSubscription: Subscription;
  routeSubscription: Subscription;
  queryParamsSubscription: Subscription;

  breakpointClass(event) {
    // console.log(event);
  }

  constructor(
    private dataService: DataService,
    private styleManagerService: StyleManagerService,
    private auth: AuthService,
    private domainConfigService: DomainConfigService,
    private batchUpdateService: BatchUpdateService,
    private userService: UserService,
    private utilsService: UtilsService,
    private networkService: NetworkService,
    private socket: Socket,
    public communicationService: CommunicationService,
    private configuratorProboService: ConfiguratorProboService,
    private contentService: ContentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    googleAnalyticsService: GoogleAnalyticsService,
    private zone: NgZone,
    private sessionStorageService: SessionStorageService
  ) {
    Object.keys({ ...localStorage })
      .filter((key) => key.startsWith('scroll-'))
      .map(key => ({ key, val: JSON.parse(localStorage.getItem(key)) }))
      .filter(({ key, val }) =>
        val.date === undefined
        || val.scroll === undefined
        || val.date + scrollMemoryDuration < Date.now()
      ).forEach(({ key }) => {
        localStorage.removeItem(key);
      });

    history.scrollRestoration = 'manual';

    this.userSubscription = this.userService.user$.subscribe((user) => {
      this.communicationService.user = user;

      console.log("user changed", user);

      this.communicationService.performAction({
        event: WidgetEvent.USERINFOCHANGED,
        dataItem: user,
      });
    });

    googleAnalyticsService.startAnalytics();

    // this.proboTest();

    this.socket.on("ToManySessions", () => {
      const currentUrl = this.router.url;
      const newUrl = `${currentUrl}(window:xfw/nl/window/to-many-sessions)`;

      this.router.navigateByUrl(newUrl);
    });

    this.socket.on("Logout", () => {
      this.auth.loginAnon();
    });
  }

  @HostListener('window:beforeunload')
  updateScrollMemory() {
    localStorage.setItem(`scroll-${this.router.url}`, JSON.stringify({ scroll: window.scrollY, date: Date.now() }));
  }

  @HostListener('wheel', ['$event'])
  onScroll(e: WheelEvent): void {
    // localStorage.setItem(`scroll-${this.router.url}`, JSON.stringify({ scroll: window.scrollY + e.deltaY, date: Date.now() }));
  }

  /**
   * This method is used when there is a corrupted token or no token at al
   * This method will get an anomymous session token
   *
   * @author Alex Spelt
   */

  private getNewSession() {
    this.auth
      .loginAnon()
      .then(() => {
        this.authenticated = true;
      })
      .then(() => {
        // load domain config
        if (!this.domainConfig)
          this.domainConfigService.getDomainConfig().then((config) => {
            this.dataService.blockLayoutGridData = config.layoutClassNames;
            this.domainConfig = config;
            this.styleManagerService.setStyle(
              "xfw",
              `/assets/${config.css}.css`,
              config.favicon
            );
          });
      })
      .catch(() => {
        document.writeln(`
        <div style="max-width: 500px; margin: 32px auto; font-family: monospace; text-align: center; border: 1px solid black;">
          <h1>500</h1>
          <h2>The authorization did not succeed!</h2>
          <p>Our servers are probably down. Try refreshing the website or try to visit our website later. Sorry for the inconvenience.</p>
        </div>
      `);
        throw "nope";
      });
  }

  public data = [];
  public filter = {};

  reference(object, path) {
    if (path.length > 1) {
      const ref = path.shift();
      console.log(path, object[ref]);

      return this.reference(object[ref], path);
    }
    return object;
  }

  ngOnInit(): void {
    const uploadEvents:
      {
        event: string;
        widgetGroup: string[];
      }[] = [];


    // setTimeout(() => {
    //   this.batchUpdateService.updateProductJSON('printcom', 'wall-graphics');
    // });

    this.socket.on("Update", (eventData) => {

      console.log('socket update: ', eventData);

      this.communicationService.performAction({
        info: eventData.event,
        widgetGroup: eventData.widgetGroup,
        event: WidgetEvent.SOCKETUPDATE,
        data: eventData.data,
      });

      ['storedInDb','thumbUploaded'].includes(eventData.event) && uploadEvents.push({
        event: eventData.event,
        widgetGroup: eventData.widgetGroup
      });

      if (uploadEvents.filter(event => this.utilsService.isSameArray(event.widgetGroup, eventData.widgetGroup)).length === 2) {
        this.communicationService.performAction({
          info: eventData.event,
          widgetGroup: eventData.widgetGroup,
          event: WidgetEvent.REFRESH,
          data: eventData.data,
        });

        uploadEvents.length = 0;
      }
    });

    this.socket.on("Error", (eventData) => {

      console.log('socket error: ', eventData);

      this.communicationService.performAction({
        info: eventData.event,
        widgetGroup: [eventData.widgetGroup],
        event: WidgetEvent.SOCKETUPDATE,
        data: eventData.data,
      });
    });

    this.socket.on("SetSessionData", (eventData) => {
      console.log('socket SetSessionData: ', eventData);
      try {
        eventData = JSON.parse(eventData);
        this.sessionStorageService.modifySessionData = eventData;
        this.communicationService.sessionConfig = eventData;

        this.communicationService.performAction({
          event: WidgetEvent.SESSIONCONFIGCHANGED,
          dataItem: this.sessionStorageService.getSessionData
        });
      } catch (e) {
        console.error('New SessionStorage data is invalid json. Can\'t merge')
      }
    });

    // setTimeout(() => {
    //   this.batchUpdateService.updateProductJSON('probo', 'bannerframe');
    // });

    // this.configuratorProboService.getProboProductInfo('/'+'airtex');
    // setTimeout(() => {
    //   this.configuratorProboService.getProboProducts().then((data: any) => {
    //     console.log(data);
    //   })
    // },2000);

    // get session if not present.
    if (localStorage.getItem(this.auth.sessionTokenName)) {
      //console.log('token already defined');

      this.auth
        .getSessionInfo()
        .then(() => {
          this.authenticated = true;
        })
        .catch(() => {
          this.getNewSession();
        });
    } else {
      // console.log("new session");

      this.getNewSession();
    }

    this.domainConfigService.getDomainConfig().then((config) => {
      console.log(config);

      this.dataService.blockLayoutGridData = config.layoutClassNames;
      this.dataService.searchNav = config.searchNav;

      this.domainConfig = config;
      this.styleManagerService.setStyle(
        "xfw",
        `/assets/${config.css}.css`,
        config.favicon
      );

    });

    // this.dataService.getBlocks('configurator/acousticpro-budget-paneel', 'nl').then(blocks => {
    //   console.log(blocks);
    // });
  }

  log(origin: string, level: number, contentJSON: any) {
    this.dataService.setLog(origin, level, contentJSON);
  }

  proboTest() {
    return;
    // Price request
    //   lastValueFrom(this.networkService.post('/api/PrintOnline/probo/price', {
    //     "products": [
    //       {
    //         "code": "banner",
    //         "options": [
    //           {
    //             "code": "width",
    //             "value": "100"
    //           },
    //           {
    //             "code": "height",
    //             "value": "100"
    //           },
    //           {
    //             "code": "amount",
    //             "value": "1"
    //           },
    //           {
    //             "code": "single-sided"
    //           },
    //           {
    //             "code": "banner-510"
    //           },
    //           {
    //             "code": "finishing-all-sides"
    //           },
    //           {
    //             "code": "hem-and-rings"
    //           },
    //           {
    //             "code": "every-30cm"
    //           },
    //           {
    //             "code": "tensioner-with-spinhook",
    //             "value": "20"
    //           }
    //         ]
    //       },
    //       {
    //         "code": "banner",
    //         "options": [
    //           {
    //             "code": "width",
    //             "value": "200"
    //           },
    //           {
    //             "code": "height",
    //             "value": "200"
    //           },
    //           {
    //             "code": "amount",
    //             "value": "1"
    //           },
    //           {
    //             "code": "single-sided"
    //           },
    //           {
    //             "code": "banner-510"
    //           },
    //           {
    //             "code": "finishing-all-sides"
    //           },
    //           {
    //             "code": "hem-and-rings"
    //           },
    //           {
    //             "code": "every-30cm"
    //           },
    //           {
    //             "code": "tensioner-with-spinhook",
    //             "value": "40"
    //           }
    //         ]
    //       },
    //       {
    //         "code": "banner",
    //         "options": [
    //           {
    //             "code": "width",
    //             "value": "300"
    //           },
    //           {
    //             "code": "height",
    //             "value": "300"
    //           },
    //           {
    //             "code": "amount",
    //             "value": "1"
    //           },
    //           {
    //             "code": "single-sided"
    //           },
    //           {
    //             "code": "banner-510"
    //           },
    //           {
    //             "code": "finishing-all-sides"
    //           },
    //           {
    //             "code": "hem-and-rings"
    //           },
    //           {
    //             "code": "every-30cm"
    //           },
    //           {
    //             "code": "tensioner-with-spinhook",
    //             "value": "20"
    //           }
    //         ]
    //       }
    //     ]
    //   }, 'json')).then((data: any) => {

    //     console.log('priceInfo: ', data);

    //   });

    //   // Configurator request
    //   lastValueFrom(this.networkService.post('/api/PrintOnline/probo/configure', {
    //     "products": [
    //       {
    //         "code": "banner",
    //         "options": [
    //           {
    //             "code": "width",
    //             "value": "300"
    //           },
    //           {
    //             "code": "height",
    //             "value": "300"
    //           },
    //           {
    //             "code": "amount",
    //             "value": "1"
    //           },
    //           {
    //             "code": "single-sided"
    //           },
    //           {
    //             "code": "banner-510"
    //           },
    //           {
    //             "code": "finishing-all-sides"
    //           },
    //           {
    //             "code": "hem-and-rings"
    //           },
    //           {
    //             "code": "every-30cm"
    //           }
    //         ]
    //       }
    //     ]
    // }, 'json')).then((data: any) => {

    //     console.log('configuratorInfo: ', data);

    // });

  }

}
