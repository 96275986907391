import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfiguratorProduct, ConfiguratorProductAddition, ConfiguratorResult } from 'src/app/configurator';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';
import { Nav, Param, WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-configurator-list',
  templateUrl: './configurator-list.component.html',
  styleUrls: ['./configurator-list.component.scss']
})
export class ConfiguratorListComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public product: ConfiguratorProduct;
  public additions: ConfiguratorProductAddition[];
  public configuration: ConfiguratorResult;
  public configurationResult: ConfiguratorResult;

  @Input() state: string;

  public productJSON: ConfiguratorProduct;

  public addRowNav: Nav;

  public configuratorReady: boolean = false;
  public currentCode: string;

  ngOnInit(): void {
    super.ngOnInit();

    this.communicationService.initWidget({
      widgetId: this.dataGroupItemInstance.widgetId,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [this.dataGroupItemInstance.widgetId],
          event: WidgetEvent.SAVED,
          func: 'updateConfigurator'
        },
        {
          widgetGroup: [this.dataGroupItemInstance.widgetId],
          event: WidgetEvent.REFRESHED,
          func: 'check'
        },
        {
          widgetGroup: [this.dataGroupItemInstance.widgetId],
          event: WidgetEvent.EDITMODECHANGED,
          func: 'setConfigurator'
        }
      ]
    });
  }

  check() {
    if (this.currentCode !== this.dataGroupItemInstance.dataTable[0].data[0].code) {
      this.configuratorReady = false;
      this.setConfigurator();
    }
  }

  setConfigurator() {
    if (this.dataGroupItemInstance.dataTable[0]?.data?.length) {
      this.currentCode = this.dataGroupItemInstance.dataTable[0].data[0].code;

      this.dataGroupItemInstance.dataTable[0].data[0].crud = 'edit';

      this.dataGroupItemInstance.dataTable[0].data[0].productJSON &&
        (this.configuratorService.originalProductJSON = structuredClone(this.dataGroupItemInstance.dataTable[0].data[0].productJSON));

      this.productJSON = structuredClone(this.configuratorService.originalProductJSON);
      this.productJSON.mode ||= 'cards';

      this.dataGroupItemInstance.dataTable[0].data[0].additionJSON && this.configuratorService.
        mergeProduct(this.productJSON, this.dataGroupItemInstance.dataTable[0].data[0].additionJSON);
      this.configuration = this.dataGroupItemInstance.dataTable[0].data[0].configurationJSON;

      this.productJSON.configuratorRows = [];

      this.productJSON.rows.forEach((row, index) => {
        row.state.isSelected = false;
        let currentItemIndex = null;

        if (row.conditionalCodesExt.length) {
          row.conditionalCodesExt.forEach(conditionalCode => {
            if (conditionalCode.itemIndex !== currentItemIndex) {
              currentItemIndex = conditionalCode.itemIndex;
              const configuratorRow = structuredClone(row);
              configuratorRow.itemIndex = conditionalCode.itemIndex;
              configuratorRow.conditionalCodesExt = row.conditionalCodesExt.filter(conditionalCode => conditionalCode.itemIndex === configuratorRow.itemIndex) ?? [];
              configuratorRow.options = configuratorRow.options.filter(option => option.itemIndex === configuratorRow.itemIndex) ?? [];
              configuratorRow.state.inValid = configuratorRow.state.hidden = true;

              this.productJSON.configuratorRows.push(configuratorRow);
            }
          });
        } else {
          const configuratorRow = structuredClone(row);
          configuratorRow.itemIndex = index;
          configuratorRow.conditionalCodesExt = [];
          configuratorRow.state.inValid = configuratorRow.state.hidden = true;
          this.productJSON.configuratorRows.push(configuratorRow);
        }
      });

      this.productJSON.configuratorRows.sort((a, b) => a.itemIndex - b.itemIndex);

      this.communicationService.setDataGroupItemChildrenParams(this.dataGroupItemInstance, this.dataGroupItemInstance.dataTable[0].data[0]);

      (this.state !== 'accessoryConfigured') &&
        (this.dataGroupItemInstance.children[0].fieldsMerge = this.dataGroupItemInstance.dataTable[0].data[0]?.nasJSON?.nas?.fields);

      this.dataGroupItemInstance.params.forEach(param => {
        param.val = this.dataGroupItemInstance.dataTable[0].data[0][param.key]
      });

      (this.state !== 'accessoryConfigured') &&
        this.communicationService.mergeQueryParams(this.widgetId, this.dataGroupItemInstance.params, [], null, true);

      // this.dataGroupItemInstance.dataTable[0].data[0].configurationJSON.noAccessories = true;

      this.dataGroupItemInstance.dataTable[0].data[0].configurationJSON.products[0]?.options?.length ?
        this.configuratorService.setConfiguration(
          this.state,
          this.productJSON,
          this.dataGroupItemInstance,
          this.dataGroupItemInstance.dataTable[0].data[0].configurationJSON)
        :
        (this.productJSON.configuratorRows[0].state.expanded = !(this.productJSON.configuratorRows[0].state.hidden = false)) &&
          this.configuratorService.setInValidState(this.productJSON);

      this.configuratorService.selectNextUndecided(this.productJSON, this.document);

      this.configuratorReady = true;
    }
  }

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([this.dataGroupItemInstance.widgetId]);
    super.ngOnDestroy();
  }

  updateConfigurator(params) {
    let data = structuredClone(params.data[0]);

    this.dataGroupItemInstance.dataTable[0].data[0].crud = 'edit'; // na het opslaan staat crud op null, maar dat mag in de configurator niet.

    (this.state !== 'accessoryConfigured') &&
      (this.dataGroupItemInstance.children[0].formGroupValidation.data = data.configurationJSON.silfSizes);

    this.configuration.suppliers = data.suppliers;
    this.configuration.requirements = data.configurationJSON.requirements;
  }
}
