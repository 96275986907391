<div *ngIf="init" class="xfw-tab-strip-container" [ngClass]="[nav.className ?? '']" #tabstrip divBreakpointClass (breakpointClass)="checkSizes($event)">
  <ng-container *ngIf="!hamburger">
    <!-- <div class="xfw-tab-strip-maintenance" *accessLevel="['moderator']">
      <app-cmd
        [nav]="maintenance"
        [navList]="[]"
        [navVisible]="0"
        [className]="'lg'"
        [collapsed]="false">
      </app-cmd>
    </div> -->

    <div *ngFor = "let tab of tabs; let first = $first; let last = $last; trackBy: trackByFn" class=""
         [ngClass]="{'xfw-tab-list': true, 'first': first, 'last': last, 'floatRight': tab.floatLeft ? false : nav.floatRight}">
      <ng-container *accessLevel="tab.accessLevel">
        <div *ngIf="!tab.menu" [ngClass]="['xfw-tab', tab.className ?? '', tab.isSelected ? 'selected' : '']"
            (click) = "select(tab, content); $event.stopPropagation();"
            title="{{ tab.text }}">
          <button class="cmd-button">
            <div class="cmd-icon-before">
              <div class="icon-wrapper">
                <i [ngClass]="tab.icon"></i>
              </div>
            </div>
            <div class="cmd-text">
              <div class="text-wrapper">
                <span [innerHTML]="tab.text"></span>
                <i *ngIf = "tab.info" class="xfw-tab-info">{{ tab.info }}</i>
              </div>
            </div>
            <div class="cmd-icon-after">
              <div class="icon-wrapper">
                <i [ngClass]="tab.icon"></i>
              </div>
            </div>
          </button>
        </div>
      </ng-container>

      <ng-container *accessLevel="tab.accessLevel">
        <div *ngIf="tab.menu" [ngClass]="['xfw-tab', tab.className ?? '', 'menu', tab.isSelected ? 'selected' : '']"
            (mouseover) = "tab.menuExpanded = true;"
            (mouseleave) = "tab.menuExpanded = false;"
            (click) = "select(tab, content); $event.stopPropagation();"
            title="{{ tab.text }}">
            <button class="cmd-button">
              <div class="cmd-icon-before">
                <div class="icon-wrapper">
                  <i [ngClass]="tab.icon"></i>
                </div>
              </div>
              <div class="cmd-text">
                <div class="text-wrapper">
                  <span [innerHTML]="tab.text"></span>
                  <i *ngIf = "tab.info" class="xfw-tab-info">{{ tab.info }}</i>
                </div>
              </div>
              <div class="cmd-icon-after">
                <div class="icon-wrapper">
                  <i [ngClass]="tab.icon"></i>
                </div>
              </div>
            </button>
          <div *ngIf="tab.menuExpanded && tab.menu.length > 1" class="xfw-tab-menu">
            <div *ngFor="let item of tab.menu; trackBy: trackByFn">
              <ng-container *accessLevel="item.accessLevel">
                <ng-container *ngIf = "!item.isInVisible">
                  <hr *ngIf="item.divide" />
                  <div [ngClass]="['xfw-tab', 'menu-item', item.className ?? '', item.isSelected ? 'selected' : '']"
                        title="{{ item.text }}"
                        (click) = "select(item, content); $event.stopPropagation();">
                    <button class="cmd-button">
                      <div class="cmd-icon-before">
                        <div class="icon-wrapper">
                          <i [ngClass]="item.icon"></i>
                        </div>
                      </div>
                      <div class="cmd-text">
                        <div class="text-wrapper">
                          <span [innerHTML]="item.text"></span>
                          <i *ngIf = "item.info" class="xfw-tab-info">{{ item.info }}</i>
                        </div>
                      </div>
                      <div class="cmd-icon-after">
                        <div class="icon-wrapper">
                          <i [ngClass]="item.icon"></i>
                        </div>
                      </div>
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="clear"></div>
  </ng-container>

  <div *ngIf="hamburger" class="hamburger-wrapper">
    <div [ngClass]="[nav.hamburgerClass ? nav.hamburgerClass : 'hamburger']">
      <i [ngClass]="[nav.hamburgerIcon ? nav.hamburgerIcon : 'dripicons dripicons-menu']" (click)="showHamburgerMenu = !showHamburgerMenu; $event.stopPropagation();"></i>
      <div class="hamburger-menu" [hidden]="!showHamburgerMenu" (clickOutside)="onClickOutside($event)">
        <div class="hamburger-menu-close">
          <i class="dripicons dripicons-cross" (click)="showHamburgerMenu = !showHamburgerMenu; $event.stopPropagation();"></i>
        </div>
        <div *ngFor = "let tab of tabs; let first = $first; let last = $last; trackBy: trackByFn" class=""
            [ngClass]="{'xfw-tab-list': true, 'first': first, 'last': last, 'floatRight': nav.floatRight}">

          <ng-container *accessLevel="tab.accessLevel">
            <div *ngIf="!tab.menu || tab.menu.length < 2" [ngClass]="{'xfw-tab': true, 'selected': tab.isSelected }"
                  (click)="showHamburgerMenu = false; select(tab, content); $event.stopPropagation();"
                  title="{{ tab.text }}">
              <span [innerHTML]="tab.text"></span>
              <i *ngIf = "tab.info" class="xfw-tab-info">{{ tab.info }}</i>
            </div>
          </ng-container>

          <ng-container *accessLevel="tab.accessLevel">
            <div *ngIf="tab.menu && tab.menu.length > 1" [ngClass]="{'xfw-tab': true, 'menu': tab.menu.length > 1, 'selected': tab.isSelected }"
                  title="{{ tab.text }}">
              <i [class]="tab.icon"></i>
              <span (click) = "tab.menuExpanded = !tab.menuExpanded; !tab.menuExpanded && select(tab, content); $event.stopPropagation();" [innerHTML]="tab.text">&nbsp;</span>
              <i *ngIf = "tab.info" class="xfw-tab-info">{{ tab.info }}</i>
            </div>
            <div [hidden]="!tab.menuExpanded" class="xfw-tab-menu-list">
              <ng-container *ngFor="let item of tab.menu; let last = last; trackBy: trackByFn">
                <ng-container *accessLevel="item.accessLevel">
                  <ng-container *ngIf = "!item.isInVisible">
                    <hr *ngIf="item.divide" />
                    <div  [ngClass]="{'xfw-tab': true, 'selected': item.isSelected, 'last': last }"
                          title="{{ item.text }}"
                          (click) = "tab.menuExpanded = !tab.menuExpanded; select(item, content); $event.stopPropagation();">
                      <!-- <span>{{ item.text }}</span> -->
                      <span [innerHTML]="item.text"></span>
                      <i *ngIf = "item.info" class="xfw-tab-info">{{ tab.info }}</i>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ msg ? msg.title : '' }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ msg ? msg.text : '' }}</p>
  </div>
  <div class="modal-footer">
    <div class="cmd text xfw3-bg-primary rounded">
      <button class="cmd-button" (click)="modal.close('CANCEL')">
        <div class="cmd-text">
          <div class="text-wrapper">
            <span [innerHTML]="msg ? msg.cancel : ''"></span>
          </div>
        </div>
      </button>
    </div>
    <div class="cmd text xfw3-bg-primary rounded uppercase">
      <button class="cmd-button" (click)="modal.close('OK')">
        <div class="cmd-text">
          <div class="text-wrapper">
            <span [innerHTML]="msg ? msg.primary : ''"></span>
          </div>
        </div>
      </button>
    </div>
  </div>
</ng-template>
