<svg:ng-container>

  <g [ngClass]="parent.view" attr.transform="translate({{boxWidth * -0.5}}, {{object.height * 0.5 }}) ">
    <path #pathElement [id]="object.ident" [attr.d]="generatePath()" class="weight drag" />
  </g>

  <!-- Resize Sliders -->
  <ng-container *ngIf="parent.povAxis === object.activeAxis">

    <!-- Width Left Slider -->
    <g *ngIf="object.inhakers[0] === undefined" attr.transform="translate({{-object.width * 0.5}})">
      <circle r="0.025" class="resize-handle ignore" />
      <circle id="{{object.ident}}.lWidth" r="0.04" class="drag" opacity="0" />
    </g>

    <!-- Width Right Slider -->
    <g *ngIf="object.inhakers[2] === undefined" attr.transform="translate({{object.width * 0.5}})">
      <circle r="0.025" class="resize-handle ignore" />
      <circle id="{{object.ident}}.rWidth" r="0.04" class="drag" opacity="0" />
    </g>

    <!-- Height Top Slider -->
    <g attr.transform="translate(0,{{object.height * 0.5}})">
      <circle r="0.025" class="resize-handle ignore" />
      <circle id="{{object.ident}}.tHeight" r="0.04" class="drag" opacity="0" />
    </g>

    <!-- Height Bottom Slider -->
    <g *ngIf="object.inhakers[1] === undefined" attr.transform="translate(0,{{-object.height * 0.5}})">
      <circle r="0.025" class="resize-handle ignore" />
      <circle id="{{object.ident}}.bHeight" r="0.04" class="drag" opacity="0" />
    </g>

  </ng-container>

  <g *ngIf="parent.selected === object"
    attr.transform="translate({{-object.width * 0.5 + 0.085}}, {{object.height * 0.5 - 0.085}})">
    <circle title="remove" r="0.04" cy="0" class="button"
      (click)="object.hookType = (object.hookType + 1) % 2; onDragEnd(object, 'tHeight',0,0)">
      <title>
        {{object.hookType !== 0 ? 'Wissel naar normaal haak' : 'Wissel naar omkeer haak'}}
      </title>
    </circle>
    <text fill="#000" dominant-baseline="central" class="text-middle icon ignore">H</text>
  </g>

  <g *ngIf="object.inhakers[0] !== undefined"
    attr.transform="translate({{-object.width * 0.5 - 0.04}},{{object.height * 0.5 - object.hookOffset}})">
    <circle r="0.025" class="resize-handle ignore" />
    <circle id="{{object.ident}}.hookOffset" r="0.04" class="drag" opacity="0" />

  </g>

  <g *ngIf="object.inhakers[2] !== undefined"
    attr.transform="translate({{object.width * 0.5 + 0.04}},{{object.height * 0.5 - object.hookOffset}})">
    <circle r="0.025" class="resize-handle ignore" />
    <circle id="{{object.ident}}.hookOffset" r="0.04" class="drag" opacity="0" />

  </g>

  <ng-container *ngIf="this.parent.selected === this.object">
    <!-- Height number -->
    <g attr.transform="translate({{(object.width * 0.5 + 0.04) * (object.inhakers[2] !== undefined ? -1 : 1)}},0)"
      [ngClass]="object.inhakers[2] !== undefined ? 'text-right' : ''">
      <text [id]="object.ident" class="display-height ignore">
        {{ Math.round(object.height * 1000) }} mm
      </text>
    </g>

    <!-- Width number -->
    <g attr.transform="translate(0, {{object.height * 0.5 + 0.04}})">
      <text class="display-width ignore text-middle">
        {{ Math.round(object.width * 1000) }} mm
      </text>
    </g>

    <!-- Measurement numbers -->
    <!-- <g attr.transform="">
      <text dy="-0.06" class="display-width ignore text-middle">
        {{ Math.round(pathElement.getBBox().width * pathElement.getBBox().height * 1000000) }} mm2
      </text>
      <text class="display-width ignore text-middle">
        Length: {{ round(pathLength)* 1000 }} mm
      </text>
      <text dy="0.06" class="display-width ignore text-middle">
        Cut: {{ round(pathCutDuration) }} s
      </text>
    </g> -->
  </ng-container>

  <ng-container *ngIf="parent.view === 'weight'">

    <g *ngIf="object.height > 0.2 && boxWidth > 0.15">
      <!-- Object name -->
      <g *ngIf="!object.counter">
        <text [style]="'font-size:' + clamp(minNameSize, maxNameSize, Math.min(boxWidth, object.height) * 0.7) + ';'"
          class="ignore text-middle weight-name">
          {{ object.ident }}
        </text>
      </g>

      <!-- Counterweight icon -->
      <g *ngIf="object.counter">
        <text [style]="'font-size:' + clamp(minNameSize, maxNameSize, Math.min(boxWidth, object.height) * 0.5) + ';'"
          class="ignore text-middle object-icon icon">{{'\uf24e'}}</text>
      </g>

      <!-- Weight number -->
      <g attr.transform="translate({{boxWidth * 0.5 - 0.03}}, {{-object.height*0.5  + 0.03}})">
        <text class="ignore text-right">
          {{ Math.round(object.mass * 10) / 10 }} kg
        </text>
      </g>
    </g>

    <g *ngIf="!(object.height > 0.2 && boxWidth > 0.15)"
      attr.transform="translate({{(object.inhakers[2] !== undefined ? -1 : 1) * (boxWidth * 0.5 + 0.06)}}, {{object.height * -0.5 - 0.06}})">
      <rect class="popout-group-border ignore" width="0.1" height="0.1" x="-0.05" y="-0.05"
        [ngClass]="{'options-counterweight-fill': object.counter}" />

      <!-- Object name -->
      <g *ngIf="!object.counter">
        <text style="font-size:0.08;" class="ignore text-middle weight-name">
          {{ object.ident }}
        </text>
      </g>

      <!-- Counterweight icon -->
      <g *ngIf="object.counter">
        <text style="font-size:0.06;" class="ignore text-middle object-icon icon">
          {{"\uf24e"}}
        </text>
      </g>

      <!-- Weight number -->
      <g attr.transform="translate(0, {{ -0.15 - 0.085 * buttons.length}})">
        <text class="ignore text-middle">
          {{ Math.round(object.mass * 10) / 10 }}
          kg</text>
      </g>
    </g>

    <ng-container *ngIf="object.density === undefined">
      <!-- Center of mass drag -->
      <use href="#target-icon" id="{{object.ident}}.com" attr.transform="translate({{object.comX}},{{object.comY}})"
        class="drag center-of-mass" />

      <!-- Weight slider & line -->
      <line [attr.x1]="object.comX" [attr.y1]="object.comY" [attr.x2]="object.comX"
        [attr.y2]="object.comY - Math.pow(object.internalMass * weightScaleLinear, 1 / weightScaleExponent)"
        class="weight-line ignore" />
      <rect id="{{object.ident}}.weight" [attr.x]="object.comX-0.02"
        [attr.y]="object.comY-Math.pow(object.internalMass * weightScaleLinear, 1 / weightScaleExponent) - 0.02"
        [attr.width]="0.04" [attr.height]="0.04" class="drag weight-handle" />
    </ng-container>
    <!-- Option buttons -->
    <g attr.transform="translate({{
        (object.inhakers[2] !== undefined ? -1 : 1)
        * (((object.height > 0.2 && boxWidth > 0.15) ? -1 : 1)
        * boxWidth * 0.5 + 0.06)
      }}, {{
        ((object.height > 0.2 && boxWidth > 0.15) ? 1 : -1)
        * object.height * 0.5 - 0.06
        - ((object.height > 0.2 && boxWidth > 0.15) ? 0 : 0.115)
      }})">

      <!-- Background -->
      <rect *ngIf="!(object.height > 0.2 && boxWidth > 0.15 && buttons.length > 0)" class="group-border ignore"
        width="0.1" [attr.height]="0.015 + 0.085 * buttons.length" x="-0.05" [attr.y]="0.035 - 0.085 * buttons.length"
        [ngClass]="{'options-counterweight-fill': object.counter}" />

      <!-- Button generate -->
      <g *ngFor="let button of buttons; let i = index" attr.transform="translate(0, {{-0.085 * i}})">
        <circle title="remove" r="0.04" cy="0" class="button" (click)="button.onClick()">
          <title>{{button.title}}</title>
        </circle>
        <text fill="#FFF" dominant-baseline="central" class="text-middle icon ignore">{{button.faIcon}}</text>
      </g>

    </g>

  </ng-container>

</svg:ng-container>