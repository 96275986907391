import { Injectable } from '@angular/core';
import { DataGroup, DataTable } from '../data';
import { Nav } from '../widgets';
import { Subscription } from 'rxjs';
import { DataService } from './data.service';

export type StepCrud = 'transfer' | 'copy' | 'merge' | 'delete';

export interface Object_Task_Data {
  workflow: Workflow;
  location: Location;
  title: {
    jobOptionComponentNo: string;
    relationCode: string;
    componentTitle: string;
  };
  remarks: string;
  delivery: Delivery;
  unplanned: boolean;
}
export interface Delivery {
  type: string;
  remarks: string;
  rruleIndex: RruleIndex;
}
export interface Competence {
  id: number;
  competence: string;
  className: string;
}

export interface TimeslotInfo {
  from: string;
  till: string;
  title: string;
  duration: number;

  /** Was used to calculate duration. Unused. Minutes relative to midnight */
  startMinute: number;
  /** Was used to calculate duration. Unused. Minutes relative to midnight */
  endMinute: number;
  /**  Was used to calculate duration for bars view. Unused. */
  durationMinutes: number;
}

export interface Resource {
  timeslotInfo: TimeslotInfo;
  absentId?: number;
  checked: boolean;
  collapsed: boolean;
  companyId: number;
  crud: string;
  edit: boolean;
  isSelected: boolean;
  pool?: unknown;
  resourceId: number;
  rruleIndex?: RruleIndex;
  sectionId: number;
  teamId: number;
  timeSlotInfo: any;
  trackBy: number;
  type: "unplanned" | "planned";
  competenceId: number;
  incidental: boolean;
  isPool: boolean;
  title: string;
  equipment: Equipment[];
  competences: Competence[];
  competenceNav: Nav;
  totalTime: number; // Dit is de totale tijd in 'dagminuten' voor het team of de pool. Dus 480 minuten voor 1 teamlid maar ook 480 voor 2 teamleden of voor een pool met 3 teamleden.
  detail: Detail;
}

export interface Equipment {
  equipmentId: number;
  type: "vehicle" | "aerialWorkPlatform" | "scaffolding" | "printer" | "router" | "laser";
  equipmenJSON: {}; // o.a. verwijzing naar track & trace voor auto's info over snelheid hoogte kenteken etc.
}
export interface Location {
  companyName: string;
  contact: string;
  addressId: number;
  street: string;
  number: string;
  zip: string;
  place: string;
  country: string;
  latitude: number;
  longitude: number;
}
export interface Workflow {
  design?: Step[];
  preprod?: Step[];
  reserve?: Step[];
  print?: Step[];
  laminate?: Step[];
  preassemble?: Step[];
  route?: Step[];
  plot?: Step[];
  peel_appl?: Step[];
  outsource?: Step[];
  finish?: Step[];
  assemble?: Step[];
  check?: Step[];
  pack?: Step[];
  // labor
  // send
  deliver?: Step[];
  mount?: Step[];
  // pickup
  // invoice
  // end
}

export interface TaskTitleObject {
  title: string;
  jobOptionComponentNo: string;
  relationCode: string;
}

export interface Step {
  batchId?: number;
  checked: boolean;
  collapsed: boolean;
  compId?: number; // componentId
  competenceId: number;
  competences: Competence[];
  crud?: StepCrud;
  dateCompleted?: string;
  dateStarted?: string;
  delivery: { type: string; remarks: string; };
  detail: Detail;
  drag: Drag;
  durationMinutes: number;
  durationTimeSlots: number;
  edit: boolean;
  isSelected: boolean;
  location?: {
    addressId: number;
    companyName: string;
    contact: string;
    country: string;
    latitude: number;
    longitude: number;
    no: string;
    place: string;
    street: string;
    zip: string;
  };
  objectId: number;
  plan: Plan;
  sortOrder: number;
  state: string; // deze gebruiken als className voor de card/tile
  stateId: number;
  step: string;
  stepTimePlanned: number; // in minuten. Zodra een step wordt verdeeld over meerdere momenten.
  timeToEnd: number; // reistijd opruimen afsluiten
  timeToStart: number; // reistijd schoonmaken opstarttijd
  title: TaskTitleObject;
  totalStepTimePlanned: number; // in minuten

  competenceNav: Nav;
}


export interface Plan {
  timeslotsOffset: number;
  rruleIndex: RruleIndex;
  resourceId: number;
}
export interface Detail {
  dataGroup: string[];
  // params staan in paramsFrom in de dataGroup en worden automatisch gezet via dataGeneric
}
export interface RruleIndex {
  date: string;
  year?: number;
  month?: number;
  day?: number;
  week?: number;
  weekday?: number;
  timeslot?: number;
  timeslotsOffset: number;
}
export interface Drag {
  draggable: boolean;
  minRruleIndex: RruleIndex; // als bij drag een drop wordt gedaan checken of >= de minRruleIndex. onMinRruleIndexError.
  maxRruleIndex?: RruleIndex; // als boven.
}

export type PlannedResource = Resource & {
  task: Step[];
};

export interface Timeslot {
  timeslot: number;
  timeslotInfo: TimeslotInfo;
}

export interface ResourceTimeslot extends Timeslot {
  resource: PlannedResource[];
}

export interface TaskTimeslot extends Timeslot { tasks: Step[]; }

export interface ResourceWithTimeslots { resource: Resource; timeslots: TaskTimeslot[]; }

export type PlanningData = RruleIndex & {
  timeslots: ResourceTimeslot[];
  resources: { resource: Resource; timeslots: TaskTimeslot[]; }[];

  startMinute: number;
  endMinute: number;
  duration: number;
  /** The total hours spanned over a day */
  span: number;
};

/** Get the starting and ending points of timeslots in minutes. */
const timeslotStartEndInMinutes = (slot: Timeslot): [number, number, number] => {
  const { from, till } = slot.timeslotInfo;

  const hrStringToMinutes = (str: string) => {
    const [hour, minute] = str.split(':').map(Number);

    return hour * 60 + minute;
  };

  let start = hrStringToMinutes(from),
    end = hrStringToMinutes(till);

  return [start, end, end - start];
};

/** Retrieve the start, end and total time of times  */
const dayStartEndTotal = (day: PlanningData): [number, number, number, number] => {
  const slots = day.timeslots
    .reduce(([startAcc, stopAcc, total], { timeslotInfo: { startMinute, endMinute, durationMinutes } }) => [
      Math.min(startAcc || startMinute, startMinute),
      Math.max(stopAcc || endMinute, endMinute),
      total + durationMinutes
    ], [, , 0]);

  slots[3] = slots[1] - slots[0];

  return slots as [number, number, number, number];
};

@Injectable({
  providedIn: "root",
})
export class PlanningService {
  public serverFilterDataGroup: DataGroup = {
    src: [{ name: '', type: 'data' }],
    widgetId: 'planning',
    noHeader: false,
    noTitle: false,
    collapsable: false,
    page: 10000,
    top: 0,
    title: {
      nodata: "Planning",
      singleItem: "Planning",
      multipleItems: "Planning",
      window: "Planning",
    },
    "edit": {
      "type": "filterForm",
      "mode": "edit",
      "accessLevel": {
        "crud": [
          "employee"
        ],
        "create": null,
        "update": null,
        "delete": null
      },
      "updateMutations": "direct",
      "create": true,
      "update": true,
      "delete": true
    },
    selectionMode: "none",
    isDraggable: false,
    dropConnectTo: [],
    search: {
      searchFields: ['jobOptionId', 'title', 'remarks', 'planRemarks']
    },
    dataTable: [
      {
        src: "GetMedia",
        queryParams: [{ key: "SdFileId" }],
        data: [],
        fields: [
          {
            fieldGroupClassName: "col-2",
            fieldGroup: [
              {
                key: "FilePath",
                type: "input",
                className: "col-12",
                templateOptions: {
                  label: {
                    "text": "FilePath"
                  },
                  type: "string",
                  readOnly: false,
                  required: false,
                },
                xfw: {
                  dataType: "VARCHAR",
                  dataListClassName: "col-2",
                  hidden: false,
                  isSelectable: false,
                  sort: { order: 0, direction: 0 },
                },
              },
            ],
          },
          {
            fieldGroupClassName: "col-2",
            fieldGroup: [
              {
                key: "FileName",
                type: "input",
                className: "col-12",
                templateOptions: {
                  label: {
                    "text": "FileName"
                  },
                  type: "string",
                  readOnly: false,
                  required: false,
                },
                xfw: {
                  dataType: "VARCHAR",
                  dataListClassName: "col-2",
                  hidden: false,
                  isSelectable: false,
                  sort: { order: 0, direction: 0 },
                },
              },
            ],
          },
          {
            fieldGroupClassName: "col-2",
            fieldGroup: [
              {
                key: "FileExtension",
                type: "input",
                className: "col-12",
                templateOptions: {
                  label: {
                    "text": "FileExtension"
                  },
                  type: "string",
                  readOnly: false,
                  required: false,
                },
                xfw: {
                  dataType: "VARCHAR",
                  dataListClassName: "col-2",
                  hidden: false,
                  isSelectable: false,
                  sort: { order: 0, direction: 0 },
                },
              },
            ],
          },
          {
            fieldGroupClassName: "col-2",
            fieldGroup: [
              {
                key: "Descr",
                type: "input",
                className: "col-12",
                templateOptions: {
                  label: {
                    "text": "Descr"
                  },
                  type: "string",
                  readOnly: false,
                  required: false,
                },
                xfw: {
                  dataType: "VARCHAR",
                  dataListClassName: "col-2",
                  hidden: false,
                  isSelectable: false,
                  sort: { order: 0, direction: 0 },
                },
              },
            ],
          },
          {
            fieldGroupClassName: "col-2",
            fieldGroup: [
              {
                key: "Title",
                type: "input",
                className: "col-12",
                templateOptions: {
                  label: {
                    "text": "Title"
                  },
                  type: "string",
                  readOnly: false,
                  required: false,
                },
                xfw: {
                  dataType: "VARCHAR",
                  dataListClassName: "col-2",
                  hidden: false,
                  isSelectable: false,
                  sort: { order: 0, direction: 0 },
                },
              },
            ],
          },
          {
            fieldGroupClassName: "col-2",
            fieldGroup: [
              {
                key: "Video",
                type: "video",
                className: "col-12",
                templateOptions: {
                  label: {
                    "text": "Video"
                  },
                  type: "string",
                  readOnly: false,
                  required: false,
                },
                xfw: {
                  dataType: "VARCHAR",
                  dataListClassName: "col-2",
                  hidden: false,
                  isSelectable: false,
                  sort: { order: 0, direction: 0 },
                },
              },
            ],
          },
        ],
      }
    ]
  };

  public ordClass = [
    {
      text: "Urgent",
      className: "urgent"
    },
    {
      text: "Opvul order",
      className: "stop-order"
    },
    {
      text: "Gereserveerd",
      className: "reserved"
    },
    {
      text: "Inplannen",
      className: "plan"
    }
  ];

  public employees = [
    {
      personId: 1,
      name: 'Yoeri',
      availableDays: [0, 1, 2, 3, 4],
      workHours: [
        {
          day: 0,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 1,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 2,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 3,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 4,
          from: '8:00',
          till: '17:00'
        }
      ]
    },
    {
      personId: 2,
      name: 'Chris',
      availableDays: [0, 1, 2, 3, 4],
      workHours: [
        {
          day: 0,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 1,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 2,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 3,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 4,
          from: '8:00',
          till: '17:00'
        }
      ]
    },
    {
      personId: 4,
      name: 'Michelle',
      availableDays: [0, 1, 2, 3, 4],
      workHours: [
        {
          day: 0,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 1,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 2,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 3,
          from: '8:00',
          till: '17:00'
        },
        {
          day: 4,
          from: '8:00',
          till: '17:00'
        }
      ]
    },
    {
      personId: 8,
      name: 'Raymond',
      availableDays: [0, 1, 2, 3, 4]
    },
    {
      personId: 16,
      name: 'Jurre',
      availableDays: [0, 1, 3, 4]
    },
    {
      personId: 32,
      name: 'Lennart',
      availableDays: [0, 1, 2, 3, 4]
    },
    {
      personId: 64,
      name: 'Andy',
      availableDays: [0, 1, 2, 3, 4]
    },
    {
      personId: 128,
      name: 'Bram',
      availableDays: [0, 1, 2, 3, 4]
    },
    {
      personId: 256,
      name: 'Thijs',
      availableDays: [0, 1, 2, 3, 4]
    },
    {
      personId: 512,
      name: 'Ruben',
      availableDays: [0, 1, 2, 3, 4]
    },
    {
      personId: 1024,
      name: 'John',
      availableDays: [0, 1, 2, 3, 4]
    },
    {
      personId: 2048,
      name: 'Erwin',
      availableDays: [0, 1, 2, 3, 4]
    }
  ];

  public navTeam: Nav = {
    navId: -21,
    menu: [
      {
        icon: '',
        text: 'Ziek',
        className: "text xfw3-bg-primary round",
        info: '',
        params: [
          {
            key: "func",
            val: "sick"
          },
          {
            key: "teamId",
          },
          {
            key: "dayId",
          }
        ],
        paramsFrom: [
          {
            key: "teamId",
          },
          {
            key: "dayId",
          }
        ],
        disabled: false,
        func: 'teamFunc'
      },
      {
        icon: '',
        text: 'Relatiegegevens',
        className: "text xfw3-bg-primary round",
        info: '',
        params: [
          {
            key: "func",
            val: "relInfo"
          },
          {
            key: "teamId",
          },
          {
            key: "dayId",
          }
        ],
        paramsFrom: [
          {
            key: "teamId",
          },
          {
            key: "dayId",
          }
        ],
        disabled: false,
        func: 'teamFunc'
      }
    ]
  };

  public navUnplanned: Nav = {
    navId: -22,
    menu: [
      {
        icon: '',
        text: 'In eerste vrije blok inplannen',
        className: "text xfw3-bg-primary round",
        info: '',
        params: [
          {
            key: "func",
            val: "firstAvailableMoment"
          },
          {
            key: "planId",
          }
        ],
        paramsFrom: [
          {
            key: "planId",
          }
        ],
        disabled: false,
        func: 'jobFunc'
      },
      {
        icon: '',
        text: 'In eerste vrije blok inplannen bij specifieke monteurs',
        className: "text xfw3-bg-primary round",
        info: '',
        params: [
          {
            key: "func",
            val: "relIfirstAvailableMomentWithEmployee"
          },
          {
            key: "planId",
          }
        ],
        paramsFrom: [
          {
            key: "planId",
          }
        ],
        disabled: false,
        func: 'jobFunc'
      }
    ]
  };

  public dataTable: DataTable[] = this.serverFilterDataGroup.dataTable;

  public competences = [
    {
      competenceId: 1,
      competence: 'Autobelettering'
    },
    {
      competenceId: 2,
      competence: 'Carwrap'
    },
    {
      competenceId: 4,
      competence: 'Interieurwrap'
    },
    {
      competenceId: 8,
      competence: 'Plakken'
    },
    {
      competenceId: 16,
      competence: 'Technisch'
    },
    {
      competenceId: 32,
      competence: 'Elektrisch'
    },
    {
      competenceId: 64,
      competence: 'BE rijbewijs'
    },
    {
      competenceId: 128,
      competence: 'Hoogwerkercertificaat'
    },
    {
      competenceId: 256,
      competence: 'VCA'
    }
  ];

  constructor(
    private dataService: DataService,
  ) { }

  public getItemCompetences(item: Step | Resource) {
    let bitmask = item.competenceId;
    let arr = [];

    while (bitmask !== 0) {
      arr.push((bitmask & 1) > 0);

      bitmask >>>= 1;
    }

    const competencies = this.competences;

    return arr
      .map((v, i) => v && competencies[i])
      .filter(v => v);

  }

  public getItemCompetenceNav(...[item, parent]: [Step] | [Step, Resource] | [Resource]): Nav {
    const competences = this.getItemCompetences(item);

    return {
      navId: -1,
      menu: competences.map(competence => {
        let ok = true;

        if (parent) (competence.competenceId & parent.competenceId) > 0;

        return {
          icon: `fa-solid fa-${competence.competence[0].toLowerCase()}`,
          className: `icon xfw3-bg-primary round xxs mx-1 ${ok ? 'ok' : 'nok'}`,
          info: `${!ok ? 'Team mist competentie: ' : ''}${competence.competence}`,
          disabled: false,
          func: '',
        };
      })
    };
  }

  public resourceHasCompetencies(resource: Resource, task: Step): boolean {
    console.log(resource.competenceId, task.competenceId, resource.competenceId & task.competenceId);
    return (resource.competenceId & task.competenceId) === task.competenceId;
  }

  public async initPlanningData(dataGroupItem: DataGroup): Promise<{
    unplannedData: (Resource & {
      task: Step[];
    })[], plannedData: PlanningData[];
  }> {
    // Retrieve all resources and tasks
    const [{ data: resources }, { data: tasks }] = (await Promise.all([
      this.getPlanningData(dataGroupItem),
      this.getPlanningData(dataGroupItem.children[0])
    ])) as [DataTable, DataTable];

    // Group all resource (unplanned) -> task
    const unplanned = resources.filter(resource => !resource.rruleIndex).map(resource => {
      const matchingTasks = tasks.filter(task =>
        task.plan.resourceId === resource.resourceId
      );

      // Set some defaults for tasks
      tasks.forEach(task => {

        if (!task.stepTimePlanned || task.stepTimePlanned === 0)
          task.stepTimePlanned = task.totalStepTimePlanned;
        task.competenceNav ??= this.getItemCompetenceNav(task);
      });

      if (matchingTasks.length > 0)
        (resource.task ??= []).push(...matchingTasks);

      return resource;
    });

    const days = [] as PlanningData[];


    // Group all day -> timeslot -> resource -> task
    resources.filter(resource => resource.rruleIndex).forEach(resource => {

      const { rruleIndex, timeslotInfo } = resource;
      const { timeslot, year, week, weekday } = rruleIndex;

      // Find matching day
      let day = days.find(d =>
        d.year === year &&
        d.week === week &&
        d.weekday === weekday
      );

      // Or make a new day
      if (!day) {
        day = { ...rruleIndex, timeslots: [], resources: null, duration: 0, endMinute: 0, startMinute: 0, span: 0 };
        days.push(day);
      }

      // Fill in missing data
      const date = new Date(day.date);

      day.month ??= date.getMonth() + 1;
      day.day ??= date.getDate();

      resource.competenceNav ??= this.getItemCompetenceNav(resource);

      // Find or default timeslot
      const slot = (day.timeslots[timeslot - 1] ??= { timeslot, timeslotInfo: timeslotInfo[timeslot - 1], resource: [] });


      // Add duration info to timeslots
      const tsInfo = slot.timeslotInfo;
      const [start, stop, duration] = timeslotStartEndInMinutes(slot);

      tsInfo.startMinute = start;
      tsInfo.endMinute = stop;
      tsInfo.durationMinutes = duration;


      slot.resource.push(resource);


      // Find matching tasks
      const resourceTasks = tasks.filter((taskItem: any, taskRowIndex: number) =>
        year === taskItem.plan.rruleIndex.year &&
        week === taskItem.plan.rruleIndex.week &&
        weekday === taskItem.plan.rruleIndex.weekday &&
        timeslot === taskItem.plan.rruleIndex.timeslot &&
        resource.resourceId === taskItem.plan.resourceId
      );

      // Set some defaults
      resourceTasks.forEach(task => {
        if (!task.stepTimePlanned || task.stepTimePlanned === 0)
          task.stepTimePlanned = task.totalStepTimePlanned;

        task.competenceNav ??= this.getItemCompetenceNav(task, resource);

      });

      // Push tasks to resource
      (resource.task ??= []).push(...resourceTasks);
    });

    // Add timing info to days
    days.forEach(day => {
      const [start, end, duration, span] = dayStartEndTotal(day);

      day.startMinute = start;
      day.endMinute = end;
      day.duration = duration;
      day.span = span;
    });

    // Add resource -> timeslot grouped tasks
    days.map(day => {
      const resourceMap = new Map<number, PlannedResource[]>();

      // Group all resources
      day.timeslots.forEach(timeslot => {
        timeslot.resource.forEach(resource => {
          let list = resourceMap.get(resource.resourceId);

          if (!list) {
            list = [];
            resourceMap.set(resource.resourceId, list);
          }

          list.push(resource);
        });

      });

      // Merge all grouped resources into single ones.
      const resources = [...resourceMap.values()].map(resources => {
        return {
          resource: resources[0] as Resource,
          timeslots: day.timeslots.map((slot, idx) => ({ timeslot: slot.timeslot, timeslotInfo: slot.timeslotInfo, tasks: resources[idx].task }))
        };
      });


      day.resources = resources;
      return resourceMap;
    });

    // Resort planning items
    unplanned.forEach(resource =>
      resource.task?.sort((a, b) => a.sortOrder - b.sortOrder)
    );

    (days as PlanningData[]).forEach(day =>
      day.timeslots.forEach(timeslot =>
        timeslot.resource.forEach(resource =>
          resource.task?.sort((a, b) => a.sortOrder - b.sortOrder)
        )
      )
    );

    const daysAsync = [] as PlanningData[];

    days.forEach((day, index) => {
      if (index === 0) {
        daysAsync.push(day);
      } else {
        setTimeout(() => {
          daysAsync.push(day);
        });
      }
    });

    return {
      unplannedData: unplanned,
      plannedData: daysAsync
    };
  }

  getPlanningData(dataGroupItem: DataGroup) {
    return new Promise((resolve, reject) => {
      const subscription: Subscription = this.dataService.getDataTable(dataGroupItem, dataGroupItem.db, dataGroupItem.src[0].name, []).subscribe(dataTable => {
        subscription.unsubscribe();

        this.dataService.processData({
          data: dataGroupItem.dataTable[0].data,
          fields: dataGroupItem.dataTable[0].fields,
          parse: true
        });

        dataGroupItem.dataTable = dataTable;

        console.log(dataTable);

        resolve(dataTable[0]);
      });
    });
  }

}
