import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-report-item',
  templateUrl: './report-item.component.html',
  styleUrls: ['./report-item.component.scss']
})
export class ReportItemComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public reportType: string = '';

  ngOnInit(): void {
    super.ngOnInit();

    this.dataGroupItemInstance.params.forEach((param: Param) => {
      if (param.key === 'reportType') {
        this.reportType = param.val;
      }
    });
  }

  ngOnDestroy():void {
    super.ngOnDestroy();
  }
}
