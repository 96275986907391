import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrl: './edit-link.component.scss'
})
export class EditLinkComponent {
  public paramJSON: any = {
    widgetId: 'dataListEditComposition',
    src: 'dataListEditComposition',
    dataGroup: null
  }

  public tabstripJSON: any = {
    widgetId: 'xfw.component-datagroup',
    src: 'xfw.component-datagroup'
  }
}
