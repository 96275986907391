import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfiguratorProduct, ConfiguratorProductAddition, ConfiguratorResult } from 'src/app/configurator';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';
import { EventParams, Nav, Param, WidgetEvent, WidgetState } from 'src/app/widgets';

const editConfiguratorChildIndex = 4;

@Component({
  selector: 'app-edit-list',
  templateUrl: './edit-list.component.html',
  styleUrls: ['../configurator-list/configurator-list.component.scss', './edit-list.component.scss']
})
export class EditListComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public additions: ConfiguratorProductAddition[];
  public configuration: ConfiguratorResult;
  public configurationResult: ConfiguratorResult;

  public configuratorRowsDataGroupItemInstance;
  public editDataGroupItemInstance;

  public configuratorInput: boolean = true;

  @Input() state: string;

  public addRowNav: Nav;
  public editNav: Nav;
  public editTab: string = 'configuratorInput';

  public configuratorReady: boolean = false;

  public productJSON: ConfiguratorProduct;

  ngOnInit(): void {
    this.initNav();
    super.ngOnInit();

    this.editDataGroupItemInstance = this.dataGroupItemInstance.children[editConfiguratorChildIndex];
    this.configuratorRowsDataGroupItemInstance = this.editDataGroupItemInstance.children[0];

    this.communicationService.initWidget({
      widgetId: this.dataGroupItemInstance.widgetId,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [this.dataGroupItemInstance.widgetId],
          event: WidgetEvent.SAVED,
          func: 'updateConfigurator'
        },
        {
          widgetGroup: [this.dataGroupItemInstance.widgetId],
          event: WidgetEvent.REFRESHED,
          func: 'setEditConfigurator'
        },
        {
          widgetGroup: [this.dataGroupItemInstance.widgetId],
          event: WidgetEvent.EDITMODECHANGED,
          func: 'setEditConfigurator'
        }
      ]
    });

    this.communicationService.initWidget({
      widgetId: this.configuratorRowsDataGroupItemInstance.widgetId,
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [
            this.configuratorRowsDataGroupItemInstance.widgetId,
            this.configuratorRowsDataGroupItemInstance.widgetId + '_form'
          ],
          event: WidgetEvent.SAVE,
          func: 'updateConfiguratorRow'
        },
        {
          widgetGroup: [
            this.configuratorRowsDataGroupItemInstance.widgetId,
            this.configuratorRowsDataGroupItemInstance.widgetId + '_form'
          ],
          event: WidgetEvent.CLOSE,
          func: 'close'
        }
      ]
    });
  }

  cancelWindow(event) {
    this.configuratorService.configurator.state.configuratorEditor = false;
  }

  updateConfiguratorRow(eventParams: EventParams): any {
    return new Promise((resolve, reject) => {
      this.configuratorRowsDataGroupItemInstance.dataTable[0].data.forEach(row => {
        row.crud = null;
        row.block.name = row.name;
        row.block.description = row.description;
      });

      resolve(eventParams.data);
    });
  }

  setEditConfigurator() {
    if (this.dataGroupItemInstance.dataTable[0]?.data?.length) {
      const preset = this.dataGroupItemInstance.children[editConfiguratorChildIndex].dataTable[0];

      this.dataGroupItemInstance.children[editConfiguratorChildIndex].dataTable = this.dataGroupItemInstance.dataTable;
      const localDataGroupItemInstance = this.dataGroupItemInstance.children[editConfiguratorChildIndex];

      Object.keys(preset).forEach(key => {
        localDataGroupItemInstance.dataTable[0][key] = preset[key];
      });

      localDataGroupItemInstance.dataTable[0].data[0].crud = 'edit';

      this.productJSON = localDataGroupItemInstance.dataTable[0].data[0].productJSON;

      this.productJSON.rows.forEach((row, rowIndex) => {
        const options: any = [];

        row.options ||= [];

        row.options.forEach((rowOption, optionIndex) => {
          rowOption.crud = null;
          rowOption.itemIndex = rowIndex;
          rowOption.trackBy = optionIndex;
          rowOption.optionCode = rowOption.code;
          rowOption.isSelected = false;
          rowOption.state ||= {};
          rowOption.text = [rowOption.block.name, ' | ', rowOption.code].join('');
          rowOption.conditionalCodesExt ||= [];
        });

        row.crud = null;
        row.state.isSelected = false;
        row.state.expanded = (rowIndex === 0);
        row.rowCode = row.code;
        row.name = row.block.name;
        row.description = row.block.description;
        row.conditionalCodesExt ||= [];
        row.text = [row.block.name, ' | ', row.code].join('');
      });

      // get dataTables for configuratorRows and options
      this.dataService.getDataTable(
        this.configuratorRowsDataGroupItemInstance,
        this.configuratorRowsDataGroupItemInstance.db,
        this.configuratorRowsDataGroupItemInstance.src[0].name, [])
      .subscribe(data => {
        this.configuratorRowsDataGroupItemInstance.dataTable = data;
        this.configuratorRowsDataGroupItemInstance.dataTable[0].data = this.productJSON.rows;
        this.configuratorRowsDataGroupItemInstance.dataTable[0].originalData = structuredClone(this.productJSON.rows);

        this.dataService.getDataTable(
          this.configuratorRowsDataGroupItemInstance.children[0],
          this.configuratorRowsDataGroupItemInstance.children[0].db,
          this.configuratorRowsDataGroupItemInstance.children[0].src[0].name, [])
        .subscribe(data => {
          this.configuratorRowsDataGroupItemInstance.children[0].dataTable = data;

          this.configuratorReady = true;
          this.loaded = true;

          this.cleanRowsAndOptions(this.productJSON);

          this.configuratorService.configurator.state.configuratorEditor = true;

          // console.log(structuredClone(this.dataGroupItemInstance));
        });
      });
    }
  }

  cleanRowsAndOptions(product: ConfiguratorProduct) {
    // const deleteProps = ['sortOrder', 'edit', 'invalid', 'crud', 'selected', 'name', 'description'];
    // product.rows.forEach(row => {
    //   for (let prop in row) {
    //     (row[prop] === null || row[prop] === undefined || deleteProps.includes(prop)) &&
    //         delete row[prop];
    //   }

    //   row.options.forEach(option => {
  //       for (let prop in option) {
  //           (option[prop] === null || option[prop] === undefined || deleteProps.includes(prop)) &&
  //               delete option[prop];
  //       }
    //   });
    // });

    // product.rows.forEach(row => {
    //   row.options.forEach(option => {
    //     const grouped = option.conditionalCodesExt.reduce((map, { itemIndex, codes }) => {
    //       const existingCodes = map.get(itemIndex) || [];
    //       map.set(itemIndex, [...new Set([...existingCodes, ...[codes]])]);
    //       return map;
    //     }, new Map());

    //     option.conditionalCodesExt = Array.from(grouped, ([itemIndex, codes]) => ({ itemIndex, codes }));
    //   });

    //   const grouped = row.conditionalCodesExt.reduce((map, { itemIndex, codes }) => {
    //     const existingCodes = map.get(itemIndex) || [];
    //     map.set(itemIndex, [...new Set([...existingCodes, ...[codes]])]);
    //     return map;
    //   }, new Map());

    //   row.conditionalCodesExt = Array.from(grouped, ([itemIndex, codes]) => ({ itemIndex, codes }));
    // });
  }

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([
      this.dataGroupItemInstance.widgetId,
      this.configuratorRowsDataGroupItemInstance.widgetId
    ]);
    super.ngOnDestroy();
  }

  selectTab(params: Param[]) {
    this.editTab = params[0].val;
    this.editNav.menu.forEach(menuItem => {
      menuItem.isSelected = menuItem.params[0].val === params[0].val;
    });
  }

  initNav() {
    this.editNav = {
      navId: 1,
      menu: [
        {
          type: "button",
          className: "cmd text rounded-corners xfw3-bg-secondary",
          icon: '',
          text: 'Vragen en opties',
          isSelected: this.editTab === 'configuratorInput',
          noToggle: false,
          func: 'selectTab',
          params: [
            {
              key: 'tab',
              val: 'configuratorInput'
            }
          ]
        },
        {
          type: "button",
          className: "cmd text rounded-corners xfw3-bg-secondary",
          icon: '',
          text: 'Dependenties',
          isSelected: this.editTab === 'conditionalCodes',
          noToggle: false,
          func: 'selectTab',
          params: [
            {
              key: 'tab',
              val: 'conditionalCodes'
            }
          ]
        },
        {
          type: "button",
          className: "cmd text rounded-corners xfw3-bg-secondary",
          icon: '',
          text: 'Koppelingen',
          isSelected: this.editTab === 'link',
          noToggle: false,
          func: 'selectTab',
          params: [
            {
              key: 'tab',
              val: 'link'
            }
          ]
        }
      ]
    }

    this.addRowNav = {
      navId: 1,
      primary: {
        icon: 'fa-solid fa-plus',
        text: '',
        className: "icon xfw3-bg-secondary round xxs",
        type: '',
        info: 'Toevoegen',
        func: 'addNewRow',
        params: [],
        disabled: false
      }
    }
  }

  updateProduct(event) {
    console.log('updateProduct', event);
  }

  addNewRow(eventParams: EventParams) {
    this.configuratorRowsDataGroupItemInstance.dataTable[0].data.forEach(row => row.isSelected = false);

    const dataItem: any = this.configuratorService.getNewRow(this.configuratorRowsDataGroupItemInstance);

    this.index = dataItem.trackBy - 1;
    this.configuratorRowsDataGroupItemInstance.dataTable[0].data.push(dataItem);

    this.edit.mode = true;
  }

  dropRow(event) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)

    this.configuratorRowsDataGroupItemInstance.dataTable[0].data.forEach((row, rowIndex) => {
      row.trackBy = rowIndex;
      row.sortOrder = rowIndex;
      row.itemIndex = rowIndex;

      row.options.forEach(option => {
        option.itemIndex = rowIndex;
        option.conditionalCodesExt.forEach(conditionalCode => {
          conditionalCode.itemIndex = rowIndex;
        });
      });
    });

    console.log(structuredClone(this.configuratorRowsDataGroupItemInstance.dataTable[0].data));

    // this.updateMutations();
  }

  editMode(eventParams: EventParams) {
    this.index = eventParams.dataItem.trackBy - 1;
    this.edit.mode = true;
  }

  close(event) {
    if (this.dataGroupItemInstance.dataTable) {
      this.dataGroupItemInstance.dataTable[0].data.forEach(row => row.edit = false);
      this.edit.mode = false;
    }
  }
}
