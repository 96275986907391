@if (loaded) {
  <div [ngClass]="['content']">
    @if (dataGroupItem.dataTable[0].data.length) {
      <app-title [contentJSON]="dataGroupItem?.contentJSON" [dataGroupItem]="dataGroupItem"/>
    }

    @for (item of dataGroupItem.dataTable[0].data; let index = $index; track index) {
      <div class="content-row-wrapper"
            [ngClass]="[dataGroupItem.dataClassName ?? '',
            !dataGroupItem.rowOptions.rowCollapsable ? 'collExpHidden' : '',
            (dataGroupItem.rowOptions.rowCollapsable && item.collapsed) ? 'collapsed' : '',
            (dataGroupItem.rowOptions.rowCollapsable && !item.collapsed) ? 'expanded' : '']">

        <div class="b-t-line"></div>

        <app-data-content-row #contentRow
          (collExp)="collExp($event)"
          [item]="{
              blocksId: item.blocksId,
              blockId: item.blockId,
              object: item.object,
              objectId: item.objectId,
              contentJSON: item.contentJSON,
              srt: item.sortOrder
            }"
          [rowIndex]="index"/>
      </div>
    }
  </div>
}
