import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
  }

  trackByFn(index, item) {
    return index;
  }
}
