import { Component } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  public delay = {
    error: 4000,
    warning: 3000,
    info: 1500,
    remark: 3000,
    devInfo: 4000,
    devWarning: 4000,
    devError: 4000
  };

  constructor(public notificationService: NotificationService) {
    // setTimeout(() => this.isMenuOpen = true, 200) // debug auto popup
  }

  get hasNotification(): boolean {
    return false;
  }

  public isMenuOpen: boolean = false;

  /**
   * This method is used to open an window with the error details of a notification.
   * @param id id of the notification
   */
  openDetails(notification) {
    notification.windowOpen = true;
  }

  /**
   * This method is used to close an window with the error details of a notification.
   * @param id id of the notification
   */
  closeDetails(notification) {
    notification.windowOpen = false;
  }

  trackByFn(index, item) {
    return index;
  }
}
