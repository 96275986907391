import { Pipe, PipeTransform } from '@angular/core';
import { ParamFrom } from 'src/app/widgets';

@Pipe({
  name: 'paramsFromKey'
})
export class ParamsFromKeyPipe implements PipeTransform {

  transform(dataItem: unknown, paramsFrom: ParamFrom[]): unknown {
    const paramsFromKeys: string[] = [];
    paramsFrom.forEach(paramFrom => {
      dataItem && dataItem[paramFrom.key] && paramsFromKeys.push(dataItem[paramFrom.key]);
    });

    return paramsFromKeys.length ? '_' + paramsFromKeys.join('-') : '';
  }

}
