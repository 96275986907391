import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasOverlap'
})
export class HasOverlapPipe implements PipeTransform {

  transform(values: string[] = [], args: string[] = []): unknown {
    return values.filter(value => args.includes(value)).length;
  }

}
