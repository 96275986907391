import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { EventParams, Nav, WidgetEvent } from 'src/app/widgets';
import { NetworkService } from 'src/app/services/network.service';
import { TempConfiguratorService } from 'src/app/services/temp-configurator.service';
import { lastValueFrom } from 'rxjs';

const editConfiguratorChildIndex = 4;

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss']
})
export class ConfiguratorComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() dataGroupItemInstance: DataGroup;

  public loaded: boolean = false;
  public component = this;

  public accessoriesEmpty: boolean = false;

  constructor(
    public configuratorService: ConfiguratorService,
    public networkService: NetworkService,
    private communicationService: CommunicationService,
    private tempConfiguratorService: TempConfiguratorService
  ) {
  }

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([
      this.dataGroupItemInstance.widgetId + '_container'
    ]);
  }

  ngOnInit(): void {
    this.communicationService.initWidget({
      widgetId: this.dataGroupItemInstance.widgetId + '_container',
      component: this,
      subscribeTo: [
        {
          widgetGroup: ['productConfig'],
          event: WidgetEvent.SAVED,
          func: 'getNas',
          params: [
            {
              key: 'configured',
              val: true
            },
            {
              key: 'nasConfigured',
              val: false
            },
            {
              key: 'accessoryConfigured',
              val: false
            },
            {
              key: 'calculated',
              val: false
            },
            {
              key: 'priceTierSelected',
              val: false
            }
          ]
        },
        {
          widgetGroup: ['productNas'],
          event: WidgetEvent.SAVED,
          func: 'nasChanged',
          params: [
            {
              key: 'nasConfigured',
              val: false
            },
            {
              key: 'accessoryConfigured',
              val: false
            },
            {
              key: 'calculated',
              val: false
            },
            {
              key: 'priceTierSelected',
              val: false
            }
          ]
        },
        {
          widgetGroup: ['productNas'],
          event: WidgetEvent.DONE, // dit is de 'Volgende' knop bij de NAS configuratie. Dit is een direct-save, dus kan niet op SAVED luisteren.
          func: 'getAccessories',
          params: [
            {
              key: 'nasConfigured',
              val: true
            },
            {
              key: 'accessoryConfigured',
              val: false
            },
            {
              key: 'priceTierSelected',
              val: false
            }
          ]
        },
        {
          widgetGroup: ['productNas', 'productNas_form'],
          event: WidgetEvent.FORMSINVALID,
          func: 'productNasForm',
          params: [
            {
              key: 'formValid',
              val: false
            }
          ]
        },
        {
          widgetGroup: ['productNas', 'productNas_form'],
          event: WidgetEvent.FORMSVALID,
          func: 'productNasForm',
          params: [
            {
              key: 'formValid',
              val: true
            }
          ]
        },
        {
          widgetGroup: ['accessoryConfig'],
          event: WidgetEvent.SAVED, // dit is de volgende knop bij de accessory.
          func: 'calculate',
          params: [
            {
              key: 'accessoryConfigured',
              val: true
            },
            {
              key: 'priceTierSelected',
              val: false
            }
          ]
        },
        {
          widgetGroup: [
            // this.dataGroupItem.children[0].widgetId, Zou voor een directe update zorgen na aanpassen aantal
            'formJobPriceTier'
          ],
          event: WidgetEvent.SAVED,
          func: 'calculate',
          params: [
            {
              key: 'priceTierSelected',
              val: false
            }
          ]
        },
        {
          widgetGroup: ['dataMatrixJobPriceTier'],
          event: WidgetEvent.REQUEST,
          func: 'updatePriceTier',
          params: [
            {
              key: 'calculated',
              val: true
            }
          ]
        },
        {
          widgetGroup: ['dataMatrixJobPriceTier'],
          event: WidgetEvent.SELECTED,
          func: 'priceTierSelect',
          params: [
            {
              key: 'priceTierSelected',
              val: true
            }
          ]
        }
      ]
    });

    this.setEditMode(null);
  }

  configuratorEditor(event) {
    this.configuratorService.configurator.state.configuratorEditor = true;
  }

  setEditMode(eventParams: EventParams) {
    this.configuratorService.configurator.state.editMode = !this.configuratorService.configurator.state.editMode;

    setTimeout(() => {
      this.communicationService.performAction({
        info: 'changeEditMode',
        widgetGroup: [this.dataGroupItemInstance.widgetId, this.dataGroupItemInstance.children[editConfiguratorChildIndex].widgetId],
        event: WidgetEvent.EDITMODECHANGED
      });
    });
  }

  productNasForm(eventParams: EventParams) {
    this.dataGroupItemInstance.children[0].footer.nav.primary.disabled = !eventParams.params.find(param => param.key === 'formValid').val;
  }

  nasChanged(eventParams: EventParams) {
    eventParams.params.forEach(param => {
      this.configuratorService.configurator.state[param.key] = param.val;
    });
  }

  getNas(eventParams: EventParams) {
    this.communicationService.performAction({
      info: 'nasDataRefresh',
      widgetGroup: ['productNas'],
      event: WidgetEvent.REFRESH
    });

    eventParams.params.forEach(param => {
      this.configuratorService.configurator.state[param.key] = param.val;
    });
  }

  getAccessories(eventParams: EventParams) {
    if (this.dataGroupItemInstance.dataTable[0].data[0].metaJSON.hasAccessories) {
      this.accessoriesEmpty = false;

      this.configuratorService.configurator.state.calculating = true;

      lastValueFrom(this.tempConfiguratorService.getAccessories(
        this.dataGroupItemInstance.dataTable[0].data[0].configurationJSON,
        this.configuratorService.getNasData(this.dataGroupItemInstance.children[0].dataTable[0]),
        this.dataGroupItemInstance.dataTable[0].data[0].compId,
        this.dataGroupItemInstance.dataTable[0].data[0].containerId)).then((data) => {

          if (data[0].filter((x: any) => x.accessoryJSON).length) {
            eventParams.params.forEach(param => {
              this.configuratorService.configurator.state[param.key] = param.val;
            });

            this.configuratorService.configurator.state.calculating = false;

            setTimeout(() => {
              document.getElementById(this.dataGroupItemInstance.children[1].widgetId).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });;
            }, 200);
          } else {
            this.accessoriesEmpty = true;
            this.calculate(eventParams);
          }
      });
    } else {
      this.calculate(eventParams);

      // this.configuratorService.configurator.state['calculated'] = param.val;

      // this.updatePriceTier({
      //   params: [
      //     {
      //       key: 'calculated',
      //       val: true
      //     },
      //     {
      //       key: 'priceTierSelected',
      //       val: false
      //     }
      //   ]});
    }
  }

  calculate(eventParams: EventParams) {
    this.configuratorService.configurator.state.calculating = true;

    const priceTiers: { amount: number } [] = this.dataGroupItemInstance.dataTable[0].data[0].configurationJSON.priceTiers;

    this.dataGroupItemInstance.children[2].children[0].dataTable[0]?.data &&
      this.dataGroupItemInstance.children[2].children[0].dataTable[0]?.data.forEach(row => {
        !priceTiers.map(priceTier => priceTier.amount).includes(row.amount) && priceTiers.push({ amount: row.amount });
      });

    lastValueFrom(this.tempConfiguratorService.getPrices(
      this.dataGroupItemInstance.dataTable[0].data[0].configurationJSON,
      this.configuratorService.getNasData(this.dataGroupItemInstance.children[0].dataTable[0]),
      [],
      this.dataGroupItemInstance.dataTable[0].data[0].compId)).then((data) => {

        eventParams.params.forEach(param => {
          this.configuratorService.configurator.state[param.key] = param.val;
        });

        this.configuratorService.configurator.state.calculating = false;

        this.communicationService.performAction({
          info: 'requestPriceTier',
          widgetGroup: ['dataMatrixJobPriceTier'],
          event: WidgetEvent.REQUEST,
          data: eventParams.data
        });

        setTimeout(() => {
          document.getElementById(this.dataGroupItemInstance.children[2].widgetId).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });;
        }, 200);
    });
  }

  priceTierSelect(eventParams: EventParams) {
    eventParams.params.forEach(param => {
      this.configuratorService.configurator.state[param.key] = param.val;
    });

    this.dataGroupItemInstance.dataTable[0].data[0].crud = 'putInBasket';

    setTimeout(() => {
      document.getElementById(this.dataGroupItemInstance.children[3].widgetId).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });;
    }, 200);
  }

  updatePriceTier(eventParams: EventParams) {
    this.communicationService.performAction({
      info: 'priceTier',
      widgetGroup: ['dataMatrixJobPriceTier'],
      event: WidgetEvent.REFRESH
    });

    eventParams.params.forEach(param => {
      this.configuratorService.configurator.state[param.key] = param.val;
    });

    setTimeout(() => {
      document.getElementById(this.dataGroupItemInstance.children[2].widgetId).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });;
    }, 200);
  }

  onHasData(event, dataGroupItem) {
    dataGroupItem.isEmpty = (event.dataLength === 0);
  }
}
