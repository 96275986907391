import { Component, OnInit, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';
import { Nav, Param } from 'src/app/widgets';
import { editType } from 'src/app/data';

@Component({
  selector: 'app-data-tabs',
  templateUrl: './data-tabs.component.html',
  styleUrls: ['./data-tabs.component.css']
})
export class DataTabsComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public nav: Nav;
  public dataTabSelected: number;

  ngOnInit() {
    super.ngOnInit();
    this.initNav();
  }

  initNav() {
    this.nav = {
      navId: 0,
      primary: {
        navItemId: 0,
        icon: 'fa-solid fa-plus',
        text: '',
        className: "icon xfw3-bg-secondary round xs",
        type: '',
        info: 'Toevoegen',
        func: 'addNewRow',
        params: [],
        disabled: false,
        isInvisible: !this.dataGroupItemInstance.edit.create
      }
    }
  }

  addNewRow(params: Param[], dataItem?, data?) {
    this.addRow({
      params,
      dataItem,
      data
    });

    if (['save', 'direct'].includes(this.dataGroupItemInstance.edit.updateMutations)) {
      this.setEditMode({
        params: [],
        dataItem: this.dataGroupItemInstance.dataTable[0].data.find(row => row.isSelected),
        data: []
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  close(event) {
    if (this.dataGroupItemInstance.dataTable) {
      this.dataGroupItemInstance.dataTable[0].data.forEach(row => row.edit = false);
      this.edit.mode = false;
    }
  }
}
