@if (loaded) {
  <div class="option-info">
    <app-data-group-item
      [widgetId]="dataGroupItemInstance.widgetId"
      [dataGroupItem]="dataGroupItemInstance"
      [dataItem]="dataItem"/>
    <app-data-group-item
      [widgetId]="dataGroupItemInstance.children[0].widgetId"
      [dataGroupItem]="dataGroupItemInstance.children[0]"
      [dataItem]="dataItem"/>
  </div>
}
