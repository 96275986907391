import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-relations',
  templateUrl: './report-relations.component.html',
  styleUrls: ['../report.component.scss']
})
export class ReportRelationsComponent {
  @Input() data: any;
}
