@if (communicationService.user.config?.actionCenter) {
  <div [class]="'action-buttons'"
       divBreakpointClass>
    <div [style.right]="'calc(1rem + ' + ((communicationService.panels.sidebarSetting.main !== 100) ? communicationService.panels.sidebarSetting.sidebar : 0).toString() + '% )'"
         [ngClass]="{'action-center-btn': true, 'selected': communicationService.user.config.actionCenter.visible}">
      <app-cmd [nav]="nav" [className]="" [navList]="['menu']" [component]="component" [navVisible]="4"/>
      @if (communicationService.user.config.actionCenter.unreadActions > 0) {
        <div class="action-center-btn-info">
          {{ communicationService.user.config.actionCenter.unreadActions }}
        </div>
      }
    </div>
  </div>
}
