@if (editMode && loaded) {
  <form [formGroup]="form" [ngClass]="['xfw3-form', 'xfw3-grid-container', formClass || '', errors ? 'errors' : '']" divBreakpointClass>
    <div class="xfw3-grid-col-container">
      @for (group of dataGroupItemInstance.dataTable[0].fields; track $index) {
        <div [ngClass]="[group.fieldGroupClassName ? (group.fieldGroupClassName | colToGCol) : 'g-col-12']">
          @if (group.title) {
            <div class="xfw3-group">
              {{ group.title }}
            </div>
          }
          <div class="xfw3-grid-col-container">
            @for (fieldGroup of group.fieldGroup | exclude: 'type' : ['hidden','hide']; track $index) {
              <ng-container *accessLevel="fieldGroup.accessLevel">
                @if (formClass?.includes('rows') && (fieldGroup.templateOptions.label.text ?? fieldGroup.templateOptions.label)) {
                  <div [ngClass]="['g-start', fieldGroup.hideWhenDisabled ? 'hide-when-disabled' : '']">
                    <label [ngClass]="['xfw3-label', fieldGroup.templateOptions.label.className  || '', fieldGroup.templateOptions?.format?.pipeToken || '']" [for]="fieldGroup.key"
                      [innerHTML]="(fieldGroup.templateOptions.label.text ?? fieldGroup.templateOptions.label ?? '&nbsp;') | safeHtml">
                    </label>
                    <span class="xfw3-label-info"
                      [innerHTML]="(fieldGroup.templateOptions.label.info ?? '') | safeHtml">
                    </span>
                  </div>
                }

                <div [ngClass]="[fieldGroup.className ? (fieldGroup.className | colToGCol) : '',
                                fieldGroup.hideWhenDisabled ? 'hide-when-disabled' : '', 'position-relative']">
                  @if (!formClass?.includes('xfw3-inline-form') && fieldGroup.templateOptions.label.text ?? fieldGroup.templateOptions.label) {
                    <div [ngClass]="[fieldGroup.templateOptions.label.className  || '']">
                    <label class="xfw3-label" [for]="fieldGroup.key"
                      [innerHTML]="(fieldGroup.templateOptions.label.text ?? fieldGroup.templateOptions.label ?? '&nbsp;') | safeHtml">
                    </label>
                    <span class="xfw3-label-info"
                      [innerHTML]="(fieldGroup.templateOptions.label.info ?? '') | safeHtml">
                    </span>
                  </div>
                  }

                  @if (['text'].includes(fieldGroup.type)) {
                    @if (fieldGroup.xfw.nav) {
                      <app-cmd *ngIf="fieldGroup.xfw.nav"
                        [component]="component"
                        [dataItem]="dataGroupItemInstance.dataTable[0].data[rowIndex]"
                        [nav]="fieldGroup.xfw.nav"
                        [navList]="['hyperlink']"
                        [text]="dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key]"
                        [className]="'xfw3-value'"
                        [navVisible]="1"/>
                    } @else {
                      <div class="xfw3-value" [attr.data-focus]="fieldGroup.key"
                        [innerHTML]="(dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key] !== null ? dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key] : '&nbsp;') | safeHtml">
                      </div>
                    }
                  } @else if (['hyperlink'].includes(fieldGroup.type)) {
                    <div class="xfw3-form-control">
                      <a [href]="dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key].url">{{ dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key].text }}</a>
                    </div>
                  } @else {
                    <div [ngClass]="['xfw3-form-group', fieldGroup.templateOptions?.format?.pipeToken || '']">
                      @if (['input', 'email', 'phone', 'number', 'password', 'color', 'img', 'thumb', 'inputMl', 'time'].includes(fieldGroup.type)) {
                        <input #formControl
                          [ngClass]="['xfw3-form-control', fieldGroup.type === 'color' ? 'color' : '']"
                          [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                          [readonly]="fieldGroup.templateOptions.readOnly"
                          [type]="fieldGroup.templateOptions.showPassword ? 'input' : fieldGroup.type"
                          [id]="fieldGroup.key + '-' + rowIndex" [formControlName]="fieldGroup.key"
                          [min]="fieldGroup.templateOptions.min" [max]="fieldGroup.templateOptions.max"
                          [step]="fieldGroup.templateOptions.step" (paste)="paste($event, formControl)"
                          (keyup)="checkKeyUp({field: fieldGroup.key, type: fieldGroup.type, event: $event, inputArea: formControl})"
                          (focus)="cellFocus(fieldGroup.key)" (blur)="checkInput(fieldGroup.key, fieldGroup.type)"
                          [appMask]="fieldGroup?.mask">
                        @if (fieldGroup.type === 'password') {
                          <div class="show-password fa-thin fa-eye"
                          (click)="fieldGroup.type === 'password' && fieldGroup.templateOptions.showPassword = !fieldGroup.templateOptions.showPassword">
                        </div>
                        }
                      }@else if (fieldGroup.type === 'newPassword') {
                        <input #formControl
                          class="xfw3-form-control"
                          [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                          type="password" [id]="fieldGroup.key + '-' + rowIndex"
                          [formControlName]="fieldGroup.key"
                          [readonly]="fieldGroup.templateOptions.readOnly" [min]="fieldGroup.templateOptions.min"
                          [max]="fieldGroup.templateOptions.max" [step]="fieldGroup.templateOptions.step"
                          (paste)="paste($event, formControl)"
                          (keyup)="checkKeyUp({field: fieldGroup.key, type: fieldGroup.type, event: $event, inputArea: formControl})"
                          (focus)="cellFocus(fieldGroup.key)" (blur)="checkInput(fieldGroup.key, fieldGroup.type)"
                          [appMask]="fieldGroup?.mask">
                        <!-- <ul *ngIf="!form.value[fieldGroup.key]">
                            <li *ngIf="form.value[fieldGroup.key].length < 8">Wachtwoord moet minimaal 8 characters bevatten</li>
                            <li *ngIf="!form.value[fieldGroup.key].match('(?=.*[A-Z])')">Wachtwoord moet minimaal 1 hoofdletter bevatten</li>
                            <li *ngIf="!form.value[fieldGroup.key].match('(?=.*[a-z])')">Wachtwoord moet minimaal 1 kleine letter bevatten</li>
                            <li *ngIf="!form.value[fieldGroup.key].match('.*[0-9].*')">Wachtwoord moet minimaal 1 cijfer bevatten</li>
                            <li *ngIf="!form.value[fieldGroup.key].match('(.*\W.*)')">Wachtwoord moet minimaal 1 speciaal teken bevatten</li>
                          </ul> -->
                      }@else if (fieldGroup.type === 'multiline') {
                        {{ form.controls[fieldGroup.key].disabled ? 'disabled' : '' }}
                        <textarea
                          class="xfw3-form-control"
                          [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                          rows="5"
                          #formControl
                          (paste)="paste($event, formControl)" (focus)="cellFocus(fieldGroup.key)"
                          (blur)="checkInput(fieldGroup.key, fieldGroup.type)"
                          [id]="fieldGroup.key + '-' + rowIndex"
                          [formControlName]="fieldGroup.key"></textarea>
                      }@else if (fieldGroup.type === 'inputCalc') {
                        <input
                          class="xfw3-form-control"
                          [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                          #formControl
                          [readonly]="fieldGroup.templateOptions.readOnly"
                          type="text"
                          [id]="fieldGroup.key + '-' + rowIndex"
                          [formControlName]="fieldGroup.key"
                          (keyup)="checkKeyUp({field: fieldGroup.key, type: fieldGroup.type, event: $event, inputArea: formControl})"
                          (paste)="paste($event, formControl)"
                          (focus)="cellFocus(fieldGroup.key)"
                          (blur)="checkInput(fieldGroup.key, fieldGroup.type)">
                        @if (fieldGroup.proportional?.length) {
                          <div class="proportional"></div>
                        }
                      }@else if (fieldGroup.type === 'inputDates') {
                        @if (fieldGroup.templateOptions.readOnly) {
                          <div class="xfw3-form-control">
                            {{ dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key] | date : 'dd-MM-yyyy HH:mm' }}
                          </div>
                        }@else {
                          <div class="xfw3-form-control xfw3-form-control-component form-date">
                            <app-date-picker
                              [key]="fieldGroup.key"
                              [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                              [formControlName]="fieldGroup.key"
                              [type]="'multi'"
                              [id]="fieldGroup.key + '-' + rowIndex"
                              (onPaste)="paste($event)"
                              (focus)="cellFocus(fieldGroup.key)"
                              (blur)="checkInput(fieldGroup.key, fieldGroup.type)"/>
                          </div>
                        }
                      }@else if (fieldGroup.type === 'inputDate') {
                        @if (fieldGroup.templateOptions.readOnly) {
                          <div class="xfw3-form-control">
                            {{ dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key] | date : 'dd-MM-yyyy HH:mm' }}
                          </div>
                        }@else {
                          <div class="xfw3-form-control xfw3-form-control-component form-date">
                            <app-date-picker [key]="fieldGroup.key"
                              [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                              [formControlName]="fieldGroup.key"
                              [type]="'single'"
                              [id]="fieldGroup.key + '-' + rowIndex"
                              (onPaste)="paste($event)"
                              (focus)="cellFocus(fieldGroup.key)"
                              (blur)="checkInput(fieldGroup.key, fieldGroup.type)"/>
                          </div>
                        }
                      }@else if (fieldGroup.type === 'editor') {
                            <quill-editor class="xfw3-form-control xfw3-form-control-component form-editor"
                              [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                              [formControlName]="fieldGroup.key"
                              [id]="fieldGroup.key + '-' + rowIndex"
                              (focus)="cellFocus(fieldGroup.key)"
                              (onBlur)="checkInput(fieldGroup.key, fieldGroup.type)" theme="snow" placeholder=" "></quill-editor>
                      }@else if (['dropdownList', 'bitmapSelect', 'multiSelect', 'comboBox'].includes(fieldGroup.type)) {
                        <div [ngClass]="[form.controls[fieldGroup.key].disabled ? 'disabled' : '', 'xfw3-form-control xfw3-form-control-component']">
                          @if (lookupTables[fieldGroup.xfw.inputData.src].data) {
                            <app-multi-select #formControl
                              [id]="fieldGroup.key + '-' + rowIndex"
                              [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                              [formControlName]="fieldGroup.key"
                              [className]="formClass"
                              [type]="fieldGroup.type"
                              [data]="lookupTables[fieldGroup.xfw.inputData.src].data"
                              [textField]="fieldGroup.xfw.inputData.textField"
                              [valueField]="fieldGroup.xfw.inputData.valueField"
                              [isJSON]="fieldGroup.xfw.isJSON"
                              [readOnly]="fieldGroup.templateOptions.readOnly"
                              (onPaste)="paste($event)"
                              (onKeyup)="checkKeyUp($event)"
                              (onFilterChange)="filterChange(fieldGroup.xfw.inputData.src, $event)"
                              (onFocus)="cellFocus(fieldGroup.key)"
                              (onBlur)="checkInput(fieldGroup.key, fieldGroup.type)"
                              (close)="onClose($event)"/>
                          }
                        </div>
                      }@else if (['optionListCheckbox', 'optionListRadio'].includes(fieldGroup.type)) {
                        <div
                          [ngClass]="[form.controls[fieldGroup.key].disabled ? 'disabled' : '', 'xfw3-form-control-component']">
                          @if (lookupTables[fieldGroup.xfw.inputData.src].data) {
                            <app-option-list
                              [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                              [type]="fieldGroup.type === 'optionListCheckbox' ? 'checkbox' : 'radio'"
                              [id]="fieldGroup.key + '-' + rowIndex" [formControlName]="fieldGroup.key"
                              [data]="lookupTables[fieldGroup.xfw.inputData.src].data"
                              [textField]="fieldGroup.xfw.inputData.textField"
                              [valueField]="fieldGroup.xfw.inputData.valueField" (focus)="cellFocus(fieldGroup.key)"
                              (blur)="checkInput(fieldGroup.key, fieldGroup.type)"/>
                          }
                        </div>
                      }@else if (fieldGroup.type === 'upload') {
                        <div class="xfw3-form-control xfw3-form-control-component form-upload">
                          <xfw-uploader
                                [widgetId]="widgetId + '_upload-' + rowIndex"
                                [fileJSON]="{
                                dataFileJSON: dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key],
                                schema: fieldGroup.xfw.upload.schema,
                                fileUid: dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.xfw.upload.fileUidField ?? 'fileUid'],
                                path: fieldGroup.xfw.upload.path,
                                imgPath: fieldGroup.xfw.upload.imgPath,
                                thumbPath: fieldGroup.xfw.upload.thumbPath,
                                imgExtension: fieldGroup.xfw.upload.imgExtension,
                                thumbExtension: fieldGroup.xfw.upload.thumbExtension,
                                extensionIsThumbName: fieldGroup.xfw.upload.extensionIsThumbName,
                                imgAndThumbs: [{}],
                                name: fieldGroup.xfw.upload.name,
                                data: fieldGroup.xfw.upload.data
                              }"
                              [size]="'form-large'"
                              [extensions]="fieldGroup.xfw.upload.extensions"
                              (onBlur)="checkInput(fieldGroup.key, fieldGroup.type)"/>
                        </div>
                      }@else if (fieldGroup.type === 'upload-image-editable') {
                        <div class="xfw3-form-control xfw3-form-control-component form-upload-image-editable">
                          <app-image-uploader
                                [widgetId]="widgetId + '_upload-' + rowIndex"
                                [fileJSON]="{
                                dataFileJSON: dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key],
                                schema: fieldGroup.xfw.upload.schema,
                                fileUid: dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.xfw.upload.fileUidField ?? 'fileUid'],
                                path: fieldGroup.xfw.upload.path,
                                imgPath: fieldGroup.xfw.upload.imgPath,
                                thumbPath: fieldGroup.xfw.upload.thumbPath,
                                imgExtension: fieldGroup.xfw.upload.imgExtension,
                                thumbExtension: fieldGroup.xfw.upload.thumbExtension,
                                extensionIsThumbName: fieldGroup.xfw.upload.extensionIsThumbName,
                                imgAndThumbs: [{}],
                                name: fieldGroup.xfw.upload.name,
                                data: fieldGroup.xfw.upload.data
                          }" [size]="'form-large'"
                          [extensions]="fieldGroup.xfw.upload.extensions"
                          [formControlName]="fieldGroup.key"
                          [style.height.rem]="13"
                          [style.width.rem]="21"
                          (onBlur)="checkInput(fieldGroup.key, fieldGroup.type)"/>
                        </div>
                      }@else if (fieldGroup.type === 'duration') {
                        <div class="w-100">
                          <app-duration-input
                            (onBlur)="checkInput(fieldGroup.key, fieldGroup.type)"
                            [formControlName]="fieldGroup.key"/>
                        </div>
                      }@else if (fieldGroup.type === 'checkbox') {
                        <div class="check-mark">
                          <label [for]="fieldGroup.key + '-' + rowIndex" (click)="$event.stopPropagation()">
                            <input #formControl type="checkbox"
                              [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : fieldGroup.templateOptions.tabIndex ?? 0"
                              [readonly]="fieldGroup.templateOptions.readOnly" (focus)="cellFocus(fieldGroup.key)"
                              (change)="checkInput(fieldGroup.key, fieldGroup.type)" [id]="fieldGroup.key + '-' + rowIndex"
                              [formControlName]="fieldGroup.key">
                          </label>
                        </div>
                      }
                      @if (!form.controls[fieldGroup.key].valid) {
                        <div class="xfw3-error">
                          {{ fieldGroup.templateOptions?.errors?.text }}
                        </div>
                      }
                    </div>
                  }
                </div>
              </ng-container>
            }
          </div>
        </div>
      }
    </div>

    @if (dataGroupItemInstance.edit.updateMutations !== 'direct') {
      <div class="xfw3-form-cmd">
        @if (['inline','inlineForm', 'filterForm'].includes(dataGroupItemInstance.edit.type)) {
          <app-cmd
          [className]="'cmd icon round small filter-cmd'" [component]="component" [widgetId]="widgetId"
          [dataItem]="dataGroupItemInstance.dataTable[0].data[rowIndex]" [nav]="navInlineForm" [navList]="['primary']"
          [navVisible]="1"/>
        }@else if (['item', 'form', 'survey'].includes(dataGroupItemInstance.edit.type)) {
          <app-cmd [className]="'cmd-form'"
          [component]="component" [widgetId]="widgetId" [dataItem]="dataGroupItemInstance.dataTable[0].data[rowIndex]"
          [nav]="navForm" [navList]="navList" [navVisible]="navList.length"/>
        }
      </div>
    }
  </form>
}
