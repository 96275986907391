import { Pipe, PipeTransform } from '@angular/core';
import { CondFields } from 'src/app/data';
import { UtilsService } from 'src/app/services/utils.service';

@Pipe({
  name: 'condField'
})
export class CondFieldPipe implements PipeTransform {
  constructor(
    private utilsService: UtilsService
  ) { }

  transform(item: any, condFields: CondFields): unknown {
    return (this.utilsService.checkCond(item, condFields)) === 'disable' ? false : true;
  }
}
