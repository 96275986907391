import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DataGroup, DataTable } from 'src/app/data';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'src/app/services/communication.service';
import { WidgetEvent } from 'src/app/widgets';
import { NotificationService } from 'src/app/services/notification.service';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() dataGroupItemInstance: DataGroup;

  @Input() formClass: string;

  appState: number = 1;

  constructor(
    private networkService: NetworkService,
    private auth: AuthService,
    private notificationService: NotificationService,
    private communicationService: CommunicationService) {}

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([this.widgetId]);
  }

  ngOnInit() {
    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this,
      subscribeTo: [
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.SAVE,
          func: 'updateMutations',
          params: []
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.REQUEST,
          func: 'requestPassword'
        },
      ]
    });
  }

  updateMutations(params) {
    return new Promise((resolve, reject) => {
      this.login(params.data)

      resolve(true);
    });
  }

  login(response) {
    this.loginSubmit(response).then((response: any) => {
      if (response.result === 'OK') {
        console.log('Login ok', response);

        this.communicationService.performAction({
          event: WidgetEvent.CLOSE
        });
      } else if (response.result === 'NOK') {
        this.notificationService.addNotification({
          statusCode: 1,
          msgType: 'error',
          msg: {
            title: 'Login',
            body: {
              text: 'Inloggen niet gelukt',
              ml: {
                nl: {
                  text: 'Inloggen niet gelukt'
                },
                en: {
                  text: 'Login failed!'
                }
              }
            }
          },
          autoHide: false
        });
      }
    });
  }

  requestPassword() {
    let mutations = this.dataGroupItemInstance.dataTable[0].data[0];
    mutations.crud = 'forgotPassword';

    this.networkService.crud([{
      src: this.dataGroupItemInstance.dataTable[0].src,
      db: 'xfw3',
      procedure: this.dataGroupItemInstance.dataTable[0].procedure,
      mutations: [mutations],
    }], 'json').subscribe(result => {
      this.notificationService.addNotification({
        statusCode: 1,
          msgType: 'info',
          msg: {
            title: 'Login',
            body: {
              text: 'Paswoord is gemaild',
              ml: {
                nl: {
                  text: 'Paswoord is gemaild'
                },
                en: {
                  text: 'Password mail is sent!'
                }
              }
            }
          },
          autoHide: true
      })

      this.communicationService.performAction({
        widgetGroup: [this.widgetId],
        event: WidgetEvent.CLOSE
      });
    });
  }

  loginSubmit(model) {
    return new Promise((resolve, reject) => {
      if(model.email !== '') {
        this.appState = 2;

        this.auth.loginUser({
          email: model.email,
          password: model.password
        }).then(() => {
          this.appState = 1;

          resolve({
            appState: 1,
            result: 'OK'
          })

        }).catch(() => {
          this.appState = 3;
          console.log('login failed');

          resolve({
            appState: 3,
            result: 'NOK'
          });
        });
      }
    });
  }
}
