@if (loaded) {
  <div class="xfw3-grid-row-container"
       [style.grid-template-rows]="layout.gridTemplateRows">
    @for (dataGroupItem of dataGroup; track dataGroup) {
      @if (!dataGroupItem.isEmpty) {
        <div class="xfw3-grid-row-auto">
          <app-action-group-item
            (eventSelect)="select($event)"
            [dataGroup]="dataGroup"
            [layout]="layout"
            [widgetId]="dataGroupItem.widgetId"
            [dataGroupItem]="dataGroupItem"/>
        </div>
      }
    }
  </div>
}
