import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Resource } from 'src/app/services/planning.service';
import { Nav } from 'src/app/widgets';



const visibilityOptionsDefault = {
  map: false,
  details: false,
  collapseButton: false,
};


export type VisibilityOptions = typeof visibilityOptionsDefault;


@Component({
  selector: 'app-data-planner-resource',
  templateUrl: './data-planner-resource.component.html',
  styleUrls: ['./data-planner-resource.component.scss']
})
export class DataPlannerResourceComponent {

  /** The resource to render. */
  @Input({ required: true }) resource: Resource;

  /** The owning component. */
  @Input({ required: true }) component: any;

  /** Whether the object is selected or not. */
  @Input({ alias: 'isSelected' }) isSelectedOverride: boolean | undefined;

  /** Part of total time expected to be taken up by traveling. */
  @Input() travelTime: number = 0;
  /** Already planned time for the given resource in a given time frame. */
  @Input() plannedTime: number = 0;
  /** Total time for a resource in a given time frame. */
  @Input() totalTime: number = 0;

  /** Names of locations the resource will visit. */
  @Input() locationNames: string[] = [];

  /** Menu buttons for the resource. */
  @Input() resourceMenuNav: Nav;


  @Input({ alias: 'visibility' }) set inputVisibility(v: Partial<VisibilityOptions>) { this.visibility = { ...visibilityOptionsDefault, ...v }; }

  @Output() click = new EventEmitter<MouseEvent>();



  public visibility: VisibilityOptions = visibilityOptionsDefault;
  public collapsed: boolean = false;

  public get isSelected() { return this.isSelectedOverride !== undefined ? this.isSelectedOverride : this.resource.isSelected; }
  public set isSelected(v: boolean) { if (this.isSelectedOverride === undefined) this.resource.isSelected = v; }


  public toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }


  @HostListener('click', ['$event'])
  private _onClick(event: PointerEvent): void {
    this.isSelected = !this.isSelected;
  }

}
