import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'primaryKey'
})
export class PrimaryKeyPipe implements PipeTransform {
  transform(value: any, args: any): string {
    return ['id', args.map(arg => value[arg.key]).join('-')].join('_');
  }
}
