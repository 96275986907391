<ng-container *accessLevel="nav.accessLevel">
  <div [ngClass]="['cmd-flex', nav?.align ?? '']">
    @for (btn of navList; track $index) {
      @if (!['menu', 'hyperlink', 'dropdownlist'].includes(btn) && nav[btn] && !nav[btn].isInvisible) {
        <div [ngClass]="['cmd', className ? className : '', nav[btn].className ?? '', !nav[btn].noToggle && nav[btn].isSelected ? 'selected' : '', nav[btn].disabled ? 'disabled' : '', collapsed ? 'collapsed' : '']"
              *accessLevel="nav[btn].accessLevel">
          <xfwPopOver [config]="nav[btn].msg?.popOver"></xfwPopOver>
          <button class="cmd-button"
              [tabIndex]="nav[btn].tabIndex"
              [title]="nav[btn].info ?? nav[btn].text ?? ''"
              [disabled]="nav[btn].disabled"
              [type]="nav[btn].type ?? 'button'"
              (click)="perform(nav[btn], content, $event); $event.stopPropagation();">
            <div class="cmd-icon-before">
              <div class="icon-wrapper">
                <i [ngClass]="[nav[btn].icon, 'fa-fw']"></i>
              </div>
            </div>
            <div class="cmd-text">
              <div class="text-wrapper">
                <span [innerHTML]="nav[btn].text"></span>
              </div>
            </div>
            <div class="cmd-icon-after">
              <div class="icon-wrapper">
                <i [ngClass]="[nav[btn].icon, 'fa-fw']"></i>
              </div>
            </div>
          </button>
        </div>
      }

      @if(btn === 'hyperlink') {
        <a *accessLevel="nav[btn].accessLevel" [ngClass]="['cmd-hyperlink', (className ? className : '')]" (click)="perform(nav[btn], content, $event); $event.stopPropagation(); ">{{ nav[btn].text }}</a>
      }
    }

    @if (nav && nav.dropdownlist) {
      <div ngbDropdown container="body" [ngClass]="['cmd', 'more', className ? className : 'sm']">
        @for (item of nav.dropdownlist; track $index) {
          @if (item.isSelected) {
            <div [class]="item.className">
              <button class="cmd-button" ngbDropdownToggle (click)="$event.stopPropagation()" [title]="item.info ? item.info : item.text">
                <div class="cmd-text">
                  <div class="text-wrapper">
                    <span [innerHTML]="item.text"></span>
                  </div>
                </div>
              </button>
            </div>
          }
        }

        <div ngbDropdownMenu class="dropdown-menu">
          @for (item of nav.dropdownlist; let i = $index; track i) {
            @if(!item.isSelected) {
              <button *accessLevel="item.accessLevel"
                      [tabIndex]="item.tabIndex"
                      [type]="item.type ?? 'button'"
                      [title]="item.info ?? item.text ?? ''"
                      (click)="perform(item, content, $event)"
                      ngbDropdownItem>
                <div [ngClass]="['dropdown-menu-icon', 'fa-fw',  item.icon ? item.icon : '']"></div>
                <div class="dropdown-menu-text">
                  {{ item?.text ? item?.text : item?.info }}
                </div>
              </button>
            }
          }
        </div>
      </div>
    }

    @if (nav && nav.menu && navVisible >= 1 && navList.includes('menu')) {
      @for (item of nav.menu | propertyValue: 'isInvisible' : false; let i = $index; track i) {
        @if (i < navVisible) {
          <div *accessLevel="item.accessLevel"
               [ngClass]="[
                  'cmd', className ? className : '',
                  item.className ? item.className : '',
                  collapsed ? 'collapsed' : '',
                  !item.noToggle && (item.isSelected || (dataItem && dataItem.state[item.toggleField])) ? 'selected' : '', item.disabled ? 'disabled' : '']">
            <xfwPopOver [config]="item.msg?.popOver"></xfwPopOver>
            <button class="cmd-button"
                    [tabIndex]="item.tabIndex"
                    [title]="item.info ?? item.text ?? ''"
                    [disabled]="item.disabled"
                    [type]="item.type ?? 'button'"
                    (click)="perform(item, content, $event); $event.stopPropagation();">
              <div class="cmd-icon-before">
                <div class="icon-wrapper">
                  <i [ngClass]="[item.icon, 'fa-fw']"></i>
                </div>
              </div>
              <div class="cmd-text">
                <div class="text-wrapper">
                  <span [innerHTML]="item.text"></span>
                </div>
              </div>
              <div class="cmd-icon-after">
                <div class="icon-wrapper">
                  <i [ngClass]="[item.icon, 'fa-fw']"></i>
                </div>
              </div>
            </button>
          </div>
        }
      }
    }

    @if (nav && nav.menu && nav.menu.length - 1 >= navVisible && !dropdownHidden &&  navList.includes('menu')) {
      <div ngbDropdown #cmdDropdown="ngbDropdown"
          [placement]="placement ? placement : 'bottom-end' "
          container="body"
          [ngClass]="['cmd', className ? className : '', dropdownClassName]">
        <button class="cmd-button" ngbDropdownToggle (click)="collapsed = !collapsed; $event.stopPropagation()">
          <div class="cmd-icon-before">
            <div class="icon-wrapper">
              <i [ngClass]="[dropdownIcon ? dropdownIcon : 'fa-sharp fa-solid fa-ellipsis-vertical']" style="line-height: 1;"></i>
            </div>
          </div>
        </button>

        <div ngbDropdownMenu class="dropdown-menu">
          @if (cmdDropdown.isOpen()) {
            @for (item of nav.menu; let i = $index; track data) {
              @if (item.divide) {
                <hr />
              }

              @if (i >= navVisible && ((item.onlyWhenItemsAreChecked && (data | checked)) || !item.onlyWhenItemsAreChecked)) {
                <button *accessLevel="item.accessLevel"
                        [ngClass]="[item.isSelected ? 'selected' : '']"
                        [tabIndex]="item.tabIndex"
                        [title]="item.info ?? item.text ?? ''"
                        [type]="item.type ?? 'button'"
                        (click)="perform(item, content)" ngbDropdownItem>
                  @if (item.icon) {
                    <div [ngClass]="['dropdown-menu-icon', 'fa-fw', item.icon]"></div>
                  }
                  <div class="dropdown-menu-text">
                    {{ item?.text ? item?.text : item?.info }}
                  </div>
                </button>
              }
            }
          }
        </div>
      </div>
    }
  </div>
</ng-container>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ msg ? msg.title : '' }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ msg ? msg.text : '' }}</p>
  </div>
  <div class="modal-footer cmd-flex">
    <div class="cmd text xfw3-bg-transparent rounded-corners">
      <button class="cmd-button" (click)="modal.close('CANCEL')">
        <div class="cmd-text">
          <div class="text-wrapper">
            <span [innerHTML]="msg ? msg.cancel : ''"></span>
          </div>
        </div>
      </button>
    </div>
    <div class="cmd text xfw3-bg-primary rounded-corners uppercase">
      <button class="cmd-button" (click)="modal.close('OK')">
        <div class="cmd-text">
          <div class="text-wrapper">
            <span [innerHTML]="msg ? msg.primary : ''"></span>
          </div>
        </div>
      </button>
    </div>
  </div>
</ng-template>

@if (notification) {
  <ngb-alert type="{{ msg?.notification ? msg?.notification.type : '' }}">{{ msg?.notification ? msg?.notification.text : '' }}</ngb-alert>
}

