import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { WidgetEvent } from 'src/app/widgets';
import { DataGenericComponent } from '../data-generic/data-generic.component';

@Component({
  selector: 'app-data-content',
  templateUrl: './data-content.component.html',
  styleUrls: ['./data-content.component.css']
})
export class DataContentComponent extends DataGenericComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
