import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgInhakerDesignToolComponent } from './svg-inhaker-design-tool/svg-inhaker-design-tool.component';
import { SvgToolBenchComponent } from './svg-tool-bench/svg-tool-bench.component';
import { SvgToolInhakerComponent } from './svg-tool-inhaker/svg-tool-inhaker.component';
import { SvgToolObjectComponent } from './svg-tool-object/svg-tool-object.component';
import { RerenderDirective } from './rerender-directive';



@NgModule({
  declarations: [
    SvgInhakerDesignToolComponent,
    SvgToolBenchComponent,
    SvgToolInhakerComponent,
    SvgToolObjectComponent,
    RerenderDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [SvgInhakerDesignToolComponent]
})
export class InhakerDesignToolModule { }
