<div class="xfw-matrix-row static-col-1" horizontalScroll *ngIf="loaded">
  <div class="xfw-matrix-col">
    <div class="xfw-matrix-content" preventHorizontalScroll>
      <div *ngFor="let dataItem of dataGroupItem.dataTable[0].data; trackBy: trackByFn;">
        <!-- <app-data-group-matrix-col-item
          #dataGroupMatrixColItem
          [dataGroupMatrix]="dataGroupMatrix"
          [index]="index"
          [item]="item"
          (clearSelected)="onClearSelected()"
          (onSelect)="onSelected($event)">
        </app-data-group-matrix-col-item> -->

        <div [ngClass]="['xfw-matrix-col-item', dataItem.isSelected ? 'selected' : '' ]"
         [style.height.px]="140"
         (click)="select(item)">
          <app-data-item-content
            [dataGroupItemInstance]="dataGroupItem"
            [item]="dataItem">
          </app-data-item-content>
        </div>
      </div>
    </div>
  </div>
</div>

