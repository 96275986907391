import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  constructor(
    private _elementRef: ElementRef<HTMLElement>
  ) { }

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  public onClickElement(event: MouseEvent) {
    setTimeout(() => document.addEventListener('click', this.listener));
  }

  public onClickGlobal(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    document.removeEventListener('click', this.listener);

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }

  private listener = (v: MouseEvent) => this.onClickGlobal(v, v.target as HTMLElement);
}
