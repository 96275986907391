<app-popup-window
  [window]="{
    title: dataGroupItem.title.window,
    dataLength: dataGroupItem.dataTable[0].data.length,
    index: index,
    nav: true,
    add: false,
    modal: true,
    closeOnOutsideClick: false,
    sidebar: false,
  }"
  (onCancelWindow)="close($event)"
  (onPrevious)="previous($event)"
  (onNext)="next($event)">

  <div class="img-viewer-container" *ngIf="dataGroupItem.dataTable[0].data?.length">
    <div class="img-viewer-wrapper-container" #wrapper>
      <div class="img-viewer-wrapper">
        @for(item of dataGroupItem.dataTable[0].data | slice: 0 : maxImgs; let imgIndex = $index; track imgIndex) {
          <div class="img-slide">
            <div class="img-wrapper" [ngClass]="[item.className || '']" #slide >
              @if (item.extension !== 'mp4') {
                <img [src]="item[imgKey]">
              } @else {
                <video style="width: 100%;" [src]="item[imgKey]" controls></video>
              }
            </div>
          </div>
        }
      </div>
    </div>

    <div class="img-thumbs-container">
      @for (item of dataGroupItem.dataTable[0].data | slice: 0 : maxThumbs; let imgIndex = $index; track imgIndex) {
        <div class="thumbs-slide">
          @if (imgIndex < dataGroupItem.dataTable[0].data.length) {
            <div class="thumbs-wrapper" #thumbslide
              [class]="imgIndex === index ? 'selected' : ''"
              (click)="animate(imgIndex);">
              @if (item.extension !== 'mp4') {
                <img [src]="item[imgKey]">
              }@else {
                <video  style="width: 100%;" [src]="item[imgKey]"></video>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>

</app-popup-window>







