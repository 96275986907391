import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-price-tier',
  templateUrl: './report-price-tier.component.html',
  styleUrls: ['../report.component.scss']
})
export class ReportPriceTierComponent implements OnInit {
  @Input() data: any;

  ngOnInit(): void {
    let amount = 0;

    this.data.forEach((item: any) => {
      item.amount !== amount ? amount = item.amount : item.amount = '';
    });
  }
}
