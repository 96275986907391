import { Sort, Key, MailRequest, CondField, CondFields } from './data';

/**
 * ContainerName from is especialy for products or other
 * name-able params, this is to keep the url friendly.
 *
 * So when you select a customer the URL could be:
 * /relation/SFLEX in stead of /relation/8830
 *
 * Very important with configurators and products
 * /product/Inhaker is good for SEO.
 */
export interface State {
  container?: string,
  containerNameFrom?: Param,
  json: any,
  userId?: number,
  isChat?: boolean,
  lastChanged?: Date
}

export interface PullRequest {
  container?: string,
  userId?: number
}

export interface LookupData {
  src: string,
  func?: string,
  params?: Param[],
  cascadeFrom?: Key[],
  refresh: boolean,
  type: string,
  data?: any[];
}

export interface Message {
  type: string;
  nl: MessageBody;
  en: MessageBody;
}

export interface MessageBody {
  type?: string;
  title?: Title;
  text?: string;
  primary?: string;
  cancel?: string;
}

export interface Title {
  nodata?: string;
  singleItem?: string;
  multipleItems?: string;
  window?: string;
}

export enum WidgetEvent {
  SAVE,
  SAVED,
  DELETE,
  DELETED,
  CLOSE,
  CLOSED,
  REFRESH,
  WAIT,
  SELECTED,
  CHECKED,
  REQUEST,
  CLEAR,
  QUERYPARAMCHANGED,
  USERINFOCHANGED,
  UNDO,
  LOGIN,
  HIGHLIGHT,
  SOCKETUPDATE,
  RUN,
  DONE,
  MERGE,
  REFRESHED,
  CREATE,
  TRANSLATE,
  SLIDESHOW,
  OPEN,
  FOCUS,
  COPY,
  CREATED,
  LANGUAGECHANGED,
  ROUTEPARAMCHANGED,
  PARTIALREFRESH,
  CASCADINGREFRESH,
  FORCEDREFRESHED,
  FORMSINVALID,
  FORMSVALID,
  SESSIONCONFIGCHANGED,
  EDITMODECHANGED
}

export enum WidgetState {
  NOK,
  OK,
  PENDING
}

export interface Widget {
  info?: string,
  widgetId: string,
  component?: any,
  subscribeTo?: WidgetSubscribeTo[],
  state?: WidgetState,
  oldQueryParams?: Param[]
}

export interface WidgetSubscribeTo {
  widgetGroup?: string[],
  event: WidgetEvent,
  func: string,
  promise?: boolean,
  callback?: string,
  path?: string,
  params?: Param[],
  paramsFrom?: Param[],
  data?: any[],
  dataItem?: any,
  procedure?: Procedure,    // Naast een func moeten we vanuit de widget ook een stored procedure kunnen aanroepen.
  http?: string                   // of een HTTP call. Op die manier houden we alles weer generiek.
}

export interface WidgetAction {
  info?: string,
  widgetGroup?: string[],
  event: WidgetEvent,
  path?: string,
  dataItem?: any,
  data?: any[],
  params?: Param[]
  paramsFrom?: ParamFrom[]
}



export interface Procedure {
  db?: 'xfw' | 'xfw3',
  src: string;                // Description in the Obj.Query table
  procedure?: string;         // Stored Proc in the Obj.Query table
  mutations?: any[];          // The params for Update and Insert
  mail?:  MailRequest,        // Mailrequest
  params?: Param[];           // The params for Update and Insert
  top?: number;               // From where - check with Odata -> dit moeten inderdaad @Top en @Page worden.
  page?: number;              // Items per page - check with Odata
  data?: any[];               // returned data
  originalData?: any[];       // copy of the data, client side
  refresh?: Refresh;
}

export interface Refresh {
  interval: number;
  socketId: string;
}

export interface NavCurrent {
  url?: string,
  navItemId?: number,
  mainIndex?: number,
  subIndex?: number
  subSubIndex?: number,
  img?: string,
  state?: {
    hidden: boolean,
  }
}

export interface Nav {
  accessLevel?: string[],
  field?: string,
  className?: string,
  hamburgerIcon?: string,
  hamburgerClass?: string,
  floatRight?: boolean,
  align?: string,
  hyperlink?: NavItem,
  onSelect?: NavItem,
  onEmpty?: NavItem,
  primary?: NavItem,
  secondary?: NavItem,
  custom?: NavItem,
  alt?: NavItem,
  cancel?: NavItem,
  prev?: NavItem,
  skip?: NavItem,
  next?: NavItem,
  done?: NavItem,
  submit?: NavItem,
  menu?: NavItem[],
  dropdownlist?: NavItem[],
  type?: string,
  childRoute?: boolean,
  tabGroup?: TabGroup[];
  dataGroup?: string,
  navId: number,

  autoSelect?: boolean,
  selectable?: string,
  delay?: number,
  maxLevel?: number,
  infoPopup?: boolean,
  fullscreen?: boolean,
  fullscreenType?: string,
  isHamburger?: boolean,
  hamburger?: {
    className?: string,
    icon: string,
    text?: string,
    align?: string,
    ml?: MultiLingual
  }
  width?: string,
  gridTemplateColumns?: string,
  fullscreenGridTemplateColumns?: string
}

export interface TabGroup {
  groupId: number,
  accessLevel?: string[],
  forceGrouping?: boolean,
  icon?: string,
  className?: string,
  group: string,
  floatLeft?: boolean,
  width?: number,
  groupWidth?: number,
  menu: NavItem[],
  menuExpanded?: boolean,
  showTabs: boolean,
  isSelected: boolean,
  floatRight?: boolean,
  columns?: number,
  rows?: number,
  tabGroup?: TabGroup[],
  cols?: any[]
  ml?: MultiLingual
}

export interface NavItem {
  onlyWhenItemsAreChecked?: boolean,
  accessLevel?: string[],
  doNotExecute?: boolean,
  doNotExecuteFunc?: string,
  startSearchTxt?: number,
  className?: string,
  skateBoard?: boolean,
  navItemId?: number,
  navItem?: string,
  icon?: string,
  type?: string,
  divide?: boolean,
  text?: string,
  floatLeft?: boolean,
  tabIndex?: number,
  width?: number,
  access?: string,
  isSelectedVariableReference?: string,
  isSelected?: boolean,
  isVisible?: boolean, // Dit moet isHidden worden. Overal aanpassen.
  isInvisible?: boolean,
  noToggle?: boolean,
  toggleField?: string,
  src?: string,
  func?: string,
  crud?: string,
  procedure?: Procedure,
  path?: string,
  callbackPath?: string,
  params?: Param[],
  paramsFrom?: Key[],
  dataItem?: any,
  data?: any[],
  outlet?: string,
  msg?: Msg,
  disabledCond?: CondFields,
  disabled?: boolean,
  hideWhenDisabled?: boolean,
  widgetAction?: WidgetAction[],
  info?: string,
  track?: Track,
  seo?: Seo,
  ml?: MultiLingual,
  tabGroup?: TabGroup,
  suppliers?: string[] // kan deze anders. We gebruiken 'm nu voor de menu's, maar ik vind het niet ideaal.

  /** Used by {@linkcode PageNavComponent} to navigate to a specific block, if it is present on the site. */
  blockId?: number;
  /** Used by {@linkcode PageNavComponent} to navigate to any HTML ID / "Fragment", like adding #xyz to the end of the URL. */
  elementId?: string;

  gridTemplateColumn?: string,
  dropdown?: boolean,
  left?: number,
  collapsed?: boolean,
  menu?: NavItem[],
  init?: boolean,
}

export interface Seo {
  title: string,
  description: string,
  tags: string
}

export interface MultiLingual {
  nl?: MultiLingualObj,
  fr?: MultiLingualObj,
  de?: MultiLingualObj,
  it?: MultiLingualObj,
  en?: MultiLingualObj,
  es?: MultiLingualObj,
  cz?: MultiLingualObj,
}

export interface MultiLingualObj {
  text: string,
  path?: string,
  widgetPath?: string,
  params?: Param[]
}

export interface Track {
  eventName: string,
  eventAction: string,
  eventValue: string
  eventCategory?: string,
  eventLabel?: string,
}

export interface Msg {
  action?: WidgetEvent;
  object?: string,
  title?: string,
  text?: string,
  cancel?: string,
  primary?: string,
  notification?: {
    type: "error" | "warning" | "info" | "remark" | "devInfo" | "devWarning" | "devError",
    title?: string,
    text?: string
  },
  popOver?: {
    active: boolean,
    path: string
  }
}

export interface TreeData {
  id: number,
  CatId: number,
  SdFileId: number,
  ProductId: number,
  parentId?: number,
  level?: number,
  refId?: number,
  crud?: string,
  editMode: boolean;
  text: string,
  textField: string,
  dataItem: any,
  isCollapsed: boolean,
  hasChildren: boolean,
  cols: TreeDataCols[],
  children: TreeData[],
  type: 'cat' | 'file',
  nav: Nav;
}

export interface TreeDataCols {
  children: TreeData[],
  totalItems?: number
}

export interface EventParams {
  params?: Param[],
  dataItem?: any,
  data?: any,
  event?: any
}

export interface Param {
  key: string,
  alias?: string,
  val?: any,
  src?: string,
  fromData?: boolean,
  isQueryParam?: boolean,
  isOptional?: boolean,
  isLocalStorageParam?: boolean,
  isNav?: boolean,
  prefereQueryParam?: boolean,
}

export interface ParamFrom {
  key: string,
  src?: string
}

export interface Filter {
  key: string,
  operand: string,
  check: string,
  sort: Sort
}

export interface CarouselData {
  height: number,
  minHeight?: number,
  visible: boolean
}

export interface ReportRequest {
  reportName: string,
  fileType?: 'pdf' | 'word' | 'excel' | 'image' | 'html4.0' | 'pptx', // Default is pdf
  params: Param[],
  language?: string, // Default is nl
  domainId?: number, // Default is request user language
  mail?: {
      mailTo?: string, // Default is request user email
      subject?: string, // Default is mailLanguage default subject
      mailName: string,
      mailParams?: { [key: string]: string }
  }
}
