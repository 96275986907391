import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Params } from '@angular/router';
import { DataGroup } from 'src/app/data';
import { PlannedResource, PlanningData, Resource, ResourceWithTimeslots, Step, StepCrud, TaskTimeslot, ResourceTimeslot } from 'src/app/services/planning.service';
import { Nav } from 'src/app/widgets';
import { DataGenericComponent } from '../data-generic/data-generic.component';



export type DropMode = 'copy' | 'transfer';



/** Copy values from {@linkcode src} to {@linkcode dest} with {@linkcode delay} as interval. */
const slowCopy = <T>(src: T[], dest: T[], delay?: number) => new Promise<void>((resolve) => {
  let i = 0;
  const interval = setInterval(() => {
    if (src.length === dest.length) {
      clearInterval(interval);
      resolve();
      return;
    }

    dest.push(src[i]);
    i++;
  }, delay);
});

@Component({
  selector: '',
  template: '',
  styleUrls: ['./data-planner.component.scss']
})
export class DataPlannerComponent extends DataGenericComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() dataGroupItem: DataGroup;


  public navCloseDetailView: Nav = {
    navId: -71,
    primary: {
      icon: 'dripicons dripicons-chevron-left',
      text: 'Terug',
      className: "icon xfw3-bg-primary round",
      info: '',
      params: [],
      disabled: false,
      func: 'closeDetailView'
    }
  };
  public navTeam: Nav;
  public navUnplanned: Nav;
  public searchResult: any[] = [];
  public searchIndex: number = -1;
  public navList: string[] = ['primary'];

  public hourToWidthMultiplier = 2 * (window.innerWidth - 314) / (1680 - 314);;

  /** Simple weekday name lookup; should probably be replaced with some kind of ml query. */
  public weekDays = [
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag'
  ];

  /** Simple month name lookup; should probably be replaced with some kind of ml query. */
  public months = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December'
  ];

  public detailViewItem: any | undefined;

  public scrollBehavior: ScrollBehavior = 'smooth';

  @ViewChildren('item', { read: ElementRef }) items: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChild('scheduleContainer', { read: ElementRef }) scheduleContainer: ElementRef<HTMLDivElement>;

  public plannedData: PlanningData[] = [];
  public unplannedData: PlannedResource[] = [];


  // ========== HELPERS ==========

  /** Generate a function to transform any planning object into a set of all child steps. */
  private makeRetriever<T>(transformer: (step: Step[]) => T) {
    const fn = (item: Step | PlanningData | PlannedResource | ResourceWithTimeslots, ret: Step[] = []): Step[] => {
      if ('resource' in item) {
        item.timeslots.forEach(slot => {
          slot.tasks.forEach(task => fn(task, ret));
        });

        return ret;
      }

      if ('timeslots' in item) {
        item.timeslots.forEach(slot =>
          slot.resource.forEach(resource =>
            fn(resource, ret)
          )
        );

        return ret;
      }

      if ('task' in item) {
        item.task.forEach(task => fn(task, ret));
        return ret;
      }

      ret.push(item);

      return ret;

    };

    return (...item: Parameters<typeof fn>) => transformer(fn(...item));
  }

  public mapCoords = this.makeRetriever(steps => steps.map(step => step.location).filter(v => v));

  /** Get all the locations of child items. */
  public locations = this.makeRetriever(steps => steps.map(step => step.location?.place).filter(v => v));

  public plannedTime = this.makeRetriever(steps => steps.reduce((total, step) => total + step.timeToStart + step.timeToEnd + step.stepTimePlanned, 0));

  /** Shorthand/helper to get the total time of all planned tasks beneath an item. */
  public taskTime = this.makeRetriever(steps => steps.reduce((total, step) => total + step.stepTimePlanned, 0));


  public travelTime = this.makeRetriever(steps => steps.reduce((total, step) => total + step.timeToStart + step.timeToEnd, 0));


  /** Shorthand/helper to get the total time for an item. */
  public totalTime(item: PlanningData | Resource | ResourceWithTimeslots): number {
    if ('resource' in item) {
      return item.resource.totalTime;
    }

    if ('timeslots' in item)
      return item.timeslots.reduce((total, slot) =>
        total + slot.resource.reduce((total, resource) =>
          total + this.totalTime(resource),
          0
        ),
        0
      );

    return item.totalTime;
  }

  /** Simply deselects everything. */
  public unselectAll(): void {

    this.unplannedData.forEach(resource => {
      resource.isSelected = false;
      resource.task?.forEach(task => task.isSelected = false);
    });

    this.plannedData.forEach(day => {
      day.timeslots.forEach(timeslot => {
        timeslot.resource.forEach(resource => {
          resource.isSelected = false;
          resource.task?.forEach(task => task.isSelected = false);
        });
      });
    });
  }


  // ========== DETAIL VIEW ==========

  public openDetailView(type: 'task' | 'resource', dataItem: Step | Resource) {
    // Already the currently selected item, so nothing changes.
    if (dataItem.isSelected) return;

    // Cleanup already selected
    this.unselectAll();

    // Set item to selected & open detail view
    dataItem.isSelected = true;
    this.detailViewItem = dataItem;
  }

  public closeDetailView() {
    this.unselectAll();

    this.detailViewItem = {};
  }

  // ========== SEARCH ==========

  // public onSearch(search: any) {
  //   search = search.join(' ');

  //   this.searchResult = [];

  //   if (search) {
  //     this.unplanned.orders.forEach(ord => {
  //       if (ord.title.toUpperCase().includes(search.toUpperCase())) {
  //         this.searchResult.push(ord);
  //         ord.searchClass = 'matrix-search-result';
  //       } else {
  //         ord.searchClass = '';
  //       }
  //     });

  //     this.planned.forEach(week => {
  //       week.days.forEach(day => {
  //         day.teams.forEach(team => {
  //           team.orders.forEach(ord => {
  //             if (ord.title.toUpperCase().includes(search.toUpperCase())) {
  //               this.searchResult.push(ord);
  //               ord.searchClass = 'matrix-search-result';
  //             } else {
  //               ord.searchClass = '';
  //             }
  //           });
  //         });
  //       });
  //     });
  //   } else {
  //     this.unplanned.orders.forEach(ord => ord.searchClass = '');
  //     this.planned.forEach(week => {
  //       week.days.forEach(day => {
  //         day.teams.forEach(team => {
  //           team.orders.forEach(ord => ord.searchClass = '');
  //         });
  //       });
  //     });
  //   }

  //   if (this.searchResult.length) {
  //     this.searchIndex = 0;
  //   };
  // }

  public onSearchPrev() {
    if (!this.searchResult.length) return;

    this.searchIndex = this.searchIndex > 0 ? this.searchIndex - 1 : this.searchResult.length - 1;

    setTimeout(() => {
      this.items.toArray().find(element => element.nativeElement.id == this.searchResult[this.searchIndex].planId.toString()).nativeElement.scrollIntoView({
        behavior: this.scrollBehavior,
        inline: 'center',
        block: 'nearest'
      });
    });
  }

  public onSearchNext() {
    if (!this.searchResult.length) return;

    this.searchIndex = this.searchIndex < this.searchResult.length - 1 ? this.searchIndex + 1 : 0;

    setTimeout(() => {
      this.items.toArray().find(element => element.nativeElement.id == this.searchResult[this.searchIndex].planId.toString()).nativeElement.scrollIntoView({
        behavior: this.scrollBehavior,
        inline: 'center',
        block: 'nearest'
      });
    });
  }

  public toggleCollapseTeam(resource: Resource) {
    resource.collapsed = !resource.collapsed;
  }

  /** Toggle an item's checked status and check whether multi-dragging is applicable. */
  public markItemChecked(item: Step, resource: PlannedResource) {
    item.checked = !item.checked;

    // resource.checked = resource.task.filter(item => item.checked).map(item => item.title).join(', ');
  }

  public ngOnInit(): void {
    this.dataService.setParams(this.communicationService.queryParams, this.utilsService.paramsFromObject(this.communicationService.user), [], [this.dataGroupItem], true);

    this.planningService.initPlanningData(this.dataGroupItem).then(data => {
      this.unplannedData = data.unplannedData;
      slowCopy(data.plannedData, this.plannedData);
    });

    this.navUnplanned = this.planningService.navUnplanned;
    this.navTeam = this.planningService.navTeam;

    super.ngOnInit();
  }

  ngAfterViewInit() {
    // this.onResize();
  }

  // ========== EVENTS ==========

  @HostListener('window:resize')
  private onResize(): void {
    this.hourToWidthMultiplier = 2 * (this.scheduleContainer.nativeElement.clientWidth - 314) / (1680 - 314);
  }
}
