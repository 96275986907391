import { Component, OnInit, input } from "@angular/core";
import { ConfiguratorOption, ConfiguratorProduct, ConfiguratorRow } from "src/app/configurator";
import { DataGroup } from "src/app/data";
import { ConfiguratorService } from "src/app/services/configurator.service";
import { Nav } from "src/app/widgets";


export type Node = ConfiguratorRow | ConfiguratorOption;

export const delim = '|';

@Component({
  selector: 'app-configurator-input',
  templateUrl: 'configurator-input.component.html',
  styleUrls: [ 'configurator-input.component.scss' ]
})


export class ConfiguratorInputComponent implements OnInit{
  public product = input<ConfiguratorProduct>({
    configuratorRows: [],
    rows: [],
    code: '',
    mode: 'rows'
  });
  public dataGroupItemInstance= input.required<DataGroup>();

  public constructor(
    public configurator: ConfiguratorService
  ) { }

  public delim = delim;

  public timeout: any;

  public component = this;

  public addRowNav: Nav;
  public addOptionNav: Nav;

  initNav() {
    this.addRowNav = {
      navId: 1,
      primary: {
        icon: 'fa-solid fa-plus',
        text: '',
        className: "icon xfw3-bg-secondary round xxs",
        type: '',
        info: 'Toevoegen',
        func: 'addRow',
        params: [],
        disabled: false
      }
    }

    this.addOptionNav = {
      navId: 1,
      primary: {
        icon: 'fa-solid fa-plus',
        text: '',
        className: "icon xfw3-bg-secondary round xxs",
        type: '',
        info: 'Toevoegen',
        func: 'addOption',
        params: [],
        disabled: false
      }
    }
  }

  ngOnInit(): void {
    this.initNav();
  }

  checkTitlesAndCodes(): void {
    const code = 'CODE';
    const name = 'TITLE';

    this.product().rows.forEach(row => {
      clearTimeout(this.timeout);

      !row.code && (row.code = this.addCode(this.product().rows.map(row => row.code), code));
      !row.block.name && (row.block.name = name);
      !row.name && (row.name = row.block.name);
      row.text = `${row.block.name} ${delim} ${row.code}`;

      row.options.forEach(option => {
        !option.code && (option.code = this.addCode(this.product().rows.flatMap(row => row.options).map(option => option.code), code));
        !option.block.name && (option.block.name = name);
        option.name = option.block.name;
        option.text = `${option.block.name} ${delim} ${option.code}`;
      });
    });
  }

  addCode(codes: string[], newCode: string): string {
    if (codes.includes(newCode)) {
      let i = 1;
      while (codes.includes(`${newCode}-${i}`)) {
        i++;
      }
      newCode = `${newCode}-${i}`;
    }

    return newCode;
  }

  public setRow(row: ConfiguratorRow, rowIndex: number, text: string) {
    clearTimeout(this.timeout);
    let [name, code = ''] = text.split(delim).map(val => val.trim() ?? '');

    code && (code = this.addCode(this.product().rows.map(row => row.code), code));

    [row.block.name, row.code] = [name, code];
    Object.assign(row, { name: row.block.name, text: text });

    this.timeout = setTimeout(() => row.text = `${name} ${delim} ${code}`, 3000);
  }

  public setOption(option: ConfiguratorOption, optionIndex: number, text: string) {
    clearTimeout(this.timeout);
    let [name, code = ''] = text.split(delim).map(val => val.trim() ?? '');

    code && (code = this.addCode(this.product().rows.flatMap(row => row.options).map(option => option.code), code));

    [option.block.name, option.code] = [name, code];
    Object.assign(option, { name: option.block.name, text: text });

    this.timeout = setTimeout(() => option.text = `${name} ${delim} ${code}`, 3000);
  }

  public addRow(product: ConfiguratorProduct, rowIndex: number) {
    product.rows.splice(rowIndex, 0, structuredClone(
      {...this.configurator.getNewRow(this.dataGroupItemInstance()), ...{crud: 'update', state: { expanded: true }}}
    ));

    product.rows.forEach((row, rowIndex) => {
      row.trackBy = rowIndex;
    })

    console.log(product.rows);

    setTimeout(() => this.select(rowIndex, undefined));
  }

  public addOption(product: ConfiguratorProduct, rowIndex: number, optionIndex: number) {
    product.rows[rowIndex].options.splice(optionIndex, 0, structuredClone(
      {...this.configurator.getNewOption(this.dataGroupItemInstance().children[rowIndex]), ...{crud: 'update'}}
    ));

    product.rows[rowIndex].options.forEach((option, optionIndex) => {
      option.trackBy = optionIndex;
    });

    console.log(this.dataGroupItemInstance());

    setTimeout(() => this.select(rowIndex, optionIndex));
  }

  keyDownEvent(product: ConfiguratorProduct, rowIndex: number, optionIndex: number, event: KeyboardEvent) {
    event.stopPropagation();

    const target = event.target as HTMLInputElement;
    let [pathRow, pathOption] = target.id.split('_')[1].split('.').map(Number);

    const up = () => {
      event.preventDefault();

      (pathOption !== undefined && pathOption > 0) ?
        (pathOption--)
        :
        pathRow > 0 ?
          (pathRow--) && (pathOption = product.rows[pathRow].options.length - 1 ?? undefined)
          :
          (pathOption = undefined);

      this.select(pathRow, pathOption);
    };

    const down = () => {
      event.preventDefault();

      product.rows[pathRow].options.length > 0 ?
        pathOption === undefined ?
          (pathOption = 0)
          :
          pathOption < product.rows[pathRow].options.length - 1 ?
            pathOption++
            :
            (pathRow++, pathOption = undefined)
        :
        pathRow < product.rows.length - 1 && pathRow++ && (pathOption = undefined);

      this.select(pathRow, pathOption);
    };

    const moveToOptions = () => {
      const origin = structuredClone(product.rows[pathRow]);
      product.rows.splice(pathRow, 1);

      pathOption = product.rows[--pathRow].options.length;

      this.addOption(product, pathRow, pathOption);

      product.rows[pathRow].options[pathOption] = {...product.rows[pathRow].options[pathOption], ...{
        text: origin.text,
        code: origin.code,
        name: origin.name,
        block: {
          name: origin.block.name,
        }
      }};

      product.rows[pathRow].options.push(...origin.options);

    };

    const moveToRows = () => {
      const originForRow = structuredClone(product.rows[pathRow].options[pathOption]);
      const originForOptions = product.rows[pathRow].options.splice(pathOption + 1);
      product.rows[pathRow].options.splice(pathOption);

      this.addRow(product, ++pathRow);
      product.rows[pathRow] = {...product.rows[pathRow], ...{
        text: originForRow.text,
        code: originForRow.code,
        name: originForRow.block.name,
        block: {
          name: originForRow.block.name,
        },
        options: originForOptions
      }};
    };

    switch (event.key) {
      case 'ArrowUp':
        up();
        break;
      case 'ArrowDown':
        down();
        break;
      case 'Backspace':
        if (target.value.length > 0) return

        pathOption ?
          product.rows[pathRow].options.splice(pathOption, 1)
          :
          product.rows.splice(pathRow, 1);

        up();
        break;
      case 'Tab':
        event.preventDefault();
        event.shiftKey ?
          pathOption !== undefined && moveToRows()
        	:
          pathOption === undefined && pathRow > 0 && moveToOptions();

        break;
      case 'Enter':
        event.preventDefault();

        pathOption !== undefined || (pathOption === undefined && pathRow < product.rows.length - 1) ?
          this.addOption(product, pathRow, ++pathOption)
          :
          this.addRow(product, ++pathRow);

        break;
    }
  }

  private select(pathRow: number, pathOption?: number): void {
    const element = document.getElementById(['ci', [pathRow, pathOption].filter(val => val !== undefined).join('.')].join('_')) as HTMLInputElement;

    if (!element) return;

    element.focus();

    const range = document.createRange();
    range.setStartAfter(element);
    range.collapse(true);

    window.getSelection()?.addRange(range);
  }
}
