<ng-container *ngIf="loaded">
  <div *ngFor="let dataItem of dataGroupItem.dataTable[0].data; trackBy: trackByFn;">
    <div [ngClass]="['xfw-matrix-col-item', dataItem.isSelected ? 'selected' : '' ]"
         [style.height.px]="140"
         (click)="select(item)">
      <app-data-item-content
        [dataGroupItemInstance]="dataGroupItem"
        [item]="dataItem">
      </app-data-item-content>
    </div>
  </div>
</ng-container>
