import { Component, EventEmitter, Input, Output } from '@angular/core';

const WHEEL_RESOLUTON = 133;

@Component({
  selector: '[virtualScroll]',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.css']
})
export class VirtualScrollComponent {
  @Input() top: number = 0;
  @Input() page: number = 0;
  @Input() paging: 'virtual-scroll' | 'scroll' | 'pagination' | 'grow' | 'window-virtual-scroll';

  @Input() scroll: number = 0;
  @Output() scrollChange = new EventEmitter<any>();

  @Input() listLength: number = 0;

  public _scroll: number = 0;
  public _prevScroll: number = 0;
  public _prevScrollTop: number = 0;

  public isDragging: boolean = false;

  public isWheel: boolean = false;

  constructor() {
  }

  scrollBar(event: any) {
    if (!this.isWheel) {
      this.paging === 'virtual-scroll' && this.onScroll({ deltaY: event.srcElement.scrollTop >= this._prevScrollTop ? 133 : -133 });
      this._prevScrollTop = event.srcElement.scrollTop;
    }
    this.isWheel = false;
  }

  onScroll(event: any) {
    if (this.paging === 'virtual-scroll') {
      this.isWheel = true;
      this._scroll = this._scroll + event.deltaY / WHEEL_RESOLUTON;
      this._prevScroll = Math.min(this.page + Math.round(this._scroll), this.listLength);
      this.scroll = Math.max(this._prevScroll, this.scroll, this.page);

      this.scrollChange.emit(this.scroll);
    }
  }
}
