import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { CommunicationService } from 'src/app/services/communication.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Nav, Param, WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: '[contextAware]',
  templateUrl: './context-aware.component.html',
  styleUrls: ['./context-aware.component.scss']
})
export class ContextAwareComponent implements OnInit, OnDestroy {
  public widgetId = 'contextAware';

  @Input() dataItem: any;
  @Input() rowIndex: number;

  public nav: Nav;
  public selected: boolean = false;

  public component = this;

  @ViewChild('contextpopover')
  public popover: ElementRef;

  public isHovering = false;

  private mouseEnterSub: any;
  private mouseLeaveSub: any;

  public leftOffset: number = -100;
  public topOffset: number = -100;

  public params: Param[];
  public edit = false;

  public path: string = window.location.pathname.substring(1);

  constructor(
    public renderer: Renderer2,
    public hostElement: ElementRef,
    private utilsService: UtilsService,
    private userService: UserService,
    public communicationService: CommunicationService) {
  }

  ngOnInit(): void {
    this.communicationService.initWidget({
      widgetId: [this.widgetId, 'id_' + this.dataItem.blockId ?? this.dataItem.fileId].join('_'),
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          event: WidgetEvent.HIGHLIGHT,
          func: 'highlightSelected'
        }
      ]
    });

    this.nav = {
      navId: -30,
      menu: [
        {
          className: 'sm xfw3-bg-primary icon mx-0 px-0',
          icon: 'fa-solid fa-location-question',
          accessLevel: ['user'],
          text: 'Vraag stellen',
          type: '',
          info: 'Vraag stellen',
          disabled: false,
          func: 'createTicket',
          widgetAction: [
            {
              info: 'contextAware',
              widgetGroup: ['action'],
              event: WidgetEvent.SELECTED,
              dataItem: {
                url: window.location.href + '#' + this.hostElement.nativeElement.id,
                queryParams: this.communicationService.queryParams,
                item: this.dataItem
              }
            }
          ]
        },
        {
          className: 'sm xfw3-bg-primary icon mx-0 px-0',
          icon: 'fa-brands fa-google red',
          accessLevel: ['admin','distributor'],
          text: 'Vertaald door Google, nog niet gecontroleerd',
          type: '',
          info: 'Vertaald door Google, nog niet gecontroleerd',
          disabled: false,
          func: '',
          params: this.utilsService.paramsFromObject(this.dataItem),
          widgetAction: []
        },
        {
          className: 'sm xfw3-bg-primary icon mx-0 px-0',
          icon: 'fa-solid fa-circle-half-stroke',
          accessLevel: ['admin'],
          isInvisible: !this.dataItem.blocksId,
          divide: true,
          text: 'Zwart/wit omdraaien',
          type: '',
          info: 'Zwart/wit omdraaien',
          disabled: false,
          widgetAction: [
            {
              info: 'contextAware',
              widgetGroup: ['action'],
              event: WidgetEvent.SELECTED,
              dataItem: {
                url: window.location.href + '#' + this.hostElement.nativeElement.id,
                queryParams: this.communicationService.queryParams,
                item: this.dataItem
              }
            }
          ]
        },
        {
          className: 'sm xfw3-bg-primary icon mx-0 px-0',
          icon: 'fa-solid fa-up',
          accessLevel: ['admin'],
          isInvisible: !this.dataItem.blocksId,
          text: 'Block omhoog',
          type: '',
          info: 'Block omhoog',
          disabled: false,
          widgetAction: [
            {
              info: 'contextAware',
              widgetGroup: ['action'],
              event: WidgetEvent.SELECTED,
              dataItem: {
                url: window.location.href + '#' + this.hostElement.nativeElement.id,
                queryParams: this.communicationService.queryParams,
                item: this.dataItem
              }
            }
          ]
        },
        {
          className: 'sm xfw3-bg-primary icon mx-0 px-0',
          icon: 'fa-solid fa-down',
          accessLevel: ['admin'],
          isInvisible: !this.dataItem.blocksId,
          text: 'Block omlaag',
          type: '',
          info: 'Block omlaag',
          disabled: false,
          widgetAction: [
            {
              info: 'contextAware',
              widgetGroup: ['action'],
              event: WidgetEvent.SELECTED,
              dataItem: {
                url: window.location.href + '#' + this.hostElement.nativeElement.id,
                queryParams: this.communicationService.queryParams,
                item: this.dataItem
              }
            }
          ]
        },
        {
          className: 'sm xfw3-bg-primary icon mx-0 px-0',
          icon: 'fa-solid fa-pen',
          accessLevel: ['admin','distributor'],
          text: 'Aanpassen',
          type: '',
          info: 'Aanpassen',
          disabled: false,
          func: 'editBody',
          params: this.utilsService.paramsFromObject(this.dataItem),
          widgetAction: []
        }
      ]
    };

    this.hostElement.nativeElement.style.position = 'relative';

    // update isHovering
    this.mouseEnterSub = fromEvent(this.hostElement.nativeElement, 'mouseenter').subscribe((event) => {
      this.isHovering = true;
    });

    this.mouseLeaveSub = fromEvent(this.hostElement.nativeElement, 'mouseleave').subscribe((event) => {
      this.isHovering = false;
    });
  }

  highlightSelected(event) {
    const id = 'id_' + this.dataItem.blockId ?? this.dataItem.fileId;

    if (event.dataItem.id === id) {
      this.selected = true;

      setTimeout(() => {
        this.selected = false;
      }, 2500);
    }
  }

  createTicket() {
    let paramObject = {
      url: window.location.href + '#' + 'id_' + this.dataItem.blockId ?? this.dataItem.fileId,
      queryParams: this.communicationService.queryParams,
      item: this.dataItem,
      objectId: 6,
      sectionId: 4
    };

    this.communicationService.performAction({
      widgetGroup: ['tickets_section'],
      event: WidgetEvent.OPEN,
      dataItem: paramObject
    });
  }

  editBody(params) {
    this.communicationService.user.config.apiMode = !this.communicationService.user.config.apiMode;
    this.userService.setUser({ config: { apiMode: this.communicationService.user.config.apiMode }}, true);

    this.params = params;
    this.edit = true;
  }

  onClose(event) {
    this.edit = false;
  }

  ngOnDestroy(): void {
    this.mouseEnterSub.unsubscribe();
    this.mouseLeaveSub.unsubscribe();
    this.communicationService.destroyWidgets([[this.widgetId, 'id_' + this.dataItem.blockId ?? this.dataItem.fileId].join('_')]);
  }

}
