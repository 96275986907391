<div *ngIf="!this.hasFailed" class="bg-dark m-2 px-2 py-5 rounded mx-auto col-12 col-md-8 mt-3">
  <h1 class="text-center mb-4">Order #{{ orderNr }} is {{ paymentStatus.isPaid ? 'betaald' : 'nog niet betaald' }}!</h1>
  <table class="table px-2 col-12 col-md-8 mx-auto">
    <tr>
      <th>Bedrag</th>
      <td>€ {{paymentStatus.amount.value}}</td>
    </tr>
    <tr>
      <th>Methode</th>
      <td>{{paymentStatus.method}}</td>
    </tr>
    <tr>
      <th>Betaald op</th>
      <td>{{paymentStatus.paidAt | date:'dd-MM-y H:mm' }}</td>
    </tr>
    <tr>
      <th>Betaal status</th>
      <td>{{paymentStatus.status}}</td>
    </tr>
  </table>
</div>

<div *ngIf="this.hasFailed" class="bg-dark m-2 px-2 py-5 rounded mx-auto col-8 mt-3">
  <h1 class="text-center">Order Id #{{ orderNr }} heeft geen betalings gegevens.</h1>
</div>

<!-- {{ paymentStatus.isPaid ? 'betaald' : 'nog niet betaald' }} -->
