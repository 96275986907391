import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataTable } from 'src/app/data';
import { ContentService } from 'src/app/services/content.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'app-data-content-row',
  templateUrl: './data-content-row.component.html',
  styleUrls: ['./data-content-row.component.css']
})
export class DataContentRowComponent implements OnInit, OnChanges {

  @Input() item: any;
  @Input() rowIndex: number;

  public loaded = false;
  public blocks: any[] = [];

  constructor(
    private contentService: ContentService,
    private communicationsService: CommunicationService
  ) { }

  ngOnInit(): void {
    this.convertToBlocks();

    this.loaded = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.item && changes.item.currentValue) {
      this.convertToBlocks();
    }
  }

  convertToBlocks() {
    this.blocks = [];

    if(Array.isArray(this.item.contentJSON)) {
      this.item.contentJSON.forEach((block, index) => {
        this.blocks.push({
          blockId: null,
          blocksId: null,
          object: this.item.object,
          objectId: this.item.objectId,
          contentJSON: this.contentService.changeLanguage(block, this.communicationsService.user.language),
          srt: index
        });
      });
    } else {
      this.blocks.push({
        blockId: null,
        blocksId: null,
        object: this.item.object,
        objectId: this.item.objectId,
        contentJSON: this.contentService.changeLanguage(this.item.contentJSON, this.communicationsService.user.language),
        srt: 0
      });
    }
  }

}
