import { Component, Input, OnInit } from '@angular/core';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  constructor() { }

  ngOnInit(): void {
  }

  trackByFn(index, item) {
    return index;
  }

}
