import { AfterContentChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Subscription, throttleTime } from 'rxjs';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';
import { Nav, Param, WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-action-item-chat',
  templateUrl: './action-item-chat.component.html',
  styleUrls: ['./action-item-chat.component.scss']
})
export class ActionItemChatComponent extends DataGenericComponent implements AfterContentChecked, OnInit, OnDestroy {
  @Input() params: Param[];

  public typeMessage: string = '';
  private initScrollTopHasHappened: boolean = false;

  @ViewChild("chat") chatElement: ElementRef;
  @ViewChild("chatInput") chatInput: ElementRef;

  private inputEvents;
  private pasteEvents;
  private inputEventsSubscription:  Subscription;
  private pasteEventsSubscription: Subscription;

  public JSON = JSON;

  public fieldGroup = {
    dataFileJSON: {},
    schema: 'Action',
    path: "https://xfw3.b-cdn.net/chat/files/",
    imgPath: "https://xfw3.b-cdn.net/chat/thumb/",
    thumbPath: "https://xfw3.b-cdn.net/chat/thumb/",
    thumbExtension: "png",
    imgAndThumbs: [{}],
    data: {}
  };

  public readonly uploaderExtensions: string[] = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'ai'];

  public displayDragoverMenu: boolean = false;

  public chatNav: Nav = {
    primary: {
      icon: 'dripicons dripicons-direction',
      className: 'xfw3-bg-primary icon sm round',
      type: 'submit',
      noToggle: true,
      text: '',
      info: 'context',
      func: 'sendMessage'
    },
    navId: -1
  }

  public contextButtonNav: Nav = {
    menu: [{
      className: 'xs xfw3-bg-secondary rounded text',
      noToggle: true,
      text: 'context',
      info: 'context',
      func: 'openContextMode'
    }],
    navId: -2
  }

  public openContextMode() {
    this.typeMessage = 'https://deinhaker.nl/';
  }

  ngOnInit(): void {

    setTimeout(() => (this.initScrollTopHasHappened = true), 500);

    this.dataGroupItem.params.forEach(paramFrom => {
      this.dataGroupItem.params.find(param => param.key === paramFrom.key).val = this.params.find(param => param.key === paramFrom.key).val;
    });

    this.setFromParams(this.dataGroupItem, this.dataItem, this.widgetId);
    this.initWidget();
    this.dataGroupItem.paramsSet = true;
    this.loadDataTable(true).then(() => {

      if (this.dataGroupItem.dataTable && this.dataGroupItem.dataTable[0].pushUpdate) {
        this.communicationService.initWidget({
          widgetId: this.widgetId,
          component: this,
          state: WidgetState.OK,
          subscribeTo: [
            {
              widgetGroup: [this.widgetId],
              event: WidgetEvent.SOCKETUPDATE,
              func: 'forceChatReload',
            }
          ]
        });

        this.dataGroupItem.dataTable[0].data = this.addAditionalContextClasses(this.dataGroupItem.dataTable[0].data);

        // console.log(this.addAditionalContextClasses(this.dataGroupItem.dataTable[0].data))

        this.inputEvents = fromEvent(this.chatInput.nativeElement, 'keyup').pipe(throttleTime(1000));
        this.pasteEvents = fromEvent(this.chatInput.nativeElement, 'paste');

        this.inputEventsSubscription = this.inputEvents.subscribe((event: any) => {
          this.markMessagesAsRead();
        });

        this.pasteEventsSubscription = this.pasteEvents.subscribe((event) => {
          // @ts-ignore
          const items = (event.clipboardData || event.originalEvent.clipboardData).items;
          for (const index in items) {
            const item = items[index];
            if (item.kind === 'file') {

              const blob = item.getAsFile();
              const reader = new FileReader();

              reader.onload = (event) => {

                console.log(reader);

                if(typeof reader.result == 'string') {
                  this.typeMessage = reader.result;

                  this.sendMessage(this.typeMessage.indexOf('data:image') === 0 ? 'image' : 'file');
                } else {
                  const decoder = new TextDecoder('utf-8');

                  this.typeMessage = decoder.decode(new Uint8Array(reader.result));

                  this.sendMessage(this.typeMessage.indexOf('data:image') === 0 ? 'image' : 'file');
                }
              };

              reader.readAsDataURL(blob);

            }
          }
        });

        setTimeout(() => this.chatElement.nativeElement.scrollTop = this.chatElement.nativeElement.scrollHeight);
      }
    });
  }

  onDragOver(event) {
    if(event.dataTransfer.types[0].indexOf('text') !== 0)
      this.displayDragoverMenu = true;
  }

  onFileDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    console.log(event)
    this.displayDragoverMenu = false;

    const files = event.target.files;

    if(files.length === 0)
      return;

    const file = files[0];

    const reader = new FileReader();

    reader.onload = (event) => {

      if(typeof reader.result == 'string') {
        const content = reader.result;

        console.log(content.length)

        if(content.length > 1000000) {
          console.error('file too big');
          return;
        }

        this.typeMessage = content;

        this.sendMessage(reader.result.indexOf('data:image') === 0 ? 'image' : 'file');
      } else {
        const decoder = new TextDecoder('utf-8');

        const content = decoder.decode(new Uint8Array(reader.result));

        console.log(content.length)

        if(content.length > 1000000) {
          console.error('file too big');
          return;
        }

        this.typeMessage = content;

        this.sendMessage(this.typeMessage.indexOf('data:image') === 0 ? 'image' : 'file');
      }
    };

    reader.readAsDataURL(file);
  }

  forceChatReload() {
    console.log('forceChatReload');

    this.loadDataTable(true).then(() => {
      this.dataGroupItem.dataTable[0].data = this.addAditionalContextClasses(this.dataGroupItem.dataTable[0].data);

      this.initScrollTopHasHappened = false;

      setTimeout(() => (this.initScrollTopHasHappened = true), 200);
    });
  }

  ngOnDestroy(): void {
    if (this.inputEventsSubscription) {
      this.inputEventsSubscription.unsubscribe();
      this.pasteEventsSubscription.unsubscribe();
    }

    this.communicationService.destroyWidgets([this.widgetId]);

    super.ngOnDestroy();
  }

  ngAfterContentChecked(): void {
    if (this.chatElement && !this.initScrollTopHasHappened) {
      this.chatElement.nativeElement.scrollTop = this.chatElement.nativeElement.scrollHeight;
    }
  }

  /**
   * this method is used to add additional css classes to messages to put them on the right
   * side an group messages.
   */
   addAditionalContextClasses(chatContent: any[]) {

    if(!chatContent)
      return;

    // add or reset additional classes to messages
    chatContent.map(message => message.additionalClasses = []);

    // put message on the right side

    chatContent.map(message => message.additionalClasses.push(
      (this.userService._user.contactId == message.contactId || message.contactId == undefined) ? 'message-right' : 'message-left'
    ));

    // Convert date to date object
    chatContent.map(content => content.logJSON.datetime = new Date(content.logJSON.datetime));

    // sort array by datetime
    chatContent = chatContent.sort((a, b) => {
      // @ts-ignore
      return a.logJSON.datetime - b.logJSON.datetime;
    });

    // count up every user change and add it to the message object for grouping
    let userChangeCount = 0;

    chatContent.map((message, index) => {
      if(index == 0) {
        message.userChangeCount = userChangeCount;
        return;
      }

      if(message.logJSON.contactId != chatContent[index - 1].logJSON.contactId) {
        userChangeCount++;
      }

      message.userChangeCount = userChangeCount;
    });

    // find messages that are send on the same minute and give the right classes for the right visuals
    const grouped = Object.values(this.groupByMinuteAndUser(chatContent)) as Message[][];

    // group messages on the same minute
    grouped.forEach((messages) => {
      if(messages.length > 1) {
        messages.forEach((message, i) => {
          if(i === 0)
            message.additionalClasses.push('message-group-start');
          else if(i === messages.length - 1)
            message.additionalClasses.push('message-group-end');
          else
            message.additionalClasses.push('message-group');
        });
      }
    });

    // modify the text to that text links in text are clickable
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // console.log(chatContent)

    return chatContent;

  }

  /**
   * this method is used to group messages on the same minute
   *
   * @param array array of messages
   * @returns grouped messages on the same minute
   */
   private groupByMinuteAndUser(array: any[]): any {
      return array.reduce((result, obj) => {
        (
          result[`${obj.logJSON.contactId}-${obj.userChangeCount}-${obj.logJSON.datetime.getMinutes()}-${obj.logJSON.datetime.getHours()}-${obj.logJSON.datetime.getDay()}-${obj.logJSON.datetime.getMonth()}-${obj.logJSON.datetime.getFullYear()}`] =
          result[`${obj.logJSON.contactId}-${obj.userChangeCount}-${obj.logJSON.datetime.getMinutes()}-${obj.logJSON.datetime.getHours()}-${obj.logJSON.datetime.getDay()}-${obj.logJSON.datetime.getMonth()}-${obj.logJSON.datetime.getFullYear()}`] || []
        ).push(obj);
        return result;
      }, {});
    };

  sendMessage(type: 'text' | 'file' | 'image' = 'text') {
    if(typeof type === 'object') {
      type = 'text';
    }

    if (this.typeMessage !== '') {

      this.dataGroupItem.dataTable[0].data.push({
        crud: 'create',
        objectId: this.dataGroupItem.params.find(param => param.key === 'objectId').val,
        logJSON: {
            contactId: this.communicationService.user.contactId,
            datetime: new Date(),
            text: this.typeMessage,
            type: type,
            isRead: false,
            firstName: this.userService._user.firstName
        }
      });
      this.updateMutations().then(data => {
        this.typeMessage = '';

        setTimeout(() => {
          this.chatElement.nativeElement.scrollTop = this.chatElement.nativeElement.scrollHeight

          // apply additional classes to messages
          this.dataGroupItem.dataTable[0].data = this.addAditionalContextClasses(this.dataGroupItem.dataTable[0].data);
        }, 100);
      });
    }

    return;
  }

  markMessagesAsRead() {
    this.dataGroupItem.dataTable[0].data.forEach(item => {
      if (!item.logJSON.isRead) {
        item.crud = 'update';
        item.logJSON.isRead = true;
        item.logJSON.text.replace(/<\/?[^>]+(>|$)/g, "");
      }
    });

    this.updateMutations();
  }

  onUploadedFiles(files: any[]) {
    setTimeout(() => files.forEach((file, index) => {

      this.typeMessage = this.fieldGroup.thumbPath + file;

      this.sendMessage("image");
    }), 2000);
  }
}

interface ChatContent {
  title: string;
  objectId: number;
  with: string[];
  messages: Message[];
}

interface Message {
  contactId: number;
  datetime: Date;
  text: string;
  additionalClasses?: string[];
  userChangeCount?: number;
  isRead?: boolean;
  logId?: number;
}
