import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'append'
})
export class AppendPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    return `${value}<div class="append">${args.join("")}</div>`;
  }

}
