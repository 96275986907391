import { ApplicationRef, ChangeDetectorRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Inject, Input, OnChanges, Renderer2, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { DragDropService } from './services/dragdrop.service';
import { DOCUMENT } from '@angular/common';

export type MultiCheckArgs<ItemType = any, ContainerNew = any> = {
  container: ContainerNew;

  item: ItemType;
};

export type MultiCheckFn = (event: MultiCheckArgs) => any[];

@Directive({
  selector: '[xDraggable]' // This directive can be used with elements having the 'xDraggable' attribute.
})
export class DragDirective implements OnChanges {
  /** Input property for the item that will be dragged. */
  @Input({ alias: 'xDraggable', required: true }) item: any;

  /** Input property for specifying drag effects allowed. */
  @Input() xDraggableEffectsAllowed: DataTransfer['effectAllowed'];

  /** Input property for specifying the container of the draggable item. */
  @Input() xDraggableContainer: any;

  /** Input property to disable dragging if set to true. */
  @Input() xDragDisabled: boolean = false;

  /** Input property specifying which drop directives it
   *   is allowed to be dropped in. Matches by xDropId on the Drop Directive */
  @Input() xDropConnectedTo: string[] = [];

  /** Input property for look of item while dragging. */
  @Input() xDragTemplate: TemplateRef<any>;

  /** Input function to check for and return any multidrag items. Template context has item, items and container. */
  @Input() xDragGetMultiItems: MultiCheckFn;

  private wrapper: HTMLElement | null = null;

  public constructor(
    private element: ElementRef<HTMLElement>,
    private service: DragDropService,
    private renderer: Renderer2,
    private viewContainer: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Set the initial value for the 'draggable' attribute
    //  on the element based on xDragDisabled value.
  }

  @HostListener('dragstart', ['$event'])
  private _dragStart(event: DragEvent) {
    const { item, xDragGetMultiItems } = this;

    // Store the item being dragged and it's container on DragDropService to access on drop events.
    this.service.item = item;
    this.service.oldContainer = this.xDraggableContainer;
    this.service.dropConnectedTo = this.xDropConnectedTo;

    // In case of multidrag; give "first class" multidrag support. Seems a little nicer than after-the-fact hacking something together.
    this.service.items = xDragGetMultiItems?.({
      item: item,
      container: this.xDraggableContainer
    }) || [];

    this.applyViewTemplate(event);
  }

  @HostListener('dragend', ['$event'])
  private _dragEnd(event: DragEvent) {
    this.clearViewTemplate(event);
  }

  @HostListener('drag', ['$event'])
  private _drag(event: MouseEvent) {
    if (!this.wrapper) return;

    const x = event.clientX;
    const y = event.clientY;
    const element = this.wrapper;

    // Position the element at the mouse coordinates
    element.style.left = x + 'px';
    element.style.top = y + 'px';
  }

  private applyViewTemplate(event: DragEvent) {
    if (!this.xDragTemplate) return;

    // Hide normal drag visual
    event.dataTransfer.setDragImage(new Image, 0, 0);

    // Initialize wrapper
    this.wrapper = this.renderer.createElement('div');
    this.renderer.addClass(this.wrapper, 'drag-preview');
    this.renderer.setStyle(this.wrapper, 'position', 'absolute');
    this.renderer.setStyle(this.wrapper, 'user-select', 'none');
    this.renderer.setStyle(this.wrapper, 'pointer-events', 'none');

    // Initialize template
    const { item, items, oldContainer: container } = this.service;
    const viewRef = this.viewContainer.createEmbeddedView(this.xDragTemplate, { item, items, container });
    const element = viewRef.rootNodes[0];

    // Attach template to app/body
    viewRef.rootNodes.forEach(v => this.wrapper.appendChild(v));
    this.renderer.appendChild(this.document.body, this.wrapper);
  }

  private clearViewTemplate(event: DragEvent) {
    if (!this.wrapper) return;

    this.renderer.removeChild(this.document.body, this.wrapper);
    this.viewContainer.clear();
    this.wrapper = null;
  }

  // Implementation of the OnChanges interface.

  public ngOnChanges(changes: SimpleChanges): void {
    // Update the 'draggable' attribute based on changes to the 'xDragDisabled' property.

    'xDragDisabled' in changes &&
      this.element.nativeElement.setAttribute(
        'draggable',
        (!this.xDragDisabled).toString()
      );
  }

}
