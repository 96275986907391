import { Component, Input, OnInit } from '@angular/core';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-full-width',
  templateUrl: './full-width.component.html',
  styleUrls: ['./full-width.component.scss']
})
export class FullWidthComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  constructor() { }

  ngOnInit(): void {
  }

  trackByFn(index, item) {
    return index;
  }

}
