<div class="position-relative">
  <iframe src=""
    [ngStyle]="{'width': width, 'height': height}"
    allow="autoplay; fullscreen; xr-spacial-tracking"
    xr-spatial-tracking
    execution-while-out-of-viewport
    execution-while-not-rendered
    allow-scripts
    web-share allowfullscreen
    mozallowfullscreen="true"
    webkitallowfullscreen="true"
    #viewer></iframe>

  <div *ngIf="popup.visible" class="popup"
    [style.left.px]="popup.x"
    [style.top.px]="popup.y">
    <app-cmd
        [nav]="nav"
        [navList]="['custom','primary']"
        [navVisible]="0"
        [className]=""
        [collapsed]="false">
    </app-cmd>
  </div>
</div>
