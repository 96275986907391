
<div *ngIf="this.contentJSON" class="container-fluid bg-dark" style="padding: 0px!important;" >
  <div class="fullwidth-col-container" divBreakpointClass>
    <img *ngFor="let item of contentJSON.cols[0].col[0].img; trackBy: trackByFn"
      [src]="item.url"
      [style.height]="item.height"
      [style.--aspect-ratio]="1" />
    <div *ngFor="let item of contentJSON.cols[0].col[0].svg; trackBy: trackByFn"
      style="
        margin: 4rem 0rem 4rem 0rem;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center;
        float: left;"
      [style.width]="item.width"
      [style.height]="item.height"
      [style.background-image]="'url(' + item.url + ')'">
    </div>
    <ng-container *ngFor="let item of contentJSON.cols[0].col[0].issuu; trackBy: trackByFn">
      <app-issuu [pdf]="item.pdf"></app-issuu>
    </ng-container>
  </div>
</div>
