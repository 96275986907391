import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ConditionalCodesExt, ConfiguratorProduct, ConfiguratorRow } from 'src/app/configurator';
import { DataGroup } from 'src/app/data';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { DragDropService } from 'src/app/services/dragdrop.service';
import { EventParams, Nav, Param, WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-edit-row',
  templateUrl: './edit-row.component.html',
  styleUrls: ['../configurator-row/configurator-row.component.scss', './edit-row.component.scss']
})
export class EditRowComponent implements OnInit {
  @Input() dataGroupItemInstance: DataGroup;
  @Input() product: ConfiguratorProduct;
  @Input() row: ConfiguratorRow;
  @Input() rowIndex: number;
  @Input() state: string;

  public edit = {
    mode: false,
    index: null,
    reorder: false,
    uploadType: 'none'
  };

  public configuratorRowOptionDataGroupItemInstance;

  @Output() onSetEditMode: EventEmitter<any> = new EventEmitter();

  public component = this;

  public addOptionNav: Nav;
  public nav: Nav;

  public next: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dragDropService: DragDropService,
    public communicationService: CommunicationService,
    public configuratorService: ConfiguratorService) { }

  ngOnInit() {
    this.configuratorService.noDrag = this.edit.mode;

    if (this.rowIndex > 0) {
      const row = structuredClone(this.dataGroupItemInstance.children[0].children[0]);
      row.widgetId = row.widgetId + '-' + this.rowIndex;
      this.dataGroupItemInstance.children[0].children.push(row);
    }

    this.configuratorRowOptionDataGroupItemInstance = this.dataGroupItemInstance.children[0].children[this.rowIndex];
    this.configuratorRowOptionDataGroupItemInstance.dataTable[0].data = this.row.options;
    this.configuratorRowOptionDataGroupItemInstance.dataTable[0].originalData = structuredClone(this.row.options);

    this.initNav();
  }

  toggle(row: ConfiguratorRow, rowIndex: number, event) {
    event.altKey ? ((product) => {
      const toggle = row.state.isSelected;

      product.rows.forEach(row => {
        row.state.isSelected = false;
      });

      row.state.isSelected = !toggle;
    })(this.product)
    :
    row.state.expanded = !row.state.expanded;
  }

  initNav() {
    this.communicationService.initWidget({
      widgetId: this.configuratorRowOptionDataGroupItemInstance.widgetId,
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [
            this.configuratorRowOptionDataGroupItemInstance.widgetId,
            this.configuratorRowOptionDataGroupItemInstance.widgetId + '_form'
          ],
          event: WidgetEvent.SAVE,
          func: 'updateConfiguratorOption'
        },
        {
          widgetGroup: [
            this.configuratorRowOptionDataGroupItemInstance.widgetId,
            this.configuratorRowOptionDataGroupItemInstance.widgetId + '_form'
          ],
          event: WidgetEvent.CLOSE,
          func: 'close'
        }
      ]
    });

    const msg = {
      title: "Verwijderen",
      text: "Deze regel verwijderen?",
      cancel: "Annuleer",
      primary: "Ok",
      action: 10
    };

    this.nav = {
      navId: 0,
      menu: [
        {
          className: 'cmd icon xs',
          icon: 'fa-solid fa-pencil',
          text: '',
          info: 'Bewerken',
          func: 'editRow',
          params: [],
          paramsFrom: []
        },
        {
          className: 'cmd icon xs',
          icon: 'fa-solid fa-x',
          text: '',
          info: 'Verwijderen',
          func: 'deleteRow',
          params: [],
          paramsFrom: [],
          msg: msg
        },
        {
          className: 'cmd icon xs round',
          icon: 'fa-solid fa-arrow-up-1-9',
          text: '',
          info: 'Volgorde aanpassen',
          func: 'toggleEdit',
          noToggle: false,
          params: [
            {
              key: 'reorder'
            }
          ],
          paramsFrom: []
        },
        {
          className: 'cmd icon xs round',
          icon: 'fa-solid fa-cloud-arrow-up',
          text: '',
          info: 'Upload afbeeldingen',
          func: 'toggleEdit',
          noToggle: false,
          params: [
            {
              key: 'uploadType'
            }
          ],
          paramsFrom: []
        },
        {
          className: 'cmd icon xs',
          icon: 'fa-regular fa-paperclip',
          info: '',
          text: 'Aanmaken samengestelde opties',
          func: 'optionSets'
        },
        {
          className: 'cmd icon xs',
          icon: 'fa-regular fa-filters',
          info: '',
          text: 'Aanmaken conditionele codes',
          func: 'optionConditionalCodeExt'
        }
      ]
    };

    this.addOptionNav = {
      navId: 1,
      primary: {
        icon: 'fa-solid fa-plus',
        text: '',
        className: "icon xfw3-bg-secondary round xxs",
        type: '',
        info: 'Toevoegen',
        func: 'addNewOption',
        params: [],
        disabled: false
      }
    }
  }

  optionSets(eventParams: EventParams) {
    const sets: string[][] = [];

    const arr = this.product.rows.filter(row => row.options.filter(option => option.state.linked).length)
      .map(row => row.options.filter(option => option.state.linked).map(option => option.code));

    const cartesianProduct = (arr: string[][]) => {
      return arr.reduce((a: string[][], b: string[]) => {
        return a.map((x: string[]) => {
          return b.map((y: string) => {
            return x.concat(y);
          })
        }).reduce((a: string[][], b: string[][]) => { return a.concat(b) }, [])
      }, [[]])
    };

    sets.push(...cartesianProduct(arr));

    this.product.rowSets ||= [];
    this.product.rowSets.push({ sets });

    this.product.rows.filter(row => row.options.filter(option => option.state.linked).length).forEach(row => {
      row.options.forEach(option => {
        option.state.linked = false;
      });
    });
  }

  optionConditionalCodeExt(eventParams: EventParams) {
    if (!this.product.rows.some(row => row.state.isSelected)) return;

    const option = this.product.rows.find(row => row.state.isSelected).options.find(option => option.state.dependent);

    if (!option) return;

    const arr = this.product.rows.filter(row => !row.state.isSelected && row.options.filter(option => option.state.dependent).length)
      .map(row => row.options.filter(option => option.state.dependent).map(option => option.code));

    const cartesianProduct = (arr: string[][]) => {
      return arr.reduce((a: string[][], b: string[]) => {
        return a.map((x: string[]) => {
          return b.map((y: string) => {
            return x.concat(y);
          })
        }).reduce((a: string[][], b: string[][]) => { return a.concat(b) }, [])
      }, [[]])
    };

    const originalCodes = option.conditionalCodesExt.length ? option.conditionalCodesExt[0].codes : [];

    const cartesianResult = cartesianProduct(arr);
    const uniqueCartesianResult = cartesianResult.filter(cartesianResultSet =>
      !originalCodes.some(originalCodesSet =>
        originalCodesSet.every(originalCodesSetItem => cartesianResultSet.includes(originalCodesSetItem))
      )
    );

    option.conditionalCodesExt.length ?
      option.conditionalCodesExt[0].codes.push(...uniqueCartesianResult)
      :
      option.conditionalCodesExt.push({
        itemIndex: option.itemIndex,
        codes: [...uniqueCartesianResult]
      });

    this.product.rows.flatMap(row => row.options).forEach(rowOption => rowOption.state.dependent = false);

    console.log(this.product.rows.find(row => row.state.isSelected).options);
  }

  updateConfiguratorOption(eventParams: EventParams): any {
    console.log('update:', eventParams);

    return new Promise((resolve, reject) => {
      this.configuratorRowOptionDataGroupItemInstance.dataTable[0].data.forEach(option => {
        option.crud = null;
        option.block.name = option.name;
        option.block.description = option.description;
      });

      resolve(eventParams.data);
    });

  }

  toggleEdit(params) {
    params.forEach(param => {
      switch (param.key) {
        case 'reorder':
          this.edit.reorder = !this.edit.reorder;
          break;
        case 'uploadType':
          this.edit.uploadType = this.edit.uploadType === 'none' ?  'single-edit' : 'none';
          break;
      }
    });
  }

  editRow(params: Param[], dataItem: any, data: any[]) {
    dataItem.edit = true;

    this.onSetEditMode.emit({
      params,
      dataItem,
      data
    });
  }

  deleteRow(params: Param[], dataItem: any, data: any[]) {
    this.dataGroupItemInstance.children[0].dataTable[0].data.splice(
      this.dataGroupItemInstance.children[0].dataTable[0].data.findIndex(row => row.trackBy === dataItem.trackBy), 1);
  }

  addNewOption(eventParams: EventParams) {
    this.configuratorRowOptionDataGroupItemInstance.dataTable[0].data.forEach(row => row.isSelected = false);

    const dataItem: any = this.configuratorService.getNewOption(this.configuratorRowOptionDataGroupItemInstance);
    this.configuratorRowOptionDataGroupItemInstance.dataTable[0].data.push(dataItem);

    this.edit.index = dataItem.trackBy;
    this.edit.mode = true;
  }

  editMode(eventParams: EventParams) {
    setTimeout(() => {
      this.edit.index = eventParams.dataItem.trackBy;
      this.edit.mode = true;
    });
  }

  dropOption(event) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)

    this.configuratorRowOptionDataGroupItemInstance.dataTable[0].data.forEach((row, rowIndex) => {
      row.sortOrder = rowIndex;
    });
  }

  close(event) {
    if (this.dataGroupItemInstance.dataTable) {
      this.dataGroupItemInstance.dataTable[0].data.forEach(row => row.edit = false);
      this.edit.mode = false;
    }
  }
}
