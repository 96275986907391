import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { ConfiguratorComponent } from 'src/app/product-config/configurator/configurator.component';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { NetworkService } from 'src/app/services/network.service';
import { TempConfiguratorService } from 'src/app/services/temp-configurator.service';
import { WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'app-job-container',
  templateUrl: './job-container.component.html',
  styleUrls: ['./job-container.component.scss']
})
export class JobContainerComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() dataGroupItemInstance: DataGroup;

  constructor(
    private communicationService: CommunicationService) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.communicationService.destroyQueryParams(this.widgetId);
  }

  onHasData(event, dataGroupItem) {
    dataGroupItem.isEmpty = (event.dataLength === 0);
  }
}
