import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { DataTable } from "src/app/data";
import { DataService } from "src/app/services/data.service";
import { CarouselData, Param, WidgetEvent, WidgetState } from "src/app/widgets";
import { UtilsService } from "src/app/services/utils.service";
import { CommunicationService } from "src/app/services/communication.service";
import { DomainConfigService } from "src/app/services/domain-config.service";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.css"],
})
export class PageComponent implements OnInit {
  public widgetId: string = "root";
  public blocks: any[] = [];
  public headerBlocks: any[] = [];
  public footerBlocks: any[] = [];
  public pageNav: string = null;

  public container: string;

  public carousel: any[] = [];
  public window: {
    size: string;
    className?: string;
    footer?: any
  } = {
    size: "narrow"
  };

  public paramJSON: any;
  public queryParams: Param[];
  public dataTable: DataTable[];
  public user: any;
  public mlNav: any;

  public cacheUrl: {
    current: {
      urlPath: string,
      urlSegments: string[],
      fragment: string;
    };
    urlPath: string;
    language: string;
  } = {
      current: {
        urlPath: "",
        urlSegments: [],
        fragment: ""
      },
      urlPath: "",
      language: "",
    };

  private current: {
    carousel: string,
    header: string,
    footer: string
  } = {
    carousel: null,
    header: null,
    footer: null
  };

  loaded: boolean = false;

  public carouselData: CarouselData;
  public routeParamsSubscription: Subscription;
  public queryParamsSubscription: Subscription;
  public fragmentSubscription: Subscription;

  public environmentJSON: any;

  public searchUrl: string;

  constructor(
    public router: Router,
    public dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    public communicationService: CommunicationService
  ) {
  }

  redirect(params?) {
    if (this.cacheUrl.language !== params.dataItem.language) {
      if (this.cacheUrl.current.urlPath) {
        this.loadBlocks(this.cacheUrl.current.urlPath, this.cacheUrl.current.urlSegments);
      } else {
        this.ngOnInit();
      }
    }
  }

  setUrl() {
    let activatedRoute: any = this.activatedRoute;
    let urlSegments = activatedRoute.snapshot.url.map((segment) => segment.path);

    if (!["window", "popup", "report", "help"].includes(urlSegments[2])) {
      this.router.navigate(["/" + this.mlNav[this.communicationService.user.language]], {
        relativeTo: this.activatedRoute,
        queryParams: activatedRoute.queryParams._value,
        fragment: this.cacheUrl.current.fragment,
        replaceUrl: true,
      });
    }

    this.cacheUrl.urlPath = this.mlNav[this.communicationService.user.language];
    this.cacheUrl.current.urlPath = this.mlNav[this.communicationService.user.language];
  }

  initWidget() {
    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          event: WidgetEvent.USERINFOCHANGED,
          func: "redirect",
          params: [],
        }
      ],
    });
  }

  ngOnInit() {
    this.initWidget();

    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        let urlQueryParams = this.utilsService.paramsFromObject(params);

        urlQueryParams.forEach((param) => {
          param["isQueryParam"] = true;
        });

        this.paramJSON = urlQueryParams;

        this.communicationService.performAction({
          event: WidgetEvent.QUERYPARAMCHANGED,
          params: this.paramJSON,
        });

        this.communicationService.setQueryParams(this.widgetId, urlQueryParams, [], this.cacheUrl.current.fragment, true);
      }
    );

    this.fragmentSubscription = this.activatedRoute.fragment.subscribe(
      (fragment) => {
        this.cacheUrl.current.fragment = fragment;
      }
    );

    this.routeParamsSubscription = this.activatedRoute.params.subscribe(
      (params) => {
        const { activatedRoute } = this;

        let urlSegments = activatedRoute.snapshot.url.map(
          (segment) => segment.path
        );

        this.container = urlSegments[urlSegments.length - 1];

        let urlPath = urlSegments.join("/");

        this.searchUrl = `${urlSegments[0]}/${urlSegments[1]}/zoek/`; // dit moet ook anders, werkt momenteel ook niet.

        this.cacheUrl.current.urlPath = urlPath;
        this.cacheUrl.current.urlSegments = urlSegments;

        this.loadBlocks(this.cacheUrl.current.urlPath, this.cacheUrl.current.urlSegments);
      }
    );
  }

  loadBlocks(urlPath: string, urlSegments: string[]) {
    if (this.cacheUrl.urlPath !== urlPath || this.cacheUrl.language !== this.communicationService.user.language) {
      this.blocks = [];

      this.dataService
        .getBlocks(urlPath, this.communicationService.user.language, this.paramJSON)
        .then(
          (data: {
            blocks: any[];
            environmentJSON: any;
            mlNavJSON: any;
          }) => {
            this.blocks = data.blocks;

            this.environmentJSON = data.environmentJSON;
            this.environmentJSON.viewportMode = this.environmentJSON.viewportMode ?? false;

            let environment = data.environmentJSON;
            this.mlNav = data.mlNavJSON;
            this.communicationService.user.current = { ...this.communicationService.user.current, ...{ mlNav: this.mlNav } };

            (urlPath.split("/").length === urlSegments.length) && this.setUrl();

            const promises = [];
            const sections = ['carousel', 'header', 'footer'];

            sections.forEach((section) => {
              (environment[section] && (this.current[section] !== environment[section] || this.cacheUrl.language !== this.communicationService.user.language)) ?
                promises.push(this.dataService.getBlocks(environment[section], this.communicationService.user.language))
                :
                promises.push(Promise.resolve({blocks: []}));
            });

            /* Todo: environment globaal maken, slimmere manier om de carouseldata te bepalen, zodat
             * met een simpele foreach door de promises kan worden gelopen.
             * Ook zo maken dat de promises.push met empty promises niet meer nodig is, maar dan moet bij de getBlocks bekend zijn waar die betrekking op heeft.
             * in de environmentJSON in de database zouden de blocken in een childobject kunnen, dan wordt het dynamisch. */

            Promise.all(promises).then((data) => {
              if (environment.carousel && (this.current['carousel'] !== environment['carousel'] || this.cacheUrl.language !== this.communicationService.user.language)) {
                this.carousel = data[0].blocks;
                this.current.carousel = environment.carousel;
              }

              if (environment.header && (this.current['header'] !== environment['header'] || this.cacheUrl.language !== this.communicationService.user.language)) {
                this.headerBlocks = data[1].blocks;
                this.current.header = environment.header;
              }

              if (environment.footer && (this.current['footer'] !== environment['footer'] || this.cacheUrl.language !== this.communicationService.user.language)) {
                this.footerBlocks = data[2].blocks;
                this.current.footer = environment.footer;
              }

              (environment.nav && (this.current['nav'] !== environment['nav'] || this.cacheUrl.language !== this.communicationService.user.language)) ?
                this.pageNav = environment.nav
                :
                (!environment.nav) && (this.pageNav = null);

              environment.window && (this.window = environment.window);

              setTimeout(() => {
                this.loaded = true;

                this.cacheUrl.current.urlPath = this.cacheUrl.urlPath = urlPath;
                this.cacheUrl.language = this.communicationService.user.language;

                setTimeout(() => {
                  if (this.cacheUrl.current.fragment) {
                    document
                      .querySelector("#" + this.cacheUrl.current.fragment)
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest",
                      });

                    this.communicationService.performAction({
                      info: "ih-fragment",
                      event: WidgetEvent.HIGHLIGHT,
                      dataItem: { id: this.cacheUrl.current.fragment },
                    });
                  }

                  this.queryParams && this.queryParams.some((param) => param.key === "objectId") &&
                    this.communicationService.performAction({
                      widgetGroup: ["tickets_section"],
                      event: WidgetEvent.OPEN,
                      dataItem: {
                        objectId: parseInt(
                          this.queryParams.find(
                            (param) => param.key === "objectId"
                          ).val
                        ),
                      },
                    });

                  this.communicationService.performAction({
                    event: WidgetEvent.ROUTEPARAMCHANGED,
                    dataItem: this.cacheUrl,
                  });
                }, 0);
              });
            });
          }
        );
    }
  }

  checkSizes(event: any) {
    if (event.windowHeight < 640) {
      this.environmentJSON.viewportMode = false;
    }
  }

  ngOnDestroy() {
    this.communicationService.destroyWidgets([this.widgetId]);
    this.routeParamsSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    this.fragmentSubscription.unsubscribe();
  }
}
