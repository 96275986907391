import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';

@Component({
  selector: 'app-data-search-list',
  templateUrl: './data-search-list.component.html',
  styleUrls: ['./data-search-list.component.css']
})
export class DataSearchListComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public blocks: any[] = [];

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  showItem(selectedItem) {
    this.dataGroupItem.dataTable[0].data.forEach(item => {
      if (item.BlockId !== selectedItem.BlockId) {
        item.hidden = true;
      }
    });

    selectedItem.hidden = !selectedItem.hidden;

    if (!selectedItem.hidden) {
      if (typeof selectedItem.ContentJSON === 'string') {
          selectedItem.ContentJSON = this.contentService.changeLanguage(JSON.parse(selectedItem.contentJSON), this.communicationService.user.language);
          selectedItem.ContentJSON = this.highLight(selectedItem.contentJSON, this.dataGroupItem.dataTable[0].queryParams.find(param => param.key === 'text').val);
      }
    }
  }

  highLight(contentJSON: any, searchText: string):any {
    const dsArray = (arr) => {
      arr.forEach(arrItem => {
        dsSwitch(arrItem);
      });

      return arr;
    }

    const dsObject = (obj) => {
      if (obj.hasOwnProperty('text')) {
        obj['text'] = obj['text'].replace(new RegExp(searchText, 'g'), `<span class='highlight'>${searchText}</span>`);
      }

      for(let key in obj) {
          dsSwitch(obj[key]);
      }

      return obj
    }

    const dsSwitch = (arrObj) => {
      if (arrObj instanceof Array) {
        arrObj = dsArray(arrObj);
      } else if (arrObj instanceof Object) {
        arrObj = dsObject(arrObj);
      }
      return arrObj;
    }

    return dsSwitch(contentJSON);
  }

}
