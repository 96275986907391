<div class="option-list" divBreakpointClass>
  <ng-container *ngFor="let option of data; trackBy: trackByFn">
    <div class="form-check" *ngIf="type === 'checkbox'">
      <label class="form-check-label" [title]="option[textField].text" [for]="id + option[valueField]">
        {{ option[textField].text }}
        <input type="checkbox"
              (change)="setCheckbox()"
              [id]="id + option[valueField]"
              [(ngModel)]="option.checked"
              [name]="id"
              [value]="option[valueField]">
        <span class="form-check-input checkbox"></span>
      </label>
    </div>

    <div class="form-check" *ngIf="type === 'radio'">
      <label class="form-check-label" [title]="option[textField].text" [for]="id + option[valueField]">
        {{ option[textField].text }}
        <input type="radio"
              (change)="setRadio()"
              [id]="id + option[valueField]"
              [name]="id"
              [value]="option[valueField]"
              [(ngModel)]="radioValue" >
        <span class="form-check-input radio"></span>
      </label>
    </div>
  </ng-container>
</div>
