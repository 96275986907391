import {Component, OnDestroy, OnInit} from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';

@Component({
  selector: 'app-data-item',
  templateUrl: './data-item.component.html',
  styleUrls: ['./data-item.component.css']
})
export class DataItemComponent extends DataGenericComponent implements OnInit, OnDestroy {
  selected: number = 0;

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  checkCondClass(item, condFields) {
    return this.utilsService.checkCond(item, condFields);
  }
}
