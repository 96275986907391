import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WidgetEvent, WidgetState } from 'src/app/widgets';
import { DataGenericComponent } from '../data-generic/data-generic.component';

@Component({
  selector: 'app-data-img-viewer',
  templateUrl: './data-img-viewer.component.html',
  styleUrls: ['./data-img-viewer.component.css']
})
export class DataImgViewerComponent extends DataGenericComponent implements OnInit, OnDestroy {
  @Input() options: any;
  @Output() closeWindow: EventEmitter<any> = new EventEmitter();

  public img = {
    key: 'imgUrl',
    fileName: 'fileName',
    extension: 'extension',
    path: 'path'
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onCloseWindow(event) {
    this.closeWindow.emit(event);

    this.communicationService.performAction({
      widgetGroup: [this.widgetId],
      event: WidgetEvent.CLOSE
    });
  }
}
