<div *ngIf="loaded">
  <app-popup-img-viewer
    *ngIf="showViewer"
    [imgKey]="img.key"
    [dataGroupItem]="dataGroupItem"

    [index]="index"
    [options]="{ thumbs: true, titleFrom: dataGroupItem.titleFrom, modal: false }"
    (closeImgViewer)="closeImgViewer($event)">
  </app-popup-img-viewer>

  <div class="data-img-container">
    <button class="xfw-control prev" (click)="onPreviousClick(); $event.stopPropagation(); ">
      <span class="xfw-arrow left"></span>
    </button>
    <button class="xfw-control next" (click)="onNextClick(); $event.stopPropagation(); ">
      <span class="xfw-arrow right"></span>
    </button>
    <div #imgcontainer class="data-img-main-container" divBreakpointClass (breakpointClass)="onResize()">
      <div class="data-img-main-wrapper" *ngIf="containerWidth.current !== 0">
        <div class="img-slide" *ngFor="let item of dataGroupItem.dataTable[0].data; index as imgIndex; trackBy: trackByFn">
          <div class="img-wrapper" [ngClass]="[item.className || '']"
              style="cursor: pointer;"
              (click)="showImgViewer([{ key: 'index', val: index}], item); $event.stopPropagation();"
              [style.width]="containerWidth.current + 'px'" #imgslide
              *ngIf="imgIndex < containerWidth.maxThumbs">
            <img [src]="item[img.key]">
            <div class="img-wrapper-nav">
              <a target="_blank" (click)="onNav({fileName: item[img.fileName] + '.' + item[img.extension]});" [href]="item[img.path] + item[img.fileName] + '.' + item[img.extension]" class="dripicons dripicons-download"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="data-img-thumbs-container" *ngIf="containerWidth.current !== 0">
    <ng-container *ngFor="let item of dataGroupItem.dataTable[0].data; index as imgIndex; trackBy: trackByFn">
      <div
        *ngIf="imgIndex < containerWidth.maxThumbs"
        class="thumbs-wrapper"
        [ngClass]="[item.className || '', imgIndex === index ? 'selected' : '']"
        (click)="animate(imgIndex);">
        <img [src]="item[img.key]">
      </div>
    </ng-container>
    <div class="thumbs-wrapper" *ngIf="dataGroupItem.dataTable[0].data.length > containerWidth.maxThumbs">
      <div class="thumbs-extra xfw-hover" (click)="showImgViewer([{ key: 'index', val: 0}], dataGroupItem.dataTable[0].data[0]); $event.stopPropagation();">
        <span class="">+{{ dataGroupItem.dataTable[0].data.length - containerWidth.maxThumbs }}</span>
      </div>
    </div>
  </div>
</div>
