import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  CarouselData,
  Widget,
  WidgetAction,
  WidgetEvent,
  WidgetState,
} from "../widgets";
import { Socket } from "ngx-socket-io";
import { UtilsService } from "./utils.service";
import { Param } from "../widgets";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { DataGroup } from "../data";

// De communicationService verzorgt de communicatie tussen verschillende widgets.
// Elk widget heeft een eigen id. De zender heeft zijn eigen id mee als widgetId en geeft
// een array mee met de widgets die een bepaalde action moeten uitvoeren. Nadat die widget
// hun action hebben uitgevoerd sturen zij een OK of NOK naar de communicationService.

// De communicationService 'spaart' alle reacties op en stuurt een response naar de
// sender zodra alle widgets hebben gereageerd. Als één van de widgets NOK geeft, dan is
// het resultaat NOK, anders is het resultaat OK. Zolang nog niet alles binnen is, is het
// resultaat PENDING.

// Op deze manier kunnen verschillende componenten eenvoudig met elkaar communiceren. Eventueel
// kan ook een data object mee worden gegeven, maar eigenlijk willen we dat de losse componenten
// verantwoordelijk blijven voor de data.

@Injectable({
  providedIn: "root",
})
export class CommunicationService {
  public widget: Widget[] = [];

  public user: any;
  public sessionConfig: any;
  public panels: any;

  _panelRefresher: number = 0;
  panelRefresher$: Subject<number> = new Subject<number>();

  initPanels() {
    this.panels = {
      minimizedHeightPx: 58,
      sidebarSetting: {
        expanded: true,
        main: 100,
        sidebar: 30
      },
      sidebarContainers: [
        {
          name: 'popup',
          height: 0,
          resize: 0,
          resizedOrigin: 0,
          isVisible: false,
          isMinimized: false,
          persist: true
        },
        {
          name: 'window',
          height: 0,
          resize: 0,
          resizedOrigin: 0,
          isVisible: false,
          isMinimized: false,
          persist: true
        },
        {
          name: 'help',
          height: 0,
          resize: 0,
          resizedOrigin: 0,
          isVisible: false,
          isMinimized: false,
          persist: true
        },
        {
          name: 'actionCenter',
          height: 0,
          resize: 0,
          resizedOrigin: 0,
          isVisible: false,
          isMinimized: false,
          persist: true
        }
      ]
    };
  }

  setSidebarContainer(options: {name: string, height: number, isMinimized: boolean, isVisible: boolean}) {
    let sidebarContainer;

    if (!this.panels.sidebarContainers.some(container => container.name === options.name)) {
      sidebarContainer = {
        name: options.name,
        height: options.isVisible ? options.height : 0,
        resize: 0,
        resizedOrigin: 0,
        isVisible: options.isVisible,
        isMinimized: options.isMinimized,
        persist: false
      };
      this.panels.sidebarContainers.unshift(sidebarContainer);

    } else {
      sidebarContainer = this.panels.sidebarContainers.find(container => container.name === options.name);

      if (!options.isVisible && !sidebarContainer.persist) {
        this.panels.sidebarContainers.splice(this.panels.sidebarContainers.findIndex(container => container.name === options.name), 1)
      } else {
        options.height && (sidebarContainer.height = options.isVisible ? options.height : 0);
        sidebarContainer.isVisible = options.isVisible;
      }
    }

    const minimizedPerc = this.panels.minimizedHeightPx / this.panels.sidebarContainers.at(-1).resizedOrigin * 100;

    if (!options.isMinimized && sidebarContainer.isMinimized) {
      sidebarContainer.isMinimized = options.isMinimized;
      sidebarContainer.height = sidebarContainer.maximizedHeight;

      const totalHeightWithoutCurrent = this.panels.sidebarContainers
        .filter(container => container.name !== sidebarContainer.name)
        .reduce((acc, container) => acc + container.height, 0);

      this.panels.sidebarContainers.filter(container => !container.isMinimized && container.name !== sidebarContainer.name).forEach(container => {
        container.height = (container.height / totalHeightWithoutCurrent) * (100-sidebarContainer.maximizedHeight);
      });

    } else {
      sidebarContainer.isMinimized = options.isMinimized;
    }

    const totalHeight = this.panels.sidebarContainers.reduce((acc, container) => acc + (container.isMinimized ? 0 : container.height), 0);
    const totalMinimizedHeight = this.panels.sidebarContainers.filter(container => container.isMinimized).length * minimizedPerc;

    totalHeight && this.panels.sidebarContainers.filter(container => !container.isMinimized).forEach(container => {
      container.height = (container.height / totalHeight) * (100 - (totalMinimizedHeight || 0));
    });

    this.panels.sidebarContainers.filter(container => container.isMinimized).forEach(container => {
      container.maximizedHeight = container.height;
      container.height = minimizedPerc;
    });

    this.panelRefresher$.next(this._panelRefresher++);

    this.panels.sidebarSetting.main = this.panels.sidebarContainers.filter(container => container.isVisible).length ?
      100 - this.panels.sidebarSetting.sidebar
      :
      100;

    return sidebarContainer;
  }

  public scrollContainer: any;

  public _oldQueryParams: Param[] = [];
  public _queryParams: Param[] = [];

  queryParamsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    this._queryParams
  );

  set queryParams(params: Param[]) {
    if (this.utilsService.paramsChanged(this._queryParams, params)) {
      this._oldQueryParams = structuredClone(this._queryParams);
      this._queryParams = structuredClone(params);

      this.queryParamsSubject.next(this._queryParams);
    }
  }

  get queryParams() {
    return this._queryParams;
  }

  public queryParamInstances: any[] = [];

  initQueryParams(params: any[], widgetId: string) {
    params.filter(param => param.isQueryParam).forEach(param => {
      if (this.queryParamInstances.some(paramInstance => paramInstance.key === param.key)) {
        const queryParamInstance = this.queryParamInstances.find(paramInstance => paramInstance.key === param.key);
        !queryParamInstance.widgetGroup.includes(widgetId) && queryParamInstance.widgetGroup.push(widgetId);
      } else {
        this.queryParamInstances.push({ ...param, widgetGroup: [widgetId] });
      }
    });

    this.queryParams.forEach(queryParam => { delete queryParam.isNav; });
  }

  removeQueryParams(queryParams: Param[]) {
    this.queryParams = this.queryParams.filter(queryParam => !queryParams.map(param => param.key).includes(queryParam.key));
    this.mergeQueryParams(null, [], [], null, true);
  }

  destroyQueryParams(widgetId) {
    let params = structuredClone(this.queryParamInstances.filter(paramInstance => !paramInstance.widgetGroup.includes(widgetId)));

    this.queryParamInstances.some(paramInstance => paramInstance.widgetGroup.includes(widgetId)) &&
      (this.queryParamInstances.filter(paramInstance => paramInstance.widgetGroup.includes(widgetId)).forEach(paramInstance => {
        paramInstance.widgetGroup = paramInstance.widgetGroup.filter((widget: string) => widget !== widgetId);
      }));

    this.queryParamInstances = this.queryParamInstances.filter(paramInstance => paramInstance.widgetGroup?.length);
    params = params.filter(param => !this.queryParamInstances.map(queryParamInstance => queryParamInstance.key).includes(param.key));

    this.queryParams = this.queryParams.filter(queryParam => !params.map(param => param.key).includes(queryParam.key) || queryParam.isNav);
  }

  public _localStorageParams: Param[] = JSON.parse(localStorage.getItem('localStorageParams')) ?? [];
  public localStorageParamInstances: any[] = [];


  set localStorageParams(params: Param[]) {
    params.forEach(param => {
      if (this._localStorageParams.some(localStorageParam => localStorageParam.key === param.key)) {
        const localStorageParam = this._localStorageParams.find(localStorageParam => localStorageParam.key === param.key);
        Object.keys(param).forEach(key => {
          localStorageParam[key] = param[key];
        });
      } else {
        this._localStorageParams.push(param);
      }
    });

    localStorage.setItem('local.StorageParams', JSON.stringify(this._localStorageParams));
  }

  get localStorageParams() {
    this._localStorageParams = JSON.parse(localStorage.getItem('local.StorageParams')) ?? [];
    return this._localStorageParams;
  }

  initLocalStorageParams(params: any[], widgetId) {
    params.filter(param => param.isLocalStorage).forEach(param => {
      if (this.localStorageParamInstances.some(paramInstance => paramInstance.key === param.key)) {
        const localStorageParamInstance = this.localStorageParamInstances.find(paramInstance => paramInstance.key === param.key);
        !localStorageParamInstance.widgetGroup.includes(widgetId) && localStorageParamInstance.widgetGroup.push(widgetId);
      } else {
        this.localStorageParamInstances.push({ ...param, widgetGroup: [widgetId] });
      }
    });
  }

  destroyLocalStorageParams(widgetId) {
    this.localStorageParamInstances.some(paramInstance => paramInstance.widgetGroup.includes(widgetId)) &&
      (this.localStorageParamInstances.find(paramInstance => paramInstance.widgetGroup.includes(widgetId)).widgetGroup =
        this.localStorageParamInstances.find(paramInstance => paramInstance.widgetGroup.includes(widgetId)).widgetGroup.filter(widget => widget !== widgetId));

    this.queryParamInstances = this.localStorageParamInstances.filter(paramInstance => paramInstance.widgetGroup.length);

    this.localStorageParams = this.localStorageParams.filter(param => this.localStorageParamInstances.map(paramInstance => paramInstance.key).includes(param.key));
  }

  private _carouselData: CarouselData = {
    height: 400,
    visible: true,
  };

  carouselSubject: BehaviorSubject<CarouselData> =
    new BehaviorSubject<CarouselData>(this._carouselData);

  carouselSubject$ = this.carouselSubject.asObservable();

  public setCarouselInfo(carouselData: CarouselData) {
    this.carouselSubject.next(carouselData);
  }

  constructor(
    private utilsService: UtilsService,
    private socket: Socket,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute
  ) {}

  initWidget(widget: Widget) {
    if (this.widget.some((item) => item.widgetId === widget.widgetId)) {
      this.widget
        .filter((item) => item.widgetId === widget.widgetId)
        .forEach((item) => {
          widget.subscribeTo.forEach((widgetSubscription) => {
            !item.subscribeTo.map(subscription => subscription.event).includes(widgetSubscription.event) &&
              item.subscribeTo.push(widgetSubscription);
          });

        });
    } else {
      this.widget.push({...widget, ...{ oldQueryParams: []}});
    }
  }

  destroyWidgets(widgets: any[]) {
    this.widget = this.widget.filter(
      (widget) => !widgets.includes(widget.widgetId)
    );
  }

  performAction(action: WidgetAction) {
    const widgetStateCheck: Widget[] = [];
    this.widget.forEach((widget) => {
      // Check if event fits.

      // if (action.event === WidgetEvent.CHECKED) {
      //   widget.subscribeTo.forEach(subscription => {
      //       console.log(widget.widgetId, subscription.widgetGroup, action.info, action.widgetGroup, WidgetEvent[subscription.event], WidgetEvent[action.event],
      //       (subscription.widgetGroup && action.widgetGroup  ? (this.utilsService.innerJoin(subscription.widgetGroup, action.widgetGroup).length) : true),
      //       subscription.event === action.event
      //       );
      //   });
      // }

      widget.subscribeTo.filter((subscription) => (
        subscription.widgetGroup && action.widgetGroup
              ? this.utilsService.innerJoin(subscription.widgetGroup, action.widgetGroup).length
              : true) && subscription.event === action.event
        )
        .forEach((subscription) => {
          switch (subscription.event) {
            case WidgetEvent.PARTIALREFRESH:
              subscription.func && widget.component[subscription.func] &&
                widget.component[subscription.func]({
                  params: [
                    ...(action.params ?? []),
                    ...(subscription.params ?? []),
                    ...this.utilsService.paramsFromDataItem(
                      subscription.params,
                      { dataItem: action.dataItem }
                    ),
                  ],
                  data: action.data,
                  dataItem: action.dataItem,
                });

              break;
            case WidgetEvent.DELETE:
              widget.state = WidgetState.PENDING;
              widgetStateCheck.push(widget);

              console.log(
                "delete",
                subscription.widgetGroup,
                widget.widgetId,
                widgetStateCheck
              );

              widget.component[subscription.func]({
                params: subscription.params
                  ? [
                      ...subscription.params,
                      ...this.utilsService.paramsFromDataItem(
                        subscription.params,
                        { dataItem: action.dataItem }
                      ),
                    ]
                  : null,
                data: action.data,
                dataItem: action.dataItem,
              }).then((data) => {
                widget.state = WidgetState.OK;

                if (
                  !widgetStateCheck.some(
                    (widget) => widget.state !== WidgetState.OK
                  )
                ) {
                  this.performAction({
                    event: WidgetEvent.DELETED,
                    widgetGroup: widgetStateCheck.map(
                      (widget) => widget.widgetId
                    ),
                    data: data,
                  });
                }
              });
              break;
            case WidgetEvent.SAVE:
              widget.state = WidgetState.PENDING;
              widgetStateCheck.push(widget);

              // console.log('save', widgetStateCheck);

              widget.component[subscription.func]({
                params: subscription.params
                  ? [
                      ...subscription.params,
                      ...this.utilsService.paramsFromDataItem(
                        subscription.params,
                        { dataItem: action.dataItem }
                      ),
                    ]
                  : null,
                data: action.data,
                dataItem: action.dataItem,
              }).then((data) => {
                widget.state = WidgetState.OK;

                // console.log(widgetStateCheck.map(widget => widget.widgetId));

                if (!widgetStateCheck.some((widget) => widget.state !== WidgetState.OK)) {
                  if (action.path) {
                    let queryParams: Param[] = action.params.filter(param => param.isQueryParam);
                    const url: any = [action.path];

                    let fragment = url[0].split('#')[1];
                    url[0]=url[0].split('#')[0];

                    url.push({outlets: { window: null } });

                    if (queryParams.length) {
                      this.mergeQueryParams(widgetStateCheck[0].widgetId, queryParams, url, fragment);
                    } else {
                      this.setQueryParams(widgetStateCheck[0].widgetId, queryParams, url, fragment);
                    }
                  }

                  this.performAction({
                    event: WidgetEvent.SAVED,
                    widgetGroup: widgetStateCheck.map(
                      (widget) => widget.widgetId
                    ),
                    dataItem: data,
                    data: data
                  });
                }
              });
              break;
            case WidgetEvent.QUERYPARAMCHANGED:
              const oldParams: Param[] = [];
              const newParams: Param[] = [];

              if (subscription.params?.length) {
                subscription.params.forEach(paramFrom => {
                  if (widget.oldQueryParams.some(param => param.key === paramFrom.key)) {
                    oldParams.push(this._oldQueryParams.find(param => param.key === paramFrom.key));
                  }
                  if (this.queryParams.some(param => param.key === paramFrom.key)) {
                    newParams.push(this.queryParams.find(param => param.key === paramFrom.key));
                  }
                });
              } else {
                oldParams.push(...widget.oldQueryParams);
                newParams.push(...this.queryParams);
              }

              // hier moet wellicht nog iets gebeuren. Probleem is dat we nu kijken naar oldQueryParams in
              // plaats van de oldQueryParams van de widget. Als er dan 3 widgets afhankelijk zijn van de
              // queryParams en de laatste past ze aan, dan krijgen de eerste 2 niet de opdracht om zich
              // te refreshen. Ik zou dus de oldQueryParams in de widget moeten opslaan en die gebruiken.
              // Voor nu hou ik het even zo, maar wellicht hebben we dan soms een dubbele refresh.

              // console.log(widget.widgetId, oldParams, newParams);

              if (this.utilsService.paramsChanged(newParams, oldParams)) {
                widget.oldQueryParams = structuredClone(this.queryParams);

                if (subscription.promise) {
                  widget.component[subscription.func]({
                    data: action.data,
                    dataItem: action.dataItem,
                    queryParams: this.queryParams,
                  }).then((data) => {
                    if (subscription.callback) {
                      widget.component[subscription.callback]({
                        data: data,
                      });
                    }
                  });
                } else {
                  widget.component[subscription.func]({
                    data: action.data,
                    dataItem: action.dataItem,
                    queryParams: this.queryParams,
                  });
                }
              }
              break;
            case WidgetEvent.CHECKED:
            case WidgetEvent.ROUTEPARAMCHANGED:
            case WidgetEvent.USERINFOCHANGED:
            default:
              if (subscription.promise) {
                widget.component[subscription.func]({
                  params: [
                    ...(action.params ?? []),
                    ...(subscription.params ?? []),
                    ...this.utilsService.paramsFromDataItem(
                      subscription.params,
                      { dataItem: action.dataItem }
                    ),
                  ],
                  data: action.data,
                  dataItem: action.dataItem,
                }).then((result) => {
                  if (subscription.callback) {
                    widget.component[subscription.callback](result);
                  }
                });
              } else if (
                subscription.func &&
                widget.component[subscription.func]
              ) {

                widget.component[subscription.func]({
                  params: [
                    ...(action.params ?? []),
                    ...(subscription.params ?? []),
                    ...this.utilsService.paramsFromDataItem(
                      subscription.params,
                      { dataItem: action.dataItem }
                    ),
                  ],
                  data: action.data,
                  dataItem: action.dataItem,
                });
              }
          }
        });
    });
  }

  changedQueryParams(currentQueryParams: Param[], newQueryParams: Param[]): Param[] {
    const result: Param[] = [];

    newQueryParams.forEach((param) => {
      if (!currentQueryParams.some((currentParam) => currentParam.key === param.key)) {
        result.push(param);
      } else if (currentQueryParams.some((currentParam) => currentParam.key === param.key && currentParam.val !== param.val)) {
        result.push(param);
      }
    });

    return result;
  }

  setDataGroupItemChildrenParams(dataGroupItem: DataGroup, dataItem: any) {
    dataGroupItem.children.forEach((child) => {
      child.children && this.setDataGroupItemChildrenParams(child, dataItem);

      if (child.params) {
        const params: Param[] =  this.utilsService.paramsFromDataItem(
          child.params,
          dataItem
        );

        params.forEach((param) => {
          (child.params.some((childParam) => childParam.key === param.key)) &&
            (param.isQueryParam = child.params.find((childParam) => childParam.key === param.key).isQueryParam) &&
            (param.isOptional = child.params.find((childParam) => childParam.key === param.key).isOptional)
        });

        child.paramsSet = true;

        child.params = params;
      }
    });
  }

  setQueryParams(widgetId: string, params: Param[], navigate: any[] = [], fragment?: string, replaceUrl = false): void {
    this.queryParams = params.filter((param) => param.isQueryParam && !(param.val === null || param.val === undefined));

    !fragment && (fragment = this.activatedRoute.snapshot.fragment);

    setTimeout(() => {
      this.router.navigate(navigate, {
        relativeTo: this.activatedRoute,
        queryParams: this.utilsService.objectFromParams(this.queryParams),
        skipLocationChange: false,
        fragment: fragment,
        replaceUrl: replaceUrl
      });

      setTimeout(() => {
        if (!replaceUrl && !fragment) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'auto'
          });
        }
      });
    });
  }

  mergeQueryParams(widgetId: string, params: Param[], navigate: any[] = [], fragment?: string, replaceUrl = false ): void {
    // console.log('mergeQueryParams', widgetId, structuredClone(params), this.utilsService.getMethodCallerName());

    let localParams = this.utilsService.mergeParams({
      params: this.queryParams,
      paramsToMerge: params,
      append: true,
      checkQueryParam: false,
      deepCopy: true
    });

    this.queryParams = localParams.filter((param) => param.isQueryParam && param.val !== null && param.val !== undefined);
    !fragment && (fragment = this.activatedRoute.snapshot.fragment);

    navigate.length ?
      this.router.navigate(navigate, {
        relativeTo: this.activatedRoute,
        queryParams: this.utilsService.objectFromParams(this.queryParams),
        fragment: fragment,
        replaceUrl: replaceUrl
      }) :
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: this.utilsService.objectFromParams(this.queryParams),
        fragment: fragment,
        replaceUrl: replaceUrl
      });

    (!replaceUrl && !fragment) &&
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'auto'
        });
      });
  }
}
