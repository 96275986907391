import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Socket } from "ngx-socket-io";


@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(private AuthService: AuthService, private socket: Socket) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq;
    const apiAuthorization = req.headers.get('apiAuthorization');

    if (!apiAuthorization)
    {
      authReq = req.clone({
        setHeaders: this.getHeaders()
      });
    } else {
      let headers = req.headers.set('Authorization', apiAuthorization);
      headers = headers.delete('apiAuthorization');

      authReq = req.clone({ headers: headers });
    }

    return next.handle(authReq);
  }

  getHeaders() {
    let headers = {
      Authorization: 'Bearer ' + (
        !this.AuthService.authToken || this.AuthService.authToken === '' ?
        this.AuthService.tempAuthToken : this.AuthService.authToken
      )
    }

    if(this.socket.ioSocket.id) {
      headers['socket-id'] = this.socket.ioSocket.id;
    }

    return headers;
  }

}
