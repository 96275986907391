import { trigger, state, style, transition, group, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ConfiguratorProduct, ConfiguratorRow } from 'src/app/configurator';
import { DataGroup } from 'src/app/data';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { EventParams, Nav, WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'app-configurator-row',
  templateUrl: './configurator-row.component.html',
  styleUrls: ['./configurator-row.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': '1000px', opacity: '1', visibility: 'visible'
      })),
      state('out', style({
        'max-height': '0px', opacity: '0', visibility: 'hidden'
      })),
      transition('in => out', [group([
          animate('100ms ease-in-out', style({
            opacity: '0'
          })),
          animate('200ms ease-in-out', style({
            'max-height': '0px'
          })),
          animate('200ms ease-in-out', style({
            visibility: 'hidden'
          }))
        ]
      )]),
      transition('out => in', [group([
          animate('0ms ease-in-out', style({
            visibility: 'visible'
          })),
          animate('500ms ease-in-out', style({
            'max-height': '1000px'
          })),
          animate('600ms ease-in-out', style({
            opacity: '1'
          }))
        ]
      )])
    ]),
  ]
})

export class ConfiguratorRowComponent implements OnInit {
  @Input() dataGroupItemInstance: DataGroup;
  @Input() product: ConfiguratorProduct;
  @Input() row: ConfiguratorRow;
  @Input() rowIndex: number;
  @Input() state: string;

  public component = this;
  public nav: Nav;

  public next: boolean = false;
  public rowType: string = 'readOnly';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public communicationService: CommunicationService,
    public configuratorService: ConfiguratorService) { }

  toggleReadOnly() {
    [this.rowType, this.row.type] = [this.row.type, this.rowType];
  }

  initNav() {
    this.nav = {
      navId: 0,
      menu: [
        {
          accessLevel: ['admin'],
          className: 'cmd icon xs round',
          icon: 'fa-solid fa-lock',
          text: '',
          info: 'readOnly',
          func: 'toggleReadOnly',
          isSelected: this.row.type === 'readOnly',
          noToggle: false,
          params: [],
          paramsFrom: []
        }
      ]
    };
  }

  ngOnInit() {
    this.initNav();

    this.row.type === 'readOnly' && (this.rowType = 'radio');

    this.communicationService.initWidget({
      widgetId: this.dataGroupItemInstance.widgetId + '-row_' + this.row.trackBy,
      component: this.component,
      subscribeTo: [
        {
          widgetGroup: ['productConfig-row'],
          event: WidgetEvent.DONE,
          func: 'rowChecked',
          params: []
        },
        {
          widgetGroup: ['productConfig-row'],
          event: WidgetEvent.CHECKED,
          func: 'dataGroupChecked',
          params: []
        }
      ]
    });

    this.next = this.row.options.filter(option => option.type === 'radio').length !== this.row.options.length;
  }

  dataGroupChecked(eventParams: EventParams) {
    if (this.row.code === eventParams.dataItem.code) {
      this.row.options[0].data = eventParams.data[0].data;
      this.row.options[0].isSelected = this.row.options[0].checked = true;

      this.configuratorService.setRowSelected(this.row, this.row.options)

      this.refreshConfiguration();
    }
  }

  rowChecked(eventParams: EventParams) {
    if (!this.row.options.filter(option => option.isSelected).length) return;

    this.configuratorService.setRowSelected(this.row, this.row.options);

    this.refreshConfiguration();
  }

  refreshConfiguration() {
    this.row.state.checked = true;
    this.row.state.expanded = false;

    this.configuratorService.refreshConfiguration(
      this.product,
      this.dataGroupItemInstance,
      this.state);

    this.configuratorService.selectNextUndecided(this.product, this.document);
  }
}

