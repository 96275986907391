import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'app-paid-page',
  templateUrl: './paid-page.component.html',
  styleUrls: ['./paid-page.component.css']
})
export class PaidPageComponent implements OnInit {

  constructor(private route: ActivatedRoute, private http: NetworkService) { }

  public orderNr: number;
  public paymentStatus: paymentInfo= {
    isPaid: false,
    amount: {
      currency: 'EUR',
      value: '0.00'
    },
    method: '',
    paidAt: '',
    status: PaymentStatus.loading
  };
  public hasFailed: boolean;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderNr = params.offAltId;

      this.http.post('/api/Payment/getPaymentInfo', { OffAltId: this.orderNr }, 'json')
        .subscribe((response: paymentInfo) => {
          console.log(response);
          this.paymentStatus = response;
        }, (err) => this.hasFailed = true );
    });
  }

}

interface paymentInfo {
  isPaid: boolean,
  status?: PaymentStatus,
  method: string,
  paidAt: string,
  amount: {
    currency: string,
    value: string
  }
}

export enum PaymentStatus {
  open = "open",
  canceled = "canceled",
  pending = "pending",
  authorized = "authorized",
  expired = "expired",
  failed = "failed",
  paid = "paid",
  loading = "loading"
}
