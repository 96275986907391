import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-multi-col',
  templateUrl: './multi-col.component.html',
  styleUrls: ['./multi-col.component.scss']
})
export class MultiColComponent implements OnInit, AfterViewInit {
  @Input() contentJSON: any;
  @Input() params: Param[] = [];
  @Input() environmentJSON: any;

  @ViewChild('viewport', { static: false }) viewport: ElementRef | undefined;

  public heightToTopOfPage: number = 0;
  public gridTemplateRows: string = '';

  constructor(
    public dataService: DataService,
    public utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.contentJSON.cols.forEach(colsRow => {
      colsRow.col && (colsRow['gridTemplateRows'] = colsRow.col.map((colRow:any) => colRow['gridTemplateRow'] ?? 'auto').join(' '));

      this.params.length && colsRow.col.forEach((col:any) => {
        col.paramJSON && (col.paramJSON.params = (col.paramJSON.params ? col.paramJSON.params : []));
        col.paramJSON &&
          this.utilsService.mergeParams({
            params: col.paramJSON.params,
            paramsToMerge: this.params,
            append: true,
            checkQueryParam: false,
            deepCopy: false
          });
      });
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
     this.checkSizes();
    });
  }

  checkSizes(event?: any) {
    this.environmentJSON.viewportMode && (this.heightToTopOfPage = this.viewport.nativeElement.getBoundingClientRect().top);
  }

  windowOpen(path: string, target: string) {
    window.open(path, target);
  }

  trackByFn(index, item) {
    return index;
  }

}
