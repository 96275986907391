import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { UtilsService } from 'src/app/services/utils.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-collapse-list',
  templateUrl: './collapse-list.component.html',
  styleUrls: ['./collapse-list.component.css']
})
export class CollapseListComponent implements OnInit {
  public widgetId = 'faq';
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  public url: string;
  public scrollBehavior = 'smooth';

  @ViewChildren('question', { read: ElementRef }) question: QueryList<ElementRef>;

  constructor(
    private communicationService: CommunicationService,
    public dataService: DataService,
    private utilsService: UtilsService,
    private clipboard: Clipboard) {
  }

  ngOnInit(): void {
    this.url = this.getUrlWithoutParams();
    this.openSearch(this.communicationService.queryParams);
  }

  openSearch(params) {
    this.contentJSON.cols[0].col[0].body.forEach(item => {
      item.hidden = !item.title.includes(this.utilsService.objectFromParams(params).query);

      setTimeout(() => {
        this.utilsService.objectFromParams(params).query &&
          this.question.toArray().find(element => element.nativeElement.id === this.utilsService.objectFromParams(params).query).nativeElement.scrollIntoView({
            behavior: this.scrollBehavior,
            inline: 'center',
            block: 'nearest'
          });
      });
    });
  }

  copyToClipBoard(url) {
    this.clipboard.copy(encodeURI(url));
  }

  getUrlWithoutParams(){
    return location.origin + location.pathname;
 }

 trackByFn(index, item) {
  return index;
}
}
