import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '../page/page.component';
import { WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-help-window',
  templateUrl: './help-window.component.html',
  styleUrls: ['../../widget/popup-window/popup-window.component.scss']
})
export class HelpWindowComponent extends PageComponent implements OnInit, OnDestroy {
  public title: string;

  public containerWidth = {
    current: 0,
    nextMax: 0,
    min: 0,
    maxThumbs: 0
  }

  public position = {
    top: 200,
    left: 800,
    minWidth: 400,
    width: 400,
    height: 600
  };

  ngOnInit(): void {
    super.ngOnInit();

    this.communicationService.initWidget({
      widgetId: this.container + '_help',
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          event: WidgetEvent.CLOSE,
          func: 'closeWindow',
          params: [
            {
              key: '',
            }
          ]
        }
      ]
    });
  }

  ngOnDestroy() {
    this.communicationService.destroyWidgets([this.container]);
    // deze is nodig om de queryparams bij te werken. Kan niet in de communicationService, want dan blijft de window open.
    // dat is een timing probleem. Url is nog niet ververst dan.
    this.communicationService.mergeQueryParams(this.widgetId, this.communicationService.queryParams, [{ outlets: { window: null } }], null, true);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeWindow();
    }
  }

  closeWindow(event?) {
    this.communicationService.mergeQueryParams(this.widgetId, [], [{ outlets: { window: null } }]);
    super.ngOnDestroy();
  }
}
