import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textLinkParse',
  standalone: true
})
export class TextLinkParsePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    // detect url(s) in text and apply a link to it
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Deep copy string to prevent mutation
    const newMsg = JSON.parse(JSON.stringify(value));

    return newMsg.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  }

}
