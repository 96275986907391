<div class="main-search-box" divBreakpointClass (clickOutside)="searchInfoToggle(false);">
  <div class="search-wrapper">
    @if(!hideSearch) {
      <div class="search-input">
        <input type="text"
          #searchInput
          placeholder="Zoeken"
          [(ngModel)]="searchTxt"
          (focus)="searchInfoToggle(true);"
          (keydown)="checkSpecialKeys($event)"
          (keyup)="searchList($event)"
          />
      </div>
      <div [ngClass]="['search-info-box', searchDropDown ? 'to-left' : '']" [hidden]="!searchInfo">
        @if (dataService.searchNav.menu) {
          @for (navItem of dataService.searchNav.menu; track $index) {
            <div (click)="this.searchTxt = navItem.info;" [ngClass]="['search-info-option']">
              <div [ngClass]="[navItem.icon]"></div>
              <span>{{ navItem.text }}</span>
              <br class="clear"/>
            </div>
          }
        }
      </div>
      <div class="search-results-dropdown-wrapper" [hidden]="!searchDropDown">
        <div class="search-results-dropdown">
          @for (item of dataTable[0].data; let index = $index; track index) {
            <div [ngClass]="['search-result-item', (index === resultIndex) ? 'selected' : '']"
                 (click)="search(index)">
              <span #result>{{ item.text }}</span>
            </div>
          }
        </div>
      </div>
      <div class="search-nav">
        <app-cmd
          [component]="component"
          [nav]="searchNav"
          [navList]="['menu']"
          [navVisible]="3"
          [dropdownHidden]="communicationService.user.authBitMask === 1"
          [className]="'cmd-round'"
          [collapsed]="false"/>
      </div>
    }
    @if(!hideLanguage) {
      <div class="language">
        <app-language />
      </div>
    }
  </div>
</div>
