<div class="container-fluid xfw3-grid-container" divBreakpointClass [ngClass]="contentJSON.blockLayoutType">
  <div class="row py-5" [class]="contentJSON.blockCombine">
    <div class="col-12 p-0">
      <div *ngIf="contentJSON.title.length" [ngClass]="['xfw3-grid-col-container', contentJSON.rowClassName ?? '']">
        <div [ngClass]="contentJSON.blockLayoutGridTitle ? contentJSON.blockLayoutGridTitle : dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
          <h2>
            <ng-container *ngFor="let item of contentJSON.title; trackBy: trackByFn">
              <div class="header" *ngIf="item.className" [class]="item.className">{{ item.text }}</div>
              <ng-container *ngIf="!item.className">{{ item.text }}</ng-container>
            </ng-container>
          </h2>
        </div>
      </div>
      <div class="xfw3-grid-col-container" [ngClass]="['xfw3-grid-col-container', contentJSON.rowClassName ?? '']">
        <ng-container *ngFor="let col of contentJSON.cols[0].col; let index = index; trackBy: trackByFn">
          <div *ngIf="col.body" [ngClass]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[index]]">
            <ng-container *ngFor="let item of col.body; trackBy: trackByFn">
              <app-sketch-fab [modelId]="item.modelId"></app-sketch-fab>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
