<div class=" xfw3-grid-container" divBreakpointClass>
  <div class="option">
    <ng-container *ngFor="let option of data; index as index;">
      <div class="xfw3-grid-col-container header">
        <div class="g-col-2">
          {{ option.option }}
        </div>
        <div class="g-col-8">
          {{ option.title }}

        </div>
        <div class="g-col-2 justify-self-end">
          <span *ngIf="data.length > 1">&nbsp;({{ index + 1 }} van {{ data.length }})</span>
        </div>
      </div>

      <app-report-component [data]="option.component"/>

      <div class="footer">
        <div class="info-area">
          <div class="info" *ngIf="option.optionJSON.references.length">
            <div class="title">Referenties</div>
            <div *ngFor="let reference of option.optionJSON.references" class="xfw3-grid-col-container">
              <div class="g-col-4">
                {{ reference.key }}
              </div>
              <div class="g-col-4">
                {{ reference.val }}
              </div>
            </div>
          </div>
        </div>
        <div class="price-info-area">
          <div class="price-info-large">Totaal excl. BTW</div>
          <div class="price-info-small">BTW {{ option.agg.vatPercentage | percent : '1.1' }}</div>
          <div class="price-info-small">Totaal incl. BTW</div>
        </div>
        <div class="price-area">
          <div class="price-info-large">{{ option.agg.price | number : '1.2-2' }}</div>
          <div class="price-info-small">{{ option.agg.vat | number : '1.2-2' }}</div>
          <div class="price-info-small">{{ option.agg.priceIncludingVat | number : '1.2-2' }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
