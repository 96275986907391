import { Component, OnInit, input } from '@angular/core';
import { ConfiguratorProduct } from 'src/app/configurator';
import { DataGroup } from 'src/app/data';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { Nav, Param } from 'src/app/widgets';

@Component({
  selector: 'app-conditional-codes',
  templateUrl: './conditional-codes.component.html',
  styleUrl: './conditional-codes.component.scss'
})
export class ConditionalCodesComponent implements OnInit {
  public product = input<ConfiguratorProduct>({
    configuratorRows: [],
    rows: [],
    code: '',
    mode: 'rows'
  });
  public dataGroupItemInstance= input.required<DataGroup>();

  public nav: Nav;
  public component = this;

  public constructor(
    public configuratorService: ConfiguratorService
  ) { }

  ngOnInit(): void {
    this.initNav();
  }

  initNav() {
    this.nav = {
      navId: 0,
      cancel: {
        className: 'cmd icon xxs round xfw3-bg-transparent',
        icon: 'fa-regular fa-xmark',
        crud: 'delete',
        text: '',
        tabIndex: -1,
        info: 'Verwijder',
        func: 'delete'
      },
    }
  }

  delete(params: Param[], dataItem: any, data: any[]) {
    this.product().rows[dataItem.rowIndex].options[dataItem.optionIndex].conditionalCodesExt[0].codes.splice(dataItem.codesIndex, 1);

    this.configuratorService.selectConditionalCodes(
      this.product(),
      this.product().rows[dataItem.rowIndex],
      this.product().rows[dataItem.rowIndex].options[dataItem.optionIndex],
      null
    );
  }
}
