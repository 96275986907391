<div class="container-fluid xfw3-grid-container" divBreakpointClass>
  @for (row of product().rows; let rowIndex = $index; track row) {
    @if (row.state.isSelected) {
      @for (option of row.options; let optionIndex = $index; track option) {
        @if (option.state.dependent && option.conditionalCodesExt[0]?.codes.length) {
          @for (codes of option.conditionalCodesExt[0].codes; let codesIndex = $index; track codes) {
            <div class="data-row xfw3-grid-col-container">
              <div class="g-col-1">
                <span>{{ codesIndex + 1 }}</span>
              </div>
              <div class="g-col-10">
                @for (code of codes; let codeIndex = $index; let last = $last; track code) {
                  <span>{{ code }}</span>
                  @if (!last) {
                    <span>, </span>
                  }
                }
              </div>
              <div class="g-col-1">
                <app-cmd
                  [component]="component"
                  [dataItem]="{ rowIndex: rowIndex, optionIndex: optionIndex, codesIndex: codesIndex }"
                  [nav]="nav"
                  [navList]="['cancel']"
                  [navVisible]="0"/>
              </div>
            </div>
          }
        }
      }
    }
  }
</div>
