<div class="xfw3-grid-container configurator">
  <div class="xfw3-grid-col-container">
    <div [ngClass]="['g-col-12']">
      <div class="configurator-product">
        <app-data-generic-header
          [widgetId]="widgetId"
          [serverFilter]="[]"
          [component]="component"
          [dataGroupItemInstance]="dataGroupItemInstance"/>
      </div>

      @if (configuratorService.configurator.state.editMode) {
        <app-edit-list
          [widgetId]="dataGroupItemInstance.widgetId"
          [dataGroupItem]="dataGroupItemInstance"
          [state]="'configured'"
          (hasData)="onHasData($event, dataGroupItemInstance)"/>
      }@else {
        <app-configurator-list
          [widgetId]="dataGroupItemInstance.widgetId"
          [dataGroupItem]="dataGroupItemInstance"
          [state]="'configured'"
          (hasData)="onHasData($event, dataGroupItemInstance)"/>

        @if (configuratorService.configurator.state.configured) {
          <div class="configurator-designer-tool">
            @if (dataGroupItemInstance.dataTable[0]?.data[0]?.nasJSON.hasDesignerTool) {
              <div class="designer-tool-container">
                <div class="designer-tool">
                  @if (dataGroupItemInstance.children[0].dataTable[0]?.data?.length) {
                    <svg-inhaker-design-tool
                    [widgetId]="dataGroupItemInstance.children[0].children[0].widgetId"
                    [dataGroupItem]="dataGroupItemInstance.children[0]"
                    [dataItem]="[
                      {
                        type: 'inhaker',
                        ident: 'i1', x: -0.6, y: 0.7, footHeight: 0.006, footWidth: 0.4, poleHeight: 1.95
                      }
                    ]"/>
                  }
                </div>
                <div class="designer-lib">
                  <app-data-group-item
                    [widgetId]="dataGroupItemInstance.children[0].children[0].children[0].widgetId"
                    [dataGroupItem]="dataGroupItemInstance.children[0].children[0].children[0]"/>
                </div>
                <div class="xfw-rowSpacer small"></div>
              </div>
            }
          </div>
        }

        @if (configuratorService.configurator.state.configured) {
          <div class="configurator-nas">
            <app-data-group-item
              [widgetId]="dataGroupItemInstance.children[0].widgetId"
              [dataGroupItem]="dataGroupItemInstance.children[0]"/>

            @if (configuratorService.configurator.state.calculating) {
              <app-spinner/>
            }
          </div>
        }

        <div class="xfw-rowSpacer small"></div>

        @if (configuratorService.configurator.state.nasConfigured && !accessoriesEmpty && this.dataGroupItemInstance.dataTable[0].data[0].metaJSON.hasAccessories) {
          <div class="configurator-accessory" [id]="dataGroupItemInstance.children[1].widgetId">
            <app-data-generic-header
              [widgetId]="dataGroupItemInstance.children[1].widgetId"
              [serverFilter]="[]"
              [dataGroupItemInstance]="dataGroupItemInstance.children[1]"/>
          </div>

          <app-configurator-list
            [widgetId]="dataGroupItemInstance.children[1].widgetId"
            [dataGroupItem]="dataGroupItemInstance.children[1]"
            [state]="'accessoryConfigured'"
            (hasData)="onHasData($event, dataGroupItemInstance.children[1])"/>

          <div class="configurator-accessory-footer">
            <app-data-generic-footer
              [widgetId]="dataGroupItemInstance.children[1].widgetId"
              [dataGroupItemInstance]="dataGroupItemInstance.children[1]"
              [component]="component"/>
          </div>
        }

        <div class="xfw-rowSpacer small"></div>

        @if (configuratorService.configurator.state.calculated) {
          <div class="configurator-price-tier" [id]="dataGroupItemInstance.children[2].widgetId">
            <app-data-group-item
              [widgetId]="dataGroupItemInstance.children[2].widgetId"
              [dataGroupItem]="dataGroupItemInstance.children[2]"/>
          </div>
        }

        <div class="xfw-rowSpacer small"></div>

        @if (configuratorService.configurator.state.priceTierSelected) {
          <div class="configurator-confirm" [id]="dataGroupItemInstance.children[3].widgetId">
            <app-data-generic-header
              [widgetId]="widgetId"
              [dataGroupItemInstance]="dataGroupItemInstance.children[3]"
              [serverFilter]="[]"
              [headerPagination]="false"/>

            <div class="row">
              <div class="col-12 col-md-6">
                <h3>
                </h3>
                <div class="xfw-rowSpacer small"></div>
                <div *ngFor="let row of dataGroupItemInstance.dataTable[0].data[0].configurationJSON.products[0].options;">
                  <span class="question">{{ row.info.question }}</span>
                  <span class="answer">{{ row.info.answer }}</span>
                </div>
                <div>
                  <app-data-group-item
                    [widgetId]="dataGroupItemInstance.children[3].children[0].widgetId"
                    [dataGroupItem]="dataGroupItemInstance.children[3].children[0]"/>
                </div>
              </div>
              <div class="col-12 col-md-6 border-left" style="display: grid; grid-template-rows: min-content 1fr">
                <div>
                  <span class="question">
                    Aantal
                    <span *ngIf="dataGroupItemInstance.children[0].dataTable[0].data[0].width || dataGroupItemInstance.children[0].dataTable[0].data[0].height"> en afmeting</span>
                  </span>
                  @for (row of dataGroupItemInstance.children[0].dataTable[0].data; track $index) {
                    <div class="nas-row">
                      {{ row.nasItemJSON?.text }}
                    </div>
                  }
                  <div class="xfw-rowSpacer small"></div>
                  <span class="question">
                    Oplage
                  </span>
                  <span class="nas-row">
                    {{ dataGroupItemInstance.dataTable[0].data[0].priceJSON.amount }}x
                  </span>
                  <div class="xfw-rowSpacer small"></div>
                  <span class="question">
                    Prijs
                  </span>
                  <span class="nas-row">
                    € {{ dataGroupItemInstance.dataTable[0].data[0].priceJSON.price | number : '1.2-2' }} excl. BTW
                  </span>
                  <div class="xfw-rowSpacer"></div>
                </div>
                <div style="align-self: flex-end;">
                  <app-cmd
                    [className]="'configurator confirmed'"
                    [component]="component"
                    [dataItem]=""
                    [nav]="dataGroupItemInstance.children[3].nav"
                    [navList]="['primary']"
                    [navVisible]="1"/>
                </div>
              </div>
            </div>
            <div class="xfw-rowSpacer"></div>
          </div>
        }
      }
    </div>
  </div>
</div>
