import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DragDropService } from './services/dragdrop.service';

const passEffects = ['all', 'none', 'uninitialized'];


export type DropContainer<T> = {
  data: T;
};

export type OnDroppedEvent<ItemType = any, ContainerOld = any, ContainerNew = any> = {

  previousContainer: DropContainer<ContainerOld>;
  container: DropContainer<ContainerNew>;

  items: DropContainer<ItemType>;
  item: DropContainer<ItemType>;
};

/** Drop zone directive for xfw. Used in merge/re-parenting operations instead of cdkDrag's simple moving. */
@Directive({
  selector: '[xDrop]'
})
export class DropDirective {
  /** Identifier used in determining if dragged items are allowed to be dropped here. */
  @Input() xDropId: string;

  /** Input property for the target item on which something is dropped. */
  @Input({ alias: 'xDrop' }) item: any;

  /** Input property for specifying the drop effect. */
  @Input() xDropEffect: DataTransfer['dropEffect'];


  /** Input property to disable dropping if set to true. */
  @Input() xDropDisabled: boolean = false;

  /** Event emitter for the 'OnDropped' event. */
  @Output() xDropOnDropped = new EventEmitter<OnDroppedEvent>();

  /**Counter for how many drag enters/leaves occurred,
   * since entering of any child element fires the event too. */
  private over: number = 0;

  constructor(
    private element: ElementRef<HTMLElement>,
    private service: DragDropService
  ) { }

  @HostListener('drop', ['$event'])
  private _drop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.over = 0;

    this.xDropOnDropped.emit({
      previousContainer: { data: this.service.oldContainer },
      container: { data: this.item },

      items: { data: this.service.items },
      item: { data: this.service.item },
    });
  }

  @HostListener('dragover', ['$event'])
  private _dragOver(event: DragEvent) {
    if (this.xDropDisabled) return;

    if (this.xDropId && !this.service.dropConnectedTo.includes(this.xDropId)) return;

    // Check if the drop effect is allowed, if not, return early.
    if (!(passEffects.includes(event.dataTransfer.effectAllowed)
      || event.dataTransfer.effectAllowed.match(this.xDropEffect))) return;

    event.dataTransfer.dropEffect = this.xDropEffect;

    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  private _dragEnter(event: DragEvent) {
    if (this.xDropDisabled) return;

    this.over++;

    // When the counter is at least inside of one or more (child) elements.
    if (this.over == 1)
      this.element.nativeElement.setAttribute('x-drag-is-over', 'true');
  }

  @HostListener('dragleave', ['$event'])
  private _dragLeave(event: DragEvent) {
    if (this.xDropDisabled) return;

    this.over--;

    // When the counter isn't inside of any elements.
    if (this.over == 0)
      this.element.nativeElement.setAttribute('x-drag-is-over', 'false');
  }
}
