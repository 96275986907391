@if (loaded && dataGroupItemInstance.dataTable[0]) {
  <div [ngClass]="['xfw3-grid-row-container']">
    <div class="xfw3-grid-header">
      <app-data-generic-header
        [widgetId]="widgetId"
        [dataGroupItemInstance]="dataGroupItemInstance"
        [serverFilter]="serverFilter"
        [(currentPage)]="currentPage"
        (onSearch)="search($event)">
      </app-data-generic-header>
    </div>

    <div cdkDropList
        [cdkDropListDisabled]="!dataGroupItemInstance.isDraggable"
        [id]="widgetId + '-cards-group'"
        cdkDropListGroup
        (cdkDropListDropped)="dropGroup($event)"
        [cdkDropListData]="dataGroupItemInstance.dataTable[0].data"
        [ngClass]="['data-list', 'xfw3-grid-body', dataGroupItemInstance.paging ? 'no-scroll' : '']"
        #dataList>

      @for (groupItem of dataGroupItemInstance.dataTable[0].data; let rowIndex = $index; track groupItem.trackBy) {
        <div (click)="selectParent(dataGroupItemInstance, groupItem); $event.stopPropagation();"
            cdkDrag
            cdkDragPreviewContainer="parent"
            [cdkDragData]="groupItem"
            cdkDragBoundary=".data-list"
            cdkDragLockAxis="y"
            cdkDropList
            [cdkDropListDisabled]="!dataGroupItemInstance.isGroupDraggable"
            (cdkDropListDropped)="dropCard($event)"
            [cdkDropListData]="groupItem.group"
            (cdkDropListExited)="exitedCard($event, rowIndex)"
            [ngClass]="['data-drag-group', 'cards-group',
            dataGroupItemInstance.selectionMode !== 'none' ? 'xfw-hover' : '',
            groupItem.isSelected ? 'selected' : '']"
            [id]="'container_' + rowIndex">

          @for(item of groupItem.group; let groupIndex = $index; track item.trackBy) {
            <div (click)="dataGroupItemInstance.children[0].selectionMode !== 'none' && selectChild(dataGroupItemInstance.children[0], groupItem, item) && $event.stopPropagation();"
                  cdkDrag
                  cdkDragPreviewContainer="parent"
                  [cdkDragData]="item"
                  cdkDragBoundary=".data-list"
                  cdkDropListSortingDisabled
                  cdkDragLockAxis="y"
                  [id]="widgetId + '-group-card-' + rowIndex"
                  [ngClass]="['data-drag-group-card', 'group-card',
                  dataGroupItemInstance.children[0].selectionMode !== 'none' ? 'xfw-hover' : '',
                  item.isSelected ? 'selected' : '']">

              @if (dataGroupItemInstance.isGroupDraggable) {
                <div class="icon" cdkDragHandle>
                  <i class="fa-solid fa-grip-lines"></i>
                </div>
              }

              <div class="title">
                <span title="{{ item[dataGroupItemInstance.card.header.title.field] }}">{{ item[dataGroupItemInstance.card.header.title.field] }}</span>
              </div>

              @if (['editRow', 'edit', 'editForm'].includes(dataGroupItemInstance.children[0]?.edit.mode)) {
                <div [ngClass]="['row-nav-' + (dataGroupItemInstance.children[0].rowOptions.navItems ? dataGroupItemInstance.children[0].rowOptions.navItems : '2'), 'showNav']">
                  <app-cmd [component]="component"
                           [dataItem]="{item, groupItem: groupItem, rowIndex: rowIndex, groupIndex: groupIndex}"
                           [nav]="dataGroupItemInstance.rowOptions.nav"
                           [navList]="['custom', 'primary','secondary','cancel', 'menu']"
                           [navVisible]="0"/>
                </div>
              }
            </div>
          }

          @if (dataGroupItemInstance.card.footer) {
            <div class="icons">
              @for (icon of groupItem[dataGroupItemInstance.card.footer?.icons?.field]; track $index) {
                <div [title]="icon.title ?? ''">
                  @if (icon.iconUrl) {
                    <img [src]="icon.iconUrl"/>
                  }@else {
                    <div class="icon">
                      <span [ngClass]="[icon.icon ?? '']"></span>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }

      @if (['inline', 'form'].includes(dataGroupItemInstance.edit.type) &&
          (dataGroupItemInstance.edit.create || (dataGroupItemInstance.edit.createWhenListIsEmpty && dataGroupItemInstance.dataTable[0].data.length === 0))) {
        <div class="row data-row px-2 pt-2">
          <app-cmd [component]="component" [nav]="addRowNav" [navList]="['primary']" [navVisible]="1"/>
        </div>
      }
    </div>

    <div class="xfw3-grid-footer" #gridFooter>
      <app-data-generic-footer
        [widgetId]="widgetId"
        [dataGroupItemInstance]="dataGroupItemInstance"
        [component]="component"
        [headerPagination]="false"
        [(currentPage)]="currentPage"
        (onAddRow)="addRow($event)"/>
    </div>
  </div>
}

@if (dataGroupItemInstance.edit.type === 'form' && groupEdit.mode) {
  <app-popup-form
    [widgetId]="dataGroupItemInstance.children[0].widgetId"
    [dataGroupItemInstance]="dataGroupItemInstance.children[0]"
    [edit]="groupEdit"
    [rowIndex]="groupIndex"
    (onCloseWindow)="close($event)"
    [window]="{
      nav: true,
      add: dataGroupItemInstance.children[0].edit.create,
      modal: true,
      closeOnOutsideClick: false}"
    (onAddRow)="addGroupRow({
      params: [],
      dataItem: null,
      data: null
    })"
    [navList]="['primary']" />
}

