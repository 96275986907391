import { Component, Input } from '@angular/core';
import { Inhaker, WeightObject, WorldObject } from '../inhaker-simulation/world';
import { SvgToolBaseComponent } from '../svg-inhaker-design-tool/svg-tool-base.component';

@Component({
  selector: 'g [app-svg-tool-inhaker]',
  templateUrl: './svg-tool-inhaker.component.html',
  styleUrls: ['./svg-tool-inhaker.component.scss', '../svg-draw/svg-draw.component.scss']
})
export class SvgToolInhakerComponent extends SvgToolBaseComponent {
  @Input({ required: true }) object!: Inhaker;

  public get hooks() { return new Array(Math.floor(this.object.poleHeight / 0.075)); }

  public override onDrag(element: Inhaker, component: string | undefined, x: number, y: number): boolean | void {
    // Find attached items by inhaker's idx
    const idx = this.parent.objects.findIndex(v => v === this.object);
    const weightObjects = this.parent.objects.filter(v => v instanceof WeightObject) as WeightObject[];

    const multiAttachedItems = weightObjects.filter(v =>
      (v.inhakers[0] === idx && v.inhakers[2] !== undefined)
      || (v.inhakers[0] !== undefined && v.inhakers[2] === idx));


    let min = Number.NEGATIVE_INFINITY,
      max = Number.POSITIVE_INFINITY;

    multiAttachedItems.forEach(v => {
      const other = this.parent.objects[v.inhakers[0] === idx ? v.inhakers[2] : v.inhakers[0]] as Inhaker;

      if (other.x < element.x)
        min = Math.max(min, other.x + (other.footWidth + element.footWidth) * 0.5);
      if (other.x > element.x)
        max = Math.min(max, other.x - (other.footWidth + element.footWidth) * 0.5);
    });

    // Move the inhaker itself
    const newX = Math.max(min, Math.min(max, x));
    const diff = element.x - newX;
    element.x = newX;

    const soloItems = weightObjects.filter(v =>
      v.inhakers.includes(idx) && v.inhakers.filter(v => v !== undefined).length === 1);

    // Move the items based on diff
    soloItems.forEach(v => v.x -= diff);
    multiAttachedItems.forEach(v => {
      v.x -= diff * 0.5;
      v.width += diff * (element.x > v.x ? -1 : 1);

    });

    return true;
  }

  public override onDragStart(element: WorldObject, component: string | undefined, x: number, y: number): void | [number, number] {

  }

  public override onDragEnd(element: WorldObject, component: string | undefined, x: number, y: number): void | [number, number] {

  }

  public export(element: WorldObject): string {
    return '';
  }
}
