import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, lastValueFrom } from "rxjs";
import { NetworkService } from "../services/network.service";

@Component({
  selector: "app-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.css"],
})
export class RedirectComponent implements OnInit {
  constructor(private http: NetworkService, private router: Router) {}

  ngOnInit(): void {
    console.log("test", window.location.hostname);

    // Create http request
    const httpRequest = lastValueFrom(
      this.http.get("/api/Domain/getDomain", "json")
    );


    // Perform http action
    httpRequest.then((data) => {
      const subdomain = window.location.host.replace(/:\d+/,'');

      console.log(data.domainURLs)

      const redirectUrl = data.domainURLs.find(subdomainUrl => subdomainUrl.domainUrl.replace('www.', '') == subdomain)?.rootPath;

      console.log(redirectUrl)

      if(!redirectUrl) {
        throw new Error("Subdomain not found");
      }

      this.router.navigateByUrl(redirectUrl, { replaceUrl: true });

      return true;
    });
  }
}
