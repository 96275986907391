@if (loaded && dataGroupItemInstance.dataTable[0]?.data) {
  <div [ngClass]="['data-list-wrapper', 'xfw3-grid-row-container', dataGroupItemInstance.dataClassName || '' ]">
    <div class="xfw3-grid-header">
      <app-data-generic-header
        [widgetId]="widgetId"
        [dataGroupItemInstance]="dataGroupItemInstance"
        [serverFilter]="serverFilter"
        [headerPagination]="false"
        [(currentPage)]="currentPage"
        (onSearch)="search($event)"
        (onSearchNext)="searchNext($event)"
        (onSearchPrevious)="searchPrevious($event)"
        (onForceReload)="forceReload()"
        (onUpdateMutations)="updateMutations()">
      </app-data-generic-header>
    </div>

    <div class="xfw-grid-title">

    </div>

    <div [ngClass]="['data-list-body', 'xfw3-grid-body', dataGroupItemInstance.paging === 'virtual-scroll' ? 'no-scroll' : 'no-scroll']" #dataList>
      @if (!dataGroupItemInstance.noTitle) {
        <div class="" style="padding-right: 0.25rem;">

          <div [ngClass]="['data-row', 'title-row',
            (dataGroupItemInstance.rowOptions.checkable ? 'checkable' : ''),
            (dataGroupItemInstance.rowOptions.rowCollapsable ? 'col-exp' : ''),
            (dataGroupItemInstance.rowOptions.navItems || ['batch','save'].includes(dataGroupItemInstance.edit.updateMutations) ? 'row-nav' : '')
            ]">
            <div class="check-mark"></div>

            <div class="row xfw3-grid-col-container">
              @for (group of dataGroupItemInstance.dataTable[0].fields; track $index) {

                @for (fieldGroup of group.fieldGroup; track $index) {
                  <ng-container *accessLevel="fieldGroup.xfw.accessLevel">

                    @if (!fieldGroup.xfw.hidden) {
                      <div [ngClass]="[fieldGroup.xfw.dataListClassName | colToGCol]">
                        <h6><small>{{ fieldGroup.templateOptions.label.text }}
                          <div class="title-sort" (click)="setSortField(dataGroupItemInstance.dataTable[0].fields, fieldGroup);">

                            @if(fieldGroup.xfw.sort.direction === 0) {
                              <div class="direction-none">
                                <div class="icon-arrows-up up"></div>
                                <div class="icon-arrows-down down"></div>
                              </div>
                            }@else if (fieldGroup.xfw.sort.direction === -1) {
                              <div class="direction-up">
                                <div class="icon-arrows-up up"></div>
                              </div>
                            }@else {
                              <div class="direction-down">
                                <div class="icon-arrows-down down"></div>
                              </div>
                            }

                            @if (fieldGroup.xfw.sort.order > 0) {
                              <div class="direction-order">{{ fieldGroup.xfw.sort.order }}</div>
                            }

                          </div>
                        </small></h6>
                      </div>
                    }
                  </ng-container>
                }
              }
            </div>

            @if (['editRow', 'editForm', 'edit'].includes(dataGroupItemInstance.edit.mode)) {
              <div [ngClass]="['row-nav-' + (dataGroupItemInstance.rowOptions.navItems ? dataGroupItemInstance.rowOptions.navItems : '2')]">
              </div>
            }
          </div>
        </div>

      }

      <div [cdkDropListDisabled]="!dataGroupItemInstance.isDroplist"
        #virtualScrollElement
          cdkDropList
          [id]="widgetId"
          [cdkDropListConnectedTo]="dataGroupItemInstance.dropConnectTo"
          (cdkDropListDropped)="dragdropService.drop(dataGroupItem, queryParams, widgetId, $event)"
          [cdkDropListData]="dataGroupItemInstance.dataTable[0].data"
          virtualScroll [paging]="dataGroupItemInstance.paging"
          [top]="dataGroupItemInstance.top"
          [page]="dataGroupItemInstance.page"
          [(scroll)]="dataGroupItemInstance.scroll"
          [listLength]="dataGroupItemInstance.dataTable[0].data.length"
          class="data-list h-100">

        @for (item of dataGroupItemInstance.dataTable[0].data | dataFilter : dataFilter | slice: dataGroupItemInstance.top :
        dataGroupItemInstance.top + (
          dataGroupItemInstance.paging === 'pagination' ? dataGroupItemInstance.page :
            dataGroupItemInstance.paging === 'grow' ? dataGroupItemInstance.dataTable[0].data.length : dataGroupItemInstance.scroll);
            track item['trackBy']; let odd = $odd; let rowIndex = $index; let first = $first; let last = $last;) {
          <div (click)="setEditRow(item, $event)" #dataItem
                cdkDrag [cdkDragDisabled]="!dataGroupItemInstance.isDraggable"
                [cdkDragData]="{ component: component, item: item, foreignKeys: dataGroupItemInstance.dataTable[0].foreignKeys }"
                class="data-drag-row">

              @defer(when dataGroupItemInstance.paging === 'pagination'; on viewport) {
                <div class="row-wrapper"
                    [ngClass]="[
                      odd ? 'odd' : '',
                      dataGroupItemInstance.rowOptions.className ?? '',
                      !dataGroupItemInstance.rowOptions.rowCollapsable ? 'collExpHidden' : '',
                      (dataGroupItemInstance.rowOptions.rowCollapsable && item['collapsed']) ? 'collapsed' : '',
                      (dataGroupItemInstance.rowOptions.rowCollapsable && !item['collapsed']) ? 'expanded' : ''
                    ]">

                <div class="expcol-row">
                  <app-data-row
                    [widgetId]="widgetId"
                    (onSelect)="select($event)"
                    (onSetEditMode)="setEditMode($event)"
                    (onUpdateMutations)="updateMutations()"
                    [item]="item"
                    [dataGroupItemInstance]="dataGroupItemInstance"
                    [focus]="dataGroupItemInstance.dataTable[0].selected?.rowIndex === rowIndex ? dataGroupItemInstance.dataTable[0].selected.cell : null"
                    [rowIndexOffset]="dataGroupItemInstance.top"
                    [rowIndex]="rowIndex"
                    [selectedIndex]="index"
                    [last]="last"/>
                </div>

                <div *cdkDragPreview class="drag-preview" >
                  @if (dataGroupItemInstance.isDraggable) {
                    <div>{{ item[dataGroupItemInstance.dragPreviewField] }}</div>

                    @for (dragRow of dataGroupItemInstance.dataTable[0].data; let dragIndex = $index; track dragRow.trackBy) {
                      @if (dragIndex !== rowIndex && dragRow.checked) {
                        <div>
                          {{ dragRow[dataGroupItemInstance.dragPreviewField] }}
                        </div>
                      }
                    }
                  }

                </div>
              </div>
            }@placeholder {
              <div style="height: 2rem; width: 100%; display: flex; align-items: center; justify-content: center;">
                ...
              </div>
            }

            @if (dataGroupItemInstance.detailType === 'root' && !item['collapsed']) {
              @for (child of dataGroupItemInstance.children | filter : 'detailType' : 'child'; track $index) {
                <app-data-group-item
                  [widgetId]="child.widgetId"
                  [dataGroupItem]="child"
                  [dataFilter]="item | primaryKeyObject : dataGroupItemInstance.dataTable[0].primaryKeys" />
              }
            }
          </div>
        }
      </div>

      @if (dataGroupItemInstance.dataTable[0].agg) {
        <div class="data-row totals">
          <div class="check-mark"></div>
          <div class="row">
            <div [ngClass]="dataGroupItemInstance.dataTable[0].agg.className">
              {{ dataGroupItemInstance.dataTable[0].agg.descr }}
            </div>
            @for (aggField of dataGroupItemInstance.dataTable[0].agg.fields; track $index) {
              <div [ngClass]="[aggField.className || '', aggField.format ? aggField.format.pipeToken : '']">
                @if (aggField.format) {
                  <span>{{ aggField.result | dynamicPipe : aggField.format.pipeToken : aggField.format.pipeArgs  }}</span>
                }@else {
                  <span>{{ aggField.result }}</span>
                }
              </div>
            }
          </div>
        </div>
      }
    </div>

    <div class="xfw3-grid-footer" #gridFooter>
      <app-data-generic-footer
        [widgetId]="widgetId"
        [dataGroupItemInstance]="dataGroupItemInstance"
        [component]="component"
        [headerPagination]="false"
        [(currentPage)]="currentPage"
        [dataItem]="utilsService.objectFromParams(dataGroupItemInstance.params)"
        (onAddRow)="addNewRow($event)"/>
    </div>
  </div>
}

@if (dataGroupItemInstance.edit.type === 'form' && edit.mode) {
  <app-popup-form
    [widgetId]="widgetId"
    [dataGroupItemInstance]="dataGroupItemInstance"
    [edit]="edit"
    [rowIndex]="index"
    (onCloseWindow)="close($event)"
    [window]="{
      nav: true,
      add: true,
      modal: true,
      closeOnOutsideClick: false}"
    (onAddRow)="addRow({
      params: [],
      dataItem: null,
      data: null
    })"
    [navList]="['primary']"/>
}
