import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-job-option-list',
  templateUrl: './job-option-list.component.html',
  styleUrls: ['../../widget/data-list/data-list.component.scss', './job-option-list.component.scss']
})
export class JobOptionListComponent extends DataGenericComponent implements OnInit, OnDestroy {
  @Input() dataGroup: DataGroup;

  ngOnInit(): void {
    this.setFromParams(this.dataGroupItem, this.dataItem, this.widgetId);
    this.initWidget();
    this.initNav();

    this.loadDataTable().then(data => {
    });
  }

  collExp(item) {
    item.collapsed = (item.collapsed === 1 ? 2 : 1);
  }

  initNav() {
    this.nav = {
      primary: {
        icon: 'fa-solid fa-plus',
        text: '',
        className: "icon xfw3-bg-secondary round xs",
        type: '',
        info: 'Toevoegen',
        func: 'addNewRow',
        params: [],
        disabled: false,
        isInvisible: !this.dataGroupItemInstance.edit.create
      }
    }
  }

  addNewRow(params: Param[], dataItem?, data?) {
    this.addRow({
      params,
      dataItem,
      data
    });
  }
}
