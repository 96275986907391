<google-map [center]="mapCenter" [zoom]="mapZoom" height="100%" width="100%"
  [options]="{ disableDefaultUI: true, mapId }" (mapClick)="select(null, {})">

  <map-directions-renderer *ngIf="directionsResults$ | async as directions" [directions]="directions"
    [options]="{suppressMarkers: true }" />

  <map-marker *ngFor="let marker of markers; trackBy: trackByFn" [position]="{ lat: marker.latitude, lng: marker.longitude }"
    [title]="marker.title" [icon]="marker.icon" #obj="mapMarker" (mapClick)="select(obj, marker)" />
  <!-- TODO Removed options; reintroduce or other things will not work like labelling -->
  <map-marker *ngFor="let marker of markersRoute; trackBy: trackByFn" [position]="{ lat: marker.latitude, lng: marker.longitude }"
    [title]="marker.title" [icon]="marker.icon" #obj="mapMarker" (mapClick)="select(obj, marker)" [label]="marker.label"
    [options]="marker" />

  <map-info-window>
    <ng-container [ngTemplateOutlet]="infoContent" [ngTemplateOutletContext]="{selected}" />
  </map-info-window>
</google-map>

<ng-template #infoContent let-selected="selected">
  <div class="info-window">
    <div class="content">
      <!-- TODO Make this generic somehow? -->
      <b>{{selected.companyName}}</b>
      <span>{{selected.street}} {{selected.no}}</span>
      <span>{{selected.zip}} {{selected.place}}</span>
      <br>
      <span><a href="tel:{{selected.companyPhone}}">{{selected.companyPhone}}</a></span>
      <span><a href="mailto:{{selected.companyEmail}}">{{selected.companyEmail}}</a></span>
      <span><a href="https:\\{{selected.website}}">{{selected.website}}</a></span>
    </div>
    <span class="info-window-close" (click)="select(null, {})">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

</ng-template>
