<h1 *ngIf="title && title[0].text" [ngClass]="contentJSON.blockLayoutTitleClassName ? contentJSON.blockLayoutTitleClassName : ''">
  <ng-container *ngFor="let item of title; trackBy: trackByFn">
    <span *ngIf="item.className" [class]="item.className" [innerHTML]="item.text"></span>
    <span *ngIf="!item.className" [innerHTML]="item.text">{{ item.text }}</span>
  </ng-container>
</h1>

<h2 *ngIf="subTitle && subTitle[0].text">
  <ng-container *ngFor="let item of subTitle; trackBy: trackByFn">
    <span *ngIf="item.className" [class]="item.className" [innerHTML]="item.text"></span>
    <span *ngIf="!item.className" [innerHTML]="item.text"></span>
  </ng-container>
</h2>
