import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateList'
})
export class DateListPipe implements PipeTransform {

  transform(dateValues: any[], ...args: unknown[]): string {
    if (!dateValues || dateValues.length === 0) {
      return '';
    }

    const _dateValues: Array<Date> = [];
    dateValues.forEach(dateValue => _dateValues.push(new Date(dateValue)));

    const oneDay = 1000 * 60 * 60 * 24;
    const dateFirst_without_time = Date.UTC(_dateValues[0].getFullYear(), _dateValues[0].getMonth(), _dateValues[0].getDate());
    const dateLast_without_time = Date.UTC(_dateValues[_dateValues.length - 1].getFullYear(), _dateValues[_dateValues.length - 1].getMonth(), _dateValues[_dateValues.length - 1].getDate());

    const diffDays = Math.round(Math.abs((dateFirst_without_time - dateLast_without_time) / oneDay));

    if (diffDays === _dateValues.length - 1 && diffDays > 1) {
      return _dateValues[0].toLocaleDateString() + ' - ' + _dateValues[_dateValues.length - 1].toLocaleDateString();
    } else if (diffDays < 1) {
      return _dateValues[0].toLocaleDateString();
    } else {
      return _dateValues.map(date => date.toLocaleDateString()).join(', ');
    }
  }
}
