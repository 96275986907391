import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHHMM'
})
export class SecondsToHHMMPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    if (value === null || value === undefined) return '';

    // Seconds to minutes
    value = Math.floor(value / 60);

    const minutes = value % 60;
    return `${Math.floor(value / 60).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
}
