import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { DataGroup, DataTable } from 'src/app/data';

interface PlaceAndSize {
  top?: string,
  left?: string,
  right?: string,
  bottom?: string,
  width?: string,
  height?: string
}

/**
 * In dit component komt straks een specifieke data-group. De paramJSON wordt
 * dan als de andere layouts. Het zal via een OUTLET route lopen.
 *
 * Voor nu wordt het de console.log voor de session objecten. Wellicht met die
 * ene slimme JSON viewer.
 */

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.css']
})
export class OverlayComponent implements OnInit {
  @Input() placeAndSize: PlaceAndSize;
  @Input() level: number;
  @Input() paramJSON: any;

  public subscription: Subscription;

  form = new UntypedFormGroup({});
  searchTxt = new UntypedFormControl('');

  public origin: string;
  public contentJSON: any;
  public dataGroupItem: DataGroup = {
    src: [{ name: '', type: 'data' }],
    widgetId: 'overlay',
    search: {
      searchFields: ["origin"]
    },
  }

  public dataTable: DataTable[] = [{
    src: '',
    queryParams: [],
    data: [],
    originalData: []
  }];

  public logData: any[] = [];
  public originalData: any[] = [];


  constructor(
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder
    ) {
    this.subscription = dataService.getLog().subscribe(data => {

      if (data && data.level === this.level) {
        this.dataGroupItem.dataTable[0].data.push(data);
        this.dataGroupItem.dataTable[0].originalData.push(data);
      }
    })
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      searchTxt: ''
    });

  }

  onSubmit() {
    this.searchData(this.dataGroupItem.search.searchFields, this.form.value.searchTxt.toLocaleLowerCase().split(' '));
  }

  searchData(searchFields: string[], searchTxts: string[]) {
    this.dataService.searchDataTable(this.dataGroupItem, searchFields, searchTxts);
  }

  clearLog() {
    this.dataGroupItem.dataTable[0].data = [];
    this.dataGroupItem.dataTable[0].originalData = [];
  }
}
