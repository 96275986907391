<div class=" xfw3-grid-container" divBreakpointClass>
  <div class="nas">
    <div class="header xfw3-grid-col-container">
      <div class="g-col-2">
        Aantal
      </div>

      <div class="g-col-2">
        Breed
      </div>

      <div class="g-col-2">
        Hoog
      </div>

      <div class="g-col-4">
        Opmerkingen
      </div>
    </div>

    <div *ngFor="let nasItem of data" class="xfw3-grid-col-container">
      <div class="g-col-2">
        {{ nasItem.amount }}x
      </div>

      <div class="g-col-2">
        {{ nasItem.width }}
      </div>

      <div class="g-col-2">
        {{ nasItem.height }}
      </div>

      <div class="g-col-4" *ngIf="nasItem.description">
        {{ nasItem.description }}
      </div>
    </div>
  </div>
</div>
