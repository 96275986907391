import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataGroup, DataTable } from 'src/app/data';
import { EventParams, Nav, Param } from 'src/app/widgets';

@Component({
  selector: 'app-data-generic-footer',
  templateUrl: './data-generic-footer.component.html',
  styleUrls: ['./data-generic-footer.component.css']
})
export class DataGenericFooterComponent implements OnInit {
  @Input() widgetId: string;
  @Input() dataGroupItemInstance: DataGroup;
  @Input() headerPagination: boolean = false;
  @Input() component: any;
  @Input() currentPage: number;
  @Input() dataItem: any;

  @Output() currentPageChange = new EventEmitter<number>();

  public nav: Nav;

  get curPage() {
    return this.currentPage;
  }

  set curPage(val) {
    this.currentPage = val;
    this.currentPageChange.emit(val);
  }

  constructor() { }

  ngOnInit(): void {
    this.curPage = this.currentPage;

    this.nav = {
      navId: 0,
      primary: {
        icon: 'fa-solid fa-plus',
        text: '',
        className: "icon xfw3-bg-secondary round xxs",
        type: '',
        info: 'Toevoegen',
        func: 'addNewRow',
        params: [],
        disabled: false,
        isInvisible: !this.dataGroupItemInstance.edit.create
      }
    }
  }
}
