import { Component, OnInit } from '@angular/core';
import { DataGroupMatrixGenericComponent } from '../data-group-matrix-generic/data-group-matrix-generic.component';

@Component({
  selector: 'app-data-group-matrix-header',
  templateUrl: './data-group-matrix-header.component.html',
  styleUrls: ['./data-group-matrix-header.component.css']
})
export class DataGroupMatrixHeaderComponent extends DataGroupMatrixGenericComponent implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();
  }
}
