import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { DataGroup, DataTable } from 'src/app/data';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-data-item-content',
  templateUrl: './data-item-content.component.html',
  styleUrls: ['./data-item-content.component.css']
})
export class DataItemContentComponent implements OnInit, AfterViewInit {
  @Input() item: any;
  @Input() lazyLoading: boolean = true;
  @Input() dataGroupItemInstance: DataGroup;

  component = this;

  public lazyLoad: boolean = !this.lazyLoading;
  public dataCondResult: any = {};

  public startTiming = new Date().getTime();

  constructor(private utilsService: UtilsService) { }

  ngAfterViewInit() {
    if (this.lazyLoading) {
      setTimeout(() => {
        // let endTiming = new Date().getTime();
        // console.log(Math.abs(endTiming - this.startTiming));
        if (this.dataGroupItemInstance.dataTable.length > 0) {
          this.lazyLoad = true;
        }
      });
    }
  }

  ngOnInit(): void {
    this.dataCondResult = this.dataGroupItemInstance.dataTable[0]?.dataCondResult.length ? this.dataGroupItemInstance.dataTable[0].dataCondResult.find(condItem => condItem.trackBy === this.item.trackBy) : [];
  }
}
