import { Component, Input, OnInit } from '@angular/core';
import { DomainConfigService } from 'src/app/services/domain-config.service';
import { DataService } from 'src/app/services/data.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { WidgetState, WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {
  @Input() widgetId: string;

  public user = {
    auth: ''
  };

  constructor(public dataService: DataService,
              public communicationService: CommunicationService,
              public domainConfigService: DomainConfigService) {
    this.user.auth = communicationService.user.authBitMask === 1 ? 'anon' : 'auth'
  }

  refresh(params) {
    this.user.auth = null;
    setTimeout(() => {
      this.user.auth = this.communicationService.user.authBitMask === 1 ? 'anon' : 'auth'
    });
  }

  ngOnInit(): void {
    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          event: WidgetEvent.USERINFOCHANGED,
          func: "refresh",
          params: [],
        }
      ],
    });
  }
}
