<form class="uploadForm" [formGroup]="uploadForm" (ngSubmit)="onSubmit()" *ngIf="size == 'form-large'">
  <div class="no-file-hover rounded position-relative d-flex justify-content-center align-items-center uploader-container" *ngIf="status === 'upload'"
    (dragover)="onDragOver($event)" (dragleave)="onDragleave($event)" (drop)="onDrop($event)" [ngClass]="{ 'on-file-hover': dragOver }"
    ngbPopover="{{ extensionString }}" placement="bottom" triggers="mouseenter:mouseleave" container="body">
    <div class="file-upload-info">
      <div *ngFor="let name of uploaded; trackBy: trackByFn">
        <div class="">{{ name }}</div>
      </div>
    </div>

    <div class="file-upload">
      <button class="upload-emblem-container cmd cmd-icon mx-auto rounded-circle">
        <i class="dripicons dripicons-plus upload-emblem-icon"></i>
      </button>
      <input
        formControlName="file"
        type="file"
        multiple="true"
        accept="{{ extensions }}"
        (change)="onFileChange($event)">
    </div>
  </div>

  <div *ngIf="status === 'uploading'" class="circular-progress position-relative no-file-hover rounded uploader-container d-flex justify-content-center align-items-center" style="border-color: var(--gray-400)">
    <div class="file-upload-info">
      <div *ngFor="let name of uploaded; trackBy: trackByFn">
        <div class="">{{ name }}</div>
      </div>
    </div>
    <div class="progress">
      <span class="progress-left">
        <span class="progress-bar border-info" [ngStyle]="{ 'transform': 'rotate(' + getRotateDegFirst + 'deg)' }"></span>
      </span>
      <span class="progress-right">
        <span class="progress-bar border-info" [ngStyle]="{ 'transform': 'rotate(' + getRotateDegSecond + 'deg)' }"></span>
      </span>
      <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
        <div class="h6 font-weight-bold">{{progress}}%</div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" *ngIf="size == 'form-small'">
  <div class="row">
    <!-- input -->
    <div class="col-12" *ngIf="status === 'upload'">
      <input
        class="form-control"
        formControlName="file"
        type="file"
        multiple="true"
        accept="{{ extensions }}"
        (change)="onFileChange($event)">
    </div>

    <!-- progress bar -->
    <div class="row" *ngIf="status === 'uploading'">
      <strong>Uploading...</strong>
      <p><ngb-progressbar type="info" [value]="progress" height="10px"></ngb-progressbar></p>
    </div>
  </div>
</form>
