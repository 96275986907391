<div class="container-fluid xfw3-grid-container" divBreakpointClass [ngClass]="contentJSON.blockLayoutType">
  <div class="row xfw3-grid-col-container" [class]="contentJSON.blockCombine">
    <div [ngClass]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
      <div *ngIf="contentJSON.title.length" class="row">
        <div [ngClass]="contentJSON.blockLayoutGridTitle ? contentJSON.blockLayoutGridTitle : dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
          <h2>
            <ng-container *ngFor="let item of contentJSON.title; trackBy: trackByFn">
              <div class="header" *ngIf="item.className" [class]="item.className" [innerHTML]="item.text | safeHtml"></div>
              <ng-container *ngIf="!item.className"><span [innerHTML] = "item.text | safeHtml"></span></ng-container>
            </ng-container>
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="contentJSON.rowClassName">
    <ng-container *ngFor="let col of contentJSON.cols[0].col; trackBy: trackByFn">
      <div [ngClass]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
        <ng-container *ngFor="let col of contentJSON.cols[0].col; trackBy: trackByFn">
          <div *ngFor="let row of col.rows;  even as isEven; odd as isOdd; first as first; last as last"
            class="row-wrapper xfw3-grid-col-container"
            [ngClass] = "{'first': first, 'last': last}"
            [class.even]="isEven"
            [class.odd]="isOdd">
            <div *ngFor="let column of row.row.columns; trackBy: trackByFn" [ngClass]="[column.className ? 'g-' + column.className : 'g-col-4']">
              <div *ngFor="let item of column.cell; trackBy: trackByFn" [class]="item.className">
                <span *ngIf="item.text" [innerHTML] = "item.text | safeHtml"></span>
                <app-cmd
                  *ngIf="item.nav"
                  [nav]="item.nav"
                  [navList]="['hyperlink']"
                  [navVisible]="0"
                  [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
                  [collapsed]="false">
              </app-cmd>
              </div>
            </div>
          </div>
      </ng-container>
      </div>
    </ng-container>
  </div>
</div>
