import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NetworkService } from '../../services/network.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'xfw-uploader',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnDestroy {
  public status: string = 'upload';
  public files: any[] = [];
  public uploadedFiles: any[] = [];
  public uploaded: any[] = [];
  public dragOver: boolean = false;
  public progress: number = 0;
  public errorMsg: string;

  public count = 0;

  @ViewChild('error') errorModal;

  // Specifies how the uploader should interact
  @Input() widgetId: string;
  @Input() fileJSON: any;
  @Input() size: 'form-small' | 'form-large' = 'form-small';
  @Input() extensions: string[];

  @Output() fileUploads: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: NetworkService,
    private communicationService: CommunicationService,
    private notificationService: NotificationService
    ) {
  }

  ngOnInit(): void {
    this.fileJSON = {...this.fileJSON, ...this.fileJSON.dataFileJSON};
    delete this.fileJSON.dataFileJSON;
  }

  ngOnDestroy(): void {
  }

  get extensionString(): string {
    if(this.extensions && this.extensions.length > 0)
      return this.extensions.toString()

    console.error('No extensions specified for uploader! Uploader will not work properly.');
    return '';
  }

  public uploadForm = new UntypedFormGroup({
    file: new UntypedFormControl('', [Validators.required]),
    fileSource: new UntypedFormControl('', [Validators.required])
  });

  public onSubmit() {

    if(!this.fileJSON) {
      this.notificationService.addNotification({
        msgType: 'error',
        msg: {
          title: 'Error',
          body: { text: 'Error uploading fileJSON' }
        }
       });

      return;
    }

    this.status = 'uploading';
    this.errorMsg = null;
    this.progress = 0;

    const formData = new FormData();

    for(let i = 0; i < this.uploadedFiles.length; i++) {
      formData.append('files', this.uploadedFiles[i]);
      this.uploaded.push(this.uploadedFiles[i].name);
    }

    formData.append('fileJSON', JSON.stringify(this.fileJSON));
    formData.append('widgetId', this.widgetId);

    this.http.post('/api/FileUpload', formData, 'json').subscribe((data) => {
      this.progress = 80;
      setTimeout(() => this.status = 'upload', 500);

      try {
        this.fileUploads.emit(data.msg.uploadedFiles);
      } catch(e) {}

      this.uploadedFiles = [];
    }, (res) => {
      this.notificationService.addNotification({
        msgType: 'error',
        msg: {
          title: 'Error',
          body: { text: 'Error uploading' }
        }
       });
    });

    const counter = setInterval(() => {
      if(this.progress > 60)
        clearInterval(counter);

      this.progress += 3;
    }, 300);
  }

  public onFileChange(event) {
    if(event.target.files.length > 0 && this.checkFileType(event.target.files)) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.uploadedFiles.push(event.target.files[i]);

        if(event.target.files.length-1 == i)
          this.onSubmit();
      }
    } else {
      const errorMsg = `Het bestandstype van "${event.target.files[0].name}" valt niet onder ${this.extensionString}.`;

      this.notificationService.addNotification({
        msgType: 'error',
        msg: {
          title: 'Error',
          body: { text: errorMsg }
        }
       });
    }
  }

  public onFileDropped($event) {
    for (const item of $event)
      this.files.push(item);
  }

  public onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = true;
  }

  public onDragleave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;
  }

  public onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;

    const files: any[] = event.dataTransfer.files;

    if(this.checkFileType(event.dataTransfer.files)) {
      for (let i = 0; i < files.length; i++) {
        this.uploadedFiles.push(files[i]);

        if(files.length-1 == i)
        this.onSubmit();
      }
    } else {
      let errorMsg: string;
      if (this.extensionString === '')
        errorMsg = `De uploader is not goed geconfigureerd. Excuus voor het ongemak.`;
      else
        errorMsg = `Het bestandstype van één of meerdere bestanden valt niet onder ${this.extensionString}.`;

      this.notificationService.addNotification({
        msgType: 'error',
        msg: {
          title: 'Error',
          body: { text: errorMsg }
        }
       });
    }
  }

  public checkFileType(files: any[]): boolean {
    let wrongFileTypeFileNameIndex: number;

    if(!this.extensions || this.extensions.length === 0)
      return false;

    const invalidFile = ![... files].find((file, i) => {
      for (let k = 0; k < this.extensions.length; k++) {
        const extension: string = this.extensions[k].toLowerCase();

        // TODO: voor security moet indexof weg. hallo.jpg.hackbestand werkt nu ook.
        const containsRightExtension = file.name.toLowerCase().indexOf(extension) !== -1;
        // console.log(containsRightExtension, file.name, extension);

        if(containsRightExtension) {
          return false;
        } else {
          wrongFileTypeFileNameIndex = i;

          if(k === this.extensions.length-1) {
            return true;
          }
        }
      }
    });

    if(!invalidFile) {
      const errorMsg = `Het bestandstype van ${files[wrongFileTypeFileNameIndex].name} valt niet onder ${this.extensionString}.`;

      this.notificationService.addNotification({
        msgType: 'error',
        msg: {
          title: 'Error',
          body: { text: errorMsg }
        }
       });
    }

    return invalidFile;
  }

  get getRotateDegFirst(): number {
    if(this.progress > 100)
      this.progress = 100;

    if(this.progress > 50)
      return 180;

    return this.progress * 3.6;
  }

  get getRotateDegSecond(): number {
    if(this.progress > 100)
      this.progress = 100;

    if(this.progress < 50)
      return 0;

    return (this.progress - 50) * 3.6;
  }

  trackByFn(index, item) {
    return index;
  }

}
