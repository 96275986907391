import { Injectable } from '@angular/core';
import { SalesPriceCalculation } from '../configurator';
import { DataGroup } from '../data';
import { DataService } from './data.service';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorDatagroupService {

  constructor(
    private dataService: DataService,
    private networkService: NetworkService
  ) { }

  getConfiguratorCalculateRequest(): any {
    return {
      error_email_addresses: ["cees@sflex.nl"],
      callback_urls: ["https://jmc-psd.nl/jmc/nl/sign/probo/status"],
      deliveries: [{
          address: {
            company_name: "JMC Print - Sign - Displays",
            first_name: "Cees",
            last_name: "Bolijn",
            street: "Technologieweg",
            house_number: "11",
            addition: "",
            postal_code: "4311RV",
            city: "Bruinisse",
            country: "NL",
            phone: "0111485485",
            email: "cees@jmc-psd.nl"
          }
        }
      ],
      products: []
    }
  }
}
