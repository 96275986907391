<div [ngClass]="['action-item', item.isSelected ? 'selected' : '',
                item.status.current.includes('unread') ? 'action-unread' : '',
                !item.status.current.includes('unread') ? 'action-read' : '',
                item.priority.state === 8 ? 'action-alert' : '']">
  @if (item.isSelected) {
    <div class="sub-header-nav">
      <app-cmd [nav]="navBack"
          [className]=""
          [navList]="['primary']"
          [component]="component"
          [navVisible]="1"
          [collapsed]="false"/>
    </div>
  }

  <div class="sub-header-description">
    @if (dataGroupItem.children[0].src[0].name === 'formActionHeader' && item.isSelected) {
      <div class="sub-header-info">
        <app-data-group-item
          [widgetId]="dataGroupItem.children[0].widgetId + '_' + rowIndex"
          [dataGroupItem]="dataGroupItem.children[0]"/>
      </div>
    } @else {
      <input [readonly]="!editMode" [(ngModel)]="item.description" type="text" />
      <div class="sub-header-info">
        <div class="date">
          {{ item.createdDateTime | date: 'dd-MM-yyyy' }}
        </div>
        <div class="contacts">
          {{ item.actionJSON?.relation?.chatContacts }}
        </div>
      </div>
    }
  </div>

  <div class="sub-header-cmd">
    @if (item.actionJSON?.url) {
      <app-cmd
        [nav]="item.actionJSON?.url.nav"
        [className]=""
        [navList]="['primary']"
        [component]="component"
        [navVisible]="1"
        [collapsed]="false"/>
    }
    <app-cmd
        [nav]="dataGroupItem.children[dataGroupItem.children.length-1].rowOptions.nav"
        [className]=""
        [navList]="['primary', 'secondary']"
        [component]="component"
        [dataItem]="item"
        [navVisible]="1"
        [collapsed]="false"/>
  </div>
</div>

@if (item.isSelected) {
  <div [ngClass]="['xfw3-grid-row-fr', 'action-item-container', item.isSelected ? 'selected' : '']">
    <div class="xfw3-grid-row-container">
      @if (dataGroupItem.children[1]?.layout === 'chat') {
        <div class="action-item-container-body">
          <app-action-item-chat
            [widgetId]="dataGroupItem.children[1].widgetId"
            [dataGroupItem]="dataGroupItem.children[1]"
            [params]="dataGroupItem.children[1].params"/>
        </div>
      } @else {
        <div class="action-item-container-body p-2">
          <app-data-content
            [widgetId]="dataGroupItem.children[0].widgetId"
            [dataGroupItem]="dataGroupItem.children[0]"/>
        </div>
      }
    </div>
  </div>
}


