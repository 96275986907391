import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'src/app/services/communication.service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Nav, Param, WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  @Input() widgetId: string;
  @Input() src: string;
  @Input() nav: Nav;
  @Input() params: Param[];
  @Input() dataItem: any;
  @Input() className: string;

  public loaded: boolean = false;
  public routerOutlet = false;
  public dataGroupItemSelected: string;

  public component = this;

  constructor(
    private communicationService: CommunicationService,
    private userService: UserService,
    private utilsService: UtilsService,
    private dataService: DataService) { }

  ngOnInit(): void {
    if (!this.nav) {
      this.loadNav();
    } else {
      this.loaded = true;

      if (this.nav.childRoute) {
        setTimeout(() => {
          this.routerOutlet = true;
        });
      }
    }
  }

  loadNav() {
    return new Promise((resolve, reject) => {
      this.dataService.getNav(this.src)
        .then(data => {
          this.nav = data;
          this.loaded = true;
        });
      });
  }

  selectDataGroupItem(params, dataItem?) {
    if (params.some(param => param.key === 'dataGroup')) {
      this.dataGroupItemSelected = params.find(param => param.key === 'dataGroup').val;
    };
  }
}
