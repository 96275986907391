@if (loaded && dataGroupItemInstance.dataTable[0]) {
  <div [ngClass]="[dataGroupItemInstance.dataClassName?.includes('b-t') ? dataGroupItemInstance.dataClassName : '', 'h-100']">
    <div class="b-t-line"></div>
    <div [ngClass]="['xfw3-grid-row-container', 'h-100', dataGroupItemInstance.dataClassName?.includes('b-t') ? '' : dataGroupItemInstance.dataClassName]">
      <div class="xfw3-grid-header">
        <app-data-generic-header
          [widgetId]="widgetId"
          [dataGroupItemInstance]="dataGroupItemInstance"
          [serverFilter]="serverFilter"
          [(currentPage)]="currentPage"
          (onSearch)="search($event)">
        </app-data-generic-header>
      </div>

      <div [cdkDropListDisabled]="!dataGroupItemInstance.isDraggable"
          cdkDropList
          [id]="widgetId"
          [cdkDropListConnectedTo]="dataGroupItemInstance.dropConnectTo"
          (cdkDropListDropped)="dragdropService.drop(dataGroupItem, queryParams, widgetId, $event)"
          [cdkDropListData]="dataGroupItemInstance.dataTable[0].data"
          [ngClass]="['data-list', 'xfw3-grid-body', 'h-100',  dataGroupItemInstance.page !== -1 ? '' : '']"
          #dataList>

        <div [ngClass]="['xfw3-cards', dataGroupItemInstance.dataClassName || '', dataGroupItemInstance.card.layout ?? '']" horizontalScroll divBreakpointClass>

          <!-- TODO This is temporary code that needs to be replaced by database data. -->
          @if(dataGroupItemInstance.widgetId == 'productsHomeDeAanhakers') {
            <div class="xfw3-card-container xfw3-card-title">
              <div class="bg-primary text-light w-100 h-100 p-2 d-flex" divBreakpointClass>
                <h2 class="fw-bold mt-auto m-2">Onze Producten</h2>
                <h4 class="fw-bold mt-auto m-2">Onze Producten</h4>
              </div>
            </div>
          }

          @if (dataGroupItemInstance.card?.upload) {
            <div class="uploader">
              <app-data-card
                #dataItem
                [item]="utilsService.objectFromParams(dataGroupItemInstance.params)"
                [dataGroupItemInstance]="dataGroupItemInstance"
                [className]="'img'"
                [uploadType]="'multiple'"/>
            </div>
          }

          @defer (on immediate) {
            @for (item of dataGroupItemInstance.dataTable[0].data | dataFilter : dataFilter | slice: dataGroupItemInstance.top : dataGroupItemInstance.top + (dataGroupItemInstance.page === -1 ? 1000 : dataGroupItemInstance.page); let rowIndex = $index; let first = $first; let last = $last; track item['trackBy']) {
              <div #dataItem [xDraggable]="{ component: component, item: item, foreignKeys: dataGroupItemInstance.dataTable[0].foreignKeys }"
                  [xDraggableContainer]="dataGroupItemInstance.dataTable[0].data"
                  [xDragTemplate]="dragTemplate"
                  [xDragDisabled]="!item['checked']"
                  [xDragGetMultiItems]="dragdropService.targetedDropMulti"
                  class="xfw3-card-container"
                  [id]="item | primaryKey : dataGroupItemInstance.dataTable[0].primaryKeys">

                  <app-data-card
                    [index]="rowIndex"
                    [item]="item"
                    [dataGroupItemInstance]="dataGroupItemInstance"
                    [cardSelected]="cardSelected"
                    (onSetEditMode)="setEditMode($event)"
                    (onSelect)="select($event)"
                    [className]="dataGroupItemInstance.rowOptions.className"
                    (onUpdateMutations)="updateMutations()"
                    [uploadType]="'none'"/>

                    @if (dataGroupItemInstance.isDraggable) {
                      <div *cdkDragPreview>
                        <div>{{ item[dataGroupItemInstance.dragPreviewField] }}
                          @for (dragRow of dataGroupItemInstance.dataTable[0].data; let dragIndex = $index; track dragRow.trackBy) {
                            @if (dragIndex !== rowIndex && dragRow.checked) {
                              {{ dragRow[dataGroupItemInstance.dragPreviewField] }}
                            }
                          }
                        </div>
                      </div>
                    }

              </div>
            }
          } @placeholder {
            <div style="height: 15rem; width: 100%; display: flex; align-items: center; justify-content: center;">
              ...
            </div>
          }

        </div>
      </div>

      <div class="xfw3-grid-footer" #gridFooter>
        <app-data-generic-footer
          [widgetId]="widgetId"
          [dataGroupItemInstance]="dataGroupItemInstance"
          [component]="component"
          [headerPagination]="false"
          [(currentPage)]="currentPage"
          (onAddRow)="addRow($event)">
        </app-data-generic-footer>
      </div>

      @if (dataGroupItemInstance.edit.type === 'form' && edit.mode) {
        <div style="position: fixed; top: 60px; left: 0; z-index: 1022;">
          <app-popup-form
            [widgetId]="widgetId"
            [dataGroupItemInstance]="dataGroupItemInstance"
            [edit]="edit"
            [rowIndex]="index"
            (onClose)="close($event)"
            (onAddRow)="addRow($event)"
            [navList]="['primary']">
          </app-popup-form>
        </div>
      }
    </div>
  </div>
}

<ng-template #dragTemplate let-items="items">
  @for (item of items; track item.trackBy) {
    {{ item.code }}<br>
  }
</ng-template>
