import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  private _session: any = {};
  private session: Subject<any> = new Subject<any>();

  constructor() {
    if(sessionStorage.getItem('session')) {
      this._session = JSON.parse(sessionStorage.getItem('session'));
    }
  }

  /**
   * @description Get the current session data
   */
  get getSessionData() {
    return this._session;
  }

  /**
   * @description Get the current session data subject for listening to changes
   */
  get getSessionSubject() {
    return this.session;
  }

  /**
   * @description merge the new session data and update the session subject
   * @param data new object to replace old object
   */
  set modifySessionData(data: any) {
    this._session = { ...this._session, ...data };
    sessionStorage.setItem('session', JSON.stringify(data));
    this.session.next(this._session);
  }
}
