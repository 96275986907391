<div class="container-fluid bg-light">
  <div class="row" divBreakpointClass>
    <div class="col-1"></div>
    <div class="col-10">
      <div class="card-columns cards-portrait">
        <ng-container *ngFor="let card of contentJSON.cols[0].col[0].cards; trackBy: trackByFn">
          <ng-container *ngIf="card.title?.length">
            <div class="card bg-primary p-4 text-light">
              <div class="d-flex h-100">
                <h3 class="d-none d-md-block align-self-end">
                  <ng-container *ngFor="let item of card.title">
                    <span *ngIf="item.className" [class]="item.className" [innerHTML]="item.text">
                    </span>
                    <ng-container *ngIf="!item.className">
                      <span [innerHTML]="item.text"></span>
                    </ng-container>
                  </ng-container>
                </h3>
                <h3 class="d-block d-md-none align-self-end large">
                  <ng-container *ngFor="let item of card.title">
                    <span *ngIf="item.className" [class]="item.className" [innerHTML]="item.text">
                    </span>
                    <ng-container *ngIf="!item.className">
                      <span [innerHTML]="item.text"></span>
                    </ng-container>
                  </ng-container>
                </h3>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!card.title?.length">
            <div class="card text-dark">
              <div [class]="card.className">
                <div style="background-repeat: no-repeat; background-position: center center;"
                    [style.background-size]="card.size ? card.size : 'cover'"
                    [style.width]="card.width ? card.width : '100%'"
                    [style.height]="card.height"
                    [style.background-image]="'url(' + card.url + ')'">
                </div>
              </div>
              <app-cmd
                  *ngIf="card.nav"
                  [nav]="card.nav"
                  [navList]="['custom']"
                  [navVisible]="0"
                  [className]="'text-dark large'"
                  [collapsed]="false">
              </app-cmd>
              <p>
                {{ card.text }}
              </p>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
