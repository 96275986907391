<div class="report">
  <div class="customer report-header">
    <app-report-relations [data]="data.customer"/>
  </div>
  <div class="deliveryAddress report-header">
    <app-report-relations *ngIf="data.deliveryAddress" [data]="data.deliveryAddress"/>
  </div>
  <div class="provider report-header">
    <div class="logo">
      <img [src]="data.quoteInfo.websiteConfig.logo" class="logo">
    </div>
    <div class="address">
      <div>{{ data.quoteInfo.websiteConfig.company.street}} {{ data.quoteInfo.websiteConfig.company.no }}</div>
      <div>{{ data.quoteInfo.websiteConfig.company.zip}}  {{ data.quoteInfo.websiteConfig.company.place }}</div>
      <div>{{ data.quoteInfo.websiteConfig.company.country }}</div>
    </div>
    <div class="contact">
      <div>{{ data.quoteInfo.websiteConfig.company.companyPhone }}</div>
      <div class="xfw-rowSpacer small"></div>
      <div>{{ data.quoteInfo.websiteConfig.company.companyEmail }}</div>
      <div>{{ data.quoteInfo.websiteConfig.company.website }}</div>
    </div>
  </div>
  <div class="quoteInfo report-header">
    <div><span class="emphasized">Offerte: </span>{{ data.quoteInfo.job }}</div>
    <div><span class="emphasized">Offertedatum: </span>{{ data.quoteInfo.jobDateTime | date : 'dd-MM-yyyy'   }}</div>
  </div>
  <div class="quoteHeader report-header">
    <div>{{ data.quoteInfo.salutation }}</div>
    <div>{{ data.quoteInfo.websiteConfig.header }}</div>
  </div>

  <div class="header">
    OFFERTE {{ data.quoteInfo.job }}
    <span *ngIf="data.quote.length > 1">&nbsp;({{ data.quote.length }} opties)</span>
  </div>

  <div class="options">
    <app-report-option [data]="data.quote"/>
  </div>

  <div class="footer">
    <div class="footer-line"></div>

    <div class="remarks">
      <ng-container *ngFor="let remark of data.quoteInfo.productRemarks">
        <div>{{ remark.text }}</div>
      </ng-container>
    </div>

    <div class="xfw-rowSpacer"></div>

    <div class="greetings">
      <div>{{ data.quoteInfo.websiteConfig.greetings }}</div>
      <div>{{ data.handler.fullName }}</div>
      <div class="xfw-rowSpacer small"></div>

      <div class="contact">
        <div class="title">{{ data.handler.companyName }}</div>
        <div>{{ data.handler.companyPhone }}</div>
        <div>{{ data.handler.contactEmail }}</div>
      </div>
    </div>

    <div class="xfw-rowSpacer"></div>

    <div class="footer-info">
      <div [innerHTML]="data.quoteInfo.websiteConfig.footerInfo"></div>
    </div>

    <div class="xfw-rowSpacer small"></div>

    <div class="terms-of-delivery">
      {{ data.quoteInfo.websiteConfig.termsOfDelivery }}
    </div>
  </div>
</div>
