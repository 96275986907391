<div *ngIf = "loaded && dataGroupItemInstance.dataTable[0]?.data?.length">
  <div class="row-header-wrapper" [ngClass]="['noExpCol']">
    <app-data-generic-header
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItemInstance"
      [serverFilter]="serverFilter"
      [(currentPage)]="currentPage"
      (onSearch)="search($event)">
    </app-data-generic-header>

    <div class="data-item-container">
      <app-form
        *ngIf="dataGroupItemInstance.edit.mode === 'edit'"
        [widgetId]="widgetId"
        [dataGroupItemInstance]="dataGroupItemInstance"
        [nav]="dataGroupItemInstance.rowOptions.nav"
        [rowIndex]="selected"
        [formClass]="dataGroupItemInstance.dataClassName ?? 'item'">
      </app-form>

      <app-data-item-content
        *ngIf="dataGroupItemInstance.edit.mode === 'view'"
        [dataGroupItemInstance]="dataGroupItemInstance"
        [item]="dataGroupItemInstance.dataTable[0]?.data[selected]">
      </app-data-item-content>

      <ng-container *ngIf="dataGroupItemInstance.detailType === 'root'">
          <app-data-group-item *ngFor="let child of dataGroupItemInstance.children; trackBy: trackByFn"
            [widgetId]="child.widgetId"
            [dataGroupItem]="child">
          </app-data-group-item>
      </ng-container>
    </div>

    <app-data-generic-footer
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItemInstance"
      [component]="component"
      [headerPagination]="false"
      [(currentPage)]="currentPage"
      (onAddRow)="addRow($event)">
    </app-data-generic-footer>

  </div>
</div>
