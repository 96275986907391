import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataGroup, DataGroupMatrix, DataTable } from 'src/app/data';
import { UtilsService } from 'src/app/services/utils.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-data-group-matrix-generic',
  templateUrl: './data-group-matrix-generic.component.html',
  styleUrls: ['./data-group-matrix-generic.component.css']
})
export class DataGroupMatrixGenericComponent implements OnInit {
  @Input() dataGroupMatrix: DataGroupMatrix[];
  @Input() item: any;
  @Input() index: number = 0;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  public dataGroupItem: DataGroup;
  public dataTable: DataTable[];
  public loaded = false;

  constructor(
    private utilsService: UtilsService,
    private dataService: DataService) { }

  ngOnInit(): void {
    const params = [];
    let data;

    if (this.item) {
      this.dataGroupMatrix[this.index].params.forEach(paramFrom => {
        if (this.item[paramFrom.key]) {
          params.push({
            key: paramFrom.key,
            val: this.item[paramFrom.key]
          });
        }
      });

      data = this.dataGroupMatrix[this.index].data.filter(item => this.utilsService.isSubset(item, this.utilsService.objectFromParams(params)));
    } else {
      data = this.dataGroupMatrix[this.index].data
    }

    this.dataGroupItem = {
      src: [{ name: '', type: 'data' }],
      widgetId: '',
      itemCondFields: this.dataGroupMatrix[this.index].itemCondFields,
    }

    this.dataGroupItem.dataTable = [{
      src: this.dataGroupMatrix[this.index].src[0].name,
      data: data,
      dataCondResult: this.dataGroupMatrix[this.index].dataCondResult,
      fields: this.dataGroupMatrix[this.index].fields,
      cache: this.dataGroupMatrix[this.index].cache,
    }];

    console.log(this.dataGroupItem.itemCondFields);

    this.dataGroupItem.dataTable[0].data.forEach(item => {
      item.itemCondClass = this.utilsService.checkCond(item, this.dataGroupItem.itemCondFields);
    });

    this.loaded = true;
  }

  select(dataItem) {
    if (dataItem.isSelected) {
      dataItem.isSelected = false;
      this.onSelect.emit(dataItem);
    } else {
      this.onClearSelected();
      dataItem.isSelected = true;
      this.onSelect.emit(dataItem);
    }
  }

  onClearSelected() {
    this.dataGroupMatrix.forEach(item => {
      item.data.forEach(x => x.isSelected = false);
    });
  }

  onSelected(event) {
    this.onSelect.emit(event);
  }

  trackByFn(index, item) {
    return item.trackBy;
  }

  log(origin: string, level: number, contentJSON: any) {
    this.dataService.setLog(origin, level, contentJSON);
  }

}
