import { Component, OnInit } from '@angular/core';
import { ActionGroup } from 'src/app/data';
import { CommunicationService } from 'src/app/services/communication.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-action-popup',
  templateUrl: './action-popup.component.html',
  styleUrls: ['./action-popup.component.css']
})
export class ActionPopupComponent implements OnInit {
  public actionGroups: ActionGroup[];

  public component = this;
  public reload = true;

  private actionJSON: any = {
    url: {
      nav: {
        primary: {
          type: "button",
          text: "",
          noToggle: true,
          icon: "dripicons dripicons-arrow-thin-left",
          className: "icon sm bg-transparent",
          path: "",
          params: []
        }
      }
    },
    relation: {
      "contact": "",
      "team": [
        {
          "contact": ""
        }
      ]
    }
  };

  constructor(
    public userService: UserService,
    public communicationService: CommunicationService,
    private utilsService: UtilsService
  ) {

   }

  setUser(user) {
    this.communicationService.user = user.dataItem;
  }

  createAction(params, dataItem) {
    const widgetId = params.find(param => param.key === 'widgetId').val;

    this.communicationService.performAction({
      info: 'action-popup',
      widgetGroup: [widgetId],
      event: WidgetEvent.CREATE,
      dataItem: {...dataItem, ...this.utilsService.objectFromParams(params)}
    });
  }

  ngOnInit(): void {
    this.communicationService.initWidget({
      widgetId: 'actionPopup',
      component: this.component,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: ['actionPopup'],
          event: WidgetEvent.REFRESH,
          func: 'forceRefresh'
        },
        {
          event: WidgetEvent.USERINFOCHANGED,
          func: 'setUser',
        }
      ]
    });
  }

  forceRefresh(params) {
    this.reload = false;
    setTimeout(() => this.reload = true);
  }
}
