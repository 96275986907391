import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { DataGroup, DataTable } from 'src/app/data';
import { Nav } from 'src/app/widgets';

@Component({
  selector: 'app-data-generic-header',
  templateUrl: './data-generic-header.component.html',
  styleUrls: ['./data-generic-header.component.css']
})
export class DataGenericHeaderComponent implements OnInit {
  @Input() widgetId: string;
  @Input() serverFilter: DataTable[];
  @Input() dataGroupItemInstance: DataGroup;
  @Input() headerPagination: boolean = false;
  @Input() currentPage: number;
  @Input() component: any;
  @Input() dataItem: any;

  @Output() currentPageChange = new EventEmitter<number>();

  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSearchPrevious: EventEmitter<any> = new EventEmitter();
  @Output() onSearchNext: EventEmitter<any> = new EventEmitter();

  @Output() onAddRow: EventEmitter<any> = new EventEmitter();
  @Output() onForceReload: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateMutations: EventEmitter<any> = new EventEmitter();

  public nav: Nav;
  public navSearch: Nav;

  public headerComponent = this;

  form = new UntypedFormGroup({});
  searchTxt = new UntypedFormControl('');

  showFilter = false;

  get curPage() {
    return this.currentPage;
  }

  set curPage(val) {
    this.currentPage = val;

    this.currentPageChange.emit(val);
  }

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      searchTxt: ''
    });

    this.curPage = this.currentPage;

    this.navSearch = {
      navId: -51,
      menu: [
        {
          icon: 'fa-solid fa-chevron-left',
          text: '',
          className: "icon xfw3-bg-secondary round xxs",
          info: '',
          params: [],
          disabled: false,
          noToggle: true,
          type: 'button',
          isInvisible: !this.dataGroupItemInstance.search?.searchFields,
          func: 'searchPrevious'
        },
        {
          icon: 'fa-solid fa-chevron-right',
          text: '',
          className: "icon xfw3-bg-secondary round xxs",
          info: '',
          params: [],
          disabled: false,
          noToggle: true,
          type: 'button',
          isInvisible: !this.dataGroupItemInstance.search?.searchFields,
          func: 'searchNext'
        },
        {
          icon: 'fa-solid fa-magnifying-glass',
          text: '',
          className: "icon xfw3-bg-secondary round xxs",
          info: '',
          params: [],
          disabled: false,
          noToggle: true,
          type: 'submit',
          isInvisible: !this.dataGroupItemInstance.search?.searchFields,
          func: 'search'
        },
        {
          icon: 'fa-solid fa-filter',
          text: '',
          className: "icon xfw3-bg-secondary round xxs",
          info: '',
          params: [],
          type: 'button',
          disabled: false,
          isInvisible: !this.serverFilter.length,
          noToggle: false,
          func: 'filter'
        },
        {
          icon: 'fa-solid fa-arrows-rotate',
          text: '',
          className: "icon xfw3-bg-secondary round xxs",
          info: '',
          params: [],
          isInvisible: !(this.dataGroupItemInstance.edit.updateMutations === 'batch'),
          disabled: false,
          noToggle: true,
          accessLevel: this.dataGroupItemInstance.edit.accessLevel.crud,
          type: 'button',
          func: 'forceReload'
        },
        {
          icon: 'fa-solid fa-cloud-arrow-up',
          text: '',
          className: "icon xfw3-bg-secondary round xxs",
          info: '',
          params: [],
          isInvisible: !(this.dataGroupItemInstance.edit.updateMutations === 'batch'),
          disabled: false,
          noToggle: true,
          accessLevel: this.dataGroupItemInstance.edit.accessLevel.crud,
          type: 'button',
          func: 'updateMutations'
        }
      ]
    };
  }

  search(params, dataItem, data, event) {
    this.onSearch.emit({
      params: params,
      dataItem: dataItem,
      data: this.form.value.searchTxt.toLocaleLowerCase().split(' '),
      event: event
    });
  }

  searchPrevious(params, dataItem, data, event) {
    this.onSearchPrevious.emit({
      params: params,
      dataItem: dataItem,
      data: data,
      event: event
    });
  }

  searchNext(params, dataItem, data, event) {
    this.onSearchNext.emit({
      params: params,
      dataItem: dataItem,
      data: data,
      event: event
    });
  }

  forceReload(params, dataItem, data, event) {
    this.onForceReload.emit({
      params: params,
      dataItem: dataItem,
      data: data,
      event: event
    });
  }

  updateMutations(params, dataItem, data, event) {
    this.onUpdateMutations.emit(event);
  }

  filter() {
    this.showFilter = !this.showFilter;
  }
}
