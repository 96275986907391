<div class="window-modal xfw-modal" divBreakpointClass *ngIf="loaded">
  <div class="window-overlay"></div>
  <div class="window-container" cdkDrag [ngClass]="[window.size, window.className ?? '']">
    <div class="window-body">
      <div class="controls">
        <div class="fa-solid fa-grip-dots-vertical px-3" cdkDragHandle></div>
        <div class="fa-solid fa-xmark" (click)="closeWindow($event)"></div>
      </div>
      <app-content-block
        *ngIf="blocks"
        [blocks]="blocks"
        [environmentJSON]="environmentJSON">
      </app-content-block>
    </div>
    <div *ngIf="window.footer" class="window-footer">
      <div [ngClass]="['primary', window.footer?.className ?? '']" >
        <app-cmd
          *ngIf="window.footer?.nav"
          [nav]="window.footer.nav"
          [navList]="['primary']"
          [navVisible]="0"
          [className]="window.footer.nav.className ?? ''"
          [collapsed]="false">
        </app-cmd>
      </div>
    </div>
  </div>
</div>
