<div class="top d-flex flex-row h-100" [ngClass]="isSelected ? 'selected' : ''">

  <div [hidden]="!visibility.collapseButton" class="header-collapse-expand"
    [ngClass]="collapsed ? 'collapsed' : 'expand'" (click)="toggleCollapsed(); $event.stopPropagation();">
  </div>

  <span class="flex-grow-1 flex-shrink-1 overflow-hidden text-truncate">{{ resource.title }}</span>


  <div class="time-planned" [ngClass]="{'overtime' : totalTime < plannedTime }" [ngbPopover]="timePopover"
    container="body" triggers="mouseenter:mouseleave" popoverTitle="Planning">

    <span class="planned" title="Ingeplande uren">
      {{ plannedTime | minutesToTime }}
    </span>
    &nbsp;
    <span class="unplanned" [title]="totalTime < plannedTime ? 'Overuren' : 'Nog in te plannen'">
      {{ (totalTime - plannedTime) | minutesToTime }}
    </span>

  </div>

  <app-cmd *ngIf="resourceMenuNav" class="menu-button" [dataItem]="resource" [component]="component"
    [nav]="resourceMenuNav" [navList]="['menu']" [navVisible]="0" />

</div>

<div class="detail-view px-3" [hidden]="!visibility.details || !isSelected">
  <app-cmd class="task-card-cmd" [dataItem]="resource" [component]="component" [nav]="resource.competenceNav"
    [navList]="['menu']" [navVisible]="5" />
  <div class="locations">
    {{ locationNames.join(', ') }}
  </div>
</div>

<ng-template #timePopover>
  <span>Ingepland uren: {{ plannedTime | minutesToTime }}</span><br />
  <span>Waarvan reistijd: {{ travelTime | minutesToTime }}</span><br />
  <span>Nog in te plannen: {{ (totalTime - plannedTime) | minutesToTime }}</span>
</ng-template>
