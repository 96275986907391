<div class="container-fluid pb-0 pb-sm-5" divBreakpointClass [ngClass]="contentJSON.blockLayoutType">
  <div class="row">
    @if (contentJSON.blockLayoutType.includes('right')) {
      <div class="d-none d-lg-block col-lg-1"></div>
    }
    <div class="d-none d-lg-block col-lg-11 p-0">
      <div style="
        margin-top: -8rem;"
        [style.background-size]="contentJSON.cols[0].col[0].img[0].size ? contentJSON.cols[0].col[0].img[0].size : 'cover'"
        [style.width]="contentJSON.cols[0].col[0].img[0].width ? contentJSON.cols[0].col[0].img[0].width : '100%'"
        [style.height]="contentJSON.cols[0].col[0].img[0].height"
        [style.background-image]="'url(\'' + contentJSON.cols[0].col[0].img[0].url + '\')'">
        <div class="bg-primary p-4 note mt-5 text-light" [ngClass]="contentJSON.blockLayoutType.includes('right') ? 'float-start' : 'float-end'">
          <h2>
            <ng-container *ngFor="let item of contentJSON?.title ? contentJSON.title : contentJSON.cols[0].col[0].title; trackBy: trackByFn">
              {{ item.text }}
            </ng-container>
          </h2>

          @for (bodyItem of contentJSON.cols[0].col[0].body; track $index) {

            @if(bodyItem.list) {
              <ul class="mt-3 checkmark">
                @for (list of bodyItem.list; track $index) {
                  <li>
                    @for (item of list.item; track $index) {
                      {{ item.text }}
                    }
                  </li>
                }
              </ul>
            }
            @else {
              <p [class]="bodyItem.className" [innerHTML]="(bodyItem.text ? bodyItem.text : '&nbsp;') | safeHtml"></p>
            }

          }

          <app-cmd
              *ngIf="contentJSON.cols[0].col[0].body[0].nav"
              [nav]="contentJSON.cols[0].col[0].body[0].nav"
              [navList]="['custom']"
              [navVisible]="0"
              [className]="!contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
              [collapsed]="false">
          </app-cmd>
        </div>
      </div>
    </div>
    <div class="d-block d-lg-none col-12 p-0">
      <div style="margin-top: -5rem"
        [style.background-size]="contentJSON.cols[0].col[0].img[0].size ? contentJSON.cols[0].col[0].img[0].size : 'cover'"
        [style.width]="contentJSON.cols[0].col[0].img[0].width ? contentJSON.cols[0].col[0].img[0].width : '100%'"
        [style.height]="contentJSON.cols[0].col[0].img[0].height"
        [style.background-image]="'url(\'' + contentJSON.cols[0].col[0].img[0].url + '\')'">
      </div>
    </div>
    <div class="d-block d-lg-none col-12 p-0">
      <div class="bg-primary mt-0 text-light">
        <div class="row py-5">
          <div class="col-1 col-xl-2"></div>
          <div class="col-10 col-xl-10">
            <h2>
              @for (item of contentJSON?.title ? contentJSON.title : contentJSON.cols[0].col[0].title; track $index) {
                {{ item.text }}
              }
            </h2>

            @for (bodyItem of contentJSON.cols[0].col[0].body; track $index) {

              @if(bodyItem.list) {
                <ul class="mt-3 checkmark">
                  @for (list of bodyItem.list; track $index) {
                    <li>
                      @for (item of list.item; track $index) {
                        {{ item.text }}
                      }
                    </li>
                  }
                </ul>
              }
              @else {
                <p [class]="bodyItem.className" [innerHTML]="(bodyItem.text ? bodyItem.text : '&nbsp;') | safeHtml"></p>
              }

            }

            @if (contentJSON.cols[0].col[0].body[0].nav) {
              <app-cmd
                [nav]="contentJSON.cols[0].col[0].body[0].nav"
                [navList]="['custom']"
                [navVisible]="0"
                [className]="!contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
                [collapsed]="false"/>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
