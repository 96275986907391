import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CustomFuncService} from '../../services/custom-func.service';
import { CommunicationService} from '../../services/communication.service';
import {  NavItem, Nav, Param, WidgetEvent} from '../../widgets';
import { DataService } from 'src/app/services/data.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { SEOService } from 'src/app/seo.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NetworkService } from 'src/app/services/network.service';
import { query } from '@angular/animations';

@Component({
  selector: 'app-cmd',
  templateUrl: './cmd.component.html',
  styleUrls: ['./cmd.component.css']
})
export class CmdComponent implements OnInit, OnDestroy {
  @Input() component: any;
  @Input() widgetId: string;
  @Input() dataItem: any;
  @Input() data: any;
  @Input() nav: Nav;
  @Input() navList: string[];
  @Input() navVisible: number;
  @Input() dropdownHidden: boolean = false;
  @Input() dropdownClassName: string = "icon sm more";
  @Input() dropdownIcon: string;
  @Input() className: string;
  @Input() text: string;
  @Input() collapsed: boolean;
  @Input() placement: string;

  public msg: any;
  public notification = false;

  constructor(public modalService: NgbModal,
              public customFuncService: CustomFuncService,
              public networkService: NetworkService,
              public dataService: DataService,
              public communicationService: CommunicationService,
              public googleAnalyticsService: GoogleAnalyticsService,
              public utilsService: UtilsService,
              public seo: SEOService
              ) {
  }

  ngOnInit(): void {
    this.checkCondsAndCrud();
  }

  checkCondsAndCrud() {
    ['primary', 'secondary', 'tertiary', 'cancel', 'hyperlink'].forEach(name => {
      (this.nav && this.nav[name] && this.nav[name].disabledCond) &&
        (this.nav[name].disabled = this.utilsService.checkCond(this.utilsService.objectFromParams(this.communicationService.queryParams), this.nav[name].disabledCond));
        (this.nav && this.nav[name] && this.nav[name].crud === 'delete' && this.dataItem.noDelete ) && (this.nav[name].isInvisible = true);
    });

    this.nav && this.nav.menu &&
      this.nav.menu.forEach(nav => {
        nav.disabledCond && (nav.disabled = this.utilsService.checkCond(this.utilsService.objectFromParams(this.communicationService.queryParams), nav.disabledCond));

        (nav.crud === 'delete' && this.dataItem.noDelete ) && (nav.isInvisible = true);
      });
  }

  ngOnDestroy(): void {
  }

  setParams(paramsFrom: Param[], params: Param[]) {
    paramsFrom.forEach(paramFrom => {
      if (paramFrom.src === 'userConfig') {
        let val = this.utilsService.paramsFromObject(this.communicationService.user.config).find(userParam => userParam.key === paramFrom.key)?.val;
        val &&
          (params.find(param => param.key === paramFrom.key || param.alias === paramFrom.key).val = isNaN(val) ?
            val :
            Number(val));
      }

      if (paramFrom.src === 'sessionConfig' && this.communicationService.sessionConfig) {
        let val = this.utilsService.paramsFromObject(this.communicationService.sessionConfig).find(sessionParam => sessionParam.key === paramFrom.key)?.val;

        val &&
          (params.find(param => param.key === paramFrom.key || param.alias === paramFrom.key).val = isNaN(val) ?
            val :
            Number(val));
      }
    });
  }

  perform(item, content, event?) {
    // If there is no params object then create an empty one
    !item.params && (item['params'] = []);

    (item.data) && (this.data = item.data);

    if (item.hasOwnProperty('noToggle') && !item.noToggle) {
      this.nav.dropdownlist &&
        this.nav.dropdownlist.forEach(navOption => {
          navOption.isSelected = false;
        });
      item.isSelected = !item.isSelected;
    }

    this.utilsService.paramsFromDataItem(
        structuredClone(item.params),
        {...(this.utilsService.objectFromParams(this.communicationService.queryParams)), ...this.dataItem || {}}
    ).forEach(paramFrom => {
      (item.params.some(param => param.key === paramFrom.key)) && (item.params.find(param => param.key === paramFrom.key).val = paramFrom.val);
    });

    item.params && this.setParams(item.params, item.params);

    if (item.msg && item.msg?.action !== 0 && item.msg?.action !== WidgetEvent.DONE) {
      this.msg = item.msg;
      this.msg.text = this.utilsService.parseTemplate(this.msg.text, this.dataItem);

      this.modalService.open(content, { centered: true }).result.then((result) => {
        result === 'OK' && this._perform(item, null, event);
      });
    } else if (!item.doNotExecute) {
      this._perform(item, null, event);
    } else {
      this.customFuncService.perform(item.doNotExecuteFunc, item.params, item.params, item.dataItem ?? this.dataItem, this.component);
    }
  }

  _perform(item: NavItem, callback?, event?) {
    (this.dataItem && item.crud) && (this.dataItem.crud = item.crud ?? this.dataItem.crud);

    const widgetAction = (item: NavItem) => {
      item.widgetAction && item.widgetAction.forEach(widgetAction => {

        widgetAction.params && this.setParams(widgetAction.params, widgetAction.params);

        this.communicationService.performAction({
          info: 'cmd',
          widgetGroup: widgetAction.widgetGroup,
          event: widgetAction.event,
          path: widgetAction.path,
          params: widgetAction.params,
          dataItem: this.dataItem,
          data: this.data
        });
      });
    }

    if (item.path || item.callbackPath || item.func || item.procedure) {
      if (item.func || item.procedure) {
        if (item.callbackPath) {
          this.customFuncService.performWithPromise(item.func, item.procedure, [...item.params],
            item.params, item.hasOwnProperty('dataItem') ? item.dataItem : this.dataItem, this.component, this.data, event).then(result => {
            if (item.callbackPath) {
              item.path = item.callbackPath;
              this.routerNavigate(item);
            }

            if (typeof callback === 'function') {
              callback(item.msg);
            }

            widgetAction(item);
          });
        } else {
          this.customFuncService.perform(item.func, item.procedure, [...item.params],
            item.params, item.hasOwnProperty('dataItem') ? item.dataItem : this.dataItem, this.component, this.data, event, item.onlyWhenItemsAreChecked).then(result => {
            typeof callback === 'function' && callback(item.msg);

            setTimeout(() => {
              widgetAction(item);
            });
          });
        }
      } else if (item.path) {
        (item.track) && this.googleAnalyticsService.sendAnalyticsEvent({ ...item.track, ...{ params: item.params } });

        widgetAction(item);

        this.routerNavigate(item);
      }
    } else if (item.params.length) {
      widgetAction(item);
      this.communicationService.mergeQueryParams(this.widgetId, item.params, [], null, true);
      this.checkCondsAndCrud();
    } else {
      widgetAction(item);
    }
  }

  routerNavigate(item: NavItem) {
    let queryParams: Param[] = item.params.filter(param => param.isQueryParam);

    queryParams.forEach(param => {
      param.isNav = true;
    });

    const url: any = [item.path];

    // SEO
    this.seo.setSEOData(item.seo);

    if (item.path && !item.outlet) {
      if (item.path.startsWith('http')) {
        window.open(item.path, '_blank');
      } else {
        item.isSelected = true;

        this.nav.menu?.filter(x => x.navItemId !== item.navItemId)
          .forEach(item => { item.isSelected = false; });

        let fragment = url[0].split('#')[1];
        url[0]=url[0].split('#')[0];

        url.push({outlets: { window: null } });

        // if (queryParams.length) {
        //   this.communicationService.mergeQueryParams(this.widgetId, queryParams, url, fragment);
        // } else {

        this.communicationService.setQueryParams(this.widgetId, queryParams, url, fragment);
        //}
      }
    } else {
      const outlets = {};
      outlets[item.outlet] = url.join('/').split('/');

      this.communicationService.mergeQueryParams(this.widgetId, queryParams, [{ outlets: outlets }]);

    }
  }

  log(origin: string, level: number, contentJSON: any) {
    this.dataService.setLog(origin, level, contentJSON);
  }

  trackByFn(index, item) {
    return index;
  }
}
