import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { WindowComponent } from './layout/window/window.component';
import { PaidPageComponent } from './layout/paid-page/paid-page.component';
import { PageComponent } from './layout/page/page.component';
import { RedirectComponent } from './redirect/redirect.component';
import { PopupComponent } from './layout/popup/popup.component';
import { ReportComponent } from './layout/report/report.component';
import { PresentationComponent } from './layout/presentation/presentation.component';
import { HelpWindowComponent } from './layout/help-window/help-window.component';

const routes: Routes = [
  { path: 'ih/iv/steelplate', redirectTo: '/xfw/nl/onderdeel?object=06-0600-1187', pathMatch: 'full' },
  { path: 'ih/iv/inhaker', redirectTo: '/xfw/nl/onderdeel?object=06-0600-1187', pathMatch: 'full' },
  {
    path: 'xfw/:lang/window/:content',
    component: WindowComponent,
    outlet: 'window'
  },
  {
    path: 'xfw/:lang/help/:content',
    component: HelpWindowComponent,
    outlet: 'help'
  },
  {
    path: 'xfw/:lang/popup/:content',
    component: PopupComponent,
    outlet: 'popup'
  },
  {
    path: 'xfw/:lang/presentation',
    component: PresentationComponent
  },
  {
    path: 'xfw/:lang/report/:content',
    component: ReportComponent
  },
  {
    path: 'xfw/:lang/:content',
    component: PageComponent
  },
  {
    path: 'paid/:offAltId',
    component: PaidPageComponent
  },
  { path: '**', component: RedirectComponent },
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'disabled',
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled'
}),
    CommonModule
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
