import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-issuu',
  templateUrl: './issuu.component.html',
  styleUrls: ['./issuu.component.scss']
})
export class IssuuComponent implements OnInit {
  @Input() pdf: string;
  @Input() environmentJSON: any;

  public issuuUrl: string;
  public safeIssuuUrl: any;
  constructor(
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.issuuUrl = 'https://e.issuu.com/embed.html?backgroundColor=%23000000&backgroundColorFullscreen=%23000000&d=' + this.pdf + '&hideIssuuLogo=true&u=deinhaker';

    this.safeIssuuUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.issuuUrl);
  }

  trackByFn(index, item) {
    return index;
  }

}
