import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ProboProduct, ConfiguratorProduct, ProboOption, ConfiguratorResultProduct, ConfiguratorResult, ConfiguratorProductAddition, ConfiguratorRow, ConfiguratorAddition } from '../configurator';
import { ConfiguratorDatagroupService } from './configurator-datagroup.service';
import { NetworkService } from './network.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorProboService {
  public product: ConfiguratorProduct;
  public additions: ConfiguratorProductAddition[];
  public configuration: ConfiguratorResult;
  public configurationResult: ConfiguratorResult;

  private renderer: Renderer2;

  public convertType = {
    radio: 'radio',
    width: 'inputCalc',
    height: 'inputCalc',
    amount: 'inputCalc',
    cross_sell_pc: 'number',
    parent: 'parent',
    parent_size: 'parent',
    parent_amount: 'parent',
    number: 'number',
    decimal: 'decimal',
  }

  public itemIndex: number;

  public codeList:any[] = []

  initProduct() {
    this.product = {
      code: '',
      block: {},
      rows: [],
      mode: 'cards',
      configuratorRows: []
    };

    this.configuration = {
      suppliers: [],
      priceTier: {
        amount: 1,
        price: 0
      },
      products: [
        {
          code: null,
          options: []
        }
      ],
      requirements: {}
    };

    this.configurationResult = structuredClone(this.configuration);
  }

  constructor(
    private utilsService: UtilsService,
    private configuratorDatagroupService: ConfiguratorDatagroupService,
    private networkService: NetworkService,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document) {
      this.renderer = rendererFactory.createRenderer(null, null);
  }

  getProduct(code: string, additions?: ConfiguratorProductAddition[]): Promise<ConfiguratorProduct> {
    return new Promise((resolve, reject) => {
      this.initProduct();

      this.networkService.getProboProduct(code).subscribe((data: ProboProduct) => {
        this.configuration.products[0].code = code;

        // size en amount doen we op onze eigen manier, dus die gooien we eruit.
        data.options = data.options.filter(option => !['size','amount', 'accessories-cross-sell'].includes(option.code));

        this.convertProboToProduct(data, this.product);

        additions.length && this.addAdditions(additions)

        const found = this.product.rows.find(row => !row.state.checked && !row.state.hidden);
        if (found) {
          found.state.expanded = true;
        }

        resolve(this.product);
      });
    });
  }

  /**
   * Maakt de resultaat set aan op basis van de geselecteerde opties.
   * Vervolgens wordt aan de hand van de resultaatset bepaald welke rows en options hidden moeten staan.
   *
   * @param product => this.product, het XFW product
   */

  // setConfiguration(product: ConfiguratorProduct, configuration: ConfiguratorResult) {
  //   const configurationOptions = configuration.products[0].options;

  //   product.rows.forEach(row => {
  //     if (configurationOptions.some(option => option.code.split('.')[0] === row.code)) {
  //       const option = row.options.filter(rowOption => rowOption.code === configurationOptions
  //         .filter(option => option.code.split('.')[0] === row.code)[0].code)[0];

  //       option.isSelected = true;
  //       option.state.hidden = false;

  //       row.optionSelected = {
  //         block: option.block,
  //         code: option.code
  //       };

  //       row.state.checked = true;
  //       row.state.expanded = false;
  //       row.state.hidden = false;
  //     }
  //   });

  //   product.rows.some(row => row.state.checked) &&
  //     (configuration.state.configured = !(product.rows.filter(row => !row.state.hidden && !row.state.checked).length > 0));
  // }

  /**
   * @param rows -> de Probo options waarvan de parent op false, de daadwerkelijke keuzes.
   * @param field -> property die uniek moet zijn binnen de rows.
   * @returns array rows zonder dubbele.
   */

  addAdditions(additions?: ConfiguratorProductAddition[]) {
    // this.mergeProduct(this.product, additions);
  }

  /**
     * Deze methode voegt producten van andere suppliers to aan de xfw producten.
     *
     * @param src
     * @param addition
     */

  mergeProduct(src: ConfiguratorProduct, additions: ConfiguratorProductAddition[]) {
    const addSupplierToOptions = (row: ConfiguratorRow, exceptions: string[], suppliers: string[], additions: ConfiguratorAddition[]) => {
      row.options.forEach(option => {
        (!exceptions.includes(option.code)) && (option.suppliers = [...option.suppliers, ...suppliers]);
      });

      (additions.map(addition => addition.code).includes(row.code)) &&
        additions.filter(addition => addition.code === row.code).forEach(addition => {
          addition.options.forEach(option => {
            row.options.push(option);
          });
        })
    }

    additions.forEach(addition => {
      (src.code === addition.code) &&
        src.rows.forEach(row => {
          (!addition.addSuppliers.exceptions.includes(row.code)) &&
            addSupplierToOptions(row, addition.addSuppliers.exceptions, addition.addSuppliers.suppliers, addition.addSuppliers.additions);
        });
    });
  }

  getProboProducts(code) {
    return new Promise((resolve, reject) => {
      const subscription = this.networkService.get('/api/PrintOnline/probo/products/' + code, 'json').subscribe(data => {
        subscription.unsubscribe();
        resolve(data);
      });
    }).catch((err) => {
      return err;
    });
  }

  getProboProductInfo(url: string) {
    return new Promise((resolve, reject) => {
      const subscription = this.networkService.post('/api/PrintOnline/probo/info/', { location: url }, 'text').subscribe(text => {
        const proboInfo: any = {};

        const blocks =  [
          {
            contentJSON: {
              "url": url,
              "blockLayout": "table",
              "blockCombine": "",
              "blockLayoutGrid": [
                "xfw-col-1"
              ],
              "blockLayoutType": "block-light-1",
              "title": [],
              "cols": [
                {
                  "col": [
                    {
                      "body": [],
                      "data": []
                    }
                  ]
                }
              ]
            }
          },
          {
            contentJSON: {
              "url": url,
              "blockLayout": "table",
              "blockCombine": "",
              "blockLayoutGrid": [
                "xfw-col-1"
              ],
              "blockLayoutType": "block-light-1",
              "title": [],
              "cols": [
                {
                  "col": [
                    {
                      "body": [],
                      "data": []
                    }
                  ]
                }
              ]

            }
          }
        ];

        const probo  = this.renderer.createElement('html');

        this.renderer.setProperty(probo, 'innerHTML', text);

        const title = probo.querySelectorAll('h1')[0].innerText.trim();
        console.log(title);

        const content = probo.querySelectorAll('.product__description');
        const subTitles = content[0].querySelectorAll('h2');
        const paragraphs = content[0].querySelectorAll('p');

        paragraphs.forEach((paragraph, index) => {
          if (index === 0) {
            blocks[0].contentJSON.cols[0].col[0].body.push({
              title: title,
              titleClassName: 'small',
              text: paragraph.outerText
            });
          } else {
            if (subTitles.length > index - 1) {
              blocks[0].contentJSON.cols[0].col[0].body.push({
                title: subTitles[index- 1 ].outerText,
                titleClassName: 'small',
                text: paragraph.outerText
              });
            }
          }
        });

        let rows = probo.querySelectorAll('#specificaties > div > li');

        proboInfo.specs = [];

        rows.forEach(row => {
          let term = row.querySelector('span.font-500').outerText.trim();
          let spec = row.querySelector('span.flex').outerText.trim();

          blocks[1].contentJSON.cols[0].col[0].data.push([
            term,
            spec
          ]);
        });

        console.log(blocks);
        subscription.unsubscribe();

        resolve(blocks);
      })
    }).catch((err) => {
      return err;
    });
  }

  /**
   * Onderstaande methode loopt door de root-options van het Probo product
   * en voegt die toe aan het XFW Product via de recursieve Addrow method.
   *
   * De property Index is puur voor testdoeleinden en heeft verder geen functie.
   * De root options zijn conditioneel op de product code.
   *
   * @param proboProduct => complete Probo product op Size en Amount na.
   *
   * block is een tekst blok, werkt ook met verschillende talen op een iets andere manier
   * dan bij Probo. Kan eenvoudig omgezet worden, maar dat doen we hier nu niet.
   *
   * op het eind zetten we alle mogelijke opties nog in een array in de row.
   */

   convertProboToProduct(proboProduct: ProboProduct, product: ConfiguratorProduct) {
    // console.log('begin convert');
    // console.log(proboProduct);
    // console.log(product);
    // console.log('end convert');
    product.code = proboProduct.code;
    product.block.title = proboProduct.translations.nl.title;
    product.articelGroupName = proboProduct.article_group_name;
    this.itemIndex = 0;

    proboProduct.options.forEach((proboOption, index) => {
      this.addRow({
        product: product,
        index: index,
        proboOption: proboOption,
        conditionalCodes: [],
        suppliers: [93]
      });
    });

    this.compressRows(product);

    const allOptions: any[] = [];

    [... new Set(product.rows.map(row => row.code))].forEach(code => {
      allOptions.push({
        parent: code,
        optionCodes: product.rows.find(row => row.code === code).options.map(option => option.code)
      });
    });

    // this.compressConditionalCodes(product, allOptions);

    this.setConditionalCodesExt(product);

    product.rows.forEach(row => {
      delete row.index;
      delete row.itemIndex;
    });
  }

  setConditionalCodesExt(product: ConfiguratorProduct) {
    product.rows.forEach(row => {
      row.options.forEach(option => {
        option.conditionalCodes.forEach(conditionalCodeSet => {
          option.conditionalCodesExt.push({
            itemIndex: option.itemIndex,
            codes: [structuredClone(conditionalCodeSet)]
          });
        });

        delete option.conditionalCodes;
      });

      delete row.conditionalCodes;
    });
  }

  /**
   * index => voor testdoeleinden
   * proboOption => de probo option
   * conditionalCodes => Als de conditionele codes allemaal voor komen in het huidige Result dan is de row zichtbaar.
   *
   * we werken met onze eigen types, maar dat is een eenvoudige translatie.
   * we slaan de probo option settings op.
   *
   * De state wordt gebruikt om de juiste classes op een optie-rij te zetten. De checked staat op true als een keuze
   * is gemaakt. Zodra alle mogelijke (hidden = false) optie-rijen op checked staan wordt de data-list met aantal en afmeting zichtbaar.
   *
   * Current moet op deze manier omdat er recursief rijen worden toegevoed, dan zou het toevoegen van de volgende optie binnen de
   * huidige children fout gaan.
   */

   addRow({ product, index, proboOption, conditionalCodes, suppliers } :
          { product: ConfiguratorProduct, index: number, proboOption: ProboOption, conditionalCodes: string[], suppliers: number[] }) {
    let optionCode = proboOption.code || 'default';

    // optionCode = proboOption.children.some(child => child.code.includes('backside')) ? optionCode + '-backside' : optionCode;
    const itemIndex = this.itemIndex++;

    product.rows.push({
      options: [],
      code: optionCode,
      conditionalCodes: structuredClone(conditionalCodes.length ? [conditionalCodes] : []),
      index: index,
      itemIndex: itemIndex,
      block: proboOption.translations.nl,
      type: this.convertType[proboOption.type_code],
      settings: proboOption.settings,
      state: {
        editMode: false,
        hidden: true,
        expanded: false,
        checked: false
      }
    });

    !this.codeList[optionCode] && (this.codeList[optionCode] = []);

    this.codeList[optionCode].push({
      itemIndex: itemIndex
    });

    const current = product.rows.length - 1;

    if (proboOption.children?.length) {
      proboOption.children.forEach((child, childIndex) => {
        this.addOption({
          product: product,
          index: index,
          itemIndex: itemIndex,
          row: product.rows[current],
          proboOption: child,
          sortOrder: childIndex,
          conditionalCodes: conditionalCodes,
          suppliers: suppliers
        });
      });
    }
  }

  /**
   * Hier worden de mogelijke opties toegevoegd. Als onder een optie weer een rij hangt dan wordt die toegevoegd aan de rows, recursief.
   * Daarbij wordt de code van de huidige optie toegevoegd aan de conditionele codes voor de desbetreffende row, zodat die alleen zichtbaar is
   * als de huidige optie is gekozen.
   *
   * index => om te testen.
   * row => huidige row
   * proboOption => mogelijke keuze(s).
   * conditionalCodes => Als de conditionele codes allemaal voor komen in het huidige Result dan is de optie zichtbaar.
   */

  addOption({ product, index, itemIndex, row, proboOption, sortOrder, conditionalCodes, suppliers } :
            { product: ConfiguratorProduct, index: number, itemIndex: number, row: any, proboOption: ProboOption,
              sortOrder: number, conditionalCodes: string[], suppliers: number[]}) {
    var rowCode = row.code || 'default';

    row.options.push({
      conditionalCodes: structuredClone(conditionalCodes),
      code: [rowCode, proboOption.code].join('.'),
      itemIndex: itemIndex,
      sortOrder: sortOrder,
      suppliers: suppliers,
      imageUrl: proboOption.images[0]?.url,
      block: proboOption.translations.nl,
      type: this.convertType[proboOption.type_code],
      unit: proboOption.unit_code,
      values: {
        value: proboOption.default_value,
        default: proboOption.default_value,
        max_value: proboOption.max_value,
        max_value_formula: proboOption.max_value_formula,
        min_value: proboOption.min_value,
        min_value_formula: proboOption.min_value_formula,
        reversible: proboOption.reversible,
        scale: proboOption.scale
      },
      selected: false,
      inValid: false,
      state: {
        hidden: true
      }
    });

    if (proboOption.children?.length) {
      proboOption.children.forEach(child => {
        this.addRow({
          product: product,
          index: index,
          proboOption: child,
          conditionalCodes: [...conditionalCodes, ...[[row.code, proboOption.code].join('.')]],
          suppliers: suppliers
        });
      });
    }
  }

  /**
   * Zodra de rows en options zijn toegevoegd aan het XFW product zijn er dubbele rows en dubbele options.
   * die compleet 't zelfde zijn op de conditioneleCodes na, die verschillen.
   *
   * de compressRows methode voegt alle conditioneleCodes die horen bij een bepaalde row of optie samen
   * en verwijdert de overige rijen om zo tot een compact product te komen.
   *
   * de conditioneleCodes zijn arrays van arrays.
   *
   * met new Set zorgen we ervoor dat alle arrays binnen de conditioneleCodes uniek zijn.
   */

   compressRows(product: ConfiguratorProduct) {
    const uniqueCodes = [... new Set(product.rows.map(row => row.code))];

    uniqueCodes.forEach(uniqueCode => {
      const multipleRows = product.rows.filter(row => row.code === uniqueCode);

      if (multipleRows.length > 0) {
        let rowOptions = [];

        const conditionalCodes = multipleRows.map(row => row.conditionalCodes.length === 0 ? [] : row.conditionalCodes[0]);
        const conditionalCodesExt = [];

        structuredClone(multipleRows).forEach(row => {
          conditionalCodesExt.push({
            itemIndex: row.itemIndex,
            codes: [structuredClone(row.conditionalCodes.flatMap(conditionalCode => conditionalCode.length === 0 ? [] : conditionalCode))]
          });
        });

        const options = [].concat.apply([], multipleRows.map(row => row.options));
        const uniqueOptionCodesAndItemIndex = options.filter((option, index, self) =>
          index === self.findIndex((t) => (
            t.code === option.code && t.itemIndex === option.itemIndex
          )));

          uniqueOptionCodesAndItemIndex.forEach(uniqueOptionCodeAndItemIndex => {
          const multipleOptions = options.filter(option => option.code === uniqueOptionCodeAndItemIndex.code && option.itemIndex === uniqueOptionCodeAndItemIndex.itemIndex);

          if (multipleOptions.length > 0) {
            const optionConditionalCodes = [... new Set(multipleOptions.filter(option => option.conditionalCodes.length).map(option => option.conditionalCodes))];

            multipleOptions.forEach((option, index) => {
              if (index === 0) {
                option.conditionalCodes = optionConditionalCodes;
                option.conditionalCodesExt = [];
              } else {
                option.code = '';
              }
            });
          }

          rowOptions = [...rowOptions, ...multipleOptions.filter(option => option.code !== '')];
        });

        multipleRows.forEach((row, index) => {
          if (index === 0) {
            row.conditionalCodes = conditionalCodes;
            row.conditionalCodesExt = conditionalCodesExt;
            row.options = rowOptions;
          } else {
            row.code = '';
          }
        });
      }
    });

    product.rows = product.rows.filter(row => row.code !== '');
  }

  /**
   * compressConditionalCodes comprimeert de conditionele codes door het vervangen van
   * conditioneleCodes die alle opties bieden, die worden vervangen door parent.*. Voordeel
   * is een compacte lijst conditionele codes en veel eenvoudiger toevoegen van extra
   * mogelijkheden.
   *
   * Nadat de mogelijke codes zijn vervangen door parent.* lopen we nogmaals door de lijst.
   * Als item n+1 een * bevat en item n ook, dan verwijderen we item n.
   */

   compressConditionalCodes(product: ConfiguratorProduct, allOptions: any[]) {
    const getConditionalParents = (conditionalCodeSet) => {
      return conditionalCodeSet.map(conditionalCode => conditionalCode.split('.')[0]).join('_');
    }

    const getColumnCodes = (conditionalCodes, conditionalParent, colIndex) => {
      return (conditionalCodes.length > colIndex && conditionalCodes[colIndex].length) ? [... new Set(conditionalCodes
        .filter(conditionalCode => getConditionalParents(conditionalCode) === conditionalParent)
        .map(conditionalCode => conditionalCode[colIndex]))]
        :
        [];
    }

    const removeObsoleteConditionalCodes = (conditionalCodeSet: string[]) => {
      let index = conditionalCodeSet.length - 1;

      while (index > 0) {
        if (conditionalCodeSet[index].includes('*') && conditionalCodeSet[index - 1].includes('*')) {
          conditionalCodeSet.splice(index - 1, 1);
        }
        index--;
      }
      return conditionalCodeSet;
    }

    const replaceConditionalCodes = (conditionalCodes: string[][]) => {
      const conditionalParents = [... new Set(conditionalCodes.map(conditionalCodeSet => getConditionalParents(conditionalCodeSet)))];

      conditionalParents.forEach(conditionalParent => {
        conditionalParent.split('_').forEach((column, colIndex) => {
          const columnCodes: any[] = getColumnCodes(conditionalCodes, conditionalParent, colIndex);
          columnCodes.length && this.utilsService.isSameArray(columnCodes, allOptions.find(option => option.parent === column).optionCodes) &&
            conditionalCodes.filter(conditionalCodeSet => getConditionalParents(conditionalCodeSet) === conditionalParent).forEach(conditionalCodeSet => {
              conditionalCodeSet[colIndex] = [conditionalCodeSet[colIndex].split('.')[0], '*'].join('.');
            });

        });
      });

      conditionalCodes = this.utilsService.uniqueArrays(conditionalCodes);

      conditionalCodes.forEach(conditionalCodeSet => {
        conditionalCodeSet = removeObsoleteConditionalCodes(conditionalCodeSet);
      });

      return conditionalCodes;
    }

    product.rows.forEach(row => {
      row.conditionalCodes = replaceConditionalCodes(row.conditionalCodes);

      row.options.forEach(option => {
        option.conditionalCodes = replaceConditionalCodes(option.conditionalCodes);
      });
    });
  }

    /**
   * Calculate maakt de probo products json aan zodat we de prijzen kunnen laten calculeren.
   *
   * @param params =>
   * @param dataItem
   */

     prepareCalculationsProbo(priceTier: any[], nas, configuratorProductResult): ConfiguratorResult {
      const result: ConfiguratorResult = this.configuratorDatagroupService.getConfiguratorCalculateRequest();

      const nasPriceTier: any[] = [];

      priceTier.forEach(priceTierRow => {
        nas.forEach(nasRow => {
          nasPriceTier.push({
            amount: priceTierRow['amount'] * nasRow['amount'],
            width: nasRow['width'],
            height: nasRow['height']
          });
        })
      })

      nasPriceTier.forEach((row, index) => {
        let optionNas = [
          {
            code: 'width',
            value: row.width
          },
          {
            code: 'height',
            value: row.height
          },
          {
            code: 'amount',
            value: row.amount
          }
        ];

        let configuratorResultProduct: ConfiguratorResultProduct = structuredClone(configuratorProductResult);

        configuratorResultProduct.options = configuratorResultProduct.options.filter(option => option.code !== 'no-option');

        configuratorResultProduct.options.forEach(option => {
          option.code = option.code.split(".")[option.code.split(".").length-1];
          delete option.suppliers;
          delete option.info;
        });

        result.products.push(configuratorResultProduct);
        result.products[index].options = [...optionNas, ...result.products[index].options];
      });

      return result;
    }

}
