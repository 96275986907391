@if (loaded) {
  <div [ngClass]="[dataGroupItemInstance.dataClassName ?? '']">
    <app-data-generic-header
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItemInstance"
      [serverFilter]="serverFilter"
      [(currentPage)]="currentPage"
      (onSearch)="search($event)"/>

    @if (dataGroupItemInstance.dataTable[0].data.length) {
      <app-form
        [widgetId]="widgetId + '_form' "
        [dataGroupItemInstance]="dataGroupItemInstance"
        [rowIndex]="index"
        [nav]="dataGroupItemInstance.rowOptions.nav"
        [formClass]="dataGroupItemInstance.dataClassName"
        [refresher]="refresher"
        [navList]="['primary','secondary']"/>
    }

    <app-data-generic-footer
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItemInstance"
      [component]="component"
      [headerPagination]="false"
      [(currentPage)]="currentPage"
      [dataItem]="dataItem"
      (onAddRow)="addRow($event)"/>
  </div>
}
