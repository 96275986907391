import { Component, ElementRef, HostListener, QueryList, ViewChildren } from '@angular/core';
import { DataPlannerComponent } from '../data-planner.component';
import { PlannedResource, PlanningData, Resource, ResourceTimeslot, Step, StepCrud } from 'src/app/services/planning.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

/** update task sortOrders to match their positions, and set their crud if it was changed. */
const updateOrdering = (data: Step[], mode: StepCrud) => {
  return data.forEach((task, index) => {
    if (!(task.sortOrder === index))
      task.crud = mode;
    task.sortOrder = index;
  });
};

type DragItemList = [
  PlanningData & { timeslot: ResourceTimeslot[]; },
  ResourceTimeslot & { resource: Resource[]; },
  Resource & { task: Step[]; }
];


@Component({
  selector: 'app-data-planner',
  templateUrl: './data-planner-task-view.component.html',
  styleUrls: ['./data-planner-task-view.component.scss', '../data-planner.component.scss']
})
export class DataPlannerTaskViewComponent extends DataPlannerComponent {
  @ViewChildren('column', { read: ElementRef }) columns: QueryList<ElementRef>;

  public view: 'calendar' | 'teams' = 'teams';

  public dragging: boolean = false;
  public multiDrag: boolean = false;
  public dropMode: StepCrud = 'transfer';

  public toggleCalenderView(index: number) {
    if (this.view === 'calendar') {
      this.view = 'teams';

      setTimeout(() => {
        this.columns.toArray()[index].nativeElement.scrollIntoView({
          behavior: this.scrollBehavior ?? 'smooth',
          inline: 'center',
          block: 'nearest'
        });
      });
    } else {
      this.view = 'calendar';
    }
  }

  /** Deals with drag-drop dropping of items. */
  public onDrop({ container, previousContainer, item, previousIndex, currentIndex }: CdkDragDrop<DragItemList, DragItemList, Step>) {
    const { data: [pDay, pTimeSlot, pResource] } = previousContainer;
    let { data: [nDay, nTimeSlot, nResource] } = container;
    let mode: StepCrud = this.dropMode;
    let dataItem = item.data;

    // CASE: Resort original container
    if (previousContainer === container) {

      moveItemInArray(nResource.task, previousIndex, currentIndex);
      updateOrdering(nResource.task, mode);

      dataItem.crud = mode;
      // TODO this.updateMutations();
      return;
    }

    // RULE: No copying checked items
    if (mode === 'copy' && dataItem.checked) {
      mode = 'transfer';
    }

    if (mode === 'copy') {
      // CASE: Split task over multiple resources/days.
      if (pTimeSlot !== nTimeSlot && nResource !== pResource) {
        // Update old item; mark for update
        dataItem.crud = 'transfer';
        dataItem.stepTimePlanned /= 2;

        // Clone the item
        dataItem = structuredClone(dataItem);

        dataItem.plan.resourceId = nResource.resourceId;

        // Add dataitem to datagroup.data
        this.dataGroupItem.children[0].dataTable[0].data.push(dataItem);

      }
      // RULE: Can't clone task for multiple parties across the same timeslot. Merge instead.
      else {
        mode = 'merge';
      }
    }

    // CASE: Merge source and destination teams
    if (mode === 'merge') {
      // Create new resource
      const resource: PlannedResource = {
        ...nResource,
        crud: 'create', // TODO @Cees Correct CRUD for resource?
        title: nResource.title + pResource.title,
        task: [],
        isSelected: false,
        resourceId: -1,
      };

      // Push new resource to nTimeSlot
      nTimeSlot.resource.push(resource);

      // Push task to new resource
      nResource = resource;

      // Add resource to datagroup.data
      this.dataGroupItem.dataTable[0].data.push(nResource);
    }

    (nResource.task ??= []).splice(currentIndex, 0, dataItem);

    // CASE: Move from resource to resource
    if (mode === 'transfer' || mode === 'merge') {
      pResource.task.splice(previousIndex, 1);

      // Filter for duplicates
      const filtered = nResource.task.filter(task => task == dataItem);

      // Delete duplicates
      if (filtered.length > 1) {
        filtered.forEach((task, index) => {
          if (index !== 0) {
            task.crud = 'delete';
          }
        });

        nResource.task = nResource.task.filter(task => task.crud !== 'delete');
      }

      dataItem.plan.resourceId = nResource.resourceId;

      // Multi-dragging
      pResource.task.forEach(item => {

        if (item.checked) {
          nResource.task.push(item);
          item.plan.resourceId = nResource.resourceId;
        }
      });

      pResource.task = pResource.task.filter(item => !item.checked);

      nResource.task.forEach(item => {
        item.checked = false;
      });
    }

    // Update competence data
    dataItem.competenceNav = this.planningService.getItemCompetenceNav(dataItem, nResource);
    nResource.competenceNav = this.planningService.getItemCompetenceNav(nResource);
    pResource.competenceNav = this.planningService.getItemCompetenceNav(pResource);

    // Update ordering info
    updateOrdering(nResource.task, mode);
    updateOrdering(pResource.task, mode);

    // TODO this.updateMutations();
  }

  public dragStart(task: Step, resource: PlannedResource) {
    if (!task.checked && 0 < resource.task.reduce((acc, v) => acc + (v.checked ? 1 : 0), 0))
      this.multiDrag = true;

    this.dragging = true;
  }

  public dragStop(task: Step, resource: PlannedResource) {
    if (2 > resource.task.reduce((acc, v) => acc + +!!v.checked, 0))
      this.multiDrag = false;

    this.dragging = false;
  }

  /** Toggle an item's checked status and check whether multi-dragging is applicable. */
  public markItemChecked(item: Step, resource: PlannedResource) {
    super.markItemChecked(item, resource);

    if (1 < resource.task.reduce((acc, v) => acc + (v.checked ? 1 : 0), 0))
      this.multiDrag = true;

    // resource.checked = resource.task.filter(item => item.checked).map(item => item.title).join(', ');
  }

  public trackByFn(index: number, item: any): string {
    return item.trackBy;
  }


  @HostListener('window:keydown', ['$event'])
  private onKeyDown(ev: KeyboardEvent): void {
    if (ev.key === 'Control')
      this.dropMode = 'copy';
  }

  @HostListener('window:keyup', ['$event'])
  private onKeyUp(ev: KeyboardEvent): void {
    if (ev.key === 'Control')
      this.dropMode = 'transfer';
  }
}
