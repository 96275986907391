import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Param, WidgetEvent, WidgetState } from 'src/app/widgets';
import { DiagnosticForceVector, DiagnosticVector, DiagnosticsProvider } from '../inhaker-simulation/diagnostics';
import { FootScrewCalculation } from '../inhaker-simulation/inhaker-foot-screw-bit-integrity-calculation';
import { Bench, Inhaker, WeightObject, WorldObject } from '../inhaker-simulation/world';
import { SvgDrawComponent } from '../svg-draw/svg-draw.component';
import { SvgToolBaseComponent } from './svg-tool-base.component';
import { debounceTime } from 'rxjs';
import { SvgToolObjectComponent } from '../svg-tool-object/svg-tool-object.component';


export type ViewType = 'weight' | 'real';
export type PointOfView = keyof typeof povAxisAlignments;

/** Clone anything recursively. */
function deepClone<T>(obj: T): T {
  // Return primitives
  if (typeof obj !== 'object' || obj === null)
    return obj;

  // Save the prototype (class definition) of the object
  const proto = Object.getPrototypeOf(obj);
  // Create a new object or array to hold the cloned properties
  const clonedObj = Array.isArray(obj) ? [] : {};

  // Recursively clone each property on the object
  for (const key in obj) {
    // Use Object.prototype.hasOwnProperty to check if the property belongs to the object itself (not inherited from the prototype chain)
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // @ts-ignore Clone the property and assign it to the cloned object
      clonedObj[key] = deepClone(obj[key]);
    }
  }

  // Return the cloned object with the prototype restored
  return Object.setPrototypeOf(clonedObj, proto) as T;
}

const indexSort = <T>(arr: T[], comparator: Parameters<typeof Array.prototype.sort>[0]): number[] => {
  // Clone of array
  const valueToOldIdxMap = new Map(arr.map((v, i) => [v, i]));

  // Sort the array by value
  arr.sort(comparator);

  // Create a map to track the changes in indexes
  const indexMap: number[] = [];

  // Populate the indexMap with the changes
  for (let i = 0; i < arr.length; i++) {
    const oldIndex = valueToOldIdxMap.get(arr[i]) as number;
    indexMap[oldIndex] = i;
  }

  return indexMap;
};

/** Update all object references */
const updateReferences = (arr: WorldObject[], updater: (v: number | undefined) => (number | undefined)) => {

  for (let i = 0; i < arr.length; i++) {
    const object = arr[i];

    if (object instanceof WeightObject) {
      object.inhakers = object.inhakers.map(updater) as typeof object.inhakers;
    } else if (object instanceof Inhaker) {
      object.leftBench = updater(object.leftBench);
      object.rightBench = updater(object.rightBench);
    } else if (object instanceof Bench) {
      object.inhaker = updater(object.inhaker);
    }
  }

};

const typeValues = (w: WorldObject) => {
  if (w instanceof WeightObject)
    return 3;

  return 0;
};

/** Resorts all items along the Z axis. */
const sortWithReferenceUpdates = (arr: WorldObject[]): WorldObject[] => {
  const oldToNewIndices = indexSort(arr, (a, b) => {
    const res = b.z - a.z;

    if (res === 0)
      return typeValues(a) - typeValues(b);

    return res;
  });

  updateReferences(arr, v => oldToNewIndices[v]);

  return arr;
};

/** Optimizes a record's values to be compacter when stringified. */
const jsonOptimizeRecursive = (v: Record<string, any>) => {
  switch (typeof v) {
    case 'object':

      for (const [key, value] of Object.entries(v)) {
        v[key] = jsonOptimizeRecursive(value);
      }

      return v;

    case 'number':
      return Math.round(v * 1000) / 1000;
    default:
      return v;
  }

};


const types = [WeightObject, Inhaker, Bench];
const defaults = types.map(ty => new ty({}));

/** Save delay after no actions, in milliseconds. */
const saveDelayNoMod = 30000;

export const povAxisAlignments = {
  'front': ['z', 1],
  'left': ['x', -1],
  'right': ['x', 1],
  'back': ['z', -1]
} as const;

@Component({
  selector: 'svg-inhaker-design-tool',
  templateUrl: './svg-inhaker-design-tool.component.html',
  styleUrls: ['./svg-inhaker-design-tool.component.scss', '../svg-draw/svg-draw.component.scss'],
})
export class SvgInhakerDesignToolComponent extends SvgDrawComponent implements OnInit {
  @Input() widgetId: string = '';
  @Input() dataItem: any[] = [];

  @Output() onUpdate$ = new EventEmitter<void>();

  // Display object types
  public Inhaker = Inhaker;
  public WeightObject = WeightObject;
  public Bench = Bench;

  public SvgToolObjectComponent = SvgToolObjectComponent;

  public Vector = DiagnosticVector;
  public ForceVector = DiagnosticForceVector;

  // Displayed state
  public component = this;
  public forceScale = 0.01;

  public view: ViewType = 'real';
  public pov: PointOfView = 'front';
  public povAxis: 'x' | 'z' = 'z';
  public povAlignment: -1 | 1 = 1;

  /** The step size of resizing the width of an object. */
  public widthResizeStep = 0.001;
  /** The step size of resizing the height of an object. */
  public heightResizeStep = 0.001;
  public isSnapBlocked: boolean = false;
  public isAltDown: boolean = false;

  public selected: WorldObject | undefined;
  public get selectedMapped() {
    return this.dragObjectMap.get(this.selected);
  }

  public objects: WorldObject[] = [];

  public diagnosticsProviders: DiagnosticsProvider[] = [new FootScrewCalculation];

  public get diagnostics() { return this.diagnosticsProviders[0]; }
  public get warnings() {
    return this.diagnostics.warn.map(v => { //FIXME temp error display code
      if (v.match(/topple/))
        return "Inhaker valt om!";

      if (v.match(/break/))
        return "Inhaker voet schroef gaat kapot!";
    });
  }

  public totalPathLength: number = 0;
  public totalCutDuration: number = 0;
  public totalCutArea: number = 0;

  private dragObjectMap = new Map<WorldObject, SvgToolBaseComponent>();

  /** Find object by type. */
  public find<T extends WorldObject>(type: { new(...args: any[]): T; }): T[] {
    return this.objects.filter(obj => obj instanceof type) as T[];
  }

  /** Transitions from current to desired point of view, applying any necessary data transformations. */
  public transition(pov: PointOfView) {
    if (pov === this.pov) return;

    const [axis, alignment] = povAxisAlignments[pov];
    const [oldAxis, oldAlignment] = povAxisAlignments[this.pov];

    this.objects.forEach(v => {
      v.z /= oldAlignment;
    });

    // Swap axis
    if (axis !== oldAxis) {
      this.objects.forEach(object => {

        const x = object.x;
        object.x = object.z;
        object.z = x;

        const wo = object as WeightObject;
        const w = wo.width;
        wo.width = wo.depth;
        wo.depth = w;
      });

    }

    this.objects.forEach(v => {
      v.z *= alignment;
    });

    sortWithReferenceUpdates(this.objects);

    this.pov = pov;
    this.povAxis = axis;
    this.povAlignment = alignment;

    setTimeout(() => this.ngAfterViewInit(), 0.1);
  }

  /** Remove a bench from the sim, properly removing it from the inhaker it's attached to as well. */
  private removeBench(bench: Bench): void {
    const inhaker = this.objects[bench.inhaker] as Inhaker;
    if (bench.x < inhaker.x) delete inhaker.leftBench;
    else delete inhaker.rightBench;
  }

  /** Remove an object from the simulation and send out an update. */
  public remove(obj: WorldObject) {
    const idx = this.objects.findIndex(object => obj === object);

    this.objects.splice(idx, 1);

    if (obj instanceof Bench) this.removeBench(obj);

    updateReferences(this.objects, (v) => {

      if (v === idx) return undefined;
      else if (v > idx) return v - 1;

      return v;
    });

    this.diagnostics.clear();
    this.onUpdate();
  }

  // ==== Drag Events ====

  /** Register object component for drag events */
  public register(component: SvgToolBaseComponent, obj: WorldObject) {
    this.dragObjectMap.set(obj, component);
  }

  /** Unregister object component for drag events */
  public unregister(arg0: SvgToolBaseComponent, obj: WorldObject) {
    this.dragObjectMap.delete(obj);
  }

  /** Gets called every time the user moves their mouse while holding an object; when dragging an object.
   * Returns true if SVGDraw shouldn't take care of moving the object */
  protected onDrag(element: WeightObject, component: string, x: number, y: number): boolean | void {
    return this.dragObjectMap.get(element)?.onDrag(element, component, x, y);
  }

  private getNewUniqueIdentifier() {
    let newIdent: string = String.fromCharCode(Math.floor(Math.random() * 255));

    while (this.objects.find(v => v.ident === newIdent))
      newIdent = String.fromCharCode(Math.floor(Math.random() * 255));

    return newIdent;
  }

  /** Gets called every time the user starts dragging an object (pointerdown).
   * Returns offset for component from object centre */
  protected onDragStart(element: WeightObject, component: string, x: number, y: number): [number, number] | void {
    this.selected = element;


    if (this.isAltDown && !component) {
      element = deepClone(element);
      element.ident = this.getNewUniqueIdentifier();
      this.objects.push(element);
    }

    return this.dragObjectMap.get(element)?.onDragStart(element, component, x, y);
  }

  /** Gets called every time the user starts dragging an object (pointerup).
   * Returns offset for component from object centre */
  protected override onDragEnd(element: WeightObject, component: string | undefined, x: number, y: number): void | [number, number] {
    const v = this.dragObjectMap.get(element)?.onDragEnd(element, component, x, y);

    [this.totalPathLength, this.totalCutDuration, this.totalCutArea] = this.objects
      .filter(v => v instanceof WeightObject)
      .map(v => {
        const comp = (this.dragObjectMap.get(v) as SvgToolObjectComponent);
        return [comp.pathLength, comp.pathCutDuration, comp.pathBoundArea];
      })
      .reduce(([aa, ab, ac], [ba, bb, bc]) => [aa + ba, ab + bb, ac + bc], [0, 0, 0]);

    return v;
  }

  public recalc() {
    [this.totalPathLength, this.totalCutDuration, this.totalCutArea] = this.objects
      .filter(v => v instanceof WeightObject)
      .map(v => {
        const comp = (this.dragObjectMap.get(v) as SvgToolObjectComponent);
        return [comp.pathLength, comp.pathCutDuration, comp.pathBoundArea];
      })
      .reduce(([aa, ab, ac], [ba, bb, bc]) => [aa + ba, ab + bb, ac + bc], [0, 0, 0]);
  }

  /** Gets called every time any data changes in the SVG-renderer */
  protected onUpdate() {
    this.updateDiagnostics();

    this.onUpdate$.emit();
  }

  /** Overwrites all current data with data from inputs. */
  public initData() {
    const immutableItems = this.dataItem;
    const mutableItems = this.dataGroupItem.dataTable[0].data.map(row => row.nasItemJSON.designTool).filter(v => v);

    // Find current selected item
    const selected = mutableItems.find(v => v.selected);

    // Map raw data into their respective objects
    this.objects = [
      ...immutableItems.map(v => this.recordOrJSONToObject(v)),
      ...mutableItems.map(v => this.recordOrJSONToObject(v))
    ];



    // Resort everything
    sortWithReferenceUpdates(this.objects);

    // Select the outside selected item
    this.objects.find(obj => obj.ident === selected?.ident);

    // Send out an update for stability checks etc
    this.onUpdate();
  }


  protected updateDiagnostics() {
    this.diagnosticsProviders.forEach(provider => provider.diagnose(this));
  }

  /** Callback for WidgetEvent.CREATE */
  public createObject(params: Param[]) {
    // Find creation data in params
    const val = params.find(v => v.key === 'newObject').val;

    // Parse object
    const newObject = this.recordOrJSONToObject(val);

    // Push object
    this.objects.push(newObject);

    // Update tool
    this.onUpdate();

  }

  /** Callback for WidgetEvent.SAVED */
  public onExternalDataUpdated() {
    this.initData();
  }

  /** Callback for WidgetEvent.SELECTED */
  public onExternalDataSelected(params: Param[]) {
    const val = params.find(v => v.key === 'newObject').val;

    this.selected = this.objects.find(obj => obj.ident === val.ident);
  }

  public save(): void {
    // Reset rotation to front, so data always comes from the same angle
    const oldPov = this.pov;
    this.transition('front');

    // Find save data.
    const saveData = this.objects
      .filter(v => v.save)
      .map(v => ({
        svg: `<svg>${this.dragObjectMap.get(v).export(v)}</svg>`,
        ...this.objectToRecord(v)
      }) as Record<string, any>);

    // Group save data
    const dataTable = this.dataGroupItem.dataTable[0].data;
    const mergeData = saveData.filter(v => dataTable.find(iv => v.ident === iv.nasItemJSON?.designTool?.ident));
    const newData = saveData.filter(v => !mergeData.includes(v));

    // Set save data
    mergeData.forEach(data => {
      const v = dataTable.find(v => data.ident === v.nasItemJSON?.designTool?.ident);
      console.log(dataTable, data, v);
      v.nasItemJSON.designTool = data;
      v.crud = 'drawUpdate';

      // Save SVG path
      (v.fileJSON ??= [])[0] = {
        svg: data.svg
      };

      // Remove duplicate svg
      delete data.svg;
    });

    newData.forEach(v => this.addRow({
      params: [],
      dataItem: ({ fileJSON: [{ svg: v.svg }], nasItemJSON: { designTool: { ...v, svg: undefined } } })
    }));

    // Push save
    this.updateMutations();

    // Return to previous pov
    this.transition(oldPov);

  }


  // ==== Events ====

  @HostListener('window:keydown', ['$event'])
  private _deleteSelected(event: KeyboardEvent) {
    if (!this.selected) return;

    if(
      (event.code === 'Delete' && !navigator.userAgent.includes('Mac')) ||
      (event.code === 'Backspace' && navigator.userAgent.includes('Mac')))
    this.remove(this.selected);
  }

  @HostListener('document:pointerdown', ['$event'])
  private _deselect(event: MouseEvent) {
    if (event.target === this.svg!.nativeElement
      === this.svg!.nativeElement.contains(event.target as SVGElement))
      this.selected = undefined;
  }

  @HostListener('window:keydown.control.c', ['$event'])
  private _copySelected(event: KeyboardEvent) {
    if (!this.selected) return;

    event.preventDefault();

    navigator.clipboard.writeText(`<svg>${this.dragObjectMap.get(this.selected).export(this.selected)}</svg>`);
  }

  public copyAll() {
    const paths = this.objects
      .map(item => `<g transform="translate(${item.x * 2834.645669}, ${item.y * -2834.645669}) ">${this.dragObjectMap.get(item).export(item)}</g>`);


    navigator.clipboard.writeText(`<svg>${paths.reduce((a, b) => a + b, '')}</svg>`);
  }

  @HostListener('window:keydown.shift')
  private _disableSnap() { this.isSnapBlocked = true; }

  @HostListener('window:keyup.shift')
  private _enableSnap() { this.isSnapBlocked = false; }

  @HostListener('window:keydown.alt', ['$event'])
  private _isAltDown(event: KeyboardEvent): void {
    this.isAltDown = true;
  }

  @HostListener('window:keyup.alt', ['$event'])
  private _isAltUp(event: KeyboardEvent): void {
    this.isAltDown = false;
  }

  public ngOnInit(): void {
    this.onUpdate$.pipe(
      debounceTime(saveDelayNoMod)
    ).subscribe(() => {
      this.save();
    });

    this.initData();

    this.communicationService.initWidget({
      widgetId: this.widgetId + '_ihdt',
      component: this.component,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: ['card_group'], // TODO
          event: WidgetEvent.CREATE,
          func: 'createObject',
          params: [
            { key: 'newItem' }, // Inhaker, bench, object
          ]
        },
        {
          widgetGroup: ['configurator_group'], // TODO
          event: WidgetEvent.SAVED,
          func: 'onExternalDataUpdated'
        },
        {
          widgetGroup: ['configurator_group'], // TODO
          event: WidgetEvent.SELECTED,
          func: 'onExternalDataSelected',
          params: [
            { key: 'item' }, // Inhaker, bench, object
          ]
        }
      ]
    });

    super.ngOnInit();
  }


  // ===== JSON To/From =====

  public objectToRecord(obj: WorldObject): Record<string, any> {
    const res = {} as Record<string, any>;
    const typeId = types.findIndex(ty => ty === Object.getPrototypeOf(obj).constructor);
    const typeDefault = defaults[typeId];

    for (const key of Object.keys(obj))
      if (typeDefault[key] !== obj[key])
        res[key] = obj[key];


    res.type = typeId;

    return res;
  }

  public recordOrJSONToObject(val: Record<string, any> | string): WorldObject {
    if (typeof val === 'string') val = JSON.parse(val) as Record<string, any>;

    switch (val.type) {
      case 'inhaker':
        return new Inhaker(val);
      case 'object':
        val.activeAxis ??= this.povAxis;
        val.z ??= this.objects.reduce((acc, val) => acc > val.z ? val.z : acc, Number.POSITIVE_INFINITY);
        if (val.z == Number.POSITIVE_INFINITY) val.z = 0;
        return new WeightObject(val);
      case 'bench':
        return new Bench(val);
      default:
        console.error('Unknown object used in creating object for inhaker design tool: ', val);
    }

  }



  addObject(t: 'weight' | 'pole' = 'weight', side: 'ar' | 'a' | 'c' | undefined = undefined) {
    const circleLeft = 'c-.2761 0-.5.2239-.5.5s.2239.5.5.5',
      circleRight = 'c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5',
      arrowRoundedLeft = 'c-.0452 0-.0887.018-.1206.05l-.3294.3294c-.0666.0666-.0666.1747 0 .2413l.3294.3294c.032.032.0754.05.1206.05',
      arrowRoundedRight = 'c.0452 0 .0887-.018.1206-.05l.3294-.3294c.0666-.0666.0666-.1747 0-.2413l-.3294-.3294c-.032-.032-.0754-.05-.1206-.05',
      arrowLeft = '-.5.5.5.5',
      arrowRight = ' .5-.5-.5-.5';

    let leftSide: string, rightSide: string;

    if (side === 'a') {
      leftSide = arrowLeft;
      rightSide = arrowRight;
    } else if (side === 'ar') {
      leftSide = arrowRoundedLeft;
      rightSide = arrowRoundedRight;
    } else if (side === 'c') {
      leftSide = circleLeft;
      rightSide = circleRight;
    }

    if (t === 'weight')
      this.objects.push(new WeightObject({
        ident: this.getNewUniqueIdentifier(),
        y: 1,
        x: 1,
        width: 0.3,
        height: 0.3,
        internalMass: 8,
        drag: 2,
        leftSidePath: leftSide,
        rightSidePath: rightSide
      }));


    if (t === 'pole') {
      updateReferences(this.objects, v => v + 1);

      this.objects.unshift(new Inhaker({
        ident: this.getNewUniqueIdentifier(),
        x: 0, y: 0.7, footHeight: 0.006, footWidth: 0.4, poleHeight: 1.95
      }));
    }


    sortWithReferenceUpdates(this.objects);

    this.onUpdate();
  }

}
