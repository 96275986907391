import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-link-preview',
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.css']
})
export class LinkPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  public params = {
    navItemId: 0
  }

  public interval: any;

  constructor(public dataService: DataService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  //   this.interval = setInterval(() => {
  //     let navItemId = this.params.navItemId === this.contentJSON.cols[0].col[0].nav.menu.length - 1 ? 0 : this.params.navItemId + 1;

  //     this.onSelect(this.contentJSON.cols[0].col[0].nav.menu.find(item => item.navItemId === navItemId));

  //  }, 3000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  onSelect(event) {
    this.contentJSON.cols[0].col[0].nav.menu.find(item => item.navItemId === this.params.navItemId).isSelected = false;
    this.params.navItemId = event.navItemId;
    event.isSelected = true;
  }

  trackByFn(index, item) {
    return index;
  }
}
