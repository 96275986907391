@if (loaded) {
  <div class="xfw3-breadcrumbs-wrapper">
    <form [formGroup]="form" class="xfw3-breadcrumbs">
      @for (fieldGroup of dataGroupItemInstance.dataTable[0].fields[0].fieldGroup | exclude: 'type' : ['hidden','hide']; track $index) {
        @if (dataGroupItemInstance.dataTable[0].data[0][fieldGroup.key]) {
          @if (dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key]?.text) {
            <div [ngClass]="[fieldGroup.className ?? '']">
              <label class="xfw3-label" [innerHTML]="(fieldGroup.templateOptions.label.text ?? fieldGroup.templateOptions.label ?? '&nbsp;') | safeHtml"></label>
              @if (['hyperlink'].includes(fieldGroup.type)) {
                <div class="xfw3-form-control">
                  <a [href]="dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key].url">{{ dataGroupItemInstance.dataTable[0].data[rowIndex][fieldGroup.key].text }}</a>
                </div>
              }@else if (['dropdownList', 'bitmapSelect', 'multiSelect', 'comboBox'].includes(fieldGroup.type)) {
                <div class="xfw3-form-control xfw3-form-control-component">
                  <app-multi-select #formControl
                    [id]="fieldGroup.key + '-' + rowIndex"
                    [tabIndex]="fieldGroup.templateOptions.readOnly ? -1 : $index"
                    [formControlName]="fieldGroup.key"
                    [className]="formClass"
                    [type]="fieldGroup.type"
                    [data]="lookupTables[fieldGroup.xfw.inputData.src].data"
                    [textField]="fieldGroup.xfw.inputData.textField"
                    [valueField]="fieldGroup.xfw.inputData.valueField"
                    [isJSON]="fieldGroup.xfw.isJSON"
                    [readOnly]="fieldGroup.templateOptions.readOnly"
                    (onKeyup)="checkKeyUp($event)"
                    (onFilterChange)="filterChange(fieldGroup.xfw.inputData.src, $event)"
                    (onFocus)="cellFocus(fieldGroup.key)"
                    (onBlur)="checkInput(fieldGroup.key, fieldGroup.type)"
                    (close)="onClose($event)"/>
                </div>
              }

              @if (fieldGroup.templateOptions.clear?.length && !(fieldGroup.templateOptions.clear | hasOverlap: clearsExcluded)) {
                <div class="xfw3-form-control-clear">
                  <app-cmd
                    [component]="component"
                    [dataItem]="fieldGroup.templateOptions.clear"
                    [nav]="nav"
                    [navList]="['primary']"
                    [navVisible]="0"/>
                </div>
              }
            </div>
          }
        }
      }
    </form>
  </div>
}

