@if (loaded) {
  <div class="container-fluid xfw3-grid-container" divBreakpointClass>
    @if (dataGroupItem.dataTable[0].data?.length) {
      @for (item of dataGroupItem.dataTable[0].data; track $index) {
        <div class="row xfw3-grid-col-container">
          <div class="g-offset-1 g-col-10 position-relative search-result" (click)="showItem(item)">
            <span style="position: absolute; right: 2rem; top: 1rem; font-size: 1.5rem; font-weight: 900;">
              <span [hidden]="!item.hidden" style="color: white;" class="dripicons dripicons-chevron-right"></span>
              <span [hidden]="item.hidden" style="color: black;" class="dripicons dripicons-chevron-down"></span>
            </span>
            <h5>
              {{ item.path }}
            </h5>
          </div>
          <div [ngClass]="[item.blocksId ? 'g-col-12 search-result-content-container' : 'g-offset-1 g-col-10']" [hidden]="item.hidden">
            <div [ngClass]="[item.blocksId ? 'search-result-content' : '']">
              <app-content-block
                [blocks]="[{
                  blockId: item.blockId,
                  blocksId: item.blocksId,
                  srt: item.sortOrder,
                  contentJSON: item.contentJSON.length ? item.contentJSON[0] : item.contentJSON
                }]"
                [environmentJSON]="{ viewportMode: false }"/>
            </div>

            <div class="search-result-button">
              <app-cmd
                [nav]="{
                  primary: {
                    icon: '',
                    text: 'Ga naar de pagina',
                    info: 'Ga naar de pagina',
                    path: item.path,
                    className: 'text'
                  }
                }"
                className="cmd-default text-dark"
                [navList]="['primary']"
                [navVisible]="1"
                [collapsed]="false"/>
            </div>
          </div>
        </div>
      }
    } @else {
      @for(item of dataGroupItem.contentJSON.cols[0].col[0].noResults; track $index) {
        <p>{{ item.text }}</p>
      }
    }
  </div>
}
