<div *ngIf="readOnly" class="multi-select-container" [ngClass]="[className || '']">
  <div class="multi-select-wrapper">
    <div class="multi-select-input">
      <input #input
        [disabled]="readOnly"
        [readOnly]="readOnly"
        [value]="searchTxt">
    </div>
  </div>
</div>

<div *ngIf="!readOnly" class="multi-select-container" [ngClass]="[className || '']"
     ngbDropdown
     [autoClose]="false"
     [placement]="['bottom-start', 'auto']"
     container="body"
     #drop="ngbDropdown">
  <div class="multi-select-wrapper">
    <div *ngIf="['multiSelect', 'bitmapSelect'].includes(this.type)" [ngClass]="['selected-items']">
      @for (item of dataChecked; track $index) {
        <div class="selected-item">
          <div class="item">
            {{ textField === 'content' ? item[textField].text : item[textField] }}
          </div>
          <div class="item-delete" (click)="deSelect(item)">
          </div>
        </div>
      }
    </div>
    <div ngbDropdownToggle (clickOutside)="handleClickOutside($event)" class="multi-select-input" (click)="typing = false; searchDropDown = !searchDropDown;">
      <input #input
        autocomplete="none"
        [disabled]="readOnly"
        (keyup)="keyUpEvent(input.value, $event.key); $event.stopPropagation();"
        (keydown)="keyDownEvent(input.value, $event.key); $event.stopPropagation();"
        [value]="searchTxt"
        (focus)="cellFocus(formControlName)">
      <div [ngClass]="['toggle-dropdown', searchDropDown ? 'expanded' : 'collapsed']">
        <span class="dropdown-collapse"></span>
        <span class="dropdown-expand"></span>
      </div>
    </div>
  </div>
  <div ngbDropdownMenu class="dropdown">
    <div *ngFor="let item of dataView; let index = index;"
      [ngClass]="['item', selectedIndex === index ? 'ngbSelected': '']"
      (click)="select(item);$event.stopPropagation();"
      ngbDropdownItem>
      <span>{{ textField === 'content' ? item[textField].text : item[textField] }}</span>
      @if (item.info) {
        <span>&nbsp; {{ item.info }}</span>
      }
    </div>
  </div>
</div>

