<div *ngIf="lazyLoad" [ngClass]="[dataGroupItemInstance.dataClassName ?? '']">
  <ng-container *ngFor="let field of dataGroupItemInstance.dataTable[0].cache;">
    <ng-container *ngIf="!field.child">
      <ng-container *ngTemplateOutlet="contentTemplate; context: {field: field, item: item }"></ng-container>
    </ng-container>
    <ng-container *ngIf="field.child">
      <h6 *ngIf="field.title">{{ field.title }}</h6>
      <div [ngClass]="['xfw3-grid-col-container', field.childClass ?? '', 'data-item']">
        <ng-container *ngFor="let child of field.child;" >
          <ng-container *ngTemplateOutlet="contentTemplate; context: {field: child, item: item }"></ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #contentTemplate let-field="field" let-item="item">
  <div [ngClass]="field.className ? 'g-' + field.className : ''">
    <div *ngIf="(dataGroupItemInstance?.showEmptyFields || item[field.key] || item[field.key] === 0) && !['upload'].includes(field.type)"
        [ngClass]="[
        'data-cell',
        field.templateOptions.label.className ?? '',
        dataCondResult[field.key] ?? '']">

    <label *ngIf="field.templateOptions.label.text"
      [ngClass]="['data-cell-label']"
      [innerHTML]="field.templateOptions.label.text ?? '&nbsp;' | safeHtml">
    </label>
    <span *ngIf="field.templateOptions.label.info" class="label-info" [innerHTML]="(field.templateOptions.label.info ?? '') | safeHtml"></span>

    <ng-container *ngIf="field.xfw.nav">
      <app-cmd
        [component]="component"
        [dataItem]="item"
        [nav]="field.xfw.nav"
        [navList]="['hyperlink']"
        [text]="item[field.key]"
        [className]="'data-cell-value'"
        [navVisible]="1" >
      </app-cmd>
    </ng-container>

    <ng-container *ngIf="!field.xfw.nav">
      <div *ngIf="field.templateOptions?.format?.pipeToken"
        [ngClass]="['data-cell-value', field.templateOptions.className ?? '']"
        [attr.data-focus]="field.key">
        <span [innerHTML]="item[field.key] | dynamicPipe : field.templateOptions.format.pipeToken : field.templateOptions.format.pipeArgs | safeHtml"></span>
        <div *ngIf="field.templateOptions.postFix" [className]="field.templateOptions.postFix.className ?? ''">
          <span [innerHTML]="field.templateOptions.postFix.text | safeHtml"></span>
        </div>
      </div>
      <ng-container *ngIf="!field.templateOptions?.format?.pipeToken">
        <div *ngIf="!['upload', 'optionListRadio', 'optionListCheckbox', 'dropdownList', 'bitmapSelect', 'multiSelect', 'comboBox'].includes(field.type)"
          [ngClass]="['data-cell-value', field.templateOptions.className ?? '', field.type === 'multiline' ? 'multiline' : '']"
          [attr.data-focus]="field.key"
          [innerHTML]="item[field.key] ?? '&nbsp;' | safeHtml">
        </div>

        <ng-container *ngIf="['bitmapSelect','multiSelect','optionListRadio','optionListCheckbox'].includes(field.type) && item[field.key]">
          <div [ngClass]="['data-cell-value', field.templateOptions.className ?? '']">
            <ng-container *ngFor="let element of item[field.key].data ? item[field.key].data : item[field.key]; let last = last;">
              <span>{{ field.xfw.inputData.textField === 'content' ? element[field.xfw.inputData.textField].text : element[field.xfw.inputData.textField] }}</span>
              <span *ngIf="!last">, </span>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="['dropdownList','comboBox','upload'].includes(field.type) && item[field.key]">
          <div [ngClass]="['data-cell-value', field.templateOptions.className ?? '']" [innerHTML]="field.xfw.inputData.textField === 'content' ? item[field.key][field.xfw.inputData.textField].text : item[field.key][field.xfw.inputData.textField] ?? '&nbsp;' | safeHtml"></div>
        </ng-container>

        <div *ngIf="field.templateOptions.postFix" [className]="field.templateOptions.postFix.className ?? ''">
          <span [innerHTML]="field.templateOptions.postFix.text | safeHtml"></span>
        </div>
      </ng-container>
    </ng-container>
    </div>
  </div>

</ng-template>
