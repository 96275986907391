<div class="task-card h-100" [ngClass]="[
    task.checked ? 'checked' : '',

    task.isSelected ? 'selected' : '', task.state ? task.state : 'normal-job',
    orientation === 'row' ? 'flex-row' : 'flex-col'
  ]"> <!--task.batchId === searchResult[searchIndex]?.batchId ? 'maxtrix-search-selected' : '',-->
  <!-- Pre-time -->
  <div *ngIf="task.timeToStart > 0" class="task-card-time-pre-post"
    [style.--width.px]="task.timeToStart * hourToWidthMultiplier">
    <ng-container *ngIf="task.timeToStart* hourToWidthMultiplier > 41">
      {{ task.timeToStart | minutesToTime }}
    </ng-container>
  </div>

  <div class="task-card-main flex-row"
    [style.--width.px]="(task.stepTimePlanned || task.totalStepTimePlanned) * hourToWidthMultiplier - 6"
    [ngClass]="(task.stepTimePlanned || task.totalStepTimePlanned) * hourToWidthMultiplier <= 50 ? 'small' : ''">

    <ng-container [ngTemplateOutlet]="taskCardBody" [ngTemplateOutletContext]="{ visibility }" />

  </div>

  <!-- Post-time -->
  <div *ngIf="task.timeToEnd > 0" class="task-card-time-pre-post"
    [style.--width.px]="task.timeToEnd * hourToWidthMultiplier">
    <ng-container *ngIf="task.timeToEnd * hourToWidthMultiplier > 41">
      {{ task.timeToEnd | minutesToTime }}
    </ng-container>
  </div>
</div>

<ng-template #taskCardBody let-visibility="visibility">
  <!-- Main -->
  <div class="flex-col flex-grow"
    [hidden]="((task.stepTimePlanned || task.totalStepTimePlanned) * hourToWidthMultiplier) < 50">
    <!-- Item Header -->
    <div class="flex-row align-items-center">
      <label *ngIf="visibility.checkbox" class="task-card-select-box  mx-1" (click)="$event.stopPropagation()">
        <input [checked]="task.checked" type="checkbox" (change)="markChecked()" />
      </label>

      <span class="task-card-title flex-grow">
        {{ task.title?.jobOptionComponentNo + ' - ' + task.title?.relationCode }}
      </span>

      <!-- <span [hidden]="view === 'bars'" class="ml-auto task-card-type task-card-type-category">{{ 'logistiek' }}</span> -->
    </div>
    <span class="task-card-subtitle">{{ task.location?.companyName }}</span>

    <app-gmap *ngIf="visibility.map && task.location" [markers]="[task.location]" [fit]="'markers'" class="map" />

    <!-- Body -->
    <div *ngIf="visibility.body" class="task-card-body flex-row flex-grow mb-1">

      <div class="mb-1 flex-grow">
        <span class="task-card-label">Levering: </span><br>
        <span>{{ task.delivery?.type || '-' }}</span>
      </div>

      <div class="mb-1 flex-grow">
        <span class="task-card-label">Plaats: </span><br>
        <span>{{ task.location?.place || '-' }}</span>
      </div>

      <div class="mb-1 flex-grow">
        <span class="task-card-label">Tijd: </span><br>
        <span>{{ task.totalStepTimePlanned | minutesToTime }}</span>
        <span *ngIf="task.stepTimePlanned && task.totalStepTimePlanned !== task.stepTimePlanned">
          / deel: {{ task.stepTimePlanned | minutesToTime }}
        </span>
      </div>

    </div>

    <!-- Footer -->
    <div *ngIf="visibility.footer" class="flex-row align-items-center">
      <span class="flex-grow">

        <app-cmd class="task-card-cmd" [dataItem]="task" [component]="this" [nav]="task.competenceNav"
          [navList]="['menu']" [navVisible]="5" />
      </span>
      <div class="task-card-type">
        {{ task.title?.title || "PRODUCTION" }}
      </div>
    </div>

  </div>

  <!-- Aside -->
  <div>
    <app-cmd *ngIf="visibility.menuButton" [placement]="'bottom-start'" class="menu-button" [dataItem]="task"
      [component]="this" [nav]="navUnplanned" [navList]="['menu']" [navVisible]="0" />
    <span *ngIf="visibility.popover" class="task-info-icon" [ngbPopover]="popover" container="body"
      triggers="mouseenter:mouseleave" popoverClass="task-card-popover"><i class="fa-solid fa-info"></i></span>
  </div>

  <ng-template #popover>
    <div class="flex-row" style="width:234px;">
      <ng-container [ngTemplateOutlet]="taskCardBody"
        [ngTemplateOutletContext]="{ visibility: withDefaultVisibility({ map: visibility.popoverMap, popover: false }) }" />
    </div>
  </ng-template>

</ng-template>