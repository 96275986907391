<div *ngIf="loaded">
  <app-data-generic-header
    [widgetId]="widgetId"
    [dataGroupItemInstance]="dataGroupItemInstance"
    [serverFilter]="serverFilter"
    [headerPagination]="false"
    [(currentPage)]="currentPage"
    (onSearch)="search($event)"
    (onSearchNext)="searchNext($event)"
    (onSearchPrevious)="searchPrevious($event)"
    (onForceReload)="forceReload()"
    (onUpdateMutations)="updateMutations()">
  </app-data-generic-header>

  <div class="xfw3-grid-body data-matrix">
    <div class="tabs">
      <div class="tab-title">
        <h5>{{ dataGroupItemInstance.matrix.tabTitle.text }}</h5>
      </div>
      <div [ngClass]="['tab-columns']" horizontalScroll >
        <ng-container *ngFor="let tab of matrix; trackBy: trackByFn">
          <app-data-tab
            #dataTab
            (select)="selectTab($event)"
            (setEditMode)="setEditMode($event)"
            [item]="tab"
            [dataGroupItem]="dataGroupItemInstance"
            [dataTabSelected]="dataTabSelected">
          </app-data-tab>
        </ng-container>
      </div>
    </div>
    <div *ngIf="matrix[dataTabSelected]?.cols" class="data-matrix">
      <div class="data-rows">
        <div class="data-row">
          <div class="column header">{{ dataGroupItemInstance.matrix.rowTitle.text }}</div>
          <ng-container *ngIf="!dataGroupItemInstance.matrix.headerInvisible">
            <div class="column header" *ngFor="let colHeader of matrix[dataTabSelected].cols; let first = first; trackBy: trackByFn" >
              <app-data-item-content
                [dataGroupItemInstance]="dataGroupItemInstance"
                [item]="colHeader">
              </app-data-item-content>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="data-rows">
        <div class="data-row edit-inline-delete-row" *ngFor="let row of matrix[dataTabSelected].rows; let rowIndex = index; trackBy: trackByFn">
          <div class="column first">
            <app-data-item-content
              [dataGroupItemInstance]="dataGroupItemInstance"
              [item]="row">
            </app-data-item-content>
          </div>
          <div *ngFor="let col of matrix[dataTabSelected].cols; let colIndex = index; trackBy: trackByFn"
               (click)="selectItem(row, col.dataRows[rowIndex])"
               [ngClass]="['column', 'hover', col.dataRows[rowIndex].selected ? 'selected' : '']">
            <app-data-item-content
              [dataGroupItemInstance]="dataGroupItemInstance"
              [item]="col.dataRows[rowIndex]">
            </app-data-item-content>
          </div>
          <div class="edit-inline-delete-button">
            <app-cmd [component]="component" [attr.data-delete]="'delete'" [dataItem]="row" [nav]="nav" [navList]="['cancel']" [navVisible]="1" ></app-cmd>
          </div>
        </div>
      </div>
    </div>

    <div class="data-form">
      <app-data-group-item
        *ngIf="dataGroupItemInstance.children.length"
        [widgetId]="dataGroupItemInstance.children[0].widgetId"
        [dataGroupItem]="dataGroupItemInstance.children[0]">
      </app-data-group-item>
    </div>

  </div>
</div>
