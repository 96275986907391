<ng-container *ngIf="loaded">
  <div [ngClass]="['xfw-matrix', 'd-flex', detailView ? 'detail-view-visible' : '']">
    <div *ngFor="let dataGroupMatrixItem of dataGroupMatrix; let index = index; trackBy: trackByFn"
      [ngClass]="['xfw-matrix-container-col', dataGroupMatrixItem.detailType === 'matrixHeader' ? 'flex-grow-1' : '']">
      <app-data-group-matrix-static-col
        *ngIf="dataGroupMatrixItem.detailType === 'matrixStaticColItem'"
        [dataGroupMatrix]="dataGroupMatrix"
        [index]="index"
        (onSelect)="onSelected($event)">
      </app-data-group-matrix-static-col>
      <app-data-group-matrix-header
        *ngIf="dataGroupMatrixItem.detailType === 'matrixHeader'"
        [dataGroupMatrix]="dataGroupMatrix"
        [index]="index"
        [item]=""
        (onSelect)="onSelected($event)">
      </app-data-group-matrix-header>
    </div>
  </div>
  <div [ngClass]="['xfw-matrix-detail-view', detailView ? 'detail-view-visible' : '']">
    <router-outlet></router-outlet>
  </div>
</ng-container>
