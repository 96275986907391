
@if(communicationService.user.config.apiMode) {
  <div class="parent-company-info" divBreakpointClass>
    <div class="show-id">
      beta-mode - contact: {{ communicationService.user.contactId }} - parent: {{ communicationService.user.config.parentCompanyId }} - {{ communicationService.user.config.parentCompanyName }} - Roles: {{ roleList }}
    </div>
    <div class="indicator"></div>
  </div>

  <app-overlay *ngIf="showLog" [level]="level" [placeAndSize]="{ bottom: '0px', right: '60px', height: '80vh', width: '400px'}"></app-overlay>
}
