import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {
  public contentJSON: any[] = [];

  public pageId: number = 0;
  public obj = {
    index: 1
  };

  public debounce: boolean = false;

  public timeOut: any;

  ngOnInit(): void {
    this.contentJSON = [
      {
        id: 'Sign-Spine',
        type: 'title',
        title: 'Sign-Spine',
        subTitle: 'slimme software voor de sign-professional'
      },
      {
        id: 'Intro',
        type: 'intro',
        left: [
          {
            duration: 2,
            text: "XFW3 is ontwikkeld als een toolbox voor het maken van software voor de maak-industrie."
          },
          {
            duration: 3,
            text: "Met behulp van XFW3 hebben we Sign-Spine gemaakt, een totaaloplossing voor de sign-professional, waarmee tegen lage kosten eenvoudig nieuwe producten kunnen worden geconfigureerd en geproduceerd."
          },
          {
            duration: 5,
            text: "Gezien de extreem korte doorlooptijden, het dynamische karakter en de complexiteit van het signmaker-vak is het vooral eenduidige en duidelijke communicatie waardoor fouten en vertraging kan worden voorkomen, met 30 jaar ervaring in de sign-branche weten we waar het mis kan gaan."
          },
          {
            duration: 3,
            text: "Met Sign-Spine beschik je altijd over de juiste informatie waardoor communicatie met leveranciers, klanten en teamleden uiterst soepel verloopt."
          },
          {
            duration: 3,
            text: "Met Sign-Spine kost het administratieve deel van het vak weinig inspanning en kunnen sign-professionals focussen op wat ze het liefste doen, met meer marge en minder ergernis."
          }
        ]
      },
      {
        id: 'Multi',
        type: 'img',
        left: [
          {
            duration: 3,
            text: "Multi-site met eigen css en logo's. Je maakt eenvoudig klantspecifieke websites mét klantspecifieke producten.",
          },
          {
            duration: 3,
            text: 'Multi-language, vertaal je site vliegensvlug met behulp van Deepl en pas daarna je teksten aan.',
          },
          {
            duration: 3,
            text: 'Werk eenvoudig samen met ZZP\'ers voor montage, met inzage in hun planning (zonder details te mogen zien).',
          },
          {
            duration: 3,
            text: 'Met de slimme configurators bereken je niet alleen de prijs, maar bepaal je competenties, selecteer je de juiste templates en produceer je zelf of besteed je uit bij Probo.',
          }
        ],
        middle: [
          {
            img: 'https://xfw3.b-cdn.net/presentation/image/6.png'
          }
        ],
        right: [
          {
            duration: 4,
            text: 'Nadat je een product hebt samengesteld en de juiste productiebestanden hebt gekoppeld maak je er met een druk op de knop een klantspecifiek product van. Zo kan jouw klant wel heel makkelijk bestellingen bij je laatsen vanuit zijn klantportaal.',
          },
          {
            duration: 5,
            text: 'Is jouw klant een soort van wederverkoper, zoals een voetbalvereniging, een tennisclub of een fotograaf, dan kunnen hun klanten ook de mogelijkheid bieden om online via hun website te bestellen, en ze kunnen het product ook aanvullen. Zo kan de sportclub naast de template voor een sponsorbord ook het sponsorcontract laten downloaden. Wel zo makkelijk.'
          }
        ],
      },
      {
        id: 'Zoekbalk',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "De zoekbalk is multi-functioneel, je zoekt snel door allerlei soorten gegevens, zoals producten, relaties, contactpersonen, orders, facturen."
          },
          {
            duration: 3,
            text: 'Je kan de zoekbalk ook gebruiken om te rekenen of om op google-maps te zoeken.'
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/Zoeken.mp4'
          }
        ],
        right: [
          {
            duration: 4,
            text: 'Bij google-maps kun je plaatsnamen, adressen maar ook relatiecodes invoeren, dat laatste zoekt lekker snel. Het is mogelijk om de route te optimaliseren.',
          },
          {
            duration: 4,
            text: 'De zoekbalk gebruik je ook voor de Snelle-Taken. Zo kun je snel een container laten legen, een pallet laten ophalen, een bestelling doorgeven aan de snackbar, of een reminder voor later maken.',
          },
        ],
      },
      {
        id: 'Relaties',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "Dankzij een hiërarchische relatie-structuur is direct duidelijk of een klant direct of via een wederverkoper bij je bestelt."
          },
          {
            duration: 3,
            text: 'Auto\'s, aanhangwagens, textielframes \'hangen\' onder een relatie. Zo kun je snel de order-historie op een bedrijfsauto zien.'
          },
          {
            duration: 5,
            text: 'Sommige producten neemt een klant herhaaldelijk af, denk aan vlaggen, banieren, sponsorborden, rollbanners. De hele \'configuratie\' is dan al bekend en meestal zijn het een paar variabelen (zoals formaat, of b.v. kenteken) die moeten worden ingevuld. Met klantspecifieke producten sla je de configuratie op (inclusief productie- en montage-bestanden) en gaat een herhaalorder in no-time richting productie.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/Relaties.mp4'
          }
        ],
        right: [
          {
            duration: 4,
            text: 'Het is nu ook mogelijk een ontwerp te beschrijven en zodoende slim-schalende ontwerpen te maken. Stel dat je een sponsorbord pakt van 300 x 60 cm en die schaal je naar 300 x 75 cm. Het logo blijft dan keurig in verhouding, net als de teksten en de witruimtes. Zo hoef je maar éém ontwerp te maken en huisstijlregels aan te leveren en de software doet de rest.',
          },
          {
            duration: 4,
            text: 'Klantspecifieke producten hebben ‘voor de klant logische namen’. Dus in plaats van ‘textielframe 44mm open-zijkanten zwart met blackback doek, 102 x 82 cm’ heet het product ‘Doek voor frame bij de balie’ of ‘doek voor frame bij de kids-afdeling’. Los van gebruiksgemak voor de klant (want hij kan uiteraard online vanaf zijn mobiel bestellen) zorgt het ook voor extra klantenbinding. Het kost de klant namelijk veel meer moeite om alle gegevens te achterhalen zodat hij een vergelijkende prijs kan opvragen bij de concurrent.',
          },
        ],
      },
      {
        id: 'Planning',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "Automatisch plannen op basis van competenties en beschikbaarheid, zowel medewerkers als resources (auto's, steigers).",
          },
          {
            duration: 3,
            text: 'In één oogopslag zien wat er moet gebeuren als een monteur is uitgevallen.',
          },
          {
            duration: 3,
            text: 'De taak \'weet\' dat bij bepaalde weersomstandigheden montage niet mogelijk is. Ken je een taak toe aan een dag waarbij het weer \'t misschien niet toelaat, dan krijg je een melding.',
          },
          {
            duration: 3,
            text: 'Ingeplande taken die door weersomstandigheden niet kunnen worden uitgevoerd krijgen een gekleurd kader, zodat je ze snel opnieuw kan inplannen.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/Planning.mp4'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'Eenvoudig taken over meerdere dagen verdelen, of onder meerdere medewerkers.',
          },
          {
            duration: 3,
            text: 'Eenvoudig teams samenstellen om opdrachten uit te voeren.',
          },
          {
            duration: 3,
            text: 'Ingeplande orders kunnen automatisch op route worden gepland dankzij koppeling met google-maps. Je kan altijd overrulen.',
          },
          {
            duration: 3,
            text: 'De planning kan ook worden gebruikt om later te kijken wie wat wanneer heeft gedaan en wie met welke auto heeft gereden).',
          }
        ],
      },
      {
        id: 'Forex',
        type: 'video',
        left: [
          {
            duration: 5,
            text: 'Je kan eenvoudig meerdere aantallen en afmetingen invoeren (waarbij je ook berekeningen kan invoeren). Verder kun je afmetingen koppelen aan artikels (kleuren folie) en kun je een omschrijving invoeren. Bij productie worden labels gemaakt met een afbeelding en de omschrijving die bij de afmetingen stond.',
          },
          {
            duration: 3,
            text: 'Onze configurators werken met prijsstaffels. Als een display uit 4 panelen bestaat, 2x een zijkant, 1x achterkant en 1x voorkant, kun je makkelijk een voorstel maken voor 1, 5 en 10 sets.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/ConfForex.mp4'
          }
        ],
        right: [
          {
            duration: 4,
            text: 'Het \'resultaat\' van het samenstellen is niet alleen de artikellijst om de prijs te berekenen. Zo is er een lijst met instellingen van printers en afwerkapparatuur, een benodigde-competenties lijst, presets van aantallen en afmetingen.',
          },
          {
            duration: 3,
            text: 'Omdat de complete configurator wordt opgehaald hoef je bij wijzigingen alleen de afhankelijke opties te verversen. Dat gaat lekker vlot.',
          }
        ]
      },
      {
        id: 'Calculatie',
        type: 'video',
        left: [
          // {
          //   duration: 3,
          //   text: "Onze configurator-data-structuur maakt het eenvoudig samenstellingen te vertalen naar de structuur van andere aanbieders zoals Print.com, Simian of eigen productie.",
          // },
          {
            duration: 2,
            text: 'Bij het uitrekenen van prijzen en het samenstellen van stuklijsten wordt ook gekeken naar de meest ideale plaatmaat, tenzij in de configuratie staat dat dit materiaal genest wordt.'
          },
          {
            duration: 2,
            text: 'Calculaties razendsnel, ook op minder krachtige servers.',
          },
          {
            duration: 4,
            text: 'Een configuratie bevat niet alleen de items, maar ook informatie over steunkleuren, benodigde competenties, de juiste templates, te gebruiken printer- en afwerkinstellingen.',
          },
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/CalculatiePrijzen.mp4'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'Krachtige formules maken het mogelijk om complexe berekeningen te maken en zo complete itemlijsten te maken voor textielframes, stellages.',
          },
          {
            duration: 3,
            text: 'Bij gebruik van de design-tool wordt ook uitgerekend hoe lang het snijden/frezen duurt, op basis van de instellingen die weer gekoppeld zijn aan het gekozen materiaal.',
          }
        ]
      },
      {
        id: 'Raambord',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "Afmetingen worden automatisch doorgezet naar de aantallen en afmetingen, maar zijn wel read-only. Maak je meer regels aan dan worden de read-only afmetingen doorgekopieerd.",
          },
          {
            duration: 3,
            text: 'Er kunnen meerdere regels afmetingen onder een keuze vallen. Staat er een U-wand met 9 panelen dan heb je 9 regels in de aantallen en afmetingen.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/ConfRaambord.mp4'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'Templates worden opgeslagen onder de samenstelling van een product, dus niet simpelweg onder het product. Kies je voor een beachflag halfrond XL, dan zie je maar één template, specifiek voor de configuratie die je hebt samengesteld.',
          },
          {
            duration: 3,
            text: 'Geuploade bestanden worden gekoppeld aan de aantallen en afmetingen, je kan de koppelingen aanpassen. Zo past de omschrijving en daarmee het label bij de bedrukking als je meerdere prints dezelfde afmeting hebben.',
          },
        ],
      },
      {
        id: 'Formulier',
        type: 'video',
        left: [
          {
            duration: 3,
            text: 'Met eenvoudige dropdownlists kun je makkelijk -in de configurator- kiezen voor een Canva of Ax formaat, automerk en type of foliekleuren.',
          },
          {
            duration: 3,
            text: 'Nadat je een product hebt samengesteld en productiebestanden hebt gekoppeld maak je er met een druk op de knop een klantspecifiek product van. Zo kan jouw klant wel heel makkelijk bestellingen bij je plaatsen vanuit zijn klantportaal.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/ConfProp.mp4'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'Onze configurators kunnen met meerdere materiaalsoorten werken, waarbij per materiaalsoort een afmeting kan worden ingevoerd. Zo kun je een desk maken met zijkanten van forex en een bovenplaat van multiplex.',
          },
          {
            duration: 3,
            text: 'Accessoires komt \'los\' op de offerte te staan. Het kan goed mogelijk zijn dat de klant de accessoires al heeft. Op deze manier kan eenvoudig worden aangegeven of de accessoires wel of niet nodig zijn.',
          }
        ],
      },
      {
        id: 'Mos letters',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "Bij de 'vragen' in de configurator kan een willekeurig 'component' worden gekozen. Zo is er veel flexibiliteit qua mogelijkheden.",
          },
          {
            duration: 3,
            text: 'In dit voorbeeld een text-editor voor mos-letters, of voor lightbulb-letters.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/MosLetters.mp4'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'Maar evengoed kan worden gekozen uit een serie patronen, of een specifieke svg-editor voor het positioneren van een logo op een t-shirt.',
          },
          {
            duration: 3,
            text: 'Onze configurators kunnen 1 of meerdere producten aanmaken, waardoor ze compatibel blijven met de belangrijke print-providers.',
          },
        ],
      },
      {
        id: 'Personalizeren',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "Met Sign-spine maak je in een handomdraai een slimme gepersonaliseerde website voor je klant. Zo kan je klant zijn eigen producten samenstellen, bestellen en betalen. Maar ook zijn eigen klanten laten bestellen en betalen.",
          },
          {
            duration: 3,
            text: 'Hier het concept voor een SignAgain website, deze is specifiek voor deze presentatie.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/SignAgainDemoConf.mp4'
          }
        ]
      },
      {
        id: 'Blanco paneel',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "De complete prijslijsten staan in Sign-Spine, inclusief soortelijke gewichten en afmetingen. Het is dan ook heel makkelijk om de panelen in een configurator te zetten en zo plaat-op-maat bestellingen te plaatsen.",
          },
          {
            duration: 3,
            text: 'De opzet van Sign-Spine is zodanig dat heel eenvoudig een prijs gekoppeld kan worden aan een levertijd. Verder \'weet\' het systeem de tijd en kan dus gecheckt worden welke levertijd haalbaar is.',
          },
          {
            duration: 3,
            text: 'Zou de klant de optie moeten hebben om ervoor te kiezen of de platen inclusief of exclusief zaagresten moeten worden geleverd dan is dat geen probleem. De configurator is eenvoudig aan te passen en de \'output\' van de configurator is aan te passen aan een voor de leverancier handig formaat.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/BlancoPaneel.mp4'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'In overleg is het mogelijk ook de webteksten van sign-provider sites te gebruiken als content voor ons op AI gebasseerd help-systeem. Sign-professionals kunnen zo snel advies opvragen over materialen etc.',
          },
          {
            duration: 3,
            text: 'Slimme suggesties kunnen worden gekoppeld aan een configuratie, zo kun je op basis van b.v. nesting of reststukken adviseren andere formaten te kiezen.',
          }
        ]
      },
      {
        id: 'Frame',
        type: 'img',
        left: [
          {
            duration: 3,
            text: "Configutor-regels hebben 'radio' of 'checkbox' opties, complete formulieren of svg\'s met inputvelden. Zo kun je b.v. bij een stalen spandoekframe breedte, hoogte en hoogte boven maaiveld invoeren.",
          },
          {
            duration: 3,
            text: 'Maar ook textielframes, ledframes, 3d frames kunnen worden samengesteld en getekend, met als \'resultaat\' een stuklijst/zaaglijst. Je kan hier ook weer een dropship-vinkje geven, zodat de producent het gelijk bij je klant kan afleveren.',
          },
          {
            duration: 3,
            text: 'De configurator rekent uit hoeveel led\'s nodig zijn en maakt gelijk een template aan voor de print.',
          }
        ],
        middle: [
          {
            img: 'https://xfw3.b-cdn.net/presentation/image/7.png'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'Met de configurator is het mogelijk om slim te kiezen voor materiaal, op basis van afmeting. Dus je kiest dibond, zodra je groter gaat dan 300 cm, dan wordt dat automatisch Dibond-XL',
          },
          {
            duration: 3,
            text: 'Slimme suggesties kunnen worden gekoppeld aan een configuratie, zo kun je op basis van b.v. nesting of reststukken adviseren andere formaten te kiezen.',
          }
        ]
      },
      {
        id: 'Design tool',
        type: 'video',
        left: [
          {
            duration: 5,
            text: 'Met de Inhaker Design Tool maak je razendsnel de juiste templates voor je Inhaker. Fijne bijkomstigheid, de IHDT rekent precies uit hoelang ’t duurt om de panelen te frezen en die informatie wordt doorgegeven aan de calculatie. Zo kun je de extra tijd die de haakjes duren op de freestafel gewoon in rekening brengen.',
          },
          {
            duration: 3,
            text: 'De IHDT is compatible met Illustrator, met een eenvoudige copy/paste zet je de gegevens zo over naar Illustrator.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/Ihdt.mp4'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'De IHDT is gecombineerd met onze ‘kantel-calculator’. Dus hang je een te groot paneel aan één zijkant van de Inhaker dan zul je zien dat hij omvalt. Uiteraard geeft de IHDT je dan voldoende suggesties om ’t probleem op te lossen.',
          },
          {
            duration: 3,
            text: 'De IHDT maakt automatisch de templates aan, maar kan later ook gecombineerd worden met slim-schaalbare ontwerpen.',
          }
        ]
      },
      {
        id: '3d beta',
        type: 'video',
        left: [
          {
            duration: 3,
            text: '3d preview van Inhaker opstellingen, maar ook van textielframes, ledframes, 3d frames.',
          },
          {
            duration: 3,
            text: 'Transparante view om haken e.d. te checken',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/IHDT3d.mp4'
          }
        ],
        right: []
      },
      {
        id: 'Editor',
        type: 'img',
        left: [
          {
            duration: 2,
            text: "Eenvoudig zelf producten aanmaken en aanpassen.",
          },
          {
            duration: 2,
            text: 'Klantspecifieke producten maak je door een configuratie op te slaan, met daaraan gekoppeld een (slim-schaalbaar) productiebestand.',
          },
          {
            duration: 2,
            text: 'Maar je kan ook klantspecifieke configurators maken, b.v. om het design van een product te bepalen binnen de huisstijl-regels.',
          },
          {
            duration: 2,
            text: 'Met de integratie van forms in configurator-regels zijn de mogelijkheden eindeloos.',
          }
        ],
        middle: [
          {
            img: 'https://xfw3.b-cdn.net/presentation/image/1.png'
          }
        ],
        right: [
          {
            duration: 3,
            text: 'Na het maken van een configurator koppel je de opties aan artikels uit de artikellijst, kies je voorgedefinieerde formules of maak je zelf je formules.',
          },
          {
            duration: 5,
            text: 'Zo wordt het uitbreiden van je webshop met complexe producten een fluitje van een cent.'
          }
        ]
      },
      {
        id: 'Job',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "Sign-Spine maakt geen onderscheid tussen orders en offertes (job's) qua nummering, dus bestandsnamen waar een offerte- of ordernummer in staat hoef je niet te renamen.",
          },
          {
            duration: 2,
            text: 'Op alle relevante plaatsen wordt een log bijgehouden, zo weet je precies wie een offerte, order of b.v. de planning heeft aangepast.',
          },
          {
            duration: 2,
            text: 'Het is heel makkelijk om opdrachten te kopieren of te verschuiven, ideaal voor herhaalopdrachten!',
          },
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/Job.mp4'
          }
        ],
        right: [

          {
            duration: 2,
            text: 'In de job zelf kun je makkelijk de aantallen en afmetingen aanpassen en opnieuw rekenen. Verder kun je de juiste staffel kiezen, correctie berekeningen uitvoeren, bestanden uploaden en de bijbehorende batches bekijken. Je kan hier ook de exacte samenstelling van je product zien.',
          },
          {
            duration: 3,
            text: 'Leverafspraken, notificaties, klantreferenties kun je per optie invoeren. Data kun je als datum, verschillende losse data of één of meerdere aangesloten periodes invoeren.',
          }
        ],
      },
      {
        id: 'Info',
        type: 'intro',
        left: [
          {
            duration: 10,
            text: "Samenwerken. Als er een order wordt uitbesteedt bij een collega sign-bedrijf of print-provider dan gaat die order in een wrapper (een envelop). Op de envelop staan de gegevens die het producerende bedrijf nodig heeft (ordernummer, klantcode etc). In de envelop zit de originele order, met de originele bestanden etc. Als de status bij het productiebedrijf wordt aangepast wordt de status op de originele order aangepast. (Het is een envelop met een venstertje, zo ziet het producerende bedrijf ook wat de status is). Er kan zelfs een envelop in een envelop, dus als Kor bij Zichtbaar uitbesteedt en Zichtbaar besteedt uit bij ZP (Zichtbaar Productie) dan gaat dat allemaal prima."
          },
          {
            duration: 3,
            text: "Voor jullie belangrijk, als je een order uitbesteedt bij een bedrijf met Sign-Spine dan heb je geen extra administratieve overlast."
          },
          {
            duration: 8,
            text: "Er komt een nieuw type order, voorraad orders. Een voorraad order heeft een langere levertijd (b.v. een jaar) en een minimale en maximale voorraad. Stel je krijgt een opdracht voor 1500 borden, die gedurende een jaar worden afgenomen. Je spreekt met de klant af dat er maximaal 300 borden in een maand mogen worden afgenomen. De eerste batch is dan ook die 300. Als de voorraad < wordt dan de minimale voorraad dan komt er automatisch een batch-order (batch = productie) order voor de aanvulling tot minimale voorraad. Bij de productie-planning kan die batchgrootte naar boven worden bijgewerkt."
          },
          {
            duration: 6,
            text: "Er is altijd een lijst met lopende voorraad-orders. Als het niet druk is bij productie kan de productie-planner zelf batch-opdrachten aanmaken op de voorraad-orders. Dus er liggen nog 400 borden op voorraad, maar is weinig te doen. Dus de productie planner maakt een batch-order aan voor 300 borden en die wordt dan geproduceerd. Uiteraard wordt gecheckt of er niet meer geproduceerd gaat worden dan in totaal besteld is."
          },
          {
            duration: 3,
            text: "De klant kan via zijn portal borden afroepen. Hij ziet dan precies hoeveel borden er nog zijn. Zowel voor klant als voor de signmaker is er altijd een duidelijk overzicht van de voorraad."
          },
          {
            duration: 5,
            text: "Als je dit systeem combineert met variable-pricing (goedkoper zijn als het rustig is en duurder als het druk is) dan zou je daar met deze klanten over kunnen praten. Geef aan dat ze ten allen tijden de voorraad kunnen laten verhogen en dat dat in sommige periodes van het jaar voordeliger kan zijn voor ze."
          },
          {
            duration: 3,
            text: "Je kan altijd meerdere facturen maken op elke order, dus ook op voorraad-orders. Dus of je in batch factureert of alles vooraf is je eigen beslissing."
          }
        ]
      },
      {
        id: 'Suggesties',
        type: 'title',
        title: 'Suggesties',
        subTitle: 'slimme suggesties die je werk vergemakkelijken en je inspireren met ideeën die interessant kunnen zijn voor jou of je klant'
      },
      {
        id: 'Info',
        type: 'intro',
        left: [
          {
            duration: 2,
            text: "Los van de slimme suggesties die verschijnen als \'tip\' tijdens je werkzaamheden kun je ook direct gebruik maken van suggesties."
          },
          {
            duration: 3,
            text: "Configureer je product op basis van functionaliteit door het beantwoorden van een aantal vragen en krijg advies over formaat, materiaalsoort en dikte, wijze van bedrukken en van bevestigen."
          },
          {
            duration: 5,
            text: "In je bedrijfsprofiel geef je aan wat je specialiteiten zijn, zodat \'suggesties\' geen buizenframe aanbeveelt als je specialiteit het zetten van houten frames is. Los van je \'vaste\' profiel kun je ook tijdgebonden profielen aanmaken (vanaf dit moment profielen). Zo kun je tijdens drukte eenvoudig uit te besteden producten aan laten bieden."
          }
        ]
      },
      {
        id: 'Acties',
        type: 'title',
        title: 'Actiecentrum ITTT',
        subTitle: 'mail, notificaties, taken, account-aanvragen, second-opinions, geautomatiseerde als-dit-dan-dat acties'
      },
      {
        id: 'Actie ITTT',
        type: 'img',
        left: [
          {
            duration: 2,
            text: "Mails koppelen met offertes, orders, ingekomen facturen, inkooporders. Altijd overzicht.",
          },
          {
            duration: 2,
            text: 'Snel toegang tot al je taken, notificaties en account-aanvragen.',
          },
          {
            duration: 2,
            text: 'Second-opinion, wil je collega dat jij nog even kijkt naar een offerte, of een order moet doorgaan ivm betalingsachterstand. Via second-opinion kun jij de actie goed of afkeuren.',
          }
        ],
        middle: [
          {
            img: 'https://xfw3.b-cdn.net/presentation/image/5.png'
          }
        ],
        right: [
          {
            duration: 2,
            text: 'Of je nu het licht uit wil laten doen als de laatste medewerker weggaat, een reminder wil mailen als een order niet is afgehaald, of een heads-up wil krijgen als er een grote order binnenkomt. Stel je vraag en geef aan wat de actie moet zijn bij ja of nee. Alles kan.',
          },
          {
            duration: 5,
            text: 'Acties kun je volgens het terugkeerpatroon instellen, elk uur, elke dag, om de week, einde maand. Maar je kan ook een datum en tijd instellen. Ook kun je acties koppelen aan bewerkingen (aanmaken nieuwe relatie, doorzetten van een offerte)'
          }
        ]
      },
      {
        id: 'Snelle taken',
        type: 'video',
        left: [
          {
            duration: 3,
            text: "Met Sign-Spine kun je simpele taken eenvoudig automatiseren, vervolgens plak je ze onder de Snelle taken knop.",
          },
          {
            duration: 2,
            text: 'Taken zijn niet alleen voor intern, maar ook extern, zo kun je opdracht geven om containers te legen of pallets op te halen.',
          },
          {
            duration: 2,
            text: 'In overleg met de externe partner kan er simpelweg een mailtje terugkomen, maar zij kunnen ook een afspraak inplannen in het sign-spine systeem.',
          },
          {
            duration: 2,
            text: 'Snelle taken zijn medewerker-specifiek. Het voorbeeld is voor productiemedewerkers. Maar met hetzelfde gemak kun je een taak maken waarmee je kosten declareert, verpakkingsmateriaal besteld, je bestelling doorgeeft voor de snackbar op vrijdag, of simpelweg een reminder voor later.',
          }
        ],
        middle: [
          {
            video: 'https://xfw3.b-cdn.net/presentation/video/QuickTask.mp4'
          }
        ],
        right: [

          {
            duration: 2,
            text: 'Je kan bepaalde delen van de software toegankelijk maken voor je leveranciers. Zo kan je leverancier in het onderhoudslogboek van je printer kunnen kijken, of in een foutenlogboek.',
          },
          {
            duration: 3,
            text: 'Onderhoudstaken of een storing-oplossen-taak zie je ook in je productieplanning, zo kun je achteraf makkelijk achterhalen waar b.v. een vertraging of kwaliteitsprobleem vandaan komt.',
          }
        ],
      },
      {
        id: 'DaaS',
        type: 'title',
        title: 'Data as a Service',
        subTitle: 'itemlijsten en configurators altijd up-to-date, klantgegevens verrijken met marketingdata, benchmarkdata'
      },
      {
        id: 'DaaS info',
        type: 'img',
        left: [
          {
            duration: 2,
            text: "Wij waarborgen de kwaliteit van data zoals artikellijsten, configurators, calculatie-modellen, ITTT statements, API koppelingen. Prijs- en artikellijsten altijd up-to-date.",
          },
          {
            duration: 2,
            text: 'XFW3 werkt voornamelijk met configureerbare componenten, dus veel lijsten, views, formulieren kunnen vanuit het DaaS model klantspecifiek worden aangemaakt. Zo richt je Sign-Spine precies in zoals jij dat wilt.',
          }
        ],
        middle: [
          {
            img: 'https://xfw3.b-cdn.net/presentation/image/3.png'
          }
        ],
        right: [
          {
            duration: 2,
            text: 'Vanuit DaaS is het mogelijk om je relatiegegevens te verrijken en toegang te krijgen tot marketingdata. Ook benchmarking hoort tot de mogelijkheden.',
          },
          {
            duration: 5,
            text: 'Gebruik je Sign-Spine in combinatie met je website, dan kun je eenvoudig ordergegevens vergelijken met marketing-acties en site-bezoek.'
          }
        ]
      },
      {
        id: 'DaaS API',
        type: 'img',
        left: [
          {
            duration: 2,
            text: "De opzet van XFW3 (en daarmee Sign-Spine) is heel open voor samenwerking met andere partijen. Hier zie je een voorbeeld van Fibaro, onze verlichting app.",
          }
        ],
        middle: [
          {
            img: 'https://xfw3.b-cdn.net/presentation/image/8.png'
          }
        ],
        right: [
          {
            duration: 2,
            text: 'Onze focus ligt nu op koppelingen met leveranciers uit de sign-branche, maar ook koppelingen met KvK, kenteken-zoekers e.d. behoren tot de mogelijkheden.'
          }
        ]
      },
    ];
  }

  showPage(pageId: number) {
    this.pageId = pageId;

    this.timeOut && clearTimeout(this.timeOut);

    this.obj.index = 0;

    const durationArray: number[] = [];

    this.contentJSON[this.pageId].left && this.contentJSON[this.pageId].left.forEach((item: any) => {
      durationArray.push(item.duration);
      item.index = durationArray.length - 1;
    });

    this.contentJSON[this.pageId].right && this.contentJSON[this.pageId].right.forEach((item: any) => {
      durationArray.push(item.duration);
      item.index = durationArray.length - 1;
    });

    this.presentPage(durationArray, this.obj);

  }

  wheel(event: any) {
    if (!this.debounce) {
      (event.deltaY >= 133) && (this.pageId++ >= this.contentJSON.length - 1) && (this.pageId = 0);
      (event.deltaY <= -133) && (this.pageId-- <= 0) && (this.pageId = this.contentJSON.length - 1);

      if (Math.abs(event.deltaY) >= 133) {
        this.obj.index = 0;

        const durationArray: number[] = [];

        this.contentJSON[this.pageId].left && this.contentJSON[this.pageId].left.forEach((item: any) => {
          durationArray.push(item.duration);
          item.index = durationArray.length - 1;
        });

        this.contentJSON[this.pageId].right && this.contentJSON[this.pageId].right.forEach((item: any) => {
          durationArray.push(item.duration);
          item.index = durationArray.length - 1;
        });

        this.presentPage(durationArray, this.obj);
      }

      this.debounce = true;

      setTimeout(() => {
        this.debounce = false;
      }, 2000);
    }
  }

  presentPage(content: number[], obj: any) {
    this.timeOut = setTimeout(() => {
      (obj.index++ === content.length - 1) && (obj.index = 0);
      this.presentPage(content, obj);
    }, content[obj.index] * 2000); // convert seconds to milliseconds
  }
}
