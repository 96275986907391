<div [ngClass]="['window-modal', window.modal ? 'xfw-modal' : '']" (resized)="onResized($event)">
  @if (window.modal) {
    <div class="window-overlay"></div>
  }
  @if (!window.sidebar) {
    <div [ngClass]="['window-container', window.nav ? 'nav' : '', window.className ?? '']" cdkDrag
    (clickOutside)="window.closeOnOutsideClick ? cancelWindow($event) : false;"
    [style.top.rem]="window.top ?? 'unset'"
    [style.left.rem]="window.left ?? 'unset'"
    [style.right.rem]="window.right ?? 'unset'"
    [style.width.rem]="window.width ?? 'unset'"
    [style.height.rem]="window.height ?? 'unset'"
    [style.z-index]="window.zIndex ?? 1013">
    <ng-container *ngTemplateOutlet="content;"/>
  </div>
  }@else if (currentPanel) {
    <div class="xfw3-sidebar-container"
         [style.right.%]="communicationService.panels.sidebarSetting.main === 100 ? -communicationService.panels.sidebarSetting.sidebar : 0"
         [style.height.%]="currentPanel.height"
         [style.width.%]="communicationService.panels.sidebarSetting.sidebar">
      <ng-container *ngTemplateOutlet="content;"/>
    </div>
  }
</div>

<ng-template #content>
  <div class="window-header">
    <div class="title">
      <h3>{{ window.title }}</h3>
    </div>

    <div class="controls">
      @if (!window.sidebar) {
        <div class="fa-solid fa-grip-dots-vertical px-3" cdkDragHandle></div>
      }@else {
        @if (window.nav && window.index > 0) {
          <div class="fa-solid fa-fw fa-chevron-left px-3" (click)="previous($event); $event.stopPropagation();"></div>
        }
        @if (window.nav && window.index < window.dataLength - 1) {
          <div class="fa-solid fa-fw fa-chevron-right px-3" (click)="next($event); $event.stopPropagation(); "></div>
        }
        @if (window.nav && window.index === window.dataLength - 1 && window.add) {
          <div class="fa-solid fa-fw fa-plus-large px-3" (click)="addRow($event); $event.stopPropagation();"></div>
        }

        @if (currentPanel.isMinimized) {
          <div class="fa-solid fa-fw fa-window-maximize px-3" (click)="toggleMinimizeWindow(false)"></div>
        }@else {
          <div class="fa-solid fa-fw fa-window-minimize px-3" (click)="toggleMinimizeWindow(true)"></div>
        }
      }
      <div class="fa-solid fa-fw fa-xmark px-3" (click)="cancelWindow($event)"></div>
    </div>

  </div>

  @if ((window.sidebar && currentPanel && !currentPanel.isMinimized) || !window.sidebar) {
    <div class="window-body">
      <ng-content></ng-content>
    </div>
  }

  @if (!window.sidebar) {
    @if (window.nav && window.index > 0) {
      <div class="window-nav left">
        <span class="fa-solid fa-sharp fa-chevron-left" (click)="previous($event); $event.stopPropagation();"></span>
      </div>
    }
    @if (window.nav && window.index < window.dataLength - 1) {
      <div class="window-nav right">
        <span class="fa-solid fa-sharp fa-chevron-right" (click)="next($event); $event.stopPropagation(); "></span>
      </div>
    }
    @if (window.nav && window.index === window.dataLength - 1 && window.add) {
      <div class="window-nav right">
        <span class="fa-solid fa-sharp fa-plus-large" (click)="addRow($event); $event.stopPropagation();"></span>
      </div>
    }
  }
</ng-template>
