<div class="xfw3-page-container" (mousemove)="onMousemove($event)" (mouseup)="onMouseup()">
  <div class="xfw3-main" [style.width.%]="communicationService.panels.sidebarSetting.main">
    <router-outlet></router-outlet>
  </div>

  <div #horizontalResizer
    [ngClass]="[
    'xfw3-horizontal-resizer-container',
    resizerIndex !== -1 ? 'moving' : '']"
    [style.left]="communicationService.panels.sidebarSetting.resize ? communicationService.panels.sidebarSetting.resize + 'px' : 'unset'"
    [style.right]="communicationService.panels.sidebarSetting.resize ?
                    'unset' : 'calc(' +
                      (communicationService.panels.sidebarSetting.main !== 100 ?
                        communicationService.panels.sidebarSetting.sidebar : 0) + '% - 0.25rem)'">
    <div class="xfw3-horizontal-resizer">
      <div class="xfw3-horizontal-resizer-handle" (mousedown)="onMousedown(0, false, $event)">
      </div>
      <div class="xfw3-horizontal-resizer-toggle-container">
        <div (click)="toggleSidebar($event)"
          [ngClass]="['xfw3-horizontal-resizer-toggle', communicationService.panels.sidebarSetting.expanded ? 'right' : 'left']">
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="'xfw3-sidebar'"
       [style.right.%]="communicationService.panels.sidebarSetting.main === 100 ? -communicationService.panels.sidebarSetting.sidebar : 0"
       [style.width.%]="communicationService.panels.sidebarSetting.sidebar">

    @for (container of communicationService.panels.sidebarContainers; let index = $index; let last = $last; track container) {
      <div class="xfw3-sidebar-container" [style.height.%]="container.height">
        @if (container.name === 'actionCenter') {
          <app-action-popup *accessLevel="['user']"/>
        }@else if (container.persist) {
          <router-outlet [name]="container.name"></router-outlet>
        }
      </div>
      <div #verticalResizer
          [ngClass]="[
            'xfw3-vertical-resizer-container',
            resizerIndex !== -1 ? 'moving' : '',
            last || !container.isVisible ? 'xfw3-invisible' : '']"
          [style.top.px]="container.resize || 'unset'">
      <div class="xfw3-vertical-resizer">
        <div class="xfw3-vertical-resizer-handle" (mousedown)="onMousedown(index, true, $event)">
        </div>
      </div>
    </div>
    }
  </div>
</div>
