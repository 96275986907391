<app-popup-window
  [window]="{
    title: dataGroupItemInstance.title.window,
    className: dataGroupItemInstance.edit.formClassName,
    dataLength: dataGroupItemInstance.dataTable[0].data.length,
    index: rowIndex,
    nav: window.nav,
    add: window.add,
    modal: window.modal,
    closeOnOutsideClick: window.closeOnOutsideClick
  }"
  (onCancelWindow)="cancelWindow($event)"
  (onPrevious)="previous($event)"
  (onNext)="next($event)"
  (onAddRow)="addRow($event)">

  <app-form
    [widgetId]="widgetId + '_form'"
    [dataGroupItemInstance]="dataGroupItemInstance"
    [rowIndex]="rowIndex"
    [nav]="nav"
    [navList]= "navList"
    [formClass]= "'xfw3-window-form'">
  </app-form>
  <div class="xfw-rowSpacer"></div>
</app-popup-window>
