import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Param } from "src/app/widgets";

@Component({
  selector: "app-content-block",
  templateUrl: "./content-block.component.html",
  styleUrls: ["./content-block.component.css"],
})
export class ContentBlockComponent implements OnInit {
  @Input() blocks: any[] = [];
  @Input() carousel: any[] = [];
  @Input() params: Param[] = [];
  @Input() environmentJSON: any;
  @Input() meta: any = {};


  public showId: boolean = false;

  public contentBlocks: any[] = [];

  constructor(
    public userService: UserService
  ) {}

  ngOnInit() {
  }
}
