<div class="switch">
  <div (click)="switchPopup = true" (clickOutside)="onClickOutside($event)">
    <img class="flag" [src]="languageList[0].content.img" />
    <div>{{ languageList[0].language }}</div>
  </div>
  <div class="switch-popup" [hidden]="!switchPopup">
    <ul>
      <ng-container *ngFor="let item of languageList; first as first; trackBy: trackByFn">
        <li class="switch-item" *ngIf="!first" (click)="setLang(item)">
          <img class="flag" [src]="item.content.img" />
          <span>{{ item.content.text }}</span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
