import { Component, Input, OnInit } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';

@Component({
  selector: 'app-option-info',
  templateUrl: './option-info.component.html',
  styleUrls: ['./option-info.component.scss']
})
export class OptionInfoComponent extends DataGenericComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

}
