@if (edit && rowIndex !== null && rowIndex !== undefined) {
  <app-edit-block [params]="params" [rowIndex]="rowIndex" (onClose)="onClose($event)"/>
}

<div [ngClass]="{ 'menu': true, 'selected': selected }" [id]="'id_' + dataItem.blockId ?? dataItem.objectId ?? dataItem.fileId">
  @if (communicationService.user.config.apiMode) {
    <div class="indicator"></div>
    <div class="show-id">
      <div>
        @if (dataItem.blocksId) {
          <span class="label">blocksId:<span class="value" [title]="dataItem.blocksId">{{ dataItem.blocksId }}</span></span>
        }
        @if (dataItem.blocksId) {
          <span class="label">blockId:<span class="value" [title]="dataItem.blockId">{{ dataItem.blockId }}</span></span>
        }
        @if (dataItem.object) {
          <span class="label">object:<span class="value" [title]="dataItem.object">{{ dataItem.object }}</span></span>
        }
        @if (dataItem.objectId) {
          <span class="label">objectId:<span class="value" [title]="dataItem.objectId">{{ dataItem.objectId }}</span></span>
        }
        @if (dataItem.fileId) {
          <span class="label">fileId:<span class="value" [title]="dataItem.fileId">{{ dataItem.fileId }}</span></span>
        }
        @if (dataItem.widgetId) {
          <span class="label">widgetId:<span class="value" [title]="dataItem.widgetId">{{ dataItem.widgetId }}</span></span>
        }
      </div>
      <div>
        <app-cmd
          [component]="component"
          [nav]="nav"
          [navList]="['menu']"
          [navVisible]="6"
          [className]=""
          [collapsed]="false"/>
      </div>
    </div>
  }


</div>

<ng-content></ng-content>


