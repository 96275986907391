<div class="flex-col component-container" *ngIf="unplannedData">
  <div class="calender-container flex-row w-100 flex-grow overflow-hidden position-relative pt-2" cdkDropListGroup>

    <!-- Left column, unplanned -->
    <div class="flex-col scheduling-list flex-shrink-0 scroll-vert me-1" *ngIf="unplannedData && unplannedData.length">
      <div *ngFor="let resource of unplannedData; trackBy: trackByFn" cdkDropList [id]="resource.resourceId"
        [cdkDropListData]="[null, null, resource]" (cdkDropListDropped)="onDrop($event)">

        <!-- Team header -->
        <ng-container [ngTemplateOutlet]="resourceHeader" [ngTemplateOutletContext]="{resource}" />

        <!-- Team items -->
        <div [ngClass]="resource.collapsed ? 'hidden' : ''">
          <div *ngFor="let task of resource.task; trackBy: trackByFn" #matrixItem [id]="task.compId" cdkDrag
            (cdkDragStarted)="dragStart(task, resource)" (cdkDragDropped)="dragStop(task, resource)"
            [cdkDragStartDelay]="0.2" [cdkDragData]="task" (click)="openDetailView('task', task)">

            <ng-container [ngTemplateOutlet]="taskCard" [ngTemplateOutletContext]="{task, resource}" />
          </div>
        </div>
      </div>
    </div>

    <!-- Right box, all day schedules, hor scroll -->
    <div class="flex-col flex-row flex-shrink-1 scroll-hor overflow-hidden-y position-relative pe-4"
      style="padding-left: 3px" [ngClass]="{ 'calendar-view': view === 'calendar',dragging: dragging }" horizontalScroll
      #scheduleContainer>
      <ng-container *ngFor="let day of plannedData; let i = index; trackBy: trackByFn">
        <div class="column flex-col me-3" #column>

          <!-- Column header -->
          <div class="flex-row w-100 column-header" (click)="toggleCalenderView(i)">
            <div>
              <div class="column-header-left flex-col">
                <span class="week">Week {{ day.week }}</span>
                <span class="day-name">{{ weekDays[day.weekday - 1] }}</span>
                <span class="day-of-month">{{ day.day }}</span>
                <span class="month">{{ months[day.month - 1] }}</span>
                <span class="year">{{ day.year }}</span>
                <span class="day-info">{{ day.info }}</span>
              </div>
            </div>
            <div class="flex-col column-header-right">
              <app-cmd class="column-header-menu" [component]="this" [nav]="navTeam" [navList]="['menu']"
                [navVisible]="0" />
              <div class="header-label-container">
                <span class="header-label">Verlof:</span><span class="header-text">{{ day.unavailable }}</span>
              </div>
              <div>
                <span class="header-label">Ziek: </span><span class="header-text">{{ day.leave }}</span>
              </div>
              <div class="weather">
                <span class="wi wi-thermometer weather-icon"></span>20.8 &#176; / 24.8 &#176;<br />
                <span class="wi wi-windy weather-icon"></span>OZO 3<br />
                <span class="wi wi-showers weather-icon"></span>15% 0.3mm
              </div>

              <ng-template #dayplanning>
                <span>Totale tijd, van alle teamleden</span><br />
                <span>Ingepland uren: {{ plannedTime(day) | minutesToTime }}</span><br />
                <span>Waarvan reistijd: {{ travelTime(day) | minutesToTime }}</span><br />
                <span>Nog in te plannen: {{ totalTime(day) - plannedTime(day) | minutesToTime }}</span>
              </ng-template>

              <div class="time-planned" container="body" placement="top" [ngbPopover]="dayplanning"
                triggers="mouseenter:mouseleave" popoverTitle="Planning"
                [ngClass]="{'overtime' : totalTime(day) < plannedTime(day)}">
                <span class="planned">{{ plannedTime(day) | minutesToTime }}</span>&nbsp;&nbsp;
                <span class="unplanned">{{ totalTime(day) - plannedTime(day) | minutesToTime }}</span>
              </div>
            </div>
            <div class="flex-col clear"></div>
          </div>

          <!-- Column teams -->
          <div class="column-list scheduling-list scroll-vert" preventHorizontalScroll>
            <div>
              <div *ngFor="let timeslot of day.timeslots; trackBy: trackByFn">
                <div class="separator">
                  <span class="title">{{ timeslot.timeslotInfo?.title }}</span>&nbsp;&nbsp;
                  <span class="time"> {{ timeslot.timeslotInfo?.from }} - {{ timeslot.timeslotInfo?.till }}</span>
                </div>
                <div *ngFor="let resource of timeslot.resource; trackBy: trackByFn"
                  [ngClass]="['flex-col', !resource.task.length ? 'is-empty' : '']" cdkDropList
                  [id]="resource.resourceId" [cdkDropListData]="[day, timeslot, resource]"
                  (cdkDropListDropped)="onDrop($event)">
                  <!-- Team header -->
                  <ng-container [ngTemplateOutlet]="resourceHeader" [ngTemplateOutletContext]="{resource}" />

                  <!-- Team items -->
                  <div [ngClass]="{ 'hidden':  resource.collapsed }">
                    <div class="task-card-container" *ngFor="let task of resource.task; trackBy: trackByFn" #item
                      [id]="task.compId" cdkDrag [cdkDragStartDelay]="0.2" [cdkDragData]="task"
                      (cdkDragStarted)="dragStart(task, resource)" (cdkDragDropped)="dragStop(task, resource)"
                      (click)="openDetailView('task', task)">

                      <ng-container [ngTemplateOutlet]="taskCard" [ngTemplateOutletContext]="{task, resource}" />

                      <div *cdkDragPreview style="height:140px;width:320px;">
                        <ng-container [ngTemplateOutlet]="taskCard" [ngTemplateOutletContext]="{task, resource}" />

                        <ng-container *ngIf="multiDrag">
                          <div class="task-card-multi-drag"></div>
                          <div class="task-card-multi-drag" style="z-index: -2; top: 12px; left: 8px;"></div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Detail window -->
    <div *ngIf="detailViewItem" class="flex-col detail-view overflow-auto p-4"
      [ngClass]="detailViewItem ? 'detail-view-visible' : ''" divBreakpointClass>
      <div class="detail-view-back menu-button">
        <app-cmd [component]="component" [nav]="navCloseDetailView" [navList]="navList" [navVisible]="navList.length" />
      </div>
      <app-data-group [src]="detailViewItem.detail.dataGroup" />
      <!-- <ngx-json-viewer [json]="currentItem"></ngx-json-viewer> -->
    </div>
  </div>
</div>
<ng-template #resourceHeader let-resource="resource" let-wrapper="wrapper">
  <app-data-planner-resource class="resource" [resource]="resource" [component]="this"
    [totalTime]="totalTime(wrapper || resource)" [travelTime]="travelTime(wrapper || resource)"
    [plannedTime]="plannedTime(wrapper || resource)" (click)="select" [resourceMenuNav]="navTeam" [visibility]="{}" />
</ng-template>

<!-- Task card -->
<ng-template #taskCard let-task="task" let-resource="resource">
  <app-data-planner-task [task]="task" [resource]="resource" />
</ng-template>