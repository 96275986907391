@if (row.state.expanded || product.mode === 'rows') {
  @if (product.mode === 'cards') {
    <app-data-card
      #dataItem
      [index]="index"
      [item]="option"
      [dataGroupItemInstance]="dataGroupItemInstance"
      (onSelect)="select(row, $event)"
      [className]="[dataGroupItemInstance.rowOptions.className, row.type === 'readOnly' ? 'overlay read-only' : ''].join(' ')"
      [uploadType]="uploadType"/>
  }@else {
    <div [ngClass]="['option', option.isSelected ? 'selected' : '', option.state.hidden ? 'invalid' : '', row.type === 'readOnly' ? 'overlay read-only' : '']"
         (click)="select(row, { dataItem: option })">
      <div class="g-col-12">
        {{ option.block.name }}
      </div>
    </div>
  }
}
