<div [ngClass]="['menu xfw3-grid-container', environmentJSON.header ?? '', !contentJSON.blockLayoutType.includes('dark') ? 'dark' : 'light']"
     [ngStyle]="{ '--xfw3-sub-menu-height': ((carousel.length && environmentJSON.visible) ? environmentJSON.height - 2.5 + 'vh' : '100%')}"
     divBreakpointClass>
  <div class="xfw3-grid-col-container">
    <div [ngClass]="[dataService.blockLayoutGridData[domainConfigService.domainConfig.menu.blockLayoutGrid[0]]]">
      @if (environmentJSON.visible && carousel.length) {
        <div class="carousel">
          <app-carousel [contentJSON]="carousel[0].contentJSON" [height]="environmentJSON.height" [isInHeader]="true"/>
        </div>
      }

      <div class="side">
        @if (contentJSON?.side?.[0]?.img?.[0]?.url) {
          <img class="side-logo" [src]="contentJSON.side[0].img[0].url"
          (click)="navigate(domainConfigService.domainConfig.home)">
        }
      </div>

      <div [ngClass]="['xfw3-grid-area-container', searchToggle ? 'search' : '']">
        <div class="logo-area">
          @if (contentJSON?.title?.[0]?.img?.[0]?.url) {
            <img class="menu-logo" [src]="contentJSON.title[0].img[0].url"
            (click)="navigate(domainConfigService.domainConfig.home)">
          }
        </div>
        <div class="suggestion-area">
          @if (contentJSON.cols[0].col[0].paramJSON) {
            <app-data-group
              [widgetId]="contentJSON.cols[0].col[0].paramJSON.widgetId"
              [src]="contentJSON.cols[0].col[0].paramJSON.dataGroup"
              [dataGroup]="contentJSON.cols[0].col[0].paramJSON.dataGroupJSON"
              [params]="contentJSON.cols[0].col[0].paramJSON.params"/>
          }
        </div>
        @if (contentJSON.cols[0].col[0].navJSON) {
          <div class="nav-area">
            <app-nav [widgetId]="contentJSON.cols[0].col[0].navJSON.widgetId" [src]="contentJSON.cols[0].col[0].navJSON.src"
              [nav]="contentJSON.cols[0].col[0].navJSON.navDataJSON.nav" [className]="'nav'" />
          </div>
        }
        <div class="search-area">
            <app-main-search [hideSearch]="meta.hideSearch" [hideLanguage]="meta.hideLanguage" (searchToggle)="onSearchToggle($event)" [searchUrl]="meta.searchUrl" />
        </div>
        <div class="product-area">
          @if (contentJSON.cols[0].col[1]?.navJSON) {
            <app-nav
              [widgetId]="contentJSON.cols[0].col[1].navJSON.widgetId"
              [src]="contentJSON.cols[0].col[1].navJSON.src"
              [nav]="contentJSON.cols[0].col[1].navJSON.navDataJSON.nav"
              [className]="'product-menu'" />
          }
        </div>
      </div>
    </div>
  </div>
</div>
