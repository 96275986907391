<ng-container *ngIf="loaded">
  <div *ngFor="let item of dataGroupItem.dataTable[0].data; trackBy: trackByFn">
    <div [ngClass]="['xfw-matrix-col-header', item.isSelected ? 'selected' : '' ]"
         (click)="select(item)">
      <app-data-item-content
        [dataGroupItemInstance]="dataGroupItem"
        [item]="item">
      </app-data-item-content>
    </div>
    <app-data-group-matrix-col-item
      #dataGroupMatrixColItem
      [dataGroupMatrix]="dataGroupMatrix[index].children"
      [item]="item"
      (clearSelected)="onClearSelected()"
      (onSelect)="onSelected($event)">
    </app-data-group-matrix-col-item>
  </div>
</ng-container>
