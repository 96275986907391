import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css']
})
export class ContentListComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
  }

}
