import { Injectable } from "@angular/core";
import { DataGroup } from "../data";
import { Param } from "../widgets";
import { DataService } from "./data.service";
import { NetworkService } from "./network.service";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root",
})
export class ContentService {
  blocks: any[];
  dataGroup: any[];
  dataTableField: any[];

  editContent: any[] = [];

  constructor(
    private utilsService: UtilsService,
    private networkService: NetworkService
  ) {}

  getFlatList(content: any[], include: string[], exclude: string[], result) {
    result.push({
      row: content,
      content: []
    });

    const dsArray = (arr) => {
      arr.forEach((arrItem) => {
        dsSwitch(arrItem);
      });

      return arr;
    };

    const dsObject = (obj) => {
      for (let key in obj) {
        if (include.includes(key) && !(obj[key] instanceof Array)) {
          result[result.length-1].content.push(obj);
          break;
        } else if (exclude.includes(key)) {
        } else {
          dsSwitch(obj[key]);
        }
      }
      return obj;
    };

    const dsSwitch = (arrObj) => {
      if (arrObj instanceof Array) {
        arrObj = dsArray(arrObj);
      } else if (arrObj instanceof Object) {
        arrObj = dsObject(arrObj);
      }
      return arrObj;
    };

    dsSwitch(content);
  }

  dsArray(arr, params, checkQueryParam, paramsType, parentType) {
    arr.forEach((arrItem) => {
      this.dsSwitch(arrItem, params, checkQueryParam, paramsType, parentType);
    });
    return arr;
  }

  dsParentObject(obj, params, checkQueryParam, paramsType, parentType) {
    if (obj.hasOwnProperty(parentType)) {
      obj = this.dsObject(
        obj[parentType],
        params,
        checkQueryParam,
        paramsType,
        parentType
      );
    }

    for (let key in obj) {
      if (key !== parentType) {
        this.dsSwitch(
          obj[key],
          params,
          checkQueryParam,
          paramsType,
          parentType
        );
      }
    }
    return obj;
  }

  dsObject(obj, params, checkQueryParam, paramsType, parentType) {
    if (obj.hasOwnProperty(paramsType)) {
      params = this.utilsService.mergeParams({
        params: params,
        paramsToMerge: obj[paramsType],
        append: true,
        checkQueryParam: checkQueryParam,
        deepCopy: false,
      });
    }

    return obj;
  }

  dsSwitch(arrObj, params, checkQueryParam, paramsType, parentType) {
    if (arrObj instanceof Array) {
      arrObj = this.dsArray(
        arrObj,
        params,
        checkQueryParam,
        paramsType,
        parentType
      );
    } else if (arrObj instanceof Object) {
      arrObj = this.dsParentObject(
        arrObj,
        params,
        checkQueryParam,
        paramsType,
        parentType
      );
    }
    return arrObj;
  }

  changeLanguage(contentJSON: any, lang: string) {
    const dsArray = (arr) => {
      arr.forEach((arrItem) => {
        dsSwitch(arrItem);
      });

      return arr;
    };

    const dsObject = (obj) => {
      if (obj.hasOwnProperty("ml")) {
        Object.assign(obj, obj["ml"][lang]);
      }

      for (let key in obj) {
        if (key !== "ml") {
          dsSwitch(obj[key]);
        }
      }

      return obj;
    };

    const dsSwitch = (arrObj) => {
      if (arrObj instanceof Array) {
        arrObj = dsArray(arrObj);
      } else if (arrObj instanceof Object) {
        arrObj = dsObject(arrObj);
      }
      return arrObj;
    };

    return dsSwitch(contentJSON);
  }

  addLanguage(contentJSON: any, fromLanguage: string, targetLanguage: string) {
    const doTranslate: boolean = true; // false is for testing purposes
    const defaultLanguage = 'nl';

    const dsArray = (arr) => {
      arr.forEach((arrItem) => {
        dsSwitch(arrItem);
      });

      return arr;
    };

    const dsObject = (obj) => {
      if (obj.hasOwnProperty("text") && !obj.hasOwnProperty("ml")) {
        obj["ml"] = {};
      }

      if (obj.hasOwnProperty("ml")) {
        let emptyObject;

        ['text','title','group','path','info'].forEach(key => {
          emptyObject = { aiTranslated: true };
          emptyObject[key] = '';

          if (obj.hasOwnProperty(key) && !obj.ml.hasOwnProperty(targetLanguage).hasOwnProperty(key)) {
            obj.ml[targetLanguage] = { ...obj.ml[targetLanguage], ...emptyObject };
          }

          if (obj.ml[targetLanguage][key] === "" && obj[key] && doTranslate) {
            const subscription = this.networkService
              .aiTranslate({ from: fromLanguage, target: targetLanguage, text: fromLanguage !== defaultLanguage ? obj.ml[fromLanguage][key] ?? obj[key] : obj[key] })
              .subscribe((result: string) => {
                obj.ml[targetLanguage][key] = result;
                subscription.unsubscribe();
              });
          }
        });
      }

      for (let key in obj) {
        if (key !== "ml" && key !== 'row') {
          dsSwitch(obj[key]);
        }
      }

      return obj;
    };

    const dsSwitch = (arrObj) => {
      if (arrObj instanceof Array) {
        arrObj = dsArray(arrObj);
      } else if (arrObj instanceof Object) {
        arrObj = dsObject(arrObj);
      }
      return arrObj;
    };

    return dsSwitch(contentJSON);
  }
}
