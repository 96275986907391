<!-- popup notifications -->
<div class="position-fixed" style="right: 16px; bottom: 64px; z-index: 600;">
  <ngb-toast *ngFor="let notification of notificationService.notificationList; trackBy: trackByFn"
             [autohide]="!notification.windowOpen && notification.autoHide"
             [delay]="delay[notification.msgType]"
             [class]="notification.msgType + '-toast'"
             style="width: 250px"
             (mouseenter)="notification.autoHide = false"
             (mouseleave)="notification.autoHide = true"
             (hide)='notificationService.removeNotification(notification.id)'>
    <ng-template ngbToastHeader >
        <strong style="flex-grow: 1;">{{notification.msgType | titlecase}}</strong>
    </ng-template>
    <h5 *ngIf="notification.msg.title">{{notification.msg.title}}</h5>
    <p *ngIf="notification.msg.body?.text">
      {{notification.msg.body.text}}
    </p>
    <div *ngIf='notification.error'>
      <hr class="mb-1 mt-3">
      <p class='text-info mb-0 link'(click)="openDetails(notification)">Details</p>
    </div>
  </ngb-toast>
</div>
