<div class="container-fluid" [ngClass]="contentJSON.blockLayoutType">
  <div class="row pb-4">
    <div class="col-12 p-0">
      <h1 style="text-align: center;">
        <ng-container *ngFor="let item of contentJSON.title; trackBy: trackByFn">
          <span *ngIf="item.className" [class]="item.className">{{ item.text }}</span>
          <ng-container *ngIf="!item.className">{{ item.text }}</ng-container>
        </ng-container>
      </h1>
    </div>
  </div>
  <div class="row py-5" [class]="contentJSON.blockCombine">
    <div class="col-12 p-0">
      <div class="row">
        <div [class]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
          <div *ngIf="contentJSON.cols[0].col[0].img; trackBy: trackByFn"
                (mouseout) = "showClient = false;"
                (mouseover) = "showClient = true;"
                style="cursor: pointer; position: relative;"
                [style.--aspect-ratio]="contentJSON.cols[0].col[0].img[0].aspectratio ? contentJSON.cols[0].col[0].img[0].aspectratio : 1.5"
                [style.background-size]="contentJSON.cols[0].col[0].img[0].size ? contentJSON.cols[0].col[0].img[0].size : 'cover'"
                [style.width]="contentJSON.cols[0].col[0].img[0].width ? contentJSON.cols[0].col[0].img[0].width : '100%'"
                [style.height]="contentJSON.cols[0].col[0].img[0].height"
                [style.min-height]="contentJSON.cols[0].col[0].img[0].minheight"
                [style.background-image]="'url(' + contentJSON.cols[0].col[0].img[0].url + ')'">
            <div [hidden]="showClient"
              style="background-color: rgba(0,0,0,0.4);
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center; ">
              <div class="choise-box text-light">
                <h1 class="choise-title">
                  <div class="header">{{ contentJSON.cols[0].col[1].body[0].title }}</div>
                </h1>
              </div>
            </div>

            <div [hidden]="!showClient" style="position: absolute; top: 0; left: 0;" class="choise-info text-dark">
              <div>
                <h1 class="text-dark">
                  <div class="header" [ngClass]="contentJSON.cols[0].col[1].body[0].titleClassName">{{ contentJSON.cols[0].col[1].body[0].title }}</div>
                </h1>
                <ng-container *ngFor="let item of contentJSON.cols[0].col[1].body; trackBy: trackByFn">
                  <p *ngIf="item.text">
                    {{ item.text }}
                  </p>
                  <ng-container *ngIf="item.nav">
                    <div class="xfw-rowSpacer"></div>
                    <app-cmd
                        [nav]="item.nav"
                        [navList]="['custom', 'primary']"
                        [navVisible]="0"
                        [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
                        [collapsed]="false">
                    </app-cmd>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div [class]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[1]]">
          <div *ngIf="contentJSON.cols[0].col[2].img"
                (mouseout) = "showReseller = false;"
                (mouseover) = "showReseller = true;"
                style="cursor: pointer; position: relative;"
                [style.--aspect-ratio]="contentJSON.cols[0].col[0].img[0].aspectratio ? contentJSON.cols[0].col[0].img[0].aspectratio : 1.5"
                [style.background-size]="contentJSON.cols[0].col[0].img[0].size ? contentJSON.cols[0].col[0].img[0].size : 'cover'"
                [style.width]="contentJSON.cols[0].col[0].img[0].width ? contentJSON.cols[0].col[0].img[0].width : '100%'"
                [style.height]="contentJSON.cols[0].col[0].img[0].height"
                [style.min-height]="contentJSON.cols[0].col[0].img[0].minheight"
                [style.background-image]="'url(' + contentJSON.cols[0].col[2].img[0].url + ')'">

            <div [hidden]="showReseller" style="background-color: rgba(0,0,0,0.4);
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center; ">
              <div class="choise-box text-light">
                <h1 class="choise-title">
                  <div class="header">{{ contentJSON.cols[0].col[3].body[0].title }}</div>
                </h1>
              </div>
            </div>

            <div [hidden]="!showReseller" style="position: absolute; top: 0; left: 0;" class="choise-info text-dark">
              <div>
                <h1 class="text-dark">
                  <div class="header" [ngClass]="contentJSON.cols[0].col[3].body[0].titleClassName">{{ contentJSON.cols[0].col[3].body[0].title }}</div>
                </h1>
                <ng-container *ngFor="let item of contentJSON.cols[0].col[3].body; trackBy: trackByFn">
                  <p *ngIf="item.text">
                    {{ item.text }}
                  </p>
                  <ng-container *ngIf="item.nav">
                    <div class="xfw-rowSpacer"></div>
                    <app-cmd
                        [nav]="item.nav"
                        [navList]="['custom', 'primary']"
                        [navVisible]="0"
                        [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
                        [collapsed]="false">
                    </app-cmd>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
