@if (dataGroupItem.selected && dataGroupItem.dataTable[0]?.data) {
  <div [ngClass]="['xfw3-grid-row-container', isItemSelected ? 'item-selected' : '', dataGroupItem.isSelected ? '' : 'but-closed']">
    @for (item of dataGroupItem.dataTable[0].data; let rowIndex = $index; track item) {
      <div [ngClass]="['xfw3-grid-row-auto', 'action-item-wrapper', item.isSelected ? 'selected' : '']" (click)="onSelectItem(item, rowIndex);">
        @if (dataGroupItem.selected && (rowVisible === rowIndex || rowVisible === -1)) {
          <app-action-item
            [dataGroupItem]="dataGroupItem"
            [item]="item"
            [rowSelected]="rowSelected"
            [rowIndex]="rowIndex"
            (deselectItem)="onDeselectItem()"
            (updateMutation)="onUpdateMutation($event)"/>
        }
      </div>
    }
  </div>
}
