<div [ngClass]="['xfw3-grid-row-container', dataGroupItem.isSelected ? 'item-selected' : 'but-closed']">
  <div [ngClass]="['action-section', dataGroupItem.selected ? 'selected' : '',
                  sectionState.unread > 0 ? 'action-unread' : '',
                  sectionState.unread === 0 ? 'action-read' : '',
                  sectionState.urgent ? 'action-alert' : '']"
                  (click)="onSelect(dataGroupItem);">
    <div class="header-info">
      @if (sectionState.urgent) {
        <span class="action-alert-items">{{ sectionState.urgent }} | </span>
      }
      @if (sectionState.unread) {
        <span class="action-unread-items">{{ sectionState.unread }}</span>
      }
      @if (sectionState.unread && sectionState.read) {
        <span> | </span>
      }
      @if (sectionState.read) {
        <span class="action-read-items">{{ sectionState.read }}</span>
      }
    </div>
    <div class="header-title">
      <h2>{{ dataGroupItem.title.singleItem }}</h2>
    </div>
  </div>

  <app-action-section
    [widgetId]="widgetId"
    [dataGroupItem]="dataGroupItem"
    (eventSelect)="onSelect($event)"
    (hasData)="onHasData($event)"
    style="margin-top: -2px;"/>
</div>

