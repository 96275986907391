import { Component, OnInit } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';
import { WidgetEvent, EventParams, Param } from "src/app/widgets";
@Component({
  selector: 'app-data-breadcrumbs',
  templateUrl: './data-breadcrumbs.component.html',
  styleUrl: './data-breadcrumbs.component.css'
})
export class DataBreadcrumbsComponent extends DataGenericComponent implements OnInit {
  public clearsExcluded: string[] = [];
  public sessionConfig: any = {};

  ngOnInit(): void {
    super.ngOnInit();

    this.communicationService.initWidget({
      widgetId: this.dataGroupItemInstance.widgetId,
      component: this,
      subscribeTo: [
        {
          event: WidgetEvent.SESSIONCONFIGCHANGED,
          func: 'sessionConfigChanged',

        }
      ]
    });
  }

  sessionConfigChanged(eventParams: EventParams) {
    let changed: boolean = Object.keys(eventParams.dataItem).some(key => this.sessionConfig[key] !== eventParams.dataItem[key]);

    changed && (this.sessionConfig = eventParams.dataItem);

    this.setQueryParams(null);

    changed &&
      setTimeout(() => {
        this.communicationService.performAction({
          widgetGroup: [this.dataGroupItemInstance.widgetId],
          event: WidgetEvent.REFRESH
        });
      });
  }

  setQueryParams(params, initial: boolean = false) {
    const checkClears = () => {
      this.clearsExcluded = [];

      this.sessionConfig && Object.keys(this.sessionConfig).forEach(key => {
        this.communicationService.queryParams.some(param => param.key === key) && this.clearsExcluded.push(key);
      });
    }

    params && super.setQueryParams(params, initial);
    params ? setTimeout(() => checkClears(), 10) : checkClears();
  }


}
