<div #cropBox class="crop" [style.z-index]="dragging ? 1047 : ''" [style.overflow]="dragging ? 'visible' : 'hidden'"
  (drop)="$event.preventDefault(); fileChange($event.dataTransfer.files[0])" (dragover)="$event.preventDefault()">

  @if (hostTop != null && hostLeft != null) {
  <div [style.position]="dragging ? 'fixed' : ''" [style.top.px]="hostTop" [style.left.px]="hostLeft">
    <div #imageContainer [style.top.px]="img.y" [style.left.px]="img.x"
      [style.transform]="'rotate(' + img.angle + 'rad)'"
      style="box-sizing: content-box;  width: fit-content; height: fit-content; position:absolute;">

      <img #imageElement [style.z-index]="dragging ? 1049 : ''" style="object-fit: cover;"
        [style.height.px]="round(img.height * img.zoom)" [src]="img.url" />
    </div>
  </div>
  }

  <div class="buttons" [hidden]="dragging">
    <div (pointerdown)="onTurnStart($event);" (click)="$event.stopPropagation();" class="button"><i
        class="fa-solid fa-arrows-rotate-reverse"></i></div>
    <div class="button" (click)="isRightAngleMode = !isRightAngleMode; $event.stopPropagation()"
      [ngClass]="isRightAngleMode ? 'active' : ''"><i class="fa-solid fa-arrow-rotate-right"></i>
    </div>
    <div class="button" (click)="resetStyling()"><i class="fa-solid fa-backward-fast"></i></div>
  </div>
  <div *ngIf="dragging" class="crop-border"></div>

</div>

<input hidden #fileInput type="file" (change)="fileChange($event.target.files[0]); $event.stopPropagation();">
<div [hidden]="!dragging && !turning" class="dragging-fullscreen-background" [ngClass]="dragging ? 'show' : ''"
  [style.top]="hostTop"></div>