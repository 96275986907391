import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { UserService } from './user.service';
import { DomainConfigService } from './domain-config.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class XfwLocalService {

  private connectionStatus: boolean = false;

  private readonly xfwLocalUrl: string = 'http://localhost:4322';

  constructor(private http: NetworkService, private userService: UserService, private domainConfigService: DomainConfigService) {

  }

  get getConnectionStatus(): boolean {
    return this.connectionStatus;
  }

  /**
   * This method will try to establish a session with the xfw-local server
   */
  async connect(): Promise<void> {

    // Check if user is logged in
    if(this.userService._user.sessionStatus !== 'userSession')
      return Promise.reject('Not logged in, user needs to be logged in to connect to xfw-local');

    const authBitMask: number = this.userService._user.authBitMask;

    // Check if user has employee role. Non employees don't need xfw-local.
    if((this.domainConfigService.domainConfig.roles["employee"] & authBitMask) === 0)
      return Promise.reject('User needs to be an employee to connect to xfw-local');

    // Get request to xfw-local
    try {
      await lastValueFrom(this.http.get(this.xfwLocalUrl + '/auth?url=' + window.location.hostname + '&token=' + localStorage.getItem('session'), 'text', true));

      this.connectionStatus = true;

      return Promise.resolve();
    } catch (error) {

      return Promise.reject('Could not connect to xfw-local. Connection either was refused or timed out.');
    }
  }

  /**
   * This method will get a list of files and directories in a directory
   *
   * @param path path of directory to list
   * @returns Array of objects with name and isFile
   */
  async listDirectory(path: string): Promise<Array<{ name: string, isFile: boolean }>> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      const response = await lastValueFrom(this.http.post(this.xfwLocalUrl + '/ls', { path: path }, 'json', true));

      return Promise.resolve(response);
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * Gets file from local directory
   *
   * @param path path of file to get
   * @returns Object with filename and fileBase64
   */
  async getFile(path: string): Promise<{fileName: string, fileBase64: string}> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      const response = await lastValueFrom(this.http.post(this.xfwLocalUrl + '/getFile', { path: path }, 'json', true));

      return Promise.resolve(response);
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * Opens a file in the local system with the default program
   *
   * @param path path of file to open
   * @returns 'ok' if file was opened
   */
  async openFile(path: string): Promise<void> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      await lastValueFrom(this.http.post(this.xfwLocalUrl + '/openFile', { path: path }, 'text', true));

      return Promise.resolve();
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * Will move a file to a new location
   *
   * @param path path of file to move
   * @param newPath path of new location
   */
  async moveFile(path: string, newPath: string): Promise<void> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      await lastValueFrom(this.http.post(this.xfwLocalUrl + '/moveFile', { path: path, newLocation: newPath }, 'json', true));

      return Promise.resolve();
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * Will copy a file to a new location
   *
   * @param path path of the file that will be copied
   * @param newPath path of the new file
   */
  async copyFile(path: string, newPath: string): Promise<void> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      await lastValueFrom(this.http.post(this.xfwLocalUrl + '/copyFile', { path: path, newFile: newPath }, 'json', true));

      return Promise.resolve();
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * Will delete a file
   *
   * @param path path of the file that will be deleted
   */
  async deleteFile(path: string): Promise<void> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      await lastValueFrom(this.http.post(this.xfwLocalUrl + '/deleteFile', { path: path }, 'json', true));

      return Promise.resolve();
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * Will put a file in the local filesystem from a base64 string from the request body
   *
   * @param path path of the file that will be downloaded
   * @param fileName name of the file. Include the extension.
   * @param fileBase64OrUrl base64 string or url of the file
   */
  async downloadFileToLocal(path: string, fileName: string, fileBase64OrUrl: string): Promise<void> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      await lastValueFrom(this.http.post(this.xfwLocalUrl + '/downloadFile', { path: path, fileName: fileName, fileBase64OrUrl: fileBase64OrUrl }, 'text', true));

      return Promise.resolve();
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * Will create a directory
   *
   * @param path Path of the directory that will be created
   */
  async createDirectory(path: string): Promise<void> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      const response = await lastValueFrom(this.http.post(this.xfwLocalUrl + '/createDirectory', { path: path }, 'json', true));

      return Promise.resolve();
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * Will delete a folder and if in recursive mode, all files and folders in the folder
   *
   * @param path Path of the folder to delete
   * @param recursive If true, will delete all files and folders in the folder. Default value is false.
   */
  async deleteFolder(path: string, recursive: boolean = false): Promise<void> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      await lastValueFrom(this.http.post(this.xfwLocalUrl + '/deleteFolder', { path: path, recursive: recursive }, 'json', true));

      return Promise.resolve();
    } catch(err) {
      Promise.reject(err);
    }
  }

  /**
   * This method will try to get all available printers in the users local system. Will only return printers that are installed.
   * Or will return OS defaults. These are not filterable but should be ignored. OneNote is not a printing option.
   *
   * @returns Printers that are available on the local system
   */
  async getPrinters(): Promise<Printer[]> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      const response = await lastValueFrom(this.http.get(this.xfwLocalUrl + '/getPrinters', 'json', true));

      return Promise.resolve(response);
    }
    catch(err) {
      return Promise.reject(err);
    }
  }

  /**
   * This method will try to print a HTML file from the local system.
   *
   * @param path path to the HTML file that will be printed
   * @param printerName name of the printer
   * @param pageSize Name of a standard size such as A4 or Letter. Or a specific size from the getPrinter request. Depending on printer width or height might be variable.
   *
   * @returns If printer did succeed or not
   */
  async printFile(path: string, printerName: string, pageSize: string | {width: number, height: Number}): Promise<void> {
    if(this.connectionStatus === false)
      return Promise.reject('Not connected to xfw-local');

    try {
      const response = await lastValueFrom(this.http.post(this.xfwLocalUrl + '/printFile', { path: path, printerName: printerName, pageSize: pageSize }, 'json', true));

      return Promise.resolve(response);
    }
    catch(err) {
      return Promise.reject(err);
    }
  }
}

interface Printer {
  name: string,
  description: string,
  status: string,
  printerSizes: Array<{width: number, height: Number} | string>
}
