import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-option',
  templateUrl: './report-option.component.html',
  styleUrls: ['../report.component.scss']
})
export class ReportOptionComponent {
  @Input() data: any;
}
