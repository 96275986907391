import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colToGCol'
})
export class ColToGColPipe implements PipeTransform {

  transform(value: string): string {
    return value.split(' ').map(className => {
      if (className.startsWith('col-') && !className.startsWith('g-col-')) {
        return 'g-' + className;
      }
      return className;
    }).join(' ');
  }
}
