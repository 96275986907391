@if (blocks.length && blocks[0]){
  @for (block of blocks; let index = $index; track block) {
    <div contextAware
        [dataItem]="block"
        [rowIndex]="index">
      <ng-container *accessLevel="block.contentJSON?.accessLevel">
        @if (['one-col', 'multi-col'].includes(block.contentJSON?.blockLayout)){
          <app-multi-col
            [contentJSON]="block.contentJSON"
            [params]="params"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'note'){
          <app-note
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'link-preview'){
          <app-link-preview
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'full-width'){
          <app-full-width
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'cards'){
          <app-cards
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'info'){
          <app-info
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (['collapse-list', 'faq'].includes(block.contentJSON?.blockLayout)){
          <app-collapse-list
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'table'){
          <app-table
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'content-list'){
          <app-content-list
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'switch'){
          <app-switch
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'matrix'){
          <app-matrix
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'fusion'){
          <app-fusion
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'sketchfab'){
          <app-sketchfab
            [contentJSON]="block.contentJSON"
            [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'header'){
            <app-header
              [contentJSON]="block.contentJSON"
              [carousel]="carousel"
              [meta]="meta"
              [environmentJSON]="environmentJSON"/>
        }@else if (block.contentJSON?.blockLayout === 'carousel'){
          <app-carousel
            [contentJSON]="block.contentJSON"
            [height]="environmentJSON.height"
            [isInHeader]="false"/>
        }@else if (block.contentJSON?.blockLayout === 'footer'){
            <app-footer
              [contentJSON]="block.contentJSON"
              [environmentJSON]="environmentJSON"/>
          }
      </ng-container>
    </div>
  }
}
