import {Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList} from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { Nav, Param } from 'src/app/widgets';
import {DataGroup, DataTable} from '../../data';
import { DataService } from 'src/app/services/data.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UtilsService } from 'src/app/services/utils.service';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.css'],
  animations: [
    trigger('slideInOut', [
      state('left', style({
        transform: 'translateX(-100%)',
      })),
      state('right', style({
        transform: 'translateX(100%)',
      })),
      state('center', style({
        transform: 'translateX(0)',
      })),
      transition('* => *', animate('200ms ease-in-out')),
    ])
  ]
})
export class DataCardComponent implements OnInit {
  @Input() dataGroupItemInstance: DataGroup;

  @Input() className: string;

  @Input() cardSelected: number;
  @Input() index: number;
  @Input() item: any;
  @Input() focus: string;
  @Input() uploadType: 'no-img' | 'none' | 'single' | 'single-edit' | 'multiple';

  @Input() draggable: boolean = false;

  @Input() component: any = this;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onShowImgViewer: EventEmitter<any> = new EventEmitter();
  @Output() onSetEditMode: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateMutations: EventEmitter<any> = new EventEmitter();

  showViewer: boolean = false;

  public window:any = window;

  viewerData: string[];

  public imgIndex = 0;
  public checked;

  public JSON = JSON;

  public data: any = {};

  public nav: Nav;
  public slideDirection = 'center';

  constructor(
    public dataService: DataService,
    public utilsService: UtilsService,
    public communicationService: CommunicationService,
    private googleAnalyticsService: GoogleAnalyticsService) {
  }

  nextImage() {
    this.slideDirection = 'left';
    // Change the image source, then reset the slide direction
    setTimeout(() => {
      this.imgIndex++;
      this.slideDirection = 'center';
    }, 200);
  }

  previousImage() {
    this.slideDirection = 'right';
    // Change the image source, then reset the slide direction
    setTimeout(() => {
      this.imgIndex--;
      this.slideDirection = 'center';
    }, 200);
  }

  ngOnInit(): void {
    this.nav = {
      navId: 0,
      primary: {
        className: 'cmd icon xs',
        icon: 'fa-solid fa-pencil',
        text: '',
        info: 'Edit',
        accessLevel: ['moderator'],
        func: 'setEditMode',
        params: [
          {
            key: "index",
            val: this.index
          }
        ],
        isInvisible: this.dataGroupItemInstance.edit.updateMutations === 'direct'
      }
    }
  }

  delete(params, dataItem?) {
    this.dataGroupItemInstance.dataTable[0].data.find(row => row.trackBy === dataItem.trackBy).crud = 'delete';
    this.onUpdateMutations.emit();
  }

  show() {
    this.showViewer = false;

    setTimeout(() => {
     this.showViewer = true;
    });
  }

  select(params: Param[], dataItem, data?, event?) {
    window.getSelection().removeAllRanges();

    if (this.dataGroupItemInstance.card?.nav?.field) {
      const navItem = this.item[this.dataGroupItemInstance.card.nav.field].onSelect;

      this.communicationService.mergeQueryParams(
        this.dataGroupItemInstance.widgetId,
        navItem.params,
        [navItem.path],
        null,
        false
      );

    } else if (this.dataGroupItemInstance.card.download) {
      this.navigate({fileName: this.item['fileName'] + '.' + this.item['extension']}, this.item['path'] + this.item['fileName'] + '.' + this.item['extension'])

    } else if (['no-img', 'none', 'single-edit'].includes(this.uploadType) && this.dataGroupItemInstance.selectionMode !== 'none' && !this.className.includes('read-only')) {

      this.dataGroupItemInstance.selectionMode !== 'viewer' && this.dataGroupItemInstance.selectionMode ?
        this.onSelect.emit({params, dataItem, data, event})
        :
        this.showImgViewer(params, dataItem, data);

    }

    !this.className.includes('read-only') && event.stopPropagation();
  }

  showImgViewer(params: Param[], dataItem, data?) {
    this.onShowImgViewer.emit({
      params,
      dataItem,
      data
    });
  }

  setEditMode(params: Param[], dataItem?, data?) {
    dataItem.isSelected = true;
    this.onSetEditMode.emit({
      params,
      dataItem,
      data
    });
  }


  navigate(item, url) {
    if (!['viewer','link'].includes(this.dataGroupItemInstance.selectionMode)) {
      this.googleAnalyticsService.sendAnalyticsEvent({
        eventName: 'download',
        eventAction: 'download-file',
        eventValue: JSON.stringify(item)
      });

      window.open(url, '_blank');
    }
  }
}
