@if (dataGroupItemInstance.dataTable[0]?.data?.length) {
  <app-breadcrumbs
    [widgetId]="widgetId + '_form'"
    [dataGroupItemInstance]="dataGroupItemInstance"
    [rowIndex]="0"
    [nav]="dataGroupItemInstance.rowOptions.nav"
    [formClass]="dataGroupItemInstance.dataClassName"
    [refresher]="refresher"
    [clearsExcluded]="clearsExcluded"
    [navList]="['primary','secondary']"/>
}
