import { Injectable } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { DataGroup } from '../data';
import { Param, WidgetEvent } from '../widgets';
import { UtilsService } from './utils.service';
import { CommunicationService } from './communication.service';
import { OnDroppedEvent } from '../drop.directive';
import { MultiCheckArgs } from '../drag.directive';

@Injectable({
  providedIn: 'root'
})
export class DragDropService {
  public dropMode: 'copy' | 'transfer' | 'drop' = 'drop';

  public noDrag: boolean = false;

  /** Primary item being dragged. */
  public item: any;

  /** All items being dragged; in case of multi-drag support.  */
  public items: any[];

  public oldContainer: any;

  public dropConnectedTo: string[] = [];



  constructor(
    private utilsService: UtilsService,
    private communicationService: CommunicationService,
  ) { }

  /** Check the source of a drag operation for any other items that might be eligible for multi-dragging based on the checked field.  */
  targetedDropMulti(event: MultiCheckArgs): any[] {
    return (event.container as any[]).filter(v => v.checked);
  }

  /**
   *
   * @param dataGroupItem
   * @param queryParams
   * @param widgetId
   * @param event
   */
  targetedDrop(dataGroupItem: DataGroup, queryParams: Param[], widgetId: string, event: OnDroppedEvent) {
    if (this.dropMode === 'drop') {
      event.previousContainer.data
        .filter(row => row.checked || row.trackBy === event.item.data.item.trackBy)
        .forEach((draggedRow) => {

          draggedRow.crud = 'update';
          event.item.data.foreignKeys.forEach(param => {
            draggedRow[param.key] = event.container.data[param.key];
          });

          // na drop de checks op null zetten.
          dataGroupItem.dataTable[0].data.forEach(row => { row.check = false; });

          (dataGroupItem.params.some(param => param.key === [dataGroupItem.widgetId, 'check'].join('_'))) &&
            (dataGroupItem.params.find(param => param.key === [dataGroupItem.widgetId, 'check'].join('_')).val = null);

          (dataGroupItem.oldParams.some(param => param.key === [dataGroupItem.widgetId, 'check'].join('_'))) &&
            (dataGroupItem.oldParams.find(param => param.key === [dataGroupItem.widgetId, 'check'].join('_')).val = null);

          this.communicationService.mergeQueryParams(dataGroupItem.widgetId, dataGroupItem.params, [], null, true);
        });
    }

    event.item.data.component.updateMutations().then((data: any[]) => {
      [widgetId].forEach(widgetId => {
        this.communicationService.performAction({
          widgetGroup: [widgetId],
          event: WidgetEvent.REFRESH,
          data: data
        });
      });
    });
  }

  /**
   * Method triggered when the user drops a draggable itim inside a drop container.
   *
   * @param event: container: CdkDropList<T>
   * Container in which the item was dropped.
   * currentIndex: number
   * Current index of the item.
   * distance: { x: number; y: number; }
   * Distance in pixels that the user has dragged since the drag sequence started.
   * isPointerOverContainer: boolean
   * Whether the user's pointer was over the container when the item was dropped.
   * item: CdkDrag
   * Item that is being dropped.
   * previousContainer: CdkDropList<O>
   * Container from which the item was picked up. Can be the same as the container.
   * previousIndex: number
   * Index of the item when it was picked ups.
   *
   */

  drop(dataGroupItem: DataGroup, queryParams: Param[], widgetId: string, event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container && this.dropMode !== 'drop') {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      // event.container.data.forEach((x, srt) => {
      //   x.crud = x.srt === srt ? x.crud : 'update';
      //   x.srt = srt;
      // });

    } else {
      if (this.dropMode === 'transfer') {
        if (event.previousContainer.data.some(row => row.checked)) {
          event.previousContainer.data.filter(row => row.checked).forEach((checkedRow) => {
            checkedRow.checked = false;
            checkedRow.crud = this.dropMode;
            checkedRow.isSelected = false;

            dataGroupItem.dataTable[0].queryParams.forEach(param => {
              checkedRow[param.key] = queryParams[param.key];
            });

            transferArrayItem(event.previousContainer.data,
              event.container.data,
              event.previousContainer.data.findIndex(row => this.utilsService.objectsAreEqual(row, checkedRow)),
              event.currentIndex);
          });
        } else {
          dataGroupItem.dataTable[0].queryParams.forEach(param => {
            event.previousContainer.data[event.previousIndex][param.key] = queryParams[param.key];
          });

          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
        }
      } else if (this.dropMode === 'copy') {
        copyArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      } else if (this.dropMode === 'drop') {
        event.previousContainer.data.filter(row => row.checked || row.trackBy === event.item.data.item.trackBy).forEach((draggedRow) => {
          console.log('row', draggedRow);
          console.log('container', event.container.data);
          console.log('event', event);

          draggedRow.crud = 'update';
          event.item.data.foreignKeys.forEach(param => {
            draggedRow[param.key] = event.container.data[event.currentIndex][param.key];
          });

          // na drop de checks op null zetten.
          // dataGroupItem.dataTable[0].data.forEach(row => { row.check = false; });

          // (dataGroupItem.params.some(param => param.key === [dataGroupItem.widgetId, 'check'].join('_'))) &&
          //   (dataGroupItem.params.find(param => param.key === [dataGroupItem.widgetId, 'check'].join('_')).val = null);

          // (dataGroupItem.oldParams.some(param => param.key === [dataGroupItem.widgetId, 'check'].join('_'))) &&
          //   (dataGroupItem.oldParams.find(param => param.key === [dataGroupItem.widgetId, 'check'].join('_')).val = null);

          // this.communicationService.mergeQueryParams(dataGroupItem.widgetId, dataGroupItem.params, [], null, true);
        });
      }

      event.item.data.component.updateMutations().then((data: any[]) => {
        [event.previousContainer.id, widgetId].forEach(widgetId => {
          this.communicationService.performAction({
            widgetGroup: [widgetId],
            event: WidgetEvent.REFRESH,
            data: data
          });
        });
      });
    }
  }
}
