<div class="row" (clickOutside)="closeMenu($event)" (resized)="onResized($event)" #menu>
  <div *ngIf="!hamburger" class="col-12 position-relative text-center">
    <div *ngFor="let tab of nav.tabGroup; let index = index; trackBy: trackByFn" (mouseover)="current.mainIndex = index; current.subIndex = 0; current.img = null;"
      class="xfw-main-menu">
      <a class="xfw-main-menu-item" title="{{ tab.group }}" (click)="showMenu($event);">
        {{ tab.group }}
      </a>
      <div class="xfw-main-menu-bottom-container">
        <div class="xfw-main-menu-bottom" [hidden]="index !== current.mainIndex">
        </div>
      </div>
    </div>

    <div class="xfw-main-menu-dropdown" *ngIf="!current.state.hidden">
      <div class="xfw-main-menu-img">
        <img style="max-width: 122px; max-height: 122px;" src="{{ current.img }}">
      </div>
      <div class="row">
        <div class="col-lg-2 col-md-3 col-3 text-start xfw-sub-menu-col">
          <div *ngFor="let row of nav.tabGroup[current.mainIndex].tabGroup; let index = index; trackBy: trackByFn" (mouseover)="current.subIndex = index; current.img = null;">
            <div [ngClass]="{ 'xfw-sub-menu-item': true, 'selected': current.subIndex === index }">
              <a [title]="row.group" >
                {{ row.group }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-3 text-start" *ngFor="let col of nav.tabGroup[current.mainIndex].tabGroup[current.subIndex].cols; trackBy: trackByFn">
          <div *ngFor="let tab of col.items; trackBy: trackByFn">
            <div [ngClass]="{'xfw-menu-item': tab.text, 'xfw-sub-sub-menu-item': tab.group}">
              <a [title]="tab.text ? tab.text : tab.group" (click) = "select(tab); $event.stopPropagation();" (mouseover)="current.img = tab.dataItem?.Img">
                {{ tab.text ? tab.text : tab.group }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="hamburger" class="hamburger-wrapper">
    <div class="hamburger">
      <i class="dripicons dripicons-menu" (click)="showHamburgerMenu = !showHamburgerMenu; $event.stopPropagation(); "></i>
      <div class="hamburger-menu" [hidden]="!showHamburgerMenu" (clickOutside)="onClickOutside($event)">
        <div class="hamburger-menu-close">
          <i class="dripicons dripicons-cross" (click)="showHamburgerMenu = !showHamburgerMenu; $event.stopPropagation(); "></i>
        </div>

        <div *ngFor="let tab of nav.tabGroup; let index = index; trackBy: trackByFn" class="xfw-main-menu">
          <div class="xfw-main-menu-item" (click)="(current.mainIndex === -1 || index !== current.mainIndex) ? current.mainIndex = index : current.mainIndex = -1;">
            <a [title]="tab.group">
              {{ tab.group }}
            </a>
          </div>

          <div *ngIf="index === current.mainIndex">
            <div class="row">
              <div class="col-12 text-start">
                <div *ngFor="let row of nav.tabGroup[current.mainIndex].tabGroup; let subIndex = index; trackBy: trackByFn" (click)="(current.subIndex === -1 || subIndex !== current.subIndex) ? current.subIndex = subIndex : current.subIndex = -1;">
                  <div [ngClass]="{ 'xfw-sub-menu-item': true, 'selected': current.subIndex === subIndex }">
                    <a [title]="row.group" >
                      {{ row.group }}
                    </a>

                    <ng-container *ngIf="current.subIndex !== -1 && current.subIndex === subIndex">
                      <div class="col-12 text-start" *ngFor="let col of nav.tabGroup[current.mainIndex].tabGroup[current.subIndex].cols; trackBy: trackByFn">
                        <div *ngFor="let tab of col.items; trackBy: trackByFn">
                          <div [ngClass]="{'xfw-menu-item': tab.text, 'xfw-sub-sub-menu-item': tab.group}">
                            <a [title]="tab.text ? tab.text : tab.group" (click) = "select(tab); $event.stopPropagation(); ">
                              {{ tab.text ? tab.text : tab.group }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
