import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DataGroup } from '../../data';
import { FormComponent} from '../form/form.component';
import { Nav, Param } from '../../widgets';
import { UtilsService } from 'src/app/services/utils.service';
import { DataService } from 'src/app/services/data.service';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-data-row',
  templateUrl: './data-row.component.html',
  styleUrls: ['../data-list/data-list.component.scss', './data-row.component.css']
})

export class DataRowComponent implements OnInit, OnChanges {
  @Input() widgetId: string;
  @Input() dataGroupItemInstance: DataGroup;

  @Input() item: any;
  @Input() rowIndexOffset: number;
  @Input() rowIndex: number;
  @Input() focus: string;
  @Input() last: boolean;
  @Input() selectedIndex: number;

  @Output() onPaste: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSetEditMode: EventEmitter<any> = new EventEmitter();
  @Output() onAddRow: EventEmitter<any> = new EventEmitter();
  @Output() onCollExp: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateMutations: EventEmitter<any> = new EventEmitter();

  @ViewChild('appForm') appForm: FormComponent;

  public nav: Nav;
  public isSelected: boolean = false;
  public highLight: boolean = false;

  public showNav: boolean = false;

  public component = this;

  private crud: string = null;

  constructor(
    private utilsService: UtilsService,
    private communicationService: CommunicationService,
    public dataService: DataService) { }

  select(params: Param[], dataItem?, data?) {
    this.onSelect.emit({
      params,
      dataItem,
      data
    });
  }

  paste(params: Param[], dataItem?, data?) {
    this.onPaste.emit({
      params,
      dataItem,
      data
    });
  }

  setEditMode(params: Param[], dataItem?, data?) {
    this.onSelect.emit({
      params,
      dataItem,
      data
    });

    this.onSetEditMode.emit({
      params,
      dataItem,
      data
    });
  }

  collExp(params: Param[], dataItem?, data?) {
    this.onCollExp.emit({
      params,
      dataItem,
      data
    });
  }

  addRow(params: Param[], dataItem?, data?) {
    this.onAddRow.emit({
      params,
      dataItem,
      data
    });
  }

  ngOnInit() {
    const msg = {
      title: "Verwijderen",
      text: "Deze regel verwijderen?",
      cancel: "Annuleer",
      primary: "Ok",
      action: 10
    };

    this.nav = {
      navId: 0,
      custom: {
        className: 'cmd icon xxs round ' + (this.dataGroupItemInstance.edit.type === 'inline' ? 'xfw3-bg-transparent' : 'xfw3-bg-secondary'),
        icon: 'fa-regular fa-plus',
        crud: null, // is altijd voor het niveau eronder.
        text: '',
        tabIndex: -1,
        info: 'addRow',
        accessLevel: this.dataGroupItemInstance.edit.accessLevel.crud,
        func: 'addRow',
        isInvisible: !this.dataGroupItemInstance.edit.create || this.dataGroupItemInstance.layout !== 'treeview',
        params: [
          {
            key: "index",
            val: this.rowIndexOffset + this.rowIndex
          }
        ]
      },
      primary: {
        className: 'cmd icon xxs round ' + (this.dataGroupItemInstance.edit.type === 'inline' ? 'xfw3-bg-transparent' : 'xfw3-bg-secondary'),
        icon: 'fa-regular fa-pencil',
        crud: 'update',
        text: '',
        info: 'Edit',
        accessLevel: this.dataGroupItemInstance.edit.accessLevel.crud,
        func: 'setEditMode',
        params: [
          {
            key: "index",
            val: this.rowIndexOffset + this.rowIndex
          }
        ],
        isInvisible: !this.dataGroupItemInstance.edit.update || this.dataGroupItemInstance.edit.updateMutations === 'direct'
      },
      cancel: {
        className: 'cmd icon xxs round ' + (this.dataGroupItemInstance.edit.type === 'inline' ? 'xfw3-bg-transparent' : 'xfw3-bg-secondary'),
        icon: 'fa-regular fa-xmark',
        crud: 'delete',
        text: '',
        tabIndex: -1,
        info: 'Verwijder',
        accessLevel: this.dataGroupItemInstance.edit.accessLevel.crud,
        func: 'cancel',
        isInvisible: !this.dataGroupItemInstance.edit.delete || (this.dataGroupItemInstance.edit.updateMutations === 'direct' ? false : !!this.item.crud),
        params: [
          {
            key: "index",
            val: this.rowIndexOffset + this.rowIndex
          }
        ],
        msg: ['save', 'direct'].includes(this.dataGroupItemInstance.edit.updateMutations) ? msg : null
      },
      secondary: {
        className: 'cmd icon xxs round ' + (this.dataGroupItemInstance.edit.type === 'inline' ? 'xfw3-bg-transparent' : 'xfw3-bg-secondary'),
        icon: 'fa-regular fa-rotate-left',
        text: '',
        tabIndex: -1,
        info: 'Undo',
        accessLevel: this.dataGroupItemInstance.edit.accessLevel.crud,
        func: 'cancel',
        isInvisible: this.dataGroupItemInstance.edit.updateMutations === 'direct' ? true : !this.item.crud,
        params: [
          {
            key: "index",
            val: this.rowIndexOffset + this.rowIndex
          }
        ]
      }
    };

    if (this.dataGroupItemInstance.rowOptions?.nav?.menu) {
      this.nav['menu'] = this.dataGroupItemInstance.rowOptions.nav.menu;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataGroupItemInstance.dataTable[0].data.length) {
      this.isSelected = this.dataGroupItemInstance.dataTable[0].data[this.rowIndexOffset + this.rowIndex].isSelected;
      this.highLight = this.dataGroupItemInstance.dataTable[0].data[this.rowIndexOffset + this.rowIndex].highLight;
      this.crud = this.dataGroupItemInstance.dataTable[0].data[this.rowIndexOffset + this.rowIndex].crud;

      if (this.crud && this.nav) {
        this.nav['cancel'].isInvisible = this.dataGroupItemInstance.edit.updateMutations !== 'batch' ? false : !!this.crud;
        this.nav['secondary'].isInvisible = this.dataGroupItemInstance.edit.updateMutations !== 'batch' ? true : !this.crud;
      }
    }
  }

  cancel(params, dataItem?) {
    let rowIndex = this.rowIndexOffset + this.rowIndex;

    if (this.dataGroupItemInstance.edit.updateMutations !== 'direct') {
      switch(dataItem.crud) {
        case 'create':
          this.dataGroupItemInstance.dataTable[0].data.splice(rowIndex, 1);
          rowIndex = rowIndex - 1 > 0 ? rowIndex - 1 : 0;
          break;
        case 'edit':
        case 'update':
          if (this.dataGroupItemInstance.dataTable[0].originalData.some(originalRow => originalRow.trackBy === this.dataGroupItemInstance.dataTable[0].data[rowIndex].trackBy)) {
            this.dataGroupItemInstance.dataTable[0].data[rowIndex] = structuredClone(this.dataGroupItemInstance.dataTable[0].originalData.find(originalRow => originalRow.trackBy === this.dataGroupItemInstance.dataTable[0].data[rowIndex].trackBy))
          } else {
            this.dataGroupItemInstance.dataTable[0].data.splice(rowIndex, 1);
            rowIndex = rowIndex - 1 > 0 ? rowIndex - 1 : 0;
            this.rowIndex = rowIndex - this.rowIndexOffset;
          }
        case 'delete':
          this.dataGroupItemInstance.dataTable[0].data.find(row => row.trackBy === dataItem.trackBy).crud = 'delete';

          console.log('delete', this.dataGroupItemInstance.edit, dataItem, this.dataGroupItemInstance.dataTable[0].data);


          if (this.dataGroupItemInstance.edit.updateMutations === 'save') {
            this.onUpdateMutations.emit();
          }
      }

      this.nav['cancel'].isInvisible = !!this.item.crud;
      this.nav['secondary'].isInvisible = !this.item.crud;

    } else {
      dataItem.crud = 'delete';
      this.dataGroupItemInstance.dataTable[0].data.find(row => row.trackBy === dataItem.trackBy).crud = 'delete';

      if (this.dataGroupItemInstance.edit.updateMutations === 'direct') {
        this.onUpdateMutations.emit();
      }
    }
  }

  updateMutations(params, dataItem?) {
    console.log(params, dataItem);

    this.onUpdateMutations.emit();
  }

  // checkRow(): WidgetState {
  //   if (this.appForm) {
  //     return this.appForm.onSubmit() ? WidgetState.OK : WidgetState.NOK;
  //   }

  //   return WidgetState.PENDING;
  // }

  checkCondClass(item, condFields) {
    return this.utilsService.checkCond(item, condFields);
  }
}
