import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value.filter(x => args[1].includes(args[0].split('.').reduce((lastResult, currentValue) => lastResult[currentValue],  x)));
  }
}
