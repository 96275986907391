import { Component, OnDestroy, OnInit } from "@angular/core";
import { SvgInhakerDesignToolComponent } from "./svg-inhaker-design-tool.component";
import { WorldObject } from "../inhaker-simulation/world";


@Component({
  template: ''
})
export abstract class SvgToolBaseComponent implements OnInit, OnDestroy {

  public abstract object: WorldObject;

  public abstract onDrag(element: WorldObject, component: string | undefined, x: number, y: number): boolean | void;
  public abstract onDragStart(element: WorldObject, component: string | undefined, x: number, y: number): [number, number] | void;
  public abstract onDragEnd(element: WorldObject, component: string | undefined, x: number, y: number): [number, number] | void;
  public abstract export(element: WorldObject): string;

  constructor(
    public parent: SvgInhakerDesignToolComponent
  ) { }

  public ngOnDestroy(): void {
    this.parent.unregister(this, this.object);
  }

  public ngOnInit(): void {
    this.parent.register(this, this.object);
  }
}
