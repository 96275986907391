import { Injectable } from '@angular/core';
import { ConfiguratorAddition, ConfiguratorOption, ConfiguratorProduct, ConfiguratorProductAddition, ConfiguratorResult, ConfiguratorResultProductOption, ConfiguratorRow, ConfiguratorRowOptionSelected, ProboProduct, State } from '../configurator';
import { DataGroup, DataTable } from '../data';
import { EventParams, Param, WidgetEvent } from '../widgets';
import { CommunicationService } from './communication.service';
import { ConfiguratorProboService } from './configurator-probo.service';
import { DataService } from './data.service';
import { NetworkService } from './network.service';
import { UtilsService } from './utils.service';
import { config } from 'process';
import { last } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorService {
  public originalProductJSON: any;

  public configurator = {
    state: {
      editMode: false,
      configuratorEditor: false,
      configured: false,
      nasConfigured: false,
      accessoryConfigured: false,
      calculated: false,
      calculating: false,
      priceTierSelected: false,
      confirmed: false
    }
  };

  public noDrag: boolean = false;

  constructor(
    private utilsService: UtilsService,
    private networkService: NetworkService,
    private dataService: DataService,
    private configuratorProboService: ConfiguratorProboService,
    private communicationService: CommunicationService
  ) { }

  getSuppliers(configurationJSON: any): number[] {
    // met deze functie bepalen we welke providers kunnen leveren. De lijst die je terugkrijgt is
    // een lijst met providers die alle opties kunnen leveren. Dus is er een optie gekozen die Probo
    // b.v. niet kan leveren, dan wordt Probo niet in de lijst opgenomen.`

    const suppliers = configurationJSON.products[0].options.map((item: any) => item.suppliers).filter(item => item);

    if (suppliers.length === 0)
        return [];

    return suppliers
      .reduce((selectedSuppliers, optionSuppliers) => selectedSuppliers.filter(supplier => optionSuppliers.includes(supplier)));
  }

  setConfiguration(state: string, product: ConfiguratorProduct, dataGroupItem, configuration: ConfiguratorResult) {
    const configurationOptions = configuration.products[0].options;
    const codes: string[] = configurationOptions.map(option => option.code);

    product.configuratorRows.forEach(row => {
      row.options.forEach(option => {
        this.utilsService.isSubsetMap([codes], [[option.code]]) &&
          (row.state.checked = option.isSelected = option.checked = true) &&
          !(row.state.expanded = row.state.hidden = row.state.inValid = option.state.hidden = false) &&
          configurationOptions.some(configOption => configOption.code === option.code && configOption.readOnly) && (row.type = 'readOnly');
      });
    });

    this.setInValidState(product);

    !product.configuratorRows.some(row =>!row.state.inValid && !row.state.checked) &&
      this.configurationState([{ key: state, val: true }], dataGroupItem);
  }

  setRowSelected(configuratorRow: ConfiguratorRow, options: ConfiguratorOption[])  {
    configuratorRow.optionSelected = {
      block: { name: options[0]?.textFields?.length ?
          options[0].textFields.map((field: string) => options[0].data[0][field]).join(', ') :
          options.filter(option => option.isSelected).reduce((names: string[], option, index) => {
            names.push((index === 0 ? option.block.name : option.block.name.toLowerCase()) + (option.value ? ` - ${option.value}${option.unit ?? ''}` : ''));
          return names;
        }, []).join(', ')
      },
      code: options.filter(option => option.isSelected).map(option => option.code).join(','),
      suppliers: [...new Set(configuratorRow.options.filter((item: any) => item.id >  1 && item.selected).flatMap((item: any) => item.suppliers))]
    }
  }

  refreshConfiguration(product, dataGroupItem: DataGroup, state: string) {
    const configuration = dataGroupItem.dataTable[0].data[0].configurationJSON;
    configuration.products[0].options = [];

    product.configuratorRows.forEach(row => {
      row.options.filter(option => option.isSelected && !option.state.hidden).forEach(option => {

        const productOption = {
          code: option.code,
          readOnly: row.type === 'readOnly',
          itemIndex: option.itemIndex,
          data: option.data,
          value: option.value ?? option.default_value,
          price: option.price,
          purchasePrice: option.purchasePrice,
          dropship: option.dropship,
          suppliers: option.suppliers,
          info: {
            question: row.block.name,
            answer: option?.textFields?.length ?
              option.textFields.map((field: string) => option.data[0][field]).join(', ') :
              option.block.name + (option.value ? ` - ${option.value}${option.unit ?? ''}` : '')
          }
        };

        // Met onderstaande functie zet ik alleen de properties met een waarde in het object.
        // undefined of null properties worden niet in het object gezet.

        configuration.products[0].options.push(Object.keys(productOption)
          .filter(property => productOption[property]).reduce((accum, current) =>
          ( {...accum, [current]: productOption[current]} ), {}) as ConfiguratorResultProductOption);
      });
    });

    this.setInValidState(product);

    configuration.suppliers = this.getSuppliers(configuration);

    !product.configuratorRows.some(row =>!row.state.inValid && !row.state.checked) &&
      this.configurationState([{ key: state, val: true }], dataGroupItem);
  }

  configurationState(params: Param[], dataGroupItem: DataGroup) {
    params.forEach(param => {
      if (['configured'].includes(param.key) && param.val) {
          dataGroupItem.dataTable[0].data[0].crud = 'edit'; // na het updaten van de url gaat de crud weg.

          this.communicationService.performAction({
            info: 'configured',
            widgetGroup: [dataGroupItem.widgetId],
            event: WidgetEvent.SAVE
          });
      }
    });
  }

  /**
   * Deze methode loopt aan de hand van de resultaatset door alle rows en options en
   * checkt aan de hand van de conditioneleCodes of een row of option hidden (hidden = true) of visible (hidden = false) moet zijn.
   *
   * @param product => this.product, het XFW product
   */

  setInValidState(product: ConfiguratorProduct) {
    const codes: string[] = [];

    product.configuratorRows.filter(row => !row.state.hidden).forEach(row => {
      row.options.some(option => option.isSelected) &&
        row.options.filter(option => option.isSelected).forEach(option => {
          !codes.includes(option.code) && codes.push(option.code);
        });
    });

    product.configuratorRows.forEach(configuratorRow => {
      configuratorRow.state.inValid = true;

      configuratorRow.options ||= [];

      configuratorRow.options.forEach(option => {
        option.state.hidden = true;

        option.conditionalCodesExt?.length ?
          option.conditionalCodesExt.forEach((optionConditionalCode) => {
            this.utilsService.isSubsetMap([codes], optionConditionalCode.codes) &&
                (configuratorRow.state.inValid = option.state.hidden = false);
          })
          :
          configuratorRow.state.inValid = option.state.hidden = false;
      });

      configuratorRow.state.inValid && (configuratorRow.state.hidden = true);

      configuratorRow.state.checked && !configuratorRow.state.inValid && !(configuratorRow.state.hidden = false) &&
        this.setRowSelected(configuratorRow, configuratorRow.options);
    });

    console.log(structuredClone(codes), structuredClone(product));
  }

  /**
   * Zoekt de volgende row op waar nog geen optie is geselecteerd en toont de opties (status = expanded)
   */

  selectNextUndecided(product: ConfiguratorProduct, document) {
    if (product.configuratorRows.some(row => !row.state.hidden && !row.state.checked && row.state.expanded)) return;

    const foundRow = product.configuratorRows.find(row => !row.state.inValid && !row.state.checked);
    foundRow && (foundRow.state.hidden = !(foundRow.state.expanded = true)) && document &&
      setTimeout(() => {
        const height = document.getElementById([foundRow.code, foundRow.itemIndex].join('_')).clientHeight;
        const scrollTop = window.scrollY;

        window.scrollTo({ left: 0, top: scrollTop + height, behavior: 'smooth' });
      });
  }

  mergeProduct(product: ConfiguratorProduct, additions: ConfiguratorProductAddition[]) {
    const addSupplierToOptions = (row: ConfiguratorRow, exceptions: string[], suppliers: string[], additions: ConfiguratorAddition[]) => {
      row.options.forEach(option => {
        (!exceptions.includes(option.code)) && (option.suppliers = [...option.suppliers, ...suppliers]);
      });

      additions.map(addition => addition.code).includes(row.code) &&
        additions.filter(addition => addition.code === row.code).forEach(addition => {
          addition.options.forEach(option => {
            row.options.push(option);
          });
        });
    }

    additions.forEach(addition => {
      product.code === addition.code &&
        product.rows.forEach(row => {
          !addition.addSuppliers.exceptions.includes(row.code) &&
            addSupplierToOptions(row, addition.addSuppliers.exceptions, addition.addSuppliers.suppliers, addition.addSuppliers.additions);
        });
    });
  }

  getNasData(dataTable: DataTable) {
    const nasData: any[] = [];

    dataTable.data.forEach(row => {
      const nasDataRow: any = {};
      dataTable.fields[0].fieldGroup
        .filter(fieldGroup => !fieldGroup.xfw.hidden && !fieldGroup.templateOptions.readOnly).map(fieldGroup => fieldGroup.key)
        .forEach(key => {
          nasDataRow[key] = row[key];
        });

      nasData.push(nasDataRow);
    });

    return nasData;
  }

  getNewRow(dataGroupItemInstance: DataGroup): any {
    const dataItem: any = {};

    dataGroupItemInstance.dataTable[0].fields.forEach(fields => {
      fields.fieldGroup.forEach(field => {
        ![undefined, null].includes(field.xfw?.inputData?.defaultItem) ?
          (dataItem[field.key] = field.xfw.inputData.defaultItem)
          :
          dataItem[field.key] = null;
      });
    });

    dataItem.block = {
      name: dataItem.name,
      description: dataItem.description
    };

    dataItem.crud = 'create';
    dataItem.trackBy = dataGroupItemInstance.dataTable[0].data.length + 1;
    dataItem.isSelected = true;
    dataItem.edit = true;

    return dataItem;
  }

  getNewOption(dataGroupItemInstance: DataGroup): any {
    const dataItem: any = {};

    dataGroupItemInstance.dataTable[0].fields.forEach(fields => {
      fields.fieldGroup.forEach(field => {
        ![undefined, null].includes(field.xfw?.inputData?.defaultItem) ?
          (dataItem[field.key] = field.xfw.inputData.defaultItem)
          :
          field.key.includes('fileJSON') ? (dataItem[field.key] = {
              schema: 'Configurator',
              path: 'https://xfw3.b-cdn.net/configurator/',
              imgPath: 'https://xfw3.b-cdn.net/configurator/thumb/',
              imgAndThumbs: [{
                styling: {}
              }],
              fileUid: Date.now().toString()
            })
            :
            dataItem[field.key] = null;
      });
    });

    dataItem.block = {
      name: dataItem.name,
      description: dataItem.description
    };

    dataItem.crud = 'create';
    dataItem.trackBy = dataGroupItemInstance.dataTable[0].data.length;
    dataItem.isSelected = true;
    dataItem.edit = true;

    return dataItem;
  }

  selectExcludes(product: ConfiguratorProduct, row: ConfiguratorRow, option: ConfiguratorOption, eventParams: EventParams) {
    const optionsMap = product.rows.flatMap(row => row.options).reduce((map, option) => {
      map.set(option.code, option);
      return map;
    }, new Map());

    option.state.dependent = true;

    product?.excludes.forEach((exclude: string[][]) => {
      [0,1].forEach(index => {
        exclude[index].includes(option.code) && exclude[1-index].forEach(excludeCode => {
          optionsMap.get(excludeCode) && (optionsMap.get(excludeCode).state.excluded = true);
        });
      });
    });
  }

  selectConditionalCodes(product: ConfiguratorProduct, row: ConfiguratorRow, option: ConfiguratorOption, eventParams: EventParams) {
    if (!row.state.isSelected) {
      option.state.dependent = option.state.dependent ? false : true;
      return;
    }

    const allConditionalCodes: string[] = [];

    allConditionalCodes.push(option.code);

    allConditionalCodes.push(...Array.from(new Set(option.conditionalCodesExt.flatMap(conditionalCodeExt =>
      conditionalCodeExt.codes.flatMap(code => code)
    ))));

    product.rows.filter(row => !row.state.isSelected).flatMap(row => row.options).forEach(rowOption => rowOption.state.dependent = false);

    product.rows.forEach(row => {
      row.options.forEach(rowOption => {
        rowOption.conditionalCodesExt.forEach(conditionalCodeExt => {
          conditionalCodeExt.codes.forEach(codes => {
            codes.forEach(code => {
              allConditionalCodes.includes(code) && (conditionalCodeExt.itemIndex > option.itemIndex) && allConditionalCodes.push(rowOption.code);
            });
          });
        });

        allConditionalCodes.includes(rowOption.code) && (rowOption.state.dependent = true);
      });
    });
  }

  selectSets(product: ConfiguratorProduct, row: ConfiguratorRow, option: ConfiguratorOption, eventParams: EventParams) {
    const allSets: string[] = [];

    product?.rowSets?.flatMap(rowSet => rowSet.sets).forEach(set => {
      set.includes(option.code) && set.forEach(singleSet => allSets.push(singleSet));
    });

    product.rows.flatMap(row => row.options).forEach(rowOption => {
      allSets.includes(rowOption.code) && (rowOption.state.linked = true);
    });

  }
}
