<div class="menu xfw3-grid-container xfw3-px" divBreakpointClass [style.width.%]="communicationService.panels.sidebarSetting.main">
  <div class="xfw3-grid-col-container">
    @if (this.domainConfigService.domainConfig.topNav) {
      <div [ngClass]="[dataService.blockLayoutGridData[domainConfigService.domainConfig.menu.blockLayoutGrid[0]]]">
        <app-nav *ngIf="user.auth"
          [widgetId]="domainConfigService.domainConfig.menu[user.auth]"
          [src]="domainConfigService.domainConfig.menu[user.auth]"
          [nav]=""
          [className]="'main'"/>
      </div>
    }
  </div>
</div>
