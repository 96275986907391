<div class="">
  <h2 *ngIf="contentJSON.title.length">
    <ng-container *ngFor="let item of contentJSON.title; trackBy: trackByFn">
      <span *ngIf="item.className" [class]="item.className" [innerHTML]="item.text ? item.text : '' | safeHtml"></span>
      <ng-container *ngIf="!item.className"><span [innerHTML]="item.text ? item.text : '' | safeHtml"></span></ng-container>
    </ng-container>
  </h2>
  <ng-container *ngFor="let col of contentJSON.cols[0].col; trackBy: trackByFn">
      <ng-container *ngFor="let item of col.body">
        <h2 *ngIf="item.title || item.subTitle">
            <span [ngClass]="[item.className ? item.className : '']" [innerHTML]="item.title ? item.title : item.subTitle | safeHtml"></span>
        </h2>
        <p *ngIf="item.intro" class="intro" [ngClass]="[item.className ? item.className : '']">
          <span [innerHTML]="item.intro | safeHtml"></span>
        </p>
        <p *ngIf="item.text" [ngClass]="[item.className ? item.className : '']" [innerHTML]="item.text | safeHtml">
        </p>

        <ng-container *ngIf="item.json">
          <app-json-viewer [fields]="item.fields" [data]="item.json"></app-json-viewer>
        </ng-container>

        <ng-container *ngIf="item.nav">
          <div class="xfw-rowSpacer"></div>
          <app-cmd
              [nav]="item.nav"
              [navList]="['custom','primary', 'menu']"
              [navVisible]="3"
              [collapsed]="false">
          </app-cmd>
        </ng-container>

        <ul *ngIf="item.list" [ngClass]="[item.className ? item.className : '']">
          <li *ngFor="let text of item.list; trackBy: trackByFn" [innerHTML]="text | safeHtml"></li>
        </ul>
        <div *ngIf="item.img?.length"
            [style.background-size]="item.size ? item.size : 'cover'"
            [style.width]="item.width ? item.width : '100%'"
            [style.height]="item.img[0].height"
            [style.min-height]="item.img[0].minheight"
            [style.background-image]="'url(' + item.img[0].url + ')'">
        </div>
        <div *ngIf="item.svg?.length"
          style="
            background-size: auto;
            background-repeat: no-repeat;
            background-position: center;
            float: left;"
            [style.width]="item.svg[0].width"
            [style.height]="item.svg[0].height"
            [style.background-image]="'url(' + item.svg[0].url + ')'">
        </div>
        <app-cmd
            *ngIf="item.nav"
            [nav]="item.nav"
            [navList]="['custom']"
            [navVisible]="0"
            [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
            [collapsed]="false">
        </app-cmd>
      </ng-container>
  </ng-container>
</div>
