<div class="presentation">
  <div class="xfw3-grid-container" divBreakpointClass *ngIf="contentJSON[pageId].type === 'title'">
    <div class="xfw3-grid-col-container">
      <div class="g-col-18">
        <div class="title">
          {{ contentJSON[pageId].title }}
        </div>
        <div class="subTitle">
          {{ contentJSON[pageId].subTitle }}
        </div>
      </div>
    </div>
  </div>
  <div class="xfw3-grid-container" divBreakpointClass *ngIf="contentJSON[pageId].type === 'intro'">
    <div class="xfw3-grid-col-container">
      <div class="g-offset-3 g-col-12">
        <div *ngFor="let row of contentJSON[pageId].left" [ngClass]="['xfw3-fade', 'left', obj.index === row.index ? 'duration-' + row.duration : '']" >
          <div class="text">
            {{ row.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="xfw3-grid-container" divBreakpointClass *ngIf="['video', 'img'].includes(contentJSON[pageId].type)">
    <div class="xfw3-grid-col-container">
      <div class="g-col-4">
        <div *ngFor="let row of contentJSON[pageId].left" [ngClass]="['xfw3-fade', 'right', obj.index === row.index ? 'duration-' + row.duration : '']">
          <div class="text">
            {{ row.text }}
          </div>
        </div>
      </div>
      <div class="g-col-10">
        <div *ngFor="let row of contentJSON[pageId].middle">
          <video *ngIf="row.video" [src]="row.video" class="video" [muted]="'muted'" controls autoplay></video>
          <img *ngIf="row.img" [src]="row.img" class="img">
        </div>
      </div>
      <div class="g-col-4">
        <div *ngFor="let row of contentJSON[pageId].right" [ngClass]="['xfw3-fade', 'left', obj.index === row.index ? 'duration-'+row.duration : '', ]" >
          <div class="text">
            {{ row.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="controls">
  <div class="wrapper">
    <div *ngFor="let page of contentJSON; let index = index;" [ngClass]="['control', index === pageId ? 'selected' : '']"
      (click)="showPage(index);">
      {{ page.id }}
    </div>
  </div>
</div>
