import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FormComponent } from './widget/form/form.component';
import { DataListComponent } from './widget/data-list/data-list.component';
import { DataRowComponent } from './widget/data-row/data-row.component';
import { SafeHtmlPipe } from './widget/pipe/safe-html.pipe';
import { TextLinkParsePipe } from './widget/pipe/text-link-parse.pipe';
import { AppRoutingModule } from './app-routing.module';
import { PageComponent } from './layout/page/page.component';
import { CmdComponent } from './widget/cmd/cmd.component';
import { PopupFormComponent } from './widget/popup-form/popup-form.component';
import { MenuComponent } from './widget/menu/menu.component';
import { DataGroupComponent } from './widget/data-group/data-group.component';
import { TabstripComponent } from './widget/tabstrip/tabstrip.component';
import { UploadComponent } from './widget/upload/upload.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DataCardsComponent } from './widget/data-cards/data-cards.component';
import { DataCardComponent } from './widget/data-card/data-card.component';
import { ClickOutsideDirective } from './widget/click-outside/click-outside.directive';
import { DatePipe, DecimalPipe, JsonPipe, PercentPipe, registerLocaleData, SlicePipe } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { DataItemComponent } from './widget/data-item/data-item.component';
import { DynamicPipe } from './widget/pipe/dynamic.pipe';
import { MainMenuComponent } from './widget/main-menu/main-menu.component';
import { HttpInterceptProviders } from './interceptors';
import { LoginComponent } from './widget/login/login.component';
import { MainSearchComponent } from './widget/main-search/main-search.component';
import { GMapComponent } from './widget/gmap/gmap.component';
import { DataGenericComponent } from './widget/data-generic/data-generic.component';
import { OverlayComponent } from './layout/overlay/overlay.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NotificationComponent } from './widget/notification/notification.component';
import { FilterPipe } from './widget/pipe/filter.pipe';
import { ExcludePipe } from './widget/pipe/exclude.pipe';
import { PrimaryKeyPipe } from './widget/pipe/primary-key.pipe';
import { DataTabsComponent } from './widget/data-tabs/data-tabs.component';
import { DataTabComponent } from './widget/data-tab/data-tab.component';
import { HorizontalScrollDirective } from './widget/horizontal-scroll/horizontal-scroll.directive';
import { PreventHorizontalScrollDirective } from './widget/horizontal-scroll/prevent-horizontal-scroll.directive';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { DataGroupMatrixComponent } from './widget/data-group-matrix/data-group-matrix.component';
import { MatrixComponent } from './widget/content-block/matrix/matrix.component';
import { DataGroupMatrixColComponent } from './widget/data-group-matrix-col/data-group-matrix-col.component';
import { DataGroupMatrixColItemComponent } from './widget/data-group-matrix-col-item/data-group-matrix-col-item.component';
import { DataItemContentComponent } from './widget/data-item-content/data-item-content.component';
import { NgIfPipe } from './widget/pipe/ng-if.pipe';
import { DataGroupMatrixHeaderComponent } from './widget/data-group-matrix-header/data-group-matrix-header.component';
import { DataGroupMatrixGenericComponent } from './widget/data-group-matrix-generic/data-group-matrix-generic.component';
import { WindowComponent } from './layout/window/window.component';
import { HelpWindowComponent } from './layout/help-window/help-window.component';
import { DataGmapComponent } from './widget/data-gmap/data-gmap.component';
import { OptionListComponent } from './widget/option-list/option-list.component';
import { PopupComponent } from './layout/popup/popup.component';
import { DataContentComponent } from './widget/data-content/data-content.component';
import { DataContentRowComponent } from './widget/data-content-row/data-content-row.component';
import { ImgListComponent } from './widget/img-list/img-list.component';
import { DataPlannerComponent } from './widget/data-planner/data-planner.component';
import { DataGenericHeaderComponent } from './widget/data-generic-header/data-generic-header.component';
import { DataGroupItemComponent } from './widget/data-group-item/data-group-item.component';
import { PaidPageComponent } from './layout/paid-page/paid-page.component';
import { CarouselComponent } from './widget/carousel/carousel.component';
import { PopupImgViewerComponent } from './widget/popup-img-viewer/popup-img-viewer.component';
import { DataImgComponent } from './widget/data-img/data-img.component';
import { ContentBlockComponent } from './widget/content-block/content-block.component';
import { NoteComponent } from './widget/content-block/note/note.component';
import { LinkPreviewComponent } from './widget/content-block/link-preview/link-preview.component';
import { FullWidthComponent } from './widget/content-block/full-width/full-width.component';
import { CardsComponent } from './widget/content-block/cards/cards.component';
import { TitleComponent } from './widget/content-block/title/title.component';
import { InfoComponent } from './widget/content-block/info/info.component';
import { DataFormComponent } from './widget/data-form/data-form.component';
import { DataImgViewerComponent } from './widget/data-img-viewer/data-img-viewer.component';
import { CollapseListComponent } from './widget/content-block/collapse-list/collapse-list.component';
import { ActionGroupComponent } from './action/action-group/action-group.component';
import { ActionGroupItemComponent } from './action/action-group-item/action-group-item.component';
import { ActionPopupComponent } from './action/action-popup/action-popup.component';
import { RedirectComponent } from './redirect/redirect.component';
import { DataLoginComponent } from './widget/data-login/data-login.component';
import { TableComponent } from './widget/content-block/table/table.component';
import { SwitchComponent } from './widget/content-block/switch/switch.component';
import { FooterComponent } from './widget/content-block/footer/footer.component';
import { HeaderComponent } from './widget/content-block/header/header.component';
import { LanguageComponent } from './widget/language/language.component';
import { ContentListComponent } from './widget/content-block/content-list/content-list.component';
import { SvgSelectComponent } from './widget/svg-select/svg-select.component';
import { SafeUrlPipe } from './widget/pipe/safe-url.pipe';
import { NavComponent } from './widget/nav/nav.component';
import { MultiColComponent } from './widget/content-block/multi-col/multi-col.component';
import { DataSearchListComponent } from './widget/data-search-list/data-search-list.component';
import { AccessLevelDirective } from './widget/access-level/access-level.directive';
import { DataSvgComponent } from './widget/data-svg/data-svg.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FibaroComponent } from './widget/fibaro/fibaro.component';
import { DataGenericFooterComponent } from './widget/data-generic-footer/data-generic-footer.component';
import { AppendPipe } from './widget/pipe/append.pipe';
import { DataGroupMatrixStaticColComponent } from './widget/data-group-matrix-static-col/data-group-matrix-static-col.component';
import { DivBreakpointClassDirective } from './widget/div-breakpoint-class/div-breakpoint-class.directive';
import { FusionViewerComponent } from './widget/fusion-viewer/fusion-viewer.component';
import { FusionComponent } from './widget/content-block/fusion/fusion.component';
import { EditBlockComponent } from './widget/content-block/edit-block/edit-block.component';
import { ConfiguratorComponent } from './product-config/configurator/configurator.component';
import { ConfiguratorRowComponent } from './product-config/configurator-row/configurator-row.component';
import { ConfiguratorOptionComponent } from './product-config/configurator-option/configurator-option.component';
import { DataMatrixComponent } from './widget/data-matrix/data-matrix.component';
import { UniqueItemsPipe } from './widget/pipe/unique-items.pipe';
import { SpinnerComponent } from './widget/spinner/spinner.component';
import { TabcolsComponent } from './widget/tabcols/tabcols.component';
import { MultiSelectComponent } from './widget/multi-select/multi-select.component';
import { PropertyValuePipe } from './widget/pipe/property-value.pipe';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { QuillModule } from 'ngx-quill';
import { HasFlagsPipe } from './widget/pipe/has-flags.pipe';
import { DatePickerComponent } from './widget/date-picker/date-picker.component';
import { MaskDirective } from './widget/mask/mask.directive';
import { SketchFabComponent } from './widget/sketch-fab/sketch-fab.component';
import { DataSketchFabComponent } from './widget/data-sketch-fab/data-sketch-fab.component';
import { TagsComponent } from './widget/tags/tags.component';
import { IssuuComponent } from './widget/content-block/issuu/issuu.component';
import { ContextAwareComponent } from './widget/context-aware/context-aware.component';
import { ActionItemComponent } from './action/action-item/action-item.component';
import { ActionSectionComponent } from './action/action-section/action-section.component';
import { ActionItemChatComponent } from './action/action-item-chat/action-item-chat.component';
import { InViewDirective } from './widget/in-view/in-view.directive';
import { TreeViewComponent } from './widget/tree-view/tree-view.component';
import { ConfiguratorListComponent } from './product-config/configurator-list/configurator-list.component';
import { PopupWindowComponent } from './widget/popup-window/popup-window.component';
import { PropertyNotEmptyPipe } from './widget/pipe/property-not-empty.pipe';
import { DateListPipe } from './widget/pipe/date-list.pipe';
import { ComponentComponentList } from './job/component-list/component-list.component';
import { PopoverComponent } from './widget/popover/popover.component';
import { DurationInputComponent } from './widget/duration-input/duration-input.component';
import { ScheduleComponent } from './schedule/schedule/schedule.component';
import { MinutesToTimePipe } from './widget/pipe/minutes-to-time.pipe';
import { ScrollTestComponent } from './schedule/scroll-test/scroll-test.component';
import { ScrollSensorComponent } from './schedule/scroll-sensor/scroll-sensor.component';
import { JobOptionListComponent } from './job/job-option-list/job-option-list.component';
import { JobContainerComponent } from './job/job-container/job-container.component';
import { SketchfabComponent } from './widget/content-block/sketchfab/sketchfab.component';
import { PageNavComponent } from './widget/page-nav/page-nav.component';
import { FocusOnRenderDirective } from './focus-on-render.directive';
import { HtmlAttributeChangeDirective } from './html-attribute-change.directive';
import { millisToHHMMPipe } from './widget/pipe/millis-to-HHMM.pipe';
import { DataTreeViewComponent } from './widget/data-tree-view/data-tree-view.component';
import { DecimalInputDirective } from './decimal-input.directive';
import { PlanningGroupItemComponent } from './planning/planning-group-item/planning-group-item.component';
import { DataFilterPipe } from './widget/pipe/data-filter.pipe';
import { PrimaryKeyObjectPipe } from './widget/pipe/primary-key-object.pipe';
import { GoogleMapsModule } from '@angular/google-maps';
import { JsonViewerComponent } from './widget/json-viewer/json-viewer.component';
import { KeyValuePipe } from './widget/pipe/key-value.pipe';
import { DataPlannerResourceComponent } from './widget/data-planner/data-planner-resource/data-planner-resource.component';
import { Tabstrip2Component } from './widget/tabstrip2/tabstrip2.component';
import { DebugInfoComponent } from './debug-info/debug-info.component';
import { ActionButtonsComponent } from './action/action-buttons/action-buttons.component';
import { DragDirective } from './drag.directive';
import { DropDirective } from './drop.directive';
import { InhakerDesignToolModule } from './inhaker-design-tool/inhaker-design-tool.module';
import { OptionInfoComponent } from './job/option-info/option-info.component';
import { ParamsFromKeyPipe } from './widget/pipe/params-from-key.pipe';
import { CondFieldPipe } from './widget/pipe/cond-field.pipe';
import { ReportComponent } from './layout/report/report.component';
import { ReportPriceTierComponent } from './layout/report/report-price-tier/report-price-tier.component';
import { ReportNasComponent } from './layout/report/report-nas/report-nas.component';
import { ReportComponentComponent } from './layout/report/report-component/report-component.component';
import { ReportOptionComponent } from './layout/report/report-option/report-option.component';
import { ReportRelationsComponent } from './layout/report/report-relations/report-relations.component';
import { ReportQuoteComponent } from './layout/report/report-quote/report-quote.component';
import { ReportItemComponent } from './layout/report/report-item/report-item.component';
import { ImageUploaderComponent } from './widget/image-uploader/image-uploader.component';
import { PresentationComponent } from './layout/presentation/presentation.component';
import { DataPlannerBarsViewComponent } from './widget/data-planner/data-planner-bars-view/data-planner-bars-view.component';
import { DataPlannerTaskViewComponent } from './widget/data-planner/data-planner-task-view/data-planner-task-view.component';
import { DataPlannerTaskComponent } from './widget/data-planner/data-planner-task/data-planner-task.component';
import { DataCardsGroupComponent } from './widget/data-cards-group/data-cards-group.component';
import { ResizedDirective } from './widget/resized/resized.directive';
import { VirtualScrollComponent } from './widget/virtual-scroll/virtual-scroll.component';
import { ColToGColPipe } from './widget/pipe/col-to-g-col.pipe';
import { SecondsToHHMMPipe } from './widget/pipe/seconds-to-hhmm.pipe';
import { DataBreadcrumbsComponent } from './widget/data-breadcrumbs/data-breadcrumbs.component';
import { BreadcrumbsComponent } from './widget/breadcrumbs/breadcrumbs.component';
import { HasOverlapPipe } from './widget/pipe/has-overlap.pipe';
import { CheckedPipe } from './widget/pipe/checked.pipe';
import { EditLinkComponent } from './product-config/edit-link/edit-link.component';
import { EditRowComponent } from './product-config/edit-row/edit-row.component';
import { EditOptionComponent } from './product-config/edit-option/edit-option.component';
import { EditListComponent } from './product-config/edit-list/edit-list.component';
import { ConfiguratorInputComponent } from './product-config/configurator-input/configurator-input.component';
import { ConditionalCodesComponent } from "./product-config/conditional-codes/conditional-codes.component";
import { PageContainerComponent } from './layout/page-container/page-container.component';

const config: SocketIoConfig = { url: isDevMode() ? 'http://localhost:8081' : 'https://xfw-hub.sflex.nl', options: {} };

registerLocaleData(localeNl, 'nl');

@NgModule({
    declarations: [
        AppComponent,
        FormComponent,
        DataListComponent,
        DataRowComponent,
        SafeHtmlPipe,
        PageComponent,
        CmdComponent,
        PopupFormComponent,
        MenuComponent,
        DataGroupComponent,
        TabstripComponent,
        DataCardsComponent,
        DataCardComponent,
        ClickOutsideDirective,
        DataItemComponent,
        DynamicPipe,
        MainMenuComponent,
        LoginComponent,
        NotificationComponent,
        MainSearchComponent,
        GMapComponent,
        DataGenericComponent,
        OverlayComponent,
        FilterPipe,
        ExcludePipe,
        PrimaryKeyPipe,
        DataTabsComponent,
        DataTabComponent,
        HorizontalScrollDirective,
        PreventHorizontalScrollDirective,
        MatrixComponent,
        DataGroupMatrixGenericComponent,
        DataGroupMatrixComponent,
        DataGroupMatrixHeaderComponent,
        DataGroupMatrixColComponent,
        DataGroupMatrixColItemComponent,
        DataItemContentComponent,
        NgIfPipe,
        WindowComponent,
        HelpWindowComponent,
        DataGmapComponent,
        OptionListComponent,
        PopupComponent,
        DataContentComponent,
        DataContentRowComponent,
        ImgListComponent,
        DataPlannerComponent,
        DataGenericHeaderComponent,
        DataGroupItemComponent,
        PaidPageComponent,
        CarouselComponent,
        PopupImgViewerComponent,
        DataImgComponent,
        ContentBlockComponent,
        NoteComponent,
        LinkPreviewComponent,
        FullWidthComponent,
        CardsComponent,
        TitleComponent,
        InfoComponent,
        DataFormComponent,
        DataImgViewerComponent,
        CollapseListComponent,
        ActionGroupComponent,
        ActionGroupItemComponent,
        ActionPopupComponent,
        RedirectComponent,
        UploadComponent,
        DataLoginComponent,
        TableComponent,
        SwitchComponent,
        FooterComponent,
        HeaderComponent,
        LanguageComponent,
        ContentListComponent,
        SvgSelectComponent,
        SafeUrlPipe,
        NavComponent,
        MultiColComponent,
        DataSearchListComponent,
        AccessLevelDirective,
        DataSvgComponent,
        FibaroComponent,
        DataGenericFooterComponent,
        AppendPipe,
        DataGroupMatrixStaticColComponent,
        DivBreakpointClassDirective,
        FusionViewerComponent,
        FusionComponent,
        EditBlockComponent,
        ConfiguratorComponent,
        ConfiguratorRowComponent,
        ConfiguratorOptionComponent,
        DataMatrixComponent,
        UniqueItemsPipe,
        SpinnerComponent,
        TabcolsComponent,
        MultiSelectComponent,
        PropertyValuePipe,
        HasFlagsPipe,
        DatePickerComponent,
        MaskDirective,
        SketchFabComponent,
        DataSketchFabComponent,
        TagsComponent,
        IssuuComponent,
        ContextAwareComponent,
        ActionItemComponent,
        ActionSectionComponent,
        ActionItemChatComponent,
        InViewDirective,
        TreeViewComponent,
        ConfiguratorListComponent,
        PopupWindowComponent,
        PropertyNotEmptyPipe,
        DateListPipe,
        ComponentComponentList,
        PopoverComponent,
        DurationInputComponent,
        ScheduleComponent,
        MinutesToTimePipe,
        ScrollTestComponent,
        ScrollSensorComponent,
        JobOptionListComponent,
        JobContainerComponent,
        SketchfabComponent,
        PageNavComponent,
        FocusOnRenderDirective,
        HtmlAttributeChangeDirective,
        millisToHHMMPipe,
        DataTreeViewComponent,
        DecimalInputDirective,
        PlanningGroupItemComponent,
        DataFilterPipe,
        PrimaryKeyObjectPipe,
        JsonViewerComponent,
        KeyValuePipe,
        DataPlannerResourceComponent,
        Tabstrip2Component,
        DebugInfoComponent,
        ActionButtonsComponent,
        DragDirective,
        DropDirective,
        OptionInfoComponent,
        ParamsFromKeyPipe,
        CondFieldPipe,
        ReportComponent,
        ReportPriceTierComponent,
        ReportNasComponent,
        ReportComponentComponent,
        ReportOptionComponent,
        ReportRelationsComponent,
        ReportQuoteComponent,
        ReportItemComponent,
        ImageUploaderComponent,
        PresentationComponent,
        DataPlannerBarsViewComponent,
        DataPlannerTaskViewComponent,
        DataPlannerTaskComponent,
        DataCardsGroupComponent,
        ResizedDirective,
        VirtualScrollComponent,
        ColToGColPipe,
        DataBreadcrumbsComponent,
        BreadcrumbsComponent,
        HasOverlapPipe,
        CheckedPipe,
        EditLinkComponent,
        EditRowComponent,
        EditOptionComponent,
        EditListComponent,
        ConditionalCodesComponent,
        ConfiguratorInputComponent,
        PageContainerComponent
    ],
    providers: [
        HttpInterceptProviders,
        PercentPipe,
        DecimalPipe,
        DatePipe,
        JsonPipe,
        DatePipe,
        SlicePipe,
        AppendPipe,
        DateListPipe,
        PropertyValuePipe,
        ExcludePipe,
        FilterPipe,
        SecondsToHHMMPipe,
        {
            provide: LOCALE_ID,
            useValue: 'nl'
        },
        {
            provide: Window,
            useValue: window
        }
    ],
    bootstrap: [AppComponent],
    imports: [
        InhakerDesignToolModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ReactiveFormsModule,
        FormsModule,
        AppRoutingModule,
        NgbModule,
        NgbPopoverModule,
        DragDropModule,
        NgxJsonViewerModule,
        SocketIoModule.forRoot(config),
        ClipboardModule,
        // AngularEditorModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // grouped commands
                    [{ 'header': [1, 2, 3, false] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ]
            }
        }),
        GoogleMapsModule,
        TextLinkParsePipe
    ]
})

export class AppModule { }

const editorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '10rem',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: '',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '',
  fonts: [],
  customClasses: [],
  uploadUrl: '',
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent',
      'insertUnorderedList',
      'insertOrderedList',
      'heading',
      'fontName'],
    ['fontSize',
      'textColor',
      'backgroundColor',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule']
  ]
};
