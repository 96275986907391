import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { ConfiguratorProductAddition } from "../configurator";
import { DataTable } from "../data";
import { Param } from "../widgets";
import { DataService } from "./data.service";
import { NetworkService } from "./network.service";
import { ConfiguratorProboService } from "./configurator-probo.service";
import { ConfiguratorPrintcomService } from "./configurator-printcom.service";

@Injectable({
  providedIn: "root",
})
export class BatchUpdateService {
  public subscription: Subscription;
  public productJSON: any;

  constructor(
    private dataService: DataService,
    private configuratorProboService: ConfiguratorProboService,
    private networkService: NetworkService,
    private configuratorPrintCom: ConfiguratorPrintcomService
  ) { }

  getProductForSupplier(supplier: 'probo' | 'printcom', code: string, additions?: ConfiguratorProductAddition[]) {
    switch (supplier) {
      case 'probo':
        return this.configuratorProboService.getProduct(code, additions);

      case 'printcom':
        return this.configuratorPrintCom.getProduct(code);
    }
  }

  async updateProductJSON(supplier: 'probo' | 'printcom', code: string) {
    const dataTable = await this.modifyDataTable(
      'getProductAdditions',
      'productAdditions',
      [
        {
          key: 'code',
          val: [ supplier, code ].join('.')
        }
      ],
      0,
      null
    );

    let additions: ConfiguratorProductAddition[] = [];
    if (dataTable[ 0 ].data.length) {
      additions = JSON.parse(dataTable[ 0 ].data[ 0 ].additionJSON);
    }

    // IK ben hier gebleven. Stuk hierboven staat in node-red
    const productJSON = await this.getProductForSupplier(supplier, code, additions);

    const removeEmpty = (object) => {
      const newObject = {};

      if (Array.isArray(object)) {
        return object;
      }

      Object.entries(object).filter(([ key, value ]) => value !== null).forEach(([ key, value ]) => {
        if (Array.isArray(value)) {
          const newArray = [];
          value.forEach(row => {
            if (typeof row === 'object') {
              newArray.push(removeEmpty(row));
            } else if (row) {
              newArray.push(row);
            }
          });

          newObject[ key ] = newArray;
        } else if (typeof value === 'object') {
          newObject[ key ] = removeEmpty(value);
        } else if (value === 0 || value) {
          newObject[ key ] = value;
        }
      });

      return newObject;
    };

    this.productJSON = removeEmpty(productJSON);

    await this.modifyDataTable(
      'getProduct',
      'product',
      [
        {
          key: 'code',
          val: [ supplier, code ].join('.')
        }
      ],
      0,
      "modifyRow"
    );
  }

  modifyDataTable(src, widgetId, params: Param[], index, func) {
    return new Promise((resolve, reject) => {
      this.subscription = this.dataService
        .getDataTable(
          {
            db: "xfw3",
            src: src,
            widgetId: widgetId,
            paramsFrom: params,
            titleFrom: "",
            collapsed: false,
            dataTable: undefined,
            layout: undefined,
            noHeader: false,
            noTitle: false,
            params: params,
          },
          "xfw3",
          src,
          params
        )
        .subscribe((dataTable: DataTable[]) => {
          if (func) {
            dataTable[ 0 ].data.forEach((dataRow) => {
              this[ func ](dataRow, params[ 0 ].val);
            });

            this.submitDataTable(dataTable, index);
          }

          this.subscription.unsubscribe();

          resolve(dataTable);
        });
    });
  }

  submitDataTable(dataTable, index) {
    const mutations = [ ...dataTable[ 0 ].data.filter((x) => x.crud) ];
    console.log(dataTable);
    console.log(dataTable[ 0 ].src);
    console.log(dataTable[ 0 ].procedure);

    console.log("We are about to save:", mutations);

    const subscription = this.networkService.crud([ {
      db: 'xfw3',
      src: dataTable[ 0 ].src,
      procedure: dataTable[ 0 ].procedure,
      mutations: mutations
    } ], 'json').subscribe(result => {
      console.log(result);

      subscription.unsubscribe();
    });
  }

  modifyRow(dataRow, supplier: string) {
    supplier = supplier.split('.')[ 0 ];
    dataRow.productJSON = this.productJSON;
    let supplierList = [];
    if (supplier == 'probo') {
      supplierList.push(93); // 93 voor Probo
    } else if (supplier ==='printcom') {
      supplierList.push(532)
    }
    dataRow.suppliers = supplierList;
    dataRow.crud = 'update';
    dataRow.oldCode = dataRow.code;
    dataRow.articelGroupName = this.productJSON.articelGroupName;
    return dataRow;
  }

}
