import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-nas',
  templateUrl: './report-nas.component.html',
  styleUrls: ['../report.component.scss']
})
export class ReportNasComponent implements OnInit {
  @Input() data: any;

  ngOnInit(): void {

  }
}
