<svg:ng-container>
  <rect [id]="object.ident" [attr.x]="-object.width * 0.5" [attr.y]="-object.height * 0.5" [attr.width]="object.width"
    [attr.height]="object.height" class="bench drag" stroke="#000" />


  <g *ngIf="!(object.height > 0.2 && object.width > 0.15)"
    attr.transform="translate({{ (object.x < inhaker.x ? -1 : 1) * (object.width * 0.5 + 0.06)}}, {{object.height * -0.5 - 0.06}})">
    <rect class="popout-group-border ignore options-counterweight-fill" width="0.1" height="0.1" x="-0.05" y="-0.05" />

    <!-- Object name -->
    <g>
      <text [style]="'font-size:0.06;'" class="ignore text-middle object-icon icon">{{"\uf4b8"}}</text>
    </g>
  </g>


  <!-- Width slider -->
  <g attr.transform="translate({{(object.x < inhaker.x ? -1 : 1) * (object.width * 0.5)}})">
    <circle r="0.025" class="resize-handle ignore" />
    <circle id="{{object.ident}}.width" r="0.04" class="drag" opacity="0" />
  </g>

  <!-- Height slider -->
  <g attr.transform="translate(0,{{object.height * 0.5}})">
    <circle r="0.025" class="resize-handle ignore" />
    <circle id="{{object.ident}}.height" r="0.04" class="drag" opacity="0" />
  </g>

  <ng-container *ngIf="parent.view === 'weight'">

    <!-- Bench icon -->
    <g>
      <text [style]="'font-size:' + clamp(minNameSize, maxNameSize, Math.min(object.width, object.height) * 0.5) + ';'"
        class="ignore text-middle object-icon icon">{{"\uf4b8"}}</text>
    </g>

    <!-- Height number -->
    <g attr.transform="translate({{(object.width*0.5 + 0.04) * (object.x < inhaker.x ? -1 : 1)}},0)"
      [ngClass]="object.x < inhaker.x ? 'text-right' : ''">
      <text [id]="object.ident" class="display-height ignore">
        {{ Math.round(object.height * 1000) }} mm
      </text>
    </g>

    <!-- Width number -->
    <g attr.transform="translate(0, {{object.height*0.5  + 0.04}})">
      <text class="display-width ignore text-middle">
        {{ Math.round(object.width * 1000) }} mm
      </text>
    </g>

    <g
      attr.transform="translate({{(object.x < inhaker.x ? -1 : 1) * (((object.height > 0.2 && object.width > 0.15) ? -1 : 1) * object.width * 0.5 + 0.06)}}, {{((object.height > 0.2 && object.width > 0.15) ? 1 : -1) * object.height * 0.5 - 0.06 - ((object.height > 0.2 && object.width > 0.15) ? 0 : 0.115) }})">

      <!-- Background -->
      <rect *ngIf="!(object.height > 0.2 && object.width > 0.15 && buttons.length > 0)"
        class="group-border ignore options-counterweight-fill" width="0.1"
        [attr.height]="0.015 + 0.085 * buttons.length" x="-0.05" [attr.y]="0.035 - 0.085 * buttons.length" />

      <!-- Button generate -->
      <g *ngFor="let button of buttons; let i = index" attr.transform="translate(0, {{-0.085 * i}})">
        <circle title="remove" r="0.04" cy="0" class="button" (click)="button.onClick()">
          <title>{{button.title}}</title>
        </circle>
        <text fill="#FFF" dominant-baseline="central" class="text-middle icon ignore">{{button.faIcon}}</text>
      </g>

    </g>
  </ng-container>

</svg:ng-container>
