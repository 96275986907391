import { Component, OnInit, OnDestroy } from '@angular/core';
import { WidgetEvent, WidgetState } from 'src/app/widgets';
import { PageComponent } from '../page/page.component';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent extends PageComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    this.communicationService.initWidget({
      widgetId: this.widgetId + '_popup',
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          event: WidgetEvent.CLOSE,
          func: 'closeWindow'
        }
      ]
    });

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.communicationService.destroyWidgets([this.widgetId + '_popup']);
  }

  closeWindow(func?) {
    this.communicationService.mergeQueryParams(this.widgetId, [], [{ outlets: { popup: null } }]);
  }
}
