import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { ContentService } from 'src/app/services/content.service';
import { CustomFuncService } from 'src/app/services/custom-func.service';
import { DragDropService } from 'src/app/services/dragdrop.service';
import { GmapService } from 'src/app/services/gmap.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { NetworkService } from 'src/app/services/network.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PlanningService } from 'src/app/services/planning.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { DataGroup, DataTable, FieldGroup, Fields } from '../../data';
import { DataService } from '../../services/data.service';
import { EventParams, Param, WidgetEvent, WidgetState } from '../../widgets';
import { TempConfiguratorService } from 'src/app/services/temp-configurator.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-data-generic',
  templateUrl: './data-generic.component.html',
  styleUrls: ['./data-generic.component.css']
})

export class DataGenericComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() dataGroupItem: DataGroup;
  @Input() dataItem: any;
  @Input() dataFilter: any;

  @Output() hasData: EventEmitter<any> = new EventEmitter();

  @ViewChild('virtualScrollElement', { read: ElementRef }) virtualScroll: ElementRef<HTMLDivElement>;
  @ViewChildren('dataItem', { read: ElementRef }) dataItems: QueryList<ElementRef>;

  public refresher: number = 0;
  public dataGroupItemInstance: DataGroup;

  public subscription: Subscription;
  public queryParams: Param[];

  public init: boolean = false;

  public serverFilter: DataTable[] = [];
  public rowSelected = 0;
  public _currentPage = 1;
  public edit = { mode: false };

  public previousSearch: string[] = [];

  get currentPage() {
    return this._currentPage;
  }

  set currentPage(page) {
    this.dataGroupItemInstance.top = Math.max(0, this.dataGroupItemInstance.page * (page - 1));
    this._currentPage = page;
  }

  public _loaded = false;

  loadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this._loaded);
  loaded$ = this.loadedSubject.asObservable();

  set loaded(value) {
    this._loaded = value;
    this.loadedSubject.next(this._loaded);
  }

  get loaded() {
    return this._loaded;
  }

  public dataTable: DataTable[];

  public index = -1;
  public component = this;

  public editRow = -1;
  public sortFields: string[] = [];
  public searchBox = true;
  public searchIndex = -1;
  public nav = {};
  public focus: string = null;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public gmapService: GmapService,
    public dragDropService: DragDropService,
    public contentService: ContentService,
    public utilsService: UtilsService,
    public dataService: DataService,
    public networkService: NetworkService,
    public communicationService: CommunicationService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public notificationService: NotificationService,
    public customFuncService: CustomFuncService,
    public configuratorService: ConfiguratorService,
    public dragdropService: DragDropService,
    public tempConfiguratorService: TempConfiguratorService,
    public planningService: PlanningService,
    public userService: UserService,
    public authService: AuthService) {
  }

  setQueryParams(params, initial: boolean = false) {
    this.queryParams = params.queryParams ? params.queryParams : params;

    const queryParamsLength = this.queryParams.length;
    // TODO: ik geloof niet dat dit ergens op slaat, checken op lengte. Die kan ws weg.
    const dataGroupItemParamsLength = this.dataGroupItemInstance.params.filter(param => param.val !== null && param.val !== undefined).length;

    ['tickets'].includes(this.dataGroupItemInstance.widgetId) &&
      console.log('check',
        structuredClone(this.dataGroupItemInstance.widgetId),
        structuredClone(this.dataGroupItemInstance.params),
        structuredClone(this.dataGroupItemInstance.oldParams),
        structuredClone(this.queryParams),
        initial);


    if (initial ||
      queryParamsLength !== dataGroupItemParamsLength ||
      (this.dataGroupItemInstance && this.utilsService.inBoth(this.dataGroupItemInstance.params, this.queryParams, 'key').length)) {

      this.dataService.setParams(this.queryParams, this.utilsService.paramsFromObject(this.communicationService.user), this.dataGroupItemInstance.dataTable[0]?.data, [this.dataGroupItemInstance], initial);

      ['tickets'].includes(this.dataGroupItemInstance.widgetId) &&
      console.log(this.widgetId, structuredClone(this.dataGroupItemInstance.params), structuredClone(this.dataGroupItemInstance.oldParams), structuredClone(this.dataGroupItemInstance.paramsSet));

      this.loadDataTable().then(() => {
        this.setSelected(this.queryParams);
        this.refresher++;
      });
    }
  }

  forceReload() {
    console.log('forceReload', this.widgetId);
    this.loadDataTable(true)
      .then(() => {
        this.setSelected(this.queryParams);
      });
  }

  setUser(params) {
    this.communicationService.user = params.dataItem ? params.dataItem : params;
  }

  setUserFromParams(params) {
    this.userService.setUser({ config: this.utilsService.objectFromParams(params) }, true);
    // this.communicationService.user.config = {...this.communicationService.user.config, ...this.utilsService.objectFromParams(params)};
  }

  setFilter(paramsObject) {
    console.log(this.utilsService.getMethodCallerName(), this.widgetId);

    return new Promise((resolve, reject) => {
      const filterCrud = this.dataGroupItemInstance.dataTable[0].procedure?.includes('local.filterCRUD');
      const serverFilter = filterCrud ? this.dataGroupItemInstance.dataTable : this.serverFilter;

      const data = structuredClone(paramsObject.data);

      const lookupFields: { key: string, valueField: string; }[] = [];

      serverFilter[0].fields[0].fieldGroup.forEach(fieldGroup => {
        if (fieldGroup.xfw.inputData) {
          lookupFields.push({
            key: fieldGroup.key,
            valueField: fieldGroup.xfw.inputData.valueField
          });
        }

        if (fieldGroup.type === 'bitmapSelect') {
          data[fieldGroup.key] = data[fieldGroup.key].value;
        }
      });

      const propertiesToDelete = ['crud', 'isSelected', 'checked', 'collapsed', 'edit', 'trackBy'];

      Object.keys(data).forEach(key => {
        if (propertiesToDelete.includes(key)) {
          delete data[key];
        } else if (data[key] === '' || data[key] === undefined) {
          data[key] = null;
        }
      });

      const params: Param[] = this.utilsService.paramsFromObject(data);
      params.forEach(param => {
        param['isQueryParam'] = true;
        param['isOptional'] = true;

        if (lookupFields.some(lookupField => lookupField.key === param.key)) {
          if (param.val instanceof Array) {
            param.val = param.val.map(val => val[lookupFields.find(lookupField => lookupField.key === param.key).valueField]).join(',');
          } else if (param.val instanceof Object) {
            param.val = JSON.stringify(param.val[lookupFields.find(lookupField => lookupField.key === param.key).valueField]);
          }
        }
      });


      // Moet dit nu setQueryParams zijn of mergeQueryParams? Als het Set moet worden dan moeten alle mogelijke queryParams erin.
      // Dat is problematisch als er meerdere filters op 1 pagina zijn. Dus het moet merge worden. Dat dus testen.
      //
      this.communicationService.setQueryParams(this.widgetId + filterCrud ? '' : '_serverFilter', params, [], null, false);
      this.setQueryParams([], true);

      resolve(null);
    });
  }

  partialRefresh(eventParams: EventParams) {
    // console.log('partialRefresh', eventParams);

    eventParams.data.forEach(refreshRow => {
      this.dataGroupItemInstance.dataTable[0].data.forEach(row => {
        let match = true;
        this.dataGroupItemInstance.dataTable[0].primaryKeys.forEach(primaryKey => {
          if (row[primaryKey.key] !== refreshRow[primaryKey.key]) {
            match = false;
          }
        });

        match && eventParams.dataItem.fields.forEach(field => {
          row[field] = field.includes('JSON') ? JSON.parse(refreshRow[field]) : refreshRow[field];
        });
      });
    });
  }

  initWidget() {
    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this.component,
      state: WidgetState.OK,
      subscribeTo: [
        {
          event: WidgetEvent.USERINFOCHANGED,
          func: 'setUser',
        },
        {
          event: WidgetEvent.QUERYPARAMCHANGED,
          func: 'setQueryParams'
          // ,
          // params: this.dataGroupItemInstance.params,
          // paramsFrom: this.dataGroupItemInstance.params
        },
        {
          widgetGroup: [this.widgetId, this.widgetId + '_form'],
          event: WidgetEvent.SAVE,
          promise: true,
          func: 'updateMutations'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.MERGE,
          promise: true,
          func: 'mergeMutations'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.CLEAR,
          promise: false,
          func: 'clearChecked'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.REFRESH,
          func: 'forceReload'
        },
        {
          widgetGroup: [this.widgetId.split('_')[0]],
          event: WidgetEvent.CASCADINGREFRESH,
          func: 'forceReload'
        },
        {
          widgetGroup: [this.widgetId.split('_')[0]],
          event: WidgetEvent.PARTIALREFRESH,
          func: 'partialRefresh'
        },
        {
          widgetGroup: [this.widgetId + '_serverFilter'],
          event: WidgetEvent.SAVE,
          func: 'setFilter'
        },
        {
          widgetGroup: [this.widgetId, this.widgetId + '_form'],
          event: WidgetEvent.COPY,
          func: 'addRow'
        },
        {
          widgetGroup: [this.widgetId, this.widgetId + '_form'],
          event: WidgetEvent.DELETE,
          func: 'deleteRow'
        }
      ]
    });
  }

  ngOnInit() {
    this.setFromParams(this.dataGroupItem, this.dataItem, this.widgetId);

    this.dataTable = this.dataGroupItemInstance.dataTable;
    this.dataTable[0]?.data?.length && (this.index = 0);

    if (this.dataGroupItemInstance.detailType !== 'child') {
      this.initWidget();
      this.setQueryParams(this.communicationService.queryParams, true);
    } else {
      if (this.communicationService.widget.some((item) => item.widgetId === this.dataGroupItemInstance.widgetId)) {
        this.loaded = true;
      } else {
        this.initWidget();
        this.setQueryParams(this.communicationService.queryParams, true);
      }
    }
  }

  setFromParams(dataGroupItem: DataGroup, dataItem: any, widgetId: string) {
    (dataGroupItem.widgetId !== widgetId) ?
      (this.dataGroupItemInstance = structuredClone({ ...dataGroupItem, ...{ widgetId: this.widgetId } })) :
      (this.dataGroupItemInstance = dataGroupItem);

    // dataItem is leeg als de dataGroupItem geen fromData paramsFrom heeft, of geen parent heeft. Bij enkelvoudige
    // lijsten dus.

    // console.log(this.dataGroupItemInstance, dataItem, widgetId);

    dataItem && this.dataGroupItemInstance.params.filter(paramFrom => paramFrom.src === 'fromData').forEach(paramFrom => {
      this.dataGroupItemInstance.params.find(param => param.key === paramFrom.key).val = dataItem[paramFrom.key];
    });

    this.dataGroupItemInstance.paramsSet = true;
  }

  public mergeMutations({ data }: { data: any[]; }) {
    return new Promise((resolve, reject) => {
      this.updateMutations({
        mutations: data,
        forceReload: true
      });
    });
  }

  public updateMutations(option?: { mutations: any[], forceReload: boolean }) {
    return new Promise((resolve, reject) => {
      if (this.dataGroupItemInstance.dataTable[0].procedure?.includes('local.filterCRUD')) {
        this.setFilter({ data: structuredClone(this.dataGroupItemInstance.dataTable[0].data.filter(x => x.crud))[0] }).then(() => {
          resolve(null);
        });
      } else {
        let appendToData = false;
        let mutations = option?.mutations ?? [];
        let forceReload = option?.forceReload ?? false;

        if (!mutations.length) {
          mutations = structuredClone(this.dataGroupItemInstance.dataTable[0].data.filter(row => row.crud));
          // children moeten nooit mee naar de database.
          mutations.forEach(mutation => { delete mutation.children; });

          this.dataGroupItemInstance.dataTable[0].fields.flatMap(field => field.fieldGroup).forEach(fieldGroup => {
              fieldGroup.doNotSaveThisField && mutations.forEach(mutation => { delete mutation[fieldGroup.key]; });
          });

        } else {
          appendToData = true;
        }

        this.dataGroupItemInstance.edit.mode === 'edit' && this.dataGroupItemInstance.dataTable[0].data.forEach(row => { row.edit = true; });

        console.log('We are about to save:', structuredClone(mutations), this.dataGroupItemInstance.dataTable[0].procedure);

        if (mutations.length) {
          if (!this.dataGroupItemInstance.dataTable[0].procedure?.includes('local.')) {
            const subscription = this.networkService.crud([{
              db: 'xfw3',
              src: this.dataGroupItemInstance.dataTable[0].src,
              procedure: this.dataGroupItemInstance.dataTable[0].procedure,
              mail: this.dataGroupItemInstance.dataTable[0].mail,
              mutations: mutations
            }], 'json').subscribe(result => {

              if (!forceReload && result.length) {
                for (let mutation of mutations) {
                  !this.dataGroupItemInstance.rowOptions.checkSave && (mutation.checked = false);

                  for (let key of Object.keys(mutation)) {
                    (result[0].some(row => row.trackBy === mutation.trackBy && row.hasOwnProperty(key))) &&
                      (mutation[key] = result[0].find(row => row.trackBy === mutation.trackBy && row.hasOwnProperty(key))[key]);
                  }
                }

                this.dataService.processData({
                  data: mutations,
                  fields: this.dataGroupItemInstance.dataTable[0].fields,
                  parse: true
                });

                appendToData && this.dataGroupItemInstance.edit.updateMutations === 'direct' &&
                  mutations.filter(mutation => mutation.crud === 'create').forEach(mutation => {
                    mutation.crud = null;
                    this.dataGroupItemInstance.dataTable[0].data.push(mutation);
                  });


                for (let row of this.dataGroupItemInstance.dataTable[0].data) {
                  mutations.some(mutation => mutation.trackBy === row.trackBy) &&
                    Object.keys(row).forEach(key => {
                      row[key] = mutations.find(mutation => mutation.trackBy === row.trackBy)[key];
                    });

                  row.crud !== 'delete' && (row.crud = null);
                }

                this.dataGroupItemInstance.dataTable[0].data = this.dataGroupItemInstance.dataTable[0].data.filter(row => row.crud !== 'delete');

                // hier selecteren we de laatst aangepaste rij, maar dat is natuurlijk niet ideaal als het gewoon om een update of zo gaat.
                // het is alleen nodig als er een create is misschien. We doen het voor nu even alleen als er een create is.
                mutations.some(mutation => mutation.crud === 'create') &&
                  this.select({ dataItem: mutations[mutations.length - 1] });

                this.communicationService.performAction({
                  widgetGroup: [this.widgetId ?? this.dataGroupItemInstance.dataTable[0].src + Math.random().toString()], // lelijke oplossing voor items waar widgetId mist.
                  event: WidgetEvent.REFRESHED,
                  data: result[0]
                });

                this.dataGroupItemInstance.cascadingDataGroup && this.communicationService.performAction({
                  widgetGroup: this.dataGroupItemInstance.cascadingDataGroup.widgetGroup,
                  event: this.dataGroupItemInstance.cascadingDataGroup.refresh ? WidgetEvent.CASCADINGREFRESH : WidgetEvent.PARTIALREFRESH,
                  data: result[0],
                  dataItem: { fields: this.dataGroupItemInstance.cascadingDataGroup.fields }
                });

                subscription.unsubscribe();

                resolve(mutations);

              } else {
                this.forceReload();
                resolve(null);
              }
            });
          } else {
            this.dataGroupItemInstance.dataTable[0].data = this.dataGroupItemInstance.dataTable[0].data.filter(mutation => mutation.crud !== 'delete');
            this.dataGroupItemInstance.dataTable[0].data.forEach(mutation => mutation.crud = null);

            this.networkService.localStorageSet([{
              src: this.dataGroupItemInstance.dataTable[0].src,
              procedure: this.dataGroupItemInstance.dataTable[0].procedure,
              mail: this.dataGroupItemInstance.dataTable[0].mail,
              mutations: this.dataGroupItemInstance.dataTable[0].data
            }]);

            this.dataService.processData({
              data: this.dataGroupItemInstance.dataTable[0].data,
              fields: this.dataGroupItemInstance.dataTable[0].fields,
              parse: true
            });

            this.communicationService.performAction({
              widgetGroup: [this.widgetId ?? this.dataGroupItemInstance.dataTable[0].src + Math.random().toString()], // lelijke oplossing voor items waar widgetId mist.
              event: WidgetEvent.REFRESHED,
              data: this.dataGroupItemInstance.dataTable[0].data
            });

            resolve(this.dataGroupItemInstance.dataTable[0].data);
          }
        }
      }
    });

  }

  setEditMode(eventParams: EventParams) {
    if (eventParams.dataItem.isSelected) {
      const selectedIndex = this.dataGroupItemInstance.dataTable[0].data.findIndex(item => item.trackBy === eventParams.dataItem.trackBy);

      if (selectedIndex !== -1) {
        this.index = selectedIndex;
        this.edit.mode = true;
        eventParams.dataItem.edit = true;
      }
    }
  }

  collExp(eventParams: EventParams) {
    // TODO: Dit werkt niet meer op deze manier. Er moet steeds een nieuwe dataGroupItem aangemaakt worden, zie de job.component.
    (eventParams.dataItem.collapsed) &&
      this.dataService.setParams(this.queryParams, this.utilsService.paramsFromObject(this.communicationService.user), eventParams.dataItem, [this.dataGroupItemInstance], false);

    eventParams.dataItem.collapsed = !eventParams.dataItem.collapsed;
  }

  setSelected(queryParams: Param[]) {
    if (this.loaded && this.dataGroupItemInstance.dataTable[0]?.data.length) {
      const findParams: Param[] = [];

      this.dataGroupItemInstance.dataTable[0].primaryKeys.forEach(field => {
        const param = this.dataGroupItemInstance.params.find(param => param.key === field.key);
        (param) &&
          findParams.push(
            {
              key: param.key,
              val: param.val
            }
          );
      });

      const selectedIndex = this.dataGroupItemInstance.dataTable[0].data
        .findIndex(item => this.utilsService.isSubset(item, this.utilsService.objectFromParams(findParams)));

      switch (this.dataGroupItemInstance.selectionMode) {
        case 'viewer':
          this.dataGroupItemInstance.dataTable[0].data.forEach((row, index) => row.isSelected = (index === selectedIndex));
          break;
        case 'row':
          (this.index !== selectedIndex || this.index === -1) &&
            this.select({ params: [], dataItem: this.dataGroupItemInstance.dataTable[0].data[selectedIndex !== -1 ? selectedIndex : 0] });
          break;
        case 'cell':
          const findFirstCell = (list) => {
            let cell = null;

            list.forEach(item => {
              item.child ?
                item.child.forEach(childItem => {
                  childItem.xfw.isSelectable && !cell && (cell = childItem.key);
                }) :
                item.xfw.isSelectable && !cell && (cell = item.key);

            });
            return cell;
          };

          (this.index !== selectedIndex || this.index === -1) &&
            this.select({
              params: [{ key: 'cell', val: findFirstCell(this.dataGroupItemInstance.dataTable[0].cache) }],
              dataItem: this.dataGroupItemInstance.dataTable[0].data[selectedIndex !== -1 ? selectedIndex : 0]
            });

      }

    }
  }

  select(eventParams: EventParams) {
    let dataItem;
    let cell;

    if (['none', 'viewer'].includes(this.dataGroupItemInstance.selectionMode)) {
      if (eventParams.params?.some(param => param.key === 'fileName')) {
        const obj = this.utilsService.objectFromParams(eventParams.params);
        window.open(obj.filePath + obj.fileName + '.' + obj.fileExtension, '_blank');
      }
      return;
    }

    cell = eventParams.params?.find(param => param.key === 'cell')?.val;
    // dataItem = eventParams.dataItem;
    dataItem = structuredClone(eventParams.dataItem);

    const selectedIndex = this.dataGroupItemInstance.dataTable[0].data
      .findIndex(item => item.trackBy === dataItem.trackBy);

    if (selectedIndex !== -1) {
      this.dataGroupItemInstance.dataTable[0].data.filter(row => row.isSelected && row.trackBy !== dataItem.trackBy).forEach(row => row.isSelected = false);
      this.index = selectedIndex;
      this.dataGroupItemInstance.dataTable[0].data[this.index].isSelected = true;
      dataItem.isSelected = dataItem.checked = true;
      eventParams.dataItem.isSelected = true;

      if (this.dataGroupItemInstance.rowOptions.nav?.onSelect) {
        this.dataGroupItemInstance.params.filter(paramFrom => !paramFrom.src).forEach(paramFrom => {
          this.dataGroupItemInstance.params.find(param => param.key === paramFrom.key).val = dataItem[paramFrom.key];
        });
        this.dataGroupItemInstance.paramsSet = true;

        this.dataGroupItemInstance.rowOptions.nav.onSelect.params.forEach(param => {
          param.val = dataItem[param.key];
          if (param.alias) {
            param.val = dataItem[param.alias];
          }
        });

        // console.log('select', this.dataGroupItemInstance.widgetId, structuredClone(this.dataGroupItemInstance.rowOptions.nav.onSelect.params));

        // console.log('select', this.dataGroupItemInstance.params, this.dataGroupItemInstance.params, this.dataGroupItemInstance.widgetId, this.dataGroupItemInstance.rowOptions.nav.onSelect.params);

        // Uitzoeken wat we met het onderstaande moeten doen, als een cell geselecteerd wordt.

        // if (this.dataGroupItemInstance.selectionMode === 'cell' && cell && !this.edit.mode) {
        //   if (this.dataGroupItemInstance.dataTable[0].selectedParams.some(x => x.key === 'cell')) {
        //     this.dataGroupItemInstance.dataTable[0].selectedParams.find(x => x.key === 'cell').val = cell;
        //   } else {
        //     this.dataGroupItemInstance.dataTable[0].selectedParams.push({
        //       key: 'cell',
        //       val: cell
        //     });
        //   }
        // }
      }
    }

    this.dataGroupItemInstance.selectionMode === 'cell' && cell && (this.focus = cell)

    if (this.dataGroupItemInstance.rowOptions.nav?.onSelect?.outlet) {
      const outlets = {};
      outlets[this.dataGroupItemInstance.rowOptions.nav.onSelect.outlet] = this.dataGroupItemInstance.rowOptions.nav.onSelect.path;
      this.communicationService.mergeQueryParams(this.widgetId, this.dataGroupItemInstance.rowOptions.nav.onSelect.params, [{ outlets: outlets }], null, false);
    } else if (this.dataGroupItemInstance.rowOptions.nav?.onSelect?.path) {
      this.communicationService.mergeQueryParams(this.widgetId, this.dataGroupItemInstance.rowOptions.nav.onSelect.params, [this.dataGroupItemInstance.rowOptions.nav.onSelect.path], null, false);
    } else if (this.dataGroupItemInstance.rowOptions.nav?.onSelect) {
      this.communicationService.mergeQueryParams(this.widgetId, this.dataGroupItemInstance.rowOptions.nav.onSelect.params, [], null, true);
    }

    if (this.dataGroupItemInstance.rowOptions.nav?.onSelect?.func) {
      this.customFuncService.perform(this.dataGroupItemInstance.rowOptions.nav?.onSelect?.func, this.dataGroupItemInstance.rowOptions.nav?.onSelect?.procedure, this.dataGroupItemInstance.rowOptions.nav.onSelect.params, this.dataGroupItemInstance.rowOptions.nav.onSelect.params, dataItem, this.component);
      this.communicationService.mergeQueryParams(this.widgetId, this.dataGroupItemInstance.rowOptions.nav.onSelect.params, [], null, true);
    }

    if (!this.dataGroupItemInstance.rowOptions.nav?.onSelect) {
      const findParams: Param[] = [];

      this.dataGroupItemInstance.dataTable[0].primaryKeys.forEach(field => {
        if (this.dataGroupItemInstance.params.filter(paramFrom => !paramFrom.src).some(paramFrom => paramFrom.key === field.key)) {
          const key = this.dataGroupItemInstance.params.find(param => param.key === field.key).key;
          findParams.push({
            key: key,
            val: dataItem[key],
            isQueryParam: true
          });
        }
      });

      this.communicationService.mergeQueryParams(this.widgetId, findParams, [], null, true);
    }
  }

  scrollIntoView() {
    const group = this.dataGroupItemInstance;

    if (group.page > 0 && group.paging === "pagination") {
      this.currentPage = Math.floor(this.index / group.scroll) + 1;
    }

    if (group.paging === "virtual-scroll") {
      // TODO virtual scroll scroll to search item
      // group.scroll = 30 + Math.max(0,this.index )  //(this.dataItems.get(0).nativeElement as HTMLElement).getBoundingClientRect()
      // console.log(
      //   this.virtualScroll.nativeElement.children[0].scrollTop = (this.dataItems.get(0).nativeElement as HTMLElement).getBoundingClientRect().height * (this.index - group.top ?? 0) - this.virtualScroll.nativeElement.getBoundingClientRect().height * 0.5)
    }

    ; (
      this.dataItems.toArray()[this.index]
        .nativeElement as HTMLElement
    ).scrollIntoView({
      behavior: "instant",
      inline: "center",
      block: "center",
    });

  }

  setCurrentPage() {
    if (this.dataGroupItemInstance.paging !== 'pagination') return;

    (this.dataGroupItemInstance.page !== -1 && this.currentPage !== Math.floor(this.index / this.dataGroupItemInstance.page) + 1) &&
      (this.currentPage = Math.floor(this.index / this.dataGroupItemInstance.page) + 1);
  }

  loadDataTable(force = false) {
    // console.log(this.utilsService.getMethodCallerName(), this.dataGroupItemInstance.widgetId, this.dataGroupItemInstance.paramsSet);

    return new Promise((resolve, reject) => {
      let parentParams: Param[] = [];

      if ((force && this.dataGroupItemInstance.dataTable.length) || this.dataGroupItemInstance.paramsSet) {
        // this.loaded = !!this.dataGroupItemInstance.dataTable.length;

        // console.time('Execution Time ' + this.dataGroupItemInstance.src);

        //console.log(this.dataGroupItem);

        this.subscription = this.dataService
          .getDataTable(this.dataGroupItemInstance, this.dataGroupItemInstance.db, this.dataGroupItemInstance.src[0].name, this.queryParams ?? [])
          .subscribe((dataTable: DataTable[]) => {
            this.index = -1;
            this.dataGroupItemInstance.paramsSet = false;
            this.dataGroupItemInstance.oldParams = structuredClone(this.dataGroupItemInstance.params);

            (!this.dataGroupItemInstance.dataTable.length) ?
              dataTable.forEach(dataTableItem => {
                this.dataGroupItemInstance.dataTable.push(dataTableItem);
              }) :
              dataTable.forEach((dataTableItem, index) => {
                this.dataGroupItemInstance.dataTable[index].data = dataTableItem.data;
                this.dataGroupItemInstance.dataTable[index].originalData = dataTableItem.originalData;
                this.dataGroupItemInstance.dataTable[index].agg = dataTableItem.agg;
              });

            this.dataGroupItemInstance.dataTable[0].data.length === 0 &&
              this.dataGroupItemInstance.removePrimaryKeysFromQueryParams &&
              this.removePrimaryKeysFromQueryParams(this.dataGroupItemInstance);

            this.searchBox = this.dataGroupItemInstance.search ? this.dataGroupItemInstance.search.searchFields.length > 0 : null;

            if (this.dataGroupItemInstance.titleFrom && this.dataGroupItemInstance.dataTable[0].data.length) {
              const title = this.dataGroupItemInstance.dataTable[0].data[0][this.dataGroupItemInstance.titleFrom] || this.queryParams.find(x => x.key === this.dataGroupItemInstance.titleFrom)?.val;

              this.dataGroupItemInstance.title = {
                text: title,
                nodata: title,
                singleItem: title,
                multipleItems: title,
                window: title
              };
            }

            if (this.dataGroupItemInstance.dataTable[0].procedure?.includes('local.')) {
              let localData = this.networkService.localStorageGet([{
                src: this.dataGroupItemInstance.dataTable[0].src,
                procedure: this.dataGroupItemInstance.dataTable[0].procedure
              }]);

              this.dataGroupItemInstance.dataTable[0].data = localData ? localData : this.dataGroupItemInstance.dataTable[0].data;
            }

            this.dataService.processData({
              data: this.dataGroupItemInstance.dataTable[0].data,
              fields: this.dataGroupItemInstance.dataTable[0].fields,
              parse: true
            });

            // onderstaande doen we om 'terug' te kunnen.
            this.dataService.processData({
              data: this.dataGroupItemInstance.dataTable[0].originalData,
              fields: this.dataGroupItemInstance.dataTable[0].fields,
              parse: true
            });

            // TODO: deze moet weg, we willen dat niet meer via de queryParams laten lopen.
            this.dataGroupItemInstance.rowOptions.checkable && this.dataService.itemCheckboxInit(this.dataGroupItem);

            this.dataGroupItemInstance.dataTable[0].fields = this.contentService.changeLanguage(this.dataGroupItemInstance.dataTable[0].fields, this.communicationService.user.language);

            if (['form', 'login'].includes(this.dataGroupItemInstance.layout) || (this.widgetId.includes('_serverFilter') && this.queryParams.length)) {
              this.index = 0;
              this.rowSelected = 0;
              this.hasData.emit({ form: true, dataLength: this.dataGroupItemInstance.dataTable[0].data.length });
            } else {
              this.hasData.emit({ form: false, dataLength: this.dataGroupItemInstance.dataTable[0].data.length });
            }

            !this.widgetId &&
              this.notificationService.addNotification({
                msgType: 'error',
                msg: {
                  title: 'Error',
                  body: { text: this.dataGroupItemInstance.dataTable[0].src + ' -> widgetId is not set' }
                }
              });

            this.communicationService.performAction({
              widgetGroup: [this.widgetId ?? this.dataGroupItemInstance.dataTable[0].src + Math.random().toString()], // lelijke oplossing voor items waar widgetId mist.
              event: WidgetEvent.REFRESHED,
              data: this.dataGroupItemInstance.dataTable[0].data
            });

            force && this.communicationService.performAction({
              widgetGroup: [this.widgetId ?? this.dataGroupItemInstance.dataTable[0].src + Math.random().toString()], // lelijke oplossing voor items waar widgetId mist.
              event: WidgetEvent.FORCEDREFRESHED,
              data: this.dataGroupItemInstance.dataTable[0].data
            });

            this.loaded = true;
            resolve(null);
          });

      } else {
        this.dataGroupItemInstance.dataTable.forEach(singleDataTable => {
          singleDataTable && (this.loaded = true);
        });

        resolve(null);
      }
    });
  }

  removePrimaryKeysFromQueryParams(dataGroupItemInstance) {
    dataGroupItemInstance.dataTable[0].primaryKeys.forEach(primaryKey => {
      this.communicationService.queryParams.forEach(param => {
        param.key === primaryKey.key && (param.val = null);
      });
    });

    this.communicationService.setQueryParams(this.widgetId, this.communicationService.queryParams, [], null, true);
  }

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([this.widgetId, this.widgetId + '_serverFilter', this.widgetId + '_form']);
    this.communicationService.destroyQueryParams(this.widgetId);
    this.subscription && this.subscription.unsubscribe();
  }

  setEditRow(item: any, $event) {
    if (this.dataGroupItemInstance.edit.type === 'inline') {
      item.edit = true;

      //   let checkRowResult: WidgetState = WidgetState.PENDING;

      //   if (item.crud !== 'edit') {
      //     if (this.dataGroupItemInstance.dataTable[0].data.some(x => x.crud === 'edit')) {
      //       this.dataRows.forEach(dataRow => {
      //         checkRowResult = dataRow.checkRow();
      //         if (checkRowResult === WidgetState.OK) {
      //           this.dataGroupItemInstance.dataTable[0].data.find(x => x.crud === 'edit').crud = 'update';

      //           item.crud = 'edit';
      //           this.focus = $event.target.dataset.focus;

      //         } else if (checkRowResult === WidgetState.NOK) {
      //           console.log('form invalid');
      //         }
      //       });
      //     } else {
      //       item.crud = 'edit';
      //       this.focus = $event.target.dataset.focus;
      //     }
      //   }
    }
  }

  search(eventParams: EventParams) {
    if (!this.utilsService.deepEquals(eventParams.data, this.previousSearch)) {
      this.previousSearch = structuredClone(eventParams.data);

      this.clearSearch();

      if (eventParams.data[0] === '') return;

      this.searchData(this.dataGroupItemInstance.search.searchFields, eventParams.data);
      if (this.dataGroupItemInstance.search.searchResults && this.dataGroupItemInstance.search.searchResults.length) {
        this.searchIndex = 0;
        this.doSearch(this.searchIndex);
      }

    } else if (!eventParams.event.shiftKey) {
      this.searchNext(eventParams);
    } else {
      this.searchPrevious(eventParams);
    }

    this.scrollIntoView();
  }

  doSearch(searchIndex: number) {
    this.index = this.dataGroupItemInstance.search.searchResults[this.searchIndex];
    this.select({ params: [], dataItem: this.dataGroupItemInstance.dataTable[0].data[this.index] });
    this.setCurrentPage();
  }

  clearSearch() {
    this.dataGroupItemInstance.dataTable[0].data.forEach(row => row.highLight = false);
    this.dataGroupItemInstance.search?.searchResults && (this.dataGroupItemInstance.search.searchResults = []);
  }

  searchNext(eventParams: EventParams) {
    this.searchIndex = this.searchIndex === this.dataGroupItemInstance.search.searchResults.length - 1 ? 0 : this.searchIndex + 1;
    this.doSearch(this.searchIndex);
  }

  searchPrevious(eventParams: EventParams) {
    this.searchIndex = this.searchIndex === 0 ? this.dataGroupItemInstance.search.searchResults.length - 1 : this.searchIndex - 1;
    this.doSearch(this.searchIndex);
  }

  searchData(searchFields: string[], searchTxts: string[]) {
    this.dataService.searchDataTable(this.dataGroupItem, searchFields, searchTxts);
    console.log(this.dataGroupItemInstance.dataTable[0].data.filter(row => row.highLight));
  }

  setSortField(fields: Fields[], fieldGroup: FieldGroup) {
    this.clearSearch();
    this.dataService.setSortField(this.dataGroupItemInstance.dataTable, this.sortFields, fields, fieldGroup);
  }

  trackByFn(index, item): string {
    return item.trackBy;
  }

  deleteRow(params) {
    return new Promise((resolve, reject) => {
      if (params.dataItem) {
        this.dataGroupItemInstance.dataTable[0].data[params.dataItem.rowIndex].crud = 'delete';

        (this.dataGroupItemInstance.edit.updateMutations === 'direct') ?
          this.updateMutations().then((result) => {
            resolve(result);
          }) : resolve(null);

      }
    });
  }

  addRow(eventParams: EventParams) {
    if (this.dataGroupItemInstance.dataTable) {
      let index = -1;
      let dataItem: any;

      this.dataGroupItemInstance.dataTable[0].data.filter(row => row.isSelected).forEach(row => row.isSelected = false);

      if (!eventParams.params.length || !eventParams.dataItem) {
        dataItem = {
          crud: 'create',
          edit: ['edit', 'editRow'].includes(this.dataGroupItemInstance.edit.mode),
          checked: false,
          collapsed: 0,
          isSelected: true,
          trackBy: this.dataGroupItemInstance.dataTable[0].data.length + 1
        };

        this.dataGroupItemInstance.dataTable[0].fields.forEach(fields => {
          fields.fieldGroup.forEach(field => {
            field.xfw?.inputData?.defaultItem ?
              (dataItem[field.key] = field.xfw.inputData.defaultItem)
              :
              field.key.includes('fileUid') ?
                (dataItem[field.key] = Date.now().toString())
                :
                dataItem[field.key] = null;
          });
        });

        if (this.dataGroupItemInstance.dataTable[0].foreignKeys) {
          for (let foreignKey of this.dataGroupItemInstance.dataTable[0].foreignKeys) {
            let value;

            (this.communicationService.queryParams.some(param => param.key === foreignKey.key)) ?
              value = this.communicationService.queryParams.find(param => param.key === foreignKey.key)?.val :
              value = this.dataGroupItemInstance.params.find(param => param.key === foreignKey.key)?.val;

            value != null ? dataItem[foreignKey.key] = isNaN(value) ? value : +value : dataItem[foreignKey.key] = foreignKey.val;
          }
        }
      } else {
        if (eventParams.params.some(param => param.key === 'index')) {
          index = eventParams.params.find(param => param.key === 'index').val;

          dataItem = eventParams.dataItem;

          // Dit zal niet nodig zijn.

          // dataItem = structuredClone(this.dataGroupItemInstance.dataTable[0].data[index]);
          // dataItem.trackBy = this.dataGroupItemInstance.dataTable[0].data.length;
          // dataItem.crud = 'create';
          // dataItem.isSelected = true;
          // for(let primaryKey of this.dataGroupItemInstance.dataTable[0].primaryKeys) {
          //   dataItem[primaryKey.key] = null;
          // }
        }
      }

      if (this.dataGroupItemInstance.edit.updateMutations === 'direct') {
        this.updateMutations({ mutations: [dataItem], forceReload: false }).then((result) => {
          setTimeout(() => {
            this.communicationService.performAction({
              info: 'create',
              widgetGroup: [this.widgetId],
              event: WidgetEvent.CREATED
            });
          });
        });
      } else if (index === -1) {
        this.dataGroupItemInstance.dataTable[0].data.push(dataItem);
        this.index = this.dataGroupItemInstance.dataTable[0].data.length - 1;
        this.dataGroupItemInstance.page !== -1 && (this.currentPage = Math.floor(this.dataGroupItemInstance.dataTable[0].data.length / (this.dataGroupItemInstance.page + 1)) + 1);

      } else {
        console.log('addRow, kwam hier', index, dataItem);
        this.index = index + 1;
        this.dataGroupItemInstance.dataTable[0].data.splice(index + 1, 0, dataItem);

        console.log(structuredClone(this.dataGroupItemInstance.dataTable[0].data));
      }
    }
  }

  clearChecked(eventParams: EventParams) {
    this.dataGroupItemInstance.dataTable[0].data.forEach(row => row.checked = false);
  }

  getFieldList(dataTable: DataTable[]) {
    const fields: {
      visibleFields: any[],
      hiddenFieldsWithoutPrimaryKey: any[],
      labels: string[],
    } =
    {
      visibleFields: [],
      hiddenFieldsWithoutPrimaryKey: [],
      labels: [],
    };

    const pushVisibleFields = (field) => {
      fields.visibleFields.push(field);
      fields.labels.push(field.templateOptions.label?.text ?? '');
    };

    dataTable[0].fields.forEach(group => {
      group.fieldGroup.forEach(field => {
        if (field.type === 'hidden' && dataTable[0].primaryKeys.some(primaryKeyParam => primaryKeyParam.key !== field.key)) {
          fields.hiddenFieldsWithoutPrimaryKey.push(field);
        } else {
          pushVisibleFields(field);
        }
      });
    });

    return fields;
  }

  copyToClipboard(params) {
    const clipboardData = [];

    const fieldList = this.getFieldList(this.dataGroupItemInstance.dataTable);

    clipboardData.push({});
    this.dataGroupItemInstance.dataTable[0].cache[0].child.forEach(field => {
      clipboardData[0][field.key] = field.templateOptions.label?.text ?? '';
    });

    this.dataGroupItemInstance.dataTable[0].data.forEach((row, rowIndex) => {
      clipboardData.push({});
      this.dataGroupItemInstance.dataTable[0].cache[0].child.forEach(field => {
        clipboardData[rowIndex + 1][field.key] = row[field.key];
      });
    });

    this.utilsService.convertFromTableDataToTableClipboard(clipboardData);
  }

  downloadListAsCSV() {
    const clipboardData = [];

    const fieldList = this.getFieldList(this.dataGroupItemInstance.dataTable);

    clipboardData.push({});
    this.dataGroupItemInstance.dataTable[0].cache[0].child.forEach(field => {
      clipboardData[0][field.key] = field.templateOptions.label?.text ?? '';
    });

    this.dataGroupItemInstance.dataTable[0].data.forEach((row, rowIndex) => {
      clipboardData.push({});
      this.dataGroupItemInstance.dataTable[0].cache[0].child.forEach(field => {
        clipboardData[rowIndex + 1][field.key] = row[field.key];
      });
    });



    this.utilsService.download(
      this.utilsService.convertTabularToCSV(clipboardData),
      'text/csv'
    );

  }

  pasteFromClipboard(transpose: boolean = false) {
    const dataTable: DataTable[] = this.dataGroupItemInstance.dataTable;
    if (!dataTable[0].selected?.cell || (!dataTable[0].selected.rowIndex && dataTable[0].selected.rowIndex !== 0)) {
      return;
    }

    const fieldList = this.getFieldList(this.dataGroupItemInstance.dataTable);
    const selectedCellIndex = fieldList.visibleFields.findIndex(field => field.key === dataTable[0].selected.cell);

    const clipboardData = [];
    clipboardData.push({});

    this.dataGroupItemInstance.dataTable[0].cache[0].child.forEach(field => {
      clipboardData[0][field.key] = 'visible';
    });

    Object.keys(this.dataGroupItemInstance.dataTable[0].data[0]).forEach(key => {
      if (!(clipboardData[0][key] === 'visible') && !this.dataGroupItemInstance.dataTable[0].primaryKeys.some(primaryKeyParam => primaryKeyParam.key === key)) {
        clipboardData[0][key] = this.dataGroupItemInstance.dataTable[0].data[0][key];
      }
    });

    const insertClipboardData = (rowIndex, cellIndex, field, cellType, cellValue) => {
      if (!field.templateOptions.readOnly) {
        switch (field.type) {
          case 'input':
          case 'number':
          case 'inputCalc':
          case 'email':
          case 'password':
          case 'textarea':
            if (cellType === field.templateOptions.type) {
              this.dataGroupItemInstance.dataTable[0].data[rowIndex + dataTable[0].selected.rowIndex][field.key] = cellType === 'string' ? cellValue : +cellValue;
            }
            break;
          case 'dropdownList':
            if (!field.xfw.inputData.table.cascadeFrom) {
              this.dataGroupItemInstance.dataTable[0].data[rowIndex + dataTable[0].selected.rowIndex][field.key] = field.xfw.inputData.table.data.find(item => item[field.xfw.inputData.textField] === cellValue);
            }
            break;
          default:
            console.log(rowIndex, cellIndex, field, field.xfw.inputData);
        }
      }

    };

    this.utilsService.convertFromClipboardTableToTableData().then((clipboardData: any) => {
      const transpose = (data) => {
        const transposeData = [...Array(data[0].length)].map(element => Array(data.length));

        data.forEach((row, rowIndex) => {
          row.forEach((cell, cellIndex) => {
            transposeData[cellIndex][rowIndex] = cell;
          });
        });

        return transposeData;
      };

      if (transpose) {
        clipboardData = transpose(clipboardData);
      }

      if (clipboardData.length > (dataTable[0].data.length - dataTable[0].selected.rowIndex)) {
        const rowsToAdd = clipboardData.length - (dataTable[0].data.length - dataTable[0].selected.rowIndex) - 1;
        for (let row = 0; row < rowsToAdd; row++) {
          this.addRow({ params: [], dataItem: null });
        }
      }

      clipboardData.forEach((row, rowIndex) => {
        row.forEach((cell, cellIndex) => {
          const cellType = cell === '' ? 'string' : isNaN(+cell) ? ['true', 'false'].includes(cell) ? 'boolean' : 'string' : 'number';
          if (selectedCellIndex + cellIndex < fieldList.visibleFields.length) {
            insertClipboardData(rowIndex, cellIndex, fieldList.visibleFields[selectedCellIndex + cellIndex], cellType, cell);
          }
        });
      });

      this.communicationService.performAction({
        info: 'paste',
        widgetGroup: [this.widgetId.split('-')[0] + '_form'],
        event: WidgetEvent.REFRESH
      });
    });
  }

  log(origin: string, level: number, contentJSON: any) {
    this.dataService.setLog(origin, level, contentJSON);
  }
}


