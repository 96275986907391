import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as SketchFab from '@sketchfab/viewer-api';
import { Nav } from 'src/app/widgets';

@Component({
  selector: 'app-sketch-fab',
  templateUrl: './sketch-fab.component.html',
  styleUrls: ['./sketch-fab.component.scss']
})
export class SketchFabComponent implements AfterViewInit {
  @Input() modelId: string;

  @ViewChild('viewer') viewer: ElementRef;

  private client: any
  public popup: {
    x: number,
    y: number,
    object: string,
    visible: boolean
  } = {
    x: null,
    y: null,
    object: null,
    visible: false
  };

  public nav: Nav = {
    navId: -110,
    "custom": {
      "type": "icon-right",
      "text": "Ga naar dit item",
      "icon": "arrow-right",
      "className": "text-icon round-icon",
      "path": "/xfw/nl/item",
      "track": {
        "eventName": "cmd",
        "eventAction": "custom",
        "eventValue": "/xfw/nl/item"
      },
      "params": [
        {
          "key": "object",
          "val": "",
          "isQueryParam": true
        }
      ],
      "ml": {
        "en": {
          "text": "Get inspired",
          "path": "/xfw/en/inspiration"
        },
        "fr": {
          "text": "Trouve l'inspiration",
          "path": "/xfw/fr/inspiration"
        },
        "de": {
          "text": "Lassen Sie sich inspirieren.",
          "path": "/xfw/de/inspirieren"
        }
      }
    }
  }

  public width: string = "100%";
  public height: string = "600px";

  ngAfterViewInit(): void {
    this.client = new SketchFab(this.viewer.nativeElement);

    window.addEventListener('message', (event) => {
      if (event.origin !== 'https://sketchfab.com') {

        const togglePopup = () => {
          if (this.popup.x && this.popup.object && !this.popup.visible) {
            this.nav.custom.params.find(param => param.key === 'object').val = this.popup.object;
            this.popup.visible = true;
          } else if (this.popup.x && this.popup.object && this.popup.visible) {
            this.popup.visible = false;
            this.popup.object = null;
            this.popup.x = null;
          }
        }

        switch (event.data.type) {
          case 'position':
            this.popup = {...this.popup, ...{x: event.data.x, y: event.data.y}};

            togglePopup();
          break;
          case 'information':
            this.popup = {...this.popup, ...{object : event.data.name}};

            togglePopup();
        }
      }
    });

    this.client.init(this.modelId, {
      success: (api) => {
        api.start();

        api.addEventListener('viewerready', () => {
          console.log('Viewer is ready');

          api.setBackground({color: [0.33, .33, .33]}, function() {
            window.console.log('Background changed');
          });

          api.hideAnnotationTooltips(function(err) {
            if (!err) {
                console.log('Hiding annotation tooltip');
            }
          });

          api.addEventListener(
            'click',
            function(info) {
              window.postMessage({ type: 'position', x: info.position2D[0], y: info.position2D[1]}, '*');
            },
            { pick: 'fast' }
          );

          api.addEventListener('annotationSelect', function(index) {
            api.getAnnotation(index, function(err, information) {
              if (!err) {
                  window.postMessage({...information, ...{ type: 'information' }}, '*');
              }
            });
          });

        });


      },
      error: (error) => {
        console.log('error: ', error);
      }
    })
  }
}
