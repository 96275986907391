import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {
  transform(value: unknown[], args: unknown): unknown[] {
    return !args ? value : value.filter(item => Object.keys(args).every((prop: string) => this.deepEqual(item[prop], args[prop]) && item.hasOwnProperty(prop)));
  }

  deepEqual(obj1, obj2) {
    if (obj1 === obj2) {
        return true;
    }
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }
    let keys1 = Object.keys(obj1);
    for (let key of keys1) {
      console.log(key, obj1[key], obj2[key]);

      if (!obj2.hasOwnProperty(key) || !this.deepEqual(obj1[key], obj2[key])) {
          return false;
      }
    }
    return true;
  }
}
