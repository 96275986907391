import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CondFields, DataGroup, DataTable } from '../../data';
import { DataService } from '../../services/data.service';
import { LookupData, WidgetEvent, Nav, WidgetState } from '../../widgets';
import { CommunicationService } from 'src/app/services/communication.service';
import { UtilsService } from 'src/app/services/utils.service';
import { XfwValidator } from 'src/app/widget/validators/xfw.validator';
import { ValidatorService } from 'src/app/services/validator.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class FormComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() refresher: number;
  @Input() widgetId: string;
  @Input() dataGroupItemInstance: DataGroup;

  public window = window;

  @Input() rowIndex: number;
  @Input() focus: string;
  @Input() nav: Nav;
  @Input() navList: string[] = ['primary', 'secondary'];
  @Input() formClass: string;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onPaste: EventEmitter<any> = new EventEmitter();

  @ViewChildren('formControl') formControls: QueryList<any>;

  public direction: {
    left: boolean,
    right: boolean;
  } = {
      left: false,
      right: false
    };

  public editMode = true;
  public _rowIndex = 0;
  public JSON = JSON;
  public condFields: any = [];

  public globalPristine = true;

  public warning = { text: '' };

  public loaded = false;

  public component = this;
  public errors = false;

  public lookupData: LookupData[] = [];
  public lookupTables = {};

  public proportionalFields = {};

  public navForm: Nav;
  public navInlineForm: Nav;

  public form: UntypedFormGroup;

  constructor(
    public communicationService: CommunicationService,
    private xfwValidator: XfwValidator,
    private validatorService: ValidatorService,
    private notificationService: NotificationService,
    private utilsService: UtilsService,
    private dataService: DataService) {
  }

  ngAfterViewInit() {
    if (this.focus) {
      this.setFocus({ dataItem: { focus: this.focus } });
    }
  }

  setFocus(params) {
    setTimeout(() => {
      if (params.dataItem.focus) {
        if (this.formControls.some(x => x.nativeElement && x.nativeElement.id === params.dataItem.focus)) {
          this.formControls.find(x => x.nativeElement && x.nativeElement.id === params.dataItem.focus).nativeElement.focus();
        }
        else if (this.formControls.some(x => x.id && x.id === params.dataItem.focus)) {
          this.formControls.find(x => x.id === params.dataItem.focus).inputElement.nativeElement.focus();
        }
      }
    });
  }

  ngOnInit() {
    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.FOCUS,
          func: 'setFocus'
        },
        {
          widgetGroup: [this.widgetId.split('-')[0]],
          event: WidgetEvent.REFRESH,
          func: 'refresh'
        },
        {
          widgetGroup: [this.widgetId.split('_')[0]],
          event: WidgetEvent.FORCEDREFRESHED,
          func: 'refreshed'
        }
      ]
    });

    if (this.dataGroupItemInstance.dataTable[0].persist && localStorage.getItem(this.widgetId)) {
      const localStorageItem = JSON.parse(localStorage.getItem(this.widgetId));
      if (localStorageItem) {
        this.dataGroupItemInstance.dataTable[0].data[this.rowIndex] = localStorageItem;
      }
    }

    this.initForm();
    this.initNav();
  }

  refreshed() {
    this.updateFormGroup(this.dataGroupItemInstance.dataTable, this.rowIndex);
    this.getLookupData();
  }

  refresh() {
    this.updateFormGroup(this.dataGroupItemInstance.dataTable, this.rowIndex);
    this.getLookupData();
  }

  initForm() {
    this._rowIndex = this.rowIndex;
    this.form = this.toFormGroup(this.dataGroupItemInstance.dataTable, this.rowIndex);
    this.checkCondFields();

    this.loaded = true;

    this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].invalid = !this.form.valid;
    this.refreshFormState([]);
  }

  getLookupData(init: boolean = true) {
    if (init) {
      this.dataService.initLookupData(this.lookupData, this.dataGroupItemInstance.dataTable, this.rowIndex);

      this.lookupData.forEach(table => {
        this.lookupTables[table.src] = table;
      });

      this.dataGroupItemInstance.dataTable[0].fields.forEach(group => {
        group.fieldGroup.forEach(field => {
          if (field.xfw.inputData) {
            field.xfw.inputData.table = this.lookupData.find(table => table.src === field.xfw.inputData.src);
          }
        });
      });
    }

    // empty tables in een clone, zodat de data niet mee hoeft naar de server.
    const lookupData = structuredClone(this.lookupData);
    lookupData.forEach(table => {
      table.data = [];
    });

    this.dataService.getLookupData(lookupData).then((data: any[]) => {
      if (data) {
        ['src', 'func'].forEach(source => {
          this.lookupData.filter(lookupDataRow => lookupDataRow[source]).forEach(table => {
            if (data.some(row => row[source] === table[source])) {
              table.data = data.find(row => row[source] === table[source]).data;
            }

            if (source === 'func' && table.data) {
              Object.keys(table.data).forEach(key => {
                if (this.form.controls[key]) {
                  this.form.controls[key].patchValue(table.data[key]);
                }
              });
            }
          });
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    (this.focus) && this.setFocus({ dataItem: { focus: this.focus + '-' + this.rowIndex.toString() } });

    if ((changes.rowIndex && !changes.rowIndex.firstChange && this.rowIndex >= 0) || changes.refresher && !changes.refresher.firstChange) {
      const onChange = () => {
        this._rowIndex = this.rowIndex;

        this.updateFormGroup(this.dataGroupItemInstance.dataTable, this.rowIndex);
        this.getLookupData();
        this.form.markAsPristine();
      };

      if (this.form.valid && !this.form.pristine) {
        this.globalPristine = false;
        this._rowIndex < this.dataGroupItemInstance.dataTable[0].data.length &&
          this.saveData(this._rowIndex, this.dataGroupItemInstance.dataTable[0].data[this._rowIndex].crud || 'update').then((result) => {
            onChange();
          });
      } else {
        onChange();
      }
    } else {
      this.getLookupData();
    }

    // check of dit wel altijd goed gaat.

    setTimeout(() => {
      if (this.dataGroupItemInstance.dataTable[0].dataCondResult?.length) {
        this.dataGroupItemInstance.dataTable[0].dataCondResult.forEach((condFieldsItem) => {
          if (this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].trackBy === condFieldsItem.trackBy) {
            switch (condFieldsItem.val) {
              case 'disable':
                this.form.controls[condFieldsItem.key].disable();
                break;
              case 'enable':
                this.form.controls[condFieldsItem.key].enable();
                break;
            }
          }
        });
      }
    });
  }

  initNav() {
    this.navForm = {
      navId: -80,
      primary: {
        doNotExecute: !this.form.valid,
        doNotExecuteFunc: 'showErrors',
        icon: 'fa-light fa-paper-plane',
        text: 'Opslaan',
        type: 'button',
        className: "text xfw3-bg-primary rounded-corners",
        info: '',
        params: [],
        disabled: this.form.pristine,
        func: 'submitForm',
        msg: {
          action: WidgetEvent.DONE,
          notification: {
            type: 'info'
          },
          title: 'Opgeslagen',
          text: '',
        }
        // widgetAction: [
        //   {
        //     widgetGroup: [this.widgetId],
        //     event: WidgetEvent.SAVE
        //   }
        // ]
      },
      secondary: {
        doNotExecute: !this.form.valid,
        doNotExecuteFunc: 'showErrors',
        icon: 'fa-light fa-trash',
        isInvisible: !this.dataGroupItemInstance.dataTable[0].persist,
        text: '',
        type: '',
        className: "icon xfw3-bg-secondary rounded-corners",
        info: '',
        disabled: false,
        func: 'clearForm',
        widgetAction: [
          {
            widgetGroup: [this.widgetId],
            event: WidgetEvent.UNDO
          }
        ]
      }
    };

    this.navInlineForm = {
      navId: -81,
      primary: {
        icon: 'dripicons dripicons-checkmark',
        className: "icon xfw3-bg-primary round xs",
        text: '',
        info: '',
        type: 'submit',
        disabled: false,
        func: "submitForm",
        // widgetAction: [
        //   {
        //     widgetGroup: [this.widgetId],
        //     event: WidgetEvent.SAVE
        //   }
        // ],
      }
    };

    this.navForm.primary = { ...this.navForm.primary, ...this.nav?.primary };
    this.navForm.secondary = { ...this.navForm.secondary, ...this.nav?.secondary };
    this.navForm.alt = { ...this.navForm.alt, ...this.nav?.alt };

    this.navInlineForm.primary = { ...this.navInlineForm.primary, ...this.nav?.primary };

    this.navForm.primary.doNotExecute = !this.form.valid;
    this.navInlineForm.primary.doNotExecute = !this.form.valid;

    (this.globalPristine) && (this.globalPristine = this.form.pristine);

    this.navForm.primary.disabled = this.globalPristine;
    this.navInlineForm.primary.disabled = this.globalPristine;
  }

  public onClose(event: any) {
    // use this to style the popup box for a dropdown or a calendar.
    //event.preventDefault();
  }

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([this.widgetId]);
  }

  public filterChange(src, val: any): void {
    (val.length > 2) && this.refreshLookupData('searchTxt', src, val);
  }

  clearForm() {
    localStorage.removeItem(this.widgetId);
    this.dataGroupItemInstance.dataTable[0].data[this.rowIndex] = structuredClone(this.dataGroupItemInstance.dataTable[0].originalData.find(row => row.trackBy === this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].trackBy));
    this.initForm();
  }

  cellFocus(key) {
    this.dataGroupItemInstance.dataTable[0].selected = {
      rowIndex: this.rowIndex,
      cell: key
    };
  }

  paste(event, formControl?) {
    //event.preventDefault();

    // this.utilsService.convertFromClipboardTableToTableData().then(clipBoardData => {
    //   if (clipBoardData.length === 1 && clipBoardData[0].length === 1) {
    //     formControl.setRangeText(clipBoardData[0][0], formControl.selectionStart, formControl.selectionEnd, 'end');
    //   } else {
    //     this.onPaste.emit(event);
    //   }
    // });
  }

  checkInput(key, type?) {
    this.errors = false;
    const value = this.form.controls[key].value;

    if (typeof value === 'object' && value !== null) {
      this.form.controls[key].markAsDirty();

      Object.keys(value).forEach(valueKey => {
        if (this.form.controls[valueKey]) {
          value[valueKey] && this.form.controls[valueKey].patchValue(value[valueKey]);
          this.refreshLookupData(valueKey);
          this.form.controls[valueKey].markAsDirty();
        }
      });
    } else {
      this.refreshLookupData(key);
    }

    if (type && type === 'inputCalc') {
      const prevValue: number = this.dataGroupItemInstance.dataTable[0].data[this.rowIndex][key];
      const eValue = eval(value);

      ((this.proportionalFields[key]?.length && eValue !== 0 && eValue) ||
        (!this.proportionalFields[key]?.length)) && this.form.controls[key].patchValue(eValue);

        this.proportionalFields[key]?.length && eValue !== 0 && eValue && this.proportionalFields[key].forEach(field => {
        const prevFieldValue: number = this.form.controls[field].getRawValue();

        prevFieldValue && prevValue && (this.form.controls[field].patchValue((eValue / prevValue * prevFieldValue).toFixed(1)));
      });
    }

    (this.dataGroupItemInstance.dataTable[0].persist) &&
      this.saveData(this.rowIndex).then(data => {
        localStorage.setItem(this.widgetId, JSON.stringify(data));
      });

    this.check(key);

    (this.dataGroupItemInstance.edit.updateMutations === 'direct' && !this.form.pristine) &&
      this.saveData(this.rowIndex, 'update')
        .then((data: any[]) => {
          this.refreshFormState(data);

          this.communicationService.performAction({
            info: 'form ' + this.dataGroupItemInstance.edit.type,
            widgetGroup: [this.widgetId.includes('-') ? this.widgetId.split('_form-')[0]+'_form' : this.widgetId],
            event: WidgetEvent.SAVE,
            dataItem: data
          });

          return data;
        });
  }

  refreshFormState(data: any[]) {
    this.communicationService.performAction({
      info: 'form ' + this.dataGroupItemInstance.edit.type,
      widgetGroup: [this.widgetId.split('-')[0]],
      event: this.dataGroupItemInstance.dataTable[0].data.some(row => row.invalid) ? WidgetEvent.FORMSINVALID : WidgetEvent.FORMSVALID,
      dataItem: data
    });
  }

  @HostListener('keydown.control.d', ['$event'])
  ctrlD(event: KeyboardEvent) {
    event.preventDefault();

    this.communicationService.performAction({
      info: 'form',
      widgetGroup: [this.widgetId.split('_')[0]],
      event: WidgetEvent.COPY,
      dataItem: this.dataGroupItemInstance.dataTable[0].selected
    });
  }

  @HostListener('keydown.control.delete', ['$event'])
  ctrlDelete(event: KeyboardEvent) {
    event.preventDefault();

    setTimeout(() => {
      this.communicationService.performAction({
        info: 'form',
        widgetGroup: [this.widgetId.split('_')[0]],
        event: WidgetEvent.DELETE,
        dataItem: this.dataGroupItemInstance.dataTable[0].selected
      });
    });
  }


  /**
   *
   * checkKeyUp uses the normal validators and checks the disabled buttons
   * against them. If the validator is Invalid, the condition is invalid.
   */

  checkKeyUp({ field, type, event, inputArea }) {
    const selectionStart = inputArea.selectionStart || 0;
    const valueLength = this.form.controls[field].value === undefined || this.form.controls[field].value === null ? 0 : this.form.controls[field].value.toString().length;

    (!['Tab', 'Enter'].includes(event.code)) && this.check(field);

    let rowIndex = this.rowIndex;

    const performAction = (rowIndex, key) => {
      this.communicationService.performAction({
        info: 'form',
        widgetGroup: [this.widgetId.split('-')[0] + '-' + rowIndex.toString()],
        event: WidgetEvent.FOCUS,
        dataItem: {
          focus: key + '-' + rowIndex.toString(),
        }
      });
    };

    switch (event.key) {
      case 'ArrowLeft':
        this.direction.right = false;

        if (selectionStart === 0 && this.direction.left) {
          const findIndex = this.dataGroupItemInstance.dataTable[0].cache[0].child.findIndex(dataField => dataField.key === field);
          if (findIndex > 0) {
            performAction(rowIndex, this.dataGroupItemInstance.dataTable[0].cache[0].child[findIndex - 1].key);
          }
        }

        this.direction.left = (selectionStart === 0);

        break;
      case 'ArrowRight':
        this.direction.left = false;

        if (selectionStart === valueLength && this.direction.right) {
          const findIndex = this.dataGroupItemInstance.dataTable[0].cache[0].child.findIndex(dataField => dataField.key === field);

          if (findIndex < this.dataGroupItemInstance.dataTable[0].cache[0].child.length - 2) {
            performAction(rowIndex, this.dataGroupItemInstance.dataTable[0].cache[0].child[findIndex + 1].key);
          }
        }

        this.direction.right = (selectionStart === valueLength);

        break;
      case 'ArrowUp':
        (rowIndex > 0) && performAction(--rowIndex, field);
        break;
      case 'ArrowDown':
        (rowIndex < this.dataGroupItemInstance.dataTable[0].data.length - 1) && performAction(++rowIndex, field);
        break;
      case 'Home':
        performAction(rowIndex = 0, field);
        break;
      case 'End':
        performAction(rowIndex = this.dataGroupItemInstance.dataTable[0].data.length - 1, field);
        break;
      default:
      // console.log(event.key);
    }
  }

  check(key) {
    (this.globalPristine) && (this.globalPristine = this.form.pristine);

    this.navForm.primary.disabled = this.globalPristine;
    this.navInlineForm.primary.disabled = this.globalPristine;

    this.navList.forEach(navItem => {
      if (this.navForm[navItem].disabledCond?.condField.length) {
        let valid = this.utilsService.checkCond(this.form.getRawValue(), this.navForm[navItem].disabledCond);

        this.navForm[navItem].disabled = (valid && this.form.status === 'VALID') ? false : true;
        this.navForm[navItem].doNotExecute = (valid && this.form.status === 'VALID') ? false : true;
      } else {
        this.navForm[navItem].doNotExecute = false;
      }
    });

    this.checkCondFields();
  }

  checkCondFields() {
    setTimeout(() => {
      this.condFields.forEach(condFieldsItem => {
        switch (this.utilsService.checkCond(this.form.getRawValue(), condFieldsItem.condFields)) {
          case 'disable':
            this.form.controls[condFieldsItem.key].disable();
            break;
          case 'enable':
            this.form.controls[condFieldsItem.key].enable();
            break;
          default:
        };
      });
    });
  }

  refreshLookupData(key, src?, val?) {
    if (this.lookupData.some(x => (x.cascadeFrom && x.cascadeFrom.map(x => x.key).indexOf(key) >= 0 && (x.func || x.src === (src ? src : x.src))))) {
      this.lookupData.filter(x => x.cascadeFrom).forEach(item => {

        const param = item.params.find(x => x.key === key);

        val = val || this.form.controls[key].value;

        if (param && param.val !== val) {
          param.val = val;
          item.refresh = true;
        }
      });
    }

    this.getLookupData(false);
  }

  updateFormGroup(dataTable: DataTable[], selected) {
    dataTable[0].fields.forEach(group => {
      group.fieldGroup.forEach(fieldGroup => {
        this.form.controls[fieldGroup.key].patchValue(dataTable[0].data[selected][fieldGroup.key], { emitEvent: true });
        this.form.controls[fieldGroup.key].markAsPristine();
      });
    });

    this.checkCondFields();
  }

  toFormGroup(dataTable: DataTable[], selected) {
    const formGroup: any = {};
    let skip = false;

    dataTable[0].fields.forEach(group => {
      group.fieldGroup.forEach(fieldGroup => {
        let value = null;
        skip = false;

        fieldGroup.proportional && (this.proportionalFields[fieldGroup.key] = fieldGroup.proportional);

        switch (fieldGroup.type) {
          case 'hide':
          case 'hidden':
            value = dataTable[0].data.length ? dataTable[0].data[selected][fieldGroup.key] : null;
            break;
          case 'inputMl':
            value = dataTable[0].data.length ? dataTable[0].data[selected][fieldGroup.key].text : null;
            break;
          case 'password':
            fieldGroup.templateOptions['showPassword'] = false;
            break;
          case 'dropdownList':
          case 'comboBox':
          case 'multiSelect':
          case 'optionListCheckbox':
          case 'optionListRadio':
            value = dataTable[0].data.length ? dataTable[0].data[selected][fieldGroup.key] : null;
            (value === null || value === undefined) && (value = fieldGroup.xfw.inputData.defaultItem);
            break;

          case 'email':
            formGroup[fieldGroup.key] = new UntypedFormControl({
              value: dataTable[0].data.length ? dataTable[0].data[selected][fieldGroup.key] : null,
              disabled: false
            },
            {
              updateOn: 'change',
              validators: fieldGroup.templateOptions.required ?
                Validators.compose([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)]) :
                Validators.compose([Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)])
            });
            skip = true;
            break;

          case 'inputCalc':
          case 'number':
            formGroup[fieldGroup.key] = new UntypedFormControl({
              value: dataTable[0].data.length ? dataTable[0].data[selected][fieldGroup.key] : null,
              disabled: false
            },
            {
              updateOn: 'change',
              validators: fieldGroup.templateOptions.required ?
                Validators.compose([Validators.required, Validators.min(fieldGroup.templateOptions.min), Validators.max(fieldGroup.templateOptions.max)]) :
                Validators.compose([Validators.min(fieldGroup.templateOptions.min), Validators.max(fieldGroup.templateOptions.max)])
            });
            skip = true;
            break;

          default:
            value = dataTable[0].data.length ? dataTable[0].data[selected][fieldGroup.key] : null;
        }

        (fieldGroup.condFields?.condField) &&
          this.condFields.push({
            key: fieldGroup.key,
            condFields: fieldGroup.condFields
          });

        !skip && (fieldGroup.templateOptions.required ?
          formGroup[fieldGroup.key] = new UntypedFormControl({
            value: value,
            disabled: false
          },
          {
            updateOn: 'change',
            validators: Validators.compose([Validators.required, Validators.min(fieldGroup.templateOptions.min), Validators.max(fieldGroup.templateOptions.max)])
          })
          :
          formGroup[fieldGroup.key] = new UntypedFormControl({
            value: value,
            disabled: false
          },
          {
            updateOn: 'change'
          }));
      });
    });

    return new UntypedFormGroup(formGroup,
      {
        validators: [this.xfwValidator.dynamicValidator(this.validatorService, this.warning, this.dataGroupItemInstance.formGroupValidation)],
        updateOn: 'blur'
      });
  }

  submitForm(params) {
    return new Promise((resolve, reject) => {
      params.forEach(param => {
        (param.key !== 'crud') && this.form.controls[param.key].patchValue(param.val);
      });

      this.onSubmit(this.utilsService.objectFromParams(params)).then(result => {
        resolve(result);
      });
    });
  }

  submit(params) {
    this.onSubmit(this.utilsService.objectFromParams(params)).then(result => {
    });
  }

  onSubmit(paramObject?): Promise<any> {
    return new Promise((resolve, reject) => {
      let crud: string = null;

      this.dataGroupItemInstance.dataTable[0].primaryKeys?.forEach(item => {
        (this.dataGroupItemInstance.dataTable[0].data[this.rowIndex][item.key] === null
          || this.dataGroupItemInstance.dataTable[0].data[this.rowIndex][item.key] === undefined) && (crud = 'create');
      });

      (this.form.valid && (!this.form.pristine || !this.globalPristine)) ?
        this.saveData(this.rowIndex, paramObject?.crud || crud)
          .then((data: any[]) => {
            (['direct', 'save'].includes(this.dataGroupItemInstance.edit.updateMutations)) ?
              this.communicationService.performAction({
                info: 'form onSubmit',
                widgetGroup: [this.widgetId],
                event: paramObject?.widgetEvent ? paramObject?.widgetEvent : WidgetEvent.SAVE,
                data: data
              }) :
              (this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].crud = this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].crud === 'edit' ? 'update' : this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].crud);

            (this.dataGroupItemInstance.edit.updateMutations === 'save' && this.navForm.primary.msg?.notification?.type) &&
              this.notificationService.addNotification({
                msgType: this.navForm.primary.msg.notification.type,
                msg: {
                  title: this.navForm.primary.msg.title,
                  body: { text: this.navForm.primary.msg.text }
                }
              });

            (this.dataGroupItemInstance.edit.type !== 'inline') &&
              this.communicationService.performAction({
                info: 'close forms',
                widgetGroup: [this.widgetId],
                event: WidgetEvent.CLOSE
              });

            localStorage.removeItem(this.widgetId);

            this.navForm.primary.disabled = true;
            this.navInlineForm.primary.disabled = true;

            resolve(data);
          }) :
        this.showErrors();

    });
  }

  showErrors() {
    this.errors = true;
  }

  saveData(rowIndex, crud?) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const formData = this.form.getRawValue();

        this.dataGroupItemInstance.dataTable[0].fields.forEach(group => {
          group.fieldGroup.forEach(fieldGroup => {
            switch (fieldGroup.type) {
              case 'inputMl':
                formData[fieldGroup.key] = { "text": formData[fieldGroup.key] = formData[fieldGroup.key] ?? fieldGroup.defaultValue };
                break;
              case 'hidden':
                delete formData [fieldGroup.key];
                break;
              default:
                formData[fieldGroup.key] = formData[fieldGroup.key];
            }
          });
        });

        if (this.dataGroupItemInstance.dataTable[0].data.length) {
          Object.assign(this.dataGroupItemInstance.dataTable[0].data[rowIndex], formData); // Object.fromEntries(Object.entries(formData).filter(([key, val]) => val != null)));
          this.dataGroupItemInstance.dataTable[0].data[rowIndex].crud = crud || this.dataGroupItemInstance.dataTable[0].data[rowIndex].crud || 'update';
        } else {
          rowIndex = 0;
          this.dataGroupItemInstance.dataTable[0].data.push({ ...formData });
          this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].crud = crud || 'create';
        }

        this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].invalid = !this.form.valid;

        resolve(this.dataGroupItemInstance.dataTable[0].data[this.rowIndex]);
      });
    });
  }

  trackByFn(index, item) {
    return `${this.rowIndex}-${index}`;
  }
}
