import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of, lastValueFrom } from 'rxjs';
import { Procedure, ReportRequest } from '../widgets';
import { LocalStorageService } from 'angular-web-storage';
import { ConfiguratorResult } from '../configurator';
import { SessionStorageService } from './session-storage.service';
import { PrintCom } from './configurator-printcom.service';

@Injectable({
  providedIn: 'root'
})
//
export class NetworkService {

  private readonly baseUrl: string = isDevMode() ? 'http://localhost:8081' : 'https://xfw-hub.sflex.nl';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService
  ) {}

  /**
   * This method will get data from a url
   * @param url this is the location this funtion will GET its data
   * @param responseType Type of data you're expecting to get
   * @param RemoveBaseUrl in development mode this variable needs to be true if u use a external api
   */

  public get(url: string, responseType: 'text' | 'json' | 'blob' | 'arraybuffer', RemoveBaseUrl?: boolean): Observable<any> {

    const requestUrl = (RemoveBaseUrl ? '' : this.baseUrl) + (url);

    switch (responseType) {
      case 'text':
        return this.http.get(requestUrl, {responseType: 'text'});

      case 'json':
        return this.http.get(requestUrl, {responseType: 'json'});

      case 'blob':
        return this.http.get(requestUrl, {responseType: 'blob'});

      case 'arraybuffer':
        return this.http.get(requestUrl, {responseType: 'arraybuffer'});
    }
  }

  /**
   * This method posts data to a url and returns the return message
   * @param url this is the location this funtion will POST its data
   * @param params this is the data that will be posted to the url
   * @param responseType Type of data you're expecting to get
   * @param RemoveBaseUrl in development mode this variable needs to be true if u use a external api
   */
  // tslint:disable-next-line:max-line-length
  public post(url: string, params: any, responseType: 'text' | 'json' | 'blob' | 'arraybuffer', RemoveBaseUrl?: boolean): Observable<any> {
    const requestUrl = (RemoveBaseUrl ? '' : this.baseUrl) + (url);

    switch (responseType) {
      case 'text':
        return this.http.post(requestUrl, params, {responseType: 'text'});

      case 'json':
        return this.http.post(requestUrl, params, {responseType: 'json'});

      case 'blob':
        return this.http.post(requestUrl, params, {responseType: 'blob'});

      case 'arraybuffer':
        return this.http.post(requestUrl, params, {responseType: 'arraybuffer'});
    }
  }

  /**
   * This method is used to query data from the query table
   * @param Params Statement Paramenters
   * @param responseType Type of data you're expecting to get
   */
  public executeStatement(params: StatementObj, responseType: 'text' | 'json' | 'blob' | 'arraybuffer'): Observable<any> {
    return this.post(
      '/api/Query/Query',
      params,
      responseType
    );
  }

  /**
   * This method is used to query data from the query table
   * @param Params Statement Paramenters
   * @param responseType Type of data you're expecting to get
   */
  public query(params: Procedure[], responseType: 'text' | 'json' | 'blob' | 'arraybuffer'): Observable<any> {

    /**
     * dynamic values:
     * now(),
     * weekDay()
     *
     * There could be more...
     *
     **/

    const dynamicValues = ['now()', 'weekDay()'];

    params.forEach(query => {
      query.params?.forEach(param => {
        if (param.val && dynamicValues.includes(param.val)) {
          switch (param.val) {
            case 'now()':
              param.val = new Date();
              break;
            case 'weekDay()':
              param.val = new Date().getDay();
              break;
          }
        }
      });
    });

    if (params.length) {
      return this.post(
        '/api/Query',
        {
          Query: JSON.stringify(params),
          Config: JSON.stringify(this.sessionStorage.getSessionData)
        },
        responseType
      );
    } else {
      return of([]);
    }

  }

  public crud(params: Procedure[], responseType: 'text' | 'json' | 'blob' | 'arraybuffer'): Observable<any> {

    params.forEach(param => {
      if (param.mail?.params) {
        param.mutations.forEach(mutation => {
          param.mail.params.forEach(mailParam => {
            mutation[mailParam.key] = mailParam.alias ? mutation[mailParam.alias] : mailParam.val;
          });
        });
      }
    });

    return this.post(
      '/api/Query/CRUD',
      {
        Procedural: JSON.stringify(params),
        Config: JSON.stringify(this.sessionStorage.getSessionData)
      },
      responseType
    );
  }

  public localStorageSet(params: Procedure[]): any {
    this.localStorage.set(params[0].procedure, params[0].mutations);
    return params[0].mutations;
  }

  public localStorageGet(params: Procedure[]): any {
    return this.localStorage.get(params[0].procedure);
  }

  public getPrintComProduct(code: string) {
    return this.get('/api/PrintOnline/printcom/products/' + code, 'json');
  }

  public getPrintComPrice(code: string, config: PrintCom.PriceCalculationRequest) {
    return this.post(`/api/PrintOnline/printcom/price/${ code }`, config, 'json') as Observable<PrintCom.PriceCalculationResponse>
  }

  public getProboProduct(code: string) {
    return this.get('/api/PrintOnline/probo/products/' + code, 'json');
  }

  public calculateProboProducts(request: ConfiguratorResult) {
    return this.post('/api/PrintOnline/probo/price', request, 'json');
  }

  public getHtml(Url: string): Observable<any> {
    return this.http.get(Url, {responseType: 'text'});
  }

  public aiTranslate({ from, target, text }: { from: string, target: string, text: string }) {
    return this.post('/api/translate', { from, target, text }, 'text');
  }

  public log = {
    origin: 'Init',
    level: '',
    contentJSON: {}
  };

  logSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.log);
  log$ = this.logSubject.asObservable();

  setLog(origin: string, level: number, contentJSON: any) {
    // console.log(origin, contentJSON);

    this.logSubject.next({
      origin: origin,
      level: level,
      contentJSON: contentJSON
    });
  }

  getLog() {
    return this.log$;
  }

  async getReport(reports: ReportRequest[]): Promise<PromiseSettledResult<string | ArrayBuffer>[]> {
    const reportRequests = reports.map(report => {

      return lastValueFrom(
        report.mail !== undefined ?
          this.post('/api/report', report, 'text') :
          this.post('/api/report', report, 'blob')
      );

    });

    return Promise.allSettled(reportRequests)
  }

}

interface StatementObj {
  Description: string;
  Params: object;
}
