import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DataGroup} from '../../data';
import { NetworkService} from '../../services/network.service';
import { Subscription} from 'rxjs';
import { Param, WidgetEvent, WidgetState } from '../../widgets';
import { DataService } from 'src/app/services/data.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-data-group',
  templateUrl: './data-group.component.html',
  styleUrls: ['./data-group.component.css']
})
export class DataGroupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() widgetId: string;
  @Input() src: string;
  @Input() dataGroup: DataGroup[];
  @Input() selected: string;
  @Input() params: Param[];
  @Input() dataItem: any;

  public loaded = false;
  public queryParams: Param[] = [];

  constructor(
    public dataService: DataService,
    public communicationService: CommunicationService,
    public userService: UserService,
    public utilsService: UtilsService) { }

  ngOnInit(): void {
    this.queryParams = this.communicationService.queryParams;

    const setDataGroup = () => {
      this.dataService.setInitialParams(this.queryParams, this.utilsService.paramsFromObject(this.communicationService.user), this.dataGroup);
      this.dataService.setParams(this.queryParams, this.utilsService.paramsFromObject(this.communicationService.user), [], this.dataGroup, true);

      this.loaded = true;
      this.selectDataGroupItem(this.selected);
      this.mergeQueryParams(this.dataGroup);
    }

    if (!this.dataGroup) {
      this.dataService.loadDataGroup(this.src, this.dataGroup).then((dataGroup: DataGroup[]) => {
        this.dataGroup = dataGroup;

        // TODO: is dit al beter of moet er nog een filter op voor de fromData?

        this.dataGroup.forEach(dataGroupItem => {
          dataGroupItem.params = this.utilsService.mergeParams({
            params: dataGroupItem.params,
            paramsToMerge: this.params,
            append: true,
            checkQueryParam: false,
            deepCopy: true
          });
        });

        setDataGroup();
      });
    } else {
      this.params?.length && this.dataGroup.forEach(dataGroupItem => {
        dataGroupItem.params = this.utilsService.mergeParams({
          params: dataGroupItem.params,
          paramsToMerge: this.params,
          append: true,
          checkQueryParam: false,
          deepCopy: true
        });
      });

      setDataGroup();
    }
  }

  mergeQueryParams(dataGroup: DataGroup[]) {
    this.dataGroup.filter(dataGroupItem => !dataGroupItem.isInvisible).forEach(dataGroupItem => {
      this.communicationService.mergeQueryParams(this.widgetId, dataGroupItem.params, [], null, true);
    });
  }

  selectDataGroupItem(selected: string): void {
    selected && this.dataGroup &&
      this.dataGroup.forEach(dataGroupItem => {
        dataGroupItem.widgetId === selected ? dataGroupItem.isInvisible = false : dataGroupItem.isInvisible = true;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectDataGroupItem(this.selected);
  }

  ngOnDestroy(): void {
    this.communicationService.destroyQueryParams(this.widgetId);
  }
}
