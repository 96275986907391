<div class="svg-container">
  <ng-container *ngIf="selectedMapped as selectedComponent">
    <div class="selected-info" *ngIf="$is(selectedComponent, SvgToolObjectComponent)" style="color: var(--secondary-color);">
      OPP.: {{ selectedComponent.pathBoundArea | number: '1.2-2' }} m2, LENGTE: {{ selectedComponent.pathLength | number: '1.2-2'}} mm, FREESDUUR: {{ selectedComponent.pathCutDuration | number: '1.1-1' }} seconden
    </div>
  </ng-container>
  <div class="total">
    OPP.: {{ totalCutArea | number: '1.2-2'}} m2, LENGTE: {{ totalPathLength | number: '1.2-2'}} mm, FREESDUUR: {{ totalCutDuration | number: '1.1-1'}} seconden
  </div>
  <div class="cutter">
    SNELHEID: 20 meter per minuut<br />
    ACCELERATIE: 9.81 x 0.7 m/s2 | 0.7G<br />
    VOLLEDIGE STOP: 0.1 seconden<br />
    <br />
    O.b.v materiaalsoort en dikte uit configuratie<br />
  </div>

  <div class="beta">
    Inhaker Design Tool BETA
  </div>


  <svg class="svg" [ngClass]="[view, povAlignment === -1 ? 'inverse-x' : '']" viewBox="-0.5 0.5 1 3.0" #svg
    stroke-width="0.007">
    <defs>
      <g id="target-icon" transform="">
        <g transform="translate(-0.0445 -0.0325)">
          <text class="svg-icon" x="0" y="0" style="font-size: 0.09px;">{{"\ue59f"}}</text>
        </g>
        <circle r="0.0485" opacity="0" />
      </g>
      <filter x="-0.001" y="-0.001" width="1.002" height="1.002" id="solid">
        <feFlood flood-color="#171717" />
        <feMerge>
          <feMergeNode in="bg" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>

    <ng-container *rerender="pov">

      <ng-container *ngFor="let object of objects">
        <g *ngIf="$is(object, Inhaker)" app-svg-tool-inhaker [object]="object"
          attr.transform="translate({{object.x}},{{object.y}})"
          [ngClass]="[view, object === selected ? 'selected' : '']" [attr.hidden]="object.hidden ? true : undefined">
        </g>

        <g *ngIf="$is(object, WeightObject)" app-svg-tool-object [object]="object" [objects]="objects"
          attr.transform="translate({{object.x}},{{object.y}})"
          [ngClass]="[view, object === selected ? 'selected' : '']" [attr.hidden]="object.hidden ? true : undefined">
        </g>

        <g *ngIf="$is(object, Bench)" app-svg-tool-bench [object]="object" [inhaker]="objects[object.inhaker]"
          attr.transform="translate({{object.x}},{{object.y}})"
          [ngClass]="[view, object === selected ? 'selected' : '']" [attr.hidden]="object.hidden ? true : undefined">
        </g>
      </ng-container>

      <ng-container *ngIf="view === 'weight'">
        <ng-container *ngFor="let object of diagnostics.drawable">
          <ng-container *ngIf="$is(object, Vector)">
            <line [attr.x1]="object.x" [attr.x2]="object.x2" [attr.y1]="object.y" [attr.y2]="object.y2"
              [attr.stroke]="object.fill" class="ignore" stroke-dasharray="0.015,0.01" />
          </ng-container>
          <ng-container *ngIf="$is(object, ForceVector)">
            <line [attr.x1]="object.x" [attr.x2]="object.x + object.xLen * forceScale" [attr.y1]="object.y"
              [attr.y2]="object.y + object.yLen * forceScale" [attr.stroke]="object.fill" class="ignore"
              opacity="0.3" />
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </svg>

  <div class="pov-button-container">
    <button class="pov-button" [ngClass]="{selected: pov === 'left'}"
      style="left:0; top: 50%; transform:translate(-50%,-50%)" (click)="transition('left')">links</button>
    <button class="pov-button" [ngClass]="{selected: pov === 'front'}"
      style="bottom:0; left: 50%; transform: translate(-50%, 50%)" (click)="transition('front')">voor</button>
    <button class="pov-button" [ngClass]="{selected: pov === 'right'}"
      style="right:0; top: 50%; transform:translate(50%,-50%)" (click)="transition('right')">rechts</button>
    <button class="pov-button" [ngClass]="{selected: pov === 'back'}"
      style="top:0; left: 50%; transform: translate(-50%, -50%)" (click)="transition('back')">achter</button>
  </div>

  <div style="position: absolute; top: 4px; right: 4px;">
    <div class="error-card" *ngFor="let warning of warnings">{{warning}}</div>
  </div>
</div>


<div class="info">
  <div class="buttons">
    <button class="ihdt-button" (click)="copyAll()">clipboard -> ai</button>
    <button class="ihdt-button bd" (click)="addObject('weight')">paneel</button>
    <button class="ihdt-button bd" (click)="addObject('weight', 'a')">pijl</button>
    <button class="ihdt-button bd" (click)="addObject('weight', 'ar')">afgeronde pijl</button>
    <button class="ihdt-button bd" (click)="addObject('weight', 'c')">cirkel</button>
    <button class="ihdt-button bd" (click)="addObject('pole')">inhaker</button>
    <button class="ihdt-button bg bd" (click)="view = view === 'real' ? 'weight': 'real'">{{view === 'real' ? 'stabiliteit' : 'design' }}</button>
  </div>
</div>
