import { Directive, ElementRef, Host, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[horizontalScroll]'
})
export class HorizontalScrollDirective {

  private nativeElement: HTMLElement;
  @HostBinding('scrollLeft') private scrollLeft;

  constructor(@Host() element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  @HostListener('wheel', ['$event']) wheelTurned(wheelEvent: WheelEvent) {
    if (this.nativeElement.scrollWidth > this.nativeElement.clientWidth && wheelEvent.deltaY && this.nativeElement.classList.contains('horizontal')) {
      this.scrollLeft = this.nativeElement.scrollLeft + wheelEvent.deltaY;
      wheelEvent.preventDefault();
    }
  }
}
