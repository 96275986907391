import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Resource, Step } from 'src/app/services/planning.service';
import { Nav } from 'src/app/widgets';
import { DataPlannerComponent } from '../data-planner.component';
import { DataPlannerTaskViewComponent } from '../data-planner-task-view/data-planner-task-view.component';


export type DataPlannerTaskOrientation = 'row' | 'col';

const defaultVisibility = {
  checkbox: true,
  menuButton: true,
  map: false,

  body: true,
  footer: true,

  popover: false,
  popoverMap: true
};

export type DataPlannerTaskVisibilityOptions = typeof defaultVisibility;

export const withDefaultVisibility = (v: Partial<DataPlannerTaskVisibilityOptions>) => ({ ...defaultVisibility, ...v });

@Component({
  selector: 'app-data-planner-task',
  templateUrl: './data-planner-task.component.html',
  styleUrls: ['./data-planner-task.component.scss']
})
export class DataPlannerTaskComponent implements AfterViewInit {
  @Input({ required: true }) task: Step;
  @Input({ required: true }) resource: Resource;
  @Input() orientation: DataPlannerTaskOrientation = 'col';
  @Input({ alias: 'visibility' }) set inputVisibility(v: Partial<DataPlannerTaskVisibilityOptions>) { this.visibility = withDefaultVisibility(v); }


  @Output() checked = new EventEmitter<Step>();

  public withDefaultVisibility = withDefaultVisibility;
  public visibility = defaultVisibility;
  public get hourToWidthMultiplier() { return this.parent.hourToWidthMultiplier; }


  public navUnplanned: Nav = {
    navId: -22,
    menu: [
      {
        icon: '',
        text: 'In eerste vrije blok inplannen',
        className: "text xfw3-bg-primary round",
        info: '',
        params: [
          {
            key: "func",
            val: "firstAvailableMoment"
          },
          {
            key: "planId",
          }
        ],
        paramsFrom: [
          {
            key: "planId",
          }
        ],
        disabled: false,
        func: 'jobFunc'
      },
      {
        icon: '',
        text: 'In eerste vrije blok inplannen bij specifieke monteurs',
        className: "text xfw3-bg-primary round",
        info: '',
        params: [
          {
            key: "func",
            val: "relIfirstAvailableMomentWithEmployee"
          },
          {
            key: "planId",
          }
        ],
        paramsFrom: [
          {
            key: "planId",
          }
        ],
        disabled: false,
        func: 'jobFunc'
      }
    ]
  };

  constructor(
    private parent: DataPlannerTaskViewComponent
  ) { }
  ngAfterViewInit(): void {
    console.log(this.task);
  }

  public markChecked() {
    const task = this.task;

    task.checked = !task.checked;
    this.checked.emit(task);
  }
}
