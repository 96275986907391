import { Component, OnInit, OnDestroy, ElementRef, HostListener, ViewChild, AfterViewChecked, AfterViewInit } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { Nav, Param, WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.css']
})

export class DataListComponent extends DataGenericComponent implements OnInit, OnDestroy, AfterViewInit {
  public layout = {
    dataListHeight: 0,
    dataRowHeight: 0,
  };

  @ViewChild('dataList', { static: false }) dataList: ElementRef | undefined;
  @ViewChild('gridFooter', { static: false }) gridFooter: ElementRef | undefined;

  @HostListener('window:resize')
  onResize() {
    if (this.gridFooter?.nativeElement && this.dataList?.nativeElement) {
      const dataListHeight = this.gridFooter?.nativeElement.getBoundingClientRect().top - this.dataList?.nativeElement.getBoundingClientRect().top;
      if (dataListHeight !== this.layout.dataListHeight && ['scroll', 'virtual-scroll','pagination'].includes(this.dataGroupItemInstance.paging)) {

        this.layout.dataListHeight = dataListHeight;
        this.layout.dataRowHeight = this.dataItems?.toArray()[0]?.nativeElement.getBoundingClientRect().height;

        this.dataGroupItemInstance.page = Math.ceil(this.layout.dataListHeight / (this.layout.dataRowHeight || 40)) - (this.dataGroupItemInstance.paging === 'pagination' ? 2 : 0);
        !this.dataGroupItemInstance.scroll && (this.dataGroupItemInstance.scroll = this.dataGroupItemInstance.page);

        this.setCurrentPage();
      }
    }
  }

  initResize() {
    setTimeout(() => {
      this.onResize();
    });
  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this.component,
      state: WidgetState.OK,
      subscribeTo: [
        {
          event: WidgetEvent.REFRESHED,
          func: 'initResize',
        }
      ]
    });

    this.initNav();
    super.ngOnInit();
    this.edit.mode = false;

    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this.component,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [this.widgetId + '_form'],
          event: WidgetEvent.CLOSE,
          func: 'close'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.CREATED,
          func: 'copied'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.DELETED,
          func: 'deleted'
        }
      ]
    });
  }

  copied(params) {
    setTimeout(() => {
      this.dataGroupItemInstance.dataTable[0].selected.rowIndex = this.dataGroupItemInstance.dataTable[0].data.length - 1;
    });
  }

  deleted(params) {
    setTimeout(() => {
      if (this.dataGroupItemInstance.dataTable[0].selected.rowIndex > this.dataGroupItemInstance.dataTable[0].data.length - 1) {
        this.dataGroupItemInstance.dataTable[0].selected.rowIndex = this.dataGroupItemInstance.dataTable[0].data.length - 1;
      }
    });
  }

  initNav() {
    // this.nav = {
    //   primary: {
    //     icon: 'fa-solid fa-plus',
    //     text: '',
    //     className: "icon xfw3-bg-secondary round xxs",
    //     type: '',
    //     info: 'Toevoegen',
    //     func: 'addNewRow',
    //     params: [],
    //     disabled: false,
    //     isInvisible: !this.dataGroupItem.edit.create
    //   }
    // }
  }

  addNewRow(params: Param[], dataItem?, data?) {
    this.addRow({
      params,
      dataItem,
      data
    });

    if (['save'].includes(this.dataGroupItem.edit.updateMutations)) {
      this.setEditMode({
        params: [],
        dataItem: this.dataGroupItemInstance.dataTable[0].data.find(row => row.isSelected),
        data: []
      });
    }
  }

  close(event) {
    if (this.dataGroupItemInstance.dataTable) {
      this.dataGroupItemInstance.dataTable[0].data.forEach(row => row.edit = false);
      this.edit.mode = false;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
