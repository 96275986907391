import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[divBreakpointClass]'
})
export class DivBreakpointClassDirective implements OnDestroy {
  @Output() breakpointClass: EventEmitter<any> = new EventEmitter();

  public _classNameWidth: string = '';
  public _classNameHeight: string = '';
  private observer: ResizeObserver;

  public breakpointClassesWidth = [
    {
      classNameWidth: 'xxs',
      minWidth: 0,
      maxWidth: 300
    },
    {
      classNameWidth: 'xs',
      minWidth: 300,
      maxWidth: 577
    },
    {
      classNameWidth: 'sm',
      minWidth: 577,
      maxWidth: 768
    },
    {
      classNameWidth: 'md',
      minWidth: 668,
      maxWidth: 798
    },
    {
      classNameWidth: 'ml',
      minWidth: 798,
      maxWidth: 992
    },
    {
      classNameWidth: 'lg',
      minWidth: 992,
      maxWidth: 1200
    },
    {
      classNameWidth: 'xl',
      minWidth: 1200,
      maxWidth: 1400
    },
    {
      classNameWidth: 'xxl',
      minWidth: 1400,
      maxWidth: 10000
    }
  ];

  public breakpointClassesHeight = [
    {
      classNameHeight: 'h-xs',
      minHeight: 0,
      maxHeight: 180
    },
    {
      classNameHeight: 'h-md',
      minHeight: 180,
      maxHeight: 300
    },
    {
      classNameHeight: 'h-ml',
      minHeight: 300,
      maxHeight: 720
    },
    {
      classNameHeight: 'h-lg',
      minHeight: 720,
      maxHeight: 100000
    }
  ];

  constructor(private zone: NgZone, public renderer: Renderer2, public hostElement: ElementRef) {
    renderer.addClass(hostElement.nativeElement, 'div-breakpoint');

    this.observer = new ResizeObserver(() => {
      this.zone.run(() => {
        this.setBreakpointClass(this.hostElement.nativeElement.offsetWidth, this.hostElement.nativeElement.offsetHeight, window.innerHeight);
      });
    });

    this.observer.observe(hostElement.nativeElement);
  }

  ngOnDestroy() {
    this.observer.unobserve(this.hostElement.nativeElement);
  }

  setBreakpointClass(width: number, height: number, windowHeight?: number) {
    if (width !== 0 && height !== 0) {
      const breakpointClass: {
        classNameWidth: string;
        classNameHeight?: string;
        minWidth?: number;
        maxWidth?: number;
        width?: number;
        height?: number;
        windowHeight?: number;
      } = {...this.breakpointClassesWidth.find(breakpointClass => breakpointClass.minWidth < width && breakpointClass.maxWidth >= width) || { classNameWidth: '' },
          ...this.breakpointClassesHeight.find(breakpointClass => breakpointClass.minHeight < height && breakpointClass.maxHeight >= height) || { classNameHeight: '' } };

      if (breakpointClass?.classNameWidth !== this._classNameWidth) {
        this._classNameWidth && this.renderer.removeClass(this.hostElement.nativeElement, this._classNameWidth);

        this.renderer.addClass(this.hostElement.nativeElement, breakpointClass.classNameWidth);
        this._classNameWidth = breakpointClass?.classNameWidth;
      }

      if (breakpointClass?.classNameHeight !== this._classNameHeight) {
        this._classNameHeight && this.renderer.removeClass(this.hostElement.nativeElement, this._classNameHeight);

        this.renderer.addClass(this.hostElement.nativeElement, breakpointClass.classNameHeight);
        this._classNameHeight = breakpointClass?.classNameHeight;
      }

      breakpointClass.width = width;
      breakpointClass.height = height;
      breakpointClass.windowHeight = windowHeight;
      this.breakpointClass.emit(breakpointClass);
    }
  }
}
