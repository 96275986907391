import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent extends DataGenericComponent implements OnInit, OnDestroy {
  @Input() contentJSON: any;

  @Input() interval: number = 6000;
  @Input() height: number = 400;
  @Input() darkenBackground: boolean = true;
  @Input() isInHeader: boolean = false;

  public currentSlide: number = 0;
  public showNavigationArrows: boolean = true;
  private timer = setInterval(() => this.onNextClick(), this.interval);
  public timerRunning: boolean;

  get getOffsetClasses(): Array<string> {
    const blockLayoutGrid = this.contentJSON?.blockLayoutGrid[0];
    const specifiedValue = this.dataService.blockLayoutGridData[blockLayoutGrid ?? 'xfw3-col-1']
    const defaultValue = this.dataService.blockLayoutGridData['xfw3-col-1']

    return (specifiedValue ?? defaultValue)
      .split(" ")
      .filter(className => className.includes('g-offset'))
      .map(className => "col-" + className.substr(9));
  }

  get getWidthClasses(): Array<string> {
    const blockLayoutGrid = this.contentJSON?.blockLayoutGrid[0];
    const specifiedValue = this.dataService.blockLayoutGridData[blockLayoutGrid ?? 'xfw3-col-1']
    const defaultValue = this.dataService.blockLayoutGridData['xfw3-col-1']

    return (specifiedValue ?? defaultValue)
      .split(" ")
      .filter(className => className.includes('g-col'))
      .map(className => className.substr(2));
  }

  showArrows(state: boolean) {
    this.showNavigationArrows = state;
  }

  resetTimer() {
    clearInterval(this.timer);
    this.timerRunning = false;
    this.startTimer();
  }

  startTimer() {
    this.timer = setInterval(() => this.onNextClick(), this.interval);
    this.timerRunning = true;
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.contentJSON.cols[0].col[0].img.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.contentJSON.cols[0].col[0].img.length ? 0 : next;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    clearInterval(this.timer);
  }
}
