<div class=" xfw3-grid-container" divBreakpointClass>
  <div class="price-tier">
    <div class="header xfw3-grid-col-container">
      <div class="g-col-2">
        Staffel
      </div>

      <div class="g-col-2">
        Levertijd
      </div>

      <div class="g-col-2">
        Staffelprijs
      </div>

      <div class="g-col-2 discount-header">
        Korting
      </div>
    </div>

    <div *ngFor="let priceTierItem of data" class="xfw3-grid-col-container">
      <div class="g-col-2">
        {{ priceTierItem.amount ? priceTierItem.amount + 'x' : '' }}
      </div>

      <div class="g-col-2">
        {{ priceTierItem.delivery }}
      </div>

      <div class="g-col-2 justify-self-end pe-4">
        {{ priceTierItem.price | number : '1.2-2' }}
      </div>

      <div class="g-col-2 justify-self-end discount" *ngIf="priceTierItem.discount > 0">
        {{ priceTierItem.discount | number : '1.2-2'  }}
      </div>
    </div>
  </div>
</div>
