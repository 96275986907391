<div #viewport
     class="container-fluid xfw3-grid-container"
     divBreakpointClass (breakpointClass)="checkSizes($event)"
     [ngClass]="[contentJSON.blockLayoutType ?? '', contentJSON.blockCombine ?? '', environmentJSON.viewportMode ? 'multi-col' : '']"
     [style.height]="!heightToTopOfPage ? 'unset' : 'calc(100vh - ' + heightToTopOfPage + 'px)'">

    @if (contentJSON.insetBgLeft) {
    <div class="inset-bg-left d-none d-md-block" [style.top.px]="contentJSON.insetBgLeft.top || 0"
      [style.height]="'calc(100% - ' + (contentJSON.insetBgLeft.top || 0) * 2 + 'px)'"
      [style.width]="contentJSON.insetBgLeft.width || '6%'">
      <img [src]="contentJSON.insetBgLeft.image" [style.height.px]="contentJSON.insetBgLeft.imageHeight || 1000">
    </div>
    }

    @if (contentJSON.insetBgRight) {
    <div class="inset-bg-right d-none d-md-block" [style.top.px]="contentJSON.insetBgRight.top || 0"
      [style.height]="'calc(100% - ' + (contentJSON.insetBgRight.top || 0) * 2 + 'px)'"
      [style.width]="contentJSON.insetBgRight.width || '6%'">
      <img [src]="contentJSON.insetBgRight.image" [style.height.px]="contentJSON.insetBgRight.imageHeight || 1000">
    </div>
    }

  <div class="b-t-line"></div>

  @if (contentJSON.title.length) {
    <div class="row" [ngClass]="[contentJSON.rowClassName ?? '', environmentJSON.viewportMode ? 'viewportMode' : '', 'xfw3-grid-col-container']">
      <div [ngClass]="contentJSON.blockLayoutGridTitle ? dataService.blockLayoutGridData[contentJSON.blockLayoutGridTitle] : dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
        <h2>
          @for (item of contentJSON.title; track $index) {
            @if (item.className) {
              <div class="header" [class]="item.className" [innerHTML]="item.text"></div>
            }@else {
              <span [innerHTML]="item.text"></span>
            }
          }
        </h2>
      </div>
    </div>
  }

  <div [ngClass]="[contentJSON.rowClassName ?? '', 'xfw3-grid-col-container', environmentJSON.viewportMode ? 'viewportMode' : '']"
       [style.grid-template-rows]="!contentJSON.gridTemplateColRows ? 'unset' : contentJSON.gridTemplateColRows">
    @for (cols of contentJSON.cols; let colsIndex = $index; track colsIndex) {
      <div [ngClass]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[colsIndex <= (contentJSON.blockLayoutGrid.length - 1) ? colsIndex : contentJSON.blockLayoutGrid.length - 1]]">
        <div [ngClass]="[!heightToTopOfPage ? '' : 'xfw3-grid-row-container']"
                        [style.grid-template-rows]="!heightToTopOfPage ? 'unset' : cols.gridTemplateRows">

          @for (col of cols.col; let index = $index; track index) {
            <div [ngClass]="[!heightToTopOfPage ? '' : 'xfw3-grid-row', col.className ?? '']">
              @for (item of col.body; track $index) {
                @if (item.title) {
                  <h4>
                    <div class="header" [class]="item.titleClassName">{{ item.title }}</div>
                  </h4>
                }

                @if (item.text) {
                  <p
                    [class]="item.className"
                    [innerHTML]="(item.text ? item.text : '&nbsp;') | safeHtml">
                  </p>
                }

                @if (item.list) {
                  <ul>
                    @for (text of item.list; track $index) {
                      <li [innerHTML]="(text ? text : '&nbsp;') | safeHtml"></li>
                    }
                  </ul>
                }

                @if (item.orderedList) {
                  <ol>
                    @for (listItem of item.orderedList; track $index) {
                      <li [innerHTML]="(listItem.text ? listItem.text : '&nbsp;') | safeHtml"></li>
                    }
                  </ol>
                }

                @if (item.img) {
                  <div (click)="item.img[0].windowOpen ? windowOpen(item.img[0].windowOpen,'_blank') : null;"
                    style="background-repeat: no-repeat; display: inline-block; vertical-align: bottom;"
                    [style.--aspect-ratio]="item.img[0].aspectratio ? item.img[0].aspectratio : 1.5"
                    [style.background-size]="item.img[0].size ? item.img[0].size : 'cover'"
                    [style.background-position-x]="item.img[0].positionX ? item.img[0].positionX : 'center'"
                    [style.width]="item.img[0].width ? item.img[0].width : '100%'"
                    [style.min-height]="item.img[0].minheight"
                    [style.height]="item.img[0].height"
                    [style.cursor]="item.img[0].windowOpen ? 'pointer' : 'default'"
                    [style.background-image]="'url(' + item.img[0].url + ')'">
                  </div>
                }

                @if (item.nav) {
                  <div [class]="item.nav.className">
                    <app-cmd
                      [nav]="item.nav"
                      [navList]="['custom','primary']"
                      [navVisible]="0"
                      [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
                      [collapsed]="false"/>
                  </div>
                }
              }

              @if (col.issuu) {
                <div>
                  <div class="d-block d-lg-none xfw-rowSpacer"></div>
                  <app-issuu [pdf]="col.issuu[0].pdf"></app-issuu>
                </div>
              }

              @if (col.embed) {
                <div>
                  <iframe [src]="col.embed.url" frameborder="0" allowfullscreen></iframe>
                </div>
              }

              @if (col.img) {
                <div [ngClass]="[col.img[0].className ?? '']">
                  <div style="background-repeat: no-repeat; display: inline-block; vertical-align: bottom;"
                      [style.--aspect-ratio]="col.img[0].aspectratio ? col.img[0].aspectratio : 1.5"
                      [style.background-size]="col.img[0].size ? col.img[0].size : 'cover'"
                      [style.background-position-x]="col.img[0].positionX ? col.img[0].positionX : 'center'"
                      [style.width]="col.img[0].width ? col.img[0].width : '100%'"
                      [style.min-height]="col.img[0].minheight"
                      [style.height]="col.img[0].height"
                      [style.background-image]="'url(' + col.img[0].url + ')'"
                      [style.margin-top]="col.img[0].marginTop ? col.img[0].marginTop : '0px'">
                  </div>
                </div>
              }

              @if (col.svg) {
                <div [ngClass]="[col.svg[0].className ?? '']">
                  <div style="background-repeat: no-repeat; background-position: center;"
                    [style.--aspect-ratio]="col.svg[0].aspectratio ? col.svg[0].aspectratio : 1.5"
                    [style.background-size]="col.svg[0].size ? col.svg[0].size : 'cover'"
                    [style.width]="col.svg[0].width ? col.svg[0].width : '100%'"
                    [style.min-height]="col.svg[0].minheight"
                    [style.width]="col.svg[0].width"
                    [style.height]="col.svg[0].height"
                    [style.background-image]="'url(' + col.svg[0].url + ')'">
                  </div>
                </div>
              }

              @if (col.video) {
                <div>
                  <div class="d-block d-lg-none xfw-rowSpacer"></div>
                  <video [src]="col.video[0].url" [class]="col.video[0].className" controls></video>
                </div>
              }

              @if (col.rowClassName) {
                <div [ngClass]="[col.rowClassName]"></div>
              }

              @if (col.paramJSON) {
                <app-data-group
                  [widgetId]="col.paramJSON.widgetId"
                  [src]="col.paramJSON.dataGroup"
                  [dataGroup]="col.paramJSON.dataGroupJSON"
                  [params]="col.paramJSON.params"/>
              }

              @if (col.tabstripJSON) {
                <app-nav
                [widgetId]="col.tabstripJSON.widgetId"
                [src]="col.tabstripJSON.container"
                [nav]="col.tabstripJSON.tabstripJSON"/>
              }

              @if (col.navJSON) {
                <div [ngClass]="[col.navJSON.className ? col.navJSON.className : '', 'h-100']">
                  <app-nav
                    [widgetId]="col.navJSON.widgetId"
                    [src]="col.navJSON.src"
                    [nav]="col.navJSON.navDataJSON.nav"/>
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
