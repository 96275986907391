@if (loaded) {
  <div class="option-list-container">
    @for (item of dataGroupItemInstance.dataTable[0].data; let rowIndex = $index; let last = $last; track rowIndex) {
      <div class="option-list">

        <div class="row-wrapper"
              [ngClass]="[dataGroupItemInstance.rowOptions.className ?? '',
                !dataGroupItemInstance.rowOptions.rowCollapsable ? 'collExpHidden' : '']">

          <div (click)="collExp(item)" [ngClass]="['expcol', 'fa-regular', ['', 'fa-chevron-down','fa-chevron-right'][!item.collapsed ? 0 : item.collapsed]]">
          </div>

          <div class="expcol-row">
            <app-data-row #dataItem
              [widgetId]="widgetId"
              (onSelect)="select($event)"
              (onSetEditMode)="setEditMode($event)"
              (onUpdateMutations)="updateMutations()"
              [item]="item"
              [dataGroupItemInstance]="dataGroupItemInstance"
              [focus]="dataGroupItemInstance.dataTable[0].selected?.rowIndex === rowIndex ? dataGroupItemInstance.dataTable[0].selected.cell : null"
              [rowIndexOffset]="dataGroupItemInstance.top"
              [rowIndex]="rowIndex"
              [selectedIndex]="index"
              [last]="last"/>
          </div>
        </div>

        @if (item.collapsed === 1) {
          <div>
            <app-component-list
              [widgetId]="dataGroupItemInstance.children[0].widgetId + '_' + (item | primaryKey : dataGroupItemInstance.dataTable[0].primaryKeys)"
              [dataGroupItem]="dataGroupItemInstance.children[0]"
              [dataItem]="item"/>

            <app-option-info
              [widgetId]="dataGroupItemInstance.children[1].widgetId  + '_' + (item | primaryKey : dataGroupItemInstance.dataTable[0].primaryKeys)"
              [dataGroupItem]="dataGroupItemInstance.children[1]"
              [dataItem]="item"/>
          </div>
        }
      </div>
    }

    <app-cmd [component]="component" [nav]="nav" [navList]="['primary']" [navVisible]="1"/>
  </div>
}
