import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomainConfigService } from 'src/app/services/domain-config.service';
import { UserService } from 'src/app/services/user.service';

@Directive({
  selector: '[accessLevel]'
})
export class AccessLevelDirective implements OnDestroy{
  private role: [string];

  @Input() set accessLevel(role) {
    this.role = role;
    this.viewContainer.clear();

    let roles = role?.reduce((sum, current) => sum + this.domainConfigService.domainConfig.roles[current], 0);

    if (!role || roles & this.user.authBitMask) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  public userSubscription: Subscription;
  public user: any;

  constructor(
    private viewContainer: ViewContainerRef,
    private domainConfigService: DomainConfigService,
    private userService: UserService,
    private templateRef: TemplateRef<any>) {
      this.userSubscription = this.userService.user$.subscribe((user) => {
        this.user = user;
        this.accessLevel = this.role;
     });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

}
