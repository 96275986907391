import { Component, Input, OnInit } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { NetworkService } from 'src/app/services/network.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-fibaro',
  templateUrl: './fibaro.component.html',
  styleUrls: ['./fibaro.component.css']
})
export class FibaroComponent implements OnInit {
  @Input() widgetId: string;
  @Input() dataGroupItemInstance: DataGroup;

  public component:any = this;

  public rooms: fibaroRoomFormatted[];
  private devices: fibaroDevice[];
  public accessDenied = false;

  constructor(private http: NetworkService, private notification: NotificationService) {
    this.init()
  }

  // TODO add socket

  private async init() {
    try {
      console.log('sync with fibaro!')
          this.rooms = await this.http.get('/api/Fibaro/rooms', 'json').toPromise();

          this.devices = await this.http.get('/api/Fibaro/devices', 'json').toPromise();

          for (let i = 0; i < this.rooms.length; i++) {
            this.rooms[i].hasDevices = false;

            for (let j  = 0; j < this.devices.length; j++) {
              const device = this.devices[j];

              if(device.room.id === this.rooms[i].id) {
                if(device.properties.categories[0] == 'lights' || device.properties.categories[0] == 'gates' &&
                  (device.properties.categories[1] && device.properties.categories[1]) != 'security') {

                  this.rooms[i].hasDevices = true;

                  if(!this.rooms[i].devices)
                  this.rooms[i].devices = [];

                  this.rooms[i].devices.push(device);
                }
              }
            }

          }
    } catch (e) {
      this.accessDenied = true;
    }

  }

  private findDeviceById(deviceId: number): fibaroDevice {
    return this.devices.find(device => device.id === deviceId);
  }

  /**
   * will switch the device that needs action
   *
   * @param deviceId Id of device to switch
   * @param state to turn of or on. If not filled in. It will switch on and then of.
   */
  async switchDevice(deviceId: number, state?: boolean) {
    console.log(state)

    if(state !== undefined) {
      // Toggle switch
      this.switch(deviceId, state);
      console.log('switch')

    } else {
      // Pulse switch
      this.pulse(deviceId);
      console.log('pulse')

    }
  }

  /**
   * will switch a device to the state specified
   *
   * @param deviceId
   * @param state the state that the device will be set to
   */
  private async switch(deviceId: number, state: boolean) {
    for (let j = 0; j < this.devices.length; j++) {
      const device = this.devices[j];

      if(device.id === deviceId) {
        device.properties.value = state;
        break;
      }
    }

    try {
      await this.http.post('/api/Fibaro/switchDevice', { deviceId: deviceId, toState: state ? 1 : 0 }, 'json').toPromise();
    } catch (e) {
      for (let j = 0; j < this.devices.length; j++) {
        const device = this.devices[j];

        if(device.id === deviceId) {
          device.properties.value = !state;
          break;
        }
      }
      this.notification.addNotification({
        statusCode: 500,
        msgType: 'error',
        msg: {
          title: 'Fibaro',
          body: {
            text: 'Het lukte niet om het apparaat te schakelen!',
            ml: {
              nl: {
                text: 'Het lukte niet om het apparaat te schakelen!'
              },
              en: {
                text: 'Failed to switch device!'
              }
            }
          }
        },
        autoHide: true
      });
    }
  }

  /**
   * will pulse the device on and then off
   *
   * @param deviceId
   */
  private async pulse(deviceId: number) {
    try {
      await this.http.post('/api/Fibaro/switchDevice', { deviceId: deviceId, toState: 1 }, 'json').toPromise();
      await this.http.post('/api/Fibaro/switchDevice', { deviceId: deviceId, toState: 0 }, 'json').toPromise();

      this.notification.addNotification({
        statusCode: 200,
        msgType: 'info',
        msg: {
          title: 'Fibaro',
          body: {
            text: `Apparaat ${this.findDeviceById(deviceId).name} is geactiveerd.`,
            ml: {
              nl: {
                text: `Apparaat ${this.findDeviceById(deviceId).name} is geactiveerd.`
              },
              en: {
                text: 'activated device ' + this.findDeviceById(deviceId).name
              }
            }
          }
        },
        autoHide: true
      })
    } catch(e) {
      this.notification.addNotification({
        statusCode: 500,
        msgType: 'error',
        msg: {
          title: 'Fibaro',
          body: {
            text: 'Het lukte niet om het apparaat te schakelen!',
            ml: {
              nl: {
                text: 'Het lukte niet om het apparaat te schakelen!'
              },
              en: {
                text: 'Failed to switch device!'
              }
            }
          }
        },
        autoHide: true
      })
    }
  }



  ngOnInit(): void {
  }

}

interface fibaroRoom {
  id: number,
  name: string,
  identifier: string
}

interface fibaroRoomFormatted extends fibaroRoom {
  devices?: fibaroDevice[],
  hasDevices?: boolean
}

interface fibaroDevice {
  id: number,
  name: number,
  room: fibaroRoom,
  identifiers: string[],
  properties: any,
  actions: object
}
