<div [ngClass]="['data-tab', 'xfw-hover', (item.isSelected ? 'selected' : '')]"
     (click)="onSelect([], item); $event.stopPropagation(); "
     (dblclick)="onSetEditMode([], item); $event.stopPropagation(); ">
  <ng-container *ngFor="let group of dataGroupItem.dataTable[0].fields; trackBy: trackByFn">
    <ng-container *ngFor="let fieldGroup of group.fieldGroup; trackBy: trackByFn">
      <div *ngIf="!fieldGroup.xfw.hidden && item.crud !== 'delete' && item[fieldGroup.key] !== undefined">
        <div [ngClass]="['data-tab-body']">
          <span [innerHTML]="(item[fieldGroup.key] ? item[fieldGroup.key] : '&nbsp;') | safeHtml"></span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
