<div *ngIf="loaded">

  <div style="width: 100%;"  [style.height.px]="options?.height ? options?.height : 750">
    <app-gmap
      type="locations"
      [dataGroupItem]="dataGroupItemInstance"
      [options]="options"
      [widgetId]="widgetId"
      [fit]="dataGroupItemInstance.fit">
    </app-gmap>
  </div>
  <app-data-generic-footer
    [widgetId]="widgetId"
    [dataGroupItemInstance]="dataGroupItemInstance"
    [component]="component"
    [headerPagination]="false"
    [(currentPage)]="currentPage"
    (onAddRow)="addRow($event)">
  </app-data-generic-footer>
</div>
