<ng-container *ngIf="loaded" cdkDropListGroup>
  <ng-container *ngFor="let dataGroupItem of dataGroup; let last = last;">

  <div *ngIf="!dataGroupItem.isInvisible"
    contextAware
    [dataItem]="dataGroupItem"
    [ngClass]="['blockMargin', 'h-100', dataGroupItem.isEmpty && dataGroupItem.edit.type === 'view' ? 'empty' : '', dataGroupItem.layout]">

    <ng-container *ngIf="loaded && (!dataGroupItem.isEmpty || (!['unset'].includes(dataGroupItem.edit.type)))">

      <app-data-group-item *ngIf="!['container'].includes(dataGroupItem.detailType)"
        [widgetId]="dataGroupItem.widgetId + (dataItem | paramsFromKey : dataGroupItem.params)"
        [dataGroupItem]="dataGroupItem"
        [dataItem]="dataItem">
      </app-data-group-item>

      <ng-container *ngIf="['container'].includes(dataGroupItem.detailType)">
        <app-data-group-item *ngFor="let child of dataGroupItem.children;"
          [widgetId]="child.widgetId"
          [dataGroupItem]="child"
          [dataItem]="dataItem">
        </app-data-group-item>
      </ng-container>
    </ng-container>
  </div>
  </ng-container>
</ng-container>
