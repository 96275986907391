<div class="container-fluid"
     [ngClass]="contentJSON.blockLayoutType">
  <div class="row py-5" [class]="contentJSON.blockCombine">
    <div class="col-12 p-0">
      <div class="row" [ngClass]="contentJSON.rowClassName">
        <ng-container *ngFor="let col of contentJSON.cols[0].col; let index = index; trackBy: trackByFn">
          <div *ngIf="col.paramJSON" [ngClass]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[index]]">
            <app-data-group-matrix *ngIf="contentJSON.cols[0].col[0].paramJSON?.container"
              [container]="contentJSON.cols[0].col[0].paramJSON.container"
              [src]="contentJSON.cols[0].col[0].paramJSON.container"
              [dataGroupMatrix]="contentJSON.cols[0].col[0].paramJSON.dataGroupJSON">
            </app-data-group-matrix>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
