import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';

@Component({
  selector: 'app-data-svg',
  templateUrl: './data-svg.component.html',
  styleUrls: ['./data-svg.component.css']
})
export class DataSvgComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public svgNotLoaded = true;

  @ViewChild('container') svgContainer;

  /**
   * this getter returns the selected elements. If you want the name. Just grab the ID of the element.
   */
  public get getSelectedIds() {
    // @ts-ignore
    return this.selectableElements.filter(el => el.firstElementChild.style.selected === 'true');
  }

  private svgElement;
  private selectableElements: Array<HTMLElement | undefined> = [];

  public localSvg;

    /**
   * This method will request all elements and initialize them from the svg.
   */

  ngOnInit() {
    super.ngOnInit();

    this.networkService.post('/api/SdFile/azureDownload', { url: this.utilsService.objectFromParams(this.dataGroupItem.params)['RemoteSvgUrl'] }, "text")
      .toPromise()
      .then((svg) => this.localSvg = svg)
      .then(() => this.svgToSelectableElements())
      .catch((err) => console.error(err));
  }


  /**
   * Init all selectable elements from the SVG.
   */
  private svgToSelectableElements(): any {
    setTimeout(() => {
      this.svgElement = this.svgContainer.nativeElement.firstElementChild;
      this.svgElement.style.maxHeight = '100%';

      let elements = this.svgElement.querySelectorAll("[id^='xfw'");
      elements = Array.from(elements);
      elements = elements.map(x => x.id);

      elements.forEach((element, i) => {
        const el = this.svgElement.getElementById(element);

        // Init element button
        if (el) {
          this.selectAction(el);

          // Adds selectable element when found
          this.selectableElements.push(el);
        }
      });

      this.svgNotLoaded = false;
    })

  }

  updateMutation(element, amount, statusId) {
    let columns = element.split("_");

    if (statusId === 1 && !this.dataGroupItem.dataTable[0].data.some(row => row.ProductId === columns[1])) {
      this.dataGroupItem.dataTable[0].data.push({
        crud: 'create',
        Amount: amount,
        ProductId: columns[1],
        Description: columns[2],
        StatusId: statusId
      });
    } else if (statusId === 0) {
      this.dataGroupItem.dataTable[0].data = this.dataGroupItem.dataTable[0].data.filter(row => row.ProductId !== columns[1]);
    }

    this.updateMutations();
  }

  /**
   * This method is responsible for selecting and deselecting elements from the SVG. selection is stored in the dom under styles.selected.
   * Not conventional, but is works. In this way there does not have to be another array that stores the selection of the elements.
   *
   * @param el Element Selection
   */
  private selectAction(el) {
    el.querySelectorAll("path").forEach(element => {
      if(element.style.selected == 'false') {
        element.style.paintOrder = 'stroke fill';
        element.style.strokeWidth = '50';
        element.style.stroke = 'var(--primary)';
        element.style.selected = 'true';

        if (el.id) {
          this.updateMutation(el.id, 1, 1);
        }

      } else {
        element.style.paintOrder = 'fill';
        element.style.strokeWidth = '0';
        element.style.selected = 'false';

        if (el.id) {
          this.updateMutation(el.id, 0, 0);
        }
      }
    });
  }

  /**
   * This method needs to be called by a (click)="func()" with $event provided. This method is responsible for
   * excecuting the select action for the right element id in the SVG.
   *
   * @param e Click Event
   */
  public clickHandler(e) {
    this.selectableElements.forEach(el => {
      const found = el.contains(e.target);

      if(found) {
        this.selectAction(el);
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
