import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { DataGroup } from '../../data';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'app-popup-form',
  templateUrl: './popup-form.component.html',
  styleUrls: ['./popup-form.component.css']
})
export class PopupFormComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() dataGroupItemInstance: DataGroup;

  @Input() rowIndex: number;
  @Input() edit: any;
  @Input() nav: any;
  @Input() navList: string[];
  @Input() window: any;

  @Output() onCloseWindow: EventEmitter<any> = new EventEmitter();
  @Output() onAddRow: EventEmitter<any> = new EventEmitter();

  constructor(
    private utilsService: UtilsService,
    private communicationService: CommunicationService
  ) {
  }

  ngOnInit(): void {
  }

  navigate(val) {
    if (val === -1 && this.rowIndex > 0) {
      // save current row
      this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].edit = false;
      this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].isSelected = false;
      this.rowIndex--;
      this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].isSelected = true;
    }

    if (val === 1 && this.rowIndex < this.dataGroupItemInstance.dataTable[0].data.length) {
      // save current row
      this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].edit = false;
      this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].isSelected = false;
      this.rowIndex++;
      this.dataGroupItemInstance.dataTable[0].data[this.rowIndex].isSelected = true;
    }
  }

  previous(event) {
    this.navigate(-1);
  }

  next(event) {
    this.navigate(1);
  }

  addRow(event) {
    this.onAddRow.emit(event);
  }

  closeWindow(event) {
    console.log('Close Window -> kwam hier');

    // this.onCloseWindow.emit(event);
  }

  cancelWindow(event) {
    if (this.dataGroupItemInstance.dataTable[0].data.find(row => row.crud === 'create')) {
      this.dataGroupItemInstance.dataTable[0].data.splice(this.dataGroupItemInstance.dataTable[0].data.findIndex(row => row.crud === 'create'), 1);
    } else {
      this.dataGroupItemInstance.dataTable[0].data.forEach(row => {
        row = structuredClone(this.dataGroupItemInstance.dataTable[0].originalData.find(originalRow => originalRow.trackBy === row.trackBy))
      });
    }

    this.onCloseWindow.emit(event);
  }

  ngOnDestroy(): void {
  }
}
