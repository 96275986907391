import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { UtilsService } from './utils.service';
import { CommunicationService } from './communication.service';
import { UserService } from './user.service';
import { ReportRequest, WidgetEvent } from '../widgets';
import { AuthService } from './auth.service';
import { DataTable } from '../data';
import { DataRowComponent } from '../widget/data-row/data-row.component';
import { resolve } from 'path';

@Injectable({
  providedIn: 'root'
})
export class CustomFuncService {
  dataTable: DataTable[] = [{
    src: "objectObserveStatus",
    procedure: "Action.Object_Observe_StatusCRUD",
    data: []
  }]

  constructor(
    private networkService: NetworkService,
    public communicationService: CommunicationService,
    public utilsService: UtilsService,
    public userService: UserService,
    private auth: AuthService,
  ) { }

  component = this;

  generic = {
    setApiMode(params, paramsFrom, dataItem, component) {
      component.communicationService.user.config.apiMode = !component.communicationService.user.config.apiMode;
      component.userService.setUser({ config: { apiMode: component.communicationService.user.config.apiMode }}, true);
    },
    logout(params, paramsFrom, dataItem, component) {
      let appState: number;

      component.auth.loginAnon()
        .then(() => appState = 1)
        .catch(() => appState = 1);
    },
    slideShow(params, paramsFrom, dataItem, component) {
      component.communicationService.performAction({
        widgetGroup: [params.find(param => param.key === 'widgetId').val],
        event: WidgetEvent.SLIDESHOW
      });
    },
    toggle(params, paramsFrom, dataItem, component) {
      paramsFrom.forEach(paramFrom => {
        dataItem.state[paramFrom.key] = !dataItem.state[paramFrom.key];
      });
    }
  }

  configurator = {
    check(params, dataItem?) {
      console.log('Check:', params, dataItem);
    },
    reset(params, dataItem?) {
      dataItem.attribValueId = null;
      dataItem.state.checked = false;
      dataItem.state.unChecked = true;
    },
    settings(params, dataItem?) {
      console.log('Settings:', params, dataItem);
    },
    calculate(params, dataItem, component) {
      component.calculate(params, dataItem);
    },
    continue(params, dataItem, component) {
      component.continue(params, dataItem);
    },
    confirmed(params, dataItem, component) {
      component.confirmed(params, dataItem);
    }
  };

  data = {
    insert(params, data?) {
      let record = {};
      record = {...record, ...data[0]};

      Object.keys(record).forEach(prop => {
        record[prop] = prop === 'crud' ? 'insert' : null;
      });

      data.push(record);
    },
    delete(params, dataItem?) {
      dataItem.crud = 'delete';
    },
    forceReload(params, paramsFrom, dataItem, component) {
      component.communicationService.performAction({
        info: 'action item',
        widgetGroup: [params.find(param => param.key === 'widgetId').val],
        event: WidgetEvent.REFRESH,
        dataItem: dataItem
      });
    }
  };

  reports = {
    sendMail(params, paramsFrom, dataItem, component) {
      const reports: ReportRequest[] = [];
      reports.push({
        reportName: params.find(param => param.key === 'reportName').val,
      	params: component.utilsService.paramsFromObject(dataItem).filter(params => paramsFrom.map(paramFrom => paramFrom.key).includes(params.key)),
        domainId: 3,
        mail: {
            mailTo: dataItem.mail.mailTo,
            subject: dataItem.mail.subject,
            mailName: dataItem.mail.mailName,
            mailParams: dataItem.mail.mailParams
        }
      });

      component.networkService.getReport(reports)
    },

    download(params, paramsFrom, dataItem, component) {
      const reports: ReportRequest[] = [];

      reports.push({
        reportName: params.find(param => param.key === 'reportName').val,
      	params: component.utilsService.paramsFromObject(dataItem).filter(params => paramsFrom.map(paramFrom => paramFrom.key).includes(params.key)),
        domainId: 3
      });

      (component.networkService as NetworkService).getReport(reports).then(data => {

        data.forEach(v => {
          if (v.status === 'fulfilled') {
            component.utilsService.downloadBlob(v.value);
          }
        });
      });
    }
  }

  lookup = {
    addressFromZipNo(params, paramsFrom, dataItem, component) {
      return new Promise((resolve, reject) => {
        if (params && !params.some(param => !param.val)) {
          params.find(param => param.key === 'zip').val = params.find(param => param.key === 'zip').val.replace(/\s/g, '');

          const subscription = component.networkService.post('/api/Parcel/DHL/resolveZip', component.utilsService.objectFromParams(params), 'json', false).subscribe({
            next: result => {
              resolve(result);

              subscription.unsubscribe();
            },
            error: err => {
              resolve({});

              subscription.unsubscribe();
            }
          });

        } else {
          resolve(null);
        }
      });
    }
  }


  perform(func, procedure, params, paramsFrom, dataItem?, component?, data?, event?, onlyWhenItemsAreChecked?: boolean) {
    return new Promise((resolve, reject) => {
      if (func) {
        if (func.split('.').length === 2) {
          this[func.split('.')[0]][func.split('.')[1]](params, paramsFrom, dataItem, this.component);
        } else {
          component[func](params, dataItem, data, event);
        }
      }

      if (procedure) {
        const mutations: any[] = [];

        (onlyWhenItemsAreChecked) ?
          data.filter(item => item.checked).forEach(item => {
            if (item.checked) {
              item.crud = this.utilsService.objectFromParams(params)['crud'];
              mutations.push(item);
            }
          })
          :
          mutations.push(params ? {...{ crud: 'update'}, ...this.utilsService.objectFromParams(params)} : dataItem)


        const subscription = this.networkService.crud([{
          db: 'xfw3',
          src: null,
          procedure: procedure,
          mail: null,
          mutations: mutations
        }], 'json').subscribe(result => {


          // Check if there is a report to download
          if(result[0][0]?.reportResultUrl) {
            let reportUrl: string = result[0][0].reportResultUrl;

            const downloadLink = document.createElement('a');

            downloadLink.href = reportUrl;
            downloadLink.target = '_blank';
            downloadLink.download = reportUrl.substring(reportUrl.lastIndexOf('/'), reportUrl.length);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

          }

          resolve(result);

          subscription.unsubscribe();
        });
      }
    });
  }

  performWithPromise(func, procedure, params, paramsFrom, dataItem?, component?, data?, event?) {
    return new Promise((resolve, reject) => {

      func && func.split('.').length === 2 ?
          this[func.split('.')[0]][func.split('.')[1]](params, paramsFrom, dataItem, this.component).then(result => {
            resolve(result);
          })
          :
          component[func](params, dataItem, data, event).then(result => {
            resolve(result);
          });

      if (procedure) {
        data.length ?
          data.forEach(dataItem => {
            if (dataItem.checked) {
              dataItem.crud = 'update';

              params.forEach(param => {
                dataItem[param.key] = param.val;
              });
            }
          })
          :
          dataItem = this.utilsService.objectFromParams(params);

        const subscription = this.networkService.crud([{
          db: 'xfw3',
          src: null,
          procedure: procedure,
          mail: null,
          mutations: [dataItem]
        }], 'json').subscribe(result => {
          resolve(result);

          subscription.unsubscribe();
        });
      }
    });
  }
}
