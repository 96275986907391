import { AfterViewInit, Component, ElementRef, HostListener, ViewChild, ViewContainerRef, OnInit, Input, Type } from '@angular/core';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';


type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export enum DragFlags {
  None = 0,
  XAxis = 1 << 0,
  YAxis = 1 << 1
}

export interface ISvgElement {
  ident: string;

  x: number;
  y: number;
  drag: DragFlags;
}

const splitMemory = new Map<string, string[]>();
const split = (str: string) => {
  let ret = splitMemory.get(str);

  if (!ret) {
    ret = str.split('\.');
    splitMemory.set(str, ret);
  }

  return ret;
};

@Component({
  template: '',
  styleUrls: ['./svg-draw.component.scss'],
})
export abstract class SvgDrawComponent extends DataGenericComponent implements AfterViewInit {
  protected abstract objects: ISvgElement[];

  protected abstract onUpdate(): void;
  protected abstract onDrag(element: ISvgElement, component: string | undefined, x: number, y: number): boolean | void;
  protected abstract onDragStart(element: ISvgElement, component: string | undefined, x: number, y: number): [number, number] | void;
  protected abstract onDragEnd(element: ISvgElement, component: string | undefined, x: number, y: number): [number, number] | void;

  /** The SVG element. */
  @ViewChild('svg') protected svg: ElementRef<SVGSVGElement> | undefined;

  // Helper functions

  /** Check if value v is of type T */
  $is = <T>(v: unknown, ...type: Type<T>[]): v is T => type.filter(ty => v instanceof ty).length > 0;
  $as = <T>(v: unknown, type: Type<T>): T => v as T;
  $filter = <T>(v: unknown[], type: Type<T>): T[] => v.filter(val => val instanceof type) as T[];

  Math = Math;

  // Drag state

  /** The object being dragged. */
  private dragging?: DistributiveOmit<ISvgElement, 'children'>;

  /** Wether any object is being dragged.
   * Use this to check whether dragging, to avoid unnecessary conversions.
  */
  private isDragging: boolean = false;
  private draggingComponent: string | undefined;

  // Offsets for dragging, so objects don't snap their centre to the mouse.
  private xOffset: number = 0;
  private yOffset: number = 0;


  // DOM-space to SVG-space conversion interfaces. Cached, creating them can be expensive.
  private point!: DOMPoint;
  private matrix!: DOMMatrix;


  // === Moving SVG elements code ===

  /** Find an object rendered in the SVG by it's identifier. */
  private findIdent(identComponent: string): [ISvgElement | undefined, string | undefined] {
    let element: ISvgElement | undefined;

    const [ident, component] = split(identComponent);

    element = this.objects.find(obj => obj.ident === ident);


    return [element, component];
  }

  /** Transform client screen-space coordinates to SVG-space coordinates. */
  private transformToSVGCoordinates(x: number, y: number): [x: number, y: number] {
    const pt = this.point;

    pt.x = x;
    pt.y = y;

    const svgPt = pt.matrixTransform(this.matrix);

    return [svgPt.x, svgPt.y];
  }

  @HostListener('pointerdown', ['$event'])
  public onPointerDown(event: PointerEvent): void {
    if (event.target == this.svg?.nativeElement) return;

    const id = (event.target as Element).id;

    let [element, component] = this.findIdent(id);

    if (!(element)) return;

    this.dragging = element;
    this.draggingComponent = component;

    const [x, y] = this.transformToSVGCoordinates(event.clientX, event.clientY);
    const [xOffset, yOffset] = this.onDragStart(element, component, event.clientX, event.clientY) || [0, 0];

    this.xOffset = element.x - x + xOffset;
    this.yOffset = element.y - y + yOffset;

    this.isDragging = true;
  }

  @HostListener('document:pointerup', ['$event'])
  private onPointerUp(event: PointerEvent): void {
    this.isDragging = false;

    this.onDragEnd(this.dragging!, this.draggingComponent, event.x + this.xOffset, event.y + this.yOffset);
  }

  @HostListener('document:pointermove', ['$event'])
  private onPointerMoveThrottle = (() => {
    // Throttled to 60 updates a second.
    let last = performance.now();

    return (event: PointerEvent) => {
      if (!this.isDragging) return;
      event.preventDefault();

      const now = performance.now();

      if (now - last < 10) return; // max 100x a second

      last = now;

      this.onPointerMove(event);
    };
  })();

  private onPointerMove(event: PointerEvent): void {

    const { dragging, draggingComponent } = this;

    let [x, y] = this.transformToSVGCoordinates(event.clientX, event.clientY);

    x += this.xOffset;
    y += this.yOffset;

    if (!this.onDrag(dragging!, draggingComponent, x, y)) {

      if (dragging!.drag & DragFlags.XAxis)
        dragging!.x = x;

      if (dragging!.drag & DragFlags.YAxis)
        dragging!.y = y;

    }

    this.onUpdate();
  }

  protected initSvgConversionMatrix(): void {
    if (!this.svg) throw new Error('No SVG Present');

    this.point = this.svg.nativeElement.createSVGPoint();
    this.matrix = this.svg.nativeElement.getScreenCTM()!.inverse();
  }

  @HostListener('window:resize')
  private _reInitMatrixOnResize() {
    this.initSvgConversionMatrix();
  }

  // === implements AfterViewInit ===

  public ngAfterViewInit(): void {
    this.initSvgConversionMatrix();
  }

}
