import { Injectable } from '@angular/core';
import { CondFields, CondField, DataTable } from '../data';
import { Param } from '../widgets';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }

  parseVariableToReference(variableName: string, object: any): { reference: any } {
    const variableNames = variableName.split('.');
    variableNames.pop();

    return { reference: variableNames.reduce((lastResult, currentValue) => lastResult[currentValue],  object) };
  }

  parseVariable(variableName: string, object: any): any {
    return { value: variableName.split('.').reduce((lastResult, currentValue) => lastResult[currentValue],  object) ?? '' };
  }

  parseTemplate(template: string, object: any): string {
    if (template.includes('${')) {
      let templateVariableNames = template.split('${').map(x => x.split('}')[0]).slice(1);

      templateVariableNames.forEach(variableName => {
        template = template.replace('${' + variableName + '}', variableName.split('.').reduce((lastResult, currentValue) => lastResult[currentValue],  object));
      });
    }

    return template;
  }

  getParamFromPathVal(path, key, object) {
    (path ? path.split(".").concat(key) : [key]).forEach(key => {
      object = object[key];
    });

    return object;
  }

  paramsChanged(params: any[] = [], oldParams: any[] = []): boolean {
    let paramsSet: boolean = false;

    params ?? (params = []);
    oldParams ?? (oldParams = []);

    const resolveAliasses = (_params: any[] = [], _oldParams: any[] = []) => {
      _params.forEach((param, index) => {
        param['uid'] = index;
      });

      _oldParams.forEach((oldParam) => {
        _params.some((param: any) => param.key === oldParam.key || param.alias === oldParam.key) &&
          (oldParam['uid'] = _params.find((param: any) => param.key === oldParam.key || param.alias === oldParam.key).uid);
      });

      _params.forEach((param) => {
        _oldParams.forEach((oldParam) => {
          if (oldParam.uid === param.uid) {
            oldParam.key = param.key;
            oldParam.alias = param.alias;
          }
        });
      });

      [..._params, ..._oldParams].forEach((param) => { delete param.uid; });
    };

    resolveAliasses(params, oldParams);
    // check of de params hetzelfde zijn als de oldParams. Zo ja, paramsSet = false;
    oldParams.length === params.length ?
      oldParams.forEach((oldParam) => {
          params.some((param) => param.key === oldParam.key &&
            ((param.val !== oldParam.val && !(param.val === null && oldParam.val === null))
            || param.isNav !== oldParam.isNav))
            || !params.some((param) => param.key === oldParam.key) ?
          paramsSet = true :
          null;
        }) :
        (paramsSet = true); // length is niet gelijk, dus paramsSet = true;

    return paramsSet;
  }

  queryParamsChanged(queryParams: Param[], newParams: Param[]): boolean {
    return this.paramsChanged(queryParams, newParams);
  }

  paramsFromDataItem(params, dataItem):Param[] {
    params?.length && dataItem &&
      params.forEach(param => {
        if (param.key.includes('.')) {
          param.val = this.parseVariable(param.key, dataItem).value;
          param.key = param.key.split('.')[param.key.split('.').length-1];
        } else {
          dataItem[param.key] && (param.val = dataItem[param.key]);
        }
      });

    return params ?? [];
  }

  /**
   *
   * @param queryParams de dataGroup params
   * @param newParams de url query params
   * @returns queryParams have value of zijn leeg
   *
   * Deze method heb ik 31-7-2021 aangepast. Waar eerst alle queryParams een
   * value moesten hebben voordat er een true terugkwam moeten nu 1 of meer
   * params een value hebben.
   */

  queryParamsHaveValuesOrEmpty(queryParams: Param[], newParams: Param[]): boolean {
    let haveValues = true;

    if (queryParams) {
      let _queryParams = structuredClone(queryParams);

      _queryParams.forEach(param => {
        param.alias && (param.key = param.alias);
      });

      _queryParams.forEach(param => {
        !param.val && !newParams.some(newParam => newParam.key === param.key && newParam.val) && (haveValues = false);
      });

      _queryParams.some(param => param.hasOwnProperty('val')) && (haveValues = true);
    }

    return haveValues;
  }

  objectFromParams(params: Param[]): any {
    return params.reduce((accum, param) => ({ ...accum, [param.key]: param.val }), {});
  }

  paramsFromObject(object: any): Param[] {
    const params: any[] = [];

    Object.keys(object).forEach(param => {
      params.push({
        key: param,
        val: object[param]
      });
    });

    return params;
  }

  getMethodCallerName() {
    try {
      throw new Error();
    }
    catch (e) {
      try {
        return {
          caller: e.stack.split('at ')[3].split(' ')[0],
          stack: e.stack.split('at ')
        };
      } catch (e) {
        return '';
      }
    }
  }

  mergeParams({ params, paramsToMerge, append, checkQueryParam, deepCopy = true }: { params: Param[], paramsToMerge: Param[], append: boolean, checkQueryParam: boolean, deepCopy: boolean }): Param[] {
    let localParams = deepCopy ? structuredClone(params) : params;

    if (Array.isArray(localParams) && Array.isArray(paramsToMerge)) {
      paramsToMerge.forEach((newParam, index) => {
        if (localParams.some(param => param.key === newParam.key || param.alias === newParam.key) && newParam.val !== null && newParam.val !== undefined) {
          const foundIndex = localParams.findIndex(param => param.key === newParam.key || param.alias === newParam.key);
          localParams[foundIndex] = {...localParams[foundIndex], ...newParam }; // zo gaat isQueryParam en isOptional ook mee, niet alleen de val.
        } else if (append) {
          if ((checkQueryParam && newParam.isQueryParam) || !checkQueryParam ) {
            if (!localParams.some(param => param.key === newParam.key)) {
              localParams.push(newParam);
            }
          }
        }
      });
    }

    return localParams;
  }

  paramsAlias({ params, paramsAlias }: { params: Param[], paramsAlias: Param[] }): Param[] {
    if (params) {
      const _params = structuredClone(params);

      _params.forEach(param => {
        if (paramsAlias.some(paramAlias => paramAlias.key === param.key && paramAlias.alias)) {
          param['alias'] = paramsAlias.find(paramAlias => paramAlias.key === param.key && paramAlias.alias).alias;
        }
      });
    }

    return params;
  }

  objectsAreEqual(item, compare) {
    return Object.keys(item).length === Object.keys(compare).length ? Object.keys(compare).every((prop: string) => item[prop]?.toString() === compare[prop]?.toString()) : false;
  }


  /**
   * @param item data-item containing all the props
   * @param compare subset (primary keys), subset of the props
   */

  isSubset(item, subset): boolean {
    return Object.keys(subset).every((prop: string) => item[prop]?.toString() === subset[prop]?.toString() && item.hasOwnProperty(prop));
  }

  /**
   * checkCond checks multiple conditions and returns true
   * if all conditions are met, else false.
   *
   * @param item -> data-item, from a dataGroupItem.dataTable[0].data. Single item.
   * @param condFields -> the conditional fields
   */
  checkCond(item: any, condFields: CondFields): any {
    if (condFields && Object.keys(condFields).length && condFields.condField.length && item && Object.keys(item).length) {
      const check = (condField: CondField) => {
        if (this.parseVariable(condField.key, item).value !== undefined || condField.key.includes('.length')) {
          switch (condField.operand) {
            case '&':
              return (parseInt(this.parseVariable(condField.key, item).value, 10) & parseInt(condField.check, 10)) > 0;
            case '|':
              return (parseInt(this.parseVariable(condField.key, item).value, 10) | parseInt(condField.check, 10)) > 0;
            case '===':
              return (condField.key.includes('.length')) ?
                item[condField.key.split('.')[0]] && item[condField.key.split('.')[0]].length === parseInt(condField.check, 10) :
                this.parseVariable(condField.key, item).value?.toString() === condField.check?.toString();
            case '!==':
              return this.parseVariable(condField.key, item).value.toString() !== condField.check.toString();;
            case '>':
              return parseInt(this.parseVariable(condField.key, item).value, 10) > parseInt(condField.check, 10);
            case '>=':
              return parseInt(this.parseVariable(condField.key, item).value, 10) >= parseInt(condField.check, 10);
            case '<':
              return parseInt(this.parseVariable(condField.key, item).value, 10) < parseInt(condField.check, 10);
            case '<=':
              return parseInt(this.parseVariable(condField.key, item).value, 10) <= parseInt(condField.check, 10);
            case '!':
              return !this.parseVariable(condField.key, item).value;
            case 'in':
              return condField.check.includes(this.parseVariable(condField.key, item).value.toString());
            case 'not in':
              return !condField.check.includes(this.parseVariable(condField.key, item).value.toString());
            default:
              return this.parseVariable(condField.key, item).value;
          }
        }
      };

      if (condFields.condField.length === 1 && !condFields.condField[0].hasOwnProperty('operand')) {
        return (condFields.condResult.find(param => param.val === item[condFields.condField[0].key])) ?
          condFields.condResult.find(param => param.val === item[condFields.condField[0].key]).result :
          false;

      } else if (condFields.condResult && (!condFields.condFieldOperator || condFields.condFieldOperator === 'and')) {
        return condFields.condField.every(check) ?
          (condFields.condField ? condFields.condResult.find(param => param.val === true)?.result : true) :
          (condFields.condField ? condFields.condResult.find(param => param.val === false)?.result : false);

      } else if (condFields.condResult && condFields.condFieldOperator === 'or') {
        return condFields.condField.some(check) ?
          (condFields.condField ? condFields.condResult.find(param => param.val === true)?.result : true) :
          (condFields.condField ? condFields.condResult.find(param => param.val === false)?.result : false);
      } else {
        return condFields.condField.every(check);
      }
    } else {
      return false;
    }
  }

  /**
   * GetBits gives an array of the single bit values, so 7 will become 1, 2, 4.
   * @param bitmap This is the bitmap
   */
  getBits(bitmap) {
    const bits = [];

    for (let i = 0; i < 50; i++) {
      if ((Math.pow(2, i) & parseInt(bitmap, 10)) > 0) {
        bits.push(Math.pow(2, i));
      }
    }

    if (!bits.length) {
      bits[0] = 0;
    }

    return bits;
  }

  /**
   * Returns the new array if the key is in both;
   *
   * @param left original array
   * @param right new array
   * @param key compare key
   */

  inBoth(left, right, key): any[] {
    return right.filter((set => x => set.has(x[key]))(new Set(left.map(x => x[key]))));
  }

  inLeftOnly(left, right, key): any[] {
    return left.filter((set => x => !set.has(x[key]))(new Set(right.map(x => x[key]))));
  }

  inRightOnly(left, right, key): any[] {
    return this.inLeftOnly(right, left, key);
  }

  innerJoin(left, right) {
    const result = [];

    right.forEach(item => {
      if (left.includes(item.toString())) {
        result.push(item);
      }
    });

    return result;
  }

  /**
   *
   * @src de grote set, waar alles in staat
   * @sub de kleine set, waarvan alle waarden voor moeten komen in de grote set
   * @returns true als alle waarden voorkomen.
   */

  isSubsetArray(src: string[], sub: string[]) {
    let result = true;

    for(const field of sub) {
      if (!src.includes(field)) {
        result = false;
        break;
      }
    }

    return result;
  }

  isSameArray(src: string[], comp: string[]) {
    return (src.length === comp.length) ? this.isSubsetArray(src, comp) : false;
  }

  isSubsetMap(src: string[][], sub: string[][]): boolean{
    return src.some(codes =>
      sub.some(subCode =>
        codes.every(code =>
          (subCode.includes(code) && codes.length === subCode.length) || subCode.length === 0
        )
      )
    );
  }

  isSubsetMapWithWildcard2(src: string[], sub: string[]) {
    let result = 0;

    let check: string[] = (sub.map((code: string) => code));
    let checkResult = new Map();

    [... new Set(check.map((code: string) => code.split('.')[0]))].forEach((code: string) => {
      checkResult.set(code, check.filter((conditionalCode: string) => conditionalCode.includes(code + '.')).map((conditionalCode: string) => conditionalCode.split('.')[1]));
    });

    src.forEach((code: string) => {
      const values = checkResult.get(code.split('.')[0]);
      values && (values.includes(code.split('.')[1]) || values.includes('*')) && (result++);
    });


    return (result === checkResult.size);
  }

  isSubsetArrayWithWildcard(src: string[], sub: string[]) {
    let result = true;

    for(const field of sub) {
      if (!this.includesWithWildcard(src, field)) {
        result = false;
        break;
      }
    }

    return result;
  }

  includesWithWildcard(array, search) {
    let result = false;

    for (let element of array) {
      if (element === search) {
        return true;
      } else if (element.includes('*')) {
        if (!!search.match('^' + element.replace('.*', '\.\*'))) {
          return true;
        }

      } else if (search.includes('*')) {
        if (!!element.match('^' + search.replace('.*', '\.\*'))) {
          return true;
        }
      }
    };

    return result;
  }

  isSameArrayWithWildcard(src: string[], comp: string[]) {
    return (src.length === comp.length) ? this.isSubsetArrayWithWildcard(src, comp) : false;
  }

  uniqueRows(rows, field) {
    return Array.from(new Set(rows.map(row => row[field]))).map(id => {
      return rows.find(row => row[field] === id);
    });
  }

  uniqueArrays(arrays) {
    return Array.from(new Set(arrays.map(array => JSON.stringify(array)))).map(jsonArray => {
      return arrays.find(array => JSON.stringify(array) === jsonArray);
    });
  }

  bitwiseAndLarge(val1, val2) {
    if (val1 && val2) {
      let shift = 0
      let result = 0;
      const mask = ~((~0) << 30); // Gives us a bit mask like 01111..1 (30 ones)
      const divisor = 1 << 30; // To work with the bit mask, we need to clear bits at a time
      while ((val1 !== 0) && (val2 !== 0)) {
        let rs = (mask & val1) & (mask & val2);
        val1 = Math.floor(val1 / divisor); // val1 >>> 30
        val2 = Math.floor(val2 / divisor); // val2 >>> 30
        for (let i = shift++; i--;) {
          rs *= divisor; // rs << 30
        }
        result += rs;
      }
      return result;
    } else {
      return 0;
    }
  }

  bitwiseOrLarge(val1, val2) {
    const maxInt32Bits = 4294967296; // 2^32
    const value1HighBits = val1 / maxInt32Bits;
    const value1LowBits = val1 % maxInt32Bits;
    const value2HighBits = val2 / maxInt32Bits;
    const value2LowBits = val2 % maxInt32Bits;
    return (value1HighBits | value2HighBits) * maxInt32Bits + (value1LowBits | value2LowBits);
  }

  bitwiseNotLarge(val1) {
    const maxInt32Bits = 4294967296; // 2^32
    const value1HighBits = val1 / maxInt32Bits;
    const value1LowBits = val1 % maxInt32Bits;
    return (~value1HighBits) * maxInt32Bits + (~value1LowBits);
  }

  deepCopy<T>(source: T): T {
    return Array.isArray(source)
    ? source.map(item => this.deepCopy(item))
    : source instanceof Date
    ? new Date(source.getTime())
    : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
             Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
             o[prop] = this.deepCopy(source[prop]);
             return o;
          }, Object.create(Object.getPrototypeOf(source)))
    : source as T;
  }

  deepMerge(target, source) {
    const isObject = (obj) => obj && typeof obj === 'object';

    if (!isObject(target) || !isObject(source)) {
      return source;
    }

    Object.keys(source).forEach(key => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = this.deepMerge(Object.assign({}, targetValue), sourceValue);
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
  }

  deepEquals(x, y) {
    if (x === y) {
      return true; // if both x and y are null or undefined and exactly the same
    } else if (!(x instanceof Object) || !(y instanceof Object)) {
      return false; // if they are not strictly equal, they both need to be Objects
    } else if (x.constructor !== y.constructor) {
      // they must have the exact same prototype chain, the closest we can do is
      // test their constructor.
      return false;
    } else {
      for (const p in x) {
        if (!x.hasOwnProperty(p)) {
          continue; // other properties were tested using x.constructor === y.constructor
        }
        if (!y.hasOwnProperty(p)) {
          return false; // allows to compare x[ p ] and y[ p ] when set to undefined
        }
        if (x[p] === y[p]) {
          continue; // if they have the same strict value or identity then they are equal
        }
        if (typeof (x[p]) !== 'object') {
          return false; // Numbers, Strings, Functions, Booleans must be strictly equal
        }
        if (!this.deepEquals(x[p], y[p])) {
          return false;
        }
      }
      for (const p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
          return false;
        }
      }
      return true;
    }
  }

  sortDataOnFields(array, fields) {
    const fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o=o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0);

    array.sort(fieldSorter(fields));

    for (let trackBy = 0; trackBy < array.length; trackBy++) {
      array[trackBy].trackBy = trackBy;
    }

    return array;
  }

  getBitmapArray(value: number, array: any[], bitmapField: string, mapField: string, seperator: string) {
    return array.filter(item => (item[bitmapField] & value)).map(item => item[mapField]).join(seperator);
  }

  getBitmapFromArray(array: any[], bitmapField: string) {
    return array.reduce((accum, current) => accum += parseInt(current[bitmapField]), 0);
  }

  toTabstrip2(nav) {
    const navBase = {
      maxLevel: 3,
      infoPopup: false,
      fullscreen: false,
      fullscreenType: "treeview",
      isHamburger: false,
      width: 0,
      gridTemplateColumns: "",
      fullscreenGridTemplateColumns: "",
      menu: []
    }

    nav = {...nav, ...navBase};

    (nav.type === 'tabstrip') && (nav.type = 'tabs-dropdown');

    nav.tabGroup.forEach((tabGroup, index) => {
      nav.menu.push(tabGroup.menu[0]);
      if (tabGroup.menu.slice(1).length) {
        nav.menu[index].menu = tabGroup.menu.slice(1);
      }
    });

    delete nav.tabGroup;

    return nav;
  }

  public async convertFromClipboardTableToTableData(): Promise<string[][]> {
    // check if browser is firefox
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      alert('This feature is not supported in Firefox');
      return;
    }

    // get data from clipboard
    const clipboardData = await navigator.clipboard.readText()
      .catch(console.log);

    if(!clipboardData) return;

    let table: string[][] = [];

    let rows = clipboardData.split('\r\n');
    rows.pop();

    rows.forEach(row => {
      table.push(row.split('\t'));
    });

    return table;

  }

  /**
   * Transforms any tabular data to a string representing raw CSV data.
   *
   * @param table tabular data alike that of data-groups.
   * @param delimiter delimiter between values.
   * @returns CSV data with {@linkcode delimiter} between values.
   */
  public convertTabularToCSV(table: Record<any, string>[], delimiter = ','): string {
    return table
      .map(row =>
        Object.values(row)
          .map(cell => cell !== null ? cell.toString() : '')
          .join(delimiter)
      )
      .join('\n');
  }

  /**
   * Download any raw {@linkcode data} as file of {@linkcode type}.
   *
   * @param data Data to let the user download.
   * @param type Mime type of the data {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types}.
   */
  public async download(data: string, type: string): Promise<void> {
    const blob = new Blob([data], { type });

    return this.downloadBlob(blob);
  }

  public async downloadBlob(blob: Blob): Promise<void> {
    const url = URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = url;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    link.click();

    URL.revokeObjectURL(url);
  }

  public async convertFromTableDataToTableClipboard(table: Record<any, string>[]): Promise<void> {
    return await navigator.clipboard.writeText(this.convertTabularToCSV(table, '\t'));
  }

}
