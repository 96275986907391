import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionGroup, Action, DataTable, DataGroup } from 'src/app/data';
import { CommunicationService } from 'src/app/services/communication.service';
import { UtilsService } from 'src/app/services/utils.service';
import { EventParams, Nav, Param, WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'app-action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.scss']
})
export class ActionItemComponent implements OnInit, AfterViewInit {
  @Input() dataGroupItem: DataGroup;

  @Input() item: any;
  @Input() rowSelected: number;
  @Input() rowIndex: number;

  @Output() deselectItem: EventEmitter<any> = new EventEmitter();
  @Output() updateMutation: EventEmitter<any> = new EventEmitter();

  constructor(
    private communicationService: CommunicationService,
    private utilsService: UtilsService
  ) {}

  public component = this;
  public editMode: boolean = false;

  public navBack: Nav =  {
    primary: {
      icon: 'dripicons dripicons-arrow-thin-left',
      text: '',
      type: '',
      className: "icon sm bg-transparent",
      info: '',
      noToggle: true,
      params: [],
      disabled: false,
      func: "onDeselectItem"
    },
    navId: -3
  }

  onDeselectItem() {
    this.deselectItem.emit();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

  }

  setActive(params, dataItem) {
    dataItem.status.current.push('active');
    dataItem.status.current = dataItem.status.current.filter(item => item !== 'inactive');
    dataItem.crud = 'setActive';
    this.updateMutation.emit(dataItem);
  }

  setRead(params, dataItem) {
    dataItem.status.current.push('read');
    dataItem.status.current = dataItem.status.current.filter(item => item !== 'unread');
    dataItem.crud = 'setRead';
    this.updateMutation.emit(dataItem);
  }

  setInactive(params, dataItem) {
    dataItem.status.current.push('inactive');
    dataItem.status.current = dataItem.status.current.filter(item => item !== 'active');
    dataItem.crud = 'setInactive';
    this.updateMutation.emit(dataItem);
  }

  archive(params, dataItem) {
    dataItem.crud = 'archive';
    this.updateMutation.emit(dataItem);
  }

  onEditMode(editMode, dataItem) {
    if (editMode) {
      this.editMode = true;
      dataItem.crud = 'update';
    } else {
      this.editMode = false;
      this.updateMutation.emit(dataItem);
    }
  }
}
