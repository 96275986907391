@if(loaded) {
<div [ngClass]="['data-list-wrapper', 'xfw3-grid-row-container', dataGroupItemInstance.dataClassName || '' ]">
  <div class="xfw3-grid-header">
    <app-data-generic-header
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItemInstance"
      [serverFilter]="serverFilter"
      [headerPagination]="false"
      [(currentPage)]="currentPage"
      (onSearch)="search($event)"
      (onSearchNext)="searchNext($event)"
      (onSearchPrevious)="searchPrevious($event)"
      (onForceReload)="forceReload()"
      (onUpdateMutations)="updateMutations()">
    </app-data-generic-header>
  </div>

  <div class="xfw3-grid-title">

  </div>

  <div [ngClass]="['data-list-body', 'xfw3-grid-body', dataGroupItemInstance.paging === 'virtual-scroll' ? 'no-scroll' : 'no-scroll']" #dataList>
    <div class="row-wrapper" *ngIf="!dataGroupItemInstance.noHeader">
      <div (click)="setAllCollapsed()" [ngClass]="['expcol', 'fa-regular', ['', 'fa-chevron-down','fa-chevron-right'][!allCollapsed ? 0 : allCollapsed]]"></div>
      <div class="expcol-row">
        <div [ngClass]="['data-row', 'title-row']">
          <div class="row">
            @for(group of dataGroupItemInstance.dataTable[0].fields; track $index) {
              @for(fieldGroup of group.fieldGroup; track $index) {
                <ng-container *accessLevel="fieldGroup.xfw.accessLevel">
                  @if(!fieldGroup.xfw.hidden) {
                  <div [ngClass]="[fieldGroup.xfw.dataListClassName]">
                    <h6><small>{{ fieldGroup.templateOptions.label.text }}
                    </small></h6>
                  </div>
                  }
                </ng-container>
              }
            }
          </div>
        </div>
      </div>
    </div>

    <div class="data-list"
      virtualScroll [paging]="dataGroupItemInstance.paging" [top]="dataGroupItemInstance.top" [page]="dataGroupItemInstance.page" [(scroll)]="dataGroupItemInstance.scroll" [listLength]="dataGroupItemInstance.dataTable[0].data.length">

      @for (item of dataView | slice: dataGroupItemInstance.top : dataGroupItemInstance.top + 5000; track item['trackBy']; let rowIndex = $index; let first = $first; let last = $last;) {
      <div #dataItem [xDrop]="item" (xDropOnDropped)="dragdropService.targetedDrop(dataGroupItem, queryParams, widgetId, $event)" class="data-drag-row">

        @defer(on viewport) {
        <div class="row-wrapper"
            [ngClass]="['level-' + item['level'],
              !dataGroupItemInstance.rowOptions.rowCollapsable ? 'coll-exp-hidden' : '',
              (dataGroupItemInstance.rowOptions.rowCollapsable && item.collapsed) ? 'collapsed' : '',
              (dataGroupItemInstance.rowOptions.rowCollapsable && !item.collapsed) ? 'expanded' : '']">

          <div (click)="collExp(item)" [ngClass]="['expcol', 'fa-regular', ['', 'fa-chevron-down','fa-chevron-right'][!item.collapsed ? 0 : item.collapsed]]"></div>

          <div class="expcol-row">
            <app-data-row
              [widgetId]="widgetId"
              (onSelect)="select($event)"
              (onSetEditMode)="setEditMode($event)"
              (onUpdateMutations)="updateMutations()"
              (onAddRow)="addNewRow($event)"
              [item]="item"
              [dataGroupItemInstance]="dataGroupItemInstance"
              [focus]="dataGroupItemInstance.dataTable[0].selected?.rowIndex === rowIndex ? dataGroupItemInstance.dataTable[0].selected.cell : null"
              [rowIndexOffset]="dataGroupItemInstance.top"
              [rowIndex]="rowIndex"
              [selectedIndex]="index"
              [last]="last">
            </app-data-row>
          </div>

          <div *cdkDragPreview class="drag-preview" >
            @if(dataGroupItemInstance.isDraggable) {
            <div>
              <div>{{ item[dataGroupItemInstance.dragPreviewField] }}</div>
              @for(dragRow of dataGroupItemInstance.dataTable[0].data; let dragIndex = $index; track dragIndex;) {
                <div *ngIf="dragIndex !== rowIndex && dragRow.checked">
                  {{ dragRow[dataGroupItemInstance.dragPreviewField] }}
                </div>
              }
            </div>
            }
          </div>
        </div>
        } @placeholder {
          <div style="height: 41px; width: 100%; display: flex; align-items: center; justify-content: center;">

          </div>
        }

        <!-- @placeholder {
          <div style="height: 2rem; width: 100%; display: flex; align-items: center; justify-content: center;">
            ...
          </div>
        } -->

      </div>
      }
    </div>
  </div>

  <div class="xfw3-grid-footer" #gridFooter>
    <app-data-generic-footer
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItemInstance"
      [component]="component"
      [headerPagination]="false"
      [(currentPage)]="currentPage">
    </app-data-generic-footer>
  </div>

  <app-popup-form *ngIf="dataGroupItemInstance.edit.type === 'form' && edit.mode"
    [widgetId]="widgetId"
    [dataGroupItemInstance]="dataGroupItemInstance"
    [edit]="edit"
    [rowIndex]="index"
    (onCloseWindow)="close($event)"
    [window]="{
      nav: false,
      add: false,
      modal: true,
      closeOnOutsideClick: false
    }"
    (onAddRow)="addRow($event)"
    [navList]="['primary']">
  </app-popup-form>
</div>
}
