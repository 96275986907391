import { Component, OnInit, ViewChild } from '@angular/core';
import { DataGroupMatrixColItemComponent } from '../data-group-matrix-col-item/data-group-matrix-col-item.component';
import { DataGroupMatrixGenericComponent } from '../data-group-matrix-generic/data-group-matrix-generic.component';

@Component({
  selector: 'app-data-group-matrix-col',
  templateUrl: './data-group-matrix-col.component.html',
  styleUrls: ['./data-group-matrix-col.component.css']
})
export class DataGroupMatrixColComponent extends DataGroupMatrixGenericComponent implements OnInit {

  @ViewChild('dataGroupMatrixColItem', {static: false}) dataGroupMatrixColItem: DataGroupMatrixColItemComponent;

  ngOnInit(): void {
    super.ngOnInit();
  }
}
