<div class="xfw-matrix-row" horizontalScroll *ngIf="loaded">
  <ng-container *ngFor="let dataItem of dataGroupItem.dataTable[0].data; trackBy: trackByFn">
    <div [ngClass]="['xfw-matrix-header', dataItem.itemCondClass]" >
      <app-data-item-content
        [dataGroupItemInstance]="dataGroupItem"
        [item]="dataItem">
      </app-data-item-content>
    </div>
    <div class="xfw-matrix-col" >
      <div class="xfw-matrix-content" preventHorizontalScroll>
        <app-data-group-matrix-col
          [dataGroupMatrix]="dataGroupMatrix[index].children"
          [item]="dataItem"
          (onSelect)="onSelected($event)">
        </app-data-group-matrix-col>
      </div>
    </div>
  </ng-container>
</div>
