import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DataGroup } from 'src/app/data';

@Component({
  selector: 'app-popup-img-viewer',
  templateUrl: './popup-img-viewer.component.html',
  styleUrls: ['./popup-img-viewer.component.css']
})
export class PopupImgViewerComponent implements OnInit, AfterViewInit {
  @Input() dataGroupItem: DataGroup;
  @Input() imgKey: string;
  @Input() options: any;
  @Input() index: number = 0;

  height: number = 400;
  thumbWidth: number = 60;

  public maxImgs: number = 3;
  public maxThumbs: number = 15;

  public item: any;

  containerWidth = {
    current: 0,
    nextMax: 0,
    min: 0,
    maxThumbs: 0
  }

  loaded: boolean = false;

  @Output() closeImgViewer: EventEmitter<any> = new EventEmitter();

  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;
  @ViewChildren('slide', { read: ElementRef }) slides: QueryList<ElementRef>;

  @ViewChildren('thumbslide', { read: ElementRef }) thumbslides: QueryList<ElementRef>;

  constructor() { }

  ngOnInit(): void {
    this.item = this.dataGroupItem.dataTable[0].data[this.index];
    this.loaded = true;

    setTimeout(() => {
      console.log(this.wrapper, this.slides, this.thumbslides);
    },500);
  }

  ngAfterViewInit():void {
    if (this.loaded) {
      this.animate(this.index, 'smooth');
    }
  }

  close(event) {
    this.closeImgViewer.emit(event);
  }

  previous(event) {
    if (this.index > 0) {
      this.animate(this.index - 1);
    } else {
      this.index = this.dataGroupItem.dataTable[0].data.length - 1;
      this.animate(this.index);
    }
  }

  next(event) {
    this.maxImgs++;
    this.maxThumbs++;

    this.index === -1 && (this.index = 0);

    if (this.index < this.dataGroupItem.dataTable[0].data.length - 1) {
      this.animate(this.index + 1);
    } else {
      this.index = 0;
      this.animate(this.index);
    }

  }

  animate(index, behavior: string = 'smooth') {
    this.index = index;

    this.animateThumbs(index, behavior).then((data) => {
      this.animateZoom(index, behavior).then((data) => {
        return;
      });
    });
  }

  animateZoom(index, behavior: string = 'smooth') {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.slides.toArray()[index].nativeElement.scrollIntoView({
          behavior: behavior,
          inline: 'center',
          block: 'nearest'
        });

        resolve(index);
      },300);
    });
  }

  animateThumbs(index, behavior: string = 'smooth') {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.thumbslides.toArray()[index].nativeElement.scrollIntoView({
          behavior: behavior,
          inline: 'center',
          block: 'nearest'
        });

        resolve(index);
      });
    });
  }

  ngOnDestroy() {

  }

  trackByFn(index, item) {
    return index;
  }

}
