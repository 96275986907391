import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss']
})
export class JsonViewerComponent implements OnInit {
    @Input() fields: Fields[];
    @Input() data: any;

    public dataView: { key: string, val: string, level: number }[] = [];

    constructor(private utilsService: UtilsService) { }

    ngOnInit(): void {
      const convertToView = (data: any, level: number) => {
        Object.keys(data).forEach(key => {
          if (this.fields.some(item => item.field === key && item.level === level)) {
            this.dataView.push(
            {
              key: this.fields.find(item => item.field === key && item.level === level)?.text || key,
              val: typeof data[key] === 'object' ? '' : data[key],
              level: level
            });

            if (typeof data[key] === 'object') {
              convertToView(data[key], ++level);
            }
          }

        });
      };

      convertToView(this.data, 0);
    }

  trackByFn(index, item) {
    return index;
  }
}

interface Fields {
  field: string;
  level: number;
  text: string;
}
