import { Injectable } from '@angular/core';
import { MultiLingual, Nav } from '../widgets';
import { ContentService } from './content.service';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notificationList: Notification[] = [];

  private idCount: number = 0;

  constructor(
    private contentService: ContentService,
    private communicationService: CommunicationService) {}

  /**
   * This method is used when you want to create an notification.
   *
   * @param notification an Msg Object
   */
  public addNotification(notification: Notification): void {
    const language: string = this.communicationService.user.language;

    // @ts-ignore
    notification.msg = notification.msg ?? 'No Error Msg.';

    notification = this.contentService.changeLanguage(notification, language);

    if(notification.autoHide === undefined) {
      notification.autoHide = true;
    }

    this.notificationList.push({ id: this.unqiueId(), ...notification });
  }

  /**
   * This method is used when you want to remove an notification.
   *
   * @param id The id of the notification that needs to be removed.
   */
  public removeNotification(id: number): void {
    this.notificationList = this.notificationList.filter(notification => notification.id !== id);
  }

  /**
   * This Method returns a unique id for a notification.
   */
  private unqiueId(): number {
    return this.idCount++;
  }
}

export interface Msg {
  statusCode?: number,
  error?: any
  msgType: 'error'|'warning'|'info'|'remark'|'devInfo'|'devWarning'|'devError',
  msg?: {
    title?: string
    body?: {
      text?: string,
      ml?: MultiLingual
    },
    nav?: Nav
  }
}

export interface Notification extends Msg {
  id?: number,
  autoHide?: boolean,
  windowOpen?: boolean
}
