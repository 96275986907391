import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionGroup, DataGroup } from 'src/app/data';
import { DataGroupComponent } from 'src/app/widget/data-group/data-group.component';
import { WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-action-group',
  templateUrl: './action-group.component.html',
  styleUrls: ['./action-group.component.css']
})
export class ActionGroupComponent extends DataGroupComponent implements OnInit {
  @Input() widgetId: string;
  @Input() dataGroup: DataGroup[];

  @Output() showActionCenter: EventEmitter<any> = new EventEmitter();

  public layout: { gridTemplateRows: string } = { gridTemplateRows: '' };

  public component = this;

  ngOnInit(): void {
    this.dataService.loadDataGroup(this.src, this.dataGroup).then((dataGroup: DataGroup[]) => {
      this.dataGroup = dataGroup;

      this.dataService.setInitialParams(this.queryParams, this.utilsService.paramsFromObject(this.communicationService.user), this.dataGroup);
      this.dataService.setParams(this.queryParams, this.utilsService.paramsFromObject(this.communicationService.user), [], this.dataGroup, true);

      if (this.communicationService.user.config.actionCenter.dataGroupSelected) {
        this.dataGroup.find(dataGroupItem => dataGroupItem.widgetId === this.communicationService.user.config.actionCenter.dataGroupSelected).selected = true;
      }

      this.layout.gridTemplateRows = this.dataGroup.filter(dataGroupItem => !dataGroupItem.isEmpty).map(dataGroupItem => dataGroupItem.selected ? '1fr' : 'min-content').join(' ');

      this.loaded = true;
    });

    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this.component,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.REFRESH,
          func: 'forceRefresh',
        }
      ]
    });
  }

  forceRefresh(params) {
    const widgetGroup: string[] = [];

    setTimeout(() => {
      this.dataGroup.forEach(dataGroupItem => {
        if (dataGroupItem.params.find(param => param.key === 'sectionId').val & params?.dataItem?.sectionId) {
          widgetGroup.push(dataGroupItem.widgetId);
          dataGroupItem.isEmpty = false;
        }
      });

      this.communicationService.performAction({
        info: 'action',
        widgetGroup: widgetGroup,
        event: WidgetEvent.REFRESH
      });
    });
  }

  select(dataGroupItem: DataGroup) {
    if (!dataGroupItem.selected) {
      this.showActionCenter.emit(true);

      this.dataGroup.forEach(dataGroupItem => {
        dataGroupItem.selected = false;
        dataGroupItem.isSelected = false;
      });

      dataGroupItem.selected = true;
      dataGroupItem.isSelected = true;

      this.layout.gridTemplateRows = this.dataGroup.filter(dataGroupItem => !dataGroupItem.isEmpty).map(dataGroupItem => dataGroupItem.selected ? '1fr' : 'min-content').join(' ');

      this.userService.setUser({ config: { actionCenter: { dataGroupSelected: dataGroupItem.widgetId }}}, true);
    }
  }

  trackByFn(index, item): string {
    return item.widgetId + index.toString();
  }
}
