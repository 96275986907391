<div class="container-fluid xfw3-grid-container" divBreakpointClass>
  <div class="row py-3 xfw3-grid-col-container">
    <div class="g-col-12 text-center" *ngIf="accessDenied">
      <h1>Toegang geweigerd!</h1>
    </div>
    <div class="g-col-12 g-col-sm-6 g-col-lg-4  mb-4" *ngFor="let room of rooms">
      <h5 *ngIf="room.hasDevices">{{room.name}}</h5>
      <div class="row xfw3-grid-col-container mb-1" *ngFor="let device of room.devices">
        <div class="g-col-7">
          {{device.name}}
        </div>
        <div class="g-col-3" *ngIf="device.properties.categories[0] === 'lights'">
          <div class="btn-group btn-group-toggle rounded-pill" data-toggle="buttons"
            [ngClass]="device.properties.value ? 'bg-primary' : 'bg-light'"
            (click)="switchDevice(device.id, !device.properties.value)">
            <label class="btn-sm btn">
              <div *ngIf="!device.properties.value" class="rounded-circle bg-white"
                style="width: 10px; height: 10px; padding-left: 0.3px; margin-right: 3px;"></div>
            </label>
            <label class="btn-sm btn">
              <div *ngIf="device.properties.value" class="rounded-circle bg-white"
                style="width: 10px; height: 10px; padding-right: 0.3px; margin-left: 3px;"></div>
            </label>
          </div>
        </div>
        <div class="g-col-3" *ngIf="device.properties.categories[0] === 'gates'" (click)="switchDevice(device.id)">
          <button class="btn">Activeer</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row xfw3-grid-col-container">
    <div class="g-col-12">
      <app-data-generic-footer [widgetId]="widgetId" [dataGroupItemInstance]="dataGroupItemInstance"
        [component]="component" [headerPagination]="false">
      </app-data-generic-footer>
    </div>
  </div>
</div>
