<ng-container *ngIf="contentJSON" >
  <div class="xfw-carousel"
    [style.height.vh]="height"
    (mouseover)="showArrows(true);"
    (mouseout)="showArrows(false);"
    (click)="showArrows(true);"
    (clickOutside)="showArrows(false);">
    <!-- carousel -->
    <ng-container *ngFor="let item of contentJSON.cols[0].col[0].img; index as i; trackBy: trackByFn" >
      <div [ngStyle]="{
        'height': height + 'vh',
        'background': darkenBackground ? 'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' + item.url + ')' : 'url(' + item.url + ')',
        'opacity': currentSlide === i ? 1 : 0,
        'z-index': 20 - (currentSlide === i ? 1 : 2) }"
          class="xfw-slide background">
          <div class="container-fluid xfw3-grid-container h-100" divBreakpointClass>
            <div class="row position-absolute w-100 h-100">
              <div [ngClass]="getOffsetClasses"></div>
              <div [ngClass]="getWidthClasses">
                  <div *ngIf="currentSlide === i" class="d-flex h-100">
                    <div [ngClass]="[isInHeader ? 'align-self-end mb-5' : 'align-self-center', 'col-12']">
                      <h1 class="carousel-title text-dark fw-bold" [ngClass]="contentJSON.cols[0].col[0].body[currentSlide].className"
                      [innerHTML]="contentJSON.cols[0].col[0].body[currentSlide].text"></h1>
                      <h3 class="carousel-title text-dark" [ngClass]="contentJSON.cols[0].col[0].body[currentSlide].className"
                      [innerHTML]="contentJSON.cols[0].col[0].body[currentSlide].text"></h3>
                      <h4 class="carousel-title text-dark" [ngClass]="contentJSON.cols[0].col[0].body[currentSlide].className"
                      [innerHTML]="contentJSON.cols[0].col[0].body[currentSlide].text"></h4>
                      <div class="carousel-nav mt-3">
                        <app-cmd
                            *ngIf="contentJSON.cols[0].col[0].body[currentSlide].nav"
                            [nav]="contentJSON.cols[0].col[0].body[currentSlide].nav"
                            [navList]="['primary','custom']"
                            [navVisible]="0"
                            [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
                            [collapsed]="false">
                        </app-cmd>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </ng-container>
    <!-- indicators -->
    <div class="xfw-indicator-container d-flex justify-content-center align-items-end" style="bottom: 1rem;">
      <button *ngFor="let item of contentJSON.cols[0].col[0].img; index as i" class="py-1" (click)="resetTimer(); currentSlide = i">
        <div class="rounded-circle bg-dark xfw-indicator" [ngStyle]="{ 'opacity': i === currentSlide ? 1 : 0.2 }"></div>
      </button>
    </div>
    <!-- controls -->
    <button class="xfw-control prev" (click)="onPreviousClick()">
      <span class="xfw-arrow left"></span>
    </button>
    <button class="xfw-control next" (click)="onNextClick()">
      <span class="xfw-arrow right"></span>
    </button>
  </div>
</ng-container>
