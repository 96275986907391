import { Component, Input, OnInit } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { ContentService } from 'src/app/services/content.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  @Input() dataGroupItem: DataGroup;

  public language: string;

  public title: any[];
  public subTitle: any[];

  constructor(
    private userService: UserService,
    private utilsService: UtilsService,
    private contentService: ContentService
  ) {
    const userSubscriber = this.userService.user$.subscribe((user) => {
      this.language = user.language;

      if (this.contentJSON) {
        this.setContent(structuredClone(this.contentJSON));
      }
    });
  }

  setContent(contentJSON) {
    contentJSON = this.contentService.changeLanguage(contentJSON, this.language);

    this.title = contentJSON.title;
    this.subTitle = contentJSON.subTitle;
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.contentJSON) {
        this.setContent(structuredClone(this.contentJSON));
      } else if (this.dataGroupItem) {
        this.title = [
          {
            text: this.dataGroupItem.params?.find((param) => param.key === (this.dataGroupItem.titleFrom ?? 'Title'))?.val,
            className: this.dataGroupItem.params?.find((param) => param.key === "className")?.val
          }
        ];

        this.subTitle = [
          {
            text: this.dataGroupItem.params?.find((param) => param.key === "SubTitle")?.val,
            className: this.dataGroupItem.params?.find((param) => param.key === "className")?.val
          }
        ];
      }
    });
  }

  trackByFn(index, item) {
    return index;
  }
}
