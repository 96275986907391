import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { CommunicationService } from 'src/app/services/communication.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-data-group-item',
  templateUrl: './data-group-item.component.html',
  styleUrls: ['../data-list/data-list.component.css']
})
export class DataGroupItemComponent implements OnInit {
  @Input() widgetId: string;
  @Input() dataGroupItem: DataGroup;
  @Input() dataItem: any;
  @Input() dataFilter: any;

  @Output() hasData: EventEmitter<any> = new EventEmitter();

  constructor(private communicationService: CommunicationService) {
  }

  ngOnInit(): void {
  }

  onHasData(event) {
    this.dataGroupItem.isEmpty = (event.dataLength === 0);

    event.form && event.dataLength === 0 && this.dataGroupItem.nav?.onEmpty &&
      this.communicationService.mergeQueryParams(this.widgetId, this.dataGroupItem.nav.onEmpty.params, [this.dataGroupItem.nav.onEmpty.path], null, false);
  }
}
