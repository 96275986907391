import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTime'
})
export class MinutesToTimePipe implements PipeTransform {

  transform(timeInMinutes: number, ...args: unknown[]): string {
    if (timeInMinutes === null || timeInMinutes === undefined) {
      return '';
    }

    timeInMinutes = Math.floor(timeInMinutes);

    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;

    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
  }
}
