import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrl: './page-container.component.scss'
})
export class PageContainerComponent implements OnInit, AfterViewInit {
  @ViewChildren('verticalResizer') verticalResizers: QueryList<ElementRef> | undefined;
  @ViewChild('horizontalResizer') horizontalResizer: ElementRef | undefined;

  public oldY: number = 0;
  public oldX: number = 0;

  constructor(public communicationService: CommunicationService) {
    this.communicationService.panelRefresher$.subscribe(() => {
      this.getResizerOrigins();
    });
  }

  public resizerIndex = -1;
  public isVertical = false;
  public verticalDirect: boolean = true;
  public horizontalDirect: boolean = false;

  ngOnInit(): void {
    this.communicationService.initPanels();

    // setTimeout(() => {
    //   this.communicationService.panels.sidebarSetting.main = 100;
    // }, 5000);
  }

  ngAfterViewInit(): void {
    this.verticalResizers?.forEach((resizer, index) => {
      this.communicationService.panels.sidebarContainers[index].resizedOrigin = resizer.nativeElement.getBoundingClientRect().top;
    });
    this.horizontalResizer &&
      (this.communicationService.panels.sidebarSetting.resizedOrigin = this.horizontalResizer.nativeElement.getBoundingClientRect().left);
  }

  toggleSidebar(event): void {
    event.stopPropagation();
    event.preventDefault();

    this.communicationService.panels.sidebarSetting.expanded = !this.communicationService.panels.sidebarSetting.expanded;

    if (this.communicationService.panels.sidebarSetting.expanded) {
      this.communicationService.panels.sidebarSetting.main = 100 - this.communicationService.panels.sidebarSetting.sidebar;
      this.communicationService.panels.sidebarSetting.resize = 0;
    } else {
      this.communicationService.panels.sidebarSetting.main = 100;
      this.communicationService.panels.sidebarSetting.resize = 0;
    }
  }

  getResizerOrigins(): void {
    setTimeout(() => {
      this.verticalResizers?.forEach((resizer, index) => {
        this.communicationService.panels.sidebarContainers[index].resizedOrigin = resizer.nativeElement.getBoundingClientRect().top;
      });
      this.horizontalResizer &&
        (this.communicationService.panels.sidebarSetting.resizedOrigin = this.horizontalResizer.nativeElement.getBoundingClientRect().left);
    });
  }

  onMousedown(index: number, isVertical: boolean, event: MouseEvent): void {
    event.preventDefault();

    this.resizerIndex = index;
    this.isVertical = isVertical;
  }

  onMousemove(event: MouseEvent): void {
    if (this.resizerIndex === -1 || (this.isVertical && event.clientY === this.oldY) || (!this.isVertical && event.clientX === this.oldX))
      return;

    const margin:number = 50;

    if (this.isVertical) {
      const visibleContainers = this.communicationService.panels.sidebarContainers.filter(container => container.isVisible);
      const visibleContainerResizerIndex = visibleContainers.findIndex(container =>
        container.name === this.communicationService.panels.sidebarContainers[this.resizerIndex].name);

      const newY = event.clientY;
      this.oldY = newY;

      const boundaryTop = (visibleContainerResizerIndex > 0 ? visibleContainers[visibleContainerResizerIndex - 1].resizedOrigin : 0) + margin;
      const boundaryBottom = visibleContainers[visibleContainerResizerIndex + 1].resizedOrigin - margin;

      newY >= boundaryTop && newY <= boundaryBottom && (this.communicationService.panels.sidebarContainers[this.resizerIndex].resize = newY);

      this.verticalDirect && this.calculateContainerHeights();
    } else {
      const newX = event.clientX;
      const pageWidth = window.innerWidth;

      this.oldX = newX;

      newX >= margin && newX <= pageWidth - margin && (this.communicationService.panels.sidebarSetting.resize = newX);
      this.horizontalDirect && this.calculateContainerWidths();
    }
  }

  onMouseup(): void {
    this.isVertical && this.calculateContainerHeights();
    !this.isVertical && this.calculateContainerWidths();
    this.resizerIndex = -1;
  }

  calculateContainerWidths() {
    if ((this.resizerIndex === -1 || this.isVertical)) return;

    if (this.communicationService.panels.sidebarSetting.expanded) {
      const totalWidth = 100;
      const sidebarSetting = this.communicationService.panels.sidebarSetting;
      sidebarSetting.main = (sidebarSetting.main / sidebarSetting.resizedOrigin) * sidebarSetting.resize;
      sidebarSetting.sidebar = totalWidth - sidebarSetting.main;
      sidebarSetting.resizedOrigin = sidebarSetting.resize;
    }

    console.log('containerWidth', structuredClone(this.communicationService.panels.sidebarSetting));
  }

  calculateContainerHeights() {
    if (this.resizerIndex === -1 || !this.isVertical) return;

    const visibleContainers = this.communicationService.panels.sidebarContainers.filter(container => container.isVisible);
    const visibleContainerResizerIndex = visibleContainers.findIndex(container =>
      container.name === this.communicationService.panels.sidebarContainers[this.resizerIndex].name);

    const topContainer = visibleContainers[visibleContainerResizerIndex];
    const bottomContainer = visibleContainers[visibleContainerResizerIndex + 1];
    const totalHeight = topContainer.height + bottomContainer.height;

    const offset = visibleContainerResizerIndex > 0 ? visibleContainerResizerIndex[visibleContainerResizerIndex - 1].resizedOrigin : 0;

    if (topContainer.resize) {
      topContainer.height = (topContainer.height / (topContainer.resizedOrigin - offset)) * (topContainer.resize - offset);
      topContainer.resizedOrigin = topContainer.resize;
      bottomContainer.height = totalHeight - topContainer.height;
    }
  }
}
