<ng-template #t let-date let-today="today" let-currentMonth="currentMonth">
  <div class="custom-day"
    [ngClass]="{'custom-day-selected': isInSelection(date), 'custom-day-not-this-month' : date.month !== currentMonth, 'today': today && !isInSelection(date)}"
    (click)="selectDate(date)">
    {{ date.day }}
  </div>
</ng-template>

<div class="date-picker-input" (clickOutside)="handleClickOutside($event)">
  <input #datePickerVisibleInput class="form-control" [value]="getSelectionString()"
    (click)="datePickerHiddenInput.toggle()" [disabled]="disabled">
  <div [ngClass]="['toggle-calendar', calendarDropDown ? 'expanded' : 'collapsed']"
    (click)="datePickerHiddenInput.toggle();">
    <span class="dropdown-collapse"></span>
    <span class="dropdown-expand"></span>
  </div>
</div>

<input hidden ngbDatepicker #datePickerHiddenInput="ngbDatepicker" [container]="'body'" [dayTemplate]="t"
  [positionTarget]="datePickerVisibleInput" [autoClose]="'outside'" (closed)="blur.emit()" />
