<div class="container-fluid xfw3-grid-container" divBreakpointClass [ngClass]="[contentJSON.blockLayoutType ?? '', contentJSON.blockCombine ?? '']">
  <div *ngIf="contentJSON.title.length" [ngClass]="[contentJSON.rowClassName ?? '', environmentJSON.viewportMode ? 'viewportMode' : '', 'xfw3-grid-col-container']">
    <div [ngClass]="contentJSON.blockLayoutGridTitle ? dataService.blockLayoutGridData[contentJSON.blockLayoutGridTitle] : dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
      <h2>
        <ng-container *ngFor="let item of contentJSON.title">
          <div class="header" *ngIf="item.className" [class]="item.className" [innerHTML]="item.text"></div>
          <span *ngIf="!item.className" [innerHTML]="item.text"></span>
        </ng-container>
      </h2>
    </div>
  </div>

  <div [ngClass]="[contentJSON.rowClassName ?? '', 'xfw3-grid-col-container']">
    <ng-container *ngFor="let col of contentJSON.cols[0].col; trackBy: trackByFn">
      <div *ngIf="col.body" [ngClass]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
        <ng-container *ngFor="let item of col.body">
            <div class="row">
              <div class="col-12 position-relative question" #question [id]="item.title" (click)="item.hidden = !item.hidden;">
                <div style="display: flex; width: 100%; order: 1;">
                  <div *ngIf="item.svg"
                    [style.--aspect-ratio]="item.svg[0].aspectratio ? item.svg[0].aspectratio : 1"
                    [style.background-size]="item.svg[0].size ? item.svg[0].size : 'cover'"
                    [style.width]="item.svg[0].width ? item.svg[0].width : '100%'"
                    [style.height]="item.svg[0].height"
                    [style.min-height]="item.svg[0].minheight"
                    [style.min-width]="item.svg[0].minwidth"
                    [style.background-image]="'url(' + item.svg[0].url + ')'"
                    style="background-repeat: no-repeat; background-position: left center; flex-basis: auto;">
                  </div>

                  <div class="question-title">
                    <h5 [innerHTML]="(item.title ? item.title : '&nbsp;') | safeHtml">
                    </h5>
                  </div>

                  <span *ngIf="item.clipboard" class="question-clipboard">
                    <span [hidden]="!item.hidden"
                      (click)="copyToClipBoard(url + '?query=' + item.title); $event.stopPropagation(); "
                      class="dripicons dripicons-copy" title="Kopieer url naar clipboard"></span>
                    <span [hidden]="item.hidden"
                      (click)="copyToClipBoard(url + '?query=' + item.title); $event.stopPropagation(); "
                      class="dripicons dripicons-copy" title="Kopieer url naar clipboard"></span>
                  </span>

                  <span class="question-collapse">
                    <span [hidden]="!item.hidden" [ngClass]="['dripicons', contentJSON.dropdownIconClosed ?? 'dripicons-chevron-right']"></span>
                    <span [hidden]="item.hidden" [ngClass]="['dripicons', contentJSON.dropdownIconOpened ?? 'dripicons-chevron-down']"></span>
                    <!-- <span [hidden]="!item.hidden" class="dripicons dripicons-chevron-right"></span>
                    <span [hidden]="item.hidden" class="dripicons dripicons-chevron-down"></span> -->
                  </span>
                </div>

                <div [hidden]="item.hidden" class="answer">
                  <div *ngFor="let answerItem of item.answer">
                    <h5 *ngIf="answerItem.title" [innerHTML]="(answerItem.title ? answerItem.title : '&nbsp;') | safeHtml">
                    </h5>

                    <p *ngIf="answerItem.text"
                      [innerHTML]="(answerItem.text ? answerItem.text : '&nbsp;') | safeHtml">
                    </p>

                    <div [ngClass]="[answerItem.img[0].className ?? '']" *ngIf="answerItem.img"
                      style="background-repeat: no-repeat; display: inline-block; vertical-align: bottom; width: 100% !important;"
                      [style.--aspect-ratio]="answerItem.img[0].aspectratio ? answerItem.img[0].aspectratio : 1.5"
                      [style.background-size]="answerItem.img[0].size ? answerItem.img[0].size : 'cover'"
                      [style.maxWidth]="answerItem.img[0].width ? answerItem.img[0].width : '100%'"
                      [style.min-height]="answerItem.img[0].minheight" [style.height]="answerItem.img[0].height"
                      [style.background-image]="'url(' + answerItem.img[0].url + ')'">
                    </div>

                    <div *ngIf="answerItem.video" style="margin: 1rem 4rem 0rem 2rem;">
                      <div class="d-block d-lg-none xfw-rowSpacer"></div>
                      <video [src]="answerItem.video[0].url" [class]="answerItem.video[0].className" controls></video>
                    </div>

                    <app-cmd *ngIf="answerItem.nav"
                      [nav]="answerItem.nav"
                      [navList]="['primary','custom']"
                      [navVisible]="0"
                      [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark mb-3' : 'text-light mb-3'"
                      [collapsed]="false">
                    </app-cmd>
                  </div>
                </div>
              </div>
            </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
