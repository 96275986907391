import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ConfiguratorOption, ConfiguratorProduct, ConfiguratorRow } from 'src/app/configurator';
import { DataGroup } from 'src/app/data';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfiguratorService } from 'src/app/services/configurator.service';
import { UtilsService } from 'src/app/services/utils.service';
import { EventParams, Param } from 'src/app/widgets';

@Component({
  selector: 'app-edit-option',
  templateUrl: './edit-option.component.html',
  styleUrls: ['../configurator-option/configurator-option.component.scss', './edit-option.component.scss']
})
export class EditOptionComponent implements OnInit{
  @Input() dataGroupItemInstance: DataGroup;
  @Input() index: number;
  @Input() product: ConfiguratorProduct;
  @Input() row: ConfiguratorRow;
  @Input() rowIndex: number;
  @Input() option: ConfiguratorOption;
  @Input() code: string;
  @Input() state: string;
  @Input() uploadType: 'no-img' | 'none' | 'single' | 'single-edit' | 'multiple' = 'single-edit';

  public component = this;

  @Output() onSetEditMode: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private utilsService: UtilsService,
    private configuratorService: ConfiguratorService,
    private communicationService: CommunicationService
  ) { }

  ngOnInit(): void {
    this.option['name'] = this.option.block.name;
    this.option['description'] = this.option.block.description;
  }

  select(row: ConfiguratorRow, eventParams: EventParams) {
    const option: ConfiguratorOption = eventParams.dataItem;

    if (eventParams.event.shiftKey && this.product?.excludes) {
      this.configuratorService.selectExcludes(this.product, row, option, eventParams);

    } else if (eventParams.event.altKey) {
      row.state.isSelected && row.options.filter(rowOption => rowOption.code !== option.code).forEach(rowOption => { rowOption.state.dependent = false; });

      this.configuratorService.selectConditionalCodes(this.product, row, option, eventParams);

    } else if (eventParams.event.ctrlKey) {
      this.configuratorService.selectSets(this.product, row, option, eventParams);

    } else {
      let showSetsOrConditionalCodes:boolean = false;

      !option.isSelected && this.product.rows.flatMap(row => row.options).forEach(rowOption => {
        rowOption.isSelected = false;

        showSetsOrConditionalCodes ||= (rowOption.state.dependent || rowOption.state.linked);

        rowOption.state.dependent = false;
        rowOption.state.excluded = false;
        rowOption.state.linked = false;
      });

      !showSetsOrConditionalCodes && (option.isSelected = !option.isSelected);

      const params: Param[] = this.dataGroupItemInstance.children[0].children[this.rowIndex].rowOptions.nav.onSelect.params;
      const paramsFrom: Param[] = this.dataGroupItemInstance.children[0].children[this.rowIndex].rowOptions.nav.onSelect.params;

      if (option.isSelected) {
        paramsFrom.forEach(paramFrom => {
          option[paramFrom.key] && (params.find(param => param.key === paramFrom.key).val = option[paramFrom.key]);
        });

        this.communicationService.mergeQueryParams(
          this.dataGroupItemInstance.children[0].children[this.rowIndex].widgetId,
          params,
          [],
          null,
          true
        );
      } else {
        this.communicationService.removeQueryParams(params);
      }
    }
  }

  editOption(params: Param[], dataItem: any, data: any[]) {
    console.log(dataItem);

    dataItem.edit = true;

    this.onSetEditMode.emit({
      params,
      dataItem,
      data
    });
  }

  deleteOption(params: Param[], dataItem: any, data: any[]) {
    this.row.options.splice(
      this.row.options.findIndex(option => option.trackBy === dataItem.trackBy), 1);
  }
}
