import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DataTable } from 'src/app/data';
import { ResizedEvent } from '../resized/resized.directive';
import { DragDropService } from 'src/app/services/dragdrop.service';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-popup-window',
  templateUrl: './popup-window.component.html',
  styleUrls: ['./popup-window.component.scss']
})
export class PopupWindowComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() window: {
    title: string,
    className: string,
    index: number,
    dataLength: number,
    nav: boolean,
    add: boolean,
    modal: boolean,
    closeOnOutsideClick: boolean
    top: number,
    left?: number,
    right: number,
    width: number,
    height: number,
    zIndex: number,
    sidebar?: boolean
  };

  @Output() onCancelWindow: EventEmitter<any> = new EventEmitter();
  @Output() onPrevious: EventEmitter<any> = new EventEmitter();
  @Output() onNext: EventEmitter<any> = new EventEmitter();
  @Output() onAddRow: EventEmitter<any> = new EventEmitter();

  public currentPanel: any;

  constructor(
    public communicationService: CommunicationService,
    public dragDropService: DragDropService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      (this.window.sidebar) && (this.currentPanel = this.communicationService.setSidebarContainer({
          name: this.widgetId,
          height: 100,
          isMinimized: false,
          isVisible: true
        })
      );
      this.dragDropService.noDrag = true;
    });
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      (this.window.sidebar) && this.communicationService.setSidebarContainer({
        name: this.widgetId,
        height: 0,
        isMinimized: false,
        isVisible: false
      });
      this.dragDropService.noDrag = false;
    });
  }

  toggleMinimizeWindow(isMinimized: boolean) {
    (this.currentPanel = this.communicationService.setSidebarContainer({
      name: this.widgetId,
      height: null,
      isMinimized: isMinimized,
      isVisible: true
    }));
  }

  cancelWindow(event) {
    this.onCancelWindow.emit(event);
  }

  previous(event) {
    this.onPrevious.emit(event);
  }

  next(event) {
    this.onNext.emit(event);
  }

  addRow(event) {
    this.onAddRow.emit(event);
  }

  onResized(event: ResizedEvent) {
    if (event?.newRect?.width !== event?.oldRect?.width) {
    }
  }

}


