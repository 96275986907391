import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Nav } from 'src/app/widgets';
import { TabstripComponent } from '../tabstrip/tabstrip.component';

@Component({
  selector: 'app-tabcols',
  templateUrl: './tabcols.component.html',
  styleUrls: ['./tabcols.component.scss']
})
export class TabcolsComponent extends TabstripComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() src: string;
  @Input() nav: Nav;

  public tabs: any[] = [];
  public showTabGroup = {};

  public loaded: boolean = false;

  ngOnInit(): void {
    this.processNav(this.nav);
  }

  processNav(nav: Nav) {
    this.nav = this.contentService.changeLanguage(this.nav, this.communicationService.user.language);
    this.tabs = [];

    this.nav.tabGroup.forEach((tabGroup, id) => {
      const _tabGroup = structuredClone(tabGroup);
      _tabGroup.menu.splice(0,1);

      this.tabs.push({
        group: _tabGroup.group,
        showHeader: true,
        menu: _tabGroup.menu
      });
    });

    setTimeout(() => {
      this.loaded = true;
    });
  }

  select(tab) {
    tab.navItemId = null;
    super.select(tab);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  trackByFn(index, item) {
    return index;
  }
}
