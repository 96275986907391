<ng-container *ngFor="let col of contentJSON.cols[0].col; trackBy: trackByFn">
  <div *ngIf="col.img" class="container-fluid p-0 m-0" [ngClass]="col.img[0].className">
    <div class="row py-3 px-0 mx-0">
      <div class="col-12 px-0">
        <div (click)="col.img[0].windowOpen ? windowOpen(col.img[0].windowOpen,'_blank') : null;"
          style="z-index: 400;
                  width: 300px;
                  height: 30px;
                  background-repeat: no-repeat;
                  float: right;"
          [style.cursor]="col.img[0].windowOpen ? 'pointer' : 'default'"
          [style.background-image]="'url(' + col.img[0].url + ')'">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="col.body"  class="container-fluid bg-dark">
    <div *ngFor = "let item of col.body; trackBy: trackByFn" class="row py-3" divBreakpointClass>
      <div class="col-1"></div>
      <div class="col-10">
        <div class="row" divBreakpointClass>
          <div class="col-12 text-light" style="cursor: pointer;" (click)="item.windowOpen ? windowOpen(item.windowOpen,'_blank') : null;">
            <app-cmd
                *ngIf="item.nav"
                [nav]="item.nav"
                [navList]="['custom','menu']"
                [navVisible]="item.nav.menu? item.nav.menu.length : 1"
                [collapsed]="false">
            </app-cmd>
            <span [innerHTML]="(item.text ? item.text : '&nbsp;') | safeHtml" ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
