<div class="configurator-input" tabindex="0" (clickOutside)="checkTitlesAndCodes()">
  <ul>
    @for (row of product().rows; let rowIndex = $index; track row) {
      <li>
        <input [id]="'ci_' + rowIndex" [value]="row.text"
          (keydown)="keyDownEvent(product(), rowIndex, null, $event);"
          (input)="setRow(row, rowIndex, $event.target.value)" />
        <ul>
          @for (option of row.options; let optionIndex = $index; track option) {
            <li>
              <input [id]="'ci_' + rowIndex + '.' + optionIndex" [value]="option.text"
                (keydown)="keyDownEvent(product(), rowIndex, optionIndex, $event);"
                (input)="setOption(option, optionIndex, $event.target.value)" />
            </li>
          }
          <!-- <app-cmd [component]="component" [nav]="addOptionNav" [navList]="['primary']" [navVisible]="1"/> -->
        </ul>
      </li>
    }
    <!-- <app-cmd [component]="component" [nav]="addRowNav" [navList]="['primary']" [navVisible]="1"/> -->
  </ul>
</div>
