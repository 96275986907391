import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true
    }
  ]
})
export class MultiSelectComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() formControlName: string;
  @Input() type: string;
  @Input() data: any = [];
  @Input() textField: string;
  @Input() valueField: string;
  @Input() disabled = false;
  @Input() id: string;
  @Input() className: string;
  @Input() readOnly: boolean;
  @Input() isJSON: boolean;

  @Output() onKeyup: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('input') inputElement: ElementRef;
  @ViewChild('drop') drop: NgbDropdown;

  private _searchDropDown = false;

  public _data: any;

  get searchDropDown() {
    return this._searchDropDown;
  }

  set searchDropDown(val) {
    this._searchDropDown = val;

    if (val) {
      this.drop.open();
    } else {
      this.drop.close();
    }
  }

  public searchTxt = '';
  public placeHolderSearchTxt = '';

  public dataChecked: any;
  public dataView: any[] = [];

  public selectedIndex: number = null;

  onTouched = () => {};
  touched = false;

  typing = false;

  public _value;

  get value() {
    return this._value;
  }

  set value(val) {
    if (val !== undefined && val !== null && this._data?.length) {
      switch(this.type) {
        case 'bitmapSelect':
          if (val) {
            let bitmap = this.utilsService.getBitmapFromArray(this._data.filter(item => item.checked), this.valueField)
            this._value = {
              [this.valueField]: bitmap,
              value: bitmap,
              data: this._data.filter(item => item.checked)
            };
          } else {
            this._value = {
              value: val,
              data: []
            };
          }
          break;
        case 'multiSelect':
          this._value = val;
          break;
        case 'dropdownList':
        case 'comboBox':
          this.searchTxt && this.isJSON ?
            this._value = this._data.find(item => this.getText(item) === this.searchTxt)
            :
            this._value = (typeof val === 'object') ?
              this._data.some(item => this.getText(item) === val[this.textField]) && this._data.find(item => this.getText(item) === val[this.textField])[this.valueField]
              :
              this._data.some(item => this.getText(item) === val) && this._data.find(item => this.getText(item) === val)[this.valueField];

          break;
      }
    } else {
      this._value = val;
    }

    this.onChange(this._value);
  }

  constructor(
    public utilsService: UtilsService) { }

  ngOnInit(): void {
    this.data && (this._data = structuredClone(this.utilsService.sortDataOnFields(this.data, [this.textField])));

    this.refreshSelected();
  }

  cellFocus(formControlName: string) {
    this.onFocus.emit(formControlName);
  }

  blur(event) {
    this.onBlur.emit(event);
    // this.searchDropDown = false;
  }

  getText(item: any) {
    return this.isJSON ?
      (this.textField === 'content' ? item[this.textField].text : item[this.textField]) || '' : item[this.textField] || '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data?.length) {
      this._data = structuredClone(this.utilsService.sortDataOnFields(this.data, [this.textField]));

      if (!this._data.some(item => this.getText(item) === this.searchTxt) && this.type !== 'comboBox') {
        this.searchTxt = '';
        this._data = this.utilsService.sortDataOnFields(this._data, [this.textField]);
      }
    }

    if (changes.data && !changes.data.isFirstChange()) {
      if (this.value?.length > 0 && ['multiSelect'].includes(this.type)) {
        this._data.forEach(item => item.checked = false);

        this.value.forEach(row => {
          (row instanceof Object) ?
            (this._data.some(item => item[this.valueField] === row[this.valueField])) &&
              (this._data.find(item => item[this.valueField] === row[this.valueField]).checked = true)
              :
            (this._data.some(item => item[this.valueField].toString() === row)) &&
              (this._data.find(item => item[this.valueField].toString() === row).checked = true);
        });
      }

      this.refreshSelected();
    }
  }

  keyDownEvent(searchTxt: string, key: any) {
    let found: any;
    switch(key) {
      case 'Tab':
      case 'Enter':
        this.typing = false;

        switch(this.type) {
          case 'bitmapSelect':
          case 'multiSelect':
            if (this.selectedIndex !== null) {
              const item = this.dataView[this.selectedIndex];
              this.select(item);
              this.value = this._data.filter(item => item.checked);
            }
            this.selectedIndex = null;
            this.searchDropDown = false;
            this.searchTxt = '';
            this.onBlur.emit();
            break;
          case 'dropdownList':
          case 'comboBox':
            found = this._data.find(item => this.getText(item).toLowerCase().includes(searchTxt.toLowerCase()));
            found && (this.searchTxt = this.getText(found));

            this.value = this.searchTxt;

            this.onBlur.emit();
            this.searchDropDown = false;
        }
        break;
    }
  }


  keyUpEvent(searchTxt: string, key: any) {
    switch(key) {
      case 'Tab':
      case 'Enter':
        this.typing = false;
        break;
      case 'ArrowDown':
        this.typing = false;
        if (this.selectedIndex !== null) {
          this.selectedIndex = this.selectedIndex < this.dataView.length - 1 ? this.selectedIndex + 1 : this.dataView.length - 1;
        } else {
          this.selectedIndex = 0;
          this.searchDropDown = true;
        }
        this.searchTxt = this.getText(this.dataView[this.selectedIndex]);
        break;
      case 'ArrowUp':
        this.typing = false;
        if (this.selectedIndex !== null) {
          if (this.selectedIndex > 0) {
            this.selectedIndex--;
            this.searchTxt = this.getText(this.dataView[this.selectedIndex]);
          } else {
            this.selectedIndex = null;
            this.searchDropDown = false;
            this.searchTxt = '';
            this.refreshSelected();
          }
        }
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
        this.typing = false;
        const event = {key: key};

        this.onKeyup.emit({field: this.id.split('_')[0], type: this.type, event: event, inputArea: this.inputElement});
        break;
      case 'Backspace':
        ['multiSelect', 'bitmapSelect'].includes(this.type) && this.searchTxt.length === 0 && this.dataChecked?.length > 0 &&
          (this.dataChecked[this.dataChecked.length - 1].checked = false);

        this.selectedIndex = null;
      default:
        this.typing = true;
        this.searchTxt = searchTxt;

        this.searchDropDown = (this.searchTxt.length && this.dataView?.some(row => this.getText(row).toLocaleLowerCase().includes(searchTxt.toLowerCase())) || this.selectedIndex !== null) ? true : false;

        // if (!this.searchTxt) {
        //   this.value = null;
        // }

        this.refreshSelected();

        if (this.type === 'comboBox') {
          if (!this._data.some(row => this.getText(row).toLocaleLowerCase().startsWith(searchTxt.toLowerCase()))) {
            this._data.length = 0;
            this.dataView.length = 0;
          }

          this.onFilterChange.emit(this.searchTxt);
        }
    }
  }

  refreshSelected() {
    if (this._data?.length) {
      switch(this.type) {
        case 'bitmapSelect':
          this.value &&
          this._data.forEach(item => item.checked = ((parseInt(item[this.valueField]) & parseInt(this.value.value)) > 0))

          this.dataChecked = this._data.filter(item => item.checked);
          this.dataView = this._data.filter(item => !item.checked);

          break;
        case 'multiSelect':
          // TODO: Controleren of hier ook initialisatie moet voor bij de serverFilters, net als de bitmapSelect

          this.dataChecked = this._data.filter(item => item.checked);
          this.dataView = this._data.filter(item => !item.checked).filter(item => this.getText(item).toLowerCase().startsWith(this.searchTxt.toLowerCase()));

          break;
        case 'dropdownList':
          this.dataView = this._data;

          if (this.typing && this.dataView?.some(row => this.getText(row).toLocaleLowerCase().includes(this.searchTxt.toLowerCase()))) {
            this.dataView = this._data.filter(item => this.getText(item).toLowerCase().includes(this.searchTxt.toLowerCase()));
            this.selectedIndex = 0;
          }
          break;
        default:
          this.dataView = this._data;
      }

      this.searchTxt?.length && this.dataView && this.dataView.length &&
        (this.selectedIndex = 0);
    }
  }

  select(item: any) {
    this.typing = false;
    if (['multiSelect', 'bitmapSelect'].includes(this.type)) {
      item.checked = true;
      this.refreshSelected();
      this.value = this._data.filter(item => item.checked);
    } else {
      this.searchTxt = this.getText(item);
      this.searchDropDown = false;
      this.value = this.searchTxt;
    }
    this.onBlur.emit();
  }

  deSelect(item) {
    item.checked = false;
    this.value = this._data.filter(item => item.checked);
    this.refreshSelected();
    this.onBlur.emit();
  }

  writeValue(val: any) {
    if (val) {
      switch(this.type) {
        case 'bitmapSelect':
          !(val instanceof Object) &&
            (val = { value: parseInt(val) });

          (this._data.some(item => (parseInt(item[this.valueField]) & val.value) > 0)) &&
            (this._data.forEach(item => item.checked = ((parseInt(item[this.valueField]) & parseInt(val.value)) > 0)));

          break;
        case 'multiSelect':
          !(val instanceof Array) && (val = [val]);

          val.forEach(row => {
            (row instanceof Object) ?
              (this._data.some(item => item[this.valueField] === row[this.valueField])) &&
                (this._data.find(item => item[this.valueField] === row[this.valueField]).checked = true)
                :
              (this._data.some(item => item[this.valueField].toString() === row)) &&
                (this._data.find(item => item[this.valueField].toString() === row).checked = true);
          });
          break;
        case 'dropdownList':
          !(val instanceof Object) &&
            (val = this._data.find(item => item[this.textField].toString() === val || item[this.valueField] === val));

          this.searchTxt = typeof val === 'object' ? this.getText(val) : val ?? '';

          break;
        case 'comboBox':
          (val instanceof Object) && (val = this.getText(val));

          this.searchTxt = val;
          this.onFilterChange.emit(this.searchTxt);

          // this.searchTxt = this.getText(val);
      }

      this.value = val;
      this.refreshSelected();
    } else {
      this.value = val;
      this.searchTxt = '';
    }
  }

  handleClickOutside(event) {
    this.searchDropDown = false;
  }

  onChange = (_: any) => {};

  registerOnChange(fn: (_: any) => void) {
    this.onChange = (value: any) => {
      fn(value);
    }
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
