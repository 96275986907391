<div *ngIf="loaded" class="row" divBreakpointClass>
  <div *ngFor="let tabGroup of tabs; trackBy: trackByFn" class="nav-group col-sm col-12">
    <div [ngClass]="tabGroup.showHeader ? 'nav-header': 'nav-header-placeholder'"
          (mouseover)="showTabGroup[tabGroup.group] = true"
          (mouseout)="showTabGroup[tabGroup.group] = false">
          <div class="nav-header-title" [ngClass]="showTabGroup[tabGroup.group] ? 'nav-header-collapse' : 'nav-header-expand'">
            <span *ngIf="tabGroup.showHeader">{{ tabGroup.group }}</span>
          </div>
      <div class="nav-items" [style.display] = "showTabGroup[tabGroup.group] ? 'block': 'none'">
          <div *ngFor="let navItem of tabGroup.menu; trackBy: trackByFn" class="nav-item" (click) = "select(navItem); $event.stopPropagation();">
            {{ navItem.text }}
          </div>
      </div>
    </div>
  </div>
</div>
