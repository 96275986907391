import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { from } from 'svgpath';
import { HookType, Inhaker, WeightObject, WorldObject } from '../inhaker-simulation/world';
import { SvgToolBaseComponent } from '../svg-inhaker-design-tool/svg-tool-base.component';

const snapDist = 0.07;
const depthSnapDist = 0.05;
const printInsertion = 0.005;

const reflectAcrossLine = (pX: number, pY: number, mX: number, mY: number): [number, number] => {
  const dot = pY * mY + pX * mX;
  const lenAB = Math.sqrt(pX ** 2 + pY ** 2) * Math.sqrt(mX ** 2 + mY ** 2);

  let sign = 1;

  if (mY / mX * pX > pY)
    sign *= -1;

  if (mX > 0)
    sign *= -1;

  const angle = Math.acos(dot / lenAB) * 2 * sign;

  return [
    Math.cos(angle) * pX - Math.sin(angle) * pY,
    Math.sin(angle) * pX + Math.cos(angle) * pY,
  ];
};

/** Horizontally flips and reverses paths. */
const svgReverse = (path: string) => {
  const svgPath =
    from('m0 0' + path)
      .scale(1, -1);

  // Library doesn't expose the path segments for whatever reason, so access them with casting.
  ((svgPath as any).segments as any[])
    .reverse() // Inverse the path definition
    .pop(); // Remove m0 0 instruction

  ((svgPath as any).segments as any[][])
    .forEach(v => {
      if (v[0] === 'c') {
        const ox = v[v.length - 2],
          oy = v[v.length - 1];

        for (let i = 1; i < v.length - 3; i += 2) {
          const [x, y] = reflectAcrossLine(v[i], v[i + 1], ox, oy);

          v[i] = x || 0;
          v[i + 1] = y || 0;
        }

      }
    });

  return svgPath.toString();
};

const svgScale = (path: string, scale: number) => {
  const svgPath =
    from('m0 0' + path)
      .scale(scale);

  ((svgPath as any).segments as any[]).shift();

  return svgPath.toString();
};

const pointToIllustratorMM = 2834.645669;
const scaleToIllustrator = (path: string) => {
  const svgPath =
    from('m0 0' + path)
      .scale(pointToIllustratorMM, -pointToIllustratorMM);

  ((svgPath as any).segments as any[]).shift();

  return svgPath.toString();

};

const calculateTime = (acceleration: number, velocity: number, distance: number): number => {
  // Acceleration in m/s² -  Velocity in m/s - Time to reach max speed
  const time1 = velocity / acceleration;

  // Distance covered during this time
  const distance1 = 0.5 * acceleration * time1 * time1;

  if (distance1 >= distance) {
    // Max speed is not reached
    return Math.sqrt(distance / (0.5 * acceleration));
  } else {
    // Max speed is reached
    return time1 + (distance - distance1) / velocity;
  }
};

const getLengths = (path: string) => {
  const partialPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  const segments = path.split(/(?=[MmLlHhVvCcSsQqTtAaZz])/);

  const acceleration = 9.81 * 0.7;
  const velocity = 20 / 60;
  const fullstop = 0.1; // seconds

  const list: {
    segment: string,
    length: number,
    time: number;
  }[] = [];

  const firstSegment = segments.splice(0, 1);

  segments.forEach(segment => {
    partialPath.setAttribute('d', firstSegment + segment);

    list.push({
      segment: segment,
      length: partialPath.getTotalLength(),
      time: fullstop + calculateTime(acceleration, velocity, partialPath.getTotalLength())

    });
  });

  return [
    list.map(item => item.length).reduce((total, length) => total + length, 0),
    list.map(item => item.time).reduce((total, time) => total + time, 0)
  ];
};


const snorSpace = 'c.0029.0014.005.0044.005.0079v.0137c0 .0047-.0037.0085-.0084.0087h-.0082';
const snorSpaceHalf = 'v.0136c0 .0047-.0037.0085-.0084.0087l-.0082 0';
const topBoardHook = 'v-.084c0-.0022-.0018-.004-.004-.004h-.003c-.0022 0-.004.0018-.004.004v.009c0 .0022-.0018.004-.004.004-.0022 0-.004-.0018-.004-.004 0 0 0-.0774 0-.0774 0-.0022-.0018-.004-.004-.004h-.034c-.0022 0-.004.0018-.004.004l0 .0774c0 .0022-.0018.004-.004.004-.0022 0-.004-.0018-.004-.004 0 0 0-.009 0-.009 0-.0022-.0018-.004-.004-.004h-.003c-.0022 0-.004.0018-.004.004v.084h0';

/** SVG path partials for hooks of different types. */
const hooks = {
  normalHookHalf: 'v.075v.0205c0 0 0 0 0 0 0 0 0 .0001 0 .0001 0 .0022-.0018.004-.004.004-.0022 0-.004-.0018-.004-.004 0 0 0 0 0 0v-.0004c0-.0004-.0001-.0007-.0004-.001l-.0082-.0082c-.0024-.0024-.0064-.0007-.0064.0026v.0382l.023.0232',
  normalHook: 'v.0205c0 0 0 0 0 0 0 0 0 .0001 0 .0001 0 .0022-.0018.004-.004.004-.0022 0-.004-.0018-.004-.004 0 0 0 0 0 0v-.0004c0-.0004-.0001-.0007-.0004-.001l-.0082-.0082c-.0024-.0024-.0064-.0007-.0064.0026v.0382l.023.0232',
  singleHook: 'v.0046c0 .0022-.0018.004-.004.004-.0008 0-.0016-.0002-.0021-.0006-.0094 0-.0168.0076-.0168.0168v.0487c0 .0056.0045.01.01.01h.0049v-.004c0-.0022.0018-.004.004-.004.0022 0 .004.0018.004.004v.004',
  upsideDown: 'v.031c0 .002.001.002.002.002h.011c.001 0 .002 0 .002-.002v-.006h0c0-.002.002-.004.004-.004s.004.002.004.004h0l0 .024c0 .003-.002.004-.004.004-.002.001-.004-.001-.004-.004v-.005c0-.002-.001-.002-.002-.002h-.011c-.001 0-.002 0-.002.002v.031'
} as const;

const upsideDownHookOffset = 0.020;
const singleHookHeight = 0.083641;
const snorSpaceOffset = 0.015;
const topBoardHookWidth = 0.08;


@Component({
  selector: 'g [app-svg-tool-object]',
  templateUrl: './svg-tool-object.component.html',
  styleUrls: ['../svg-draw/svg-draw.component.scss', './svg-tool-object.component.scss']
})
export class SvgToolObjectComponent extends SvgToolBaseComponent {
  @Input({ required: true }) object!: WeightObject;
  @Input({ required: true }) objects!: WorldObject[];

  @ViewChild('pathElement') path: ElementRef<SVGPathElement>;

  get widthSnapInterval() { return this.parent.widthResizeStep; }


  get boxWidth(): number {
    return this.object.width - this.boxSizeAdjustment;
  }

  get boxSizeAdjustment(): number {
    const { object } = this;

    return object.height * (
      (object.leftSidePath && object.inhakers[0] === undefined ? 0.5 : 0)
      + (object.rightSidePath && object.inhakers[2] === undefined ? 0.5 : 0)
    );
  }

  get shortestInhakerOnSide(): Inhaker | undefined {
    const lhs = this.objects[this.object.inhakers[0] as number] as Inhaker | undefined;
    const rhs = this.objects[this.object.inhakers[2] as number] as Inhaker | undefined;
    const behind = this.objects[this.object.inhakers[3] as number] as Inhaker | undefined;

    const leftHeight = lhs ? lhs.footHeight + lhs.poleHeight : Number.POSITIVE_INFINITY;
    const rightHeight = rhs ? rhs.footHeight + rhs.poleHeight : Number.POSITIVE_INFINITY;
    const behindHeight = behind ? behind.footHeight + behind.poleHeight : Number.POSITIVE_INFINITY;

    if (rightHeight < leftHeight && rightHeight < behindHeight)
      return rhs;
    else if (behindHeight < leftHeight)
      return behind;
    else
      return lhs || rhs || behind;
  };

  public Math = Math;

  public clamp = (min: number, max: number, value: number) => Math.min(max, Math.max(min, value));
  public round = (n: number) => this.Math.round(n * 1000) / 1000;

  public minNameSize = 0.15;
  public maxNameSize = 0.4;

  public weightScaleExponent = 3;
  public weightScaleLinear = 1 / 9.81;

  public get pathBoundArea() {
    const bBox = this.path.nativeElement.getBBox();
    return bBox.width * bBox.height;
  }
  public pathLength = 0;
  public pathCutDuration = 0;

  public buttons = [{ title: 'Remove', faIcon: '\uf2ed', onClick: () => this.parent.remove(this.object) }];


  public generatePath(): string {
    const { object, parent } = this;

    const axisMatched = parent.povAxis === object.activeAxis;
    let xOffset = 0;

    let top: string = `h${this.boxWidth}`;
    let right: string = `v${-object.height}`;
    let bottom: string = `h${-this.boxWidth}`;
    let left: string = `v${object.height}`;

    if (axisMatched) {

      if (object.inhakers[2] !== undefined) right = this.getHooksFlipped();
      else if (object.rightSidePath) {
        right = svgScale(this.object.rightSidePath!, this.object.height);

        xOffset -= object.height * 0.25;
      };


      if (object.inhakers[1] !== undefined)
        bottom = `h${-(this.boxWidth - topBoardHookWidth) * 0.5 + object.bottomHookOffsetX}${topBoardHook}h${-(this.boxWidth - topBoardHookWidth) * 0.5 - object.bottomHookOffsetX}`;


      if (object.inhakers[0] !== undefined) left = this.getHooks();
      else if (object.leftSidePath) {
        left = svgScale(object.leftSidePath!, object.height);
        xOffset += object.height * 0.25;
      }
    }

    const path = `m${xOffset} 0${top}${right}${bottom}${left}`;

    [this.pathLength, this.pathCutDuration] = getLengths(path);

    return path;
  }

  public export(element: WorldObject): string {
    return `<path id="${element.ident}" d="${scaleToIllustrator(this.generatePath())}"/>`;
  }

  public getHooks(inhaker: Inhaker = this.objects[this.object.inhakers[0]!] as Inhaker) {
    const height = this.object.height;
    const hookTopOffset = this.object.hookOffset; // TODO Or top coupling overlap
    const bottomCouplingOverlap = inhaker ?
      -Math.min(this.object.y - inhaker.y - inhaker.footHeight - this.object.height * 0.5 - 0.15, 0)
      : 0;
    const hookSpace = height - hookTopOffset - bottomCouplingOverlap - (bottomCouplingOverlap > 0 ? snorSpaceOffset : 0);

    let hooksPath: string;
    if (hookSpace < 0.15) // 1 hook = click
      hooksPath = `v${hookSpace - singleHookHeight}${hooks.singleHook}`;
    else if (this.object.hookType === HookType.UpsideDown)
      hooksPath = `h-.023v${hookSpace % 0.075}${hooks.upsideDown.repeat(Math.floor(hookSpace / 0.075))}h.023`;
    else if (hookSpace < 0.45) // 2-5 hook = normal
      hooksPath = `v${hookSpace % 0.075}${hooks.normalHook.repeat(Math.floor(hookSpace / 0.075))}`;
    else
      hooksPath = `v${hookSpace % 0.15}${hooks.normalHookHalf.repeat(Math.floor(hookSpace / 0.15))}`;


    let bottomOffsetSpace: string;
    if (bottomCouplingOverlap > 0.0303) bottomOffsetSpace = `v${(bottomCouplingOverlap - 0.0303 + snorSpaceOffset)}${snorSpace}`;
    else if (bottomCouplingOverlap > 0) bottomOffsetSpace = `h0.005v${(bottomCouplingOverlap - 0.0223 + snorSpaceOffset)}${snorSpaceHalf}`;
    else bottomOffsetSpace = `h-.0110011`;

    return `${bottomOffsetSpace}${hooksPath}v${hookTopOffset}h.0110011`;
  }

  public getHooksFlipped() {
    return svgReverse(this.getHooks(this.objects[this.object.inhakers[2]!] as Inhaker));
  }

  public override onDrag(element: WeightObject, component: string | undefined, x: number, y: number): boolean | void {
    switch (component) {
      case 'hookOffset':
        const inhaker = (this.objects[element.inhakers[0]] || this.objects[element.inhakers[2]]) as Inhaker;
        const bottomCouplingOffset = inhaker ?
          -this.Math.min(0, element.y - inhaker.y - inhaker.footHeight - element.height * 0.5 - 0.15)
          : 0;
        const newOffset = Math.min(element.height - bottomCouplingOffset - 0.084, Math.max(0, element.y - y));
        element.hookOffset = newOffset;

        return true;
      case 'weight':
        element.internalMass = (Math.max(element.y + element.comY - y, 0) ** (this.weightScaleExponent)) / this.weightScaleLinear;

        return true;

      case 'lWidth':
        let lWidth = Math.round(Math.max(((element.x + element.width * 0.5) - x), 0) / this.parent.widthResizeStep) * this.parent.widthResizeStep;
        lWidth = Math.max(this.boxSizeAdjustment, lWidth);
        const diffL = (lWidth - element.width) * 0.5;
        element.x -= diffL;
        element.width = lWidth;

        element.comX = Math.min(Math.max(element.comX, - element.width * 0.5), element.width * 0.5);

        // If object is attached to the top of a pole, make sure the hook stays snapped to the pole.
        if (element.inhakers[1] !== undefined)
          element.bottomHookOffsetX = Math.max(-element.width * 0.5 + 0.04, element.bottomHookOffsetX + diffL);

        return true;

      case 'rWidth':
        let rWidth = Math.round(Math.max((x - (element.x - element.width * 0.5)), 0) / this.parent.widthResizeStep) * this.parent.widthResizeStep;
        rWidth = Math.max(this.boxSizeAdjustment, rWidth);
        const diffR = (rWidth - element.width) * 0.5;
        element.x += diffR;
        element.width = rWidth;

        element.comX = Math.min(Math.max(element.comX, - element.width * 0.5), element.width * 0.5);

        // If object is attached to the top of a pole, make sure the hook stays snapped to the pole.
        if (element.inhakers[1] !== undefined)
          element.bottomHookOffsetX = Math.min(element.width * 0.5 - 0.04, element.bottomHookOffsetX - diffR);

        return true;

      case 'tHeight': {
        let maxH = Number.POSITIVE_INFINITY;
        let minH = singleHookHeight;

        const inhaker = this.shortestInhakerOnSide;
        if (inhaker !== undefined) {

          maxH = (inhaker.poleHeight + inhaker.footHeight + inhaker.y - element.y) * 2 + element.hookOffset;
          maxH -= Math.max(maxH - element.height, 0) / 2;

          minH += inhaker ? -Math.min(this.object.y - inhaker.y - inhaker.footHeight - this.object.height * 0.5 - 0.15, 0) : 0;
        }

        const height = Math.round(Math.max(Math.min(y - (element.y - element.height * 0.5), maxH), minH) / this.parent.heightResizeStep) * this.parent.heightResizeStep;

        element.y += (height - element.height) * 0.5;
        element.height = height;
        element.comY = Math.min(Math.max(element.comY, - element.height * 0.5), element.height * 0.5);

        return true;
      }
      case 'bHeight': {
        let maxH = Number.POSITIVE_INFINITY;
        let minH = singleHookHeight;

        const inhaker = this.shortestInhakerOnSide;
        if (inhaker !== undefined) {

          maxH = (element.y - inhaker.y - inhaker.footHeight) * 2;
          maxH -= Math.max(maxH - element.height, 0) / 2;

          minH += inhaker ? -Math.min(this.object.y - inhaker.y - inhaker.footHeight - this.object.height * 0.5 - 0.15, 0) : 0;
        }

        const height = Math.round(Math.max(Math.min((element.y + element.height * 0.5) - y, maxH), minH) / this.parent.heightResizeStep) * this.parent.heightResizeStep;;

        element.y -= (height - element.height) * 0.5;
        element.height = height;
        element.comY = Math.min(Math.max(element.comY, - element.height * 0.5), element.height * 0.5);

        return true;
      }

      case 'com':
        element.comX = Math.min(Math.max(x - element.x, - element.width * 0.5), element.width * 0.5);
        element.comY = Math.min(Math.max(y - element.y, - element.height * 0.5), element.height * 0.5);

        return true;

      default:

        if (!this.parent.isSnapBlocked && this.attemptSnapForMovement(element, x, y)) {

          const inhaker = (this.objects[element.inhakers[0]] || this.objects[element.inhakers[2]]) as Inhaker;
          const bottomCouplingOffset = inhaker ?
            -this.Math.min(0, element.y - inhaker.y - inhaker.footHeight - element.height * 0.5 - 0.15)
            : 0;
          const newOffset = Math.max(0, Math.min(element.height - bottomCouplingOffset - 0.084, Math.max(0, element.hookOffset)));
          element.hookOffset = newOffset;

          return true;
        }

        // Default move
        element.inhakers[0] = undefined;
        element.inhakers[1] = undefined;
        element.inhakers[2] = undefined;
        element.inhakers[3] = undefined;
        // element.bottomHookOffsetX = 0; TODO should reset on resnap?
        element.y = y;
        element.x = x;

        return true;
    }
  }

  private attemptSnapForMovement(element: WeightObject, x: number, y: number): boolean | void {
    const inhakers = this.parent.find(Inhaker);

    let closestLeft: Inhaker | undefined;
    let closestLeftDist = Number.POSITIVE_INFINITY;

    let closestBottom: Inhaker | undefined;
    let closestBottomDist = Number.POSITIVE_INFINITY;

    let closestRight: Inhaker | undefined;
    let closestRightDist = Number.POSITIVE_INFINITY;

    let closestPole: Inhaker | undefined;
    let closestPoleDist = Number.POSITIVE_INFINITY;

    const objectBottom = y - element.height * 0.5;
    const objectLeft = x - element.width * 0.5;
    const objectRight = x + element.width * 0.5;

    for (const inhaker of inhakers) {
      const top = inhaker.y + inhaker.footHeight + inhaker.poleHeight;

      // Depth-wise the element needs to be close to an object to have it snap to it.
      if (!(Math.abs(inhaker.z - element.z) < depthSnapDist))
        continue;

      // Calculate closest pole top
      const distTop = objectBottom - top;

      if (distTop >= -snapDist && closestBottomDist > distTop && inhaker.x > objectLeft + element.bottomHookOffsetX && inhaker.x < objectRight + element.bottomHookOffsetX) {
        closestBottomDist = distTop;
        closestBottom = inhaker;
      }

      // No chance of any of the next snap points matching, since it's not within the y length of the pole.
      if (!(element.y - element.hookOffset * 0.5 > inhaker.y && element.y - element.hookOffset * 0.5 < top))
        continue;

      // Calculate closest inhaker on the left
      const distLeft = objectLeft - inhaker.x - inhaker.poleWidth / 2;

      if (distLeft > -snapDist && closestLeftDist > distLeft) {
        closestLeftDist = distLeft;
        closestLeft = inhaker;
      }

      // Calculate closest inhaker on the right
      const distRight = inhaker.x - objectRight - inhaker.poleWidth / 2;

      if (distRight > -snapDist && closestRightDist > distRight) {
        closestRightDist = distRight;
        closestRight = inhaker;
      }

      const overlapClosestDist = Math.abs(inhaker.x - element.x) - element.width * 0.5;
      if (overlapClosestDist < closestPoleDist) {
        closestPole = inhaker;
        closestPoleDist = overlapClosestDist;
      }
    }

    element.inhakers[0] = undefined;
    element.inhakers[1] = undefined;
    element.inhakers[2] = undefined;
    element.inhakers[3] = undefined;

    if (Math.min(closestLeftDist, closestRightDist, closestBottomDist) < snapDist) {

      // Snap to top of pole, on bottom of object
      if (Math.min(closestLeftDist, closestRightDist) > closestBottomDist) {
        const closest = closestBottom!;

        element.x = closest.x - element.bottomHookOffsetX;
        element.y = closest.y + closest.footHeight + closest.poleHeight + element.height * 0.5 - 0.005;
        element.z = closest.z;

        element.inhakers[1] = this.objects.findIndex(v => v === closest);

      } else if (closestLeftDist > closestRightDist) { // Snap to the left of a pole, the pole closest on the right of an object
        const closest = closestRight!;

        element.inhakers[2] = this.objects.findIndex(v => v === closest);

        element.z = closest.z;

        if (closestLeft && closestRight!.x - closestLeft.x - closestLeft.poleWidth / 2 - closestRight!.poleWidth / 2 - element.width < snapDist) {
          const dist = closest.x - closestLeft.x;

          element.x = closestLeft.x + dist * 0.5;
          element.width = dist - closestLeft.poleWidth * 0.5 - closest.poleWidth * 0.5 + printInsertion * 2;

          element.inhakers[0] = this.objects.findIndex(v => v === closestLeft);

          // Align inhakers
          closestRight.z = closest.z;

          let shortest = closest;
          if (closestLeft && closestLeft.poleHeight < shortest.poleHeight)
            shortest = closestLeft;

          this.ySnap(element, shortest, y);

          return true;
        } else {
          this.ySnap(element, closest, y);

        }


        element.x = closest.x - (closest.poleWidth + this.object.width) * 0.5 + printInsertion;

      } else { // Snap to the right of a pole, the pole closest on the left of an object
        const closest = closestLeft!;

        element.inhakers[0] = this.objects.findIndex(v => v === closest);

        element.z = closest.z;

        if (closestRight && closestRight.x - closestLeft!.x - closestLeft!.poleWidth / 2 - closestRight.poleWidth / 2 - element.width < snapDist) {
          const dist = closestRight.x - closest.x;

          element.x = closest.x + dist * 0.5;
          element.width = dist - closest.poleWidth * 0.5 - closestRight.poleWidth * 0.5 + printInsertion * 2;

          element.inhakers[2] = this.objects.findIndex(v => v === closestRight);

          // Align inhakers
          closestRight.z = closest.z;

          let shortest = closest;
          if (closestRight && closestRight.poleHeight < shortest.poleHeight)
            shortest = closestRight;

          this.ySnap(element, shortest, y);

          return true;
        } else {
          this.ySnap(element, closest, y);

        }

        element.x = closest.x + (closest.poleWidth + element.width) * 0.5 - printInsertion;
      }

      return true;
    } else {
      // Check inside inhaker, then only y-snap
      if (closestPoleDist < snapDist) {
        const closest = closestPole!;
        this.ySnap(element, closest, y);
        this.object.inhakers[3] = this.objects.findIndex(v => v === closestPole);
        element.x = x;

        const offset = closest.poleWidth * 0.5 + element.depth * 0.5;
        element.z = closest.z + offset * ((element.z - closest.z) <= 0 ? -1 : 1);

        return true;
      }
    }
  }

  private ySnap(element: WeightObject, closest: Inhaker, y: number) {
    let hookOffset = element.height - element.hookOffset < 0.15 ? -0.002 : element.hookType === 1 ? upsideDownHookOffset : 0.04840544444428055956788439084448; // TODO Size x hook rules, + hooktype rules single = 0.00; normal = 0.05;  2834.645669
    hookOffset += element.hookOffset;

    const newY = Math.round((y + element.height / 2 - hookOffset - closest.footHeight - closest.y) / 0.075) * 0.075
      + closest.footHeight + closest.y - element.height / 2 + hookOffset;

    const topHookPoint = this.round(closest.poleHeight % 0.075);
    const topHookAttachment = topHookPoint > hookOffset ? 0 : (topHookPoint || 0.075) - hookOffset;

    element.y = this.clamp(
      closest.footHeight + closest.y + element.height * 0.5,
      closest.poleHeight + closest.footHeight + closest.y - element.height * 0.5 - topHookAttachment,
      newY
    );

  }

  public override onDragStart(dragging: WeightObject, component: string | undefined, x: number, y: number): void | [number, number] {
    switch (component) {
      case 'hookOffset':
        return [0, -dragging.hookOffset];
      case 'weight':
        return [0, dragging.comY - (dragging.internalMass * this.weightScaleLinear) ** (1 / this.weightScaleExponent)];
      case 'lWidth':
        return [-dragging.width * 0.5, 0];
      case 'rWidth':
        return [dragging.width * 0.5, 0];
      case 'tHeight':
        return [0, dragging.height * 0.5];
      case 'bHeight':
        return [0, -dragging.height * 0.5];
      case 'com':
        return [dragging.comX, dragging.comY];
    }
  }

  public override onDragEnd(element: WeightObject, component: string | undefined, x: number, y: number): void | [number, number] {
    switch (component) {
      case 'hookOffset':
      case 'tHeight':
      case 'bHeight':
        let shortest = element.inhakers
          .map(v => this.objects[v as number] as Inhaker)
          .filter(v => v)
          .reduce((acc, curr) => curr.poleHeight > (acc?.poleHeight || Number.POSITIVE_INFINITY) ? acc : curr, undefined);

        if (shortest && element.inhakers[1] === undefined)
          this.ySnap(element, shortest, element.y);

        return;
      case 'lWidth':
      case 'rWidth':
        this.attemptSnapForMovement(element, element.x, element.y);
        return;
    }
  }
}
