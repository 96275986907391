import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { UserService } from './user.service';
import { UtilsService } from './utils.service';
import { Nav } from '../widgets';

@Injectable({
  providedIn: 'root'
})
export class DomainConfigService {

  constructor(
    private http: NetworkService,
    private utilsService: UtilsService,
    private userService: UserService) {

  }

  public domainConfig: domainConfig;
  private sdId: number;

  /**
   * this method returns the domain config based on the retreved session.
   */
  public getDomainConfig(): Promise<domainConfig> {
    return new Promise(async (resolve) => {
      // If already requested then get from cache

      if(this.domainConfig) {
        resolve(this.domainConfig);
        return;
      }

      this.sdId = 7;

      // Request domain config
      this.http.query([{
        db: 'xfw3',
        src: 'getDomainConfig',
        params: []
      }], 'json').subscribe((config) => {
        config = JSON.parse(config[0].data[0].websiteConfig);

        // If not able to retreve config or config does not excist then return default.
        if(config.error || !config.layoutClassNames) {
          resolve({
            topNav: true,
            sideNav: true,
            css: 'xfw',
            favicon: 'jmc',
            title: 'XFW 3 Sign System'
          } as domainConfig);
          return;
        }

        // Store to cache
        this.domainConfig = config;

        // Return config.
        resolve(this.domainConfig);
      });
    });
  }
}

interface domainConfig {
  topNav: boolean,
  sideNav: boolean,
  menu?: {
    anon: string,
    auth: string,
    blockLayoutGrid: string[]
  }
  home?: [],
  login: boolean,
  css: string,
  favicon: string,
  title: string,
  googleAnalytics?: string
  layoutClassNames: string[],
  searchNav: Nav,
  roles: any;
  languages: [
    {
      language: string,
      text: string,
      img: string
    }
  ]
}
