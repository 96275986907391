<div class="row-wrapper" [ngClass]="row.state" (click)="row.state.expanded = !row.state.expanded;">
  <div [ngClass]="['xfw3-grid-col-container', product.mode === 'rows' ? 'rows' : '', row.state.expanded ? 'expanded' : 'colapsed', row.type === 'readOnly' ? 'read-only' : '']"
       divBreakpointClass
       [id]="[row.code, row.itemIndex].join('_')">
    <div class="option-area">
      <h5>
        {{ row.block.name }}
      </h5>
    </div>

    <div class="choice-area">
      {{ row.optionSelected?.block?.name }}
    </div>

    <div class="icon-area">
      @if (row.state.checked) {
        <div class="icon-checked"></div>
      }
    </div>

    <div class="cmd-area">
      @if (row.state.checked) {
        <app-cmd
          [component]="component"
          [dataItem]="row"
          [nav]="nav"
          [navList]="['menu']"
          dropdownClassName="icon xxs more"
          [navVisible]="1"/>
      }
    </div>
  </div>

  <div class="row" [hidden]="!row.state.expanded && product.mode !== 'rows'">
    <div class="col-12">
      @if (row.type !== 'dataGroup') {
        <div [ngClass]="[product.mode === 'cards' ? 'xfw3-cards' : 'xfw3-grid-row-container', row.imgAspectRatio ?? '']">
          @for (option of row.options; let index = $index; track option) {
            @if (!option.state.hidden || product.mode === 'rows') {
              <app-configurator-option
                  [dataGroupItemInstance]="dataGroupItemInstance"
                  [product]="product"
                  [row]="row"
                  [rowIndex]="rowIndex"
                  [index]="index"
                  [option]="option"
                  [code]="option.code"
                  [uploadType]="row.imgAspectRatio === 'no-img' ? 'no-img' : 'none'"
                  [state]="state"/>
            }
          }
        </div>
      }@else {
        <app-data-group
          [widgetId]="row.options[0].dataGroup"
          [src]="row.options[0].dataGroup"
          [dataItem]="row"/>
      }
    </div>
    @if (next && row.type !== 'dataGroup') {
      <div class="configurator-row-footer">
        <app-cmd
          [nav]="dataGroupItemInstance.rowOptions.nav"
          [navList]="['primary']"
          [navVisible]="dataGroupItemInstance.rowOptions.navItems"
          [className]=""
          [component]="component"
          [dataItem]="row"/>
      </div>
    }
  </div>
</div>
