import { Injector, Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe, JsonPipe, PercentPipe } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { NgIfPipe } from './ng-if.pipe';
import { AppendPipe } from './append.pipe';
import { PropertyValuePipe } from './property-value.pipe';
import { DateListPipe } from './date-list.pipe';
import { SecondsToHHMMPipe } from './seconds-to-hhmm.pipe';


@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: any, pipeToken: any, pipeArgs: any[]): any {
    let pipe = null;

    if (!pipeToken) {
      return value;
    } else {
      switch (pipeToken) {
        case 'PercentPipe':
          pipe = this.injector.get(PercentPipe);
          break;
        case 'DatePipe':
          pipe = this.injector.get(DatePipe);
          break;
        case 'DecimalPipe':
          pipe = this.injector.get(DecimalPipe);
          break;
        case 'JsonPipe':
          pipe = this.injector.get(JsonPipe);
          break;
        case 'FilterPipe':
          pipe = this.injector.get(FilterPipe);
          break;
        case 'NgIfPipe':
          pipe = this.injector.get(NgIfPipe);
          break;
        case 'AppendPipe':
          pipe = this.injector.get(AppendPipe);
          break;
        case 'PropertyValuePipe':
          pipe = this.injector.get(PropertyValuePipe);
          break;
        case 'DateListPipe':
          pipe = this.injector.get(DateListPipe);
          break;
        case 'SecondsPipe':
          pipe = this.injector.get(SecondsToHHMMPipe);
          break;
      }
      return pipe.transform(value, ...pipeArgs);
    }
  }
}
