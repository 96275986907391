@if (loaded && configuratorReady &&  productJSON?.configuratorRows) {
  <div [ngClass]="[productJSON.mode === 'rows' ? 'xfw3-masonry' : 'xfw3-grid-col-container']">
    @for (row of productJSON.configuratorRows; let index = $index; track index) {
      @if ((!row.state.hidden && row.type !== 'hide') || productJSON.mode === 'rows') {
        <div [ngClass]="[productJSON.mode === 'cards' ? 'g-col-12' : 'g-col-4']">
          <app-configurator-row
            [dataGroupItemInstance]="dataGroupItemInstance"
            [product]="productJSON"
            [row]="row"
            [rowIndex]="index"
            [state]="state"/>
        </div>
      }
    }
  </div>
}
