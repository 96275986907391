import { Directive, ElementRef, Host, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[preventHorizontalScroll]'
})
export class PreventHorizontalScrollDirective {

  private nativeElement: HTMLElement;
  @HostBinding('scrollLeft') private scrollLeft;

  constructor(@Host() element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  @HostListener('wheel', ['$event']) wheelTurned(wheelEvent: WheelEvent) {
    wheelEvent.stopPropagation();
  }
}
