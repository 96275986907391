import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, lastValueFrom, map, of, switchMap } from 'rxjs';
import { NetworkService } from './network.service';
import { fork } from 'child_process';
import { ConfiguratorProduct } from '../configurator';
import { ConfiguratorPrintcomService } from './configurator-printcom.service';

@Injectable({
  providedIn: 'root'
})
export class TempConfiguratorService {

  constructor(
    private networkService: NetworkService,
    private printcom: ConfiguratorPrintcomService
  ) { }

  getPrices(configurationJSON: any, nasData: any[], accessoryJSON: any, compId: number) {
    const procedure: string = 'Job.PriceTierCRUD';
    const mutations: any[] = [];

    console.log('getPrices', configurationJSON.suppliers, configurationJSON, nasData, accessoryJSON, compId);

    if (!configurationJSON.suppliers.length) return of([]);	// geen suppliers, geen prijzen.

    const requests: Observable<any>[] = [];

    configurationJSON.suppliers.forEach((supplierId: number) => {
      switch(supplierId) {
        case 532:
          requests.push(this.printcom.getPrices(supplierId, configurationJSON, nasData, compId));
          break;
        case 93: // Probo
          requests.push(this.getPricesProbo(supplierId, configurationJSON, nasData, compId));
          break;
        default:
          requests.push(this.getPricesSignSpine(supplierId, configurationJSON, nasData, compId));
      }
    });

    return forkJoin(requests).pipe(
      map((results: any[]) => {
        results.forEach(dataSet => {
          dataSet && dataSet.forEach((row: any) => {
            mutations.push(structuredClone(row));
          });
        });
        return mutations;
      }),
      switchMap((mutations: any) => {
        return this.networkService.crud([{
          db: 'xfw3',
          src: null,
          procedure: procedure,
          mail: null,
          mutations: mutations
        }], 'json')
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return of('An error occurred while processing the observables');
      }),
    );
  }

  getAccessories(configurationJSON: any, nasData: any[], compId: number, containerId: number): Observable<any> {
    const procedure: string = 'Job.ComponentCRUD';
    const mutations: any[] = [];
    const mutation: any = {
      crud: 'mergeChild',
      compId: compId,
      containerId: containerId,
      accessoryJSON: null
    };

    if (!configurationJSON.suppliers.length) return of([]);	// geen suppliers, geen prijzen.

    const requests: Observable<any>[] = [];

    configurationJSON.suppliers.forEach((supplierId: number) => {
      switch(supplierId) {
        case 532:

          break;
        case 93: // Probo
          requests.push(this.getAccessoriesProbo(supplierId, configurationJSON, nasData));
          break;
        default:
          requests.push(this.getAccessoriesSignSpine(supplierId, configurationJSON, nasData));
      }
    });

    return forkJoin(requests).pipe(
      map((data: any[]) => {
        data.forEach((row: any) => {
          mutation.accessoryJSON = row;
          mutations.push(structuredClone(mutation));
        });

        return mutations;
      }),
      switchMap((mutations: any) => {
        return this.networkService.crud([{
          db: 'xfw3',
          src: null,
          procedure: procedure,
          mail: null,
          mutations: mutations
        }], 'json')
      }),
      catchError(error => {
        console.error('Error occurred:', error);
        return of('An error occurred while processing the observables');
      }),
    );
  }

  getAccessoriesSignSpine(supplierId: number, configurationJSON: any, nasData: any[]): Observable<any> {
    return of(null); // bij Sign-Spine maken we de accessory in de database zelf.
  }

  getPricesSignSpine(supplierId: number, configurationJSON: any, nasData: any[], compId: number): Observable<any> {
    console.log('getPricesSignSpine', nasData, compId);

    return of(configurationJSON.priceTiers.map(priceTier => {
        return {
          crud: 'calculate',
          supplierId: supplierId,
          amount: priceTier.amount,
          compId: compId
        }
      })
    ); // bij Sign-Spine berekenen we de prijzen in de database zelf.
  }

  getPricesProbo(supplierId: number, configurationJSON: any, nasData: any[], compId: number): Observable<any> {
    const requests: Observable<any>[] = [];
    nasData = configurationJSON.priceTiers.map(priceTier => {
      return nasData.map(nasRow => {
        return {
          amount: nasRow.amount * priceTier.amount,
          width: nasRow.width,
          height: nasRow.height
        };
      });
    });

    // we rekenen de accessoires er nooit bij, maar we houden het wel in de convertToProboProducts functie.
    // voor mogelijke toekomstige aanpassingen.

    nasData.forEach((nasDataSet: any) => {
      const products = this.convertToProboProducts(configurationJSON.products, nasDataSet, []);
      requests.push(this.networkService.post('/api/PrintOnline/probo/price', { products: products }, 'json'));
    });

    return forkJoin(requests).pipe(
      catchError(error => {
        console.error('Error occurred:', error);

        // Return an error message as an Observable
        return of('An error occurred while processing the observables');
      }),
      map((data: any[]) => {
        let convertedPrices: any[] = new Array(data.length || 1).fill(null).map(() => structuredClone([]));

        data.forEach((priceSet: any, index: number) => {
          priceSet.prices.forEach((price: any) => {
          !convertedPrices[index].map((convertedPrice: any) => convertedPrice.purchasePrice).includes(price.products_purchase_price) &&
            convertedPrices[index].push({
              crud: 'merge',
              amount: configurationJSON.priceTiers[index].amount,
              delivery: Math.ceil(parseInt(price.production_hours) / 24) * 2,
              purchasePrice: price.products_purchase_price,
              supplierId: supplierId,
              compId: compId
            });
          });
        });

        return convertedPrices.flatMap(convertedPrice => convertedPrice);
      })
    );
  }

  getAccessoriesProbo(supplierId, configurationJSON: any, nasData: any[]): Observable<any> {
    const requests: Observable<any>[] = [];

    const products = this.convertToProboProducts(configurationJSON.products, nasData, []);

    products.forEach((product: any) => {
      requests.push(this.networkService.post('/api/PrintOnline/probo/configure', { products: [product] }, 'json'));
    });

    return forkJoin(requests).pipe(
      catchError(error => {
        console.error('Error occurred:', error);

        // Return an error message as an Observable
        return of('An error occurred while processing the observables');
      }),
      map((data: any[]) => {
        if (data[0].products[0].available_options.length) {
          const productJSON: ConfiguratorProduct = {
            mode: 'cards',
            code: 'accessory',
            rows: [],
            configuratorRows: []
          };

          const children = data[0].products[0].available_options[0].children;
          children.forEach((child: any) => {
            child.values = [child.default_value];
          });

          data.forEach((row: any, index: number) => {
            if (index > 0) {
              const extraChildren = row.products[0].available_options[0].children;

              children.forEach((child: any) => {
                const extraChild = extraChildren.find((extraChild: any) => extraChild.name === child.name);
                child.values.push(extraChild.default_value);
                child.default_value += extraChild.default_value;
                child.value = child.default_value
                child.type = 'checkbox';
              });
            }
          });

          const convertRowOption = (row: any, option: any) => {
            return {
              conditionalCodes: [],
              code: [row.code, option.code].join('.'),
              suppliers: [supplierId],
              imageUrl: option.images[0]?.url,
              block: { name: option.name },
              type: option.type || 'checkbox',
              unit: option.unit_code,
              selected: false,
              inValid: false,
              state: {
                hidden: true
              },
              default_value: option.default_value,
              value: option.value ?? option.default_value,
              values: option.values,
              price: option.price.purchase_price,
              purchasePrice: option.price.purchase_price,
              dropshipable: true // dit moet ergens vandaan komen, in de database zetten in plaats van hier.
            }
          };

          productJSON.rows.push({
            code: data[0].products[0].available_options[0].code,
            block: {
              name: data[0].products[0].available_options[0].name
            },
            state: {
              expanded: true
            },
            options: data[0].products[0].available_options[0].children.map((child: any) => convertRowOption(data[0].products[0].available_options[0], child)),
            conditionalCodesExt: []
          });
          return productJSON;
        } else {
          return null;
        }
      })
    );
  }

  convertToProboProducts(products: any[], nasData: any[], accessoryData: any) {
    const nasKeys: string[] = ['amount', 'width', 'height'];
    const productOptionKeys: string[] = ['code', 'value'];

    // eerst product.code goed zetten, zonder .
    products.forEach((product: any) => {
      product.code.includes('.') && (product.code = product.code.split('.')[1]);
    });

    nasData = nasData.map((row: any) => {
      return nasKeys.reduce((acc: any[], key) => {
        row[key] && acc.push(
            {
              code: key,
              value: parseInt(row[key])
            }
          );
        return acc;
      }, []);
    });

    // structuredClone van products 0 maken voor elke afmeting.
    products = new Array(nasData.length || 1).fill(null).map(() => structuredClone(products[0]));

    // opschonen product.option arrays
    products.forEach((product: any, index: number) => {
        (product.options = [
            ...(nasData.length ? nasData[index] : []),
            ...[
              ...(accessoryData?.options?.length ? accessoryData.options : []),
              ...product.options].map((row: any) => {
          return productOptionKeys.reduce((acc: any, key) => {
            row[key] && key === 'code' && (acc[key] = row[key].split('.')[1]);
            row[key] && key === 'value' && (acc[key] = parseInt(row[key]));
            return acc;
          }, {});
        })]);
    });

    return products
  }
}
