@for (item of [item]; track item) {
  <div [ngClass]="['data-row ',
                  (['row','link'].includes(dataGroupItemInstance.selectionMode) ? 'xfw-hover' : ''),
                  (dataGroupItemInstance.rowOptions?.nav?.type ?? ''),
                  (dataGroupItemInstance.rowOptions.checkable ? 'checkable' : ''),
                  (dataGroupItemInstance.rowOptions.rowCollapsable ? 'col-exp' : ''),
                  (dataGroupItemInstance.rowOptions.navItems || ['edit', 'editForm', 'editRow'].includes(dataGroupItemInstance.edit.mode) || dataGroupItemInstance.edit.updateMutations === 'batch' ? 'row-nav' : ''),
                  ((dataGroupItemInstance.selectionMode === 'row' || highLight) && isSelected ? 'selected' : ''),
                  (dataGroupItemInstance.rowOptions.checkable && item.checked ? 'checked' : ''),
                  highLight ? 'highlight' : '',
                  item.crud && dataGroupItemInstance.edit.updateMutations === 'batch' ? item.crud : '']"
      (click)="dataGroupItemInstance.selectionMode !== 'cell' && select([{ key: 'index', val: rowIndex }], item);"
      (mouseover)="showNav = true;"
      (mouseleave)="showNav = false;">

    <div class="check-mark">
      <div class="check-mark-label" (click)="dataService.itemCheckboxChange($event, item.trackBy, item, dataGroupItemInstance); $event.stopPropagation();">
        <input [checked]="item.checked" type="checkbox">
      </div>
    </div>

    <div [ngClass]="['row', ((dataGroupItemInstance.edit.mode == 'edit' || (dataGroupItemInstance.edit.mode === 'editRow' && item.edit)) && dataGroupItemInstance.edit.type === 'inline') ? '' : 'xfw3-grid-col-container']">
      @if (['view', 'editForm'].includes(dataGroupItemInstance.edit.mode) || (dataGroupItemInstance.edit.mode === 'editRow' && dataGroupItemInstance.edit.type !== 'inline')) {
        @for (field of dataGroupItemInstance.dataTable[0].cache; let last = $last; track $index) {
          @if (!field.child) {
            <ng-container *ngTemplateOutlet="contentTemplate; context: {field: field, item: item, last: last }"></ng-container>
          }@else {
            @for (child of field.child; track $index) {
              <ng-container *ngTemplateOutlet="contentTemplate; context: {field: child, item: item, last: last }"></ng-container>
            }
          }
        }
      }@else if ((dataGroupItemInstance.edit.mode == 'edit' || (dataGroupItemInstance.edit.mode === 'editRow' && item.edit)) && dataGroupItemInstance.edit.type === 'inline') {
        <app-form #appForm
          [widgetId]="widgetId + '_form' + '-' + rowIndex"
          [focus]="focus"
          [dataGroupItemInstance]="dataGroupItemInstance"
          [rowIndex]="rowIndex"
          [navList]="['primary']"
          [formClass]="dataGroupItemInstance.dataClassName"
          (onPaste)="paste($event)"
          (onSelect)="select($event)"/>
      }
    </div>

    @if (['editRow', 'edit', 'editForm'].includes(dataGroupItemInstance.edit.mode)) {
      <div [ngClass]="['row-nav-' + (dataGroupItemInstance.rowOptions.navItems ? dataGroupItemInstance.rowOptions.navItems : '2'), 'showNav']">
        <app-cmd
          [component]="component"
          [dataItem]="item"
          [nav]="nav"
          [navList]="['custom', 'primary','secondary','cancel', 'menu']"
          [dropdownClassName]="'icon xs more px'"
          [navVisible]="0"/>
      </div>
    }

    <div class="col-exp" (click)="collExp([], item); $event.stopPropagation();">
      @if (!item.collapsed) {
        <span class="row-collapse fa-regular fa-chevron-down"></span>
      }@else {
        <span class="row-expand fa-regular fa-chevron-right"></span>
      }
    </div>

  </div>
}

<ng-template #contentTemplate let-field="field" let-item="item" let-last="last">
  <div
    *accessLevel="field.xfw.accessLevel"
    [ngClass]="['data-cell', (field.xfw.dataListClassName | colToGCol) ?? '',
    field.templateOptions.dynamicClassName? item[field.templateOptions.dynamicClassName.field] ?? '' : '',
    field.templateOptions.format?.pipeToken ?? '',
    (dataGroupItemInstance.selectionMode === 'cell' && field.xfw.isSelectable ? 'xfw-hover' : ''),
    (dataGroupItemInstance.selectionMode === 'cell'
    && item.isSelected
    && field.xfw.isSelectable
    && item.cell === field.key ? 'selected' : '')]"
    (click)="dataGroupItemInstance.selectionMode === 'cell'
              && field.xfw.isSelectable
              && select([{ key: 'cell', val: field.key }, { key: 'index', val: rowIndex }], item)">

    <div>
      @if (field.type === 'checkbox') {
        <input [checked]="item[field.key]" type="checkbox" disabled>
      }

      @if (field.type === 'inputJSON') {
        <app-json-viewer [data]="item[field.key].data" [fields]="item[field.key].fields"></app-json-viewer>
      }

      @if (['inputDate','inputDates','inputCalc','number', 'duration'].includes(field.type)) {
        @if (field.templateOptions.format?.pipeToken) {
          <span [innerHTML]="(item[field.key] | dynamicPipe : field.templateOptions.format.pipeToken : field.templateOptions.format.pipeArgs) ?? '' | safeHtml"></span>
        }@else {
          <span>{{ item[field.key] }}</span>
        }
      }

      @if (['bitmapSelect','multiSelect','optionListRadio','optionListCheckbox'].includes(field.type) && item[field.key]) {
        @for (element of item[field.key].data ? item[field.key].data : item[field.key]; let last = $last; track $index) {
          <span>{{ field.xfw.inputData.textField === 'content' ? element[field.xfw.inputData.textField].text : element[field.xfw.inputData.textField] }}</span>
          @if (!last) {
            <span>, </span>
          }
        }
      }

      @if (['dropdownList','comboBox','upload'].includes(field.type) && item[field.key]) {
        @if (field.xfw.isJSON) {
          <span>{{ field.xfw.inputData.textField === 'content' ? item[field.key][field.xfw.inputData.textField].text : item[field.key][field.xfw.inputData.textField]}}</span>
        }@else {
          <span>{{ item[field.key]}}</span>
        }
      }

      @if (!['inputJSON', 'inputDate','inputDates','inputCalc','number','checkbox','dropdownList', 'bitmapSelect', 'multiSelect', 'comboBox','optionListCheckbox','optionListRadio','upload', 'duration'].includes(field.type)) {
        <div [attr.data-focus]="field.key"
          [class]="((last && dataGroupItemInstance.edit.type === 'inline') ? ' edit-inline-delete' : '')"
          [title]="item[field.key]"
          [innerHTML]="((item[field.key] !== null && item[field.key] !== undefined) ? item[field.key] : '&nbsp;') | safeHtml">
        </div>
      }
    </div>
  </div>
</ng-template>
