@if (loaded) {
  <div [ngClass]="['xfw3-tabstrip-container', className ?? '', nav.align ?? '', nav.className ?? '']"
    (clickOutside)="showMenu(null, false)"
    #tabstripContainer
    divBreakpointClass (breakpointClass)="checkSizes($event)"
    (mouseenter)="inTabstrip = true;"
    (mouseleave)="inTabstrip = false;">

    @if (isHamburger) {
      <div [ngClass]="['hamburger', nav.hamburger?.className ?? '', nav.hamburger?.align ?? '']" (click)="toggleFullscreen(true)">
        @if (nav.hamburger?.icon) {
          <div [ngClass]="['cmd-icon-before', nav.hamburger?.icon ?? '']"></div>
        }

        <div class="cmd-text"[innerHTML]="nav.hamburger?.text" [title]="nav.hamburger?.text"></div>

        @if (nav.hamburger?.icon) {
          <div [ngClass]="['cmd-icon-after', nav.hamburger?.icon ?? '']"></div>
        }
      </div>
    }

    @if (nav.fullscreen) {
      <div class="close" (click)="toggleFullscreen(false)"></div>
    }

    @if (!isHamburger || (isHamburger && nav.fullscreen)) {
      <div
        [ngClass]="['xfw3-tab-list', ['columns', 'treeview', 'tabs'].includes(nav.type) ? nav.type : '', nav.fullscreen ? 'fullscreen' : '']"
        [style.grid-template-columns]="nav.gridTemplateColumns">

        <ng-container *ngTemplateOutlet="['columns', 'treeview', 'tabs'].includes(nav.type) ? listItem : menu; context: { item: nav, level: 0 }"></ng-container>

      </div>
    }
  </div>
}

<ng-template #menu let-item="item" let-level="level">
  @for (menuItem of item.menu | ngIf : 'disabled' : false; track $index) {
    <ng-container *ngTemplateOutlet="tab; context: { item: menuItem, level: level }"></ng-container>
  }
</ng-template>

<ng-template #tab let-item="item" let-level="level">
  <div *accessLevel="item.accessLevel" #tabElement
       [ngClass]="['xfw3-tab', item.menu ? 'xfw3-tab-menu' : '', 'xfw3-tab-level-' + level, nav.type]"
       (click)="nav.type === 'tabs-dropdown-fullwidth' && showMenu(item, true);"
       (mouseenter)="nav.type !== 'tabs-dropdown-fullwidth' && showMenu(item, true);"
       (mouseleave)="nav.type !== 'tabs-dropdown-fullwidth' && showMenu(item, false); infoItem = null;">

    <ng-container *ngTemplateOutlet="cmd; context: { item:item, level: level }"></ng-container>

    @if (nav.type.includes('dropdown') && item.menu && !item.collapsed) {
      <div [ngClass]="['xfw3-dropdown-container', item.dropdown ? 'd-block' : 'd-none']"
          [style.left]="item.left ? item.left + 'px' : 'unset'"
          [style.width]="item.width ? item.width + 'px' : 'unset'">
        <div [ngClass]="['xfw3-dropdown-container-content', nav.infoPopup ? 'info-popup' : '']" [hidden]="!item.dropdown">
          @if (item.init || item.dropdown) {
            @defer (when nav.type !== 'tabs-dropdown-fullwidth'; on viewport) {
              @if (['tabs-dropdown', 'tabs-dropdown-treeview', 'tabs-dropdown-submenu'].includes(nav.type)) {
                @for (menuItem of item.menu; track $index) {
                  <ng-container *ngTemplateOutlet="tab; context: { item: menuItem, level: level + 1 }"/>
                }
              }@else {
                @for (menuItem of item.menu; track $index) {
                  <ng-container *ngTemplateOutlet="listItem; context: { item: menuItem, level: level + 1 }"/>
                }
              }

            }@placeholder {
              <div>
                @if (nav.type === 'tabs-dropdown-fullwidth') {
                  <app-spinner/>
                }@else {
                  <div style="height: 2rem; width: 100%; display: flex; align-items: center; justify-content: center;">
                    ...
                  </div>
                }
              </div>
            }
          }
        </div>
        @if (item.dropdown && nav.type === 'tabs-dropdown-fullwidth' && nav.infoPopup && blocks?.imageUrl) {
          <div class="xfw3-tab-info-popup-container">
            <img [src]="blocks?.imageUrl" />
            <!-- <app-content-block
              *ngIf="blocks"
              [blocks]="blocks"
              [params]="params"
              [environmentJSON]="{ viewportMode: false }">
            </app-content-block> -->
          </div>
        }
      </div>
    }
  </div>
</ng-template>

<ng-template #listItem let-item="item" let-level="level">
  <ng-container *accessLevel="item.accessLevel">
    @if (item.text) {
      <div [ngClass]="['xfw3-tab', item.menu ? 'xfw3-tab-menu' : '', 'xfw3-tab-level-' + level]">
        <ng-container *ngTemplateOutlet="cmd; context: { item:item, level: level }"></ng-container>
      </div>
    }
    @if (item.menu && !item.collapsed && level < nav.maxLevel) {
      @for (menuItem of item.menu | ngIf : 'disabled' : false; track $index) {
        <ng-container *ngTemplateOutlet="listItem; context: { item: menuItem, level: level + 1 }"></ng-container>
      }
    }
  </ng-container>
</ng-template>

<ng-template #cmd let-item="item" let-level="level">
  <ng-container *accessLevel="item.accessLevel">
    <div [ngClass]="['cmd', item.icon ? 'icon-text' : 'text', item.className ?? '', item.dropdown ? 'hover' : '', item.isSelected ? 'selected' : '']" [title]="item.text"
      (click)="select(item); !item.menu?.length && $event.stopPropagation();"
      (mouseenter)="!item.menu?.length && (blocks = item);">

      @if (item.collapsed) {
        <div>></div>
      }

      @if (item.icon) {
        <div [ngClass]="['cmd-icon-before', item.icon ?? '']"></div>
      }

      <div class="cmd-text"[innerHTML]="item.text" [title]="item.text"></div>

      @if (item.icon) {
        <div [ngClass]="['cmd-icon-after', item.icon ?? '']"></div>
      }

      @if (item.supplierIcons) {
        <div class="icons">
          @for (icon of item.supplierIcons; track $index) {
            @if (icon.iconUrl) {
              <img [src]="icon.iconUrl" [title]="icon.title ?? ''" />
            }
          }
        </div>
      }
    </div>
    @if (item.divide) {
      <hr class="cmd-divide"/>
    }
  </ng-container>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ msg ? msg.title : '' }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ msg ? msg.text : '' }}</p>
  </div>
  <div class="modal-footer">
    <div class="cmd text xfw3-bg-primary rounded">
      <button class="cmd-button" (click)="modal.close('CANCEL')">
        <div class="cmd-text">
          <div class="text-wrapper">
            <span [innerHTML]="msg ? msg.cancel : ''"></span>
          </div>
        </div>
      </button>
    </div>
    <div class="cmd text xfw3-bg-primary rounded uppercase">
      <button class="cmd-button" (click)="modal.close('OK')">
        <div class="cmd-text">
          <div class="text-wrapper">
            <span [innerHTML]="msg ? msg.primary : ''"></span>
          </div>
        </div>
      </button>
    </div>
  </div>
</ng-template>
