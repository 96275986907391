import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Nav, NavCurrent, TreeData } from '../../widgets';
import { TabstripComponent } from '../tabstrip/tabstrip.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent extends TabstripComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() container: string;
  @Input() src: string;
  @Input() nav: Nav;

  @ViewChild('menu', { static: false }) menu: ElementRef;

  public loaded = false;
  public treeData: TreeData[] = [];

  public productMenu: any[] = [];

  public current: NavCurrent = {
    mainIndex: -1,
    subIndex: -1,
    subSubIndex: -1,
    img: null,
    state: {
      hidden: true
    }
  };

  showMenu(event) {
    this.current.state.hidden = false;
  }

  closeMenu(event) {
    this.current.state.hidden = true;
  }

  ngOnInit(): void {
    this.processNav(this.nav);
  }

  ngAfterViewInit() {
    this.onResized();
  }

  processNav(nav: Nav) {
    nav = this.contentService.changeLanguage(nav, this.communicationService.user.language);

    nav.tabGroup.forEach(item => {
      item.tabGroup.forEach(subItem => {
        if (subItem.tabGroup) {
          subItem.cols = this.flattenTabgroups(subItem.tabGroup, 16);
        } else {
          subItem.cols = this.flattenTabgroups([subItem], 16);
        }
      })
    });

    this.loaded = true;
  }

  onResized(event?) {
    if (this.menu.nativeElement.offsetWidth < 920) {
      this.hamburger = true;
    } else {
      this.hamburger = false;
    }
  }

  flattenTabgroups(tabGroup: any[], itemsPerCol: number) {
    const cols: any[] = [];

    let items = [];
    tabGroup.forEach(item => {
      items.push({
        group: item.group
      });

      items = [...items, ...item.menu];
    });

    while (items.length) {
      // onderstaande is om ervoor te zorgen dat de laatste van een colom niet net een header is.
      if (!items[itemsPerCol - 1]?.group) {
        cols.push({
          items: items.splice(0, itemsPerCol)
        });
      } else {
        cols.push({
          items: items.splice(0, itemsPerCol - 1)
        });
      }
    }

    return cols;
  }

  trackByFn(index, item) {
    return index;
  }
}
