import { Component, Input, OnInit } from '@angular/core';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  constructor() { }

  ngOnInit(): void {
  }

  windowOpen(path: string, target: string) {
    window.open(path, target);
  }

  trackByFn(index, item) {
    return index;
  }

}
