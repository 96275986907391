@if (!dataGroupItemInstance.noHeader) {
  <div class="row-header" [id]="'1'" divBreakpointClass>
    @if (dataGroupItemInstance.title.text) {
      <div class="header-top">
        <div [ngClass]="['title', dataGroupItemInstance.title.className ?? '']">
          {{ dataGroupItemInstance.title.text }}
        </div>
      </div>
    }

    @if (dataGroupItemInstance.header?.nav && dataGroupItemInstance.header.navItems > 0 ) {
      <div class="header-middle">
        <app-cmd
          [nav]="dataGroupItemInstance.header.nav"
          [navList]="['menu']"
          [navVisible]="dataGroupItemInstance.header.navItems"
          [className]=""
          [component]="component"
          [dataItem]="dataItem"
          [data]="dataGroupItemInstance.dataTable[0]?.data"
          [collapsed]="true"/>
      </div>
    }

    @if (!['form','item'].includes(dataGroupItemInstance.layout)) {
      <div class="header-bottom">
        @if (['pagination', 'virtual-scroll'].includes(dataGroupItemInstance.paging)) {
          <div class="title-container">
            <div class="rows">
              {{ dataGroupItemInstance?.dataTable[0]?.data?.length }}
              @if (dataGroupItemInstance.page > 0) {
                /{{ dataGroupItemInstance.page }}
              }
            </div>
            <!-- <div class="sub-title">{{ dataGroupItemInstance.dataTable[0].data.length === 0 ? dataGroupItemInstance.title.nodata : dataGroupItemInstance.dataTable[0].data.length === 1 ? dataGroupItemInstance.title.singleItem : dataGroupItemInstance.title.multipleItems }}</div> -->
          </div>
        }

        @if (headerPagination && dataGroupItemInstance.dataTable[0].data.length > dataGroupItemInstance.page && dataGroupItemInstance.paging === 'pagination') {
          <div class="pagination">
            <ngb-pagination
              (click)="$event.stopPropagation()"
              [collectionSize]="dataGroupItemInstance.dataTable[0].data.length"
              [pageSize]="dataGroupItemInstance.page"
              [(page)]="curPage"
              [size]="'sm'"
              [maxSize]="3"
              [rotate]="true"
              [boundaryLinks]="true"
              [ellipses]="false"/>
          </div>
        }

        <div class="search">
          <form class="form-inline" [formGroup]="form">
            <div [hidden]="!dataGroupItemInstance.search?.searchFields" class="search-wrapper">
              <div class="search-input">
                <input type="search" class="form-control bg-transparent" formControlName="searchTxt" placeholder="...">
              </div>
            </div>
            <div class="search-button">
              <app-cmd
                [nav]="navSearch"
                [navList]="['menu']"
                [navVisible]="6"
                [className]=""
                [component]="headerComponent"
                [dataItem]="dataItem"
                [collapsed]="false"/>
            </div>
          </form>
        </div>

        @if (dataGroupItemInstance.header?.nav && dataGroupItemInstance.header.navItems === 0) {
          <div>
            <app-cmd
              [nav]="dataGroupItemInstance.header.nav"
              [navList]="['menu']"
              [navVisible]="dataGroupItemInstance.header.navItems ?? 3"
              [className]=""
              dropdownClassName="icon xxs more"
              [component]="component"
              [dataItem]="dataItem"
              [data]="dataGroupItemInstance.dataTable[0]?.data"
              [collapsed]="true"/>
          </div>
        }
      </div>
    }
  </div>
}
