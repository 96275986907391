import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DomainConfigService } from './services/domain-config.service';
import { UserService } from './services/user.service';
import { Seo } from './widgets';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private userService: UserService,
    private domainConfigService: DomainConfigService
  ) {
    this.domainTitle = this.domainConfigService.domainConfig.title;
  }

  private domainTitle;

  /**
   * This method will set the right SEO params in the header
   *
   * @param SEOData Current page SEO data
   */
  public setSEOData(SEOData: Seo) {
    // console.log('Running SEO thingy!')

    if(!SEOData) {
      this.titleService.setTitle(this.domainTitle);

      // console.warn('No SEO data provided in this route. This page is not Search Engine optimized!')
      return;
    }

    // Set title of page
    if(SEOData.title)
      // @ts-ignore
      this.titleService.setTitle(SEOData.title + ' | ' + this.domainTitle);
    else
      this.titleService.setTitle(this.domainTitle);

    // Set Meta Tags
    if(SEOData.description)
      this.metaService.addTag({
        name: 'description',
        content: SEOData.description
      });

    if(SEOData.tags)
      this.metaService.addTag({
        name: 'keywords',
        content: SEOData.tags
      });
  }

}
