import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';

@Component({
  selector: 'app-data-gmap',
  templateUrl: './data-gmap.component.html',
  styleUrls: ['./data-gmap.component.css']
})
export class DataGmapComponent extends DataGenericComponent implements OnInit, OnDestroy{
  public options: any;

  ngOnInit(): void {
    super.ngOnInit();
    this.options = this.utilsService.objectFromParams(this.dataGroupItem.params);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
