import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventParams, Nav, Param, WidgetEvent, WidgetState } from 'src/app/widgets';
import { DataGenericComponent } from '../data-generic/data-generic.component';

@Component({
  selector: 'app-data-matrix',
  templateUrl: './data-matrix.component.html',
  styleUrls: ['./data-matrix.component.scss']
})
export class DataMatrixComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public dataTabSelected: number = 0;

  public matrixTabs: any[] = [];
  public matrixHeaders: any[] = [];
  public matrixRows: any[] = [];
  public matrixData: any[] = [];
  public matrix: any[] = [];

  public nav: Nav;

  public component = this.component;

  public doRefreshMatrix: boolean = true;

  ngOnInit() {
    console.log(this.widgetId);

    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.REFRESHED,
          func: 'refreshMatrix'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.SAVED,
          func: 'refreshMatrix'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.DELETED,
          func: 'refreshMatrix'
        }
      ]
    });

    this.nav = {
      navId: -60,
      cancel: {
        className: 'cmd icon xs',
        icon: 'icon icon-arrows-remove',
        text: '',
        info: 'Verwijder',
        widgetAction: [
          {
            widgetGroup: [this.widgetId],
            event: WidgetEvent.DELETE
          }
        ]
      }
    };

    super.ngOnInit();
  }

  deleteRow(params) {
    return new Promise((resolve, reject) => {
      const field = 'amount';

      this.dataGroupItemInstance.dataTable[0].data.filter(dataRow => dataRow[field] === params.dataItem[field]).forEach(dataRow => {
        dataRow.crud = 'delete';
      });

      this.updateMutations();

      resolve(null);
    });
  }

  refreshMatrix() {
    if (this.doRefreshMatrix && this.dataGroupItemInstance.dataTable[0]?.data.length) {
      this.matrixTabs = [];
      this.matrixHeaders = [];
      this.matrixRows = [];
      this.matrixData = [];
      this.matrix = [];

      this.dataGroupItemInstance.dataTable[0].data.forEach(row => {
        const matrixTab = {};
        const matrixHeader = {};
        const matrixRow = {};
        const matrixDataItem = {};

        this.dataGroupItemInstance.matrix.tabFields.forEach(field => {
          matrixTab[field] = row[field];
        });

        matrixTab['cols'] = [];
        matrixTab['rows'] = [];
        matrixTab['isSelected'] = false;

        this.matrixTabs.push(matrixTab);

        this.dataGroupItemInstance.matrix.headerFields.forEach(field => {
          matrixHeader[field] = row[field];
        });

        matrixHeader['dataRows'] = [];

        this.matrixHeaders.push(matrixHeader);

        this.dataGroupItemInstance.matrix.rowFields.forEach(field => {
          matrixRow[field] = row[field];
        });

        this.matrixRows.push(matrixRow);

        this.dataGroupItemInstance.matrix.fields.forEach(field => {
          matrixDataItem[field] = row[field];
        });

        this.matrixData.push(matrixDataItem);
      });

      this.matrixTabs = this.utilsService.uniqueArrays(this.matrixTabs);
      this.matrixHeaders = this.utilsService.uniqueArrays(this.matrixHeaders);
      this.matrixRows = this.utilsService.uniqueArrays(this.matrixRows);
      this.matrixData = this.utilsService.uniqueArrays(this.matrixData);


      this.matrixTabs.forEach(tab => {
        this.matrixHeaders.forEach(header => {
          if (this.objectsHaveCommonFieldValues(tab, header, this.dataGroupItemInstance.matrix.tabFields)) {
            this.matrixData.forEach(item => {
              if (this.objectsHaveCommonFieldValues(header, item, this.dataGroupItemInstance.matrix.headerFields)) {
                header.dataRows.push(this.objectRemoveFields(item, [...this.dataGroupItemInstance.matrix.headerFields, ...this.dataGroupItemInstance.matrix.rowFields]));
              }
            });
            tab.cols.push(this.objectRemoveFields(header, this.dataGroupItemInstance.matrix.tabFields));
          }
        });

        this.matrixRows.forEach(row => {
          if (this.objectsHaveCommonFieldValues(tab, row, this.dataGroupItemInstance.matrix.tabFields)) {
            tab.rows.push(this.objectRemoveFields(row, this.dataGroupItemInstance.matrix.headerFields));
          }
        });

        this.matrix.push(tab);
      });

      this.matrix[this.dataTabSelected].isSelected = true;
    }

    this.doRefreshMatrix = true;
  }

  objectsHaveCommonFieldValues(src: any, dest: any, fields: string[]): boolean {
    let result = true;

    for(let field of fields) {
      if (src[field] !== dest[field]) {
        return false;
      }
    };

    return result;
  }

  objectRemoveFields(object: any, fields: string[]): any {
    fields.forEach(field => {
      delete object[field];
    });

    return object;
  }

  selectTab(eventParams: EventParams) {
    this.matrix.forEach(tab => tab.isSelected = false);

    this.dataTabSelected = this.matrix.findIndex(tab => this.objectsHaveCommonFieldValues(tab, eventParams.dataItem, this.dataGroupItemInstance.matrix.tabFields));
    this.matrix[this.dataTabSelected].isSelected = true;
  }

  selectItem(row, col) {
    this.doRefreshMatrix = false;

    this.matrix.forEach(tab => {
      tab.cols.forEach(col => {
        col.dataRows.forEach(dataRow => {
          dataRow.isSelected = false;
          dataRow.selected = false;
        });
      });
    });

    col.isSelected = true;
    col.selected = true;

    const dataItem: any = {};

    Object.keys(row).forEach(key => {
      dataItem[key] = row[key];
    });

    Object.keys(col).forEach(key => {
      dataItem[key] = col[key];
    });

    let primaryKeys: Param[] = [];

    this.dataGroupItemInstance.dataTable[0].primaryKeys.forEach(param => {
      primaryKeys.push({
        key: param.key,
        val: dataItem[param.key]
      });
    });

    this.dataGroupItemInstance.dataTable[0].data &&
    (this.dataGroupItemInstance.dataTable[0].data.forEach(row => row.selected = false))

    this.dataGroupItemInstance.dataTable[0].data &&
      (this.dataGroupItemInstance.dataTable[0].data.find(item => this.utilsService.isSubset(item, this.utilsService.objectFromParams(primaryKeys))).crud = 'select') &&
          this.updateMutations();

    this.communicationService.performAction({
      widgetGroup: [this.widgetId],
      event: WidgetEvent.SELECTED,
      dataItem
    });
  }
}
