import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyValue'
})
export class PropertyValuePipe implements PipeTransform {

  transform(value: any, ...args: any): any {
    return value.filter(x => !!x[args[0]] === args[1]);
  }
}
