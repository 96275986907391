import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService, Notification } from '../services/notification.service';

@Injectable()
export class ErrorResponceInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map((req: HttpEvent<any>) => {
          // The full response including the body was received.
          if(req.type === 4) {
            const request = req as HttpResponse<any>;

            if(Array.isArray(request.body)) {
              request.body.forEach((responseObject) => {
                this.checkIfObjectIsAnError(responseObject);
              });
            } else {
              this.checkIfObjectIsAnError(request);
            }
          }

          return req;
        })
      )
  }

  /**
   * This method will check for an default error response.
   *
   * @param httpResponse An httpResponse object from an intercept response
   */
  private checkIfObjectIsAnError(httpResponse: any) {
    if(httpResponse.body && httpResponse.body.msgType) {
      const defaultResponse: Notification = {
        statusCode: httpResponse.status,
        ...httpResponse.body
      }

      if(defaultResponse.msgType === 'error' || defaultResponse.msgType === 'warning') {
        // Procedure allways returns 200 because one procedure might fail. But that does not mean all procedures failed.
        // This will give a more fitting response code.
        defaultResponse.statusCode = defaultResponse.statusCode === 200 ? 400 : 200;

        defaultResponse.autoHide = defaultResponse.msgType === 'warning';

        this.notificationService.addNotification(defaultResponse);
      }
    }
  }

}
