<div class="xfw3-grid-container">
  <div class="xfw3-grid-row-container">
    <div class="xfw3-grid-row">
      <app-data-group
        [widgetId]="paramJSON.widgetId"
        [src]="paramJSON.src"
        [dataGroup]="paramJSON.dataGroup"/>
    </div>
    <div class="xfw3-grid-row">
      <app-nav
        [widgetId]="tabstripJSON.widgetId"
        [src]="tabstripJSON.src"/>
    </div>
  </div>
</div>
