import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ContentService } from 'src/app/services/content.service';
import { DomainConfigService } from 'src/app/services/domain-config.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit, OnDestroy {
  public switchPopup: boolean = false;

  public languageList: any[];
  public language: string;
  private userSubscriber: Subscription;

  constructor(
    private userService: UserService,
    private domainConfigService: DomainConfigService,
    private contentService: ContentService
  ) {
    this.userSubscriber = this.userService.user$.subscribe((user) => {
      this.language = user.language;
    });
  }

  ngOnInit(): void {
    this.languageList = this.domainConfigService.domainConfig.languages;
    this.reorderList(this.language);
  }

  ngOnDestroy(): void {
      this.userSubscriber.unsubscribe();
  }

  onClickOutside(e: Event) {
    this.switchPopup = false;
  }

  reorderList(language: string) {
    this.languageList.unshift(this.languageList.splice(this.languageList.findIndex(item => item.language === language), 1)[0]);
    this.contentService.changeLanguage(this.languageList, language);
  }

  setLang(item) {
    this.userService.setUser({ language: item.language });
    this.reorderList(item.language);
    // window.location.reload();
  }

  trackByFn(index, item) {
    return index;
  }
}
