import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { DataGroup, DataTable } from 'src/app/data';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';
import { EventParams, Nav, WidgetEvent } from 'src/app/widgets';

@Component({
  selector: 'app-component-list',
  templateUrl: './component-list.component.html',
  styleUrls: ['./component-list.component.scss']
})
export class ComponentComponentList extends DataGenericComponent implements OnInit, OnDestroy {
  @ViewChild("componentTabstrip") componentTabstrip: ElementRef;

  public navJSON: any = {
    widgetId: 'xfw.job-component',
    src: 'xfw.job-component'
  }

  public nav: Nav;
  public component: any = this;

  ngOnInit(): void {
    this.setFromParams(this.dataGroupItem, this.dataItem, this.widgetId);
    this.initWidget();
    this.initNav();

    this.communicationService.initWidget({
      widgetId: this.dataGroupItemInstance.widgetId + '_container',
      component: this,
      subscribeTo: [
        {
          widgetGroup: ['jobNas'],
          event: WidgetEvent.DONE,
          func: 'calculate'
        }
      ]
    });

    this.loadDataTable().then(data => {
    });
  }

  calculate(eventParams: EventParams) {
    const dataItem: any = this.dataGroupItemInstance.dataTable[0].data.filter(row => row.compId === eventParams.dataItem.compId)[0];
    let dataTable = eventParams.data[0] as DataTable;

    lastValueFrom(this.tempConfiguratorService.getPrices(
      dataItem.configurationJSON,
      this.configuratorService.getNasData(dataTable),
      [],
      dataItem.compId)).then((data) => {
        this.communicationService.performAction({
          info: 'refreshPriceTier',
          widgetGroup: ['dataMatrixJobPriceTier'],
          event: WidgetEvent.CASCADINGREFRESH,
          dataItem: dataItem
        });

        this.communicationService.performAction({
          info: 'refreshComponentPrice',
          widgetGroup: ['dataListJobComponent'],
          event: WidgetEvent.PARTIALREFRESH,
          data: data[0],
          dataItem: { fields: ['priceJSON']}
        });
    });
  }

  initNav() {
    // TODO: nav nog naar de database, overal.
    const msg = {
      title: "Verwijderen",
      text: "Dit component verwijderen?",
      cancel: "Annuleer",
      primary: "Ok",
      action: 10
    };

    this.nav = {
      navId: 0,
      primary: {
        className: 'cmd icon xxs round xfw3-bg-secondary',
        icon: 'fa-regular fa-pencil',
        crud: 'update',
        text: '',
        info: 'Edit',
        noToggle: true,
        accessLevel: this.dataGroupItemInstance.edit.accessLevel.crud,
        // func: 'configure',
        path: '/xfw/nl/configurator',
        params: [
          {
            key: "code",
            isQueryParam: true
          },
          {
            key: "compId",
            isQueryParam: true
          }
        ],
        paramsFrom: [
          {
            key: "code",
            src: "fromData"
          },
          {
            key: "compId",
            src: "fromData"
          }
        ]
      },
      cancel: {
        className: 'cmd icon xxs round xfw3-bg-secondary',
        icon: 'fa-regular fa-xmark',
        crud: 'delete',
        text: '',
        tabIndex: -1,
        info: 'Verwijder',
        noToggle: true,
        accessLevel: this.dataGroupItemInstance.edit.accessLevel.crud,
        func: 'delete',
        params: [
          {
            key: "compId"
          }
        ],
        paramsFrom: [
          {
            key: "compId",
            src: "fromData"
          }
        ],
        msg: msg
      }
    };
  }

  collExp(eventParams: EventParams) {
    eventParams.dataItem.collapsed = !eventParams.dataItem.collapsed;

    // if (!eventParams.dataItem.collapsed) {
    //   setTimeout(() => {
    //     this.componentTabstrip.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    //   }, 120);
    // }
  }

  onHasData(event, dataGroupItem) {
    dataGroupItem.isEmpty = (event.dataLength === 0);
  }
}
