import { Component, OnInit } from '@angular/core';
import { DataGroupMatrixGenericComponent } from '../data-group-matrix-generic/data-group-matrix-generic.component';

@Component({
  selector: 'app-data-group-matrix-static-col',
  templateUrl: './data-group-matrix-static-col.component.html',
  styleUrls: ['./data-group-matrix-static-col.component.css']
})
export class DataGroupMatrixStaticColComponent extends DataGroupMatrixGenericComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

}
