<ng-container>
  @if (dataGroupItem.layout === 'breadcrumbs') {
    <app-data-breadcrumbs
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'list'){
    <app-data-list
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'job'){
    <app-job-container
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItem"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'search-list'){
    <app-data-search-list
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'item'){
    <app-data-item
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'form'){
    <app-data-form
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'img'){
    <app-data-img
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'img-viewer'){
    <app-data-img-viewer
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'content'){
    <app-data-content
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'cards-group'){
    <app-data-cards-group
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout.includes('cards')){
    <app-data-cards
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'treeview'){
    <app-data-tree-view
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'tabs'){
    <app-data-tabs
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'configurator'){
    <app-configurator
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItem"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'login'){
    <app-data-login
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'gmap-list'){
    <app-data-gmap
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'svg'){
    <app-data-svg
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'matrix'){
    <app-data-matrix
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'sketchFab'){
    <app-data-sketch-fab
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'plan'){
    <app-data-planner
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'report'){
    <app-report-item
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataItem]="dataItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'inhakerDesignTool'){
    <svg-inhaker-design-tool
      [widgetId]="widgetId"
      [dataGroupItem]="dataGroupItem"
      [dataFilter]="dataFilter"
      (hasData)="onHasData($event)"
    />
  } @else if (dataGroupItem.layout === 'fibaro'){
    <app-fibaro
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItem"
    />
  }
</ng-container>
