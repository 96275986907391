import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageComponent } from '../page/page.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent extends PageComponent implements OnInit, OnDestroy  {
  public data: any = {};

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
