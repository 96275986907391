import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-fusion',
  templateUrl: './fusion.component.html',
  styleUrls: ['./fusion.component.css']
})
export class FusionComponent implements OnInit {
  @Input() contentJSON: any;
  @Input() environmentJSON: any;

  constructor(
    public dataService: DataService
  ) { }

  ngOnInit(): void {
  }

  trackByFn(index, item) {
    return index;
  }

}
