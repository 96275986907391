import { Injectable } from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import { DataTable } from '../data';
import { NetworkService } from './network.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public _user: any = {};

  userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(this._user);
  user$: Observable<any> = this.userSubject.asObservable();

  constructor(
    private networkService: NetworkService,
    private utilsService: UtilsService
    ) { }

  getUserGeoLocation() {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(position => {
            this.setUser({ latitude: position.coords.latitude, longitude: position.coords.longitude });
          },
          error => {
              switch (error.code) {
                  case 1:
                      console.log('Permission Denied');
                      break;
                  case 2:
                      console.log('Position Unavailable');
                      break;
                  case 3:
                      console.log('Timeout');
                      break;
              }
          }
        );
    }
  }

  setUser(data: any, skipRefresh: boolean = false) {
    const _user = {...this._user, ...data, ...{ sessionStatus: data?.authBitMask == 1 ? 'anonSession' : 'userSession' }};

    if (!this.utilsService.deepEquals(this._user, _user)) {
      this._user = _user;

      this.updateMutations(data);

      if (!skipRefresh) {
        this.userSubject.next(this._user);
      }
    }
  }

  updateMutations(data) {
    const dataTable: DataTable[] = [{
      src: "getUser",
      procedure: "Relation.UserCRUD",
      data: []
    }]

    dataTable[0].data[0] = {...data, ...{ crud: 'updateConfig'}};

    const subscription = this.networkService.crud([{
      db: 'xfw3',
      src: dataTable[0].src,
      procedure: dataTable[0].procedure,
      mail: dataTable[0].mail,
      mutations: dataTable[0].data
    }], 'json').subscribe(result => {
      const _subscription = this.networkService.get('/api/OauthV2/refresh', 'json').subscribe(session => {
        localStorage.setItem('session', session);

        _subscription.unsubscribe();
        subscription.unsubscribe();
      });
    });

  }

}
