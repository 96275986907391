import { Component, Input } from '@angular/core';
import { Bench, Inhaker, WorldObject } from '../inhaker-simulation/world';
import { SvgInhakerDesignToolComponent } from '../svg-inhaker-design-tool/svg-inhaker-design-tool.component';
import { SvgToolBaseComponent } from '../svg-inhaker-design-tool/svg-tool-base.component';

@Component({
  selector: 'g [app-svg-tool-bench]',
  templateUrl: './svg-tool-bench.component.html',
  styleUrls: ['./svg-tool-bench.component.scss', '../svg-draw/svg-draw.component.scss']
})
export class SvgToolBenchComponent extends SvgToolBaseComponent {
  @Input({ required: true }) object!: Bench;
  @Input({ required: true, alias: 'inhaker' }) set inhakerInput(v: WorldObject) { this.inhaker = v as Inhaker; }
  inhaker!: Inhaker;


  public Math = Math;

  public clamp = (min: number, max: number, value: number) => Math.min(max, Math.max(min, value));

  public minNameSize = 0.15;
  public maxNameSize = 0.4;

  public buttons = [{ title: 'Remove', faIcon: '\uf2ed', onClick: (() => this.parent.remove(this.object)) }];

  public override onDrag(element: Bench, component: string | undefined, x: number, y: number): boolean | void {
    switch (component) {
      case 'width':
        const inhaker = this.inhaker as Inhaker;

        if (element.x < inhaker.x) { // left
          const width = Math.max(((element.x + element.width * 0.5) - x), 0);

          element.x -= (width - element.width) * 0.5;
          element.width = width;
        } else { // right
          const width = Math.max((x - (element.x - element.width * 0.5)), 0);

          element.x += (width - element.width) * 0.5;
          element.width = width;
        }

        return true;

      case 'height':
        const height = Math.max((y - (element.y - element.height * 0.5)), 0);

        element.y += (height - element.height) * 0.5;
        element.height = height;

        return true;

      default:

        const idx = this.object.inhaker;
        const closest = this.parent.objects[idx] as Inhaker;
        element.inhaker = idx;

        const snapDist = (closest.poleWidth + element.width) * 0.5 - 0.005;
        const snapPoint =
          x > closest.x + snapDist * 0.33
            ? closest.x + snapDist
            : (
              x < closest.x - snapDist * 0.33
                ? closest.x - snapDist
                : closest.x
            );

        element.x = snapPoint;

        return true;

    }
  }

  public override onDragStart(element: Bench, component: string | undefined, x: number, y: number): void | [number, number] {
    switch (component) {
      case 'width':
        const inhaker = this.parent.objects[element.inhaker] as Inhaker;
        if (element.x < inhaker.x) return [-element.width * 0.5, 0];
        else return [element.width * 0.5, 0];

      case 'height':
        return [0, element.height * 0.5];

    }
  }

  public override onDragEnd(element: Bench, component: string | undefined, x: number, y: number): void | [number, number] {
  }

  public export(element: WorldObject): string {
    return '';
  }
}
