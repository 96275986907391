<ng-container *ngIf="loaded && dataGroupItemInstance.dataTable[0]?.data">
  <div [ngClass]="['tab-columns']" horizontalScroll>
    <ng-container *ngFor="let item of dataGroupItemInstance.dataTable[0].data | dataFilter : dataFilter; trackBy: trackByFn">
      <app-data-tab
        #dataTab
        (select)="select($event)"
        (setEditMode)="setEditMode($event)"
        [item]="item"
        [dataGroupItem]="dataGroupItem"
        [dataTabSelected]="dataTabSelected"></app-data-tab>
    </ng-container>

    <div *ngIf="['inline', 'form'].includes(dataGroupItemInstance.edit.type)" class="add-new-tab">
      <app-cmd [component]="component" [nav]="nav" [navList]="['primary']" [navVisible]="1" ></app-cmd>
    </div>
  </div>

  <div style="position: fixed; top: 60px; left: 0; z-index: 1022;" *ngIf="dataGroupItemInstance.edit.type === 'form' && edit.mode">
    <app-popup-form
      [widgetId]="widgetId"
      [dataGroupItemInstance]="dataGroupItemInstance"
      [edit]="edit"
      [rowIndex]="index"
      (onCloseWindow)="close($event)"
      [window]="{
        nav: true,
        add: true,
        modal: true,
        closeOnOutsideClick: false}"
      (onAddRow)="addRow($event)"
      [navList]="['primary']">
    </app-popup-form>
  </div>
</ng-container>


