import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Track } from '../widgets';
import { DomainConfigService } from './domain-config.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private analyticsId;

  constructor(private router: Router, private domainConfigServer: DomainConfigService, private userService: UserService) { }

  public startAnalytics() {

    const subscription = this.userService.userSubject.subscribe((user) => {

      // check if user is defined. Then load domain data.
      if(user && user.contactId) {
        this.domainConfigServer.getDomainConfig().then((domainConfig) => {

          // set google analytics id
          this.analyticsId = domainConfig?.googleAnalytics;

          // if google analytics id is not set then return.
          if(!this.analyticsId) {
            console.log('failed to get analytics ID. It might not be configured right.');
            return;
          }

          // start tracking page navigation.
          this.trackPageNavs();

        });

        subscription.unsubscribe();
      }
    });
  }

  private trackPageNavs() {
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      if(window.document.domain !== 'localhost')
        // @ts-ignore
        gtag('config', this.analyticsId, {
          'page_path': event.urlAfterRedirects,
          'page_location': event.urlAfterRedirects
        });
    });

    console.log(`started tracking page navigation with id ${this.analyticsId} with google analytics`);
  }

  /**
   * Names of events, actions, category's and labels need to be in snakecase for analytics.
   *
   * @param track {
   *  eventName name of the event
   *  eventCategory category of the event
   *  eventLabel label of the event
   *  eventAction action of the event
   *  eventValue value of the event
   * }
   */
  public sendAnalyticsEvent(track: Track) {
    if(this.analyticsId)
      if (track?.eventName) {
        // @ts-ignore
        gtag('event', track.eventName, {
          event_category: track.eventCategory,
          event_label: track.eventLabel,
          event_action: track.eventAction,
          event_value: track.eventValue
        });
      }
  }
}
