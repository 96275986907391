import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checked'
})
export class CheckedPipe implements PipeTransform {

  transform(data: any[], ...args: unknown[]): unknown {
    return data && data.some((item: any) => item.checked);
  }

}
