import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-data-img',
  templateUrl: './data-img.component.html',
  styleUrls: ['./data-img.component.css']
})
export class DataImgComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public img = {
    key: 'imgUrl',
    fileName: 'fileName',
    extension: 'extension',
    path: 'path'
  }

  showAllThumbs: boolean = false;
  showViewer: boolean = false;
  maxThumbs: number = 8;
  thumbWidth: number = 60;

  containerWidth = {
    current: 0,
    nextMax: 0,
    min: 0,
    maxThumbs: 0
  }

  @ViewChild('imgcontainer', { static: false }) container: ElementRef;
  @ViewChildren('imgslide', { read: ElementRef }) slides: QueryList<ElementRef>;

  @HostListener('window:resize')
  onResize() {
    if (this.index === -1) {
      this.index = 0;
    }

    if (this.container) {
      this.containerWidth.current = this.container.nativeElement.offsetWidth;
      this.containerWidth.maxThumbs = Math.floor(this.containerWidth.current / this.thumbWidth);
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getMaxThumbs(): number {
    if (this.container) {
      if (this.showAllThumbs) {
        return this.dataGroupItem.dataTable[0].data.length;
      } else {
        return (this.dataGroupItem.dataTable[0].data.length < this.containerWidth.maxThumbs) ? this.dataGroupItem.dataTable[0].data.length : (this.dataGroupItem.dataTable[0].data.length > this.containerWidth.maxThumbs) ? this.containerWidth.maxThumbs - 1 : this.containerWidth.maxThumbs;
      }
    }
  }

  onPreviousClick() {
    if (this.index > 0) {
      this.index--;
      this.animate(this.index)
    }
  }

  onNextClick() {
    if (this.index === -1) {
      this.index = 0;
    }

    if (this.index < this.getMaxThumbs()) {
      this.index++;
      this.animate(this.index)
    }
  }

  animate(index) {
    this.index = index;
    this.slides.toArray()[index].nativeElement.scrollIntoView({
      behavior: 'smooth',
      inline: 'nearest',
      block: 'nearest'
    });
  }

  showImgViewer(params: Param[], dataItem, data?) {
    this.index = params[0].val;
    this.showViewer = true;
  }

  closeImgViewer(event) {
    this.showViewer = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onNav(item) {
    this.googleAnalyticsService.sendAnalyticsEvent({
      eventName: 'download',
      eventAction: 'download-file',
      eventValue: JSON.stringify(item)
    });
  }
}
