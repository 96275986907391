import { Component, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }]
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() key: string;
  @Input() formControlName: string;
  @Input() id: string;
  @Input() data: any;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() valuePrimitive:boolean = false;
  @Input() type: string | 'single' | 'multi';
  @Input() disabled = false;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() filterChange: EventEmitter<any> = new EventEmitter();

  multiSelection: Array<NgbDateStruct> = [];
  value: Array<Date> = [];

  onChange: (data: Array<Date>) => void;
  onTouched: () => void;

  // if date selector is showed
  calendarDropDown: boolean = false;
  isDisabled: boolean = false;

  isMultiSelectKeyDown: boolean = false;
  isShiftKeyDown: boolean = false;

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if(
      (event.code === 'ControlLeft' && !navigator.userAgent.includes('Mac')) ||
      (event.code === 'MetaRight' && navigator.userAgent.includes('Mac'))
    ) {
      this.isMultiSelectKeyDown = true;
    }

    if(event.code === 'ShiftLeft') {
      this.isShiftKeyDown = true;
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if(
      (event.code === 'ControlLeft' && !navigator.userAgent.includes('Mac')) ||
      (event.code === 'MetaRight' && navigator.userAgent.includes('Mac'))
    ) {
      this.isMultiSelectKeyDown = false;
    }

    if(event.code === 'ShiftLeft') {
      this.isShiftKeyDown = false;
    }
  }


  /**
   * this method adds date to selection
   *
   * @param date date to add to the list of selected dates
   */
  selectDate(date: NgbDateStruct) {
    if(this.isMultiSelectKeyDown && this.type == 'multi') {

      const isDuplicate: boolean = this.multiSelection.find(selection => selection.day ===  date.day && selection.month === date.month && selection.year === date.year) ? true : false;

      if(!isDuplicate) {
        this.multiSelection.push(date);
      } else {
        this.multiSelection = this.multiSelection.filter(selection => selection.day !== date.day || selection.month !== date.month || selection.year !== date.year);
      }

    } else if(this.isShiftKeyDown && this.type == 'multi') {

      if(this.multiSelection.length === 0)
        return;

      // Last selection is the latest date selected
      const lastSelection = this.multiSelection[this.multiSelection.length - 1];

      // Define start and end date in bootstrap date objects
      const startDate = lastSelection;
      const endDate = date;

      // Define date objects
      // JS date objects are 0 based, so we need to subtract 1 from the month
      const startDateDate = new Date(startDate.year, startDate.month - 1, startDate.day);
      const endDateDate = new Date(endDate.year, endDate.month - 1, endDate.day);

      // make sure startDate is before endDate
      let dt = startDateDate < endDateDate ? startDateDate : endDateDate;
      let endDt = startDateDate < endDateDate ? endDateDate : startDateDate;

      // This use of 'var' is intentional. 'let' will breack this code.
      // Make array of dates between startDate and endDate
      for(var dateRange=[]; dt<=endDt; dt.setDate(dt.getDate()+1)){
        dateRange.push(new Date(dt));
      }

      // Convert date array to NgbDateStruct array
      const dateRangeObj: NgbDateStruct[] = dateRange.map(dateObj => {

        return {
          year: dateObj.year || dateObj.getFullYear(),
          month: dateObj.month || dateObj.getMonth() + 1,
          day: dateObj.day || dateObj.getDate()
        }
      })

      const totalSelection = [ ...this.multiSelection, ...dateRangeObj ];

      // Remove duplicates from array
      let duplicateIndexes = [];

      for(let i = 0; i < totalSelection.length; i++) {
        for(var j = i + 1; j < totalSelection.length; j++) {
          if(totalSelection[i].day === totalSelection[j].day && totalSelection[i].month === totalSelection[j].month && totalSelection[i].year === totalSelection[j].year) {
            duplicateIndexes.push(j);
          }
        }
      }

      for(let i = duplicateIndexes.length - 1; i >= 0; i--) {
        totalSelection.splice(duplicateIndexes[i], 1);
      }

      this.multiSelection = totalSelection;

    } else {

      // Normal click removes other selections and only selects the clicked object
      this.multiSelection = [date];

    }

    this.writeValue(this.multiSelection);
    this.onChange(this.value);
  }

  /**
   * this method checks if date is selected
   *
   * @param selection current date
   * @returns boolean true if date is selected
   */
  isInSelection(selection: NgbDateStruct): boolean {
    return this.multiSelection.find(
      multiSelection =>
        selection.day === multiSelection.day &&
        selection.month === multiSelection.month &&
        selection.year === multiSelection.year
      ) ? true : false;
  }

  /**
   * this method returns a string of all the multiSelection dates
   */
  getSelectionString(): string {
    return this.multiSelection.map(date => date.day + '-' + date.month + '-' + date.year).join(', ');
  }

  /**
   * this method handles the outside clicks to close the datepicker automatically
   *
   * @param event click event object
   */
  handleClickOutside(event) {
    if (this.calendarDropDown) {
      this.calendarDropDown = false;
      this.blur.emit();
    }
  }

  //
  // implement ControlValueAccessor interface
  //

  writeValue(obj: any): void {
    // Als writeValue een null krijgt doen we niks.
    if(obj == null) {
      this.multiSelection = [];
      this.value = [];
      return;
    }

    if (!Array.isArray(obj)) {
      obj = [obj];
    }

    // Convert date array to NgbDateStruct array
    const dateRangeObj: NgbDateStruct[] = obj.map(dateObj => {
      return {
        year: dateObj.year || new Date(dateObj).getFullYear(),
        month: dateObj.month || new Date(dateObj).getMonth() + 1,
        day: dateObj.day || new Date(dateObj).getDate()
      }
    });


    this.multiSelection = obj ? dateRangeObj : [];
    this.value = obj ? obj.map(ngDate => new Date(Date.UTC(ngDate.year, ngDate.month - 1, ngDate.day))) : [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
