<div [ngClass]="['xfw3-card', className || '',
    dataGroupItemInstance.card.download ? 'xfw3-hover' : '',
    item.state?.linked ? 'linked' : '',
    item.state?.dependent ? 'dependent' : '',
    item.state?.excluded ? 'excluded' : '',
    item.isSelected || cardSelected ? 'selected' : '',
    item.highLight ? 'highlight' : '',
    ['none', 'single-edit'].includes(uploadType) && dataGroupItemInstance.selectionMode !== 'none' ? 'xfw3-hover' : '']"

     (click)="select([{ key: 'index', val: index}], item, null, $event)">
  @if (dataGroupItemInstance.card?.before) {
    <div class="before-area">
      <span title="{{ item[dataGroupItemInstance.card.before.field] }}">{{ item[dataGroupItemInstance.card.before.field] }}</span>
    </div>
  }

  <div class="template-area"></div>

  @if (dataGroupItemInstance.card?.header) {
    <div class="check-mark-area">
      <div class="check-mark">
        <label (click)="dataService.itemCheckboxChange($event, item.trackBy, item, dataGroupItemInstance); $event.stopPropagation();">
          <input [checked]="item.checked" type="checkbox" [ngClass]="dataGroupItemInstance.rowOptions.checkable ? 'checkable' : ''">
          <span class="check-mark-input"></span>
        </label>
      </div>
    </div>
  }

  @if (dataGroupItemInstance.card?.header?.title) {
    <div class="title-area">
      <span title="{{ item[dataGroupItemInstance.card.header.title.field] }}">{{ item[dataGroupItemInstance.card.header.title.field] }}</span>
    </div>
  }

  <div class="subtitle-area">
    @if (dataGroupItemInstance.card?.header?.subtitle?.field) {
      <span title="{{ item[dataGroupItemInstance.card.header.subtitle.field] }}">{{ item[dataGroupItemInstance.card.header.subtitle.field] }}
      </span>
    }
  </div>

  <div class="task-area" >
    @if (dataGroupItemInstance.card?.header?.task?.field) {
      <div class="state task">
        {{ item[dataGroupItemInstance.card.header.task.field] }}
      </div>
    }
  </div>
  <div class="header-nav-area" >
    @if (dataGroupItemInstance.card?.header?.nav) {
      <app-cmd [nav]="dataGroupItemInstance.card.header.nav"
      [navList]="['menu']"
      [navVisible]="0"
      [component]="component"
      [dataItem]="item"
      [dropdownClassName]="'icon xxs more'"
      [className]="dataGroupItemInstance.card.header.nav.className ?? ''"
      [collapsed]="false"/>
    }
  </div>

  @if ((dataGroupItemInstance.card.img || ['single-edit', 'none'].includes(uploadType)) && uploadType !== 'no-img') {
    <div class="img-area">
      @if (['none', 'single-edit'].includes(uploadType) && item.imgAndThumbs) {
        <div class="img-nav">
          <div class="cmd left" (click)="previousImage()" [hidden]="imgIndex === 0">
            <span class="fa-solid fa-fw fa-sharp fa-chevron-left"></span>
          </div>
          <div class="cmd right" (click)="nextImage()" [hidden]="imgIndex === item.imgAndThumbs.length - 1">
            <span class="fa-solid fa-fw fa-sharp fa-chevron-right"></span>
          </div>

          @if (dataGroupItemInstance.card.img.nav?.primary) {
            <div class="cmd ok">
              <span class="fa-solid fa-fw fa-sharp fa-check"></span>
            </div>
          }
          @if (dataGroupItemInstance.card.img.nav?.secondary) {
            <div class="cmd nOk">
              <span class="fa-solid fa-fw fa-sharp fa-xmark"></span>
            </div>
          }
          @if (dataGroupItemInstance.card.img.nav?.custom) {
            <div class="img-state">
              <span class="fa-solid fa-fw fa-sharp fa-exclamation"></span>
            </div>
          }
        </div>
      }


      @if(['single','multiple'].includes(uploadType)) {
        <xfw-uploader [fileJSON]="{
          schema: dataGroupItemInstance.card.upload.schema,
          imgPath: dataGroupItemInstance.card.upload.imgPath,
          thumbPath: dataGroupItemInstance.card.upload.thumbPath,
          path: dataGroupItemInstance.card.upload.path,
          type: dataGroupItemInstance.card.upload.type,
          fileUid: dataGroupItemInstance.card.upload.fileUid.params[0].val,
          prefix: item.prefix,
          data: item
        }"
        [widgetId]="dataGroupItemInstance.widgetId"
        [size]="'form-large'"
        [extensions]="dataGroupItemInstance.card.upload.extensions"></xfw-uploader>
      }@else if(uploadType !== 'single-edit') {
        @if (dataGroupItemInstance.card.img?.video) {
          <video [src]="item[dataGroupItemInstance.card.img.video.field]" [class]="dataGroupItemInstance.card.img.className" controls></video>
        }@else if ((item[dataGroupItemInstance.card.img.src?.field] || (dataGroupItemInstance.card.img.hasFileJSON || item.fileJSON)) || dataGroupItemInstance.card.img.hasFileJSON) {
          <img [src]="!['none', 'single-edit'].includes(uploadType) ? '../../assets/icons/upload.svg' :
                (dataGroupItemInstance.card.img.hasFileJSON || item.fileJSON) ? item.fileJSON?.imgAndThumbs[imgIndex]?.imgUrl :
                item.imgAndThumbs ? item.imgAndThumbs[imgIndex]?.imgUrl :
                (dataGroupItemInstance.card.img.path?.field ? item[dataGroupItemInstance.card.img.path?.field] : '') +
                (item[dataGroupItemInstance.card.img.src?.field]) +
                (dataGroupItemInstance.card.img.extension?.field ? '.' + item[dataGroupItemInstance.card.img.extension?.field] : '')"
          [@slideInOut]="slideDirection"
          onerror="this.src = '../../assets/icons/noimg.svg'"/>
        }
      }@else if (item.fileJSON) {
        <app-image-uploader
          [widgetId]="dataGroupItemInstance.widgetId + '_upload-' + index"
          [fileJSON]="item.fileJSON"
          [size]="'form-large'"
          [extensions]="[item.fileJSON.extension]"
          [style.height.rem]="13"
          [style.width.%]="100"/>
      }
    </div>
  }

  @if (dataGroupItemInstance.card.body && ['no-img', 'none', 'single-edit'].includes(uploadType)) {
    <div [ngClass]="['body-area xfw3-grid-container', uploadType]" divBreakpointClass>
      <ng-container *ngTemplateOutlet="body; context: { item: item, cbody: dataGroupItemInstance.card.body }"></ng-container>
    </div>
  }

  @if (dataGroupItemInstance.card.footer?.icons?.field) {
    <div class="indicators-area">
      <div class="icons">
        @for (icon of item[dataGroupItemInstance.card.footer?.icons?.field]; track $index) {
          @if (icon.iconUrl) {
            <img [src]="icon.iconUrl" [title]="icon.title ?? ''"/>
          }@else {
            <span [title]="icon.title ?? ''" [ngClass]="[icon.icon ?? '']" ></span>
          }
        }
      </div>
    </div>
  }

  <div class="status-area">
    @if (dataGroupItemInstance.card.footer?.state) {
      <div [ngClass]="['state', 'ok']">
        <span>{{ item[dataGroupItemInstance.card.footer.state.field]?.content?.text }}</span>
      </div>
    }
  </div>

  @if (dataGroupItemInstance.card.footer?.nav) {
    <div class="footer-nav-area">
      @if (dataGroupItemInstance.card.footer.nav.field) {
        <app-cmd
          [nav]="item[dataGroupItemInstance.card.footer.nav.field]"
          [navList]="['custom']"
          [component]="component"
          [dataItem]="item"
          [navVisible]="1"/>

      }@else if (!dataGroupItemInstance.card.footer.nav.field) {
        <app-cmd
          [nav]="dataGroupItemInstance.card.footer.nav"
          [navList]="['primary', 'secondary', 'menu']"
          [component]="component"
          [dataItem]="item"
          [navVisible]="dataGroupItemInstance.card.footer.navItems ?? 2"/>
      }
    </div>
  }

  @if (dataGroupItemInstance.card.after) {
    <div class="after-area">
      <span title="{{ item[dataGroupItemInstance.card.after.field] }}">{{ item[dataGroupItemInstance.card.after.field] }}</span>
    </div>
  }
</div>

<ng-template #body let-item="item" let-cbody="cbody" >
  @if (cbody) {
    <div [ngClass]="['xfw-card-body']">
      @if (cbody.contentJSON) {
        <app-content-block
          [blocks]="item[cbody.contentJSON.field]"
          [environmentJSON]="{ viewportMode: false }"/>
      }
      @if (!cbody.editMode) {
        @for (field of cbody.fields; track $index) {
          @if (item | condField : field.condFields) {
            @if (!field.type) {
              <div [ngClass]="['field', field.label.text ? '' : 'one-column', field.label.className ?? '']">
                @if (field.label.text) {
                  <div class="xfw3-label">{{ field.label.text }}</div>
                }
                <div class="xfw3-value" [class]="field.className ?? ''" [title]="item[field.field]?.text ? item[field.field].text : item[field.field]">
                  @if (field.templateOptions?.format?.pipeToken) {
                    <span [innerHTML]="item[field.field] | dynamicPipe : field.templateOptions.format.pipeToken : field.templateOptions.format.pipeArgs | safeHtml">
                    </span>
                  }@else {
                    <span>
                      {{ item[field.field]?.text ? item[field.field].text : item[field.field] }}
                    </span>
                  }
                </div>
              </div>
            }@else if (['number', 'input'].includes(field.type))  {
              <div [ngClass]="['field', field.label.className ?? '', cbody.className ?? '']">
                <div class="xfw3-label">{{ field.label.text }}</div>
                <div class="xfw3-form-group"><input class="xfw3-form-control" (click)="$event.stopPropagation()" [(ngModel)]="item[field.field]" [type]="field.type"></div>
              </div>
            }@else if (['checkbox'].includes(field.type)) {
              <div [ngClass]="['field', field.label.className ?? '', cbody.className ?? '']">
                <div class="xfw3-form-group">
                  <div class="xfw3-label">{{ field.label.text }}</div>
                  <div class="check-mark">
                    <label [for]="field.field + '-' + index">
                      <input (click)="$event.stopPropagation()" [(ngModel)]="item[field.field]" [type]="field.type" [id]="field.field + '-' + index">
                    </label>
                  </div>
                </div>
              </div>
            }
          }
        }
      }

      @if (cbody.editMode) {
        <app-form #appForm
          [widgetId]="dataGroupItemInstance.widgetId + '_form-' + index"
          [focus]="focus"
          [dataGroupItemInstance]="dataGroupItemInstance"
          [rowIndex]="index"
          [formClass]="cbody.className"
          [navList]="['primary']"/>
      }

    </div>
  }
</ng-template>
