import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action, ActionGroup, DataGroup } from 'src/app/data';
import { CommunicationService } from 'src/app/services/communication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-action-group-item',
  templateUrl: './action-group-item.component.html',
  styleUrls: ['./action-group-item.component.css']
})
export class ActionGroupItemComponent implements OnInit {
  @Input() widgetId: string;
  @Input() dataGroup: DataGroup[];
  @Input() dataGroupItem: DataGroup;
  @Input() layout: any;

  @Output() eventSelect: EventEmitter<any> = new EventEmitter();

  public sectionState: SectionState = {
    read: 0,
    unread: 0,
    urgent: 0
  }

  private selectItem = {
    show: false,
    item: null,
    index: null
  };

  public isItemSelected: boolean = false;

  constructor(
    private communicationService: CommunicationService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  onHasData(event) {
    this.dataGroupItem.isEmpty = (event.dataLength === 0);
    this.layout.gridTemplateRows = this.dataGroup.filter(dataGroupItem => !dataGroupItem.isEmpty).map(dataGroupItem => dataGroupItem.selected ? '1fr' : 'min-content').join(' ');
    this.checkStatus();
  }

  onSelect(event) {
    this.eventSelect.emit(event);

    this.checkStatus();
  }

  checkStatus() {
    this.sectionState.read = 0;
    this.sectionState.unread = 0;
    this.sectionState.urgent = 0;

    this.dataGroupItem.dataTable[0].data.forEach(item => {
      if (this.communicationService.user.config.actionCenter.unread?.indexOf(item['objectId']) === -1) {
        this.sectionState.read++;
      } else {
        this.sectionState.unread++;
        if (this.communicationService.user.config.actionCenter.urgent?.indexOf(item['objectId']) > 0) {
          this.sectionState.urgent++;
        }
      }
    });
  }
}

interface SectionState {
  read: number,
  unread: number,
  urgent: number
}
