import { Component, OnInit, Input, Output, EventEmitter, forwardRef, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OptionListComponent),
      multi: true
    }
  ]
})
export class OptionListComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() type: string;
  @Input() id: string;
  @Input() data: any;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() disabled = false;

  @Output() blur: EventEmitter<any> = new EventEmitter();

  onTouched = () => {};
  touched = false;

  public _value;
  public radioValue;

  get value() {
    return this._value;
  }

  set value(val) {
    switch(this.type) {
      case 'checkbox':
        if (val) {
          let bitmap = this.utilsService.getBitmapFromArray(this.data.filter(item => item.checked), this.valueField)
          this._value = {
            value: bitmap,
            data: this.data.filter(item => item.checked)
          };
        }
        break;
      case 'radio':
        if (val) {
          this._value = val;
        }
      }

      this.onChange(this._value);

  }

  constructor(
    public utilsService: UtilsService) { }

  ngOnInit(): void {
    this.data.forEach(row => {
      row[this.valueField] = row[this.valueField].toString();
    });
  }

  ngOnChanges(): void {
    if (this.data) {
      this.data.forEach((item, index) => {
        item._bitmap = Math.pow(2, index);
        item.checked = false;
      });
    }
  }

  /**
   * set Initial value, form new FormControl
   *
   * @param value => initial value
   */
  writeValue(value: any) {
    if (value) {
      if (!Array.isArray(value) && typeof value !== 'object') {
        const bitmapArray = [];
        for (let i = 0; i <= 54; i++ ) {
          if ((parseInt(value) & Math.pow(2, i)) === Math.pow(2, i)) {
            bitmapArray.push(
              {
                [this.valueField]: Math.pow(2, i)
              });
          }
          if (Math.pow(2, i) > parseInt(value)) {
            break;
          }
        }
        value = bitmapArray;
      } else {
        value = value;
      }

      if (value[0]) {
        switch(this.type) {
          case 'radio':
            console.log(value[0], this.valueField);

            this.radioValue = value[0][this.valueField].toString();
            this.data.find(item => item[this.valueField].toString() === value[0][this.valueField].toString()).checked = true;
            break;
          case 'checkbox':
            value.forEach(val => {
              this.data.filter(item => item[this.valueField].toString() === val[this.valueField].toString()).forEach(item => item.checked = true);
            })
            break;
        }
      }
    }
  }

  onChange = (_: any) => {};

  registerOnChange(fn: (_: any) => void) {
    this.onChange = (value: any) => {
      fn(value);
    }
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setRadio() {
    this.value = this.data.filter(item => item[this.valueField] === this.radioValue);
    this.blur.emit();
  }

  setCheckbox() {
    this.value = this.data.filter(item => item.checked);
    this.blur.emit();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  trackByFn(index, item) {
    return index;
  }
}
