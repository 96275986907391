import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataGroupMatrix } from 'src/app/data';
import { Param } from 'src/app/widgets';
import { Subscription } from 'rxjs';
import { NetworkService } from 'src/app/services/network.service';
import { DataService } from 'src/app/services/data.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-data-group-matrix',
  templateUrl: './data-group-matrix.component.html',
  styleUrls: ['./data-group-matrix.component.css']
})
export class DataGroupMatrixComponent implements OnInit {
  @Input() container: string;
  @Input() src: string;
  @Input() queryParams: Param[];
  @Input() dataGroupMatrix: DataGroupMatrix[];

  public detailView = false;

  public loaded = false;

  public item: any;

  public dataGroupSrcParam: any[] = [];

  public subscription = new Subscription();

  constructor(
    private dataService: DataService,
    private utilsService: UtilsService,
    private networkService: NetworkService ) { }

  ngOnInit(): void {
    if (!this.dataGroupMatrix) {
      this.loadDataGroupMatrix().then(() => {
        this.loaded = true;
      });
    } else {
      this.setInitialParams(this.queryParams, this.dataGroupMatrix);
      this.loadDataTables().then(() => {
        this.loaded = true;
      });
    }
  }

  setInitialParams(queryParams: Param[], dataGroup: any[]) {
    dataGroup.forEach(item => {
      this.dataGroupSrcParam.push({
        src: item.src,
        params: queryParams
      });

      if (item.children) {
        this.setInitialParams(queryParams, item.children);
      }
    });

    return;
  }

  loadDataGroupMatrix() {
    return new Promise((resolve, reject) => {
      this.dataGroupMatrix = [];

      this.subscription.add(this.networkService.query(
        [
          {
            src: 'GetDataGroup',
            params: [{ key: 'DataGroup', val: this.src }]
          }
        ], 'json')
        .subscribe(data => {
          if (data.length && data[0].data[0].DataGroupJSON) {
            this.dataGroupMatrix = JSON.parse(data[0].data[0].DataGroupJSON);
            this.setInitialParams(this.queryParams, this.dataGroupMatrix);

            this.loadDataTables().then(() => {
              resolve(null);
            });
          }
        }));
    });
  }

  loadDataTables() {
    return new Promise((resolve, reject) => {

      let startTiming = new Date().getTime();

      this.subscription.add(this.networkService.query(this.dataGroupSrcParam, 'json')
        .subscribe(dataTable => {

          const setDataGroupMatrixData = (dataGroupMatrixItem) => {
            dataGroupMatrixItem.forEach(item => {
              item.data = dataTable[0].find(x => x.src === item.src).data;
              item.dataCondResult = dataTable[0].find(x => x.src === item.src).dataCondResult;
              item.cache = dataTable[0].find(x => x.src === item.src).cache;
              item.originalData = [...dataTable[0].find(x => x.src === item.src).data];
              item.fields = dataTable[0].find(x => x.src === item.src).fields;
              item.itemCondFields = dataTable[0].find(x => x.src === item.src).itemCondFields;
              if (item.children) {
                setDataGroupMatrixData(item.children);
              }
            });

            return;
          }

          setDataGroupMatrixData(this.dataGroupMatrix);

          this.item = this.utilsService.objectFromParams(this.queryParams);
          resolve(null);
        })
      );
    });
  }

  onSelected(event) {
    this.detailView = event.isSelected;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  trackByFn(index, item) {
    return item.trackBy;
  }

  log(origin: string, level: number, contentJSON: any) {
    this.dataService.setLog(origin, level, contentJSON);
  }
}
