import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Observable, catchError, firstValueFrom, forkJoin, map, of } from 'rxjs';
import { ConfiguratorOption, ConfiguratorProduct, ConfiguratorRow } from '../configurator';

export namespace PrintCom {
  export interface ConfiguratorProduct {
    sku: string;
    active: boolean;
    titleSingle: string;
    titlePlural: string;
    createdAt: Date;
    updatedAt: Date;
    properties: Property[];
    maxDesigns: number;
    bleed: number;
    hsCode: string;
    isAvailableInUserRegion: boolean;
    hasAccessories: boolean;
    accessoryExcludes: any[];
    excludes: Array<Exclude[]>;
  }

  export interface Exclude {
    options: string[];
    property: string;
  }

  export interface Property {
    slug: string;
    title: string;
    locked: boolean;
    options: Option[];
  }

  export interface Option {
    slug: number | string;
    name?: string;
    nullable: boolean;
    eco?: boolean;
    customSizes?: CustomSizes;
    type?: string;
    description?: string;
  }

  export interface CustomSizes {
    minHeight: number;
    sizeUnit: string;
    minWidth: number;
    maxHeight: number;
    dimensionalAccuracy: string;
    maxWidth: number;
  }

  export interface PriceCalculationRequest {
    deliveryPromise: 0 | 1 | 2,
    options: Options,
    printCoupon?: string,
    variants?: (PriceCalculationRequest[ 'options' ])[];
  }

  export interface PriceCalculationResponse {
    prices: Prices;
    includes: Includes;
    options: Options;
    supplierId: string;
  }

  export interface Includes {
    numberOfSheets: number;
    sheetHeight: number;
    sheetWidth: number;
    packaging_extras: string;
    printing_side: string;
  }

  export type Options = Record<string, string | number>;

  export interface Prices {
    urgencyPrice: number;
    productPrice: number;
    agreementPriceType: string;
    normalPrice: number;
    salesPrice: number;
    discountPercentage: number;
    deliveryPromisePrice: number;
    deliveryPromisePremiumPrice: number;
  }

}

const ingoredRows = [ 'summary_image', 'copies', 'urgency', 'packaging_extras' ];

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorPrintcomService {

  constructor (
    private net: NetworkService
  ) { }

  async getProduct(code: string): Promise<ConfiguratorProduct> {
    const product = await firstValueFrom(this.net.getPrintComProduct(code)) as PrintCom.ConfiguratorProduct;

    return this.convertPrintComProduct(product);
  }

  public convertPrintComProduct(product: PrintCom.ConfiguratorProduct): ConfiguratorProduct {
    const properties = product.properties;
    const propertySlugs = new Map(properties.map((v, i) => [ v.slug, i ]));

    // Sort by property
    const allExcludes = product.excludes
      .map(v => v.sort((a, b) =>
        propertySlugs.get(a.property)! - propertySlugs.get(b.property)!
      ))
      .map(v => v.map(v => ({ property: v.property, options: v.options.map(option => `${ v.property }.${ option }`) })));

    // Make all the rows with their options
    const xfwRows = properties
      .filter(v => !ingoredRows.includes(v.slug))
      .map<ConfiguratorRow>((v, idx) => ({
        code: v.slug.toString(),
        block: { name: v.title },
        conditionalCodesExt: [],
        options: v.options.map<ConfiguratorOption>(o => ({
          block: { name: o.name ?? o.slug.toString(), description: o.description! },
          code: `${ v.slug.toString() }.${ o.slug.toString() }`,
          conditionalCodes: [],
          conditionalCodesExt: [ { codes: [], itemIndex: idx } ],
          imageUrl: '',
          itemIndex: idx,
          sortOrder: 0,
          state: { hidden: false },
          suppliers: [ 532 ],
          type: 'radio',
          values: { value: 0 },
          unit: ''
        })),
        settings: [],
        state: { hidden: true },
        type: '',
        imgAspectRatio: "no-img"
      }));


    return {
      rows: xfwRows,
      mode: 'rows',
      code: product.sku,
      block: { title: product.titleSingle },
      configuratorRows: [],
      excludes: allExcludes
    } as any;
  }

  /** Gets the price(s) of a product configuration from the PrintCom API. */
  public getPrices(supplierId: number, configurationJSON: any, nasData: any[], compId: number) {

    const requests: Observable<PrintCom.PriceCalculationResponse>[] = [];
    console.log(configurationJSON, nasData);
    for (const nasDataSet of nasData) {
      const products = configurationJSON.products; //TODO something with nas data sets?

      for (const product of products) {
        let sku = product.code.split('.');
        sku = sku[sku.length - 1];

        requests.push(this.net.getPrintComPrice(sku, {
          deliveryPromise: 0,
          options: {
            ...Object.fromEntries(product.options.map(opt => (opt.code as string).split('.'))),
            ...nasDataSet,
            copies: nasDataSet.amount, //HACK
            amount: undefined
          }
        }));
      }
    }

    return forkJoin(requests).pipe(
      catchError(error => {
        console.error('PrintCom Price Request Error: ', error);

        // Return an error message as an Observable
        return of('PrintCom Price Request Error');
      }),
      map(data => {
        if (typeof data === 'string') return;

        let convertedPrices = new Array(data.length || 1)
          .fill(null);

        for (let idx = 0; idx < data.length; idx++) {
          const priceSet = data[ idx ];
          const { normalPrice, salesPrice } = priceSet.prices;

          convertedPrices.push({
            crud: 'merge',
            amount: configurationJSON.priceTiers[ idx ].amount,
            discount: normalPrice - salesPrice,
            price: salesPrice,
            purchasePrice: salesPrice,
            originalPrice: normalPrice,
            supplierId,
            compId
          });
        }

        return convertedPrices;
      })
    );
  }

  public;
}
