<div class="component">
  <div *ngFor="let component of data">
    <div class="title-area">
      <div class="title">{{ component.component ? component.component + ' - ' : '' }} {{ component.title }}</div>
    </div>
    <div class="description-area">
      {{ component.info }}
    </div>
    <div class="price-area">
      <div class="price-info-large">{{ component.priceJSON.price | number : '1.2-2'}}</div>
      <div class="price-info-small">{{ component.priceJSON.originalPrice | number : '1.2-2' }}</div>
    </div>

    <div [ngClass]="['nas-area']">
      <app-report-nas *ngIf="component.nas" [data]="component.nas"/>
    </div>
    <div [class]="['price-tier-area']">
      <app-report-price-tier *ngIf="component.priceTier" [data]="component.priceTier"/>
    </div>
  </div>
</div>
