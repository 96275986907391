import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataGroup, DataTable } from 'src/app/data';
import { ContentService } from 'src/app/services/content.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { DataService } from 'src/app/services/data.service';
import { NetworkService } from 'src/app/services/network.service';
import { Nav, Param, WidgetEvent, WidgetState } from 'src/app/widgets';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-block',
  templateUrl: './edit-block.component.html',
  styleUrls: ['./edit-block.component.scss']
})
export class EditBlockComponent implements OnInit, OnDestroy {
  @Input() params: Param[];
  @Input() rowIndex: number;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public dataGroup: DataGroup[] = [];
  public filterGroup: DataGroup[];
  public nav: Nav;
  public widgetId = 'editBlock';
  public src = 'rawTranslateContent';
  public loaded = false;

  public blocks: any[] = [];

  public editData: any[] = [];
  public editViewData: any[] = [];

  public component = this;

  public languages: string[] = [];

  constructor(
    private contentService: ContentService,
    private utilsService: UtilsService,
    private communicationService: CommunicationService,
    private dataService: DataService,
    private networkService: NetworkService
  ) { }

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([this.widgetId]);
  }

  ngOnInit(): void {
    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: ['filterLanguage'],
          event: WidgetEvent.REFRESHED,
          func: 'languagesChanged'
        }
      ]
    });

    this.nav = {
      navId: -30,
      menu: [
        {
          icon: 'fa-regular fa-paper-plane',
          text: 'Opslaan',
          type: 'submit',
          className: "icon-text xfw3-bg-primary sm rounded-corners",
          info: '',
          params: [],
          disabled: false,
          func: 'updateMutations'
          // widgetAction: [
          //   {
          //     widgetGroup: [this.widgetId],
          //     event: WidgetEvent.SAVE
          //   }
          // ]
        }
      ]

    };

    this.getRawContent(this.src, this.params, this.editData, this.dataGroup);

    setTimeout(() => {
      this.loaded = true;
      this.editViewData = this.editData.filter(row => row.content.length > 0);
      this.translateContent();

      console.log(this.editData);
    }, 800);
  }

  getRawContent(src, params: Param[], result: any[], dataGroup: DataGroup[]) {
    const processDataGroup = (dataGroupItem: DataGroup, params: Param[], processedResult: any[]) => {
      if (params.some(param => param.val && param.key === dataGroupItem.params[0].key)) {
        dataGroupItem.params[0].val = params.find(param => param.key === dataGroupItem.params[0].key).val;

        const subscription: Subscription = this.dataService.getDataTable(dataGroupItem, dataGroupItem.db, dataGroupItem.src[0].name, dataGroupItem.params).subscribe(dataTable => {
          subscription.unsubscribe();

          this.dataService.processData({
            data: dataGroupItem.dataTable[0].data,
            fields: dataGroupItem.dataTable[0].fields,
            parse: true
          });

          dataGroupItem.dataTables.push(dataTable[0]);

          dataGroupItem.dataTable[0].data.forEach(row => {
            const _result: any[] = [];

            this.contentService.getFlatList(row, ['group', 'text', 'title'], ['ml'], _result);
            processedResult.push(..._result);
          });

          if (dataGroupItem.children.length) {
            dataGroupItem.children.forEach(child => {
              let params = [];
              this.contentService.getFlatList(dataTable[0].data, [child.params[0].key], [], params);

              if (!processedResult[processedResult.length - 1].hasOwnProperty('children')) {
                processedResult[processedResult.length - 1]['children'] = [];
              }

              params.forEach(param => {
                param.content.forEach(paramContent => {
                  processDataGroup(child, [
                    {
                      key: child.params[0].key,
                      val: paramContent[child.params[0].key]
                    }
                  ], processedResult[processedResult.length - 1].children);
                });
              });
            });
          }
        });
      }
    };

    this.dataService.loadDataGroup(src, dataGroup).then((_dataGroup: DataGroup[]) => {
      dataGroup.push(..._dataGroup);

      _dataGroup.forEach(dataGroupItem => {
        processDataGroup(dataGroupItem, params, result);
      });
    });
  }

  languagesChanged(params) {
    this.languages = [];

    params.data.forEach(row => {
      row.language.forEach(item => {
        this.languages.push(item);
      });
    });

    if (this.editData.length) {
      this.translateContent();
    }
  }

  checkInput(item: any) {
    console.log(item);
  }

  translateContent() {
    this.languages.forEach((language: any) => {
      this.contentService.addLanguage(this.editData, 'nl', language.id);
    });
  }

  previous(event) {
    this.rowIndex = this.rowIndex > 0 ? this.rowIndex - 1 : this.rowIndex;
  }

  next(event) {
    this.rowIndex = this.rowIndex < this.editViewData.length - 1 ? this.rowIndex + 1 : this.rowIndex;
  }

  close(event?) {
    this.onClose.emit(event);
  }

  updateMutations() {
    const processDataGroup = (dataGroupItem: DataGroup) => {
      if (dataGroupItem.children?.length) {
        dataGroupItem.children.forEach(child => {
          processDataGroup(child);
        });
      }

      if (dataGroupItem.dataTables?.length) {
        dataGroupItem.dataTables.forEach(dataTable => {
          dataGroupItem.dataTable[0].data.forEach(row => {
            row.crud = 'update';
          });

          const mutations = dataGroupItem.dataTable[0].data;

          console.log('We are about to save:', mutations);

          const subscription = this.networkService.crud([{
            db: 'xfw3',
            src: dataGroupItem.dataTable[0].src,
            procedure: dataGroupItem.dataTable[0].procedure,
            mail: dataGroupItem.dataTable[0].mail,
            mutations: mutations
          }], 'json').subscribe(result => {
            console.log(result);

            subscription.unsubscribe();
          });

        });
      }
    }

    this.dataGroup.forEach(dataGroupItem => {
      processDataGroup(dataGroupItem);
    });

  }

  trackByFn(index, item) {
    return index;
  }
}
