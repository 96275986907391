import { Injectable } from "@angular/core";
import { NetworkService } from "./network.service";
import { Observable, Subject, lastValueFrom } from "rxjs";
import { UserService } from "./user.service";
import { Socket } from "ngx-socket-io";
import { CommunicationService } from "./communication.service";
import { WidgetEvent } from "../widgets";
import { XfwLocalService } from "./xfw-local.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private readonly localStorageTokenName = "session";

  public authToken: string = localStorage.getItem(this.localStorageTokenName) || "";
  public tempAuthToken: string = "";

  public sessionObj: any = {};
  private sessionStatus: "noSession" | "anonSession" | "userSession" = "noSession";
  private sessionStatusSubject: Subject<"noSession" | "anonSession" | "userSession"> = new Subject();


  constructor (
    private userService: UserService,
    private http: NetworkService,
    private communicationService: CommunicationService,
    private socket: Socket,
    private xfwLocalService: XfwLocalService
  ) {
    this.sessionStatusSubject.subscribe((sessionStatus) => {
      this.sessionStatus = sessionStatus;
      this.loginSocket();

      // XfwLocalService try connecting
      if (this.sessionStatus === 'userSession')
        setTimeout(() => {
          xfwLocalService.connect().then(() => {
            console.debug('xfw-local connected');
          }).catch((err) => {
            console.error(err);
          });
        }, 250);
    });

    this.removeDeprecatedSessionObjects();

    socket.on("Login", (msg: "success" | "nosession" | "loginFailed") => {
      if (msg === "success") {
        console.log(
          `Socket session is updated to ${ this.sessionStatus } status!`,
          this.sessionObj
        );
        this.communicationService.performAction({
          event: WidgetEvent.USERINFOCHANGED,
          dataItem: this.sessionObj,
        });
      } else if (msg === "nosession") {
        this.loginSocket();
      } else if (msg === "loginFailed") {
        console.log("Failed to establish socket connection.");
      }
    });
  }

  public get getSessionStatus() {
    return this.sessionStatus;
  }

  public get sessionTokenName() {
    return this.localStorageTokenName;
  }

  /**
   * login with anonymous session
   */
  public loginAnon() {
    return new Promise((resolve, reject) => {
      lastValueFrom(this.http.post("/api/OauthV2/loginAnon", { host: window.location.host }, "json"))
        .then((token) => {
          this.tempAuthToken = token;
          this.authToken = "";

          this.getSessionInfo()
            .then(() => {
              this.authToken = token as string;
              this.sessionStatusSubject.next("anonSession");
              resolve(null);
            })
            .catch(() => reject());
        })
        .catch(() => reject());
    });
  }

  /**
   * This function will log the user in with normal email password authentication
   *
   * @param email email of user
   * @param password password of user
   */
  public async loginUser(credentials: loginForm): Promise<void> {
    const token = await lastValueFrom(this.http.post("/api/OauthV2/login", { Email: credentials.email, Password: credentials.password }, "json"));
    this.authToken = token as string;

    return this.getSessionInfo();
  }

  public getSessionInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      lastValueFrom(this.http.get("/api/OauthV2/info", "json"))
        .then((session) => {
          session.language = session.language ?? "nl";

          this.userService.setUser(session);

          if (typeof session.authBitMask === "number") {

            console.log(session);

            // If user does not have a hashword in session, then log out user for a new session that will contain a hashword.
            // if (session.authBitMask !== 1 && !session.hashword)
            //   return reject();

            this.sessionObj = session;

            localStorage.setItem(
              this.localStorageTokenName,
              !this.authToken || this.authToken === ""
                ? this.tempAuthToken
                : this.authToken
            );

            this.sessionStatusSubject.next(session.authBitMask === 1 ? "anonSession" : "userSession");

            resolve(session);
          } else {
            reject();
          }
        })
        .catch(() => reject());
    });
  }

  private loginSocket() {
    console.log("Refreshing socket session");
    this.socket.emit("Login", this.authToken);
  }

  private removeDeprecatedSessionObjects() {
    // Get all keys from deprecated session objects and remove them.
    Object.keys({ ...localStorage })
      .filter((key) => !(
        key === this.localStorageTokenName ||
        key.startsWith("form") ||
        key.startsWith("local.") ||
        key.startsWith('scroll') ||
        key === "language"
      )).forEach((key) => {
        console.log(`Removing depricated session object "${ key }"`);

        localStorage.removeItem(key);
      });
  }
}

export interface loginForm {
  email: string;
  password: string;
}

export interface Session {
  contactId: number;
  domainId: number;
  companyId: number;
  language: string;
  fallbackLanguage?: string;
  authBitMask: number;
  expireDate?: Date;
  password?: string;
  iat?: number;
}
