<div class="container-fluid xfw3-grid-container" divBreakpointClass [ngClass]="[contentJSON.blockLayoutType ?? '', contentJSON.blockCombine ?? '']">
  <div *ngIf="contentJSON.title.length" [ngClass]="[contentJSON.rowClassName ?? '', environmentJSON.viewportMode ? 'viewportMode' : '', 'xfw3-grid-col-container']">
    <div [ngClass]="contentJSON.blockLayoutGridTitle ? dataService.blockLayoutGridData[contentJSON.blockLayoutGridTitle] : dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
      <h2>
        <ng-container *ngFor="let item of contentJSON.title">
          <div class="header" *ngIf="item.className" [class]="item.className" [innerHTML]="item.text"></div>
          <span *ngIf="!item.className" [innerHTML]="item.text"></span>
        </ng-container>
      </h2>
    </div>
  </div>


  <div [ngClass]="[contentJSON.rowClassName ?? '', 'xfw3-grid-col-container']">
    <ng-container *ngFor="let col of contentJSON.cols[0].col;">
      <div *ngIf="col.body" [ngClass]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
        @if(col.title) {
          <h4>
            @for (item of col.title; track $index) {
              @if (item.className) {
                <div class="header" [class]="item.className" [innerHTML]="item.text"></div>
              }@else {
                <span [innerHTML]="item.text"></span>
              }
            }
          </h4>
        }
        <ng-container *ngFor="let item of col.body">
          <h4 *ngIf="item.title">
            <div class="header" [ngClass]="item.titleClassName" [innerHTML]="(item.title ? item.title : '&nbsp;') | safeHtml"></div>
          </h4>
          <ul>
            <li *ngFor="let listitem of item.list" class="list" [innerHTML]="(listitem.text ? listitem.text : '&nbsp;')">
            </li>
          </ul>
          <ng-container *ngIf="item.nav">
            <div class="xfw-rowSpacer"></div>
            <app-cmd
                [nav]="item.nav"
                [navList]="['custom','primary']"
                [navVisible]="0"
                [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
                [collapsed]="false">
            </app-cmd>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
