<div *ngIf="this.contentJSON" class="container-fluid xfw3-grid-container"
     [ngClass]="contentJSON.blockLayoutType">
  <div class="row py-5">
    <div class="col-12 p-0">
      <div class="row xfw3-grid-col-container" divBreakpointClass>
        <div [class]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[0]]">
          <div class="border-left-thick">
            <ng-container *ngFor="let item of contentJSON.cols[0].col[0].nav.menu; last as last; trackBy: trackByFn">
              <h3 (click)="onSelect(item)" class="link" [ngClass]="[item.isSelected ? 'text-primary' : '', last ? '' : 'm-0']">
                {{ item.text }}
              </h3>
            </ng-container>
          </div>
          <div class="border-left-padding pr-5">
            <ng-container *ngFor="let item of contentJSON.cols[0].col[0].body; trackBy: trackByFn">
              <ng-container *ngIf="item.cond.val === params[item.cond.key]">
                <p class="mh-50" *ngIf="item.text">
                  {{ item.text }}
                </p>
                <app-cmd
                    *ngIf="item.nav"
                    [nav]="item.nav"
                    [navList]="['custom']"
                    [navVisible]="0"
                    [className]="contentJSON.blockLayoutType.includes('dark') ? 'text-dark' : 'text-light'"
                    [collapsed]="false">
                </app-cmd>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div [class]="dataService.blockLayoutGridData[contentJSON.blockLayoutGrid[1]]">
          <div class="d-block d-lg-none xfw-rowSpacer"></div>
          <ng-container *ngFor="let item of contentJSON.cols[0].col[1].img; trackBy: trackByFn">
            <ng-container *ngIf="item.cond.val === params[item.cond.key]">
              <div *ngIf="item.url"
                    [style.background-size]="item.size ? item.size : 'cover'"
                    [style.background-repeat]="item.repeat ? item.repeat : 'no-repeat'"
                    [style.width]="item.width ? item.width : '100%'"
                    [style.min-height]="item.minheight"
                    [style.height]="item.height"
                    [style.background-image]="'url(' + item.url + ')'">
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
