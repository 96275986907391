<div class="overlay-container"
     [ngStyle]="{
       'top': placeAndSize.top,
       'left': placeAndSize.left,
       'bottom': placeAndSize.bottom,
       'right': placeAndSize.right,
       'height': placeAndSize.height,
       'width': placeAndSize.width,
       'z-index': 1000000
     }">

    <div class="header-text-left">
      Log
    </div>

    <div class="header-search">
      <form class="form-inline" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="search-wrapper">
          <div class="search-input">
            <input type="text" class="form-control bg-transparent" formControlName="searchTxt" placeholder="Zoeken">
          </div>
        </div>
        <div class="cmd-round">
          <button class="dripicons dripicons-search" type="submit"></button>
        </div>
        <div class="cmd-round">
          <button (click)="clearLog()" class="dripicons dripicons-trash"></button>
        </div>
      </form>
    </div>

    <div class="overlay">
      <ng-container *ngFor="let item of dataGroupItem.dataTable[0].data; let index = index;">
        <strong>{{index + 1}} - {{ item.origin }}</strong>
        <p>
          <ngx-json-viewer [json]="item.contentJSON"></ngx-json-viewer>
        </p>
        <br />
     </ng-container>
    </div>
</div>
