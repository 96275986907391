import { DragFlags, ISvgElement } from "../svg-draw/svg-draw.component";
import { SvgInhakerDesignToolComponent } from "../svg-inhaker-design-tool/svg-inhaker-design-tool.component";


export abstract class WorldObject implements ISvgElement {
  public ident!: string;

  public drag: DragFlags = 0;

  public x: number = 0;
  public y: number = 0;
  public z: number = 0;

  public save = false;
  public hidden = false;
}

export const footWeightPerCuMeter = 7850;
export const poleWeightPerHeight = 2.1;

export class Inhaker extends WorldObject {
  public poleHeight: number = 0;
  public poleWidth: number = 0.09;

  public footWidth: number = 0;
  public footHeight: number = 0;

  public footOffsetX: number = 0;
  public footOffsetZ: number = 0;

  public reinforcement: number = 1;

  public leftBench?: number;
  public rightBench?: number;

  public get poleWeight() {
    return this.poleHeight * poleWeightPerHeight;
  }

  public get footWeight() {
    return this.footHeight * this.footWidth ** 2 * footWeightPerCuMeter;
  }

  public get weight() {
    return this.footWeight + this.poleWeight;
  }

  public constructor(data: Partial<Inhaker>) {
    super();
    Object.assign(this, data);

    if (this.footOffsetX > (this.footWidth - this.poleWidth) / 2)
      console.error(`FootOffsetX for inhaker ${this.ident} is causing foot to be outside of inhaker.`);

    if (this.footOffsetZ > (this.footWidth - this.poleWidth) / 2)
      console.error(`FootOffsetY for inhaker ${this.ident} is causing foot to be outside of inhaker.`);
  }

  public getLeftBench(world: SvgInhakerDesignToolComponent): Bench | undefined {
    if (!this.leftBench) return;

    return world.objects[this.leftBench] as Bench;
  }

  public getRightBench(world: SvgInhakerDesignToolComponent): Bench | undefined {
    if (!this.rightBench) return;

    return world.objects[this.rightBench] as Bench;
  }
}

export enum HookType {
  Normal,
  UpsideDown
}

export class WeightObject extends WorldObject {
  /** Tuple of inhaker connection points. In order: left, top, right, over */
  public inhakers: [number?, number?, number?, number?] = [undefined, undefined, undefined, undefined];

  public width: number = 0;
  public height: number = 0;
  public depth: number = 0.01;

  public comX: number = 0;
  public comY: number = 0;

  public internalMass: number = 0;
  public density?: number = 4.100;

  public hookOffset: number = 0;
  public hookType: HookType = 0;

  public bottomHookOffsetX: number = 0;
  public save: boolean = true;

  public activeAxis: 'x' | 'z' = 'z';

  /** Path starting at 0,0 ending in 0,1, with a width spanning to -0.5 x */
  public leftSidePath?: string;
  /** Path starting at 0,0 ending in 0,-1, with a width spanning to 0.5 x */
  public rightSidePath?: string;

  /** Whether the weight is a counter weight, visual purposes exclusively */
  public counter = false;
  public override drag = 2;

  public get mass() {
    return this.density === undefined
      ? this.internalMass
      : this.width * this.height * this.density;
  }

  public get weightN() { return 9.8 * this.mass; }

  public constructor(data: Partial<WeightObject>) {
    super();
    Object.assign(this, data);
  }
}

export class Bench extends WorldObject {
  /** World list index */
  public inhaker!: number;

  public width: number = 0;
  public height: number = 0;

  public constructor(data: Partial<Bench>) {
    super();
    Object.assign(this, data);
  }

  public getInhaker(world: SvgInhakerDesignToolComponent): Inhaker {
    return world.objects[this.inhaker] as Inhaker;
  }

}
