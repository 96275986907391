import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { Param } from 'src/app/widgets';

@Component({
  selector: 'app-data-tab',
  templateUrl: './data-tab.component.html',
  styleUrls: ['./data-tab.component.css']
})
export class DataTabComponent implements OnInit {
  @Input() dataGroupItem: DataGroup;
  @Input() dataTabSelected: number;
  @Input() item: any;
  @Input() focus: string;

  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() setEditMode: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(params: Param[], dataItem?, data?) {
    this.select.emit({
      params,
      dataItem,
      data
    });
  }

  onSetEditMode(params: Param[], dataItem?, data?) {
    this.select.emit({
      params,
      dataItem,
      data
    });

    this.setEditMode.emit({
      params,
      dataItem,
      data
    });
  }

  trackByFn(index, item) {
    return index;
  }
}
